import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { IFDGElement, IFDGOptions } from './FDGInterfaces';

export class FDGElement extends PIXI.Container {
  active = true;
  fixed = false;
  target: { x: number, y: number };
  targetScale = 1;
  vX = 0;
  vY = 0;

  // defaultRadius = 70;
  // defaultColor = 0xffffff;
  // force:number=5;
  // mass:number=1;
  // invMass:number=1/this.mass;
  // damp:number=0.98;
  // minV:number=0.000001;

  onAdd: () => void;
  onTick: () => void;
  onRemove: () => void;

  private changeScale: () => void;

  constructor(public options: IFDGElement, private containerOptions: IFDGOptions) {
    super();
    _.defaults(options, { radius: containerOptions.elementRadius, color: containerOptions.elementColor, x: 0, y: 0 });

    this.x = options.x;
    this.y = options.y;
    let graphic = new PIXI.Graphics;
    graphic.beginFill(options.color);
    // graphic.lineStyle(1);
    graphic.drawCircle(0, 0, options.radius);
    this.addChild(graphic);
    if (options.display) {
      this.addChild(options.display);
      let mask = new PIXI.Graphics();
      mask.beginFill(0xffffff);
      mask.drawCircle(0, 0, options.radius);
      mask.drawRect(-options.radius, -options.radius * 1.5, options.radius * 2, options.radius * 1.5);
      this.addChild(mask);
      this.mask = mask;
    }
    this.scale.set(0, 0);
    this.changeScale = this.growTo1;
  }

  get radius(): number {
    return this.options.radius * this.scale.x;
  }

  growTo1 = () => {
    if (this.scale.x < 1 && this.scale.x < this.targetScale * 1.5) {
      this.scale.x += 0.1;
      this.scale.y += 0.1;
    } else {
      this.changeScale = this.adjustScale;
    }
  }

  adjustScale = () => {
    if (this.scale.x !== this.targetScale) {
      if (this.scale.x > this.targetScale) {
        this.scale.x *= 0.95;
        this.scale.y *= 0.95;
      } else {
        this.scale.x = this.targetScale;
        this.scale.y = this.targetScale;
      }
    }
  }

  onTickMain = () => {
    this.moveBody();
    if (this.onTick) {
      this.onTick();
    }

    this.changeScale();
  }

  moveBody = () => {
    if (this.fixed) {
      return;
    }

    this.vX *= this.containerOptions.friction;
    this.vY *= this.containerOptions.friction;

    if (Math.abs(this.vX) > this.containerOptions.minVelocity || Math.abs(this.vY) > this.containerOptions.minVelocity) {
      this.x += this.vX;
      this.y += this.vY;
    } else {
      this.vX = this.vY = 0;
    }
  }

}
