import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'teacher-onboarding',
  templateUrl: './teacher-onboarding.component.html',
  styleUrls: ['./teacher-onboarding.component.scss']
})
export class TeacherOnboardingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
