import { Component, OnInit } from '@angular/core';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'form-text-inform-consent',
  templateUrl: './form-text-inform-consent.component.html',
  styleUrls: ['./form-text-inform-consent.component.scss']
})
export class FormTextInformConsentComponent implements OnInit {

  constructor(
    public whiteLabel: WhitelabelService,
  ) { }

  ngOnInit() {
  }

}
