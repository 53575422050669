import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { SidepanelService } from '../../core/sidepanel.service';
import { Router, NavigationExtras } from '@angular/router';
import { ChatpanelService } from '../../core/chatpanel.service';

import { LeaderboardService, IClassTypeDef } from 'src/app/core/leaderboard.service';
import { ILeaderboard, IBoosterPack } from 'src/app/data/cambridge/types';
import { FormControl, FormArray } from '@angular/forms';
import { BoosterpacksService } from 'src/app/ui-student/boosterpacks.service';
// import { DatePipe } from '@angular/common';
import * as moment from 'moment'; // moment(
import { serverTimestamp } from '../../data/timestamp';

// const FORM_DATE = 'y-MM-dd'; // YYYY-MM-DD
const teamNameTypesBase = [
  {listId: 'none', caption: 'No Team Names'  }, 
];

@Component({
  selector: 'admin-leaderboards',
  templateUrl: './admin-leaderboards.component.html',
  styleUrls: ['./admin-leaderboards.component.scss']
})
export class AdminLeaderboardsComponent implements OnInit {
  
  @ViewChild('selectedLeaderboardPanel') selectedLeaderboardPanel:ElementRef;

  isEnabled:boolean;
  isLackingRole:boolean;

  activeLeaderboards: ILeaderboard[];
  inactiveLeaderboards: ILeaderboard[];

  controlledDistrictIds: string[];

  currentLeaderboard: ILeaderboard;
  isEditing:boolean;
  isSavePending:boolean;
  currentBoosterpack: IBoosterPack;

  newLeaderboard = false;

  currentLeaderboardScores = [];
  isShowingInactiveLeaderboards:boolean;
  availableBoosters: IBoosterPack[] = [];
  teamNameTypes = [];
  groupTypes = ['random', 'houses', 'individual']; // removing class grouping TEMPORARILY until we know if / how to handle it for Cambridge
  // groupTypes = ['class', 'random', 'houses', 'individual'];
  scoreAggregations = ['average', 'total'];
  
  // {id: 'randomEnglish', caption: 'English Pairs (~600 combinations)'  }, 
  // {id: 'randomLuxembourgish', caption: 'Luxembourgish Names (~2500 combinations)' }

  leaderboardForm = {
    name:              new FormControl({value:'', disabled:true}),
    district:          new FormControl({value:'', disabled:true}),
    boosterPackId:     new FormControl({value:'', disabled:true}),
    allowClassless:    new FormControl({value:false, disabled:true}),
    dateOpened:        new FormControl({value:null, disabled:true}),
    dateOpened_minute: new FormControl({value:0, disabled:true}),
    dateOpened_hour:   new FormControl({value:0, disabled:true}),
    dateClosed:        new FormControl({value:null, disabled:true}),
    dateClosed_hour:   new FormControl({value:0, disabled:true}),
    dateClosed_minute: new FormControl({value:0, disabled:true}),
    scoreCollected:    new FormControl({value:'stars', disabled:true}),
    scoreAggregation: new FormControl({value:'', disabled:true}),
    pointsPerStar:     new FormControl({value:'', disabled:true}),
    pointsPerMedal:    new FormControl({value:'', disabled:true}),
    grouping:          new FormControl({value:'', disabled:true}),
    minGroupSize:      new FormControl({value:'', disabled:true}),
    maxGroupSize:      new FormControl({value:'', disabled:true}),
    houses:            new FormArray([new FormControl({value:'', disabled:true})]),
    teamNameList:      new FormControl({value:'', disabled:true}),
    teamNameChangeMessage: new FormControl({value:'', disabled:true}),
    prizes:                new FormControl({value:'', disabled:true}),
    rules: new FormArray([new FormControl({value:'', disabled:true})]),
  };

  // datePipe:DatePipe;

  constructor(
    public ls: LeaderboardService,
    private afs: AngularFirestore,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private auth: AuthService,
    private router: Router,
    private bps: BoosterpacksService,
  ) {
    this.leaderboardForm.boosterPackId.valueChanges.subscribe(this.updateCurrentBoosterpack);
    // this.datePipe = new DatePipe(navigator.language);
  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();

    let signinSub = this.auth.user.subscribe(user => {
      // console.log('user', JSON.stringify(user))
      this.checkDistrictRoles();
      window.setTimeout(() => signinSub.unsubscribe(), 100);
    });
    _.each(this.leaderboardForm, (field:FormControl) =>{
      field.valueChanges.subscribe( o => {
        this.isSavePending = true;
      })
    })
  }

  checkDistrictRoles(){
    let uid = this.auth.getUid();
    this.afs
      .collection('districtRoles', ref=>ref.where('uid', '==', uid).limit(30))
      .get()
      .toPromise()
      .then(res =>{
        if (res.docs.length > 0){
          this.isEnabled = true;
          this.controlledDistrictIds = res.docs.map(doc => {
            return doc.data().districtId;
          })
          // console.log('controlledDistrictIds', this.controlledDistrictIds)
          this.loadBoosterPacks();
          this.loadRandomNamesLists();
          this.ls.initData().then( () => this.loadLeaderboards())
        }
        else{
          this.isLackingRole = true;
        }
      })
  }

  loadRandomNamesLists(){
    this.teamNameTypes = [].concat(teamNameTypesBase);
    Promise.all(this.controlledDistrictIds.map(districtId => {
      return this.afs
        .collection('leaderboardTeamNamesLists', ref=>ref.where('districtId', '==', districtId))
        .get()
        .toPromise()
        .then(res =>{
          res.docs.forEach(doc => {
            this.teamNameTypes.push(doc.data());
          })
        });
    }))
  }

  loadBoosterPacks(){
    this.availableBoosters = []
    let boosterPackIds:string[] = [];
    Promise.all(this.controlledDistrictIds.map(districtId => {
      return this.afs
        .collection('districtBoosterMaps', ref=>ref.where('districtId', '==', districtId))
        .get()
        .toPromise()
        .then(res =>{
          res.docs.forEach(doc => {
            boosterPackIds.push(doc.data().boosterPackId);
          })
        });
    }))
    .then( ()=> {
      boosterPackIds = _.uniq(boosterPackIds);
      return boosterPackIds.map(boosterPackId => {
        return this.bps
          .getBoosterPackById(boosterPackId)
          .then(boosterPack =>{
            this.availableBoosters.push(boosterPack);
          })
      })
    })
    .then( () => {
      console.log('All booster packs loaded');
    })
  }

  loadLeaderboards(){
    this.ls.getAllLeaderboards().then(leaderboards => {
      this.activeLeaderboards = [];
      this.inactiveLeaderboards = [];
      leaderboards.forEach(leaderboard => {
        if (this.controlledDistrictIds.indexOf(leaderboard.district) !== -1){
          this.activeLeaderboards.push(leaderboard);
        }
      })
    });
  }

  goToLeaderboard(leaderboard: ILeaderboard) {
    // console.log()
    this.router.navigate([
      'teacher',
      'leaderboard',
      leaderboard.__id,
    ]);
  }

  editingStart(){
    _.each(this.leaderboardForm, (field:FormControl | FormArray) =>{
      field.enable()
      // field.disable(false);
    })
    this.isEditing = true;
    this.isSavePending = false;
  }

  editingStop(){
    this.isSavePending = false;
    _.each(this.leaderboardForm, (field:FormControl | FormArray) =>{
      field.disable();
    })
    this.isEditing = false;
  }

  deselectActiveLeaderboard(){
    if (this.isEditing){
      alert('Please save or cancel your edits before closing.');
      return;
    }
    this.currentLeaderboard = null;
  }

  getSampleTeamName(leaderboard){
    // this.ls.generateUniqueTeamName(leaderboard); // temp
    this.ls.generateRandomTeamName(leaderboard.teamNameList).then(teamName => {
      alert(teamName)
    })
  }

  selectLeaderboard(leaderboard: ILeaderboard) {
    if (this.isEditing){
      alert('Please save your edits before selecting another Leaderboard Challenge.');
      return;
    }
    

    this.editingStop();
    this.currentLeaderboard = leaderboard;
    this.currentToForm();
    this.newLeaderboard = false;
    this.ls.getTopEntries(leaderboard, 50).then(groupScores => {
      // console.log('groupScores', groupScores)
      this.currentLeaderboardScores = groupScores;
      this.currentLeaderboardScores.forEach(team =>{
        team.cleanScore = Math.floor(team.score);
      })
    });
    setTimeout(()=>{
      // time delay is for aesthetic reasons, but if we decide to remove it, we should take it out
      const el = this.selectedLeaderboardPanel.nativeElement;
      if (el){
        el.scrollIntoView({behavior: 'smooth'});
      }
    }, 700);
  }

  toggleInactiveLeaderboardListing() {
    this.isShowingInactiveLeaderboards = !this.isShowingInactiveLeaderboards;
  }

  cancelEdit(){
    if (!this.isSavePending || window.confirm('Are you sure you want to cancel your edits?')){
      this.currentLeaderboard = null;
      this.editingStop();
    }
  }

  loadNewLeaderboard() {
    this.currentLeaderboard = {
      administrator: this.auth.getUid(),
      district: this.controlledDistrictIds[0],
      classTypes: [],
      boosterPackId: '',
      isActive: true,
      isArchived: false,
      allowClassless: false,
      name: 'New Leaderboard Challenge',
      rules: [''],
      prizes: '',
      scoreCollected: 'stars',
      scoreAggregation: 'total',
      pointsPerStar: 100,
      pointsPerMedal: 100,
      grouping: 'individual',
      minGroupSize: 8,
      maxGroupSize: undefined,
      houses: [''],
      dateOpened: undefined,
      dateClosed: undefined,
      teamNameList: 'randomEnglish',
      teamNameChangeMessage: '',
    };
    this.currentToForm();
    this.newLeaderboard = true;
    this.editingStart();
  }

  isSavingLeaderboard:boolean;
  postCurrentLeaderboard() {
    this.isSavingLeaderboard = true;
    this.formToCurrent();
    if (this.newLeaderboard) {
      let leaderboard: any = {};
      for (let key of Object.keys(this.currentLeaderboard)) {
        if (this.currentLeaderboard[key]) {
          leaderboard[key] = this.currentLeaderboard[key];
        }
      }
      this.newLeaderboard = false;
      this.afs
        .collection('leaderboards/')
        .add({
          ... leaderboard,
          timeCreated: serverTimestamp(),
        })
        .then(value => {
          this.currentLeaderboard.__id = value.id;
          this.activeLeaderboards.push(this.currentLeaderboard);
          this.editingStop();
          this.isSavingLeaderboard = false;
        });
    } 
    else {
      let leaderboard: any = {};
      for (let key of Object.keys(this.currentLeaderboard)) {
        if (this.currentLeaderboard[key]) {
          leaderboard[key] = this.currentLeaderboard[key];
        }
      }
      let leaderboardId = leaderboard.__id;
      delete leaderboard.__id;
      // save backup
      this.afs
        .collection('leaderboardsBackups/')
        .add({
          ... leaderboard,
          leaderboardId,
          uid: this.auth.getUid(),
          timestamp: serverTimestamp(),
        })
      // save live version
      this.afs
        .doc('leaderboards/' + this.currentLeaderboard.__id)
        .set(leaderboard)
        .then(() => {
          // window.alert('Leaderboard ' + this.currentLeaderboard.name + ' successfully updated!');
          this.editingStop();
          this.isSavingLeaderboard = false;
        });
        
    }
  }

  deleteCurrentLeaderboard() {
    window.alert('To delete this leaderboard please contact to your database administrator with the following leaderboard ID: ' + this.currentLeaderboard.__id);
  }
  restoreLeaderboard(leaderboard:ILeaderboard){
    this.afs
      .doc('leaderboards/'+leaderboard.__id)
      .update({
        isArchived: false,
      })
      .then(()=>{
        leaderboard.isArchived = false;
      })
  }

  archiveLeaderboard(leaderboard:ILeaderboard){
    if (confirm('Are you sure you want to hide the '+leaderboard.name+' from student and teachers?')){
      this.afs
        .doc('leaderboards/'+leaderboard.__id)
        .update({
          isArchived: true,
        })
        .then(()=>{
          leaderboard.isArchived = true;
        })
      this.deselectActiveLeaderboard();
    }
  }

  currentToForm() {
    this.leaderboardForm.name.setValue(this.currentLeaderboard.name);
    this.leaderboardForm.district.setValue(this.currentLeaderboard.district);

    this.leaderboardForm.boosterPackId.setValue(this.currentLeaderboard.boosterPackId);
    this.leaderboardForm.allowClassless.setValue(this.currentLeaderboard.allowClassless);
    this.leaderboardForm.scoreCollected.setValue(this.currentLeaderboard.scoreCollected);
    this.leaderboardForm.scoreAggregation.setValue(this.currentLeaderboard.scoreAggregation);
    this.leaderboardForm.pointsPerStar.setValue(this.currentLeaderboard.pointsPerStar);
    this.leaderboardForm.pointsPerMedal.setValue(this.currentLeaderboard.pointsPerMedal);
    this.leaderboardForm.grouping.setValue(this.currentLeaderboard.grouping);
    this.leaderboardForm.minGroupSize.setValue(this.currentLeaderboard.minGroupSize);
    this.leaderboardForm.maxGroupSize.setValue(this.currentLeaderboard.maxGroupSize);
    this.leaderboardForm.prizes.setValue(this.currentLeaderboard.prizes);
    this.leaderboardForm.teamNameList.setValue(this.currentLeaderboard.teamNameList);
    this.leaderboardForm.teamNameChangeMessage.setValue(this.currentLeaderboard.teamNameChangeMessage);

    this.leaderboardForm.rules = new FormArray([new FormControl({value:'', disabled:true})]);
    this.currentLeaderboard.rules = this.currentLeaderboard.rules || [''];
    while (this.currentLeaderboard.rules.length > this.leaderboardForm.rules.length) {
      this.addRuleForm();
    }
    this.leaderboardForm.rules.setValue(this.currentLeaderboard.rules);

    this.leaderboardForm.houses = new FormArray([new FormControl({value:'', disabled:true})]);
    this.currentLeaderboard.houses = this.currentLeaderboard.houses || [''];
    while (this.currentLeaderboard.houses.length > this.leaderboardForm.houses.length) {
      this.addHouseForm();
    }
    this.leaderboardForm.houses.setValue(this.currentLeaderboard.houses);

    
    this.loadDateToFormFields(
      this.currentLeaderboard.dateOpened,
      this.leaderboardForm.dateOpened,
      this.leaderboardForm.dateOpened_hour,
      this.leaderboardForm.dateOpened_minute,
    )
    this.loadDateToFormFields(
      this.currentLeaderboard.dateClosed,
      this.leaderboardForm.dateClosed,
      this.leaderboardForm.dateClosed_hour,
      this.leaderboardForm.dateClosed_minute,
    )

    this.isSavePending = false;
  }

  formToCurrent() {
    this.currentLeaderboard.name = this.leaderboardForm.name.value;
    this.currentLeaderboard.district = this.leaderboardForm.district.value;
    // this.currentLeaderboard.classTypes = this.leaderboardForm.classTypes.value;
    this.currentLeaderboard.boosterPackId = this.leaderboardForm.boosterPackId.value;
    this.currentLeaderboard.allowClassless = this.leaderboardForm.allowClassless.value;
    this.currentLeaderboard.scoreCollected = this.leaderboardForm.scoreCollected.value;
    this.currentLeaderboard.scoreAggregation = this.leaderboardForm.scoreAggregation.value;
    this.currentLeaderboard.pointsPerStar = this.leaderboardForm.pointsPerStar.value;
    this.currentLeaderboard.pointsPerMedal = this.leaderboardForm.pointsPerMedal.value;
    this.currentLeaderboard.grouping = this.leaderboardForm.grouping.value;

    switch(this.currentLeaderboard.grouping) {
      case 'class':
        this.currentLeaderboard.minGroupSize = this.leaderboardForm.minGroupSize.value;
        this.currentLeaderboard.maxGroupSize = this.leaderboardForm.maxGroupSize.value;
        delete this.currentLeaderboard.houses;
        break;
      case 'houses':
        delete this.currentLeaderboard.minGroupSize;
        delete this.currentLeaderboard.maxGroupSize;
        this.currentLeaderboard.houses = this.leaderboardForm.houses.value;
        break;
      case 'random':
        this.currentLeaderboard.minGroupSize = this.leaderboardForm.minGroupSize.value;
        this.currentLeaderboard.maxGroupSize = this.leaderboardForm.maxGroupSize.value;
        delete this.currentLeaderboard.houses;
        break;
      case 'individual': default:
        delete this.currentLeaderboard.minGroupSize;
        delete this.currentLeaderboard.maxGroupSize;
        delete this.currentLeaderboard.houses;
        break;
    }

    this.currentLeaderboard.prizes = this.leaderboardForm.prizes.value;
    this.currentLeaderboard.teamNameList = this.leaderboardForm.teamNameList.value;
    this.currentLeaderboard.teamNameChangeMessage = this.leaderboardForm.teamNameChangeMessage.value;
    this.currentLeaderboard.rules = this.leaderboardForm.rules.value;

    this.currentLeaderboard.dateOpened = this.renderTimestampFromFormValues(this.leaderboardForm.dateOpened.value, this.leaderboardForm.dateOpened_hour.value, this.leaderboardForm.dateOpened_minute.value);
    this.currentLeaderboard.dateClosed = this.renderTimestampFromFormValues(this.leaderboardForm.dateClosed.value, this.leaderboardForm.dateClosed_hour.value, this.leaderboardForm.dateClosed_minute.value);
  }

  loadDateToFormFields(timestamp: { seconds: number }, dateField:FormControl, hourField:FormControl, minuteField:FormControl) {
    let date;
    if (!timestamp) {
      dateField.setValue(null);
      hourField.setValue(0);
      minuteField.setValue(0);
      return;
    }
    date = new Date(timestamp.seconds * 1000);
    dateField.setValue( moment(date).format('YYYY-MM-DD') ); //this.datePipe.transform(date, FORM_DATE));
    hourField.setValue(date.getHours());
    minuteField.setValue(date.getMinutes());
  }

  renderTimestampFromFormValues(dateStr: string, hours:number, minutes:number) {
    if (dateStr) {
      let dateMoment = moment(
        dateStr+' '+(hours||0)+':'+(minutes || 0), 
        'YYYY-MM-DD H:m'
      );
      return {seconds: dateMoment.unix()}
    } 
    else {
      return undefined;
    }
  }

  toggleClassType(classType: IClassTypeDef) {
    const classTypeId = classType.classTypeId;
    const classTypes = this.currentLeaderboard.classTypes;
    const i = classTypes.indexOf(classTypeId);
    if (i >= 0) {
      classTypes.splice(i, 1);
    } else {
      classTypes.push(classTypeId);
    }
  }

  hasClassType(classType: IClassTypeDef) {
    return (this.currentLeaderboard.classTypes.indexOf(classType.classTypeId) !== -1);
  }

  addRuleForm() {
    this.leaderboardForm.rules.push(new FormControl({value:'', disabled: !this.isEditing}));
  }

  removeRuleForm(form: FormControl) {
    let index = this.leaderboardForm.rules.controls.indexOf(form);
    this.leaderboardForm.rules.removeAt(index);
    if (this.leaderboardForm.rules.length === 0) {
      this.addRuleForm();
    }
  }

  addHouseForm() {
    this.leaderboardForm.houses.push(new FormControl({value:'', disabled: !this.isEditing}));
  }

  removeHouseForm(form: FormControl) {
    let index = this.leaderboardForm.houses.controls.indexOf(form);
    this.leaderboardForm.houses.removeAt(index);
    if (this.leaderboardForm.houses.length === 0) {
      this.addHouseForm();
    }
  }

  getClassTypeCaption(districtId:string, classTypeId:string){
    return this.ls.getClassTypeCaption(districtId, classTypeId);
  }

  updateCurrentBoosterpack = (boosterPackId: string) => {
    this.currentBoosterpack = null;
    if (boosterPackId){
      this.bps
        .getBoosterPackById(boosterPackId)
        .then(boosterPack => this.currentBoosterpack = boosterPack);
    }
  }

  previewLeaderboardMap(leaderboardId:string) {
    this.router.navigate([
      'admin',
      'leaderboard',
      leaderboardId,
    ], {});
  }
  previewTeacherView(leaderboardId:string) {
    alert('Teacher View is disabled for QC.');
    return;
  }

}
