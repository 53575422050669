import { Component, OnInit, Input } from '@angular/core';
import { IContentElementMcqOption } from '../models';

@Component({
  selector: 'element-config-mcq-option-math',
  templateUrl: './element-config-mcq-option-math.component.html',
  styleUrls: ['./element-config-mcq-option-math.component.scss']
})
export class ElementConfigMcqOptionMathComponent implements OnInit {

  @Input() mcqOption:IContentElementMcqOption;

  constructor() { }

  ngOnInit() {
    
  }

}
