import { Component, OnInit, Input } from '@angular/core';
import { IContentElementMcq, IContentElementMcqOption, QuestionState } from '../models';
import { indexOf } from '../services/util';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'element-render-order',
  templateUrl: './element-render-order.component.html',
  styleUrls: ['./element-render-order.component.scss']
})
export class ElementRenderOrderComponent implements OnInit {

  @Input() element:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  constructor() { }
  currentSelections = [];

  ngOnInit() {

  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

}
