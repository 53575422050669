import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { renderEmailFromUsername } from '../../data/accounts/pseudo-email-usernames';
import { FormValidation, SubFormRequest } from '../../core/forms';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'classroom-play-login',
  templateUrl: './classroom-play-login.component.html',
  styleUrls: ['./classroom-play-login.component.scss']
})
export class ClassroomPlayLoginComponent implements OnInit, OnDestroy {

  classroomId: string;
  classCodeId: string;
  username: string;
  routeSub:Subscription;
  signinSub:Subscription;

  constructor(
    public whiteLabel: WhitelabelService,
    private router: Router, 
    private auth:AuthService,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId']; // used to show name in the UI
      this.classCodeId = params['classCodeId'];
      this.username = params['username'];
      this.attemptAutoFocus();
    });
    this.signinSub = this.auth.user.subscribe(user => {
      if (user){
        this.afterSignIn();
      }
    })
  }

  ngOnDestroy(){
    if(this.routeSub) {this.routeSub.unsubscribe()}
    if(this.signinSub) {this.signinSub.unsubscribe()}
  }

  attemptAutoFocus(){
    console.log('attemptAutoFocus')
    setTimeout(()=>{
      try {
        if (this.username){
          document.getElementById('passwordInput').focus();
        }
        else{
          document.getElementById('usernameInput').focus();
        }
      }
      catch(e){
        console.warn('could not autofocus password field')
      }
    }, 300);
  }

  getFreshAccountPassword(){
    const freshAccount = this.auth.getFreshAccountSignup();
    if (freshAccount){
      return freshAccount.password;
    }
    // return 'SUGGESTED'
  }

  afterSignIn(){
    return this.auth
      .fetchNewUserInfo()
      .then( () => {
        this.auth.navigateToUserUiHome()
      })
  }

}
