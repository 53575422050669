import { Injectable } from '@angular/core';
import { IAssignment } from '../data/collections/assignments.types';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

enum Collections {
  assignments = 'assignments'
}

@Injectable({
  providedIn: 'root'
})
export class AssignmentsService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { }

  getOpenAssessments(classroomId:string, maxEntries:number=3){
    return this.afs
      .collection<IAssignment>(Collections.assignments, ref => {
        return ref
          .where('classroom', '==', classroomId)
          .where('isDirectStart', '==', true)
          .where('isDirectClose', '==', false)
          .where('isArchived', '==', false)
          .orderBy('timeDirectStart', 'desc')
          .limit(maxEntries)
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data();
            return { __id: a.payload.doc.id, ...data };
          });
        })
      )
  }

  getRecentlyCompletedAssessments(classroomId:string, maxEntries:number=3){
    return this.afs
      .collection<IAssignment>(Collections.assignments, ref => {
        return ref
          .where('classroom', '==', classroomId)
          .where('isDirectClose', '==', true)
          .where('isArchived', '==', false)
          .orderBy('timeDirectClose', 'desc')
          .limit(maxEntries)
      })
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data();
            return { __id: a.payload.doc.id, ...data };
          });
        })
      )
  }

}
