import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IBoosterPack, IBoosterPackItem, ILeaderboard, ILeaderboardScore } from '../../data/cambridge/types';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';
import { BoosterpacksService, IItemPerformance, ItemTypes, IItemScore, IBoosterPackItemOptions } from '../boosterpacks.service';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { LeaderboardService } from 'src/app/core/leaderboard.service';

@Component({
  selector: 'student-booster-pack',
  templateUrl: './student-booster-pack.component.html',
  styleUrls: ['./student-booster-pack.component.scss']
})
export class StudentBoosterPackComponent implements OnInit {

  @ViewChild('mapView') mapView: ElementRef
  @ViewChild('currentLevelIndicator') currentLevelIndicator: ElementRef

  isEditingMap:boolean = false;
  isEditingMenuCollapsed:boolean = false;
  clipboardItem:IBoosterPackItem;
  classroomId:string;
  leaderboardId: string;
  leaderboardData: ILeaderboard;
  leaderboardItemScores: ILeaderboardScore[];
  itemOptions: IBoosterPackItemOptions;

  isLeaderboard:boolean = false;
  activeBoosterPackId:string;
  activeBoosterPack:IBoosterPack;
  activeBoosterPackItems:IBoosterPackItem[];
  activeBoosterPackCheckpoints:Map<string, boolean> = new Map();
  selectedItem;
  isDirty:boolean;
  mapUrl:SafeResourceUrl;
  mapViewScale:number = 1;
  isMapImageLoaded: boolean;
  computedMapWidth:number = 100;
  computedMapHeight:number = 100;
  isInstructionalShotMode:boolean;

  private immediatelyCompletedItemId:string;
  private routeSub:Subscription;
  private routeDataSub:Subscription;
  private itemIconClasses = new Map();
  private itemScores:Map<string, IItemScore> = new Map();
  private itemRef:Map<string, IBoosterPackItem> = new Map();
  private itemLocks = new Map();
  private totalStars:number = 0;
  private totalMaxStars:number = 0;
  private routeData:{isTeacher?:boolean, isAdmin?:boolean, isLeaderboard?:boolean} = {}

  constructor(
    private auth: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private bps: BoosterpacksService,
    private ls: LeaderboardService,
    ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId']; // only for teachers
      this.leaderboardId = params['leaderboardId']; // only for leaderboard mode
      if (this.leaderboardId) {
        this.isLeaderboard = true;
        this.loadLeaderboard(this.leaderboardId)
          .then(leaderboardData => {
            console.log('leaderboardData', this.leaderboardId, leaderboardData)
            this.activeBoosterPackId = leaderboardData.boosterPackId;
            if (this.activeBoosterPackId){
              this.loadBoosterPackData();
            }
          });
      } 
      else {
        this.activeBoosterPackId = params['boosterPackId']; // used to show name in the UI
        this.loadBoosterPackData();
      }
    });
    if (this.auth.isContentAdminAccount()){
      this.isEditingMap = true;
    }
    this.routeDataSub = this.route.data.subscribe(v => this.routeData = v );
    this.immediatelyCompletedItemId = this.bps.getLastCompletedItem();
    this.bps.clearLastCompletedItem();
  }

  ngOnDestroy(){
    if (this.routeSub) { this.routeSub.unsubscribe(); }
    if (this.routeDataSub) { this.routeDataSub.unsubscribe(); }
  }

  activateInstructionalShotMode(){
    this.isInstructionalShotMode = true;
  }

  saveBoosterPackPositions(){
    this.activeBoosterPackItems.forEach(item =>{
      if (item.isDirty){
        this.bps
          .updateItemPosition(item.__id, item);
        item.isDirty = false;
        this.computeItemIconClasses(item);
      }
    })
    this.isDirty = false;
  }

  isTeacher(){
    try {
      return this.routeData.isTeacher || this.routeData.isAdmin;
    }
    catch(e){
      return false;
    }
  }

  isImmediatelyCompletedItem(item:IBoosterPackItem){
    if (item){
      return this.immediatelyCompletedItemId === item.__id
    }
  }

  goBackToBoosterPackSelector(){

    if (this.routeData.isLeaderboard){
      if (this.routeData.isAdmin){
        this.router.navigate([
          'admin',
          'leaderboards'
        ]);
      }
      else if (this.routeData.isTeacher){
        this.router.navigate([
          'teacher',
          'leaderboards'
        ]);
      }
      else{
        this.router.navigate([
          'student',
          'leaderboards',
        ]);
      }
    }
    else{
      if (this.routeData.isTeacher){
        this.router.navigate([
          'teacher',
          'classroom',
          this.classroomId,
          'boosterpacks'
        ]);
      }
      else{
        this.router.navigate([
          'student',
          'dashboard',
        ]);
      }
    }
  }

  
  recomputeNumStars = _.throttle(()=>{
    let totalStars = 0;
    let totalMaxStars = 0;
    this.activeBoosterPackItems.forEach(item => {
      const scoreInfo =  this.getItemScore(item);
      totalStars += scoreInfo.numStars;
      totalMaxStars += scoreInfo.maxStars;
    });
    // console.log('recompute', totalStars, totalMaxStars)
    this.totalStars = totalStars;
    this.totalMaxStars = totalMaxStars;
    this.bps.updateBoosterPackPerfSummary(this.activeBoosterPackId, totalStars, totalMaxStars, this.leaderboardId);
  }, 500);

  openSelectedItem(){
    // console.log('openSelectedItem 1', this.itemLocks.has(this.selectedItem), this.itemLocks.get(this.selectedItem))
    if (this.isImmediatelyCompletedItem(this.selectedItem)){
      return;
    }
    if (this.isItemLocked(this.selectedItem) && !this.isTeacher()){
      return;
    }
    if (this.routeData.isLeaderboard){
      this.ls.leaderboardCollapseState.set(this.leaderboardId, false);
    }
    if (this.selectedItem){
      if (this.routeData.isAdmin && this.routeData.isLeaderboard){
        this.router.navigate([
          'admin',
          'leaderboard',
          this.leaderboardId,
          'item',
          this.selectedItem.__id,
        ]);
      }
      else if (this.routeData.isTeacher){
        if (this.routeData.isLeaderboard) {
          this.router.navigate([
            'teacher',
            'leaderboard',
            this.leaderboardId,
            'item',
            this.selectedItem.__id,
          ]);
        } else {
          this.router.navigate([
            'teacher',
            'classroom',
            this.classroomId,
            'boosterpacks',
            this.activeBoosterPackId,
            'item',
            this.selectedItem.__id,
          ]);
        }
      }
      else{
        if (this.routeData.isLeaderboard) {
          this.router.navigate([
            'student',
            'leaderboard',
            this.leaderboardId,
            'item',
            this.selectedItem.__id,
          ]);
        } else {
          this.router.navigate([
            'student',
            'booster',
            this.activeBoosterPackId,
            'item',
            this.selectedItem.__id,
          ]);
        }
      }
    }
  }
  
  sendToClipboard(item:IBoosterPackItem){
    this.clipboardItem = item;
  }
  clearClipboard(){
    this.clipboardItem = null;
  }

  getItemIconClasses(item){
    return this.itemIconClasses.get(item) || [];
  }

  updateItemScore(item:IBoosterPackItem, score:number){
    this.itemScores.set(
      item.__id, 
      this.bps.processItemScore(item.itemType, score)
    );
  }
  
  getItemScore(item:IBoosterPackItem) : IItemScore {
    if (this.itemScores.has(item.__id)){
      return this.itemScores.get(item.__id)
    }
    const itemType = item.itemType || ItemTypes.red;
    let maxStars = 1;
    switch(itemType){
      case ItemTypes.blue:  maxStars = 1; break; 
      case ItemTypes.red:
      case ItemTypes.black:
      case ItemTypes.medal:
        maxStars = 3; break; 
    }
    return {
      score: 0, 
      numStars:0, 
      maxStars,
    };
  }

  computeItemIconClasses(item:IBoosterPackItem){
    const classes = [];
    const itemType = item.itemType || ItemTypes.red;
    switch(itemType){
      case ItemTypes.blue:  classes.push('is-item-blue'); break; 
      case ItemTypes.red:
      case ItemTypes.medal:
        classes.push('is-item-red'); break; 
      case ItemTypes.black: classes.push('is-item-black'); break; 
    }
    const score:IItemScore = this.getItemScore(item);
    if (score.hasMedal) {
      classes.push('is-has-medal');
    } else {
      switch(score.numStars){
        case 3: classes.push('is-3-stars'); break;
        case 2: classes.push('is-2-stars'); break;
        case 1: classes.push('is-1-stars'); break;
        default:
        case 0: classes.push('is-0-stars'); break;
      }
    }
    this.itemIconClasses.set(item, classes);
  }

  determineItemLockState(item:IBoosterPackItem){
    let isLocked = false;
    if (item.unlockedBy){
      item.unlockedBy.forEach(dItemId => {
        const dItem = this.itemRef.get(dItemId);
        const score:IItemScore = this.getItemScore(dItem);
        if (!score){
          isLocked = true;
        }
        else if(score.numStars < 1){
          isLocked = true;
        }
      })
    }
    // console.log('determineItemLockState', isLocked, item.unlockedBy)
    this.itemLocks.set(item, isLocked);
  }

  isItemLocked(item){
    if (this.isInstructionalShotMode){
      return false;
    }

    if (this.leaderboardData && !this.ls.isLeaderboardActive(this.leaderboardData)) {
      return true;
    }

    if (!this.itemLocks.has(item)){
      return true;
    }
    return this.itemLocks.get(item);
  }

  getBoosterStarsEarned(){
    return this.totalStars;
  }
  getBoosterStarsMax(){
    return this.totalMaxStars;
  }

  getBoosterStarProgress(){
    const maxStars = this.getBoosterStarsMax();
    let progress = 0;
    if (maxStars > 0){
      progress = this.getBoosterStarsEarned() / maxStars;
    }
    return Math.round(progress * 100);
  }

  clickSelectItem(item:IBoosterPackItem){
    this.immediatelyCompletedItemId = null;
    this.selectItem(item);
  }

  selectItem(item:IBoosterPackItem, skipSave:boolean=false, scrollIntoView:boolean=false, openIfSelected:boolean=true){
    if (this.isSelectedItem(item)){
      if (openIfSelected){
        this.openSelectedItem();
      }
    }
    else{
      this.selectedItem = item;
      if(scrollIntoView){
        if(this.isMapImageLoaded){
          this.scrollCurrentLevelIndicatorIntoView()
        }
        else{
          this.isScrollPending = true;
        }
      }
      if (this.isEditingMap){
        const el = document.getElementById('item-edit-'+this.selectedItem.__id);
        if (el){
          el.scrollIntoView({behavior: 'smooth'});
        }
      }
      if (!skipSave){
        this.bps.setPackLastTouch(this.activeBoosterPackId, item.__id);
      }
    }
  }
  
  isScrollPending:boolean;
  scrollCurrentLevelIndicatorIntoView(){
    this.isScrollPending = false;
    setTimeout(()=>{
      this.currentLevelIndicator.nativeElement.scrollIntoView({behavior: 'smooth', block:'end'});
    }, 500);
  }

  isSelectedItem(item){
    return this.selectedItem === item;
  }
  
  markAsDirty(){
    this.isDirty = true;
  }

  onImageLoaded(){
    setTimeout(()=>{ // failing to retrieve native element on occasion
      this.isMapImageLoaded = true;
      this.initResize(true);
      if (this.isScrollPending){
        this.scrollCurrentLevelIndicatorIntoView()
      }
    }, 100);
  }

  initResize(forceRecompute:boolean){
    this.resizeMap(forceRecompute);
  }

  getBoosterPackName(){
    if (this.activeBoosterPack){
      return this.activeBoosterPack.caption;
    }
  }



  resizeMap = (forceRecompute:boolean=false) => {
    const el:HTMLElement = this.mapView.nativeElement;
    const viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    const mapViewUnscaledWidth = el.offsetWidth;
    const mapViewUnscaledHeight = el.offsetHeight;
    const scale = Math.min(1, (viewportWidth - 50)/mapViewUnscaledWidth);
    this.mapViewScale = scale;
    this.computedMapWidth = mapViewUnscaledWidth * scale;
    this.computedMapHeight = mapViewUnscaledHeight * scale;
    // console.log('scale', scale, mapViewUnscaledWidth, viewportWidth)
  }




  getItemById = (id:string) => {
    let matchingItem = {name:'NOT_FOUND'};
    if (this.activeBoosterPackItems){
      // console.log('getItemById')
      this.activeBoosterPackItems.forEach(item => {
        // console.log(item.__id, id)
        if (item.__id === id){
          matchingItem = item;
        }
      });
    }
    return matchingItem;
  }

  loadLastTouched(){
    this.bps
      .getPackLastTouch(this.activeBoosterPackId)
      .then(selectedBoosterPackItemId => {
        this.activeBoosterPackItems.forEach(item => {
          if (item.__id === selectedBoosterPackItemId){
            this.selectItem(item, true, true);
          }
        })
      })
  }

  hasCheckpoint(item:IBoosterPackItem){
    if (item){
      return this.activeBoosterPackCheckpoints.get(item.__id);
    }
  }

  loadBoosterPackData(){
    this.bps
      .getBoosterPackSummary(this.activeBoosterPackId)
      .subscribe( boosterPack => {
        this.activeBoosterPack = boosterPack;
        this.mapUrl = this.sanitizer.bypassSecurityTrustResourceUrl(boosterPack.bgImageUrl);
      });
    
    this.bps
      .getBoosterPackItemOptions(this.activeBoosterPackId)
      .then(itemOptions => {
        this.itemOptions = itemOptions; 
        console.log('options', this.itemOptions);
      });

    this.bps
      .getBoosterPackCheckpoints(this.activeBoosterPackId)
      .subscribe (checkpoints => {
        this.activeBoosterPackCheckpoints = new Map();
        console.log('getBoosterPackCheckpoints', this.activeBoosterPackId, checkpoints);
        checkpoints.forEach(checkpoint => {
          this.activeBoosterPackCheckpoints.set(checkpoint.boosterPackItemId, true);
        })
      })

    this.bps
      .getBoosterPackItems(this.activeBoosterPackId)
      .subscribe( boosterPackItems =>{
        this.activeBoosterPackItems = boosterPackItems.sort( ( a,b) => {
          return (a.humanOrdering > b.humanOrdering) ? 1 : -1;
        });
        this.activeBoosterPackItems.forEach(item => {
          this.itemRef.set(item.__id, item);
          this.itemLocks.set(item, true);
          this.computeItemIconClasses(item);
        })
        // this.recomputeNumStars();
        this.loadLastTouched();
        this.bps
          .loadItemCompletions(this.activeBoosterPackId, this.leaderboardId)
          .then( (itemPerformances:IItemPerformance[]) =>{
            // console.log('loadItemCompletions', data)
            itemPerformances.forEach( itemPerformance => {
              const item = this.itemRef.get(itemPerformance.boosterPackItemId);
              if (item) {
                this.updateItemScore(item, itemPerformance.score);
                this.computeItemIconClasses(item);
              }
            })
            this.activeBoosterPackItems.forEach(item => {
              this.determineItemLockState(item);
            });
            this.recomputeNumStars();
          })
      })
  }

  loadLeaderboard(leaderboardId: string) {
    return this.ls
      .getLeaderboardById(leaderboardId)
      .then(data => {
        if (data) {
          this.leaderboardData = data;
        }
        if (data.scoreCollected === 'score') {
          this.ls.getMyLeaderboardItemScores(data).then((itemScores: ILeaderboardScore[]) => {
            this.leaderboardItemScores = itemScores;
          });
        }
        return data;
      });
  }

  itemOptionsUpdated(event: any) {
    this.bps.updateBoosterPackItemOptions(this.activeBoosterPackId, event);
    console.log(event);
  }

  getMapUrl(){
    return this.activeBoosterPack.bgImageUrl;
  }

  editorAddOn:string;
  EDITOR_ADD_ONS = {
    TILEMAP: 'TILEMAP',
    NEW_ITEM: 'NEW_ITEM',
    MAP_OVERLAYS: 'MAP_OVERLAYS',
  }
  activateTimeMapUpload(){
    this.editorAddOn = this.EDITOR_ADD_ONS.TILEMAP
  }
  activateTimeMapOverlays(){
    this.editorAddOn = this.EDITOR_ADD_ONS.MAP_OVERLAYS
  }
  activateNewItemInsert(){
    this.editorAddOn = this.EDITOR_ADD_ONS.NEW_ITEM
  }

  exitEditorAddOn() {
    this.editorAddOn = null;
  }

  showItemScore() {
    return this.leaderboardData && this.leaderboardData.scoreCollected === 'score';
  }

  getItemLeaderboardScore(item: IBoosterPackItem) {
    let score = _.find(this.leaderboardItemScores, {itemId: item.__id});
    if (score) {
      return score.score;
    }

    return 0;
  }
}
