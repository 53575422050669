import * as _ from 'lodash';
import {firestore} from 'firebase/app';
import { Observable, Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { FormControl } from '@angular/forms';

import { serverTimestamp } from '../../data/timestamp';
import { IAssignment } from '../../data/collections/assignments.types';
import { ITask } from '../../data/collections/tasks.types';
import { IClassroom } from '../../data/collections/classrooms.types';
import { IUser } from '../../data/collections/users.types';
import { IAssignmentTaskSubmission, ISubmResponse } from '../../data/collections/assignment-task-submissions.types';

import { SORT_NAMES_DESC, SORT_NAMES_ASC } from '../../data/sort/names';

import { CollectionsService } from '../../data/collections.service';
import { ClassroomService } from '../classroom.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ChatpanelService } from '../../core/chatpanel.service';

import { ITeacherGradingFormlet } from '../teacher-grading-formlet/teacher-grading-formlet.component';
import { TaskCacheService } from '../../ui-taskcreator/task-cache.service';
import { ensureFirstLastName } from '../../data/name-split';
import { BoosterService } from '../../core/booster.service';
import { IBoosterPack } from '../../data/cambridge/types';



type IStudentUid = string;
type IQuestionTaskId = string;
type ISubmissionRef = Map<IQuestionTaskId, IStudentSubmissionRef>
type IStudentSubmissionRef = Map<IStudentUid, IAssignmentTaskSubmission>
type IQuestionResponses = Map<IQuestionTaskId, IQuestionResponseInfo[]>
interface IQuestionResponseInfo {
  responseText: string, // used as the index
  responseText_clean: string, // used as display
  responses: Array<ISubmResponse>,
  isCorrect: boolean,
  students: string[],
  marksEarned?: number,
}
interface IQuestionSlot {
  taskId: string,
  config: ITask,
  computedTotalMarks: number,
  responses: Map<IStudentUid, IAssignmentTaskSubmission>
  responsesSummarized: IAssignmentTaskSubmission[],
}

const DEFAULT_MARKS_BUCKET = '_NONE_';

@Component({
  selector: 'teacher-classroom-assignment-report',
  templateUrl: './teacher-classroom-assignment-report.component.html',
  styleUrls: ['./teacher-classroom-assignment-report.component.scss']
})
export class TeacherClassroomAssignmentReportComponent implements OnInit {

  public isNamesHidden:boolean = true;
  public sortBy:string = 'completion';
  
  public responseCommentEdits:FormControl[];
  public questions:IQuestionSlot[];
  public questionsRef:Map<string, IQuestionSlot> = new Map();
  public activeStudents:IUser[];
  public activeAssignment:IAssignment;
  public classConfig:IClassroom = {name:''};
  public studentRef:Map<string, IUser> = new Map();
  public teacherResponseGradeOverrides: Map<string, {__id:string, isCorrect:boolean}> = new Map();
  public teacherResponseComments: Map<string, {__id:string, comment:string}> = new Map();
  public teacherResponseCommentEditFlags: Map<string, boolean> = new Map();
  public responseGradings: Map<string, boolean> = new Map();
  public responseComments: Map<string, string> = new Map();
  public isCustomGradingModalOpen:boolean;
  public currentCustomGradingFocus = {
    questionTaskId: null,
    screenshot:  null,
    response:  null,
    responseIndex:  null,
  }
  public isolatedStudent:string = null;
  public isMarksAllocEditShowing:boolean = false;
  public markAllocEdits = new FormControl('');
  public activeTask:ITask;
  public allStudents:IUser[];
  public boosterPacksAssoc:IBoosterPack[];
  public classroomId:string;

  private submissions:ISubmissionRef = new Map();
  private questionResponsesRef:IQuestionResponses = new Map();
  private studentPerformanceSummary:Map<string, {score:number, proportionComplete:number, questionMarksTally?:any}> = new Map();  /// any ::: {numCorrect:number, numTotal:number, marksEarned:number}
  private assignmentId:string;
  private forceOpenSubmGradingMap:Map<string, boolean> = new Map();
  private showingStudentNamesByQuestion:Map<string, boolean> = new Map();
  

  constructor(
    private afs: AngularFirestore,
    private tcs: TaskCacheService,
    private route: ActivatedRoute,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private boosterService: BoosterService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
    ) { 

    // creating 40 commment slots (supports up to 40 response parts per question)
    this.responseCommentEdits = [];
    for (let i=0; i<40; i++){
      this.responseCommentEdits.push(new FormControl())
    }
  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = params['classroomId'];
      this.assignmentId = params['assignmentId'];
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId, ()=> {
        this.loadStudents(this.classConfig.currentStudents, ()=> {
          this.loadAssignment(this.assignmentId, ()=> {
            this.loadTeacherOverrides(this.assignmentId)
            this.loadAssignmentSubmissionFeeds(this.assignmentId);
          });
        });
      });
    });
  }

  loadClassroom(classroomId:string, then:Function){
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        then();
      });
  }

  loadStudents(studentIds:string[], then:Function){
    this.allStudents = [];
    this.activeStudents = [];
    let responseTally:number = 0;
    if (!studentIds){
      then();
    }
    else{
      studentIds.map( studentUid => {
        let sub = this.afs.doc<IUser>('users/'+studentUid).get().subscribe( res =>{
          let studentInfo = <IUser>res.data();
          if (!studentInfo.isTestUser){
            this.activeStudents.push(studentInfo); 
          }
          ensureFirstLastName(studentInfo);
          this.allStudents.push(studentInfo); 
          this.studentRef.set(studentInfo.uid, studentInfo);
          this.applyStudentSorting(); // inefficient to be doing this after each student...
          sub.unsubscribe();
          responseTally ++;
          if (responseTally === studentIds.length){
            then();
          }
        });
      })
    }
  }

  getStudentNameById(studentId:string){
    let student = this.studentRef.get(studentId);
    if (student){
      return student.firstName +' '+student.lastName
    }
  }

  private SORT_STUDENTS_BY_SCORE = (a:IUser,b:IUser) => {
      var nameA = this.getStudentQuestionScoreSummary(a.uid).score || 0;
      var nameB = this.getStudentQuestionScoreSummary(b.uid).score || 0;
      if (nameA > nameB) { return -1; }
      if (nameA < nameB) { return 1; }
      return 0;
  }

  private SORT_STUDENTS_BY_COMPLETION = (a:IUser,b:IUser) => {
      var nameA = this.getStudentQuestionScoreSummary(a.uid).proportionComplete || 0;
      var nameB = this.getStudentQuestionScoreSummary(b.uid).proportionComplete || 0;
      if (nameA > nameB) { return -1; }
      if (nameA < nameB) { return 1; }
      return 0;
  }

  applyStudentSorting(){
    if (this.sortBy === 'name'){
      this.activeStudents = this.activeStudents.sort(SORT_NAMES_ASC);
    }
    else if (this.sortBy === 'score'){
      this.activeStudents = this.activeStudents.sort(this.SORT_STUDENTS_BY_SCORE);
    }
    else if (this.sortBy === 'completion'){
      this.activeStudents = this.activeStudents.sort(this.SORT_STUDENTS_BY_COMPLETION);
    }
    
  }

  showTestStudents(){
    this.activeStudents = [];
    this.allStudents.forEach(studentInfo => {
      this.activeStudents.push(studentInfo);
    })
  }

  markAllocEditSubscription:Subscription;
  toggleMarksAllocEditor(){
    this.isMarksAllocEditShowing = !this.isMarksAllocEditShowing;

    if (this.isMarksAllocEditShowing){
      let markedQuestions = [];
      this.markAllocEdits.setValue(markedQuestions);
      this.markAllocEditSubscription = this.markAllocEdits.valueChanges.subscribe(newValues => {
        console.log()
      })
    }
    else{
      this.markAllocEditSubscription.unsubscribe()
    }
  }
    

  loadAssignment(assignmentId:string, then:Function){
    this.afs.doc('assignments/'+assignmentId).get().toPromise().then( res => {
      this.activeAssignment = <IAssignment>res.data();
      this.loadTask(this.activeAssignment.tasks[0], then); // hard-coded to one for now...
    })
  }

  loadAssignmentSubmissionFeeds(assignmentId:string){
    this.afs.collection<IAssignmentTaskSubmission>(
      'assignmentTaskSubmissions', 
      ref => ref.where(
        'assignmentId', '==', assignmentId
      ) 
    ).snapshotChanges()
    .subscribe( submissionsList => {
      // console.log('submissions update'); 
      // currently this entires thing is recomputed if there is any change in the (this also triggers a full recomputation of the student scores) 
      submissionsList.forEach(submissionPayloadContainer => {
        let submissionPayload:IAssignmentTaskSubmission = <IAssignmentTaskSubmission>(submissionPayloadContainer.payload.doc.data());
        submissionPayload.__id = submissionPayloadContainer.payload.doc.id;
        // console.log('submissionPayload', submissionPayload.questionTaskId);
        return this.setStudentQuestionPerformance(submissionPayload.questionTaskId, submissionPayload.uid, submissionPayload)
      })
    })
  }


  translateResponseText(str:string){
    // temporary.... timing will change when this is handled properly as we will need to collect the packs as they come in
    const knownTranslationIds = [
      {id: 'TRANPACK://lux_ef_c4-_m6_diag/valids', en: 'Valid'},
      {id: 'TRANPACK://lux_ef_c4-_m6_diag/bad_select', en: 'Invalid'},
    ]
    knownTranslationIds.forEach(trans => {
      str = str.replace(trans.id, trans.en);
    })
    return str;
  }

  setStudentQuestionPerformance(questionTaskId:string, studentId:string, submission:IAssignmentTaskSubmission){
    // console.log('setStudentQuestionPerformance', questionTaskId, studentId);
    if (!this.submissions.has(questionTaskId)){
      this.submissions.set(questionTaskId, new Map());
    }
    
    let studentInfo = this.studentRef.get(studentId);
    if (!studentInfo){
      console.error('User not in class (anymore?)', studentId);
      return
    }
    if (studentInfo.isTestUser){
      return;
    }

    // ensure unique response ids
    if (submission){
      if (submission.screenshotUrl && !submission.lastScreenshot){
        submission.lastScreenshot = submission.screenshotUrl;
      }
    }
    if (submission.responses){
      let idsUsed = new Map();
      let responseIdCounter = 1;
      submission.responses.forEach(response => {
        if (idsUsed.has(response.id)){
          response.id = response.id + '__DUPLICATE__'+responseIdCounter;
          responseIdCounter ++;
        }
      })
    }

    let questionRef = this.submissions.get(questionTaskId);
    if (questionRef.has(studentId)){
      this.removeStudentQuestionResponseRef(questionTaskId, studentId, questionRef.get(studentId));
    }
    // submission.responseText = this.translateResponseText(submission.responseText); // timing of this will change so it would be better to encapsulate in an object
    if (submission.responses){
      submission.responses.forEach(response => {
        if (response.responseText){
          response.responseText = this.translateResponseText(response.responseText);
          response.responseText_clean = response.responseText.replace(/\{\{.+\}\}/g,"");  
        }
        if (response.responseDetailed){
          let responseDetailed = [];
          Object.keys(response.responseDetailed).forEach((key) => {
            responseDetailed.push(response.responseDetailed[key]);
          });
          // console.log('INIT response.responseDetailed ::: ', typeof responseDetailed, responseDetailed)
          response.responseDetailed = responseDetailed;
        }
      })
    }
    questionRef.set(studentId, submission);
    // if (!this.studentRef.get(studentId).isTestUser){
    this.addStudentQuestionResponseRef(questionTaskId, studentId, questionRef.get(studentId));
    // }
    this.recomputeStudentSummaryMetrics(studentId);
  }

  getQuestionResponseSlotByQuestionAndResponseText(questionTaskId:string, responseText:string){
    let questionResponses = this.questionResponsesRef.get(questionTaskId);
    if (questionResponses){
      let matchingResponse:IQuestionResponseInfo;
      questionResponses.forEach(response => {
        if (response.responseText === responseText){
          matchingResponse = response;
        }
      });
      return matchingResponse;  
    }
  }

  removeStudentQuestionResponseRef(questionTaskId:string, studentId:string, submission:IAssignmentTaskSubmission){
    let matchingResponse:IQuestionResponseInfo = this.getQuestionResponseSlotByQuestionAndResponseText(questionTaskId, submission.responseText);
    let spliceIndex = matchingResponse.students.indexOf(studentId);
    if (spliceIndex !== -1){
      matchingResponse.students.splice(spliceIndex, 1);
    }
  }

  addStudentQuestionResponseRef(questionTaskId:string, studentId:string, submission:IAssignmentTaskSubmission){
    if (!this.questionResponsesRef.has(questionTaskId)){
      this.questionResponsesRef.set(questionTaskId, []);  
    }
    let questionResponses = this.questionResponsesRef.get(questionTaskId);
    let matchingResponse:IQuestionResponseInfo;
    questionResponses.forEach(response => {
      if (response.responseText === submission.responseText){
        matchingResponse = response;
      }
    })
    if (!matchingResponse){
      matchingResponse = {
        responseText: submission.responseText, 
        responseText_clean: submission.responseText_clean, 
        responses: submission.responses, 
        students:[], 
        isCorrect:false
      };
      questionResponses.push(matchingResponse);
    }
    matchingResponse.isCorrect = matchingResponse.isCorrect || submission.isCorrect;
    matchingResponse.students.push(studentId);
    questionResponses.sort((a:IQuestionResponseInfo, b:IQuestionResponseInfo) => {
      if (a.students.length < b.students.length) { return 1; }
      if (a.students.length > b.students.length) { return -1; }
      return 0;
    })
  }

  getQuestionResponses(questionTaskId:string){
    return this.questionResponsesRef.get(questionTaskId);
  }

  revealNamesGlobally(){
    if (confirm('Do you want to show all student names on the screen?')){
      this.isNamesHidden = false;
    }
  }

  hideNamesGlobally(){
    this.isNamesHidden = true;
  }

  setSortBy(mode:string){
    this.sortBy = mode;
    this.applyStudentSorting();
  }

  renderPercentage(num:number){
    if (num || num === 0){
      return Math.round(num*100) + '%';
    }
    return '';
  }

  getStudentQuestionScoreSummary(studentId:string):{score?:number, proportionComplete?:number}{
    if (this.studentPerformanceSummary.has(studentId)){
      return this.studentPerformanceSummary.get(studentId);
    }
    return {};
  }

  recomputeAllStudentSummaryMetrics = _.throttle(() => {
    if (this.activeStudents){
      this.activeStudents.forEach(student => this.recomputeStudentSummaryMetrics(student.uid) );
    }
  }, 1000, {leading:false});

  recomputeStudentSummaryMetrics(studentId:string){
    // score, timespent, propotion complete
    this.recomputeStudentProportionComplete(studentId);
    
    if (this.activeTask.isMarksUsed){
      this.recomputeStudentScoreWithMarks(studentId);    
    }
    else{
      this.recomputeStudentScore(studentId);    
    }
    this.updateQuestionPerformanceSummary();
  }

  isUserGeneratedTask(){
    return this.activeTask && this.activeTask.isUserGeneratedTask;
  }
  
  isItematicTask(){
    return this.activeTask && !this.activeTask.isUserGeneratedTask;
  }

  updateQuestionPerformanceSummary(){
    if (this.activeTask.isMarksUsed){
      this.questions.forEach(question => {
        let responseSummaries = this.questionResponsesRef.get(question.taskId);
        if (responseSummaries){
          responseSummaries.forEach(responseSummary => {
            let studentId = responseSummary.students[0];
            if (studentId){
              let perfSummary = this.studentPerformanceSummary.get(studentId);
              if (perfSummary.questionMarksTally && perfSummary.questionMarksTally[question.taskId]){
                let marksEarned = 0;
                let questionMarksTally = perfSummary.questionMarksTally[question.taskId];
                Object.keys(questionMarksTally).forEach(part => {
                  let marks = questionMarksTally[part].marksEarned;
                  if (isNaN(marks)){
                    console.error('is nan val', question.taskId, part)
                  }
                  else{
                    marksEarned += questionMarksTally[part].marksEarned
                  }
                })
                responseSummary.marksEarned = marksEarned;
                // responseSummary.isCorrect = (perfSummary.score === 1)
              }
            }
          })  ;
          responseSummaries.sort((a, b)=>{
            if (a.marksEarned === b.marksEarned){
              return (a.responseText < b.responseText) ? -1 : 1 ;
            }
            else{
              return (a.marksEarned > b.marksEarned) ? -1 : 1 ;
            }
          });
          this.questionResponsesRef.set(question.taskId, responseSummaries)
        }
      })
    }
  }

  updateStudentPerformanceSummary(studentId:string, prop:string, val:any){
    if (!this.studentPerformanceSummary.has(studentId)){
      this.studentPerformanceSummary.set(studentId, { score:0, proportionComplete:0});
    }
    let summary = this.studentPerformanceSummary.get(studentId);
    summary[prop] = val;
  }

  getQuestionMarksDenoRef = (question:IQuestionSlot) => {
    let marksDenoRef = {};
    if (question.config && question.config.marksAlloc){
      question.config.marksAlloc.forEach(markAlloc => {
        marksDenoRef[markAlloc.part] = markAlloc.marks;
      })
    }
    // else{
    //   marksDenoRef[DEFAULT_MARKS_BUCKET] = question.config.totalMarks || 1
    // }
    return marksDenoRef
  } 
  
  marksUngradedRef = new Map();
  
  recomputeStudentScoreWithMarks(studentId:string){
    // this.recomputeStudentScore(studentId); // testing...
    let totalMarksEarnedAggr = 0;
    let marksTotal = 0;
    let questionMarksTally = {};
    
    this.questions.forEach(question => {

      let performance:IAssignmentTaskSubmission = this.getStudentQuestionPerformance(question.taskId, studentId);
      let currentPart = DEFAULT_MARKS_BUCKET;
      let marksTally = {}
      let marksDenoRef = this.getQuestionMarksDenoRef(question);
      let defaultResponseTotalMarks = 1;
      if (question.config.totalMarks){
        let unallocatedPartMarks = 1*question.config.totalMarks;
        Object.keys(marksDenoRef).forEach(part => {
          unallocatedPartMarks -= marksTally[part];
        })
        defaultResponseTotalMarks = 1*Math.max(0, unallocatedPartMarks);
      }
      let marksUngradedRef = this.marksUngradedRef.get(question.taskId); //this.getQuestionMarksUngradedRef(question);
      if (!marksUngradedRef){
        marksUngradedRef = {};
        this.marksUngradedRef.set(question.taskId, marksUngradedRef)
      }
      let isAllCorrect = true;

      _.each(performance.responses, response => {
        if (response.part){
          currentPart = response.part;
        }
        if (!marksDenoRef[currentPart]){
          // console.warn(question.taskId, currentPart, defaultResponseTotalMarks, marksDenoRef)
          marksDenoRef[currentPart] = defaultResponseTotalMarks;
        }
        if (!marksTally[currentPart]){
          marksTally[currentPart] = {
            numCorrect: 0,
            numTotal: 0,
          }
        }
        if (response.isUngraded){
          marksUngradedRef[currentPart] = true;
        }
        else{
          
          let isCorrect = response.isCorrect;
          let hasTeacherGrading:boolean = false;
          let path = this.getResponseEntryPath(question.taskId, performance.responseText, response.id, response.responseText);
          if (this.teacherResponseGradeOverrides.has(path)){
            hasTeacherGrading = true;
            isCorrect = this.teacherResponseGradeOverrides.get(path).isCorrect;
          }
          if (!response.isCustomGrading || hasTeacherGrading){
            marksTally[currentPart].numTotal ++;
            if (isCorrect){
              marksTally[currentPart].numCorrect ++;
            }
            else{
              isAllCorrect = false;
            }
          }
        }
      });
      
      performance.isCorrect = isAllCorrect;
      let matchingResponse:IQuestionResponseInfo = this.getQuestionResponseSlotByQuestionAndResponseText(question.taskId, performance.responseText);
      if (matchingResponse){
        matchingResponse.isCorrect = isAllCorrect
      }

      questionMarksTally[question.taskId] = marksTally;
      let totalMarksEarned = 0;
      let totalMarks = 0;
      Object.keys(marksDenoRef).forEach(part => {
        let proportion = 0;
        let markTally = marksTally[part];
        if (markTally && markTally.numTotal > 0){
          proportion = markTally.numCorrect / markTally.numTotal
        }

        if (!marksUngradedRef[part]){
          totalMarks += 1*marksDenoRef[part];
        }
        
        let marksEarned = Math.round(4*marksDenoRef[part] * proportion)/4; // quarter marks are easy for teachers to reason about right?        
        totalMarksEarned += marksEarned;
        if (markTally){ markTally.marksEarned = marksEarned; }  
      });
      // console.log('marksDenoRef', marksDenoRef)
      // console.log(question.taskId, totalMarks, marksUngradedRef)
      if (totalMarks){
        if (!question.computedTotalMarks || totalMarks > question.computedTotalMarks){
          question.computedTotalMarks = totalMarks;
        }
      }
      let questionTotalMarks = question.computedTotalMarks ? question.computedTotalMarks : 1;
      marksTotal += 1*questionTotalMarks // should move to using the value from the response level because the teacher may not always have graded the ambiguous stuff

      // should bake these weights in once the questions are initially loaded
      performance.marksEarned = totalMarksEarned;
      totalMarksEarnedAggr += performance.marksEarned
    });
    let score = 0;
    if (marksTotal > 0){
      score = totalMarksEarnedAggr / marksTotal;
    }
    if (studentId === 'QpDoJBbpNxZIcPge8hhOnWHvbkJ2'){
      // console.log('performance details', totalMarksEarnedAggr, marksTotal)
    }
    this.updateStudentPerformanceSummary(studentId, 'score', score);
    this.updateStudentPerformanceSummary(studentId, 'questionMarksTally', questionMarksTally);
    // this.updateStudentPerformanceSummary(studentId, 'questionMarksTallyDebug', {totalMarksEarnedAggr, marksTotal});
  }

  recomputeStudentScore(studentId:string){
    let numCorrect = 0;
    let numTotalScored = 0;
    this.questions.forEach(question => {
      let performance = this.getStudentQuestionPerformance(question.taskId, studentId);
      // if (! (question.config.isOpenResponse && !performance.isTeacherGraded) ){
        numTotalScored++;
        if (performance.isCorrect){
          numCorrect ++;
        }
      // }
    })
    let score=0;
    if (numTotalScored > 0){
      score = numCorrect / numTotalScored;
    }
    this.updateStudentPerformanceSummary(studentId, 'score', score);
  }
  recomputeStudentProportionComplete(studentId:string){
    let numFilled = 0;
    let numTotal = 0;
    this.questions.forEach(question => {
      let performance = this.getStudentQuestionPerformance(question.taskId, studentId);
      numTotal ++;
      if (performance.isFilled){ numFilled ++ ; }
      else if (performance.isCorrect === true || performance.isCorrect === false){ 
        // some questions are incorrectly being marked as not filled, so overriding to mark any question we have data on as "filled"
        numFilled ++ ; 
      }
    });
    let proportionComplete = 0;
    if (numTotal > 0){
      proportionComplete = numFilled / numTotal;
    }
    this.updateStudentPerformanceSummary(studentId, 'proportionComplete', proportionComplete);
  }

  scrollToTop(){
    window.scrollTo({top:0, behavior: "smooth"})
  }

  scrollToQuestion(questionIndex:number, focusOnStudent?:string){
    let el = document.getElementById('question-detail-'+questionIndex) ;
    let scrollConfig;
     if (questionIndex < 5){
       scrollConfig = {behavior: "smooth"}
     }
    el.scrollIntoView(scrollConfig)
    if (focusOnStudent){
      // open the response
    }
   }

  debugLog(t){
    console.log(t);
  }

  debug(t){
    return JSON.stringify(t);
  }



  loadTeacherOverrides(assignmentId:string){
    
    this.afs.collection(
      'assignmentTeacherGradeOverrides', 
      ref => ref.where(
        'assignmentId', '==', assignmentId
      ) 
    ).snapshotChanges()
    .subscribe( payloadContainers => {
      payloadContainers.forEach(payloadContainer => {
        let entry:{path:string, isCorrect:boolean} = <any>payloadContainer.payload.doc.data();
        let path = entry.path;
        this.teacherResponseGradeOverrides.set(path, {
          __id: payloadContainer.payload.doc.id,
          isCorrect: entry.isCorrect
        });
        // console.log('assignmentTeacherGradeOverrides', path)
      })
      this.recomputeAllStudentSummaryMetrics();
    });

    this.afs.collection(
      'assignmentTeacherComments', 
      ref => ref.where(
        'assignmentId', '==', assignmentId
      ) 
    ).snapshotChanges()
    .subscribe( payloadContainers => {
      payloadContainers.forEach(payloadContainer => {
        let entry:{path:string, comment:string} = <any>payloadContainer.payload.doc.data();
        let path = entry.path;
        this.teacherResponseComments.set(path, {
          __id: payloadContainer.payload.doc.id,
          comment: entry.comment
        });
      })
      this.recomputeAllStudentSummaryMetrics();
    });

  }

  setTeacherGradeOverridePersistant(questionTaskId:string, responseTextAggr:string, responseId:string, responseText:string, isCorrect:boolean){
    let path = this.getResponseEntryPath(questionTaskId, responseTextAggr, responseId, responseText);
    let assignmentId = this.assignmentId;
    let taskId = this.activeTask.__id;
    let payload = {
      path,
      assignmentId,
      taskId,
      questionTaskId, 
      responseTextAggr, 
      responseId, 
      responseText, 
      isCorrect
    }
    if (this.teacherResponseGradeOverrides.has(path)){
      this.afs.doc('assignmentTeacherGradeOverrides/'+this.teacherResponseGradeOverrides.get(path).__id).set(payload);
    }
    else{
      this.afs.collection('assignmentTeacherGradeOverrides/').add(payload);
    }
  }

  setTeacherCommentPersistant(questionTaskId:string, responseTextAggr:string, responseId:string, responseText:string, comment:string){
    let path = this.getResponseEntryPath(questionTaskId, responseTextAggr, responseId, responseText);
    let assignmentId = this.assignmentId;
    let taskId = this.activeTask.__id;
    let payload = {
      path,
      assignmentId,
      taskId,
      questionTaskId, 
      responseTextAggr, 
      responseId, 
      responseText, 
      comment
    }
    if (this.teacherResponseComments.has(path)){
      this.afs.doc('assignmentTeacherComments/'+this.teacherResponseComments.get(path).__id).set(payload);
    }
    else{
      this.afs.collection('assignmentTeacherComments/').add(payload);
    }
  }

  getResponseEntryPath(questionTaskId:string, responseTextAggr:string, responseId:string, responseText:string){
    return [this.assignmentId, this.activeTask.__id, questionTaskId, responseTextAggr, responseId, responseText].join('::');
  }
  setResponseGrading(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse, isCorrect:boolean){
    this.setTeacherGradeOverridePersistant(questionTaskId, response.responseText, responsePart.id, responsePart.responseText, isCorrect);
  }

  // setResponseComment(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse, comment:string){
  //   let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
  //   this.teacherResponseComments.set(path, comment);
  // }

  hasResponseComment(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseComments.has(path);
  }

  getResponseComment(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseComments.get(path).comment;
  }

  saveCurrentComment(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse, formTextArea:FormControl){
    this.setTeacherCommentPersistant(questionTaskId, response.responseText, responsePart.id, responsePart.responseText, formTextArea.value);
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    this.teacherResponseCommentEditFlags.set(path, false); // should wait for the save to come through
  }

  isQuestionPartCustomGraded(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseGradeOverrides.has(path);
  }

  openResponseCommentEdit(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse, formTextArea:FormControl){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    if (this.teacherResponseComments.has(path)){
      formTextArea.setValue(this.teacherResponseComments.get(path).comment);
    }
    else{
      formTextArea.reset()
    }
    return this.teacherResponseCommentEditFlags.set(path, true);
  }

  closeResponseCommentEdit(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    this.teacherResponseCommentEditFlags.set(path, false);
  }

  hasResponseCommentEdit(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseCommentEditFlags.has(path) && this.teacherResponseCommentEditFlags.get(path);
  }

  checkAssignmentOpen(){
    if (this.activeAssignment){
      return this.activeAssignment.isDirectStart || this.checkAssignmentOpenDateRange(this.activeAssignment);
    }
  }
  checkAssignmentOpenDateRange(assignment:IAssignment){
    return false;
  }

  toggleStudentIsolate(studentId:string){
    if (this.isolatedStudent === studentId){
      this.isolatedStudent = null;
    }
    else{
      this.isolatedStudent = studentId;
    }
  }
  checkStudentIsolate(studentId:string, strictInverse:boolean=false){
    if (strictInverse){
      if (this.isolatedStudent === null){
        return false;
      }
      else{
        // console.log(this.isolatedStudent === studentId, this.isolatedStudent, studentId)
        // return false;
        return !(this.isolatedStudent === studentId);    
      }
    }
    else{
      return this.isolatedStudent === studentId;
    }
  }
  checkResponseValidStudentIsolate(response:IQuestionResponseInfo){
    if (this.isolatedStudent === null){
      return true;
    }
    else{
      if (response && response.students){
        return (response.students.indexOf(this.isolatedStudent) !== -1);
      }
    }
    return false;
  }

  getQuestionPartCustomIsCorrectMatch(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse, isCorrect:boolean){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    if (this.teacherResponseGradeOverrides.has(path)){
      return this.teacherResponseGradeOverrides.get(path).isCorrect === isCorrect;
    }
    else{
      if (responsePart.isCustomGrading){
        return false;
      }
      else{
        return responsePart.isCorrect === isCorrect;
      }
    }
  }

  isQuestionPartCommented(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseComments.has(path);
  }

  getQuestionPartCommented(questionTaskId:string, response:IQuestionResponseInfo, responsePart:ISubmResponse){
    let path = this.getResponseEntryPath(questionTaskId, response.responseText, responsePart.id, responsePart.responseText);
    return this.teacherResponseComments.get(path).comment || '';
  }

  sanitizeArray(arr:any[]){
    // console.log(arr[0].content);
    return arr
  }
  
  openCustomGradingModal(questionTaskId:string, response:IQuestionResponseInfo){
    this.currentCustomGradingFocus.questionTaskId = questionTaskId
    this.currentCustomGradingFocus.response = response;
    let responses = this.getQuestionResponses(questionTaskId);
    this.currentCustomGradingFocus.responseIndex = responses.indexOf(response);
    this.isCustomGradingModalOpen = true;
    this.responseCommentEdits.forEach(form => {
      form.reset();
    })
    this.updateCustomModalScreenshot();
  }

  getQuestionScreenshotById(questionTaskId:string){
    let questionSlot = this.questionsRef.get(questionTaskId);
    try {
      return questionSlot.config.screenshot.url
    }
    catch(e){
      // console.warn('This question does')
    }
  }

  gotoPrevCustomModalResponse(){
    if (this.currentCustomGradingFocus.responseIndex > 0){
      let responses = this.getQuestionResponses(this.currentCustomGradingFocus.questionTaskId);
      let i = this.currentCustomGradingFocus.responseIndex -= 1;
      this.currentCustomGradingFocus.response = responses[i];
      this.updateCustomModalScreenshot();
    }
  }

  gotoNextCustomModalResponse(){
    let responses = this.getQuestionResponses(this.currentCustomGradingFocus.questionTaskId);
    if (this.currentCustomGradingFocus.responseIndex < (responses.length - 1)){
      let i = this.currentCustomGradingFocus.responseIndex += 1;
      this.currentCustomGradingFocus.response = responses[i];
      this.updateCustomModalScreenshot();
    }
  }

  updateCustomModalScreenshot(){
    var url = null;
    let questionTaskId = this.currentCustomGradingFocus.questionTaskId;
    if (this.currentCustomGradingFocus.response.students.length === 1){
      let student = this.currentCustomGradingFocus.response.students[0]
      this.focusOnQuestionStudentResponseImage(questionTaskId, student)
      url = this.focusedQuestionStudentResponse.get(questionTaskId).studentImage;
    }
    if (!url){
      url = this.getQuestionScreenshotById(questionTaskId);
    }
    this.currentCustomGradingFocus.screenshot = url
  }

  closeCustomGradingModal(){
    this.isCustomGradingModalOpen = false;
  }


  getStudentQuestionPerformanceComment(questionTaskId:string, studentId:string){
    let questionPerformance:IAssignmentTaskSubmission = this.getStudentQuestionPerformance(questionTaskId, studentId);
    if (questionPerformance && questionPerformance.gradingsLog){
      let i = questionPerformance.gradingsLog.length-1;
      let lastEntry = questionPerformance.gradingsLog[i];
      return lastEntry.comment;
    }
  }

  getPerformanceGrading(questionPerformance:{isOpenResponse?:boolean, isTeacherGraded?:boolean, isFilled?:boolean, isCorrect?:boolean}, isOutline:boolean, isQuestionOpenResponse:boolean){
    let prefix;
    if (isOutline){
      prefix = 'student-score-outline-';
    }
    else{
      prefix = 'student-score-';
    }
    if (questionPerformance.isOpenResponse || (questionPerformance.isFilled && isQuestionOpenResponse)){
      if (questionPerformance.isTeacherGraded){
        if (questionPerformance.isCorrect){
          return prefix+'good';
        }
        else{
          return prefix+'bad';
        }
      }
      else{
        return prefix+'ungraded';
      }
    }
    else if (questionPerformance.isCorrect === true){
      return prefix+'good';
    }
    else if (questionPerformance.isCorrect === false){
      return prefix+'bad';
    }
  }

  getStudentQuestionPerformanceStatus(questionTaskId:string, studentId:string, isQuestionOpenResponse?:boolean){
    let questionPerformance:IAssignmentTaskSubmission = this.getStudentQuestionPerformance(questionTaskId, studentId);
    return this.getPerformanceGrading(questionPerformance, false, isQuestionOpenResponse);
  }

  focusedQuestionStudentResponse = new Map();
  
  clearQuestionStudentResponseImage(questionTaskId:string){
    this.focusedQuestionStudentResponse.delete(questionTaskId);
  }
  isFocusedQuestionStudent(questionTaskId:string, studentId:string){
    let focus = this.focusedQuestionStudentResponse.get(questionTaskId);
    if (focus && focus.studentId === studentId){
      return true;
    }
  }
  
  focusOnNextQuestionStudentResponseImage(questionTaskId:string){
    let focus = this.focusedQuestionStudentResponse.get(questionTaskId);
    let newIndex = 0;
    if (focus){
      newIndex = Math.min(focus.studentIndex+1, this.activeStudents.length-1);
    }
    this.focusOnQuestionStudentResponseImage(questionTaskId, this.activeStudents[newIndex].uid);
  }
  focusOnPrevQuestionStudentResponseImage(questionTaskId:string){
    let focus = this.focusedQuestionStudentResponse.get(questionTaskId);
    if (focus){
      let newIndex = Math.max(focus.studentIndex-1, 0);
      this.focusOnQuestionStudentResponseImage(questionTaskId, this.activeStudents[newIndex].uid);
    }
  }

  focusOnQuestionStudentResponseImage(questionTaskId:string, studentId:string){
    let studentImage = this.getStudentQuestionPerformanceImage(questionTaskId, studentId);
    let studentIndex = this.getActiveStudentIndexById(studentId);
    this.focusedQuestionStudentResponse.set(questionTaskId, {
      studentId,
      studentIndex,
      studentImage
    });
  }

  getActiveQuestionStudentIndex(questionTaskId:string){
    let focus = this.focusedQuestionStudentResponse.get(questionTaskId);
    if (focus){
      return focus.studentIndex;
    }
    return 'BLANK'
  }

  getActiveStudentIndexById(studentId:string){
    let index = -1;
    this.activeStudents.forEach( (student:IUser, i:number) => {
      if (student.uid === studentId){
        index = i;
      }
    });
    return index
  }

  getStudentQuestionPerformanceImage(questionTaskId:string, studentId:string):string{
    let questionPerformance:IAssignmentTaskSubmission = this.getStudentQuestionPerformance(questionTaskId, studentId);
    if (questionPerformance){
      try {
        return questionPerformance.lastScreenshot;
        // return questionPerformance.submissionLog[0].screenshotUrl;
      }
      catch(e){
        return null;
      }  
    }
    return null;
  }

  getStudentQuestionPerformance(questionTaskId:string, studentId:string):IAssignmentTaskSubmission{
    let questionPerformance:IAssignmentTaskSubmission;
    let questionRef = this.submissions.get(questionTaskId);
    if (questionRef){
      questionPerformance = questionRef.get(studentId);
      if (questionPerformance){
        return questionPerformance;
      }
    }
    // console.log(!!questionPerformance, !!questionPerformance, questionTaskId, studentId)
    return {isFilled:false}
  }

  loadTask(taskId:string, then:Function){
    this.questions = []; // this assumes only one task per assignment... this is likely to change

    this.boosterService
      .getBoostersAssociatedToTask(taskId)
      .then(boosterPacks => {
        this.boosterPacksAssoc = boosterPacks;
      })
    
    this.afs
      .doc('tasks/'+taskId)
      .get()
      .toPromise()
      .then( res => {
        const task:ITask = <ITask>res.data();
        this.activeTask = task;
        this.activeTask.__id = taskId;
        // if ('DEBUGFLAG'){
        //   this.activeTask.questions = ['9qXmLcVo2PA03ZFxFRHC']
        // }
        this.activeTask.isMarksUsed = true; // hard override for now... best option for most cases
        const customTaskSetsLoaders = [];
        const customTaskSetsIds = [];
        if (task.customTaskSetId){
          customTaskSetsIds.push(task.customTaskSetId);
        }
        customTaskSetsIds.forEach( customTaskSetId => {
          customTaskSetsLoaders.push(
            this.tcs.loadCustomTaskSetToCache(customTaskSetId)
          );
        })
        Promise.all(customTaskSetsLoaders).then( () => {
          this.initTaskQuestions(task.questions, then)
        })
      })

  }

  initTaskQuestions(questionTaskIds:string[], then:Function){
    if (questionTaskIds){
      let questionIndexes = Object.keys(questionTaskIds);
      questionIndexes.forEach(i=> {
        let question = questionTaskIds[i]
        let questionSlot:IQuestionSlot = {
          taskId: question,
          config: <ITask>{},
          responses: new Map(),
          responsesSummarized: [],
          computedTotalMarks: 1,
        }
        this.questions.push(questionSlot);
        this.questionsRef.set(question, questionSlot);
        this.loadQuestionConfig(questionSlot); 
      })
    }
    then()
  };

  getCustomTaskSetQuestion(questionTaskId:string){
    return this.tcs.getQuestionByTaskId(questionTaskId);
  }


  loadQuestionConfig(questionSlot:IQuestionSlot){
    this.afs.doc('tasks/'+questionSlot.taskId).get().toPromise().then( res => {
      let data = res.data();
      // console.log('loadQuestionConfig', questionSlot.taskId, data&&true);
      // console.log('loadQuestionConfig', this.tcs.getQuestionByTaskId(questionSlot.taskId))
      if (data){
        questionSlot.config = <ITask>res.data();
        this.recomputeAllStudentSummaryMetrics(); // this is getting called MULTIPLE times... much more expensive than it needs to be  
      }
    })
  }

  getForceOpenStatus(questionTaskId:string, studentId:string){
    return this.forceOpenSubmGradingMap.get(questionTaskId+'/'+studentId);
  }

  startForceOpen(questionTaskId:string, studentId:string){
    this.forceOpenSubmGradingMap.set(questionTaskId+'/'+studentId, true);
  }

  endForceOpen(questionTaskId:string, studentId:string){
    this.forceOpenSubmGradingMap.set(questionTaskId+'/'+studentId, false);
  }

  checkIfTaskSubmGradingOpen(question:{config:ITask, taskId:string}, studentId:string){
    let performance = this.getStudentQuestionPerformance(question.taskId, studentId);
    return performance.isFilled && (this.getForceOpenStatus(question.taskId, studentId) || (question.config.isOpenResponse && !performance.isTeacherGraded));
  }

  isShowingStudentNames(questionTaskId:string){
    return this.showingStudentNamesByQuestion.get(questionTaskId);
  }

  setShowingStudentName(questionTaskId:string, status:boolean){
    this.showingStudentNamesByQuestion.set(questionTaskId, status);
  }

  markAsOpenQuestion(question:IQuestionSlot, status:boolean){
    if (confirm('Turn custom marking ' + (status?'on':'off') +'?' )){
      this.afs.doc('tasks/'+question.taskId).update({isOpenResponse: status}).then( ()=> {
        question.config.isOpenResponse = status;
      })
    }
  }

  applyTeacherGrading(questionTaskId:string, studentId:string, grading:ITeacherGradingFormlet){
    let performance = this.getStudentQuestionPerformance(questionTaskId, studentId);
    let assignmentTaskId:string = performance.__id;
    let comment = grading.comment;
    let isCorrect = grading.isCorrect;
    this.afs.doc('assignmentTaskSubmissions/'+assignmentTaskId).update({
      isCorrect,
      isTeacherGraded: true,
      gradingsLog: firestore.FieldValue.arrayUnion({
          timestamp: (new Date()).toISOString(),
          isCorrect,
          comment
      })
    }).then( value => {
      this.endForceOpen(questionTaskId, studentId);
    })
  }

}
