import { Component, OnInit } from '@angular/core';
import { IBoosterPackSummary } from '../../data/cambridge/types';
import { AngularFirestore } from '@angular/fire/firestore';
import { serverTimestamp } from '../../data/timestamp';
import { BoosterpacksService, IBoosterPackPerfSummary } from '../boosterpacks.service';
import { Router } from '@angular/router';

interface IBoosterPackPerf {
  percentage: number, 
  bpPerfSummary: IBoosterPackPerfSummary
}

@Component({
  selector: 'student-booster-packs',
  templateUrl: './student-booster-packs.component.html',
  styleUrls: ['./student-booster-packs.component.scss']
})
export class StudentBoosterPacksComponent implements OnInit {

  boosterPacks:IBoosterPackSummary[];
  boosterPackCheckpointsCount:Map<string, number> = new Map();
  boosterPackRef:Map<string, IBoosterPackSummary> = new Map();
  boosterPackPerfRef:Map<string, IBoosterPackPerf> = new Map();

  constructor(
    private afs: AngularFirestore,
    private bps: BoosterpacksService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.bps
      .getBoosterPacks()
      .then((boosterPackSummaries) =>{
        this.boosterPacks = boosterPackSummaries;
        this.boosterPacks.forEach(boosterPack => {
          let boosterPackId = boosterPack.__id;
          this.boosterPackRef.set(boosterPackId, boosterPack);
          this.boosterPackCheckpointsCount.set(boosterPackId, 0);
          this.bps
            .getBoosterPackCheckpoints(boosterPackId)
            .subscribe(checkpoints => this.boosterPackCheckpointsCount.set(boosterPackId, checkpoints.length))
        });
        this.bps
          .getBoosterPackPerfSummaries()
          .then(boosterPackPerfSummaries => {
            boosterPackPerfSummaries.forEach(bpPerfSummary => {
              let percentage = 0;
              if (bpPerfSummary.totalStars > 0){
                percentage = bpPerfSummary.numStars / bpPerfSummary.totalStars;
              }
              this.boosterPackPerfRef.set(bpPerfSummary.boosterPackId, {
                percentage,
                bpPerfSummary
              });
            })
          })
      })
  }

  getNumBoosterPackCheckpoints(boosterPackId:string){
    return this.boosterPackCheckpointsCount.get(boosterPackId)
  }

  getPackPerfPercentage(boosterPackId:string){
    if (this.boosterPackPerfRef.has(boosterPackId)){
      return (this.boosterPackPerfRef.get(boosterPackId).percentage*100)+'%';
    }
    return '0%';
  }

  getPackTitleImage(pack:IBoosterPackSummary){
    return pack.titleImage;
  }

  openPack(boosterPackId){
    this.router.navigate([
      'student',
      'booster',
      boosterPackId
    ])
  }

  transferToFirebase(){
    return;
    // temp
    // const collections = {
    //   BOOSTER_PACKS: 'boosterPacks',
    //   TASKS: 'tasks',
    //   BOOSTER_PACK_ITEMS: 'boosterPackItems'
    // }
    // BOOSTER_PACKS.forEach(boosterPack => {
    //   // Define packs on the database and get creation ID
    //   this.afs.collection(collections.BOOSTER_PACKS)
    //     .add({
    //       slug: boosterPack.id,
    //       caption: boosterPack.caption,
    //       bgImageUrl: boosterPack.bgImageUrl,
    //       curriculumId: 'cambridge-stage6',
    //       startingItems: [],
    //     })
    //     .then((res)=>{
    //       const boosterPackId = res.id;
    //       console.log('boosterPackId', boosterPackId);
    //       boosterPack.items.forEach(item =>{
    //         // Define tasks for each item and get creation ID
    //         this.afs.collection(collections.TASKS)
    //         .add({
    //           isPublic: true,
    //           name: item.caption,
    //           type: 'lesson',
    //           timeCreated: serverTimestamp(),
    //           itematicLessonId: '',
    //           itematicEngineVersion: item.itematicEngineVersion,
    //           itematicItemVersionId: item.itematicItemVersionId,
    //           numThumbsDown: 0,
    //           numThumbsUp: 0,
    //         })
    //         .then((res)=>{
    //           const taskId = res.id;
    //           console.log('taskId', taskId);
    //           // Create boosterPackItem definition using the task ID (dont store Itematic ID at this level). 
    //           // also feed in the parent booster pack ID
    //           this.afs.collection(collections.BOOSTER_PACK_ITEMS)
    //             .add({
    //               taskId,
    //               boosterPackId,
    //               name: item.caption,
    //               humanOrdering: item.id,
    //               timeCreated: serverTimestamp(),
    //               x: Math.floor(Math.random()*10),
    //               y: Math.floor(Math.random()*10),
    //               unlockedBy: [],
    //             })
    //         });
    //       })
    //     })
    // });
      
  }

}
