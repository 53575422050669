import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ITask } from '../../data/collections/tasks.types';
import { CURRICULUM_MODEL } from '../../data/cambridge/curriculum';
import { Router } from '@angular/router';
import { ICurriculumSubStrand, ICurriculumAssessment, ICurriculumLearningObjective } from '../../data/cambridge/types';
import { AssignmentService } from '../assignment.service';

type TaskId = string;
type StrandCode = string;
type SubStrandCode = string;

@Component({
  selector: 'assignment-selector',
  templateUrl: './assignment-selector.component.html',
  styleUrls: ['./assignment-selector.component.scss']
})
export class AssignmentSelectorComponent implements OnInit {

  @Input() classroomId:string = '_';
  @Input() viewOnly:boolean = false;
  @Output() create = new EventEmitter<ITask>();

  isFormatSelected:boolean;
  activeFormat:string;
  curriculumData = CURRICULUM_MODEL;
  activeStrand;
  DEBUG = false;
  assessmentCompletionMap:Map<TaskId, boolean> = new Map();
  strandCompletionMap:Map<StrandCode, boolean> = new Map();
  subStrandCompletionMap:Map<SubStrandCode, boolean> = new Map();
  strandProgressMap:Map<StrandCode, string> = new Map();
  subStrandProgressMap:Map<SubStrandCode, string> = new Map();
  
  constructor(
    private router:Router,
    private assignmentService:AssignmentService,
  ) { }

  ngOnInit() {
    // this.curriculumData.strands[0].code
    // this.curriculumData.strands[0].learningObjectives
    // this.curriculumData.strands[0].learningObjectives[0].assessments
    // this.ensureCurriculumStrandIds();
    this.loadAllPreviouslyRunAssessments();
  }

  private loadAllPreviouslyRunAssessments(){
    this.assignmentService
      .loadAllPreviouslyRunAssignments(this.classroomId)
      .then(assignments => {
        const taskIds = assignments.map(assignment => assignment.tasks[0]);
        this.updateAssessmentCompletionMap(taskIds)
      })
  }

  private updateAssessmentCompletionMap(taskIds:string[]){
    // console.log('taskIds', this.classroomId, taskIds)
    taskIds.forEach(taskId => this.assessmentCompletionMap.set(taskId, true) );
    // to do: traverse the hierarchy
    this.curriculumData.strands.forEach(strand=> {
      if (!strand.isLocked){
        const strandCode = strand.code;
        let substrandsAssigned = 0;
        let substrandsTotal = 0;
        strand.learningObjectives.forEach(subStrand=> {
          if (subStrand.isLive){
            const subStrandCode = subStrand.code;
            let isLoComplete = false;
            let asmtAssigned = 0;
            let asmtTotal = 0;
            subStrand.assessments.forEach(assessment=> {
              asmtTotal ++;
              if (this.assessmentCompletionMap.get(assessment.taskId)){
                asmtAssigned ++;
              }
            });
            if(asmtAssigned === asmtTotal){
              isLoComplete = true;
              this.subStrandCompletionMap.set(subStrandCode, true);
            }
            substrandsAssigned += asmtAssigned;
            substrandsTotal += asmtTotal;
            this.subStrandProgressMap.set(subStrandCode, Math.round(100*asmtAssigned/asmtTotal) + '%');
          }
        });
        if(substrandsAssigned === substrandsTotal){
          this.strandCompletionMap.set(strandCode, true);
        }
        this.strandProgressMap.set(strandCode, Math.round(100*substrandsAssigned/substrandsTotal) + '%');
      }
    })
    // [0].learningObjectives[0].assessments
  }

  getStrandProgress(strandCode:StrandCode){
    if (this.strandProgressMap.has(strandCode)){
      return this.strandProgressMap.get(strandCode);
    }
    return ''
  }

  isStrandPreviouslyAssigned(strandCode:StrandCode){
    return this.strandCompletionMap.get(strandCode);
  }

  getSubStrandProgress(subStrandCode:SubStrandCode){
    if (this.subStrandProgressMap.has(subStrandCode)){
      return this.subStrandProgressMap.get(subStrandCode);
    }
    return ''
  }

  isSubStrandPreviouslyAssigned(subStrandCode:SubStrandCode){
    return this.subStrandCompletionMap.get(subStrandCode);
  }

  isAssessmentPreviouslyAssigned(taskId:string){
    return this.assessmentCompletionMap.get(taskId);
  }

  selectFormat(format:string){
    this.activeFormat = format;
    this.checkForValidContents();
  }
  isSelectedFormat(format:string){
    return this.activeFormat === format;
  }

  selectStrand(strand:ICurriculumSubStrand){
    if (this.isSelectedStrand(strand)){
      this.activeStrand = null;
    }
    else{
      this.activeStrand = strand;
      this.checkForValidContents();
    }
  }

  validLoMap:Map<ICurriculumLearningObjective, boolean> = new Map();

  private checkForValidContents(){
    this.curriculumData.strands.forEach(strand => {
      strand.learningObjectives.forEach(learningObjective => {
        let containsAnyValid:boolean = false;
        learningObjective.assessments.forEach(assessment => {
          containsAnyValid = containsAnyValid || this.isValidForMode(assessment);
        });
        this.validLoMap.set(learningObjective, containsAnyValid);
      })
    })
  }

  isValidLOSection(learningObjective:ICurriculumLearningObjective){
    return (learningObjective.isLive && this.validLoMap.get(learningObjective));
  }

  isSelectedStrand(strand:ICurriculumSubStrand){
    return (this.activeStrand === strand);
  }

  isValidForMode(assessment:ICurriculumAssessment){
    // show everything in headsdown, but for heads up, only show stuff that is known to work in that format
    if (this.activeFormat === 'HEADSDOWN'){
      return true;
    }
    else if (this.activeFormat === 'HEADSUP'){
      return assessment.isHeadsUp;
    }
    return true;
  }


  generatePreviewTaskUrl(taskId:string){
    const PLACEHOLDER:string = '_';
    let url = this.router.createUrlTree([
      'student',
      'classroom',
      this.classroomId,
      'assignment',
      PLACEHOLDER,
      'task',
      taskId
    ]).toString();
    // console.log('url', url)
    return url;
  }

  createTask(taskRef:ICurriculumAssessment){
    this.create.emit({
      __id: taskRef.taskId,
      name: taskRef.caption, 
      isHeadsUp: (this.activeFormat === 'HEADSUP')
    });
  }

}
