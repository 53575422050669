import * as _ from 'lodash';
import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { CosmeticCategory, ICosmeticInfo } from 'src/app/data/collections/heads-up-lobby';
import { CosmeticInfoList } from 'src/app/ui-avatar-displays/util/PlayerModels/CosmeticInfoList';

export interface ICosmeticSetting {
  cosmeticType: CosmeticCategory;
  config: ICosmeticInfo;
}

export interface IMenuCategory {
  slug: CosmeticCategory;
  caption: string;
  icon: string;
}

@Component({
  selector: 'avatar-cosmetic-menu',
  templateUrl: './avatar-cosmetic-menu.component.html',
  styleUrls: ['./avatar-cosmetic-menu.component.scss']
})

export class AvatarCosmeticMenuComponent implements OnInit, OnChanges {
  @Output() changeCosmetic: EventEmitter<ICosmeticSetting> = new EventEmitter();
  @Output() changeCosmeticCategory: EventEmitter<{category: CosmeticCategory}> = new EventEmitter();

  @Input() cosmeticInfo: CosmeticInfoList;
  @Input() update: number;

  activeMenuCategory: string;
  menuCategories: IMenuCategory[] = [
    { slug: CosmeticCategory.SKIN, caption: 'skin', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-color.png?alt=media&token=f05a52ec-42b1-48aa-a8fa-7fefcb0d7ec7' },

    // { slug: CosmeticCategory.HAT, caption: 'hat', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-hats.png?alt=media&token=b31bf0ae-9a72-4ed4-9a4a-33e3ee182050'},
    { slug: CosmeticCategory.HAIR, caption: 'hair', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-hair.png?alt=media&token=b2184478-6123-4680-9270-77b0403db023' },
    { slug: CosmeticCategory.HAIR_COLOR, caption: 'hair color', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-color.png?alt=media&token=f05a52ec-42b1-48aa-a8fa-7fefcb0d7ec7'},

    // { slug: CosmeticCategory.ACCESSORY_HEAD, caption: 'accessories', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-glasses.png?alt=media&token=2fa8c253-622c-45ee-ae25-1f6cd4e28b9f'},
    // ** NOTE ** ACCESSORY_HEAD will include Glasses, Beard, Tattoo and Ears

    // { slug: CosmeticCategory.GLASSES, caption: 'glasses', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-glasses.png?alt=media&token=2fa8c253-622c-45ee-ae25-1f6cd4e28b9f'},
    // { slug: CosmeticCategory.BEARD, caption: 'beard', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-mustache.png?alt=media&token=ccd70a49-c532-413d-b783-a933898300d0'},
    // { slug: CosmeticCategory.TATTOO, caption: 'tattoo'},
    // { slug: CosmeticCategory.EARS, caption: 'earrings'},
    { slug: CosmeticCategory.SHIRT, caption: 'shirt', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-shirt.png?alt=media&token=1e5ffa39-020e-4faf-8f58-cffcdd365127' },
    { slug: CosmeticCategory.PANTS, caption: 'pants', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-pants.png?alt=media&token=68fd562b-357b-4046-984e-029c94560704'},
    // { slug: CosmeticCategory.SHOES, caption: 'shoes', icon: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Favatar-menu-shoes.png?alt=media&token=6c8d575a-55d5-42f0-a313-9a7f6011c1c7' },

    // { slug: CosmeticCategory.ACCESSORY_BODY, caption: 'accessories', icon: }
    // ** NOTE ** ACCESSORY_BODY will include Back, Held, Neck and Belt

    // { slug: CosmeticCategory.BACK, caption: 'back', icon: }
    // { slug: CosmeticCategory.HELD, caption: 'held item', icon: }
    // { slug: CosmeticCategory.NECK, caption: 'necklace', icon: }
    // { slug: CosmeticCategory.BELT, caption: 'belt', icon: }
  ];

  menuOptions: Map<string, ICosmeticSetting[]> = new Map();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    let dataFromInfo: ICosmeticSetting[] = this.cosmeticInfo.toCosmeticMenuSettings();

    this.menuOptions = new Map();

    dataFromInfo.forEach(entry => {
      if (!this.menuOptions.has(entry.cosmeticType)) {
        this.menuOptions.set(entry.cosmeticType, []);
      }
      this.menuOptions.get(entry.cosmeticType).push(entry);
    });
  }

  getActiveMenuOptions() {
    if (this.activeMenuCategory) {
      return this.menuOptions.get(this.activeMenuCategory);
    }
  }

  paginateMenuOptions() {
    // backwards / forwards
  }

  changeCategory(slug: CosmeticCategory) {
    this.activeMenuCategory = slug;
    this.changeCosmeticCategory.emit({category: slug});
  }

  selectCosmetic(a: ICosmeticSetting) {
    console.log('selectCosmetic', a);
    this.changeCosmetic.emit(a);
  }

}
