import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'marker-coord-overvie',
  templateUrl: './marker-coord-overvie.component.html',
  styleUrls: ['./marker-coord-overvie.component.scss']
})
export class MarkerCoordOvervieComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
