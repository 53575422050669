import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { WhitelabelService, WLST_TRIAL_ID, WLST_INST_SCOPE_ID } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';
import { renderEmailFromUsername } from '../../data/accounts/pseudo-email-usernames';

interface IAccountType { 
  id: string,
  caption: string,
}
enum AccountTypes { // keep in lower case to match db covention
  TEACHER = 'teacher',
  PARENT  = 'parent',
  STUDENT = 'student',
}

type UserFields = 'email' | 'password' | 'fullname' | 'agreeTerms' | 'agreeIntComms' | 'agreeExtComms';
type FormErrors = { [u in UserFields]: string };

@Component({
  selector: 'user-registration-form',
  templateUrl: './user-registration-form.component.html',
  styleUrls: ['./user-registration-form.component.scss']
})
export class UserRegistrationFormComponent implements OnInit, OnDestroy {

  currentAccountType:IAccountType;
  userForm: FormGroup;
  newUser = false; // to toggle login or signup form
  passResetInProgress = false; // set to true when password reset is triggered
  passResetComplete = false; // set to true when password reset is triggered
  passResetFail = false; // set to true when password reset is triggered

  private signinSub:Subscription;
  
  accountTypes:IAccountType[] = [
    {id:AccountTypes.TEACHER, caption:'Teacher'},
    // {id:AccountTypes.PARENT, caption:'Parent'},
    {id:AccountTypes.STUDENT, caption:'Student'},
  ]
  
  formErrors: FormErrors = {
    'fullname': '',
    'email': '',
    'password': '',
    'agreeTerms': 'required',
    'agreeIntComms': '',
    'agreeExtComms': '',
  };
  validationMessages = {
    'fullname': {
      'required': 'Name is required.',
      'minlength': 'Name is too short.',
    },
    'email': {
      'required': 'Email is required.',
      'email': 'Email must be a valid email.',
      'minlength': 'Email is too short.',
    },
    'password': {
      'required': 'Password is required.',
      'pattern': 'Password must include at least one letter and one number (and cannot contain any special symbols).',
      'minlength': 'Password must be at least 6 characters long.',
      'maxlength': 'Password cannot be more than 25 characters long.',
    },
  };

  constructor(
    private fb: FormBuilder, 
    private auth: AuthService,
    private router: Router,
    public whiteLabel: WhitelabelService
  ) { 
    // this.currentAccountType = this.accountTypes[0];
  }

  ngOnInit() {
    this.buildForm();
    this.signinSub = this.auth.user.subscribe( userInfo => {
      if (userInfo){
        if (userInfo.uid && !userInfo.ui){
          this.router.navigate('signup/sso'.split('/'));
        }
      }
    })
  }

  ngOnDestroy() {
    this.signinSub.unsubscribe();
  }

  currentSignupError;
  signup() {
    this.currentSignupError = null;
    let email = this.userForm.value['email'];
    let password = this.userForm.value['password'];
    if (!email){ alert('Please enter your email address'); }
    if (!password){ alert('Please enter a password'); }
    email = email.trim();
    password = password.trim();
    let ui:string = AccountTypes.TEACHER;
    if (this.currentAccountType){
      ui = this.currentAccountType.id
    }
    let agreementsMap:Map<string, boolean> = new Map();
    agreementsMap.set('agreeIntComms', this.userForm.value['agreeIntComms']);
    agreementsMap.set('agreeTerms', this.userForm.value['agreeTerms']);
    
    return this.auth.emailSignUp(
      email, 
      password,
      this.userForm.value['fullname'],
    )
    .then ( () => {
      return this.auth.updateUserAgreements(agreementsMap)
    })
    .then( () => {
      return this.auth
        .updateUserUi(
            ui, 
            this.whiteLabel.getSiteText(WLST_TRIAL_ID),
            this.whiteLabel.getSiteText(WLST_INST_SCOPE_ID)
          )
    })
    .then( () => {
      return this.auth.navigateToUserUiHome();
    })
    .catch(error => this.handleSignupError(error));
  }

  isEmailEntered(){
    return this.userForm.value['email'] && true;
  }

  resetPassword(){
    this.passResetInProgress = true;
    this.passResetComplete = false;
    this.passResetFail = false;
    let studentEmail = renderEmailFromUsername(this.userForm.value['email']);
    this.auth.resetPassword(studentEmail)
      .then(() => {
        this.passResetComplete = true;
        this.passResetInProgress = false;
      })
      .catch((reason) => {
        this.passResetFail = true;
        this.passResetInProgress = false;
      })
  }

  handleSignupError(error){
    this.currentSignupError = error.code;
    console.log('signup error', error);
  }

  buildForm() {
    this.userForm = this.fb.group({
      'fullname': ['', [
        Validators.required,
        Validators.minLength(3),
      ]],
      'email': ['', [
        Validators.required,
        Validators.email,
        Validators.minLength(5),
      ]],
      'password': ['', [
        Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'),
        Validators.minLength(6),
        Validators.maxLength(25),
      ]],
      'agreeTerms': [false, [
        Validators.requiredTrue,
      ]],
      'agreeIntComms': [false, [
      ]],
      'agreeExtComms': [false, [
      ]],

    });
    this.userForm.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  // Updates validation state on form changes.
  onValueChanged(data?: any) {
    if (!this.userForm) { return; }
    const form = this.userForm;
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && (field === 'email' || field === 'password')) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key) ) {
                this.formErrors[field] += `${(messages as {[key: string]: string})[key]} `;
              }
            }
          }
        }
      }
    }
  }

  selectAccountType(accountType:IAccountType){
    this.currentAccountType = accountType;
  }
  isAccountTypeSelected(accountType:IAccountType){
    return (accountType === this.currentAccountType);
  }
  isStudentAccountTypeSelected(){
    if (this.currentAccountType){
      return this.currentAccountType.id === AccountTypes.STUDENT;
    }
  }

}





