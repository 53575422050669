import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'anon-id',
  templateUrl: './anon-id.component.html',
  styleUrls: ['./anon-id.component.scss']
})
export class AnonIdComponent implements OnInit {

  @Input()  submitMessage : string;
  @Output() submit = new EventEmitter();

  profileForm = new FormGroup({
    displayName: new FormControl('', [
      Validators.required,
      Validators.minLength(4)]),
    email: new FormControl('', [
      Validators.required,
      Validators.email,
      Validators.minLength(4)]),
  });

  constructor() { }

  ngOnInit() {
  }

  onSubmit(){
    console.log('onSubmit',this.profileForm.value)
    this.submit.emit(this.profileForm.value)
  }

}
