import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { IContentElementMcq, IContentElementMcqOption, QuestionState, IEntryStateMcq } from '../models';
import { indexOf } from '../services/util';
import { element } from '@angular/core/src/render3';




@Component({
  selector: 'element-render-mcq',
  templateUrl: './element-render-mcq.component.html',
  styleUrls: ['./element-render-mcq.component.scss']
})
export class ElementRenderMcqComponent implements OnInit, OnChanges {

  @Input() element:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  lastTrackedQuestionState;
  isStarted:boolean;
  isShowSolutionApplied:boolean;

  constructor() { }

  currentSelections:IContentElementMcqOption[] = [];

  ngOnInit() {

  }

  ngOnChanges(){
    if (this.lastTrackedQuestionState !== this.questionState){
      this.lastTrackedQuestionState = this.questionState;
      if (this.questionState){
        this.handleNewState();
      }
    }
    if (this.isShowSolution && !this.isShowSolutionApplied){
      this.showSolution()
      this.isShowSolutionApplied = true;
    }
  }

  showSolution(){
    this.deselectAllOptions();
    this.element.options.forEach(option => {
      if (option.isCorrect){
        this._selectOption(option);
      }
    })
  }

  handleNewState(){
    const entryState:IEntryStateMcq = this.questionState.get(this.element.entryId);
    if (entryState){
      this.isStarted = entryState.isStarted;
      entryState.selections.forEach(selectionState => {
        let i = selectionState.i;
        this.selectOption(this.element.options[i]);
      })
    }
  }

  isSelected(option:IContentElementMcqOption){
    if (indexOf(this.currentSelections, option) !== -1){
      return true;
    }
    return false;
  }

  private _selectOption(option:IContentElementMcqOption){
    if (!this.element.isMultiSelect){
      if (!this.isSelected(option)){
        this.currentSelections = [option];
      }
    }
    else{
      let i = indexOf(this.currentSelections, option);
      let isSelected = (i !== -1);
      if (isSelected){ 
        this.currentSelections.splice(i, 1);
      }
      else{
        this.currentSelections.push(option);
      }
    }
  }

  selectOption(option:IContentElementMcqOption){
    if (this.isLocked){ return; }
    this.isStarted = true;
    this._selectOption(option);
    this.updateState();
  }

  deselectAllOptions(){
    this.currentSelections.splice(0, this.currentSelections.length);
  }

  updateState(){

    
    let isCorrect = true;

    let selectionsMap = new Map();
    let isOneCorrectOptionSelected = false;

    let selections = this.currentSelections.map( selection => {
      let i = indexOf(this.element.options, selection);
      let temporaryId = i;
      selectionsMap.set(temporaryId, true);
      if (selection.isCorrect){
        isOneCorrectOptionSelected = true
      }
      let content;
      if (selection.elementType === 'image'){
        content = selection.url;
      }
      else{
        content = selection.content;
      }
      return {
        i,
        id: selection.optionId,
        elementType: selection.elementType,
        content
      }
    });

    if (this.element.isMultiSelect){
      this.element.options.forEach((option, i) => {
        let temporaryId = i;
        if (option.isCorrect && !selectionsMap.has(temporaryId)){
          isCorrect = false;
        }
        if (!option.isCorrect && selectionsMap.get(temporaryId)){
          if (this.element.isMultiSelect){
            isCorrect = false;
          }
        }
      })
    }
    else if (!this.element.isMultiSelect){
      if (isOneCorrectOptionSelected){
        isCorrect = true;
      }
      else{
        isCorrect = false
      }
    }

    // console.log('MCQ updateScore', isCorrect, isOneCorrectOptionSelected)

    let entryState:IEntryStateMcq = {
      type: 'mcq',
      isCorrect,
      isStarted: this.isStarted,
      isFilled: selections.length > 0,
      selections
    }
    this.questionState.set(this.element.entryId, entryState);
    
  }

}
