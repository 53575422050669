import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IClassroomAssignment } from '../../data/collections/classroom-assignment.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';


@Component({
  selector: 'student-assignment',
  templateUrl: './student-assignment.component.html',
  styleUrls: ['./student-assignment.component.scss']
})
export class StudentAssignmentComponent implements OnInit, OnDestroy {

  public assignmentId:string;
  public classroomId:string;
  public isStateLoaded:boolean;
  public itematicIframe:SafeResourceUrl;
  public classroomAssignment: Observable<IClassroomAssignment[]>;

  private routeSub:Subscription;
  private classroomAssignmentSub:Subscription;
  
  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {
  }
  
  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId']; // used to show name in the UI
      this.assignmentId = params['assignmentId'];
      this.loadAssignmentById(this.assignmentId)
    });
  }

  ngOnDestroy() {
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.classroomAssignmentSub){ this.classroomAssignmentSub.unsubscribe(); }
  }

  loadAssignmentById(assignmentId:string){
    this.classroomAssignmentSub = this.afs.doc<IClassroomAssignment>('assignments/'+assignmentId).get().subscribe( res =>{
      let assignmentData:IClassroomAssignment = <IClassroomAssignment>res.data();
      if (!assignmentData){
        console.error('this assignment no longer exists!');
        return;
      }
      console.log('loadAssignmentById', assignmentData);
      // next we would want to pull the names of the assignments
    })
  }

}
