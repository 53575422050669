import { Component, OnInit, Output, OnDestroy, Renderer2, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { FormControl } from '@angular/forms';
import { debuglog } from 'util';
import { ITEMATIC_ENGINE_FETCH_URL, ITEMATIC_ENGINE_DOMAIN } from '../../core/constants';
import { ITask } from '../../data/collections/tasks.types';


interface IItematicScreenDef { 
  name:string,
  screenId:string,
  isGraded?:boolean, // not being passed up from Itematic as of 2019-02-12
  isExcluded?:boolean, // not being passed up from Itematic as of 2019-02-12 (for utility screens)
  thumbnail:string,
  thumbnailBg: {
    color: string,
    isScreenMode: boolean,
    colorCoverOpacity: number,
    img: string
  }
}

interface INewItematicTask { 
  name: string,
  itematicItemId: string,
  screens: any[],
}

interface IScreenForm {
  name:FormControl, 
  isGraded:FormControl, 
  isExcluded:FormControl, 
  screenId:string, 
  thumbnailUrl?:string
}

@Component({
  selector: 'form-task-creator',
  templateUrl: './form-task-creator.component.html',
  styleUrls: ['./form-task-creator.component.scss']
})

export class FormTaskCreatorComponent implements OnInit {
  
  @Output() captureNewTask = new EventEmitter<INewItematicTask>();
  @Output() onClose = new EventEmitter<null>();

  screenshotData = new FormControl('');
  newTaskName = new FormControl('');
  newItematicItemId = new FormControl('');
  newItematicScreenIds = new FormControl(null);

  currentItermatEngine = '5.13.34c';
  itematicIframe:SafeResourceUrl;
  newItematicScreens:Array<IScreenForm>
  stopListening: Function;

  constructor( private http: HttpClient, private sanitizer: DomSanitizer, private renderer: Renderer2 ) { 
    this.itematicIframe = sanitizer.bypassSecurityTrustResourceUrl(ITEMATIC_ENGINE_DOMAIN+'/itematic-engines/v'+this.currentItermatEngine+'/m0/index.html#!/');
    // this.itematicIframe = sanitizer.bypassSecurityTrustResourceUrl('http://localhost:3002');
    this.stopListening = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit() {
    this.http.get(ITEMATIC_ENGINE_FETCH_URL).subscribe( data => {
      console.log('engine version', data)
    })
  }

  ngOnDestroy() {
    this.stopListening();
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    // if (message.origin !== ITEMATIC_ENGINE_DOMAIN) return;
    let data:{type:string, payload:any} = JSON.parse(message.data);
    if (data.type === 'TASK_EDITOR_INFO'){
      // console.log('payload', data.payload)
      this.newItematicItemId.setValue(data.payload.itemId);
      this.newItematicScreens = [];
      data.payload.screens.forEach( (element:IItematicScreenDef) => {
        this.newItematicScreens.push({
          name: new FormControl(element.name),
          isGraded: new FormControl(element.isGraded),
          isExcluded: new FormControl(element.isExcluded),
          screenId: element.screenId,
          thumbnailUrl: element.thumbnail
        })
      });
    }
  }

  captureCurrentTask(){
    let screens = [];
    let screenshotDataRef = {};
    if (this.screenshotData.value){
      let screenshotData = JSON.parse(this.screenshotData.value);
      screenshotData.forEach(screenData => {
        screenshotDataRef[screenData.screen] = {
          url: screenData.img_url,
          bg: screenData.bg_settings,
        }
      })
    }
    this.newItematicScreens.forEach( screen => {
      if (screen.isGraded.value && !screen.isExcluded.value){
        let screenConfig:ITask = {
          isPublic: true,
          name: screen.name.value,
          itematicScreenId: (''+screen.screenId).trim(),
          type: 'question_screen',
          numThumbsUp: 0,
          numThumbsDown: 0,
        }
        if (screenshotDataRef[screen.screenId]){
          screenConfig.screenshot = screenshotDataRef[screen.screenId];
        }
        screens.push(screenConfig);
      }
    })
    this.captureNewTask.emit({
      name: this.newTaskName.value,
      itematicItemId: this.newItematicItemId.value,
      screens: screens
    })
  }

  requestClose(){
    this.onClose.emit();
  }

}
