import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { FDGElement } from './FDGElement';
import { IFDGOptions, IFDGElement } from './FDGInterfaces';

export class FDGContainer extends PIXI.Container {
  private objectCanvas = new PIXI.Container;
  private bgCanvas = new PIXI.Graphics;

  private nodes: FDGElement[] = [];

  private currentScale = 1;

  constructor(private bounds: PIXI.Rectangle, private options: IFDGOptions = {}) {
    super();
    options = _.defaults(options, {
      physicsEnabled: true,
      wallBounce: 0.1,
      repulseMarginMult: 1,
      repulseRatio: 1,
      friction: 0.95,
      minVelocity: 0.00001,
      elementRadius: 70,
      elementColor: 0xffffff,
      elementScaleAdjust: 0.95,
      elementMinScale: 0.5
    });

    this.addChild(this.bgCanvas);
    this.addChild(this.objectCanvas);
  }

  private intervalID: number;
  startOwnTicker() {
    this.intervalID = window.setInterval(this.applyForces, 17);
  }

  endOwnTicker() {
    window.clearInterval(this.intervalID);
  }

  addNewNode = (options: IFDGElement): FDGElement => {
    options.canvas = options.canvas || this.objectCanvas;
    return this.addNode(new FDGElement(options, this.options));
  }

  addNode = (node: FDGElement): FDGElement => {
    this.nodes.push(node);
    this.objectCanvas.addChild(node);
    if (this.currentScale > this.options.elementMinScale) {
      this.currentScale *= this.options.elementScaleAdjust;
      this.nodes.forEach(node2 => {
        node2.targetScale = this.currentScale;
      });
    } else {
      node.targetScale = this.currentScale;
    }
    return node;
  }

  removeNode = (node: FDGElement): FDGElement => {
    if (node.onRemove) {
      node.onRemove();
    }
    _.pull(this.nodes, node);
    this.removeChild(node);
    return node;
  }

  removeNodeByIndex = (index: number): FDGElement => {
    let node = this.nodes[index];
    if (node.onRemove) {
      node.onRemove();
    }
    this.nodes.splice(index, 1);
    this.removeChild(node);
    return node;
  }

  applyForces = () => {
    let i = this.nodes.length - 1;
    if (i < 0) {
      return;
    }

    do {
      const node = this.nodes[i];
      let j = this.nodes.length - 1;
      while (j > i) {
        const other = this.nodes[j];

        const dX = (node.x - other.x);
        const dY = (node.y - other.y);

        let dist = Math.sqrt(dX * dX + dY * dY);
        const maxVal = this.options.repulseMarginMult * (node.radius + other.radius);
        if (dist < maxVal) {
          dist = maxVal - dist;
          let ang = Math.atan2(other.y - node.y, other.x - node.x);
          let mult = - dist / maxVal * this.options.repulseRatio;
          node.vX += mult * Math.cos(ang);
          other.vX += mult * Math.cos(ang + Math.PI);
          node.vY += mult * Math.sin(ang);
          other.vY += mult * Math.sin(ang + Math.PI);
        }
        // if (dX > 0) {
        //   dX = Math.max(2, dX);
        // } else {
        //   dX = Math.min(-2, dX);
        // }
        // if (dY > 0) {
        //   dY = Math.max(2, dY);
        // } else {
        //   dY = Math.min(-2, dY);
        // }

        // let dist2 = Math.sqrt(dX * dX + dY * dY);
        // let mult = 0;
        // if (dist2 < this.options.repulseMarginMult) {
        //   // var _mult:Number=REPULSE/((REPULSE*REPULSE_C)+dist);
        //   mult += this.options.repulseRatio / (FDG_CONFIG.REPULSE_C + dist2);
        // }

        // if (dist2 > FDG_CONFIG.PULL_MIN) {
        //   // _mult=-CONSTRAINT_PULL*dist+CONSTRAINT_C;
        //   mult -= FDG_CONFIG.PULL_VAL * dist2;
        // }

        // node.vY += mult * dX;
        // node.vY += mult * dY;
        // other.vX -= mult * dX;
        // other.vY -= mult * dY;
        j -= 1;
      }

      if (node.x < this.bounds.left / this.scale.x + node.radius) {
        node.vX += this.options.wallBounce * (this.bounds.left / this.scale.x + node.radius - node.x);
      }
      if (node.x > this.bounds.right / this.scale.x - node.radius) {
        node.vX += this.options.wallBounce * (this.bounds.right / this.scale.x - node.radius - node.x);
      }
      if (node.y < this.bounds.top / this.scale.y + node.radius) {
        node.vY += this.options.wallBounce * (this.bounds.top / this.scale.y + node.radius - node.y);
      }
      if (node.y > this.bounds.bottom / this.scale.y - node.radius) {
        node.vY += this.options.wallBounce * (this.bounds.bottom / this.scale.y - node.radius - node.y);
      }

      if (!node.active) {
        node.vX *= 0.7;
        node.vY *= 0.7;
      }

      if (node.target != null) {
        let dX = (node.x - node.target.x);
        let dY = (node.y - node.target.y);
        let dist2 = dX * dX + dY * dY;

        // if (dist2 > FDG_CONFIG.TARGET_MIN) {
        //   let mult = -FDG_CONFIG.TARGET_RATIO;

        //   node.vX += mult * dX;
        //   node.vY += mult * dY;
        // }
      }

      node.onTickMain();
    } while (i--);
  }
}
