import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'student-headsup-lobby-count',
  templateUrl: './student-headsup-lobby-count.component.html',
  styleUrls: ['./student-headsup-lobby-count.component.scss']
})
export class StudentHeadsupLobbyCountComponent implements OnInit, OnDestroy {

  @Input() assignmentId:string;

  count:number;

  private playerCountSub:Subscription

  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.playerCountSub = this.afs.doc('headsUpLobbyPlayersCount/'+this.assignmentId)
      .valueChanges()
      .subscribe((value:any) => {
        this.count = value.count;
      });
  }

  ngOnDestroy() {
    if (this.playerCountSub){ this.playerCountSub.unsubscribe(); }
  }

}
