import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

export interface ITeacherGradingFormlet {
  isCorrect: boolean,
  comment: string,
}

@Component({
  selector: 'teacher-grading-formlet',
  templateUrl: './teacher-grading-formlet.component.html',
  styleUrls: ['./teacher-grading-formlet.component.scss']
})
export class TeacherGradingFormletComponent implements OnInit {

  @Input() startingText:string;
  @Output() applyGrading = new EventEmitter<ITeacherGradingFormlet>();

  comment = new FormControl('');

  constructor() { }

  ngOnInit() {
    if (this.startingText){
      this.comment.setValue(this.startingText);
    }
  }

  _applyGrading(isCorrect:boolean){
    this.applyGrading.emit({
      isCorrect,
      comment: this.comment.value,
    })
  }

}
