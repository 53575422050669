import { IUser } from "../collections/users.types";

export const getComparableFullName = (user:IUser) => {
    if (!user.lastName){ user.lastName = ''; }
    if (!user.firstName){ user.firstName = ''; }
    return (''+user.lastName).toUpperCase()+' '+(''+user.firstName).toUpperCase();
}
export const SORT_NAMES_DESC = (a:IUser,b:IUser) => {
    var nameA = a.displayName.trim().toLowerCase();// getComparableFullName(a);
    var nameB = b.displayName.trim().toLowerCase();// getComparableFullName(b);
    if (nameA > nameB) { return -1; }
    if (nameA < nameB) { return 1; }
    return 0;
}
export const SORT_NAMES_ASC = (a:IUser,b:IUser) => {
    var nameA = a.displayName.trim().toLowerCase();// getComparableFullName(a);
    var nameB = b.displayName.trim().toLowerCase();// getComparableFullName(b);
    if (nameA < nameB) { return -1; }
    if (nameA > nameB) { return 1; }
    return 0;
}