import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ISelectBlockContent, ISelectBlockContentPagelet } from '../../ui-partial/body-block-arr-select/body-block-arr-select.component';
import { OPTIONS_ACCOUNT_USER_TYPE, ISelectBlockContentUserType } from '../user-registration-sso/content/options-account-user-type';
import { OPTIONS_ACCOUNT_RESTORATION } from '../user-registration-sso/content/options-account-restoration';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { WhitelabelService, WLST_TRIAL_ID, WLST_INST_SCOPE_ID } from '../../domain/whitelabel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';

@Component({
  selector: 'user-registration-inprogress',
  templateUrl: './user-registration-inprogress.component.html',
  styleUrls: ['./user-registration-inprogress.component.scss']
})
export class UserRegistrationInprogressComponent implements OnInit {

  accountUserType = new FormControl();
  agreeTerms = new FormControl(true);
  agreeIntComms = new FormControl(true);
  optionsAccountUserType:ISelectBlockContent[] = OPTIONS_ACCOUNT_USER_TYPE;
  accountLinkingError:string;

  constructor(
    public whiteLabel: WhitelabelService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.whiteLabel.onReady( () => {
      this.initTemplates();
    })
  }

  ngOnDestroy() {

  }

  initTemplates(){
    this.optionsAccountUserType.forEach(entry => {
      entry.caption = this.whiteLabel.updateTemplateContent(entry.caption);
      entry.body = this.whiteLabel.updateTemplateContent(entry.body);
    });
  }

  completeNewAccountSetup(){
    let userType:ISelectBlockContentUserType = this.accountUserType.value;
    let agreementsMap:Map<string, boolean> = new Map();
    agreementsMap.set('agreeIntComms', this.agreeIntComms.value);
    agreementsMap.set('agreeTerms', this.agreeTerms.value);
    return this.auth.updateUserAgreements(agreementsMap)
    .then( () => {
      return this.auth
        .updateUserUi(
          userType.data.ui, 
          this.whiteLabel.getSiteText(WLST_TRIAL_ID),
          this.whiteLabel.getSiteText(WLST_INST_SCOPE_ID)
        )
    })
    .then( () => {
      return this.auth.navigateToUserUiHome();
    })
  }

  canContinue(){
    if (!this.accountUserType.value){
      return false;
    }
    if (!this.agreeTerms.value){
      return false;
    }
    return true;
  }

}
