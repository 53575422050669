import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'student-class-activity',
  templateUrl: './student-class-activity.component.html',
  styleUrls: ['./student-class-activity.component.scss']
})
export class StudentClassActivityComponent implements OnInit {

  constructor(
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
  }

}
