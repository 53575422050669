import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { serverTimestamp } from '../../data/timestamp';
import { AuthService } from '../../core/auth.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { Router } from '@angular/router';
import { IUser } from '../../data/collections/users.types';
import { STANDALONE_FRAME_BOTTOM_PADDING } from '../../ui-avatar-displays/constants/constants';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { TeacherClassroomCurriculumComponent } from '../../ui-teacher/teacher-classroom-curriculum/teacher-classroom-curriculum.component';
import { IAssignment } from '../../data/collections/assignments.types';
import { IAssignmentSubmission } from '../../data/collections/assignment-submissions.types';
import { BoosterpacksService } from '../boosterpacks.service';

enum Overlays {
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  ASSIGNMENTS = 'ASSIGNMENTS',
  BOOSTER_PACKS = 'BOOSTER_PACKS',
  CHECKPOINTS = 'CHECKPOINTS',
  DAILY_REWARDS = 'DAILY_REWARDS',
}


@Component({
  selector: 'student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.scss']
})
export class StudentDashboardComponent implements OnInit {

  activeOverlay:Overlays;
  OVERLAYS = Overlays;
  userInfo:IUser;
  userSub:Subscription;
  isBoosterPackSelection:boolean;
  isAssignmentAvailable:boolean;
  isCheckpointAvailable:boolean;
  private classroomWatchers:Map<string, Subscription> = new Map();
  private classroomWatcherFlags:Map<string, boolean> = new Map();

  constructor(
    public whiteLabel: WhitelabelService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    private bps: BoosterpacksService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.chatPanel.deactivate();
    this.sidePanel.deactivate();
    this.userSub = this.auth.user.subscribe( userInfo => {
      this.refreshUserInfo(userInfo);
      this.watchForOpenAssessments()
      this.watchForOutstandingCheckpoints()
    });
  }

  watchForOutstandingCheckpoints(){
    this.bps
      .isAnyCheckpointsOutstanding()
      .subscribe(isOutstanding => {
        console.log('isOutstanding', isOutstanding)
        this.isCheckpointAvailable = isOutstanding;
      })
  }

  
  watchForOpenAssessments(){
    // this does not account for a student who is removed from the classroom while they have this view open
    this.userInfo.classroomsAsStudent.forEach(classroomId => {
      if (!this.classroomWatchers.has(classroomId)){
        const subscription = this.createClassroomAssignmentSubscription(classroomId).subscribe( changes =>{
          if(changes.length > 0){
            let isAnyPending:boolean;
            changes.forEach(change =>{
              const assignment:IAssignment = change.payload.doc.data();
              const assignmentId = change.payload.doc.id;
              this.checkAssignmentSubmissionStatus(assignmentId, assignment.tasks[0])
                .then(isSubmitted => {
                  // to do: if the change is an assignment being removed, would have to deal with that here (not handling now, but it could lead to isAnyOfSubmitted being set to false)
                  if (!isSubmitted){
                    isAnyPending = true;
                  }
                  this.classroomWatcherFlags.set(classroomId, isAnyPending);
                  this.checkForOpenClassroomFlags();
                })
            })
          }
          else{
            this.classroomWatcherFlags.set(classroomId, false);
          }
          this.checkForOpenClassroomFlags(); // probably not needed here
        })
        this.classroomWatchers.set(classroomId, subscription);
      }
    })
  }

  createClassroomAssignmentSubscription(classroomId:string){
    return this.afs
      .collection<IAssignment>('assignments', ref => ref
          .where('classroom', '==', classroomId)
          .where('isArchived', '==', false)
          .where('isDirectStart', '==', true)
          .where('isDirectClose', '==', false)
          // .limit(1)
      )
      .stateChanges()
  }

  checkForOpenClassroomFlags(){
    let isAnyAssessmentOpen = false;
    this.classroomWatcherFlags.forEach(flag => {
      if(flag){
        isAnyAssessmentOpen = true;
      }
    })
    this.isAssignmentAvailable = isAnyAssessmentOpen;
  }

  checkAssignmentSubmissionStatus(assignmentId:string, taskId:string){
    return this.afs
      .collection<IAssignmentSubmission>('assignmentSubmissions', ref => {
        return ref
          .where('isCurrent', '==', true)
          .where('uid', '==', this.auth.getUid())
          .where('assignmentId', '==', assignmentId)
          .where('taskId', '==', taskId)
          .limit(5)
      })
      .get()
      .toPromise()
      .then(res => {
        let isAnyCompleted:boolean;
        res.docs.forEach(doc => {
          let currentSubmission:IAssignmentSubmission = <any>doc.data();;
          if(currentSubmission.isSubmitted){
            isAnyCompleted = true;
          }
        })
        return isAnyCompleted;
      }) 
  }

  openAccountOverlay(){
    this.openOverlay(Overlays.ACCOUNT_SETTINGS);
  }

  getAvatarOffsetY(){
    return STANDALONE_FRAME_BOTTOM_PADDING + 15 // the extra little bit is because of some weird css display offset
  }

  openAssignmentsOverlay(){
    this.openOverlay(Overlays.ASSIGNMENTS);
    return;
    this.router.navigate([
      'student',
      'classroom',
      this.userInfo.classroomsAsStudent[0],
      'assignments',
    ])
    // OVERLAYS.ASSIGNMENTS
  }

  refreshUserInfo(userInfo:IUser){
    this.userInfo = userInfo;
  }

  openOverlay(overlayId:Overlays){
    this.activeOverlay = overlayId;
  }

  
  openBoosterpacksMenu(){
    this.isBoosterPackSelection = true;
    // routerLink="/student/booster"
  }
  exitBoosterpacksMenu(){
    this.isBoosterPackSelection = false;
  }

  closeActiveOverlay(){
    this.activeOverlay = null;
  }

  defaultBtnClick(){
    alert('under construction');
  }

  leaderboardAvailable() {
    return true;
  }

  navLeaderboard() {
    this.router.navigate([
      'student',
      'leaderboards',
      'district',
      'cambridge',
      'classType',
      'stage_6'
    ]);
  }

}
