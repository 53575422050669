import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IContentElementMath, TextParagraphStyle, QuestionState } from '../models';

@Component({
  selector: 'element-render-math',
  templateUrl: './element-render-math.component.html',
  styleUrls: ['./element-render-math.component.scss']
})
export class ElementRenderMathComponent implements OnInit {

  @Input() element:IContentElementMath;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() isInline:boolean;

  constructor() { }

  ngOnInit() {
    // console.log('jquery in angular', (window as any).jQuery);
    // console.log('mathquill in angular', (window as any).MathQuill);
    console.log('isInline', this.isInline)
  }

  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL
  }

}
