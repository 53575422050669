import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IBoosterPack, IBoosterPackItem } from '../data/cambridge/types';
import { AngularFirestore } from '@angular/fire/firestore';
import { FunctionsService } from './functions.service';
import { AuthService } from './auth.service';
import { ClassroomService } from '../ui-teacher/classroom.service';
import { clearSubjectMap } from './util/clear-subject-map';
import { IItemScore } from '../ui-student/boosterpacks.service';

enum Collections {
  boosterPacks = 'boosterPacks',
  boosterPackItems = 'boosterPackItems',
  taskBoosterMappings = 'taskBoosterMappings',
}

export interface ITaskBoosterMapping {
  __id?: string,
  taskId:string,
  performanceThreshold:number,
  boosterPackId:string, 
  boosterPackItemId:string, 
  displayName: string,
  creatorUid: string,
}

export enum ItemTypes {
  blue = 'blue',
  red = 'red',
  black = 'black',
  medal = 'medal',
}

@Injectable({
  providedIn: 'root'
})
export class BoosterService {

  boosterPackListeners:Map<string, ReplaySubject<IBoosterPack>> = new Map();
  boosterPackItemListeners:Map<string, ReplaySubject<IBoosterPackItem>> = new Map();

  constructor(
    private afs: AngularFirestore,
    private funs: FunctionsService,
    private auth: AuthService,
    private classroomService: ClassroomService,
  ) {
    this.auth.newAuthSub.subscribe(()=>this.clearListeners())
  }

  clearListeners(){
    clearSubjectMap(this.boosterPackListeners);
    clearSubjectMap(this.boosterPackItemListeners);
  }

  getBoostersAssociatedToTask(taskId:string){
    const boosterPacks:IBoosterPack[] = [];
    const boosterPacksTrackRef = new Map();
    return this.afs
      .collection(Collections.taskBoosterMappings, ref=>ref
        .where('taskId', '==', taskId)
      )
      .get()
      .toPromise()
      .then(res=>{
        console.log('getBoostersAssociatedToTask', res.docs.length)
        const boosterPackIdRef:Map<string, boolean> = new Map()
        res.forEach(entry => {
          const taskBoosterMapping:ITaskBoosterMapping = <any>entry.data()
          boosterPackIdRef.set(taskBoosterMapping.boosterPackId, true);
        });
        const boosterPackIds = [];
        boosterPackIdRef.forEach((flag:boolean, boosterPackId:string) => {
          boosterPackIds.push(boosterPackId)
        });
        Promise.all(
          boosterPackIds.map(boosterPackId => {
            this.afs.doc(Collections.boosterPacks+'/'+boosterPackId)
              .get()
              .toPromise()
              .then(snap =>{
                boosterPacks.push({
                  ... <IBoosterPack>snap.data(),
                  __id: snap.id
                })
              })
          })
        )
      })
      .then(()=> boosterPacks)
  }

  getBoosterPackInfo(boosterPackId:string){
    return this.getCollectionInfo(boosterPackId, this.boosterPackListeners, Collections.boosterPacks);
  }

  getBoosterPackItemInfo(boosterPackItemId:string){
    return this.getCollectionInfo(boosterPackItemId, this.boosterPackItemListeners, Collections.boosterPackItems);
  }

  getCollectionInfo(id:string, listenerMap:Map<string, ReplaySubject<any>>, collection:string) : ReplaySubject<any> {
    if (listenerMap.has(id)){
      return listenerMap.get(id);
    }
    let subject:ReplaySubject<any> = new ReplaySubject(1);
    listenerMap.set(id, subject);
    this.afs.doc(collection+'/'+id)
        .valueChanges()
        .subscribe( observer => {
          subject.next(observer);
        });
    return subject;
  }

  processItemScore(itemType:string, score: number): IItemScore {
    let numStars:number, maxStars:number, hasMedal: boolean;
    if (itemType === ItemTypes.blue){
      maxStars = 1;
      if (score > 0){
        numStars = 1;
      }
      else{
        numStars = 0;
      }
    }
    else if (itemType === ItemTypes.medal) {
      maxStars = 3;
      if (score < 0.25)       {  numStars = 0; }
      else if (score < 0.5)   {  numStars = 1; }
      else if (score < 0.75)  {  numStars = 2; }
      else                    {  numStars = 3; }
      if (score === 1)        {  hasMedal = true; }
    }
    else {
      maxStars = 3;
      if (score < 0.5)     {  numStars = 0; }
      else if (score < 0.8){  numStars = 1; }
      else if (score < 1)  {  numStars = 2; }
      else if (score === 1){  numStars = 3; }
    }
    return {
      score, 
      numStars,
      maxStars,
      hasMedal
    };
  }
}
