import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { ClassroomService } from '../../ui-teacher/classroom.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FormValidation } from '../../core/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';


@Component({
  selector: 'play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.scss']
})
export class PlayComponent implements OnInit {

  classCodeForm:FormGroup; 
  classCodeFormValidation:FormValidation; 

  constructor(
    private classroomService: ClassroomService,
    private fb: FormBuilder, 
    private router:Router,
    private auth:AuthService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.buildForm();

  }

  getFreshAccountClassCode(){
    const freshAccount = this.auth.getFreshAccountSignup();
    if (freshAccount){
      return freshAccount.classCodeId;
    }
  }

  buildForm(){
    this.classCodeForm = this.fb.group({
      'class_code': ['', [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(8),
      ]],
    });
    this.classCodeFormValidation = new FormValidation(this.classCodeForm, {
      'class_code': {
        'required': 'Class code required.',
        'minlength': 'Class codes are always at least 3 letters and numbers.',
        'maxlength': 'Class codes are never more than 8 letters and numbers.',
      }
    });
  }

  validateClassCode(){
    this.classCodeFormValidation.markAsSent();
    const classCodeId = this.classCodeForm.value['class_code'];
    if (!classCodeId){
      this.classCodeFormValidation.markAsFailed('no_code');
      return;
    }
    this.classroomService
      .getClassroomIdByClassCode(classCodeId)
      .then( classroomId => {
        console.log('found class', classroomId);
        this.classCodeFormValidation.markAsComplete();
        this.router.navigate(['classroom','play', classroomId, classCodeId])
      })
      .catch ( e => {
        console.log('query failed', e);
        this.classCodeFormValidation.markAsFailed(e.message)
      } )
  }

}
