import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FielTrialAdminComponent } from './fiel-trial-admin/fiel-trial-admin.component';
import { FieldTrialTeachersComponent } from './field-trial-teachers/field-trial-teachers.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FielTrialAdminComponent, FieldTrialTeachersComponent],
  imports: [
    CommonModule,
    RouterModule, 
    ReactiveFormsModule,
  ]
})
export class UiFieldcoordModule { }
