import { FirebaseTimestamp } from "../../data/base.types";

export interface ICustomTaskSet { 
  __id?: string,
  __backup__id?: string,
  __backup__userDisplayName?: string,
  name?: string,
  order?: number,
  taskId?: string, // cross link to collection `tasks`
  project?: string,
  isReady?: boolean,
  labelCounter?:number,
  isArchived?: boolean,
  questions?:IQuestionConfig[] | string,
  creatorUid?: string,
  timeCreated?: FirebaseTimestamp,
  timeLastSaved?: FirebaseTimestamp,
  schemaVersion?: number,
  lastTouchedBy?: string,
  assessmentFrameworkId?:string,
}

export interface ICustomTaskTag {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdBy: string,
  timestamp: FirebaseTimestamp,
}

export interface ICustomTaskComment {
  __id?:string,
  caption: string,
  customTaskSetId: string,
  localTaskId: number,
  createdByName: string,
  createdByUid: string,
  timestamp: FirebaseTimestamp,
}

export interface IQuestionConfig {
  content: IContentElement[],
  label: string,
  isReady?: boolean,
  isInfoSlide?: boolean,
  notes?: string,
  taskId?: string,
  localTaskId: number,
  entryIdCounter?: number,
  entryOrder?: number[],
}
export interface IContentElement {
  elementType:string | ElementType;
  isShowAdvancedOptions?:boolean;
  entryId?:number,
  _changeCounter?:number,
}
export interface IContentElementText extends IContentElement {
  caption: string,
  paragraphStyle: TextParagraphStyle,
  simpleList: string[],
  advancedList: IContentElement[],
}
export enum TextParagraphStyle {
  HEADLINE = 'headline',
  HEADLINE_SMALL = 'headline_small',
  REGULAR = 'regular',
  SMALL = 'small',
  BULLET = 'bullet',
  NUMBERED = 'numbered',
  ADVANCED_INLINE = 'advanced-inline',
}

export enum InputFormat {
  NUMBER = 'number',
  FRACTION = 'fraction',
  ALGEBRA = 'algebra',
  RATIO = 'ratio',
  TEXT = 'text',
}

export type QuestionState = Map<number, any>;

// <option>Headline</option>
// <option>Regular</option>
// <option>Small</option>
// <option>Bullet List</option>
// <option>Numbered List</option>
// <option>Advanced Inline</option>

export interface IContentElementMath extends IContentElement { 
  latex:string,
  paragraphStyle:string,
}
export interface IContentElementImage extends IContentElement {
  url: string;
  scale: number;
}
export interface IContentElementVideo extends IContentElement {
  youtubeId:string;
}
export interface IContentElementIframe extends IContentElement {
  url:string;
}
export interface IContentElementMcq extends IContentElement {
  displayStyle: McqDisplay,
  options: Array<IContentElementMcqOption>
  isMultiSelect?: boolean,
}
export interface IContentElementMcqOption {
  optionId: number,
  elementType: string,
  content: string,
  url?: string,
  isCorrect?: boolean,
}

export enum McqDisplay {
  VERTICAL = 'vertical',
  GRID = 'grid',
  HORIZONTAL = 'horizontal',
  WRAP = 'wraparound',
}
export interface IRatioTermConfig {
  value: string,
}
export enum NumberTolerance {
  ALTVALUE = 'altvalue',
  DECIMALS = 'decimals',
  MINMAX = 'minmax',
  PROXPERC = 'proxperc', // proximity by percentage
}
export interface INumberTolerance {
  toleranceTypeId: NumberTolerance,
  numDecimals?: number,
  min?:number,
  max?:number,
  proxPerc?:number,
}
export interface IContentElementInput extends IContentElement { 
  format: InputFormat,
  latex?: string,
  startingLatex?: string,
  tolerances?: INumberTolerance[],
  value?:string,
  prefix?:string,
  suffix?:string,
  alternativeValues?: IContentElementInput[],
  ratioTerms?: string[],
  isStrictLowestTerms?: boolean,
}

export interface IElementTypeDef {
  id: string,
  caption: string,
  icon: string,
  isInteractive?: boolean,
}

export enum ElementType {
  TEXT = 'text',
  MATH = 'math',
  IMAGE = 'image',
  VIDEO = 'video',
  MCQ = 'mcq',
  ORDER = 'order',
  INPUT = 'input',
  IFRAME = 'iframe',
}

export const elementTypes:IElementTypeDef[] = [
  {id: ElementType.TEXT,    caption:'Text',  icon:'fa-font'},
  {id: ElementType.MATH,    caption:'Math',  icon:'fa-pencil'},
  {id: ElementType.IMAGE,   caption:'Image', icon:'fa-picture-o'},
  {id: ElementType.MCQ,     caption:'Multiple Choice',  isInteractive: true, icon:'fa-bars'},
  {id: ElementType.ORDER,   caption:'Ordering',         isInteractive: true, icon:'fa-sort'},
  {id: ElementType.INPUT,   caption:'Keyboard Input',   isInteractive: true, icon:'fa-keyboard-o'},
  // {id: ElementType.IFRAME,  caption:'iFrame', icon:'fa-video'},
  // {id:'survey', caption:'Text'},
  // {id:'emoji-break', caption:'Text'},
  // {id:'thought-bubbles', caption:'Text'},
];


export interface IEntryState {
  type: string,
  isCustomGrading?: boolean,
  isCorrect?: boolean,
  isStarted: boolean,
  isFilled: boolean,
}
export interface IEntryStateMcq extends IEntryState {
  selections: Array<{
    i: number,
    id: number,
    elementType:string,
    content: any
  }>
}

export interface IEntryStateInputNumber extends IEntryState {
  value: string
}
export interface IEntryStateInputMath extends IEntryState {
  latex: string
}
export interface IEntryStateInputText extends IEntryState {
  str: string
}
export interface IEntryStateInputFraction extends IEntryState {
  wholenumber: number
  numerator: number
  denominator: number
  reducedValue: number
}

export const generateDefaultElementText = (elementType):IContentElementText => {
  return {
    elementType,
    caption: '',
    simpleList: [],
    advancedList: [],
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementMath = (elementType):IContentElementMath => {
  return {
    elementType,
    latex: '',
    _changeCounter: 0,
    paragraphStyle: TextParagraphStyle.REGULAR
  }
}
export const generateDefaultElementImage = (elementType):IContentElementImage => {
  return {
    elementType,
    url: null,
    scale: 100,
  }
}
export const generateDefaultElementVideo = (elementType):IContentElementVideo => {
  return {
    elementType,
    youtubeId: null,
  }
}
export const generateDefaultElementMcq = (elementType):IContentElementMcq => {
  return {
    elementType,
    displayStyle: McqDisplay.VERTICAL,
    options: [
      {elementType:ElementType.TEXT, content: 'Option 1', isCorrect:false, optionId: 1},
      {elementType:ElementType.TEXT, content: 'Option 2', isCorrect:true,  optionId: 2},
      {elementType:ElementType.TEXT, content: 'Option 3', isCorrect:false, optionId: 3},
      {elementType:ElementType.TEXT, content: 'Option 4', isCorrect:false, optionId: 4},
    ]
  }
}
export const generateDefaultElementInput = (elementType):IContentElementInput => {
  return {
    elementType,
    format: null,
    alternativeValues: [],
    ratioTerms: [],
  }
}

export const elementIconById:Map<string, string> = new Map();
elementTypes.forEach(elementType => {
  elementIconById.set(elementType.id, elementType.icon)
});
  
export const createDefaultElement = (elementType:string):IContentElement => {
  if (elementType === ElementType.TEXT){ return generateDefaultElementText(elementType); }
  if (elementType === ElementType.MATH){ return generateDefaultElementMath(elementType); }
  if (elementType === ElementType.IMAGE){ return generateDefaultElementImage(elementType); }
  if (elementType === ElementType.MCQ){ return generateDefaultElementMcq(elementType); }
  if (elementType === ElementType.VIDEO){ return generateDefaultElementVideo(elementType); }
  if (elementType === ElementType.INPUT){ return generateDefaultElementInput(elementType); }
  return { elementType }
}