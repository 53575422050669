import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IContentElementMath, TextParagraphStyle } from '../models';
import { FormControl } from '@angular/forms';
import { bindFormControl, bindFormControls } from '../services/data-bind';

@Component({
  selector: 'capture-math',
  templateUrl: './capture-math.component.html',
  styleUrls: ['./capture-math.component.scss']
})
export class CaptureMathComponent implements OnInit, OnChanges {

  @Input() obj:any;
  @Input() prop:string;
  @Input() startBlank:boolean;
  @Input() isManualKeyboard:boolean;
  @Input() isSpacesTabs:boolean;
  @Input() isLocked:boolean;
  @Output() onChange = new EventEmitter<null>();
  @ViewChild('mathField') mathFieldRef: ElementRef;

  MathLive = null;
  mathField;
  isMathFieldLocked:boolean = false;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(){
    if(this.isLocked && !this.isMathFieldLocked){
      this.isMathFieldLocked = true;
      let currentLatex = this.obj[this.prop];
      let staticMathFieldContainer = this.mathFieldRef.nativeElement;
      staticMathFieldContainer.innerHTML = '$$'+currentLatex+'$$';
      this.MathLive.renderMathInElement(staticMathFieldContainer);
    }
  }
  
  ngAfterViewInit() {
    // console.log('mathFieldRef', this.mathFieldRef.nativeElement)
    this.MathLive = (window as any).MathLive;
    const mathField = this.MathLive.makeMathField(this.mathFieldRef.nativeElement, {
      smartMode: false,
      // virtualKeyboards: 'numeric roman greek functions'
      virtualKeyboardMode: this.isManualKeyboard ? 'manual' : 'onfocus',
      onContentDidChange: mf => {
        let newLatex:string = mathField.$latex();
        let newLatexClean = newLatex.replace('#@', '\\placeholder{}');
        if (newLatex !== newLatexClean){
          newLatex = newLatexClean;
          mathField.$latex(newLatexClean);
        }
        // console.log('latex', newLatex);
        if (this.obj){
          if (newLatex !== this.obj[this.prop]){
            this.obj[this.prop] = newLatex;
            this.onChange.emit();
            if (!this.obj._changeCounter){
              this.obj._changeCounter = 1;
            }
            else{
              this.obj._changeCounter ++;
            }
          }
        }
      }
    });
    if (this.obj[this.prop]){
      mathField.$latex(this.obj[this.prop])
    }
  }

}
