import {firestore} from 'firebase/app';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassroomService } from '../classroom.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { IClassroom } from '../../data/collections/classrooms.types';
import { FormGroup, FormControl } from '@angular/forms';
import { renderEmailFromUsername, renderUsernameFromEmail } from '../../data/accounts/pseudo-email-usernames';
import { IUser } from '../../data/collections/users.types';
import { UsersService } from '../../core/users.service';

interface ITeacherSlot {
  uid: string,
  displayName: string,
  username: string,
}

@Component({
  selector: 'share-classroom',
  templateUrl: './share-classroom.component.html',
  styleUrls: ['./share-classroom.component.scss']
})
export class ShareClassroomComponent implements OnInit {

  classroomId:string;
  classConfig: IClassroom;
  isClassroomInfoLoaded: boolean;
  currentTeacherSlots:ITeacherSlot[];
  addTeacherForm = new FormGroup({username: new FormControl('')});
  ADD_TEACHER_ERRORS = {
    EMAIL_NO_MATCH: 'users/email-no-match',
    EMAIL_MANY_MATCHES: 'users/email-many-matches',
    NO_CLASSROOM: 'config/no-classroom',
    NO_USER: 'config/no-user',
  }
  isAddingTeacher:boolean;
  isAddingTeacherSuccess:boolean;
  errorOnAddTeacher:string;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route
      .params
      .subscribe(params => {
        this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
        if (!this.classroomId){ return; }
        this.loadClassroom(this.classroomId);
      });
    this.addTeacherForm
      .valueChanges
      .subscribe( () => {
        this.isAddingTeacherSuccess = false;
      })
  }

  loadClassroom(classroomId: string) {
    // console.log('classroomId', classroomId)
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        this.isClassroomInfoLoaded = true;
        // console.log(classroomId, this.classConfig)
        this.loadTeachers();
      });
  }

  loadTeachers(){
    this.currentTeacherSlots = [];
    if(!this.classConfig.currentTeachers){
      console.warn('No teachers in class')
      return;
    }
    this.classConfig.currentTeachers.forEach(teacherUid => {
      const teacherSlot:ITeacherSlot = {
        uid: teacherUid,
        displayName: '',
        username: '',
      }
      this.currentTeacherSlots.push(teacherSlot);
      this.usersService
        .getUserInfo(teacherUid)
        .subscribe((userInfo:IUser)=>{
          console.log('Got teacher info')
          teacherSlot.displayName = userInfo.displayName;
          teacherSlot.username = renderUsernameFromEmail(userInfo.email);
        })
    })
  }

  addTeacherByUsername(){
    this.isAddingTeacher = true;
    this.isAddingTeacherSuccess = false;
    this.errorOnAddTeacher = '';
    const usernameEntered:string = this.addTeacherForm.value['username'];
    const usernameSanitized:string = renderEmailFromUsername(usernameEntered);
    // pull by username (obtain UID)
    this.afs
      .collection('users', ref => ref
        .where('email', '==', usernameSanitized) 
      )
      .get()
      .toPromise()
      .then( snap => {
        if (snap.docs.length === 0){
          throw new Error('users/email-no-match');
        }
        if (snap.docs.length > 1){
          throw new Error('users/email-many-matches');
        }
        const record:IUser = <any>snap.docs[0].data();
        // console.log('record', record);
        return this.shareClassWithTeacherUid(record.uid);
      })
      .then( ()  => {
        this.isAddingTeacher = false;
        this.isAddingTeacherSuccess = true;
      })
      .catch( (error:Error) =>{
        this.errorOnAddTeacher = error.message;
        console.warn('ADD TEACHER FAILED', error.message);
      })
    // add
  }

  shareClassWithTeacherUid(uid:string){
    let classroomId = this.classroomId;
    if (!classroomId){ throw new Error('config/no-classroom') }
    if (!uid){ throw new Error('config/no-user') }
    return this.afs.doc('classrooms/'+this.classroomId).update({
      currentTeachers: firestore.FieldValue.arrayUnion(uid)
    })
    .then( () => {
      return this.afs.doc('users/'+uid).update({
        classroomsAsTeacher: firestore.FieldValue.arrayUnion(classroomId)
      })
    })
  }

}
