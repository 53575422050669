import * as _ from 'lodash';
import * as PIXI from 'pixi.js';

interface IFireworkOptions {
  container?: PIXI.Container;
  x?: number;
  y?: number;
  color?: number;
  gravity?: number;
  fade?: number;
  startVX?: number;
  startVY?: number;
  startOX?: number;
  startOY?: number;
  numParts?: number;
  size?: number;
}

export class PIXIFirework {
  static TEXTURE: PIXI.Texture;
  static particles: Array<PIXIFireworkParticle> = [];
  static initialized: Boolean = false;

  defaultOptions: IFireworkOptions = {
    x: 0,
    y: 0,
    color: 0xffffff,
    gravity: 0.01,
    fade: 0.001,
    startVX: 1,
    startVY: 1,
    startOX: 0,
    startOY: 0,
    numParts: 50,
    size: 3
  };

  static initialize = (app: PIXI.Application) => {
    if (!PIXIFirework.initialized) {
      let firework: PIXI.Graphics = new PIXI.Graphics;
      firework.beginFill(0xffffff);
      firework.drawCircle(0, 0, 5);
      PIXIFirework.TEXTURE = app.renderer.generateTexture(firework, 1, 1);
      PIXI.Ticker.shared.add(PIXIFirework.onTick);
      PIXIFirework.initialized = true;
    }
  }

  static onTick = () => {
    for (let i = 0; i < PIXIFirework.particles.length; i++) {
      PIXIFirework.particles[i].update();
      if (PIXIFirework.particles[i].alpha < 0.1) {
        PIXIFirework.particles[i].destroy();
        PIXIFirework.particles.splice(i, 1);
        i--;
      }
    }
  }

  constructor(options: IFireworkOptions = null) {
    if (!PIXIFirework.initialized) {
      console.error('must initialize the static class before using');
      return;
    }

    options = _.defaults(options, this.defaultOptions);

    for (let i = 0; i < options.numParts; i += 1) {
      let particle: PIXIFireworkParticle = new PIXIFireworkParticle(options);
      PIXIFirework.particles.push(particle);
      options.container.addChild(particle);
    }
  }
}

class PIXIFireworkParticle extends PIXI.Sprite {
  vX: number;
  vY: number;

  constructor(private options: IFireworkOptions) {
    super(PIXIFirework.TEXTURE);
    this.x = options.x;
    this.y = options.y;
    this.vX = options.startOX + options.startVX * (Math.random() - 0.5);
    this.vY = options.startOY + options.startVY * (Math.random() - 0.5);
    this.width = options.size;
    this.height = options.size;
    this.tint = options.color;
  }

  update = () => {
    this.x += this.vX;
    this.y += this.vY;
    this.vY += this.options.gravity;
    this.alpha -= this.options.fade;
  }
}

