import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isArchived'
})
export class IsArchivedPipe implements PipeTransform {

  transform(arr:any [], invert?:boolean): any[] {
    if (arr){
      return arr.filter(val => !!val.isArchived === !!invert);
    }
  }

}
