import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DemoLandingComponent } from './demo-landing/demo-landing.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DemoLandingComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
})
export class UiWhitelabelDemoModule { }
