import * as _ from 'lodash';
import * as PIXI from 'pixi.js';
import * as _2 from '../_2';

import { ITileLayer, ITileDefinition, ITileset, ITiledMapData } from './TempMapData';
import { PathingMap, IPathingTile } from './PathingMap';
import { TiledSpritesheet } from './TiledSpritesheet';

export class Tilemap extends PIXI.Container {
  tileSpritesheet: TiledSpritesheet;
  tileSize: number;
  bounds: PIXI.Rectangle;

  backgroundLayers: PIXI.Container[] = [];
  backgroundSprites: PIXI.Sprite[][] = [];
  // gameObjects: {def: ILoadedObjectChunk, sprite: PIXI.Sprite}[] = [];
  spriteLayer = new PIXI.Container;
  pathLayer: PIXI.Graphics;

  pathingMap: PathingMap;

  currentBounds: PIXI.Rectangle;

  constructor(private tileset: ITileset, private tiledata: ITiledMapData, private renderer: PIXI.Renderer) {
    super();

    this.tileSize = tileset.data.tilewidth - 1;
    this.bounds = new PIXI.Rectangle(0, 0, this.tileSize * tiledata.width, this.tileSize * tiledata.height);

    this.tileSpritesheet = new TiledSpritesheet(tileset.data);
    this.tileSpritesheet.onLoad(() => {
      let offs: ITileDefinition[] = _.filter(tileset.defs, def => Boolean(def.zThis));
      // for (let i = 0; i < 30; i++) {
        _.forEach(tiledata.layers, layer => this.addLayer(layer, offs));
      // }

      if (!this.spriteLayer.parent) {
        this.addChild(this.spriteLayer);
      }

      this.updateBounds();
    });
    this.pathingMap = new PathingMap({ layers: tiledata.layers, definitions: tileset.defs });

    // this.overlayPathingMap(this.pathingMap.tiles);
  }

  getMapBounds(): PIXI.Rectangle {
    return new PIXI.Rectangle(0, 0, this.tiledata.width * this.tileSize, this.tiledata.height * this.tileSize);
  }

  addLayer(layerData: ITileLayer, defs: ITileDefinition[]) {
    if (layerData.type === 'objectgroup') {
      this.addChild(this.spriteLayer);
    } else {
      let layerWidth = layerData.width;
      let layer = new PIXI.Container;
      let sprites: PIXI.Sprite[] = [];

      layerData.data.forEach((index, i) => {
        let x = i % layerWidth;
        let y = Math.floor(i / layerWidth);

        let tile = this.tileSpritesheet.getPositionedTile(index, x, y);

        if (tile) {
          let def = _.find(defs, { index });
          if (def) {
            tile.y += def.zThis;
          }

          // renderer.render(tile, texture, false);
          layer.addChild(tile);
          sprites[i] = tile;
        }
      });

      // renderer.render(layer,texture);
      this.addChild(layer);
      this.backgroundLayers.push(layer);
      this.backgroundSprites.push(sprites);
    }
  }

  addObject(object: PIXI.DisplayObject) {
    this.spriteLayer.sortableChildren = true;

    object.zIndex = object.y;
    this.spriteLayer.addChild(object);
  }

  updateZIndex(object?: PIXI.DisplayObject) {
    // ** NOT VERY EFFICIENT CODE, WATCH TO SEE IF THIS NEEDS TO BE OPTIMIZED
    if (object) {
      object.zIndex = object.y;
      this.spriteLayer.sortChildren();
    }
  }

  overlayPathingMap(tiles: IPathingTile[], color?: number) {
    if (this.pathLayer) {
      this.pathLayer.destroy();
    }
    let layer = new PIXI.Graphics;

    for (let i = 0; i < tiles.length; i++) {
      layer.beginFill(color || (tiles[i].walkable ? 0x00ff00 : 0xff0000));
      layer.drawRect(tiles[i].loc.x * this.tileSize, tiles[i].loc.y * this.tileSize, this.tileSize, this.tileSize);
    }

    this.pathLayer = layer;
    this.addChildAt(layer, 1);
  }

  // tempRect: PIXI.Graphics = new PIXI.Graphics;
  updateBounds(localRect?: PIXI.Rectangle) {
    if (!localRect) {
      this.toggleVisibleRect(false, 0, 0, this.tiledata.width, this.tiledata.height);

      let rect = new PIXI.Rectangle(0, 0, this.tiledata.width, this.tiledata.height);
      this.currentBounds = rect;
      return;
    }
    let newBounds = new PIXI.Rectangle(
      Math.floor(localRect.x / this.tileSize / this.scale.x),
      Math.floor(localRect.y / this.tileSize / this.scale.y),
      Math.ceil(localRect.width / this.tileSize / this.scale.x) + 1,
      Math.ceil(localRect.height / this.tileSize / this.scale.y) + 1
    );

    // this.tempRect.clear();
    // this.tempRect.lineStyle(2, 0x00ffff);
    // this.tempRect.drawRect(localRect.x / this.scale.x, localRect.y / this.scale.x, localRect.width / this.scale.x, localRect.height / this.scale.x);
    // this.tempRect.lineStyle(2, 0xff00ff);
    // this.tempRect.drawRect(newBounds.left * this.tileSize, newBounds.top * this.tileSize, newBounds.width * this.tileSize, newBounds.height * this.tileSize);
    // this.addChild(this.tempRect);

    if (!this.currentBounds) {
      return;
    }
    if (newBounds.left !== this.currentBounds.left || newBounds.right !== this.currentBounds.right || newBounds.bottom !== this.currentBounds.bottom || newBounds.top !== this.currentBounds.top) {
      this.toggleVisibleRect(false, this.currentBounds.left, this.currentBounds.top, this.currentBounds.right, this.currentBounds.bottom);
      this.currentBounds = newBounds;
      this.toggleVisibleRect(true, this.currentBounds.left, this.currentBounds.top, this.currentBounds.right, this.currentBounds.bottom);
    }
  }

  toggleVisibleRect(state: boolean, left: number, top: number, right: number, bottom: number) {
    let columns = this.tiledata.width;

    for (let x = left; x < right; x++) {
      for (let y = top; y < bottom; y++) {
        let index = y * columns + x;
        this.backgroundSprites.forEach(sprites => {
          if (sprites[index]) {
            sprites[index].visible = state;
          }
        });
      }
    }
  }
}
