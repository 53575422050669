import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { FormControl } from '@angular/forms';
import { ChatpanelService } from '../../core/chatpanel.service';
import { IClassroom } from '../../data/collections/classrooms.types';
import { IAssignment } from '../../data/collections/assignments.types';
import { IItemCheckpoint } from '../../data/collections/booster-packs-item-checkpoints';
import { IItemPerformance, IItemAttemptSession } from '../../ui-student/boosterpacks.service';
import { LeaderboardService } from '../../core/leaderboard.service';
import { AuthService } from '../../core/auth.service';
import { serverTimestamp } from '../../data/timestamp';
import { ILeaderboardGroup, ILeaderboard, ILeaderboardScore, ILeaderboardGroupScore } from '../../data/cambridge/types';
import { firestore } from 'firebase/app';

interface IStatsPayloadTracker {
  trialId: string,
  yyyymmdd_latest: number,
}
interface IStatsPayload {
  trialId: string,
  numTeacherSignups: number,
  numTeacherLogins: number,
  numClasses: number,
  numStudentSignups: number,
  numStudentLogins: number,
  numHeadsUp: number,
  numHeadsDown: number,
  numQuestionsAnswered: number,
  numBPCheckpoints: number,
  numBPCheckpointsCompleted: number,
  numBPItems: number,
  numBPItemsCompleted: number,
}
interface IStatsPayloadDaily extends IStatsPayload {
  yyyymmdd: number,
}
interface IStatsPayloadAggr extends IStatsPayload {
  yyyymmdd_latest: number,
}
interface IStatsPayloadYearly extends IStatsPayloadAggr {
  year: number, 
}
interface IStatsPayloadMonthly extends IStatsPayloadAggr {
  year: number, 
  month: number, 
}
interface IStatsPayloadWeekly extends IStatsPayloadAggr {
  year: number, 
  week: number, 
}

interface ISessionsPayload {
  limitsHit:number, 
  sessions:IItemAttemptSession[],
  scoreFreq?: any,
  numItemsCompleted?: number,
  numUsers?: number,
  numUsersCompleted?: number,
}

@Component({
  selector: 'admin-user-accounts',
  templateUrl: './admin-user-accounts.component.html',
  styleUrls: ['./admin-user-accounts.component.scss']
})
export class AdminUserAccountsComponent implements OnInit {

  issuerTimeCreatedImportDetail: boolean;
  
  userSearchResults: IUser[];
  
  searchOptions = [
    {caption:'ID',            filterBy:'uid',        formControl: new FormControl('')},
    {caption:'First name',    filterBy:'firstName',   formControl: new FormControl('')},
    {caption:'Last name',     filterBy:'lastName',    formControl: new FormControl('')},
    {caption:'Display name',  filterBy:'displayName', formControl: new FormControl('')},
    {caption:'Trial ID',      filterBy:'trialId',     formControl: new FormControl('cambridge-stage6-2019')},
    {caption:'Email',         filterBy:'email',       formControl: new FormControl('')},
  ]
  userTaskSetForm: {uid:FormControl, taskSetGroupId:FormControl, role:FormControl} = {
    uid:new FormControl(''), 
    taskSetGroupId:new FormControl(''),
    role:new FormControl('')
  }
  userDistrictForm: {uid:FormControl, districtId:FormControl, role:FormControl} = {
    uid:new FormControl(''), 
    districtId:new FormControl(''),
    role:new FormControl('')
  }

  userActivityForm: {uid:FormControl} = {
    uid: new FormControl()
  }

  randomNamesForm: {listId:FormControl, districtId:FormControl, names:FormControl} = {
    listId: new FormControl(),
    districtId: new FormControl(),
    names: new FormControl(),
  }

  recentRegForm : {limit: FormControl, step: FormControl, } = {
    limit: new FormControl(10),
    step: new FormControl(1),
  }

  userUis = [
    '(all)',
    'teacher',
    'student',
  ]
  userUiFilter = new FormControl('teacher');

  timeCreatedImport = new FormControl();

  private userCollection: AngularFirestoreCollection<IUser>;

  constructor(
    public leaderboardService: LeaderboardService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {
    
  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
  }

  userMainSub:Subscription;
  setupNewMainUserSearch(valueChanges:Observable<IUser[]>){
    if (this.userMainSub){
      this.userMainSub.unsubscribe();
    }
    this.userMainSub = valueChanges.subscribe(res => {
      this.userSearchResults = res;
    })
  }

  studentActivityBoosterPackName = '';
  studentActivityBoosterPacks = [];
  studentActivityBoosterPackItems = [];
  studentActivityItemSessions = [];
  selectedActivityStudent;
  selectedActivityBoosterPack;
  selectedActivityItem;
  lookupStudentActivity(){
    this.selectedActivityStudent = null;
    this.studentActivityBoosterPacks = [];
    this.studentActivityBoosterPackItems = [];
    this.studentActivityItemSessions = [];
    let uid = this.userActivityForm.uid.value;
    this.afs
      .doc<IUser>('users/'+uid)
      .get()
      .toPromise()
      .then(res => {
        this.selectedActivityStudent = res.data();
      })
    this.afs
      .collection('boosterPacksPerformanceSummary', ref => ref.where('uid', '==', uid))
      .get()
      .toPromise()
      .then(res => {
        res.docs.forEach(doc => {
          let boosterPackSummary:any = {
            ... doc.data(),
            __summaryId: doc.id,
            name: 'booster'
          }
          this.afs
            .doc('boosterPacks/'+boosterPackSummary.boosterPackId)
            .get()
            .toPromise()
            .then(res => {
              let boosterPack = res.data();
              boosterPackSummary.name = boosterPack.caption;
              boosterPackSummary.name
            })
          if (boosterPackSummary.leaderboardId){
            this.afs
              .doc('leaderboards/'+boosterPackSummary.leaderboardId)
              .get()
              .toPromise()
              .then(res => {
                boosterPackSummary._leaderboard = res.data();
              })
          }
          // console.log('boosterPackSummary', boosterPackSummary)
          this.studentActivityBoosterPacks.push(boosterPackSummary);
        })
      })
  }


  selectActivityBP(bpActivity){
    this.selectedActivityBoosterPack = bpActivity;
    this.studentActivityItemSessions = [];
    let itemsAccessed = this.studentActivityBoosterPackItems = [];
    let uid = this.userActivityForm.uid.value;
    this.afs
      .collection('boosterPacksItemPerformances', ref=>ref
        .where('boosterPackId', '==', bpActivity.boosterPackId)
        .where('uid', '==', uid)
      )
      .get()
      .toPromise()
      .then(res => {
        res.docs.forEach(doc => {
          let itemCompletionSummary:any = {
            ... doc.data(),
            __id: doc.id,
          }
          this.afs
            .doc('boosterPackItems/'+itemCompletionSummary.boosterPackItemId)
            .get()
            .toPromise()
            .then(res => {
              let itemInfo = res.data();
              itemCompletionSummary.name = itemInfo.name;
              itemCompletionSummary.itemType = itemInfo.itemType;
            })
          // console.log('itemCompletionSummary', itemCompletionSummary)
          itemsAccessed.push(itemCompletionSummary)
        });
      })
  }

  selectActivityItem(itemActivity){
    this.selectedActivityItem = itemActivity;
    this.studentActivityItemSessions = [];
    console.log('itemActivity', itemActivity)
    let uid = this.userActivityForm.uid.value;
    this.afs
      .collection('boosterPacksItemAttemptSession', ref=>ref
        .where('boosterPackItemId', '==', itemActivity.boosterPackItemId)
        .where('uid', '==', uid)
      )
      .get()
      .toPromise()
      .then(res => {
        res.docs.forEach(doc => {
          let session:any = {
            ... doc.data(),
            __id: doc.id,
          }
          this.studentActivityItemSessions.push(session);
        });
      });
  }

  fetchTaskInfo(){
    alert('This feature is currently disabled')
  }
  
  // boosterPacksItemPerformances (how they did)
  // leaderboardUserItemScore

  // boosterPacksItemAttemptSession


  // userUiFilter
  searchUsers(filterBy:string, filterValue:string) {
    let limit = this.recentRegForm.limit.value;
    if (!limit){
      limit = 1;
    }
    this.setupNewMainUserSearch(
      this.afs
      .collection<IUser>('users', ref => {
          let _ref;
          _ref = ref.where(filterBy, '>=', filterValue);
          _ref = this.applyUserUiFilter(_ref);
          _ref = _ref.orderBy(filterBy);
          _ref = _ref.limit(limit);
          return _ref
        }
      )
      .valueChanges()
    )
  }

  applyUserUiFilter(ref){
    let userUiFilter = this.userUiFilter.value;
    if (userUiFilter && userUiFilter !== this.userUis[0]){
      // console.log('applyUserUiFilter', userUiFilter)
      return ref.where('ui', '==', userUiFilter);
    }
    return ref;
  }

  isShowingTeacherClasses:boolean;
  isShowingTeacherStudents:boolean;
  isShowingTeacherAssignments:boolean;
  isShowingTeacherCheckpoints:boolean;
  isShowingTeacherItems:boolean;
  getTeacherClasses(){
    this.isShowingTeacherClasses = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      if (user.classroomsAsTeacher){
        userExtended.__numClasses = user.classroomsAsTeacher.length
      }
      else {
        userExtended.__numClasses = 0;
      }
    })
  }
  getTeacherStudents(){
    this.isShowingTeacherStudents = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      userExtended.__numStudents = 0
      if (user.classroomsAsTeacher){
        user.classroomsAsTeacher.forEach(classroomId => {
          this.afs
            .doc<IUser>('classrooms/'+classroomId)
            .get()
            .toPromise()
            .then( res => {
              let classroom = <IClassroom>res.data();
              if (classroom.currentStudents){
                userExtended.__numStudents += classroom.currentStudents.length;
              }
              // console.log('getTeacherStudents', classroom)
            })
        })
      }
    })
  }
  getTeacherAssessments(){
    this.isShowingTeacherAssignments = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      userExtended.__assignments = [];
      userExtended.__numHeadsUp = 0
      userExtended.__numHeadsDown = 0
      if (user.classroomsAsTeacher){
        user.classroomsAsTeacher.forEach(classroomId => {
          this.afs
            .collection<IUser>('assignments', ref => ref.where('classroom', '==', classroomId))
            .get()
            .toPromise()
            .then( res => {
              // console.log('getTeacherAssessments', res.docs.length);
              res.docs.forEach(doc => {
                userExtended.__assignments.push(doc.id);
                let assignment = <IAssignment>doc.data();
                if (assignment.isHeadsUp){
                  userExtended.__numHeadsUp ++;
                }
                else{
                  userExtended.__numHeadsDown ++;
                }
              })
            })
        })
      }
    })
  }
  getTeacherQuestionsAnswered(){
    this.isShowingTeacherAssignments = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      userExtended.__numQuestionsAnswered = 0
      if (userExtended.__assignments){
        userExtended.__assignments.forEach(assignmentId => {
          this.afs
            .collection('assignmentTaskSubmissions', ref => ref.where('assignmentId', '==', assignmentId))
            .get()
            .toPromise()
            .then( res => {
              res.docs.forEach(doc => {
                userExtended.__numQuestionsAnswered ++;
              })
            })
        })
      }
    })
  }
  getTeacherCheckpoints(){
    this.isShowingTeacherCheckpoints = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      userExtended.__numBPCheckpoints = 0
      userExtended.__numBPCheckpointsCompleted = 0
      if (user.classroomsAsTeacher){
        user.classroomsAsTeacher.forEach(classroomId => {
          this.afs
            .collection<IUser>('boosterPacksItemCheckpoints', ref => ref.where('cause_classroomId', '==', classroomId))
            .get()
            .toPromise()
            .then( res => {
              console.log('getTeacherCheckpoints', res.docs.length);
              res.docs.forEach(doc => {
                let checkpoint = <IItemCheckpoint>doc.data();
                userExtended.__numBPCheckpoints ++;
                if (checkpoint.isComplete){
                  userExtended.__numBPCheckpointsCompleted ++;
                }
              })
            })
        })
      }
    })
  }
  getTeacherBoosterActivity(){
// 
    this.isShowingTeacherItems = true;
    this.userSearchResults.forEach(user => {
      let userExtended = <any>user;
      userExtended.__numBPItems = 0
      userExtended.__numBPItemsCompleted = 0
      if (user.classroomsAsTeacher){
        user.classroomsAsTeacher.forEach(classroomId => {
          this.afs
            .collection<IUser>(
              'boosterPacksItemAttemptSession', 
              ref => ref
                .where('classroomIds', 'array-contains', classroomId)
            )
            .get()
            .toPromise()
            .then( res => {
              res.docs.forEach(doc => {
                let checkpoint = <IItemCheckpoint>doc.data();
                userExtended.__numBPItems ++;
                if (checkpoint.isComplete){
                  userExtended.__numBPItemsCompleted ++;
                }
              })
            })
        })
      }
    })
  }

  prevRegPage(){
    this.recentRegForm.step.setValue(Math.max(1, this.recentRegForm.step.value - 1));
    this.showRecentRegistrations()
  }
  nextRegPage(){
    this.recentRegForm.step.setValue(this.recentRegForm.step.value + 1);
    // console.log('this.recentRegForm.step.value', this.recentRegForm.step.value)
    this.showRecentRegistrations()
  }
  showRecentRegistrations() {
    // go back to page 1
    this._showRecentRegistrations();
  }
  _showRecentRegistrations() {
    let limit = this.recentRegForm.limit.value;
    let page = this.recentRegForm.step.value;
    this.setupNewMainUserSearch (
      this.afs
      .collection<IUser>(
        'users', 
        ref => ref.orderBy('timeJoined', 'desc')
                  .limit(limit)
                  // .startAt((page-1)*limit)
      )
      .valueChanges()
    )
  }


  // trialId: this.auth.getUserTrialId(),
  // instScopeId: this.auth.getUserInstScope(),


  // cambridge-stage6-2019
  updateUserTrialId(uid:string){
    this.updateUserFieldWithPrompt(uid, 'trialId', 'enter the new trial id')
  }
  updateUserUI(uid:string){
    this.updateUserFieldWithPrompt(uid, 'ui', 'enter the new UI')
  }
  updateUserFieldWithPrompt(uid:string, fieldProp:string, promptCaption:string) {
    let newVal = window.prompt(promptCaption);
    if (newVal){
      let payload = {};
      payload[fieldProp] = newVal;
      this.afs
        .doc('users/'+uid)
        .update(payload)
        .then(()=>{
          console.log('updated ', fieldProp, 'on', uid)
        })
    }
  }

  assignUserTimeCreated(){
    let rows = JSON.parse(this.timeCreatedImport.value);
    Promise.all(
      rows.map(data => {
        return this.afs
          .doc('users/'+data.uid)
          .update({
            timeJoined: new Date(data.timeJoined), 
          })
          .then(()=>{
            console.log('updated '+data.uid)
          })
      })
    )
    .then( ()=>{
      alert('updated time stamps')
    })
  }

  addUserToTaskSetGroup(){
    let uid = this.userTaskSetForm.uid.value;
    let role = this.userTaskSetForm.role.value;
    let project = this.userTaskSetForm.taskSetGroupId.value;
    if (!uid || !role || !project){
      alert('fill all the fields');
      throw new Error();
    }
    this.afs
      .collection('customTaskSetProjectRoles')
      .add({
        project, 
        role, 
        uid
      })
      .then(()=>{
        alert('Added user to group');
      })
  }

  generateLeaderboardTeamDummyData(){
    const leaderboardId = 'hc7CCVHjznx8eo5bAtap';
    const numStudents = Math.floor(3+Math.random()*20)
    const name = 'Fake '+ Math.floor(Math.random()*1000000);
    const score = Math.floor(10 + Math.random()*100)*100;
    const baseScore = numStudents * score;
    const moderator = this.auth.getUid();
    const isFake = true;
    this.afs.collection('leaderboardGroups').add({
      leaderboardId,
      name,
      numStudents,
      timeCreated: serverTimestamp(),
      moderator,
      isFake,
    }).then(res => {
      const groupId = res.id;
      this.afs.collection('leaderboardGroupScore').add({
        leaderboardId,
        groupId,
        hasMinStudents: true,
        baseScore,
        score,
        name,
        numStudents,
        timeModified: serverTimestamp(),
        isFake,
      }).then(res => {
        console.log('Created both entries!')
      });
    });
  }

  createRandomTeamNames(){
    const listId = this.randomNamesForm.listId.value;
    const districtId = this.randomNamesForm.districtId.value;
    const names:string[] = this.randomNamesForm.names.value.split('\n');

    const uid = this.auth.getUid();

    this.afs.doc('leaderboardTeamNamesLists/'+listId).get().toPromise().then( res => {
      if (res.exists){
        alert('List ID is already used');
        return;
      }
      else{
        return this.afs.doc('leaderboardTeamNamesLists/'+listId).set({
          listId,
          uid,
          districtId,
          names: names.length,
          timeCreated: serverTimestamp(),
        }).then( res => {
          Promise.all(names.map((name, i) => {
            return this.afs.collection('leaderboardTeamNames').add({
              listId,
              uid,
              districtId,
              i,
              name, 
              timeCreated: serverTimestamp(),
            }).then( resAdd => {
              console.log()
            });
          }))
        })
      }
    })
      
  }

  inputOutput = new FormControl();

  inputUsers_outputUids(){
    const users:IUser[] = JSON.parse(this.inputOutput.value)
    const uids = users.map(u => u.uid);
    this.inputOutput.setValue(JSON.stringify(uids))
  }
  inputItemSessions_outputItemSessionsCompleted(){
    const sessionsPayload:ISessionsPayload = JSON.parse(this.inputOutput.value);
    const newUserTrack = new Map();
    const newUserCompletionTrack = new Map();
    sessionsPayload.numItemsCompleted = 0;
    sessionsPayload.numUsers = 0;
    sessionsPayload.numUsersCompleted = 0;
    sessionsPayload.scoreFreq = {};
    sessionsPayload.sessions.forEach(session => {
      let isNewUser:boolean = !newUserTrack.has(session.uid);
      let isNewUserCompletion:boolean = false;
      if (!sessionsPayload.scoreFreq[session.score]){
        sessionsPayload.scoreFreq[session.score] = 0;
      }
      if (session.isComplete){
        isNewUserCompletion = !newUserCompletionTrack.has(session.uid);
        sessionsPayload.numItemsCompleted ++;
        sessionsPayload.scoreFreq[session.score] ++;
      }
      if (isNewUser){
        sessionsPayload.numUsers ++;
        newUserTrack.set(session.uid, true);
      }
      if (isNewUserCompletion){
        sessionsPayload.numUsersCompleted ++;
        newUserCompletionTrack.set(session.uid, true);
      }
    })
    console.log('inputItemSessions_outputItemSessionsCompleted', sessionsPayload);
    this.inputOutput.setValue(JSON.stringify(sessionsPayload))
  }

  inputLeaderboardGroups_mergeStudents(){
    // this is written for what I would expect to be a very special case where the foreignClassId is overlapping multiple leaderboard groups in the same leaderboard ID. I'm not sure why this is occuring as of yet.
    // this is also lazy because it does nto recompute scores at even a basical level
    const leaderboardGroups:ILeaderboardGroup[] = JSON.parse(this.inputOutput.value);
    const targetGroup:ILeaderboardGroup = leaderboardGroups[0];
    Promise.all(
      leaderboardGroups.map(group => {
        if (group !== targetGroup){
          // put students into the target
          targetGroup.students = targetGroup.students.concat(group.students)
          // obfuscate the foreignClass id reference and empty the students
          return this.afs.doc('leaderboardGroups/'+group.__id).update({
            students: [],
            timeMerged: serverTimestamp(),
            mergedInto_groupId: targetGroup.__id,
          })
          .then(()=>{
            console.log('merged:', group.name);
          })
        }
      })
    ).then(()=>{
      this.afs.doc('leaderboardGroups/'+targetGroup.__id).update({
        students: targetGroup.students,
        numStudents: targetGroup.students.length
      })
      console.log('merged students into:', targetGroup.__id);
    })
  }

  


  inputGroupsWithTeacherAsStudent_updateGroupCount(){
    const groups:ILeaderboardGroup[] = JSON.parse(this.inputOutput.value);
    Promise.all(
      groups.map( group => {
        let numStudents = group.numStudents - 1;
        if (!group.moderator){
          return;
        }
        return this.afs
          .doc('leaderboardGroups/' + group.__id)
          .update({
            students: <any>firestore.FieldValue.arrayRemove(group.moderator || ''),
            numStudents,
          })
          .then( () => {
            return this.afs
              .collection('leaderboardGroupScore', ref=> ref.where('groupId', '==', group.__id))
              .get()
              .toPromise()
              .then(res =>{
                return Promise.all(
                  res.docs.map(doc => {
                    let newScore = doc.data().baseScore / numStudents;
                    if (isNaN(newScore) || !newScore){
                      newScore = 0;
                    }
                    return this.afs
                      .doc('leaderboardGroupScore/'+doc.id)
                      .update({
                        numStudents,
                        score: newScore,
                      })
                  })
                )
              })
          })
      })
    )
    .then (()=>{
      console.log('update complete')
    })
  }

  inputGroups_outputGroupsWithTeacherAsStudent(){
    const groups:ILeaderboardGroup[] = JSON.parse(this.inputOutput.value);
    const targetGroups = _.filter(groups, group => {
      return _.includes(group.students, group.moderator)
    });
    console.log('groups', targetGroups)
    this.inputOutput.setValue(JSON.stringify(targetGroups));
  }

  inputGroups_outputGroupsWithSameForeignClass(){
    const groups:ILeaderboardGroup[] = JSON.parse(this.inputOutput.value);
    const seenForeingClassId = new Map();
    const duplicatesRef = new Map();
    const duplicatesTrack = new Map();
    const duplicates = [];
    groups.forEach(group => {
      const key = [group.leaderboardId, group.foreignClassId].join(';');
      if (!duplicatesRef.has(key)){
        let list = [];
        duplicatesRef.set(key, list);
      }
      duplicatesRef.get(key).push(group);
      if (seenForeingClassId.has(key)){
        if (!duplicatesTrack.has(key)){
          duplicatesTrack.set(key, true);
          duplicates.push(duplicatesRef.get(key));
        }
      }
      seenForeingClassId.set(key, true);
    });
    console.log('duplicates', duplicates)
    this.inputOutput.setValue(JSON.stringify(duplicates));
  }

  inputLeaderboards_outputCollectionEntries(collectionId:string, leaderboardParam:string='leaderboardId'){
    const leaderboards:ILeaderboard[] = JSON.parse(this.inputOutput.value);
    let entries:any[] = [];
    Promise.all(
      leaderboards.map(leaderboard => {
        return this.afs
          .collection(collectionId, ref=> ref 
            .where(leaderboardParam, '==', leaderboard.__id)
          )
          .get()
          .toPromise()
          .then( res => {
            entries = entries.concat( res.docs.map(doc =>{
              return {
                ... <any>doc.data(),
                __id: doc.id,
              }
            }) );
          })
      })
    )
    .then(()=>{
      console.log(collectionId, entries)
      // this.inputOutput.setValue(JSON.stringify(entries));
    })
  }

  inputStudentScoreOverrides_postOverride(){
    const studentScoreOverrides:ILeaderboardGroupScore[] = JSON.parse(this.inputOutput.value);
    studentScoreOverrides.map(studentScore => this.afs
      .collection('leaderboardstudentScore', ref=>ref
        .where('uid', '==', studentScore['uid'])
        .where('leaderboardId', '==', studentScore['leaderboardId'])
        .limit(1) 
      )
      .get()
      .toPromise()
      .then(res=>{
        if (res.docs.length > 0){
          const __id = res.docs[0].id;
          this.afs
            .doc('leaderboardUserBoosterScore/'+__id)
            .update({
              score: studentScore['score_recomputed'],
            })
            .then(()=>{
              console.log('Updated', __id, studentScore);
            })
        }
        else{
          console.error('Cant find matching student', studentScore);
        }
      })
    )
  }

  inputGroupScoreOverrides_postOverride(){
    const groupScoreOverrides:ILeaderboardGroupScore[] = JSON.parse(this.inputOutput.value);
    const GROUP_ID_COL = 'Groups.__id';
    groupScoreOverrides.map(groupScore => this.afs
      .collection('leaderboardGroupScore', ref=>ref.where('groupId', '==', groupScore[GROUP_ID_COL]).limit(1) )
      .get()
      .toPromise()
      .then(res=>{
        if (res.docs[0]){
          const __id = res.docs[0].id;
          this.afs
            .doc('leaderboardGroupScore/'+__id)
            .update({
              score: groupScore.score,
              baseScore: groupScore.baseScore,
              numStudents: groupScore.numStudents,
            })
            .then(()=>{
              console.log('Updated', __id, groupScore);
            })
        }
        else{
          console.error('Cant find matching group', groupScore);
        }
      })
    )
  }

  inputLeaderboards_outputLeaderboardIndivScores(){
    this.inputLeaderboards_outputCollectionEntries('leaderboardUserBoosterScore');
  }
  inputLeaderboards_outputGroupScores(){
    this.inputLeaderboards_outputCollectionEntries('leaderboardGroupScore');
  }
  inputLeaderboards_outputStudentItemScores(){
    this.inputLeaderboards_outputCollectionEntries('leaderboardUserItemScore');
  }
  inputLeaderboards_outputStudentItemScores2(){
    this.inputLeaderboards_outputCollectionEntries('boosterPacksItemPerformances');
  }
  inputLeaderboards_outputStudentItemSessions(){
    this.inputLeaderboards_outputCollectionEntries('boosterPacksItemAttemptSession');
  }

  inputLeaderboardIndivScores_outputFilteredToDuplicate(){
    const scores:ILeaderboardScore[] = JSON.parse(this.inputOutput.value);
    const usersTrack = new Map();
    const users:{uid, leaderboardId, count:number, list:ILeaderboardScore[]}[] = [];
    let dupUsersTally = 0;
    scores.forEach(score => {
      const key = [score.uid, score.leaderboardId].join(';')
      let storage:{uid, leaderboardId, count:number, list:ILeaderboardScore[]} = usersTrack.get(key);
      if (!storage){
        storage = {
          uid: score.uid,
          leaderboardId: score.leaderboardId,
          count: 0,
          list: [],
        };
        usersTrack.set(key, storage)
        users.push(storage);
      }
      storage.count ++;
      storage.list.push(score)
      if (storage.count == 2){
        dupUsersTally ++;
      }
    })
    const duplicatedScores = _.filter(users, score => score.count > 1);
    console.log('duplicatedScores 1', dupUsersTally);
    console.log('duplicatedScores 2', duplicatedScores);
    this.inputOutput.setValue(JSON.stringify(duplicatedScores));
  }

  inputFilteredToDuplicate_postMerge(){
    const duplicatedScores:{uid, leaderboardId, toDelete?:string[], points?:number, count:number, list:ILeaderboardScore[]}[] = JSON.parse(this.inputOutput.value);
    const entryUpdates:{id: string, score:number}[] = []
    const entryDeletions:string[] = []
    // aggregate
    duplicatedScores.forEach(userDup => {
      userDup.points = 0;
      let largestSeconds = userDup.list[0].timeModified.seconds;
      let latestEntry = userDup.list[0];
      userDup.list.forEach(score => {
        userDup.points += score.score
        if (score.timeModified.seconds > largestSeconds){
          largestSeconds = score.timeModified.seconds;
          latestEntry = score;
        }
      })
      entryUpdates.push({id: latestEntry.__id, score: userDup.points})
      userDup.toDelete = [];
      userDup.list.forEach(score => {
        if (score !== latestEntry){
          userDup.toDelete.push(score.__id);
          entryDeletions.push(score.__id)
        }
      });
    })
    
    // update scores of latest entry

    entryUpdates.map(entry => {
      this.afs.doc('leaderboardUserBoosterScore/'+entry.id).update({
        score: entry.score,  
      });
    })
    
    // delete the other entries
    
    entryDeletions.map(id => {
      this.afs.doc('leaderboardUserBoosterScore/'+id).delete();
    })

    console.log('updates and deletions', entryUpdates, entryDeletions);
    this.inputOutput.setValue(JSON.stringify(duplicatedScores));
  }

  inputLeaderboards_outputGroups(){
    const leaderboards:ILeaderboard[] = JSON.parse(this.inputOutput.value);
    let groups:ILeaderboardGroup[] = [];
    Promise.all(
      leaderboards.map(leaderboard => {
        return this.afs
          .collection('leaderboardGroups', ref=> ref 
            .where('leaderboardId', '==', leaderboard.__id)
          )
          .get()
          .toPromise()
          .then( res => {
            groups = groups.concat( res.docs.map(doc =>{
              return {
                ... <any>doc.data(),
                __id: doc.id,
              }
            }) );
          })
      })
    )
    .then(()=>{
      console.log('groups', groups)
      this.inputOutput.setValue(JSON.stringify(groups));
    })
  }
  inputDistrict_outputLeaderboards(){
    const district:string = this.inputOutput.value;
    const now = new Date();
    this.afs
    .collection('leaderboards', ref=> ref 
      .where('district', '==', district)
    )
    .get()
    .toPromise()
    .then( res => {
      const leaderboards:ILeaderboard[] = [];
      res.docs.forEach(doc => {
        const data:ILeaderboard = {
          ... <any>doc.data(),
          __id: doc.id,
        }
        if (data.isArchived){
          return;
        }
        if (data.classTypes && data.classTypes.length === 1 && data.classTypes[0] === 'lux_testing'){
          return;
        }
        // if (data.dateClosed && data.dateClosed.seconds*1000 < now.valueOf() ){
        //   return;
        // }
        leaderboards.push(data);
      })
      console.log('leaderboards', leaderboards)
      this.inputOutput.setValue(JSON.stringify(leaderboards));
      
    })

  }

  inputUids_outputTotalLeaderboardScore(){
    const uids:string[] = JSON.parse(this.inputOutput.value);
    let score = 0;
    Promise.all(
      uids.map( uid => {
        return this.afs
          .collection('leaderboardUserBoosterScore', ref=> ref 
            .where('uid', '==', uid)
          )
          .get()
          .toPromise()
          .then( res => {
            res.docs.forEach(doc => {
              score += doc.data().score;
            })
          })
      })
    )
    .then(()=>{
      console.log('TotalLeaderboardScore', score)
      this.inputOutput.setValue(JSON.stringify(score));
    })
  }

  inputUids_outputLeaderboardGroups(){
    const uids:string[] = JSON.parse(this.inputOutput.value);
    const leaderboardGroupTracker:Map<string, boolean> = new Map();
    const leaderboardGroups:ILeaderboardGroup[] = [];
    Promise.all(
      uids.map( uid => {
        return this.afs
          .collection('leaderboardGroups', ref=> ref 
            .where('students', 'array-contains', uid)
          )
          .get()
          .toPromise()
          .then( res => {
            res.docs.forEach(doc => {
              if (!leaderboardGroupTracker.has(doc.id)){
                leaderboardGroupTracker.set(doc.id, true);
                leaderboardGroups.push({
                  ... <ILeaderboardGroup>doc.data(),
                  __id: doc.id,
                })
              }
            })
          })
      })
    )
    .then(()=>{
      console.log('leaderboardGroups', leaderboardGroups)
      this.inputOutput.setValue(JSON.stringify(leaderboardGroups));
    })
  }

  inputUids_outputItemSessions(){
    const inputStr = this.inputOutput.value;
    const uids:string[] = JSON.parse(inputStr)
    return this.getItemSessionsFromUids(uids).then(payload =>{
      this.inputOutput.setValue(JSON.stringify(payload));
    });
  }
  inputUids_outputUsers(){
    const uids = JSON.parse(this.inputOutput.value);
    this.getUserAccountsFromUids(uids).then(users => {
      console.log('users', users)
      this.inputOutput.setValue(JSON.stringify(users))
    })
  }
  inputMtic2UserIdList_outputUserAccounts(){
    const inputStr = this.inputOutput.value;
    const foreignIds = inputStr.split('\n');
    return this.getUserAccountsFromForeignIds(foreignIds).then(userAccounts =>{
      this.inputOutput.setValue(JSON.stringify(userAccounts))
    });
  }
  inputUserBestItemScores_outputDiscrepancies(){
    const users = JSON.parse(this.inputOutput.value);
    let tally = 0;
    let tallyCompleted = 0;
    const discrepancies = {
      noRecord: [],
      doubleRecords: [],
      // over: [],
      under: [],
      over: 0,
      equal: 0,
    }
    const throtlog = _.throttle(()=>{
      console.log('discrepancies', tally, discrepancies);
      this.inputOutput.setValue(JSON.stringify(discrepancies));
      fetchSome();
    }, 5000);
    const fetchSome = _.throttle(()=>{
      const portion = requestPayloads.splice(0, 10)
      if (portion.length > 0 && tally < 1000000){
        portion.forEach(payload => {
          tally ++;
          this.afs
            .collection('boosterPacksItemPerformances', ref=> ref 
              .where('uid', '==', payload.uid)
              .where('boosterPackItemId', '==', payload.itemId)
              .limit(2)
            )
            .get()
            .toPromise()
            .then( res => {
              const docs:any[] = res.docs.map(doc => {
                return {
                  ... doc.data(),
                  __id: doc.id
                }
              })
              if (docs.length < 1){
                discrepancies.noRecord.push(payload) 
              }
              else if (docs.length > 1){
                discrepancies.doubleRecords.push(docs);
              }
              else{
                const doc = docs[0];
                doc.recomputedScore = payload.score;
                if (doc.score === doc.recomputedScore) {
                  discrepancies.equal ++;
                }
                else if (!doc.score || doc.score < doc.recomputedScore){
                  discrepancies.under.push(doc);
                }
                else if (doc.score > doc.recomputedScore){
                  discrepancies.over ++;
                  // discrepancies.over.push(doc);
                }
              }
              throtlog();
            })
        })
      }
      this.inputOutput.setValue(JSON.stringify(discrepancies));
    }, 5000); // 10 requests every 5 seconds
    
    const requestPayloads = [];
    _.each(users, (items, uid) => {
      _.each(items, (score, itemId)=>{
        requestPayloads.push({uid, itemId, score});
      })
    });
    fetchSome();
  }
  outputBestItemScoresLast24(){
    const limitStr = this.inputOutput.value;
    if (limitStr){
      const limit = Math.min(20000, 1*limitStr);
      let timeStart = new Date();
      timeStart.setDate(timeStart.getDate()-1);
      this.getItemSessionsByDateBound(timeStart, limit)
        .then(sessions =>{
          this.parseUsersBestScoreFromSession(sessions)
            .then(users => {
              console.log('outputBestItemScoresLast24', users);
              this.inputOutput.setValue(JSON.stringify(users));
            })
          ;
        })
    }
    
  }
  parseUsersBestScoreFromSession(sessions:IItemAttemptSession[]){
    return new Promise((resolve) => {
      const users = {};
      sessions.forEach(session => {
        if (session.isComplete){
          let user:any; // itemId, score
          if (!users[session.uid]){
            users[session.uid] = {};
          }
          user = users[session.uid];
          if (!user[session.boosterPackItemId]){
            user[session.boosterPackItemId] = 0
          }
          if (session.score > user[session.boosterPackItemId]){
            user[session.boosterPackItemId] = session.score;
          }
        }
      })
      resolve(users)
    });
  }
  getItemSessionsByDateBound(dateFrom:Date, limit:number = 20 /* total entries */){
    const sessions:IItemAttemptSession[] = [];
    return this.afs
      .collection('boosterPacksItemAttemptSession', ref => ref
        .where('timeStart', '>', dateFrom)
        .limit(limit)
      )
      .get()
      .toPromise()
      .then( res =>{
        // console.warn('getItemSessionsFromUids > ', uid, res.docs.length);
        let sessions:IItemAttemptSession[] = res.docs.map(doc => {
          return {
            ... <IItemAttemptSession>doc.data(),
            __id: doc.id
          }
        })
        console.log('getItemSessionsByDateBound >> ', sessions);
        return sessions;
      })
  }

  getItemSessionsFromUids(uids:string[]){
    let limitsHit = 0;
    const limit = 100; // entries per user
    const sessions:IItemAttemptSession[] = [];
    return Promise.all(uids.map(uid => {
      return this.afs
        .collection('boosterPacksItemAttemptSession', ref => ref
          .where('uid', '==', uid)
          .limit(100)
        )
        .get()
        .toPromise()
        .then( res =>{
          console.warn('getItemSessionsFromUids > ', uid, res.docs.length);
          if (res.docs.length === limit) {
            limitsHit ++;
          }
          res.docs.forEach(doc => {
            sessions.push(<IItemAttemptSession>doc.data())
          })
        })
    }))
    .then( () => {
      const output:ISessionsPayload = {limitsHit, sessions}
      // console.log('getItemSessionsFromUids >> ', output);
      return output;
    })
  }

  getUserAccountsFromUids(uids:string[]){
    const userAccounts:IUser[] = [];
    return Promise.all(uids.map(uid => {
      return this.afs
        .doc('users/'+uid)
        .get()
        .toPromise()
        .then( doc =>{
          userAccounts.push(<IUser>doc.data())
        })
    }))
    .then( () => {
      return userAccounts;
    })
  }
  getUserAccountsFromForeignIds(foreignIds:string[]){
    const userAccounts:IUser[] = [];
    return Promise.all(foreignIds.map(foreignId => {
      return this.afs
        .collection('users', ref => ref
          .where('foreignUid', '==', foreignId)
          .limit(1)
        )
        .get()
        .toPromise()
        .then( res =>{
          res.docs.forEach(doc => {
            userAccounts.push(<IUser>doc.data())
          })
        })
    }))
    .then( () => {
      return userAccounts;
    })
  }

  outputUserCompletions(){

  }


  addUserToDistrict(){
    let uid = this.userDistrictForm.uid.value;
    let role = this.userDistrictForm.role.value;
    let districtId = this.userDistrictForm.districtId.value;
    if (!uid || !role || !districtId){
      alert('fill all the fields');
      throw new Error();
    }
    this.afs
      .collection('districtRoles')
      .add({
        districtId, 
        role, 
        uid
      })
      .then(()=>{
        alert('Added user to group');
      })
  }

  getDistricts(){
    return this.leaderboardService.getDistricts();
  }

}
