import * as _ from 'lodash';

import { FDGElement } from "./FDG/FDGElement";
import { DragonSprite } from "./DragonAvatar/DragonSprite";
import { AvatarScene } from "./AvatarScene";
import { AvatarBubbles } from "./AvatarBubbles";
import { ElementRef } from "@angular/core";
import { ILoadedCosmeticsEquipped, ILoadedCosmetic } from "./DragonAvatar/DragonInterfaces";
import { STANDALONE_FRAME_W, STANDALONE_BASE_SCALE, STANDALONE_FRAME_BOTTOM_PADDING, STANDALONE_BUBBLE_RADIUS } from '../constants/constants';

export interface ISingleAvatarDisplay {
  pixiViewport: ElementRef;
  fullSized: boolean;
  sendMove: (e:{x: number, y: number}) => void;
  defaultAnimation: string;
  startingCosmetics: ILoadedCosmeticsEquipped;
}

export class SingleAvatarDisplay {
  private parentElement: any;

  private currentSize: boolean = false;
  private changingSizes = 0;
  private onChangingSizesComplete: () => void;

  private bubbleRender: AvatarBubbles;
  private sceneRender: AvatarScene;

  private myAvatar: { bubble?: FDGElement, avatar: DragonSprite };

  constructor(private settings: ISingleAvatarDisplay) {
    this.initializeAvatarRender(settings.pixiViewport, settings.fullSized);
    this.loadPlayerAvatar(settings.startingCosmetics);
  }

  addCosmetic = (cosmetic: ILoadedCosmetic) => {
    if (!this.myAvatar) {
      return;
    }

    this.myAvatar.avatar.addCosmetic(cosmetic);
  }

  getScreenshot(callback: (data:{blob: Blob, type: 'screenshot' | 'avatar'}) => void ) {
    if (this.changingSizes <= 0) {
      this.takeScreenshot(callback);
    } else {
      this.onChangingSizesComplete = () => this.getScreenshot(callback);
    }
  }

  changeZoom(big?: boolean) {
    if (this.currentSize === big) {
      return;
    }
    this.changingSizes++;
    this.currentSize = big;
    this.bubbleRender.changeView(this.myAvatar, big, () => {
      this.changingSizes--;
      if (this.changingSizes <=0) {
        if (this.onChangingSizesComplete) {
          this.onChangingSizesComplete();
          this.onChangingSizesComplete = undefined;
        }
      }
    });
  }
  
  performAnimation = (slug: string) => {
    if (slug) {
      if (this.myAvatar) {
        this.myAvatar.avatar.display.animation.fadeIn(slug);
      }
    }
  }
  
  private initializeAvatarRender (pixiViewport: ElementRef, fullSized?: boolean) {
    const div: HTMLDivElement = pixiViewport.nativeElement;
    this.parentElement = div.closest('div .avatar-space');
    if (fullSized) {
      this.sceneRender = new AvatarScene(div, {
        width: this.parentElement.offsetWidth,
        height: this.parentElement.offsetHeight,
      });
    } else {
      this.bubbleRender = new AvatarBubbles(div, {
        width: this.parentElement.offsetWidth,
        height: this.parentElement.offsetHeight
      });
    }
  }

  private loadPlayerAvatar = (data: ILoadedCosmeticsEquipped) => {
    if (this.sceneRender) {
      this.myAvatar = {
        avatar: this.sceneRender.addCharacter(
          data.body, 
          data.cosmetics, 
          { 
            x: STANDALONE_FRAME_W/2, 
            y: this.sceneRender.pixi.stageBorders.height - STANDALONE_FRAME_BOTTOM_PADDING, 
            scale: STANDALONE_BASE_SCALE, 
          }
        )
      };
    } 
    else {
      this.myAvatar = this.bubbleRender.addCharacterBubble(
        data.body, 
        data.cosmetics, 
        { 
          radius: STANDALONE_BUBBLE_RADIUS, 
          x: STANDALONE_FRAME_W/2, 
          y: STANDALONE_FRAME_W/2 
        }
      );

      if (this.settings.sendMove) {
        this.myAvatar.bubble.interactive = true;
        this.myAvatar.bubble.addListener('pointerdown', e => {
          let loc = e.data.getLocalPosition(this.myAvatar.bubble);
          this.bubbleRender.makePing(this.myAvatar.bubble, loc);
          this.settings.sendMove({ x: -loc.x, y: -loc.y });
        });
      }
    }

    if (this.settings.defaultAnimation) {
      this.performAnimation(this.settings.defaultAnimation);
    }
  }

  private takeScreenshot(callback: (data: {blob: Blob, type: 'screenshot' | 'avatar'}) => void) {
    if (this.myAvatar && this.myAvatar.bubble) {
      let bounds = this.myAvatar.bubble.getLocalBounds();
      bounds.height = bounds.width;
      this.bubbleRender.pixi.takeScreenshot(this.myAvatar.bubble, bounds).then(blob => {
        callback({blob, type: 'avatar'});
      });
    }
  }

  private onResize = (e) => {
    if (this.bubbleRender) {
      this.bubbleRender.pixi.resize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
    }
    if (this.sceneRender) {
      this.sceneRender.pixi.resize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
    }
  }
}
