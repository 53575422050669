import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
const headers = new HttpHeaders({ 
  'Content-Type': 'application/json' , 
  'Access-Control-Allow-Origin': '*'
});
    
@Injectable({
  providedIn: 'root'
})
export class FunctionsService {

  private localFunctionsPath = 'http://localhost:5000/calculating-cats/us-central1/';
  private liveFunctionsPath = 'https://us-central1-calculating-cats.cloudfunctions.net/';
  private functionsPath:string;

  constructor(
    private http: HttpClient,
  ) {
    let host:string = window.location.host;
    if (host && host.split(':')[0] === 'localhost'){
      this.functionsPath = this.localFunctionsPath;
    }
    else{
      this.functionsPath = this.liveFunctionsPath;
    }
    // this.functionsPath = this.liveFunctionsPath;
  }

  public post(functionName:string, data:any){
    return this.http.post(this.functionsPath+functionName, data, {headers})
  }
}
