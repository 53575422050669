import { Component, Input, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';

// expected date format: YYYY-M-D H:M

@Component({
  selector: 'countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})

export class CountdownComponent implements OnInit, OnDestroy {
  @Input() units: any;
  @Input() end: any;
  @Input() displayString: String = '';
  @Input() text: any;
  @Input() captionScale: string = '100%';
  @Input() divider: any;
  @Input() hideUnits: boolean;
  @Input() showZero: Boolean = false;
  @Output() reached: EventEmitter<Date> = new EventEmitter();
  display: any = [];
  displayNumbers: any = [];
  wasReached: Boolean = false;
  intervalTracker;
  firstSignificantIndex = 0;

  constructor() {
    
  }

  ngOnInit() {
    this.intervalTracker = setInterval(() => this._displayString(), 300);
    
    let givenDate: any = new Date(this.end);
    if (isNaN(givenDate.valueOf())){
      console.warn('non-standard date: '+ this.end +' use format: YYYY-M-D H:M')
    }
  }
  
  ngOnDestroy(){
    clearInterval(this.intervalTracker);
  }

  _displayString() {

    if (this.wasReached){
      return;
    }

    if (typeof this.units === 'string') {
      this.units = this.units.split('|');
    }

    let givenDate: any = new Date(this.end);
    if (isNaN(givenDate.valueOf())){
      let dateAndTime = this.end.split(' ');
      let date = dateAndTime[0].split('-')
      let time = dateAndTime[1].split(':')
      let computedGivenDate:Date = new Date(
        date[0],
        date[1]-1,
        date[2],
        time[0],
        time[1],
        0,
        0
      )
      givenDate = computedGivenDate;
    }
    let now: any = new Date();
    let dateDifference: any = givenDate - now;

    if ((dateDifference < 100 && dateDifference > 0) || dateDifference < 0 && !this.wasReached) {
      this.wasReached = true;
      this.reached.next(now);
    }

    // console.log('this.wasReached', isNaN(givenDate.valueOf()), givenDate)

    let lastUnit = this.units[this.units.length - 1],
      unitConstantForMillisecs = {
        year: (((1000 * 60 * 60 * 24 * 7) * 4) * 12),
        month: ((1000 * 60 * 60 * 24 * 7) * 4),
        weeks: (1000 * 60 * 60 * 24 * 7),
        days: (1000 * 60 * 60 * 24),
        hours: (1000 * 60 * 60),
        minutes: (1000 * 60),
        seconds: 1000
      },
      unitsLeft = {},
      returnText = '',
      returnNumbers = '',
      totalMillisecsLeft = dateDifference,
      i,
      unit: any;

    for (i in this.units) {
      if (this.units.hasOwnProperty(i)) {

        unit = this.units[i].trim();
        if (unitConstantForMillisecs[unit.toLowerCase()] === false) {
          //$interval.cancel(countDownInterval);
          throw new Error('Cannot repeat unit: ' + unit);

        }
        if (unitConstantForMillisecs.hasOwnProperty(unit.toLowerCase()) === false) {
          throw new Error('Unit: ' + unit + ' is not supported. Please use following units: year, month, weeks, days, hours, minutes, seconds, milliseconds');
        }

        // If it was reached, everything is zero
        unitsLeft[unit] = (this.wasReached) ? 0 : totalMillisecsLeft / unitConstantForMillisecs[unit.toLowerCase()];

        if (lastUnit === unit) {
          unitsLeft[unit] = Math.ceil(unitsLeft[unit]);
        } else {
          unitsLeft[unit] = Math.floor(unitsLeft[unit]);
        }

        totalMillisecsLeft -= unitsLeft[unit] * unitConstantForMillisecs[unit.toLowerCase()];
        unitConstantForMillisecs[unit.toLowerCase()] = false;

        // If it's less than 0, round to 0
        unitsLeft[unit] = (unitsLeft[unit] > 0) ? unitsLeft[unit] : 0;

        returnNumbers += ' ' + unitsLeft[unit] + ' | ';
        returnText += ' ' + unit;
      }
    }

    if (this.text === null || !this.text) {
      this.text = {
        Year: 'Year',
        Month: 'Month',
        Weeks: 'Weeks',
        Days: 'Days',
        Hours: 'Hours',
        Minutes: 'Minutes',
        Seconds: 'Seconds',
        MilliSeconds: 'Milliseconds'
      };
    }

    this.displayString = returnText
      .replace('Year', this.text.Year + ' | ')
      .replace('Month', this.text.Month + ' | ')
      .replace('Weeks', this.text.Weeks + ' | ')
      .replace('Days', this.text.Days + ' | ')
      .replace('Hours', this.text.Hours + ' | ')
      .replace('Minutes', this.text.Minutes + ' | ')
      .replace('Seconds', this.text.Seconds);

    this.displayNumbers = returnNumbers.split('|');
    this.display = this.displayString.split('|');

    let isFirstSignificantFound = false;
    this.displayNumbers.forEach((num,i ) => {
      if (!isFirstSignificantFound && (num*1) > 0){
        this.firstSignificantIndex = i;
        isFirstSignificantFound = true;
      }
    })
      
  }
}
