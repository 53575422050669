import * as _ from 'lodash';
import {firestore} from 'firebase/app';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { IClassroom } from '../../data/collections/classrooms.types';
import { IUser } from '../../data/collections/users.types';
import { SORT_NAMES_ASC } from '../../data/sort/names';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { renderUsernameFromEmail } from '../../data/accounts/pseudo-email-usernames';
import { ClassroomService } from '../classroom.service';
import { UsersService } from '../../core/users.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ensureFirstLastName } from '../../data/name-split';
import { Subscription } from 'rxjs';
import { WhitelabelService } from '../../domain/whitelabel.service';

export interface IClassroomStudent {
  uid: string;
  displayName: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  isSelected?: boolean;
}


@Component({
  selector: 'teacher-classroom-students',
  templateUrl: './teacher-classroom-students.component.html',
  styleUrls: ['./teacher-classroom-students.component.scss']
})
export class TeacherClassroomStudentsComponent implements OnInit {

  public currentModal = {
    id: '',
    homePage: 'HOME',
    currentPage: 'HOME',
    isOnHomePage: true,
    isLastPage: false,
    isProceedDisabled: false,
    pageHistory: [],
    selections: {},
  };
  // public currentModal = {
  //   id: 'ADD_STUDENTS',
  //   homePage: 'ENTER_ACCOUNTS',
  //   currentPage: 'ENTER_ACCOUNTS',
  //   isOnHomePage: true,
  //   isLastPage: false,
  //   isProceedDisabled: false,
  //   pageHistory: [],
  //   selections: {},
  // };

  public activeStudents: IClassroomStudent[] = [];
  public classConfig: IClassroom = { name: '' };
  public classroomId: string;
  public isClassroomInfoLoaded:boolean = false;
  public isAssigningNewClassCode:boolean;
  public enableClassListingThroughClassCode:FormControl = new FormControl(true);
  public isUpdatingDb:boolean;
  public newStudentsForm = new FormGroup({

  });
  public newStudentsName = new FormControl('');
  public newStudentList:{name: string, username?:string, password?:string}[] = [];
  public currentEditingStudent: IClassroomStudent;
  public isStudentEditFormValid:boolean = true;
  public editStudentForm = new FormGroup({
    __editName: new FormControl(false),
    __editPassword: new FormControl(false),
    firstName: new FormControl(''),
    lastNameInitial: new FormControl(''),
    password: new FormControl(''),
  });
  public editStudentNameHelp:string;
  public editStudentPasswordHelp:string;
  public isMoreClassCodeRetInstr:boolean;
  
  private editStudentFormSubscr:Subscription;
  private numSelectedStudents:number = 0;
  private studentPasswords:Map<string, string> = new Map();

  constructor(
    public whitelabelService: WhitelabelService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId);
    });
    // this.usersService.test()

  }

  loadClassroom(classroomId: string) {
    // console.log('classroomId', classroomId)
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        this.isClassroomInfoLoaded = true;
        // this.initClassroomSettings();
        this.loadStudents(this.classConfig.currentStudents); // to do: could make this more efficient by not clearing out the list everytime.
      });
    this.classroomService
      .getClassroomPasswords(classroomId)
      .then(passwordEntries=>{
        passwordEntries.forEach(entry => {
          this.studentPasswords.set(entry.uid, entry.password)
        })
      })
  }

  toggleClassListingByCC(){
    this.classroomService.toggleClassListingByCC(this.classroomId, !this.classConfig.enableClassListingByCC);
  }
  
  // isClassroomSettingsInited:boolean;
  // initClassroomSettings(){
  //   if (this.isClassroomSettingsInited){
  //     return;
  //   }
  //   this.isClassroomSettingsInited = true;
  //   this.enableClassListingThroughClassCode.setValue(this.classConfig.enableClassListingByCC);
  //   this.enableClassListingThroughClassCode.valueChanges.subscribe
    
  // }

  insertNewStudentName(){
    let name:string = this.newStudentsName.value;
    if (name){
      name = name.trim();
    }
    if (name){
      this.newStudentList.push({name});
      this.newStudentsName.reset();
    }
  }

  removeStudentFromNewList(i){
    this.newStudentList.splice(i, 1);
  }

  assignNewClassCode(){
    this.isAssigningNewClassCode = true;
    this.classroomService
      .assignClassCode(this.classroomId)
      .then( () => {
        // this.isAssigningNewClassCode = false; // leaving this out because there might still be a lag before the code actually comes into the application which woul allow the user to click o nthe button again
      })
  }

  removeStudent(student:IClassroomStudent){
    if (confirm(`Are you sure you want to remove ${student.displayName} from the class?`)){
      this.classroomService
      .removeStudentFromClassroom(this.classroomId, student.uid);
      if (this.currentEditingStudent === student){
        this.currentEditingStudent = null;
        this.closeActiveModal();
      }
    }
  }

  removeSelectedStudents(){
    if (confirm(`Are you sure you want to remove these ${this.numSelectedStudents} student(s) from the class?`)){
      this.activeStudents.forEach(student => {
        if (student.isSelected){
          this.classroomService
            .removeStudentFromClassroom(this.classroomId, student.uid);
        }
      })
    }
  }

  toggleSelectStudent(student:IClassroomStudent){
    if (student.isSelected){
      this.deselectStudent(student);
    }
    else{
      this.selectStudent(student);
    }
  }
  selectStudent(student:IClassroomStudent){
    if (!student.isSelected){
      this.numSelectedStudents ++;
      student.isSelected = true;
    }
  }
  deselectStudent(student:IClassroomStudent){
    if (student.isSelected){
      this.numSelectedStudents --;
      student.isSelected = false;
    }
  }
  isAnyStudentsSelected(){
    return this.numSelectedStudents > 0;
  }
  isAllStudentsSelected(){
    return this.numSelectedStudents === this.activeStudents.length;
  }
  selectAllStudents(){
    if (this.isAllStudentsSelected()){
      this.activeStudents.forEach(student => {
        this.deselectStudent(student);
      });
    }
    else{
      this.activeStudents.forEach(student => {
        this.selectStudent(student);
      });
    }
  }

  isShowStudentUid:boolean;
  toggleShowStudentUid(){
    this.isShowStudentUid = !this.isShowStudentUid;
  }

  loadStudents(studentIds: string[]) {
    if (this.activeStudents && this.activeStudents.length > 0) {
      console.warn('Reloading student accounts...');
    }
    this.activeStudents = [];
    if (studentIds) {
      studentIds.forEach(uid => {
        let studentPacket: IClassroomStudent = {
          uid,
          displayName: '',
          firstName: '',
          lastName: '',
          username: '',
        }
        this.activeStudents.push(studentPacket);
        this.usersService
          .getUserInfo(uid)
          .subscribe((userInfo:IUser)=>{
            ensureFirstLastName(userInfo);
            studentPacket.displayName = userInfo.displayName;
            studentPacket.firstName = userInfo.firstName;
            studentPacket.lastName = userInfo.lastName;
            studentPacket.username = renderUsernameFromEmail(userInfo.email);
            this.applyStudentSorting(); 
          })
      });
    }
  }

  applyStudentSorting = _.throttle(() => {
    this.activeStudents = this.activeStudents.sort(SORT_NAMES_ASC);
  }, 500)

  isTooltipDismissed(){
    if (this.isAnyStudentsEnrolled()){
      return true;
    }
    return false;
  }

  isAnyStudentsEnrolled(){
    return this.activeStudents && this.activeStudents.length > 0;
  }



  openModal(id: string) {
    this.currentModal = {
      id,
      homePage: 'HOME',
      currentPage: 'HOME',
      isOnHomePage: true,
      isLastPage: false,
      isProceedDisabled: false,
      pageHistory: [],
      selections: {},
    }
  }

  alert(str: string) {
    alert(str);
  }

  gotoPrevModalPage() {
    if (this.currentModal.pageHistory.length > 0) {
      let pageId = this.currentModal.pageHistory.pop();
      this.currentModal.currentPage = pageId;
      if (this.currentModal.pageHistory.length === 0) {
        this.currentModal.isOnHomePage = true;
      }
      this.currentModal.isLastPage = false;
    }
  }

  loadNextModalPage(pageId: string) {
    this.currentModal.pageHistory.push(this.currentModal.currentPage);
    this.currentModal.currentPage = pageId;
    this.currentModal.isOnHomePage = false;
  }

  gotoToNextModalPage() {

    let id = this.currentModal.id;
    let currentPage = this.currentModal.currentPage;

    if (id === 'ADD_STUDENTS') {
      if (currentPage === 'HOME') {
        let haveAccounts = this.currentModal.selections['haveAccounts'];
        if (haveAccounts === 'YES') {
          this.loadNextModalPage('CLASS_CODE_RETURNING');
          this.currentModal.isLastPage = true;
        }
        else if (haveAccounts === 'NO') {
          this.loadNextModalPage('NEW_ACCOUNTS');
        }
        else {
          this.loadNextModalPage('ENTER_ACCOUNTS');
        }
      }
      else if (currentPage === 'NEW_ACCOUNTS') {
        let accountCreator = this.currentModal.selections['accountCreator'];
        if (accountCreator === 'STUDENT') {
          this.loadNextModalPage('CLASS_CODE');
          this.currentModal.isLastPage = true;
        }
        else if (accountCreator === 'TEACHER') {
          this.resetStudentList();
          this.loadNextModalPage('ENTER_ACCOUNTS');
        }
        else {
          this.resetStudentList();
          this.loadNextModalPage('ENTER_ACCOUNTS');
        }
      }
      else if (currentPage === 'ENTER_ACCOUNTS') {
        // need to actually create the accounts here
        this.createNewAccounts()
          .then(()=>{
            this.loadNextModalPage('PRINT_CLASS_INSTR');
            this.currentModal.isLastPage = true;
          })
      }
      else if (currentPage === 'CLASS_CODE') {
        this.loadNextModalPage('PRINT_INDIV_INSTR');
        this.currentModal.isLastPage = true;
      }
      else if (currentPage === 'PRINT_INDIV_INSTR') {
        this.closeActiveModal();
      }
    }

  }

  resetStudentList(){
    this.newStudentList = [];
    this.newStudentsForm.reset();
  }

  isUpdatingDbError:boolean;
  createNewAccounts(){
    this.isUpdatingDb = true;
    this.isUpdatingDbError = false;
    return Promise.all(
      this.newStudentList.map(student => {
        return this.usersService
          .createTeacherStudent(this.classroomId, student.name)
          .then(studentInfo => {
            student.username = studentInfo.username;
            student.password = studentInfo.password;
          })
      })
    )
    .then( () => {
      this.isUpdatingDb = false;
    })
    .catch(err => {
      console.error(err);
      this.isUpdatingDbError = true;
    })
  }

  updateStudentEditFormValidation(){
    let isValid = true;
    this.editStudentNameHelp = '';
    this.editStudentPasswordHelp = '';
    const formControls = this.editStudentForm.controls
    try {
      if (formControls.__editName.value){
        if ( !formControls.firstName.value || (formControls.firstName.value.trim().length < 1)){
          isValid = false;
          this.editStudentNameHelp = 'Please enter the student\'s first name and last initial';
        }
      }
      if (formControls.__editPassword.value){
        // console.log('formControls.password.value', formControls.password.value)
        if(!formControls.password.value || formControls.password.value.trim().length < 6){
          isValid = false;
          this.editStudentPasswordHelp = 'Password must contain at least 6 characters.'
        }
      }
    }
    catch(e){
      isValid = false;
    }
    this.isStudentEditFormValid = isValid;
    // console.log('this.isStudentEditFormValid', this.isStudentEditFormValid)
  }

  openStudentEdit(student: IClassroomStudent) {
    this.currentEditingStudent = student;
    this.openModal('EDIT_STUDENT');
    this.editStudentForm.reset();
    if (this.editStudentFormSubscr){
      this.editStudentFormSubscr.unsubscribe();
    }
    this.updateStudentEditFormValidation()
    this.editStudentFormSubscr = this.editStudentForm.valueChanges.subscribe(() => this.updateStudentEditFormValidation())
  }

  getStudentPassword(student:IClassroomStudent){
    let password;
    if(this.studentPasswords.has(student.uid)){
      password = this.studentPasswords.get(student.uid);
    }
    return this.usersService.parseStudentPassword(password);
  }

  saveStudentEditing(){
    const formControls = this.editStudentForm.controls
    this.isUpdatingDb = true;
    const promises = [];
    const studentUid = this.currentEditingStudent.uid;
    const hasAllFormValues = (props:string[]) => {
      let isAnyFalse:boolean;
      props.forEach(prop => {
        if (!formControls[prop].value){
          isAnyFalse = true;
        }
      })
      return !isAnyFalse;
    }
    const isUpdatingDisplayName = hasAllFormValues(['__editName', 'firstName', 'lastNameInitial']);
    const isUpdatingPassword = hasAllFormValues(['__editPassword', 'password']);
    let newPassword; 
    if(isUpdatingDisplayName){
      promises.push(
        this.usersService.updateUserDisplayName(this.classroomId, studentUid, formControls.firstName.value, formControls.lastNameInitial.value)
      )
    }
    if(isUpdatingPassword){
      newPassword = formControls.password.value.trim();
      const studentUsername = this.currentEditingStudent.username;
      promises.push(
        this.usersService.updateUserPassword(this.classroomId, studentUid, studentUsername, newPassword)
      )
    }
    Promise.all(promises)
      .then(() => {
        this.isUpdatingDb = false;
        if (isUpdatingPassword){
          this.studentPasswords.set(studentUid, newPassword); // to do: would be better if the sub just updated this automatically? maybe more overhead than it is worth
        }
        this.closeActiveModal();
      })
  }

  removeCurrentStudent(student: { uid: string }, name: string) {
    if (confirm('Remove ' + name + ' from class?')) {
      this.afs.doc('classrooms/' + this.classroomId).update({
        currentStudents: firestore.FieldValue.arrayRemove(student.uid),
        archivedStudents: firestore.FieldValue.arrayUnion(student.uid)
      })
    }
  }

  closeActiveModal() {
    this.currentModal.id = null;
  }

}
