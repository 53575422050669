import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'student-calculator',
  templateUrl: './student-calculator.component.html',
  styleUrls: ['./student-calculator.component.scss']
})
export class StudentCalculatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
