import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarBubblesComponent } from './avatar-bubbles/avatar-bubbles.component';
import { AvatarPodiumComponent } from './avatar-podium/avatar-podium.component';
import { AvatarExploreComponent } from './avatar-explore/avatar-explore.component';
import { AvatarCosmeticPurchaseComponent } from './avatar-cosmetic-purchase/avatar-cosmetic-purchase.component';
import { MoneyDisplayComponent } from './money-display/money-display.component';
import { SingleAvatarComponent } from './single-avatar/single-avatar.component';
import { AvatarCosmeticMenuComponent } from './avatar-cosmetic-menu/avatar-cosmetic-menu.component';
import { AvatarPickerComponent } from './avatar-picker/avatar-picker.component';

@NgModule({
  declarations: [
    AvatarBubblesComponent,
    AvatarPodiumComponent,
    AvatarExploreComponent,
    AvatarCosmeticPurchaseComponent,
    AvatarCosmeticMenuComponent,
    AvatarPickerComponent,
    SingleAvatarComponent,
    MoneyDisplayComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AvatarBubblesComponent,
    AvatarPodiumComponent,
    AvatarExploreComponent,
    AvatarCosmeticPurchaseComponent,
    AvatarPickerComponent,
    SingleAvatarComponent,
    MoneyDisplayComponent
  ]
})
export class UiAvatarDisplaysModule {}
