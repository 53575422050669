import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { IPanelLink } from './panels/type';
import { teacherPanels } from './panels/teacher';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { adminPanels } from './panels/admin';
import { districtCoordPanels } from './panels/district-coord';
import { ChatpanelService } from '../../core/chatpanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';
import { trialCoordPanels } from './panels/trial-coord';
import { UserUi } from '../../data/collections/users.types';

export interface Item { name: string; }

@Component({
  selector: 'main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit, OnDestroy {

  isAccountDropupActive = false;
  sidePanelOptions:IPanelLink[] = [];
  activePanelLink:IPanelLink;

  private userProfile: AngularFirestoreDocument<Item>;
  private signinSub:Subscription;
  private routerSub:Subscription;
  
  constructor(
    public whiteLabel: WhitelabelService,
    private afs: AngularFirestore,
    private auth: AuthService,
    private router: Router,
    // private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) {
    
  }
  
  ngOnInit(){
    this.signinSub = this.auth.user.subscribe(userInfo => {
      if (userInfo){
        this.assignPanelLinks();
        this.checkForActivePanel(this.router.url);
      }
    });
    this.routerSub =  this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd){
        this.checkForActivePanel(val.url);
        setTimeout(()=> { // because the router processing takes a moment to update the active classroom id from the route itself... TO DO: have a mechanism that can extract (and update) the classroom id from here
          this.checkForActivePanel(val.url);
        }, 100);
      }
    });
  }

  ngOnDestroy() {
    if (this.signinSub) {this.signinSub.unsubscribe();}
    if (this.routerSub) {this.routerSub.unsubscribe();}
  }

  assignPanelLinks(){
    this.sidePanelOptions = [];
    this.getUiPanels().forEach(panel => {
      this.sidePanelOptions = this.sidePanelOptions.concat(panel);
    })
  }

  getUiPanels(){
    const ui = this.auth.getUi();
    switch(ui){
      case UserUi.TEACHER:           return [teacherPanels]
      case UserUi.DISTRICT_COORD:    return [districtCoordPanels];
      case UserUi.TRIAL_COORD:       return [teacherPanels, trialCoordPanels]
      case UserUi.ADMIN:             return [teacherPanels, trialCoordPanels, adminPanels];
      default: return [];
    }
  }

  isOptionHidden(option:IPanelLink){
    if (option.requiresClassroom){
      if (!this.userSiteContext.hasActiveClassroom()){
        return true;
      }
    }
    return false;
  }

  openSupportChat(){
    this.chatPanel.expand();
  }

  checkForActivePanel(currentUrl:string){
    this.activePanelLink = null;
    this.sidePanelOptions.forEach( panelLink =>{
      const routeParts:string[] = this.processRouteParts(panelLink.routerLink);
      const url = routeParts.join('/');
      if (url === currentUrl){
        this.activePanelLink = panelLink;
      }
    })
  }

  getUsername(){
    return this.auth.getUsername();
  }

  getAccountTypeDisplayName(){
    switch(this.auth.getUi()){
      case UserUi.TEACHER: return 'Teacher';
      case UserUi.STUDENT: return 'Student';
      case UserUi.PARENT: return 'Parent';
      case UserUi.ADMIN: return 'Administrator';
      case UserUi.TRIAL_COORD: return 'Trial Coordinator';
      case UserUi.DISTRICT_COORD: return 'District Coordinator';
    }
  }

  isUserCoordinator(){

  }
  isUserTeacher(){
    switch(this.auth.getUi()){
      case UserUi.TEACHER:
      case UserUi.TRIAL_COORD:
      case UserUi.ADMIN:
        return true;
    }
    return false;
  }
  isUserParent(){
    return this.auth.getUi() === UserUi.PARENT;
  }
  isUserAdministrator(){
    return this.auth.getUi() === UserUi.ADMIN;
  }

  logout(){
    this.auth.signOut();
    this.router.navigate(['/']);
  }

  getUserPhotoUrl(){
    return this.auth.getPhotoUrl();
  }

  toggleAccountDropdown(){
    if (this.isAccountDropupActive){
      this.isAccountDropupActive = false;
    }
    else{
      this.isAccountDropupActive = true;
      this.sidePanel.expand();
    }
  }

  getUserDisplayname(){
    return this.auth.getDisplayName()
  }

  isSidePanelExpanded():boolean{
    return this.sidePanel.getExpandedStatus();
  }

  expandSidePanel(){
    this.sidePanel.expand();
  }
  collapseSidePanel(){
    this.sidePanel.unexpand();
  }

  isRouteActive(panelLink:IPanelLink) {
    if(panelLink === this.activePanelLink){
      return true;
    }
    return false;
  }

  processRouteParts(routeTemplate:string):string[]{
    let routeParts:string[] = routeTemplate.split('/');
    routeParts.forEach( (routePart,i) => {
      if (i > 0){
        routeParts[i] = this.userSiteContext.processRoutePart(routePart);
      }
    })
    return routeParts;
  }

  navToOptionRoute(option:IPanelLink){
    // console.log('navToOptionRoute', option.requiresClassroom, this.userSiteContext.hasActiveClassroom()) 
    // return;
    this.navToRoute(option.routerLink);
  }

  navToRoute(routeTemplate:string){
    let routeParts:string[] = this.processRouteParts(routeTemplate);
    this.router.navigate(routeParts);
  }

}
