import * as _ from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { serverTimestamp } from '../../data/timestamp';
import { FormControl, FormGroup } from '@angular/forms';
import { IClassroom } from '../../data/collections/classrooms.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';
import { ClassroomService } from '../classroom.service';
import { UsersService } from '../../core/users.service';
import { ensureFirstLastName } from '../../data/name-split';
import { IUser } from '../../data/collections/users.types';
import { SORT_NAMES_ASC } from '../../data/sort/names';
import { AssignmentsService } from '../../core/assignments.service';
import { WhitelabelService } from '../../domain/whitelabel.service';

enum Overlays {
  NEW_CLASSROOM = 'NEW_CLASSROOM',
}
interface IClassroomArchivedTeachers {
  __id?: string,
  classroomId: string,
  timestamp: any,
  uid:string
}
interface IUserInfo {
  uid: string,
  displayName: string,
}
interface IClassroomSlot {
  id: string, 
  archiveId?: string, 
  name: string,
  curricShort?: string,
  classCode?: string,
  numStudents?: number,
  numTeachers?: number,
}
@Component({
  selector: 'teacher-classrooms',
  templateUrl: './teacher-classrooms.component.html',
  styleUrls: ['./teacher-classrooms.component.scss']
})
export class TeacherClassroomsComponent implements OnInit, OnDestroy {

  @ViewChild('selectedClassroomPanel') selectedClassroomPanel:ElementRef;
  

  activeClassroom:IClassroom;
  classroomId:string;
  classroomSlots:IClassroomSlot[];
  archivedClassroomSlots:IClassroomSlot[];
  classroomNameLoaders:Map<string, any> = new Map();
  newClassName = new FormControl(null);
  classroomNameForm = new FormGroup({name: new FormControl('')});
  isEditingClassroomName: boolean;
  isSavingClassroomName: boolean;
  isAddingNewClassroom;
  isManagingClassrooms:boolean;
  isClickBufferActive:boolean;
  activeStudents:IUserInfo[] = []
  activeTeachers:IUserInfo[] = [];
  OVERLAYS = Overlays;
  MAX_STUDENT_DISPLAY = 7;
  MAX_TEACHER_DISPLAY = 5;

  private classroomClassCodeCache:Map<string, string> = new Map();
  private signinSub:Subscription;

  dummylist = [
    // 'Askew Isfar ',
    // 'Oronly Domainer ',
    // 'Dot-coms Rather ',
    // 'Theman Underrated',
    // 'Hesays Intheart',
    // 'Investment Which',
    // 'Oldas Thecommercial ',
    // 'The strategy is founded on ',
    // 'the belief that ',
    // 'building property ',
    // 'on a site — even the',
    // 'semblance of a functional ',
    // 'enterprise — can make ',
    // 'the domain that',
  ]

  dummylistShort = [
    // 'The strategy is founded on ',
    // 'the belief that ',
    // 'building property ',
  ]
  

  constructor(
    public whitelabelService: WhitelabelService,
    private auth: AuthService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private asmtService: AssignmentsService,
    private usersService: UsersService,
    private userSiteContext: UserSiteContextService,
    private classroomService: ClassroomService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();

    this.signinSub = this.auth.user.subscribe(user => {
      this.activeClassroom = null;
      this.loadClassrooms(user.classroomsAsTeacher);
      this.loadRoute();
    });
  }

  ngOnDestroy() {
    this.signinSub.unsubscribe();
  }

  getActiveClassroomDepth(){
    if (this.classroomId){
      return 'SETTINGS';
    }
    else{
      return 'ADD_REMOVE';
    }
  }

  selectClassroom(classroom:IClassroomSlot){
    this.selectClassroomById(classroom.id);
  }

  selectClassroomById(classroomId:string){
    this.router.navigate([
      'teacher',
      'classrooms',
      classroomId
    ]);
    setTimeout(()=>{
      // time delay is for aesthetic reasons, but if we decide to remove it, we should take it out
      const el = this.selectedClassroomPanel.nativeElement;
      if (el){
        el.scrollIntoView({behavior: 'smooth'});
      }
    }, 700);
  }

  routeSub:Subscription;
  loadRoute(){
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId);
    });
  }


  loadClassrooms(classroomList:string[]){
    this.classroomSlots = [];
    if (classroomList){
      classroomList.forEach(classroomId => {
        let classroomSlot:IClassroomSlot = {
          id: classroomId,
          numStudents: 0,
          classCode: '',
          curricShort: '',
          name: '',
          numTeachers: 0,
        };
        this.classroomSlots.push(classroomSlot);
        this.classroomService
          .getClassroomInfo(classroomId)
          .subscribe( classroom => {
            if (classroom.currentTeachers){
              classroomSlot.numTeachers = classroom.currentTeachers.length;
            }
            if (classroom.currentStudents){
              classroomSlot.numStudents = classroom.currentStudents.length
            }
            classroomSlot.name = classroom.name;
            classroomSlot.classCode = classroom.classCode;
            classroomSlot.curricShort = 'Stage 6';
          })
      })
    }
    else{
      console.warn('You are not a teacher in any classes!')
    }
  }


  loadClassroom(classroomId: string) {
    this.afs
      .doc<IClassroom>('classrooms/' + classroomId)
      .get()
      .subscribe(res => {
        this.activeClassroom = <IClassroom>res.data();
        if (this.activeClassroom){
          let students:string[] = this.activeClassroom.currentStudents;
          let teachers:string[] = this.activeClassroom.currentTeachers;
          if (students && students.length > 0){
            students = _.shuffle(students).slice(0, this.MAX_STUDENT_DISPLAY);
          }
          this.loadStudents(students);
          if (teachers){
            this.loadTeachers( teachers.slice(0, this.MAX_TEACHER_DISPLAY) );
          }
          this.activeClassroomOpenAssessments = this.asmtService.getOpenAssessments(classroomId);
          this.activeClassroomRecentAssessments = this.asmtService.getRecentlyCompletedAssessments(classroomId);
          if (!this.activeClassroom.classCode && this.classroomClassCodeCache.has(classroomId)){
            this.activeClassroom.classCode = this.classroomClassCodeCache.get(classroomId);
          }
        }
      });
  }

  activeClassroomOpenAssessments;
  activeClassroomRecentAssessments;

  openAssessmentReport(assignmentId:string){
    this.router.navigate(['teacher','classroom',this.classroomId,'assignment', assignmentId, 'report']);
  }

  openClassroomNameEditor(){
    if (!this.isEditingClassroomName){
      this.isEditingClassroomName = true;
      this.classroomNameForm.setValue({name: this.activeClassroom.name});
    }
  }
  closeClassroomNameEditor(){
    this.isEditingClassroomName = false;
  }

  changeCurrentClassroomName(){
    let name = this.classroomNameForm.value['name'];
    if (name){
      this.isSavingClassroomName = true;
      this.afs
        .doc('classrooms/'+this.classroomId)
        .update({ name, })
        .then( () => {
          this.activeClassroom.name = name;
          this.isEditingClassroomName = false;
          this.isSavingClassroomName = false;
        })
    }
  }

  activeOverlay:string;
  openNewClassroomModal(){
    this.activeOverlay = Overlays.NEW_CLASSROOM;
  }
  closeActiveOverlay(){
    this.activeOverlay = null;
  }

  renderOverlayName(overlayId:Overlays){
    switch(overlayId){
      case Overlays.NEW_CLASSROOM: return 'Create a New Classroom'
      default: return overlayId;
    }
  }

  // loadClassroomName(classroomSlot:IClassroomSlot){
  //   let sub = this.afs.doc<IClassroom>('classrooms/'+classroomSlot.id).valueChanges().subscribe(val => {
  //     classroomSlot.name = val.name;
  //   })
  //   this.classroomNameLoaders.set(classroomSlot.id, sub);
  // }

  

  loadUsers(userList:IUserInfo[], UIDs:string[]){
    if (UIDs) {
      UIDs.forEach(uid => {
        let userPacket: IUserInfo = {
          uid,
          displayName: '',
        }
        userList.push(userPacket);
        this.usersService
          .getUserInfo(uid)
          .subscribe((userInfo:IUser)=>{
            ensureFirstLastName(userInfo);
            userPacket.displayName = userInfo.displayName;
          })
      });
    }
  }

  loadTeachers(teacherIds: string[]) {
    this.activeTeachers = [];
    this.loadUsers(this.activeTeachers, teacherIds);
  }

  loadStudents(studentIds: string[]) {
    this.activeStudents = [];
    if (studentIds){
      this.loadUsers(this.activeStudents, studentIds);
    }
  }

  getNumStudents(){
    if(this.activeClassroom && this.activeClassroom.currentStudents){
      return this.activeClassroom.currentStudents.length;
    }
    return 0;
  }

  initArchivedClassroomStream(){
    this.archivedClassroomSlots = [];
    this.classroomService
      .loadArchivedTeacherClassrooms()
      .subscribe((archivedClassrooms:IClassroomArchivedTeachers[]) => {
        this.archivedClassroomSlots = [];
        archivedClassrooms.forEach(archivedClassroom => {
          let classroomSlot:IClassroomSlot = {
            id: archivedClassroom.classroomId,
            archiveId: archivedClassroom.__id,
            name: '',
          };
          this.archivedClassroomSlots.push(classroomSlot);
          this.classroomService
            .getClassroomInfo(archivedClassroom.classroomId)
            .subscribe( classroom => {
              classroomSlot.name = classroom.name;
              classroomSlot.classCode = classroom.classCode;
              classroomSlot.curricShort = 'Stage 6';
            })
        })

      })
  }
  activateClickBuffer(){
    this.isClickBufferActive = true;
    setTimeout(()=>{
      this.isClickBufferActive = false;
    }, 800)
  }
  archiveClassroom(classroomSlot:IClassroomSlot){
    if (this.isClickBufferActive){ return; }
    if (this.classroomId === this.classroomId){
      this.router.navigate([ 'teacher', 'classrooms' ])
    }
    this.activateClickBuffer();
    this.classroomService
      .archiveClassroom(classroomSlot.id, classroomSlot.numTeachers === 1)
      // the list gets adjusted when the list gets reloaded (triggered by the list in the user view)
  }
  restoreClassroom(classroomSlot:IClassroomSlot){
    if (this.isClickBufferActive){ return; }
    this.activateClickBuffer();
    this.classroomService
      .restoreClassroom(classroomSlot.id, classroomSlot.archiveId)
  }
  activateClassroomManagement(){
    this.isManagingClassrooms = true;
    this.initArchivedClassroomStream();
  }
  deactivateClassroomManagement(){
    this.isManagingClassrooms = false;
  }

  // pendingClassroomSelection

  
  createNewClassroom(){
    this.isAddingNewClassroom = true;
    this.classroomService
      .createNewClassroom('cambridge-stage6', this.newClassName.value)
      .then(res => {
        this.isAddingNewClassroom = false;
        this.newClassName.reset(); 
        this.closeActiveOverlay();
        this.classroomClassCodeCache.set(res.classroomId, res.classCode);
        this.selectClassroomById(res.classroomId);
      })
  }

}
