import { Component, OnInit, Input } from '@angular/core';
import { IContentElementText, TextParagraphStyle, QuestionState } from '../models';

@Component({
  selector: 'element-render-text',
  templateUrl: './element-render-text.component.html',
  styleUrls: ['./element-render-text.component.scss']
})
export class ElementRenderTextComponent implements OnInit {

  @Input() element:IContentElementText;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  constructor() { }

  ngOnInit() {
  }

  isHeading(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE;
  }
  isHeadingSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL;
  }
  isBody(){
    return this.element.paragraphStyle === TextParagraphStyle.REGULAR;
  }
  isSmall(){
    return this.element.paragraphStyle === TextParagraphStyle.SMALL;
  }
  isBulletList(){
    return this.element.paragraphStyle === TextParagraphStyle.BULLET;
  }
  isNumberedList(){
    return this.element.paragraphStyle === TextParagraphStyle.NUMBERED;
  }

  isSimpleText(){
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.REGULAR){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isListText(){
    if (this.element.paragraphStyle === TextParagraphStyle.BULLET){ return true }
    if (this.element.paragraphStyle === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    if (this.element.paragraphStyle === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

}
