
import { RainbowShift } from '../ColorUtilities';
import { PIXIFirework } from './Fireworks';

let clearFunctions: (() => void)[] = [];

export function rainbowTrail(container: PIXI.Container) {
  let rainbow = new RainbowShift;

  const onMove = (e: PIXI.interaction.InteractionEvent) => {
    let pos = e.data.getLocalPosition(container);
    let firework = new PIXIFirework({ x: pos.x, y: pos.y, numParts: 1, startVX: 0.5, startVY: 0.5, color: rainbow.getNextColor(), container, gravity: 0, fade: 0.05 });
  }

  const onDown = (e: PIXI.interaction.InteractionEvent) => {
    let pos = e.data.getLocalPosition(container);
    let firework = new PIXIFirework({ x: pos.x, y: pos.y, numParts: 20, fade: 0.02, startVX: 3, startVY: 3, size: 7, color: rainbow.getNextColor(), container });
  };

  container.addListener('pointermove', onMove);
  container.addListener('pointerdown', onDown);

  clearFunctions.push(() => {
    container.removeListener('pointermove', onMove);
    container.removeListener('pointerdown', onDown);
  });
}

export function whitePop(container: PIXI.Container) {
  const onMove = (e: PIXI.interaction.InteractionEvent) => {
    let pos = e.data.getLocalPosition(container);
    let firework = new PIXIFirework({ x: pos.x, y: pos.y, numParts: 1, startVX: 0.5, startVY: 0.5, color: 0xffffff, container, gravity: 0, fade: 0.05 });
  }

  const onDown = (e: PIXI.interaction.InteractionEvent) => {
    let pos = e.data.getLocalPosition(container);
    let firework = new PIXIFirework({ x: pos.x - 10, y: pos.y - 10, numParts: 1, fade: 0.02, startVX: 0, startVY: 0, size: 20, color: 0xffffff, container, gravity: 0 });
    firework = new PIXIFirework({ x: pos.x, y: pos.y, numParts: 8, fade: 0.01, startVX: 1, startVY: 1, size: 4, color: 0xffffff, container, gravity: 0 });
  };

  container.addListener('pointermove', onMove);
  container.addListener('pointerdown', onDown);

  clearFunctions.push(() => {
    container.removeListener('pointermove', onMove);
    container.removeListener('pointerdown', onDown);
  });
}

// export function gatheringOrbs(container: PIXI.Container) {
//   let rainbow = new RainbowShift;
//   let orbs: { display: PIXI.Graphics, vX: number, vY: number, acc: number, rad: number, fric: number }[] = [];
//   let curr = { x: 0, y: 0 };
//   let burst = 500;

//   const onMove = (e: PIXI.interaction.InteractionEvent) => {
//     curr = e.data.getLocalPosition(container)
//   }

//   const onDown = (e: PIXI.interaction.InteractionEvent) => {
//     orbs.forEach(orb => {
//       orb.vX += burst * (-0.5 + Math.random());
//       orb.vY += burst * (-0.5 + Math.random());
//     })
//   }

//   const onTick = () => {
//     orbs.forEach(orb => {
//       orb.display.tint = rainbow.getNextColor(0.1);
//       let dX = curr.x - orb.display.x;
//       let dY = curr.y - orb.display.y;
//       if (Math.abs(dX) > orb.rad) {
//         orb.vX += orb.acc * dX;
//         orb.vX *= orb.fric;
//       }
//       if (Math.abs(dY) > orb.rad) {
//         orb.vY += orb.acc * dY;
//         orb.vY *= orb.fric;
//       }

//       orb.display.x += orb.vX * 0.3;
//       orb.display.y += orb.vY * 0.3;
//     });
//   }

//   container.addListener('pointermove', onMove);
//   container.addListener('pointerdown', onDown);
//   JMBL.events.ticker.add(onTick);

//   clearFunctions.push(() => {
//     container.removeListener('pointermove', onMove);
//     container.removeListener('pointerdown', onDown);
//     JMBL.events.ticker.remove(onTick);
//     orbs.forEach(orb => orb.display.parent.removeChild(orb.display))
//   });

//   let makeOrb = (acc: number, rad: number) => {
//     let orb = new PIXI.Graphics;
//     orb.beginFill(0xffffff);
//     orb.drawCircle(0, 0, 2);
//     orbs.push({ display: orb, vX: 0, vY: 0, acc, fric: 0.9, rad });
//     container.addChild(orb);
//   }

//   makeOrb(0.1, 16);
//   makeOrb(0.12, 16);
//   makeOrb(0.14, 16);
//   makeOrb(0.16, 16);
//   makeOrb(0.1, 14);
//   makeOrb(0.12, 14);
//   makeOrb(0.14, 14);
//   makeOrb(0.16, 14);
//   makeOrb(0.1, 12);
//   makeOrb(0.12, 12);
//   makeOrb(0.14, 12);
//   makeOrb(0.16, 12);
// }

// export function rainbowPhysics(container: PIXI.Container) {
//   let rainbow = new RainbowShift;
//   let orb: { x: number, y: number };

//   let vX = 0;
//   let vY = 0;
//   let oE = { x: 0, y: 0 };

//   let vM = 0.06;
//   let fric = 0.7;
//   let thresh = 2;
//   let oM = 0.25;
//   let dens = 3;

//   const onMove = (e: PIXI.interaction.InteractionEvent) => {
//     oE = e.data.getLocalPosition(container);
//     if (!orb) {
//       orb = oE;
//     }
//   }
//   const onDown = (e: PIXI.interaction.InteractionEvent) => {
//     let pos = e.data.getLocalPosition(container);
//     new PIXIFirework(container, { x: pos.x, y: pos.y, numParts: 20, fade: 0.02, startVX: 3, startVY: 3, size: 7, color: rainbow.getNextColor() })
//   }

//   const onTick = () => {
//     if (!orb) return;
//     let dX = oE.x - orb.x;
//     let dY = oE.y - orb.y;
//     vX = (vX + dX * vM) * fric;
//     vY = (vY + dY * vM) * fric;
//     orb.x += vX;
//     orb.y += vY;
//     if (Math.abs(vX) > thresh || Math.abs(vY) > thresh) {
//       new PIXIFirework(container, { x: orb.x, y: orb.y, numParts: dens, startOX: vX * oM, startOY: vY * oM, fade: 0.01, startVX: 0.5, startVY: 0.5, gravity: 0.0001, color: rainbow.getNextColor() })
//     }
//   }

//   container.addListener('pointermove', onMove);
//   container.addListener('pointerdown', onDown);
//   JMBL.events.ticker.add(onTick);

//   clearFunctions.push(() => {
//     container.removeListener('pointermove', onMove);
//     container.removeListener('pointerdown', onDown);
//     JMBL.events.ticker.remove(onTick);
//   });
// }

export const clearMouseEffects = () => {
  while (clearFunctions.length > 0) {
    clearFunctions.shift()();
  }
}