import { ICurriculumModel } from "./types";

export const CURRICULUM_MODEL:ICurriculumModel = {
  id: 'cambridge-stage6',
  strands: [
    {
      code: 'Nn',
      caption: 'Numbers and the number system',
      boosterPackId: 'ySugyQzRV3662E7Q9yYo',
      isLocked: false,
      learningObjectives: [
        {
          code: '6Nn1',
          caption: 'Count on and back in fractions and decimals, e.g. 1/3s, 0.1s, and repeated steps of whole numbers (and through zero)',
          isLive: true,
          assessments: [
            {
              human_id: '3_2',
              caption: 'Counting By Fractions',
              numQuestions: 3,
              estimatedTime: '5 to 15 minutes',
              taskId: '2hTS58bBri3D7tFQgtnr',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-fractions/B'},
              ],
            },
          ],
        },
        {
          code: '6Nn2 ',
          caption: 'Know what each digit represents in whole numbers up to a million',
          assessments: [],
        },
        {
          code: '6Nn3 ',
          caption: 'Know what each digit represents in one- and two-place decimal numbers',
          assessments: [],
        },
        {
          code: '6Nn4 ',
          caption: 'Multiply and divide any whole number from 1 to 10000 by 10, 100 or 1000 and explain the effect',
          assessments: [],
        },
        {
          code: '6Nn5 ',
          caption: 'Multiply and divide decimals by 10 or 100 (answers up to two decimal places for division)',
          assessments: [],
        },
        {
          code: '6Nn6 ',
          caption: 'Find factors of two-digit numbers',
          assessments: [],
        },
        {
          code: '6Nn7 ',
          caption: 'Find some common multiples, e.g. for 4 and 5',
          assessments: [],
        },
        {
          code: '6Nn8 ',
          caption: 'Round whole numbers to the nearest 10, 100 or 1000',
          assessments: [],
        },
        {
          code: '6Nn9 ',
          caption: 'Round a number with two decimal places to the nearest tenth or to the nearest whole number',
          assessments: [],
        },
        {
          code: '6Nn10 ',
          caption: 'Make and justify estimates and approximations of large numbers',
          assessments: [],
        },
        {
          code: '6Nn11 ',
          caption: 'Order and compare positive numbers to one million, and negative integers to an appropriate level',
          assessments: [],
        },
        {
          code: '6Nn12 ',
          isLive: false,
          caption: 'Use the >, < and = signs correctly',
          assessments: [ ],
        },
        {
          code: '6Nn13 ',
          caption: 'Estimate where four-digit numbers lie on an empty 0–10000 line',
          assessments: [],
        },
        {
          code: '6Nn14 ',
          caption: 'Order numbers with up to two decimal places (including different numbers of places)',
          assessments: [],
        },
        {
          code: '6Nn15 ',
          caption: 'Recognise and extend number sequences',
          assessments: [],
        },
        {
          code: '6Nn16 ',
          caption: 'Recognise and use decimals with up to three places in the context of measurement',
          assessments: [],
        },
        {
          code: '6Nn17 ',
          caption: 'Recognise odd and even numbers and multiples of 5, 10, 25, 50 and 100 up to 1000',
          assessments: [],
        },
        {
          code: '6Nn18 ',
          caption: 'Make general statements about sums, differences and multiples of odd and even numbers',
          assessments: [],
        },
        {
          code: '6Nn19 ',
          caption: 'Recognise prime numbers up to 20 and find all prime numbers less than 100',
          assessments: [],
        },
        {
          code: '6Nn20 ',
          caption: 'Recognise the historical origins of our number system and begin to understand how it developed',
          assessments: [],
        },
        {
          code: '6Nn21',
          caption: 'Compare fractions with the same denominator and related denominators, e.g. 3/4 with 7/8',
          isLive: true,
          assessments: [
            {
              human_id: '3_4',
              caption: 'Comparing Fractions',
              numQuestions: 9,
              estimatedTime: '10 minutes',
              taskId: 'h5BjH9YiFqKwL652fM7P',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-fractions/I'},
                {boosterPackPath:'cambridge-stage6-fractions/J'},
                {boosterPackPath:'cambridge-stage6-fractions/K'},
              ],
            },
          ],
        },
        {
          code: '6Nn22',
          isLive: false,
          caption: 'Recognise equivalence between fractions, e.g. between 1/100s , 1/10s and 1/2s',
          assessments: [ ],
        },
        {
          code: '6Nn23',
          caption: 'Recognise and use the equivalence between decimal and fraction forms',
          assessments: [],
        },
        {
          code: '6Nn24',
          isLive: false,
          caption: 'Order mixed numbers and place between whole numbers on a number line',
          assessments: [ ],
        },
        {
          code: '6Nn25',
          isLive: true,
          caption: 'Change an improper fraction to a mixed number, e.g. 17/8 to 2 1/8',
          assessments: [
            {
              human_id: '3_1',
              caption: 'Mixed and Improper Fractions',
              numQuestions: 4,
              estimatedTime: '5 to 10 minutes',
              taskId: 'CG3lC6vH1IQ8jBhwpLgw',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-fractions/A'},
              ],
            },
          ],
        },
        {
          code: '6Nn26',
          isLive: true,
          caption: 'Reduce fractions to their simplest form, where this is 1/4 , 1/2 , 3/4 or a number of fifths or tenths',
          assessments: [
            {
              human_id: '3_3',
              caption: 'Equivalent Fractions',
              numQuestions: 9,
              estimatedTime: '10 to 15 minutes',
              taskId: 'Yz16xJfYdYlCZtuDpjn7',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-fractions/C'},
                {boosterPackPath:'cambridge-stage6-fractions/D'},
                {boosterPackPath:'cambridge-stage6-fractions/E'},
                {boosterPackPath:'cambridge-stage6-fractions/F'},
                {boosterPackPath:'cambridge-stage6-fractions/G'},
                {boosterPackPath:'cambridge-stage6-fractions/H'},
              ],
            },
          ],
        },
        {
          code: '6Nn27',
          caption: 'Begin to convert a vulgar fraction to a decimal fraction using division',
          assessments: [],
        },
        {
          code: '6Nn28',
          caption: 'Understand percentage as parts in every 100 and express 1/2 , 1/4 , 1/3 , 1/10 , 1/100 as percentages',
          assessments: [],
        },
        {
          code: '6Nn29',
          caption: 'Find simple percentages of shapes and whole numbers',
          assessments: [],
        },
        {
          code: '6Nn30',
          caption: 'Solve simple problems involving ratio and direct proportion',
          assessments: [],
        },
        {
          code:'6Nn',
          caption: 'Unit Test',
          isLive: true,
          assessments: [
            {
              human_id: 'summative',
              caption: 'Fractions Unit Test',
              numQuestions: 14,
              estimatedTime: '20 to 40 minutes',
              taskId: 'KfjZtDmevXktofnICPgM',
              isHeadsUp: false,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-fractions/A'},
                {boosterPackPath:'cambridge-stage6-fractions/B'},
                {boosterPackPath:'cambridge-stage6-fractions/C'},
                {boosterPackPath:'cambridge-stage6-fractions/D'},
                {boosterPackPath:'cambridge-stage6-fractions/E'},
                {boosterPackPath:'cambridge-stage6-fractions/F'},
                {boosterPackPath:'cambridge-stage6-fractions/G'},
                {boosterPackPath:'cambridge-stage6-fractions/H'},
                {boosterPackPath:'cambridge-stage6-fractions/I'},
                {boosterPackPath:'cambridge-stage6-fractions/J'},
                {boosterPackPath:'cambridge-stage6-fractions/K'},
              ],
            },
          ],
        },
      ],
    },
    {
      code: 'Nc',
      caption: 'Calculation',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Nc1 ',
          caption: 'Recall addition and subtraction facts for numbers to 20 and pairs of one-place decimals with a total of 1, e.g. 0.4 + 0.6',
          assessments: [],
        },
        {
          code:'6Nc2 ',
          caption: 'Derive quickly pairs of one-place decimals totalling 10, e.g. 7.8 and 2.2, and two-place decimals totalling 1, e.g. 0.78 + 0.22',
          assessments: [],
        },
        {
          code:'6Nc3 ',
          caption: 'Know and apply tests of divisibility by 2, 4, 5, 10, 25 and 100',
          assessments: [],
        },
        {
          code:'6Nc4 ',
          caption: 'Use place value and number facts to add or subtract two-digit whole numbers and to add or subtract three-digit multiples of 10 and pairs of decimals, e.g. 560 + 270; 2.6 + 2.7; 0.78 + 0.23',
          assessments: [],
        },
        {
          code:'6Nc5 ',
          caption: 'Add/subtract near multiples of one when adding numbers with one decimal place, e.g. 5.6 + 2.9; 13.5 – 2.1',
          assessments: [],
        },
        {
          code:'6Nc6 ',
          caption: 'Add/subtract a near multiple of 10, 100 or 1000, or a near whole unit of money, and adjust, e.g. 3127 + 4998; 5678 – 1996',
          assessments: [],
        },
        {
          code:'6Nc7 ',
          caption: 'Use place value and multiplication facts to multiply/divide mentally, e.g. 0.8 × 7; 4.8 ÷ 6',
          assessments: [],
        },
        {
          code:'6Nc8 ',
          caption: 'Multiply pairs of multiples of 10, e.g. 30 × 40, or multiples of 10 and 100, e.g. 600 × 40',
          assessments: [],
        },
        {
          code:'6Nc9 ',
          caption: 'Double quickly any two-digit number, e.g. 78, 7.8, 0.78 and derive the corresponding halves',
          assessments: [],
        },
        {
          code:'6Nc10',
          caption: ' Divide two-digit numbers by single-digit numbers, including leaving a remainder Addition and subtraction',
          assessments: [],
        },
        {
          code:'6Nc11',
          caption: ' Add two- and three-digit numbers with the same or different numbers of digits/decimal places',
          assessments: [],
        },
        {
          code:'6Nc12',
          caption: ' Add or subtract numbers with the same and different numbers of decimal places, including amounts of money',
          assessments: [],
        },
        {
          code:'6Nc13',
          caption: ' Find the difference between a positive and negative integer, and between two negative integers in a context such as temperature or on a number line',
          assessments: [],
        },
        {
          code:'6Nc14',
          caption: ' Multiply pairs of multiples of 10, e.g. 30 × 40, or multiples of 10 and 100, e.g. 600 × 40',
          assessments: [],
        },
        {
          code:'6Nc15',
          caption: ' Multiply near multiples of 10 by multiplying by the multiple of 10 and adjusting',
          assessments: [],
        },
        {
          code:'6Nc16',
          caption: ' Multiply by halving one number and doubling the other, e.g. calculate 35 × 16 with 70 × 8',
          assessments: [],
        },
        {
          code:'6Nc17',
          caption: ' Use number facts to generate new multiplication facts, e.g. the 17× table from 10× + 7× tables',
          assessments: [],
        },
        {
          code:'6Nc18',
          caption: ' Multiply two-, three- or four-digit numbers (including sums of money) by a single-digit number and two- or three-digit numbers by two-digit numbers',
          assessments: [],
        },
        {
          code:'6Nc19',
          caption: ' Divide three-digit numbers by single-digit numbers, including those leaving a remainder and divide three-digit numbers by two-digit numbers (no remainder) including sums of money',
          assessments: [],
        },
        {
          code:'6Nc20',
          caption: ' Give an answer to division as a mixed number, and a decimal (with divisors of 2, 4, 5, 10 or 100).',
          assessments: [],
        },
        {
          code:'6Nc21',
          caption: ' Relate finding fractions to division and use them as operators to find fractions including several tenths and hundredths of quantities',
          assessments: [],
        },
        {
          code:'6Nc22',
          caption: ' Know and apply the arithmetic laws as they apply to multiplication (without necessarily using the terms commutative, associative or distributive)',
          assessments: [],
        },

      ],
    },
    {
      code: 'Gs',
      caption: 'Shapes and geometric reasoning',
      isLocked: true,
      learningObjectives: [
        {
          code: '6Gs1',
          caption: 'Classify different polygons and understand whether a 2D shape is a polygon or not',
          assessments: [],
        },
        {
          code: '6Gs2',
          caption: 'Visualise and describe the properties of 3D shapes, e.g. faces, edges and vertices',
          assessments: [],
        },
        {
          code: '6Gs3',
          caption: 'Identify and describe properties of quadrilaterals (including the parallelogram, rhombus and trapezium), and classify using parallel sides, equal sides, equal angles',
          assessments: [],
        },
        {
          code: '6Gs4',
          caption: 'Recognise and make 2D representations of 3D shapes including nets',
          assessments: [],
        },
        {
          code: '6Gs5',
          caption: 'Estimate, recognise and draw acute and obtuse angles and use a protractor to measure to the nearest degree',
          assessments: [],
        },
        {
          code: '6Gs6',
          caption: 'Check that the sum of the angles in a triangle is 180°, for example, by measuring or paper folding; calculate angles in a triangle or around a point',
          assessments: [],
        },
      ],
    },
    {
      code: 'Gp',
      caption: 'Position and movement',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Gp1',
          caption: 'Read and plot co-ordinates in all four quadrants',
          assessments: [],
        },
        {
          code:'6Gp2',
          caption: 'Predict where a polygon will be after one reflection, where the sides of the shape are not parallel or perpendicular to the mirror line, after one translation or after a rotation through 90° about one of its vertices',
          assessments: [],
        },
      ],
    },
    {
      code: 'Gl',
      caption: 'Length, mass and capacity',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Gl1',
          caption: 'Select and use standard units of measure. Read and write to two or three decimal places',
          assessments: [],
        },
        {
          code:'6Gl2',
          caption: 'Convert between units of measurement (kg and g, l and ml, km, m, cm and mm), using decimals to three places, e.g. recognising that 1.245 m is 1 m 24.5 cm',
          assessments: [],
        },
        {
          code:'6Gl3',
          caption: 'Interpret readings on different scales, using a range of measuring instruments',
          assessments: [],
        },
        {
          code:'6Gl4',
          caption: 'Draw and measure lines to the nearest centimetre and millimetre',
          assessments: [],
        },
        {
          code:'6Gl5',
          caption: 'Know imperial units still in common use, e.g. the mile, and approximate metric equivalents',
          assessments: [],
        },
      ],
    },
    {
      code: 'Gt',
      caption: 'Time',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Gt1',
          caption: 'Recognise and understand the units for measuring time (seconds, minutes, hours, days, weeks, months, years, decades and centuries); convert one unit of time into another',
          assessments: [],
        },
        {
          code:'6Gt2',
          caption: 'Tell the time using digital and analogue clocks using the 24-hour clock',
          assessments: [],
        },
        {
          code:'6Gt3',
          caption: 'Compare times on digital and analogue clocks, e.g. realise quarter to four is later than 3:40',
          assessments: [],
        },
        {
          code:'6Gt4',
          caption: 'Read and use timetables using the 24-hour clock',
          assessments: [],
        },
        {
          code:'6Gt5',
          caption: 'Calculate time intervals using digital and analogue times',
          assessments: [],
        },
        {
          code:'6Gt6',
          caption: 'Use a calendar to calculate time intervals in days, weeks or months',
          assessments: [],
        },
        {
          code:'6Gt7',
          caption: 'Calculate time intervals in days, months or years',
          assessments: [],
        },
        {
          code:'6Gt8',
          caption: 'Appreciate how the time is different in different time zones around the world',
          assessments: [],
        },
      ],
    },
    {
      code: 'Ma',
      caption: 'Area and perimeter',
      boosterPackId: 'km6a3YDUgdRnge5b6qyV',
      isLocked: false,
      learningObjectives: [
        {
          code:'6Ma1',
          caption: 'Measure and calculate the perimeter and area of rectilinear shapes',
          isLive: true,
          assessments: [
            {
              human_id: '1_1',
              caption: 'Area of Rectangles and Squares',
              numQuestions: 8,
              estimatedTime: '10 to 20 minutes',
              taskId: 'MmO17PZcHgeEmEBwHq9w',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/A'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/B'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/C'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/D'},
              ],
            },
            {
              human_id: '1_2',
              caption: 'Perimeter of Rectangles and Squares',
              numQuestions: 9,
              estimatedTime: '10 minutes',
              taskId: 'NWjtaTJUboCGokcTzOd7',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/E'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/F'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/G'},
              ],
            },
          ],
        },
        {
          code:'6Ma2',
          caption: 'Estimate the area of an irregular shape by counting squares',
          isLive: true,
          assessments: [
            {
              human_id: '1_5',
              caption: 'Area of Irregular Shapes',
              numQuestions: 3,
              estimatedTime: '5 to 10 minutes',
              taskId: 'oo6XE8VOfxL8SFi1mDYY',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/O'},
              ],
            },
          ],
        },
        {
          code:'6Ma3',
          caption: 'Calculate perimeter and area of simple compound shapes that can be split into rectangles',
          isLive: true,
          assessments: [
            {
              human_id: '1_3',
              caption: 'Perimeter of Compound Shapes',
              numQuestions: 3,
              estimatedTime: '5 to 10 minutes',
              taskId: '5xj7n1nPvpI10ynw6JPx',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/H'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/I'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/J'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/K'},
              ],
            },
            {
              human_id: '1_4',
              caption: 'Area of Compound Shapes',
              numQuestions: 3,
              estimatedTime: '5 to 10 minutes',
              taskId: 'nNCPrT7y9RicFpVPGVED',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/L'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/M'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/N'},
              ],
            },
          ],
        },
        {
          code:'6Ma',
          caption: 'Unit Test',
          isLive: true,
          assessments: [
            {
              human_id: 'summative',
              caption: 'Area and Perimeter',
              numQuestions: 13,
              estimatedTime: '30 to 60 minutes',
              taskId: 'mRk6q3iMQhfjGgXmk56b',
              isHeadsUp: false,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-area_perimeter/A'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/B'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/C'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/D'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/E'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/F'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/G'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/H'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/I'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/J'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/K'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/L'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/M'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/N'},
                {boosterPackPath:'cambridge-stage6-area_perimeter/O'},
              ],
            },
          ],
        },
      ],
    },
    {
      code: 'Dh',
      caption: 'Organising, categorising and representing data',
      isLocked: false,
      boosterPackId: 'FFUCAL9lvAsgZZPaFsep',
      learningObjectives: [
        {
          code:'6Dh1',
          caption: 'Solve a problem by representing, extracting and interpreting data in tables, graphs, charts and diagrams, e.g. line graphs for distance and time; a price ‘ready-reckoner’ for currency conversion; frequency tables and bar charts with grouped discrete data',
          isLive: true,
          assessments: [
            {
              human_id: '2_2',
              caption: 'Line Graphs',
              numQuestions: 10,
              estimatedTime: '5 to 15 minutes',
              taskId: '1UaIm5OGTUkCqiKMZPUY',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-data/H'},
                {boosterPackPath:'cambridge-stage6-data/I'},
                {boosterPackPath:'cambridge-stage6-data/J'},
                {boosterPackPath:'cambridge-stage6-data/K'},
              ],
            },
            {
              human_id: '2_3',
              caption: 'Bar Graphs',
              numQuestions: 5,
              estimatedTime: '5 to 15 minutes',
              taskId: 'tFltFWpdDWZsVB51sO9U',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-data/L'},
                {boosterPackPath:'cambridge-stage6-data/M'},
                {boosterPackPath:'cambridge-stage6-data/N'},
                {boosterPackPath:'cambridge-stage6-data/O'},
              ],
            },
            {
              human_id: '2_4',
              caption: 'Pie Graphs',
              numQuestions: 3,
              estimatedTime: '3  minutes',
              taskId: 'BBwDlOXK7jhdUBrBBnx1',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-data/P'},
                {boosterPackPath:'cambridge-stage6-data/Q'},
                {boosterPackPath:'cambridge-stage6-data/R'},
                {boosterPackPath:'cambridge-stage6-data/S'},
                {boosterPackPath:'cambridge-stage6-data/T'},
              ],
            },
            {
              human_id: '2_5',
              caption: 'Tables',
              numQuestions: 4,
              estimatedTime: '10 to 15 minutes',
              taskId: 'Culsa1EjuSIGk5OzNJAT',
              isHeadsUp: true,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-data/U'},
                {boosterPackPath:'cambridge-stage6-data/V'},
                {boosterPackPath:'cambridge-stage6-data/W'},
              ],
            },
          ],
        },
        {
          code:'6Dh2',
          caption: 'Find the mode and range of a set of data from relevant situations, e.g. scientific experiments',
          isLive: false,
          assessments: [],
        },
        {
          code:'6Dh3',
          caption: 'Begin to find the median and mean of a set of data',
          isLive: false,
          assessments: [],
        },
        {
          code:'6Dh4',
          caption: 'Explore how statistics are used in everyday life',
          isLive: false,
          assessments: [
            {
              human_id: '2_1',
              caption: 'Averages',
              numQuestions: 8,
              estimatedTime: '5 minutes',
              taskId: '0kmrEsn0AnreuDxANaQa',
              isHeadsUp: true,
              linkedBoosters: [
              ],
            },
          ],
        },
        {
          code:'6Dh',
          caption: 'Unit Test',
          isLive: true,
          assessments: [
            {
              human_id: 'summative',
              caption: 'Organising, Categorising and Representing Data',
              numQuestions: 18,
              estimatedTime: '20 to 40 minutes',
              taskId: '4juJjWHzU9dUWHeYUD6m',
              isHeadsUp: false,
              linkedBoosters: [
                {boosterPackPath:'cambridge-stage6-data/A'},
                {boosterPackPath:'cambridge-stage6-data/B'},
                {boosterPackPath:'cambridge-stage6-data/C'},
                {boosterPackPath:'cambridge-stage6-data/D'},
                {boosterPackPath:'cambridge-stage6-data/E'},
                {boosterPackPath:'cambridge-stage6-data/F'},
                {boosterPackPath:'cambridge-stage6-data/G'},
                {boosterPackPath:'cambridge-stage6-data/H'},
                {boosterPackPath:'cambridge-stage6-data/I'},
                {boosterPackPath:'cambridge-stage6-data/J'},
                {boosterPackPath:'cambridge-stage6-data/K'},
                {boosterPackPath:'cambridge-stage6-data/L'},
                {boosterPackPath:'cambridge-stage6-data/M'},
                {boosterPackPath:'cambridge-stage6-data/N'},
                {boosterPackPath:'cambridge-stage6-data/O'},
                {boosterPackPath:'cambridge-stage6-data/P'},
                {boosterPackPath:'cambridge-stage6-data/Q'},
                {boosterPackPath:'cambridge-stage6-data/R'},
                {boosterPackPath:'cambridge-stage6-data/S'},
                {boosterPackPath:'cambridge-stage6-data/T'},
                {boosterPackPath:'cambridge-stage6-data/U'},
                {boosterPackPath:'cambridge-stage6-data/V'},
                {boosterPackPath:'cambridge-stage6-data/W'},
              ],
            },
          ],
        },
      ],
    },
    {
      code: 'Db',
      caption: 'Probability',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Db1',
          caption: 'Use the language associated with probability to discuss events, to assess likelihood and risk, including those with equally likely outcomes',
          assessments: [],
        },
      ],
    },
    {
      code: 'Pt',
      caption: 'Using techniques and skills in solving mathematical problems',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Pt1',
          caption: 'Choose appropriate and efficient mental or written strategies to carry out a calculation involving addition, subtraction, multiplication or division',
          assessments: [],
        },
        {
          code:'6Pt2',
          caption: 'Understand everyday systems of measurement in length, weight, capacity, temperature and time and use these to perform simple calculations',
          assessments: [],
        },
        {
          code:'6Pt3',
          caption: 'Check addition with a different order when adding a long list of numbers; check when subtracting by using the inverse',
          assessments: [],
        },
        {
          code:'6Pt4',
          caption: 'Recognise 2D and 3D shapes and their relationships, e.g. a cuboid has a rectangular cross-section',
          assessments: [],
        },
        {
          code:'6Pt5',
          caption: 'Estimate and approximate when calculating, e.g. use rounding, and check working',
          assessments: [],
        },
      ],
    },
    {
      code: 'Ps',
      caption: 'Using understanding and strategies in solving problems',
      isLocked: true,
      learningObjectives: [
        {
          code:'6Ps1',
          caption: 'Explain why they chose a particular method to perform a calculation and show working',
          assessments: [],
        },
        {
          code:'6Ps2',
          caption: 'Deduce new information from existing information and realise the effect that one piece of information has on another',
          assessments: [],
        },
        {
          code:'6Ps3',
          caption: 'Use logical reasoning to explore and solve number problems and mathematical puzzles',
          assessments: [],
        },
        {
          code:'6Ps4',
          caption: 'Use ordered lists or tables to help solve problems systematically',
          assessments: [],
        },
        {
          code:'6Ps5',
          caption: 'Identify relationships between numbers and make generalised statements using words, then symbols and letters, e.g. the second number is twice the first number plus 5 (n, 2n + 5); all the numbers are multiples of 3 minus 1 (3n – 1); the sum of angles in a triangle is 180°',
          assessments: [],
        },
        {
          code:'6Ps6',
          caption: 'Make sense of and solve word problems, single and multi-step (all four operations), and represent them, e.g. with diagrams or on a number line; use brackets to show the series of calculations necessary',
          assessments: [],
        },
        {
          code:'6Ps7',
          caption: 'Solve simple word problems involving ratio and direct proportion',
          assessments: [],
        },
        {
          code:'6Ps8',
          caption: 'Solve simple word problems involving percentages, e.g. find discounted prices',
          assessments: [],
        },
        {
          code:'6Ps9',
          caption: 'Make, test and refine hypotheses, explain and justify methods, reasoning, strategies, results or conclusions orally',
          assessments: [],
        },
      ],
    },
  ]
}