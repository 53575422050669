import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { MainNavComponent } from './main-nav/main-nav.component';
// pages/views
import { UserLoginComponent } from './user-login/user-login.component';
import { HomePageComponent } from './home-page/home-page.component';

// smaller components
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { NotificationMessageComponent } from './notification-message/notification-message.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { UserFormComponent } from './user-form/user-form.component';
import { SsrPageComponent } from './ssr-page/ssr-page.component';
import { ItematicThumbnailComponent } from './itematic-thumbnail/itematic-thumbnail.component';
import { ListEditorComponent } from './list-editor/list-editor.component';
import { SortComponent } from './sort/sort.component';
import { PlayComponent } from './play/play.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { UserRegistrationFormComponent } from './user-registration-form/user-registration-form.component';
import { UserLoginFormComponent } from './user-login-form/user-login-form.component';
import { UserRegistrationSsoComponent } from './user-registration-sso/user-registration-sso.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { IndexComponent } from './index/index.component';
import { UiPartialModule } from '../ui-partial/ui-partial.module';
import { WidgetCountdownModule } from '../widget-countdown/widget-countdown.module';
import { UserRegistrationAnonComponent } from './user-registration-anon/user-registration-anon.component';
import { UserRegistrationInprogressComponent } from './user-registration-inprogress/user-registration-inprogress.component';
import { FormTextAgreeTermsComponent } from './form-text-agree-terms/form-text-agree-terms.component';
import { FormTextAgreeIntCommsComponent } from './form-text-agree-int-comms/form-text-agree-int-comms.component';
import { FormTextInformConsentComponent } from './form-text-inform-consent/form-text-inform-consent.component';
import { UserLogoutComponent } from './user-logout/user-logout.component';

@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    ReactiveFormsModule,
    UiPartialModule,
    WidgetCountdownModule,
  ],
  declarations: [
    UserLoginComponent,
    HomePageComponent,
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserFormComponent,
    SsrPageComponent,
    ItematicThumbnailComponent,
    ListEditorComponent,
    SortComponent,
    PlayComponent,
    UserRegistrationComponent,
    UserRegistrationFormComponent,
    UserLoginFormComponent,
    UserRegistrationSsoComponent,
    MaintenanceComponent,
    IndexComponent,
    UserRegistrationAnonComponent,
    UserRegistrationInprogressComponent,
    FormTextAgreeTermsComponent,
    FormTextAgreeIntCommsComponent,
    FormTextInformConsentComponent,
    UserLogoutComponent,
  ],
  exports: [
    MainNavComponent,
    LoadingSpinnerComponent,
    NotificationMessageComponent,
    UserProfileComponent,
    UserLoginFormComponent,
    UiPartialModule,
    WidgetCountdownModule,
  ]
})
export class UiModule {}
