import { ISelectBlockContent } from "../../../ui-partial/body-block-arr-select/body-block-arr-select.component";

export interface ISelectBlockContentUserType extends ISelectBlockContent {
    data: {
        ui: string,
    }
}

export const OPTIONS_ACCOUNT_USER_TYPE:ISelectBlockContentUserType[] = [
    { 
        id: 'teacher',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Fteacher-v3.png?alt=media&token=cce7cb1d-1eef-4181-8ee2-420a7d740508',
        caption: 'Teacher',
        data: {
            ui: 'teacher'
        },
    },
    // { 
    //     id: 'parent',
    //     imageUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Fparent.png?alt=media&token=1067f13c-9884-4926-b321-100494bda326',
    //     caption: 'Parent',
    //     data: {
    //         ui: 'parent'
    //     },
    // },
    { 
        id: 'student',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Fchild.gif?alt=media&token=f5ac581d-7c4d-4e36-8737-ee2399fc664b',
        caption: 'Student',
        data: {
            ui: 'student'
        },
    },
];