import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirestoreMomentPipe } from '../time/firestore-moment.pipe';
import { SortPipe } from './sort.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SortPipe,
    FirestoreMomentPipe
  ],
  exports: [
    SortPipe,
    FirestoreMomentPipe
  ]
})
export class TimeModule { }
