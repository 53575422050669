import * as _ from 'lodash';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { IHeadsUpLobbyPlayers, IHeadsUpLobbyPlayerActions, AvatarPlayerActions, IHeadsUpLobby, HeadsUpScreenModes, IHeadsUpLobbyPlayerQuestionState, genBaseCosmetics, CosmeticCategory } from '../../data/collections/heads-up-lobby';
import { TaskCacheService } from '../../ui-taskcreator/task-cache.service';
import { IAssignment } from '../../data/collections/assignments.types';
import { ITask } from '../../data/collections/tasks.types';
import { serverTimestamp } from '../../data/timestamp';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IUser } from '../../data/collections/users.types';
import { download } from './utils/download';
import { jsonToCsv } from './utils/csv';
import { ClassroomService } from '../classroom.service';
import { AssignmentService } from '../assignment.service';
import { WhitelabelService } from '../../domain/whitelabel.service';

const ACCOUNT_LOOKUP_LIMIT = 100;

interface IPerformance {
  uid: string,
  timestampSeconds?: number,
  isCorrect: boolean
}
interface IRankedPerformance {
  uid: string,
  timestampSeconds?: number,
  bonus?: number,
}

@Component({
  selector: 'headsup-lobby',
  templateUrl: './headsup-lobby.component.html',
  styleUrls: ['./headsup-lobby.component.scss']
})
export class HeadsupLobbyComponent implements OnInit {

  @ViewChild('screenContainer') screenContainer: ElementRef;

  lobbyState:Partial<IHeadsUpLobby> = {
    screenMode: HeadsUpScreenModes.LOADING
  } ;

  joinedPlayers: Array<IHeadsUpLobbyPlayers> = [];
  numUpdates = 0;
  assignmentId: string;
  isAssessmentLoaded:boolean;
  activeAssignment:IAssignment; // might not need
  activeTask: ITask;
  currentQuestionTaskId: string;
  currentLobbyId:string;
  delayedStartFlag:boolean;
  submissionRef = new Map(); // question > student 
  performance:Map<string, Map<string, IPerformance>> = new Map(); // question > student > isCorrect
  isStudentListShown;
  currentChatMessage = new FormControl();
  classroomId:string;
  classCode: string;
  
  chatForm = new FormGroup({
    currentChatMessage: this.currentChatMessage,
 });
  
  constructor(
    public whitelabelService: WhitelabelService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AuthService,
    private tcs: TaskCacheService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private classroomService: ClassroomService,
    private assignmentService: AssignmentService,
  ) { }

  ngOnInit() {
    this.chatPanel.deactivate();
    this.sidePanel.deactivate();
    this.route.params.subscribe(params => {
      this.assignmentId = params['assignmentId'];
      this.classroomId = params['classroomId'];
      this.classroomService
        .getClassroomInfo(this.classroomId)
        .subscribe( classroom => {
          if (classroom){
            this.classCode = classroom.classCode;
          }
        });
      this.listenToUserJoins();
      this.listenToUserChat();
      this.listenToQuestionSubmissions();
      this.initLobby();
      this.loadAssignment(this.assignmentId);
      setTimeout(()=>{
        // this is just so that the teacher is more likely to see the animation showing that the heads up is ready to be started
        this.delayedStartFlag = true;
      }, 3*1000)
    });
  }

  initLobby(){
    this.afs.collection<IHeadsUpLobby>('headsUpLobby', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .limit(10); // should never be more than 1...
    }).get().toPromise().then(observer => {
      // console.log('initlobby', observer.docs.length)
      if (observer.docs.length > 0){
        this.lobbyState = <IHeadsUpLobby>observer.docs[0].data()
        // console.log('this.lobbyState', this.lobbyState)
        this.listenToLobby(observer.docs[0].id);
      }
      else{
        this.lobbyState = {
          assignmentId: this.assignmentId,
          createdByUid: this.auth.getUid(),
          createdByName: this.auth.getDisplayName(),
          timeOpen: serverTimestamp(),
          isStarted: false,
          isSolutionShown: false,
          currentQuestionIndex: 0,
          screenMode: HeadsUpScreenModes.LOBBY,
        }
        this.afs.collection<Partial<IHeadsUpLobby>>('headsUpLobby').add(this.lobbyState).then(res => {
          this.listenToLobby(res.id);
        });
      }
    });
  }

  toggleStudentList(){
    this.isStudentListShown = !this.isStudentListShown;
  }

  removeJoinedPlayer(player:IHeadsUpLobbyPlayers){
    _.pull(this.joinedPlayers, player);
    this.afs.doc<IHeadsUpLobbyPlayers>('headsUpLobbyPlayers/'+player.__id).delete();
  }

  updatePointsForCurrentQuestion(){
    // console.log('updatePointsForCurrentQuestion')
    // guard against computation happening more than once
    let questionTaskId = this.currentQuestionTaskId;
    // identify and rank all players
    let rankedPlayers:IRankedPerformance[] = [];
    let playerRankFactors = new Map()
    let speedSorter = (a:IRankedPerformance, b:IRankedPerformance) => {
      return (a.timestampSeconds > b.timestampSeconds) ? 1 : -1;
    }
    this.joinedPlayers.forEach(player => {
      let performance = this.getQuestionPerformance(questionTaskId, player.uid);
      rankedPlayers.push({
        uid: player.uid,
        timestampSeconds: performance.timestampSeconds,
      })
    });
    rankedPlayers = rankedPlayers.sort(speedSorter);
    let scoreBonusBaseline = 100;
    let rankFactorIncrement = Math.round(scoreBonusBaseline / rankedPlayers.length);
    let remainingScoreBonus = scoreBonusBaseline;
    rankedPlayers.forEach(rankedPlayer => {
      playerRankFactors.set(rankedPlayer.uid, remainingScoreBonus);
      remainingScoreBonus -= rankFactorIncrement;
    })
    // assign scores
    this.joinedPlayers.forEach(player => {
      if (!player.score){ player.score = 0;}
      if (!player.coins){ player.coins = 0;}
      let score = 0;
      let performance = this.getQuestionPerformance(questionTaskId, player.uid);
      if (performance){
        if (performance.isCorrect){
          let rankFactor = playerRankFactors.get(player.uid)
          score += 500 + rankFactor;
        }
        else {
          score += 100
        }
      }
      player.coins += Math.round(score/10);
      player.score += score;
      this.postPlayerScores(player);
    });
    this.sortPlayersByScore();
    this.updateLeaderboardCache();
  }

  sortPlayersByScore = _.throttle(() => {
    let scoreSorter = (a:IHeadsUpLobbyPlayers, b:IHeadsUpLobbyPlayers) => {
      if (!a.isAnonymous && b.isAnonymous){ return -1; }
      if (a.isAnonymous && !b.isAnonymous){ return 1; }
      return (a.score < b.score) ? 1 : -1;
    }
    this.joinedPlayers = this.joinedPlayers.sort(scoreSorter);
    let lastPlayer = null;
    let currentRank = 1;
    let playersCrossed = 0;
    this.joinedPlayers.forEach(player => {
      if (!(lastPlayer && player.score === lastPlayer.score)){
        currentRank = playersCrossed + 1;
      }
      player.rank = currentRank
      playersCrossed ++;
      lastPlayer = player;
    })
  }, 500, {leading: true})

  postPlayerScores(player:IHeadsUpLobbyPlayers){
    this.afs.doc<Partial<IHeadsUpLobbyPlayers>>('headsUpLobbyPlayers/'+player.__id).set({
      score: player.score,
      coins: player.coins,
    }, {merge: true})
  }


  gotoScreenQuestions(){
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      screenMode: HeadsUpScreenModes.QUESTIONS,
      isStarted: true,
      timeStart: serverTimestamp(),
    })
  }

  gotoScreenLeaderboard(){
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      screenMode: HeadsUpScreenModes.LEADERBOARD,
    })
  }

  gotoScreenPodium(){
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      screenMode: HeadsUpScreenModes.PODIUM,
      timeClose: serverTimestamp(),
    })
    this.assignmentService.directCloseAssignment(this.assignmentId);
  }

  // for testing only :)
  hideSolution(){
    this.updatePointsForCurrentQuestion();
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      isSolutionShown: false,
    })
  }

  revealSolution(){
    this.updatePointsForCurrentQuestion();
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      isSolutionShown: true,
    })
  }

  gotoNextQuestion(){
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/'+this.currentLobbyId).update({
      screenMode: HeadsUpScreenModes.QUESTIONS,
      currentQuestionIndex: this.lobbyState.currentQuestionIndex + 1,
      isSolutionShown: false,
    })
  }

  
  listenToLobby(lobbyId:string){
    this.currentLobbyId = lobbyId;
    this.afs.doc<IHeadsUpLobby>('headsUpLobby/' + lobbyId).valueChanges().subscribe(data => {
      this.lobbyState = data;
    })
  }

  currentChat = [];
  listenToUserChat() {
    this.afs.collection('headsUpLobbyChat/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .orderBy('timeCreated')
        .limit(30)
    }).stateChanges().subscribe(observer => {
       observer.forEach( entry => {
        const chatEntry:{__id?:string, isHost:boolean, displayName:string, msg} = <any>entry.payload.doc.data();
        chatEntry.__id = entry.payload.doc.id;
        if (this.currentChat.length === 0 || this.currentChat[this.currentChat.length-1].__id !== chatEntry.__id){
          this.currentChat.push(chatEntry);
        }
      })
      // console.log(observer.length, this.currentChat)
      let elements = document.getElementsByClassName('chat-windows');
      if (elements.length > 0){
        for (let i=0; i<elements.length; i++ ){
          let el = elements[i];
          console.log('elements', el)
          el.scrollTop = el.scrollHeight;
        }
      }
    })
  }

  listenToUserJoins() {
    this.afs.collection<IHeadsUpLobbyPlayers>('headsUpLobbyPlayers/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .limit(ACCOUNT_LOOKUP_LIMIT);
    }).stateChanges().subscribe(observer => {
      observer.forEach(entry => {
        const playerEntry = entry.payload.doc.data();
        playerEntry.__id = entry.payload.doc.id;
        this.checkIfPlayerAnon(playerEntry);
        if (this.isPlayerPresent(playerEntry)){
          let existingPlayerEntry = _.find(this.joinedPlayers, { uid: playerEntry.uid });
          existingPlayerEntry.displayName = playerEntry.displayName;
          this.checkIfPlayerAnon(existingPlayerEntry);
        }
        else if (playerEntry.isPresent) {
          this.newPlayerJoined(playerEntry);
        }  
      });
      this.updatePlayersCount();
    });
  }

  checkIfPlayerAnon(playerEntry){
    if (playerEntry.displayName === "nameless user"){
      playerEntry.isAnonymous = true;
    }
  }

  listenToQuestionSubmissions() {
    this.afs.collection<IHeadsUpLobbyPlayerQuestionState>('headsUpLobbyPlayerQuestionState/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .limit(1000);
    }).stateChanges().subscribe(entries => {
      entries.forEach(entry => {
        this.processQuestionSubmission(entry.payload.doc.id, entry.payload.doc.data());
      })
    })
  }

  ensureSubmissionRef(questionTaskId:string){
    if (!this.submissionRef.has(questionTaskId)){
      this.submissionRef.set(questionTaskId, [])
    }
    if (!this.performance.has(questionTaskId)){
      this.performance.set(questionTaskId, new Map())
    }
  }

  getQuestionSubmissionCount(questionTaskId:string){
    this.ensureSubmissionRef(questionTaskId);
    let submissionQuestionEntries = this.submissionRef.get(questionTaskId);
    return submissionQuestionEntries.length;
  }

  getQuestionSubmissionProgress(questionTaskId:string){
    return Math.round(100*(this.getQuestionSubmissionCount(questionTaskId) / this.joinedPlayers.length))
  }

  sendCurrentChatMessage(){
    this.sendChat(this.currentChatMessage.value);
    this.currentChatMessage.reset('');
  }

  isAdminUser(){
    return (this.auth.getUi() === 'admin');
  }

  exportLobbyList(){
    // get all of their emails
    Promise.all(this.joinedPlayers.map(player => {
      if (player.uid){
        return this.afs.doc('users/'+player.uid).get().toPromise().then( snap => {
          let userInfo:IUser = <IUser>snap.data();
          player.email = userInfo.email;
        })
      }
    }))
    .then( () => {
      this.joinedPlayers[0].email = '';
      download('lobby.csv', jsonToCsv(this.joinedPlayers));
    });
  }

  updatePlayersCount = _.throttle(() => {
    this.afs.doc('headsUpLobbyPlayersCount/'+this.assignmentId).set({
      count: this.joinedPlayers.length,
    });
  }, 5000);

  updateLeaderboardCache = _.throttle(() => {
    console.log('updateLeaderboardCache', this.joinedPlayers.slice(0, 5))
    this.afs.doc('headsUpLobbyLeaderboardCache/'+this.assignmentId).set({
      playersJson: JSON.stringify(this.joinedPlayers.slice(0, 5))
    });
  }, 5000)

  sendChat(msg:string){
    if (msg){
      this.afs.collection('headsUpLobbyChat').add({
        assignmentId: this.assignmentId,
        isHost: true,
        uid: this.auth.getUid(),
        displayName: this.auth.getDisplayName(),
        timeCreated: serverTimestamp(),
        msg,
      })
    }
  }

  leaderBoardCap_MIN = 5;
  leaderBoardCap = this.leaderBoardCap_MIN;
  decreaseLeaderboardSize(){
    if (this.leaderBoardCap > this.leaderBoardCap_MIN){
      this.leaderBoardCap -= 5;
    }
  }
  increaseLeaderboardSize(){
    if (this.leaderBoardCap < this.joinedPlayers.length){
      this.leaderBoardCap += 5;
    }
  }

  getQuestionPerformance(questionTaskId:string, uid:string):Partial<IPerformance>{
    this.ensureSubmissionRef(questionTaskId);
    let questionPerformance = this.performance.get(questionTaskId);
    if (questionPerformance.has(uid)){
      return questionPerformance.get(uid);
    }
    return {};
  }

  processQuestionSubmission(id, data:IHeadsUpLobbyPlayerQuestionState){
    // console.log('student submission', id, data)
    this.ensureSubmissionRef(data.questionTaskId);
    // set performance
    let questionPerformance = this.performance.get(data.questionTaskId);
    questionPerformance.set(data.uid, {
      uid: data.uid,
      isCorrect: data.isCorrect,
      timestampSeconds: data.timestamp.seconds
    });
    // make sure that submission is tracked for the purpose of counting
    let targetSubmissionEntry = null;
    let submissionQuestionEntries = this.submissionRef.get(data.questionTaskId);
    submissionQuestionEntries.forEach(submissionEntry => {
      if (submissionEntry.uid === data.uid){
        targetSubmissionEntry = submissionEntry;
      }
    })
    if (targetSubmissionEntry === null){
      submissionQuestionEntries.push({
        uid: data.uid
      })
    }
  }


  newPlayerJoined(player?: IHeadsUpLobbyPlayers) {
    if (player) {
      this.joinedPlayers.push(player);
      this.numUpdates++;
    } 
    else {
      // this.joinedPlayers.push({ assignmentId: 'TEST', uid: String(Math.random()), isPresent: true });
    }
    this.sortPlayersByScore();
  }

  toggleFullScreen() {
    // console.log('toggleFullScreen', this.isFullScreen(), window.document['fullscreenElement'])
    if (!this.isFullScreen()){
      this.screenContainer.nativeElement.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    }
    else{
      window.document.exitFullscreen();
    }
  }

  isFullScreen(){
    return window.document['fullscreenElement'] && true;
  }

  startHeadsUp(){
    this.gotoScreenQuestions();
  }

  isReadyToStart(){
    return this.isAssessmentLoaded && (this.joinedPlayers.length>0)
  }

  getCurrentCustomTaskSetQuestion(){
    if (this.isAssessmentLoaded){
      this.currentQuestionTaskId = this.activeTask.questions[this.lobbyState.currentQuestionIndex];
      return this.tcs.getQuestionByTaskId(this.currentQuestionTaskId);
    }
  }

  isLastQuestion(){
    if (this.activeTask){
      return this.lobbyState.currentQuestionIndex === this.activeTask.questions.length - 1;
    }
  }

  loadAssignment(assignmentId:string){
    return this.afs.doc('assignments/'+assignmentId).get().toPromise().then( res => {
      this.activeAssignment = <IAssignment>res.data();
      return this.loadTask(this.activeAssignment.tasks[0]); // hard-coded to one for now...
    })
  }

  loadTask(taskId:string){
    return this.afs.doc('tasks/'+taskId).get().toPromise().then( res => {
      const task:ITask = <ITask>res.data();
      this.activeTask = task;
      this.activeTask.__id = taskId;
      const customTaskSetsLoaders = [];
      const customTaskSetsIds = [];
      if (task.customTaskSetId){
        customTaskSetsIds.push(task.customTaskSetId);
      }
      customTaskSetsIds.forEach( customTaskSetId => {
        customTaskSetsLoaders.push(
          this.tcs.loadCustomTaskSetToCache(customTaskSetId)
        );
      })
      return Promise.all(customTaskSetsLoaders).then( () => {
        this.isAssessmentLoaded = true;
      })
    })
  }

  isPlayerPresent(player: IHeadsUpLobbyPlayers) {
    return Boolean(_.find(this.joinedPlayers, { uid: player.uid }));
  }
}
