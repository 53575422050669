
import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { StudentTaskService } from '../../functions-student/student-task.service';
import { ITEMATIC_ENGINE_DOMAIN } from '../../core/constants';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IClassroomAssignment } from '../../data/collections/classroom-assignment.types';
import { IItematicAppState, IItematicSceneState } from '../../data/itematic/app-state.types';
import { IAssignmentTaskSubmission } from '../../data/collections/assignment-task-submissions.types';
import { IAssignmentSubmission } from '../../data/collections/assignment-submissions.types';
import { ITask } from '../../data/collections/tasks.types';
import { auth } from 'firebase';
import { firestore } from 'firebase/app';
import { AuthService } from '../../core/auth.service';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { TaskCacheService } from '../../ui-taskcreator/task-cache.service';
import { IQuestionConfig as ICustomQuestionConfig } from '../../ui-taskcreator/models/index';
import { gradeUserGenQResponse } from './util/grade-response';
import { COLLECTIONS } from './util/constants';
import { IQuestionConfig } from './util/types';
import { joinIdKey } from './util/join-key';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { ITaskBoosterMapping } from '../../ui-taskcreator/booster-assign/booster-assign.component';
import { IItemPerformance, BoosterpacksService } from '../boosterpacks.service';
import { IItemCheckpoint } from '../../data/collections/booster-packs-item-checkpoints';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { TaskService } from '../task.service';

const DEFAULT_ENGINE_VERSION = '5.15.4';
export interface ICollectionDef { 
  name: string,
  scehma?: any,
  // sub: Key<string, ICollectionDef>
}


interface IAppletState {
  current_frame: number,
  current_scene_id: string,
  item_id: string,
  item_version_id: string,
  max_reached_frame: number,
  new_scene_id: string,
  total_scenes: number,
}

@Component({
  selector: 'student-assignment-task',
  templateUrl: './student-assignment-task.component.html',
  styleUrls: ['./student-assignment-task.component.scss']
})
export class StudentAssignmentTaskComponent implements OnInit, OnDestroy {

  public assignmentId:string;
  public classroomId:string;
  public taskId:string;
  public isStateLoaded:boolean;
  public itematicIframe:SafeResourceUrl;
  public classroomAssignment: Observable<IClassroomAssignment[]>;
  public loadProgress = {
    assignment: false,
    task: false,
    subTasks: false,
    taskState: false,
    screenStates: false,
  }
  public isUserGeneratedContentTaskSet;
  public questionStateMaster;
  public isAlreadySubmitted:boolean;
  public isBoosterAssignmentComplete:boolean;
  public numNewCheckpoints:number;

  private lastItematicScreenId:string;
  private currentQuestionTaskId;
  private currentQuestionTaskIndex;
  private questionTaskIds;
  private assignmentTaskSubmissions: AngularFirestoreCollection<IAssignmentTaskSubmission>;
  private currentItematicEngine:string = DEFAULT_ENGINE_VERSION;
  private postMessageSubCancel: Function;
  private currentTask:ITask;
  private currentSubmissionId:string;
  private currentAttemptNumber:number;
  private itemScreenToQuestionTask:Map<string, string> = new Map();
  private questionTaskStateCache = new Map();
  private isPreviewMode:boolean;
  private signinSub:Subscription;
  private subTaskSub:Subscription;
  private routeQuerySub:Subscription;
  private routeParamSub:Subscription;
  private taskSub:Subscription;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private tcs: TaskCacheService,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2,
    public whiteLabel: WhitelabelService,
    private taskService: TaskService,
    private bps: BoosterpacksService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { 
    this.postMessageSubCancel = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    
    let queryParamsReceived:boolean = false;
    let routeParamsReceived:boolean = false;
    this.routeQuerySub = this.route.queryParams.subscribe( queryParams => {
      if (queryParamsReceived) { return; }
      queryParamsReceived = true;
      this.isPreviewMode = (queryParams['preview'] === 'true')
      // console.log('this.isPreviewMode', this.isPreviewMode)
      this.routeParamSub = this.route.params.subscribe(params => {
        if (routeParamsReceived) { return; }
        routeParamsReceived = true;
        this.classroomId = params['classroomId'];
        this.assignmentId = params['assignmentId'];
        this.taskId = params['taskId'];
        this.signinSub = this.auth.user.subscribe(userInfo => {
          if (userInfo){
            this.initTaskLoad();
          }
          else{
            if (this.isPreviewMode){
              this.auth.anonymousLogin().then( res => {
                this.initTaskLoad();
              });
            }
            else{
              alert('You must be logged in to access this page');
            }
          }
        });
      });
    })
  }

  ngOnDestroy() {
    if (this.signinSub){ this.signinSub.unsubscribe(); }
    if (this.subTaskSub){ this.subTaskSub.unsubscribe(); }
    if (this.routeQuerySub){ this.routeQuerySub.unsubscribe(); }
    if (this.routeParamSub){ this.routeParamSub.unsubscribe(); }
    if (this.taskSub){ this.taskSub.unsubscribe(); }
  }

  initTaskLoad(){
    this.loadTaskById(this.taskId, ()=>{
      this.loadTaskSubmissionStatus(this.auth.getUid(), this.assignmentId, this.taskId, ()=> {
        this.loadTaskState(this.auth.getUid(), this.assignmentId, this.taskId, this.currentSubmissionId);
        this.loadSubTasksById(this.taskId);
      });
    });
    // this.startOrResumeAttemptSession(this.assignmentId);
  }
  
  handleMessage(event: Event) {
    const message = event as MessageEvent;
    let data:{type:string, payload:any};
    // if (message.origin !== ITEMATIC_ENGINE_DOMAIN) return;
    // console.log('message.data', message.data)
    if (!message.data || typeof message.data === 'object' ){
      return;
    }
    try {
      data = JSON.parse(message.data);
    }
    catch(e){
      console.error(e);
      return;
    }
    // console.log('handleMessage', data.type, data.payload);
    if (data.type === 'SCREENSHOT_CAPTURED'){
      this.recordScreenshot(data.payload)
    }
    else if (data.type === 'RESOURCE_LOAD_COMPLETE'){
      this.messageIntoLesson('LESSON_STATE_RESTORE', this.appletState);
    }
    else if (data.type === 'IDLE_TIME_RESET'){
      // console.log('IDLE_TIME_RESET')
    }
    else if (data.type === 'item-work-submission'){
      // console.log('IDLE_TIME_RESET')
      this.messageIntoLesson('SUBMISSION_RESOLVED_'+data.payload.comm_id, {success:true, msg:'submitted work successfully'})
    }
    else if (data.type === 'ITEM_PROGRESS'){
      this.recordSubmission(data.payload);
      if (data.payload.completed){ // based on Itematic data model
        this.markAsCompleted()
      }
    }
  }

  onUserGenTaskPrev(stateData:any){
    this.postUserGenProgress(this.currentQuestionTaskId, stateData);
    if (!this.isFirstQuestion()){
      this.previousUserGeneratedQuestion();
    }
  }

  onUserGenTaskNext(stateData:any){
    this.postUserGenProgress(this.currentQuestionTaskId, stateData);
    if (this.isLastQuestion()){
      if (confirm('Submit your assessment and exit?')){
        this.markAsCompleted();
      }
    }
    else{
      this.nextUserGeneratedQuestion();
    }
  }

  postUserGenProgress(questionTaskId:string, stateData:Map<number, any>){
    if (!stateData){ stateData = new Map(); }
    this.questionTaskStateCache.set(questionTaskId, stateData);

    // update assignment state
    
    // update question state

    // console.log('postUserGenProgress', stateData)

    // update question submission
    let uid = this.auth.getUid();
    let taskId = this.taskId
    let assignmentId = this.assignmentId;

    let isCorrect = true;
    let isStarted = true;
    let isFilled = true;
    
    let question:ICustomQuestionConfig = this.getCurrentCustomTaskQuestion();
    let responses = [];
    let responseTextAgg = [];
    if (question.entryOrder){
      question.entryOrder.forEach(entryId => {
        const entryState = stateData.get(entryId);
        let responsePayload = gradeUserGenQResponse(entryId, entryState, responseTextAgg, responses);
        if (!responsePayload.isCorrect && !responsePayload.isCustomGrading){
          isCorrect = false;
        }
      })
    }
    // console.log('responses', responses)
    let responseText = responseTextAgg.join('\n');
    let key = joinIdKey([
      uid,
      assignmentId,
      questionTaskId,
      'USERGEN',
      this.currentSubmissionId
    ]);
    let payload:IAssignmentTaskSubmission = {
      timeLastModified: serverTimestamp(),
      uid,
      assignmentId,
      taskId,
      questionTaskId,
      submissionId: this.currentSubmissionId,
      taskType: 'question_screen',
      isCorrect,
      isStarted,
      isFilled,
      responseText,
      responses,
    }
    // if (stateData.validationHash){
    //   payload.submissionLog = firestore.FieldValue.arrayUnion({
    //     timestamp: new Date(),
    //     responses,
    //     validationHash: stateData.validationHash,
    //   })
    // }
    payload.timeCreated = serverTimestamp(); // need to check for created keys at beginning and store in a map
    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_SUBMISSIONS.name)
      .doc(key)
      .set(payload, {merge:true});
  }

  markAsCompleted(){
    this.afs.doc<IAssignmentSubmission>(COLLECTIONS.ASSIGNMENT_SUBMISSIONS.name+'/'+this.currentSubmissionId).update({
      isSubmitted: true,
      timeSubmitted: serverTimestamp()
    });
    return this.taskService
      .computeQuickScore(this.assignmentId, this.taskId)
      .then(score => {
        console.log('quick score:', score)
        if (score >= this.taskService.getPerformanceCutoff()){
          this.numNewCheckpoints = 0;
          this.isBoosterAssignmentComplete = true;
        }
        else{
          this.bps
            .assignBoosters(this.assignmentId, this.taskId, this.classroomId)
            .then(numNewCheckpoints=>{
              this.numNewCheckpoints = numNewCheckpoints;
              this.isBoosterAssignmentComplete = true;
            })
        }
      })
  }

  proceedToDashboard(){
    this.router.navigate([`/student/dashboard`]);
  }
  

  loadTaskSubmissionStatus(uid:string, assignmentId:string, taskId:string, then:()=>any){
    // COLLECTIONS
    
    let assignmentSubmissionsCollection = this.afs.collection<IAssignmentSubmission>(COLLECTIONS.ASSIGNMENT_SUBMISSIONS.name, ref => {
      return ref
        .where('isCurrent', '==', true)
        .where('uid', '==', uid)
        .where('assignmentId', '==', assignmentId)
        .where('taskId', '==', taskId)
        .limit(10)
    });
    let assignmentSubmissions = CollectionsService.getValuesIdentified(assignmentSubmissionsCollection);
    let sub = assignmentSubmissions.subscribe(data => {
      sub.unsubscribe();
      if (data && data.length > 0){
        if (data.length > 1){ console.error('Should not be possible to have more than one current submission at once!', data); }
        let currentSubmission:IAssignmentSubmission = data[0];
        this.currentSubmissionId = currentSubmission.__id;
        this.currentAttemptNumber = currentSubmission.attemptCount;
        if (currentSubmission.isSubmitted && !this.auth.isTeacherAccount() ){
          this.isAlreadySubmitted = true;
        }
        else{
          then();
        }
      }
      else{
        // create a new submission entry to track things (superceding current must be done in the previous screen)
        this.currentAttemptNumber = 1;
        this.createNewAssignmentSubmission(uid, assignmentId, taskId, this.currentAttemptNumber, (submissionId) => {
          this.currentSubmissionId = submissionId;
          then();
        })
      }
    })
  }

  createNewAssignmentSubmission(uid:string, assignmentId:string, taskId:string, attemptNumber:number, then:(submissionId:string)=>any){
    this.afs.collection(COLLECTIONS.ASSIGNMENT_SUBMISSIONS.name).add({
      isCurrent: true,
      isSubmitted: false,
      uid, 
      assignmentId,
      taskId,
      attemptNumber,
      timeStart: serverTimestamp(),
    }).then( res => {
      then(res.id);
    })
  }
  
  recordScreenshot(payload:{scene_id:string, screenshotUrl:string, screenshotBg:string, validationHash:string}){
    let uid:string = this.auth.getUid();
    let assignmentId = this.assignmentId;
    let taskId = this.taskId;
    let screenshotUrl = payload.screenshotUrl;
    let screenshotBg = payload.screenshotBg;
    let itematicScreenId = payload.scene_id;
    let validationHash = payload.validationHash;
    let key = joinIdKey([
      uid,
      assignmentId,
      taskId, 
      itematicScreenId,
      this.currentSubmissionId
    ]);
    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_SUBMISSIONS.name)
    .doc(key)
    .set({
      lastScreenshot: screenshotUrl,
      screenshots: firestore.FieldValue.arrayUnion({
        screenshotUrl,
        screenshotBg,
        validationHash,
      })
    }, {merge:true});
  }

  recordSubmission(state:IItematicAppState){
    let uid:string = this.auth.getUid();
    let itematicScreenId = state.app_state.current_scene_id;
    let validationHash:string = state.validationHash;
    let appletState:IAppletState = {
      current_frame: state.app_state.current_frame,
      current_scene_id: state.app_state.current_scene_id,
      item_id: state.app_state.item_id,
      item_version_id: state.app_state.item_version_id,
      max_reached_frame: state.app_state.max_reached_frame,
      new_scene_id: state.app_state.new_scene_id,
      total_scenes: state.app_state.total_scenes,
    }

    this.recordTaskState(uid, this.assignmentId, this.taskId, appletState);

    let itematicScreenIds:string[] = [itematicScreenId];
    if (this.lastItematicScreenId && this.lastItematicScreenId!==itematicScreenId){
      itematicScreenIds.push(this.lastItematicScreenId);
    }

    itematicScreenIds.forEach(_itematicScreenId => {
      let screenState:IItematicSceneState = state.app_state.state_data[_itematicScreenId];
      if (screenState){
        this.recordTaskScreenSubmission(uid, this.assignmentId, this.taskId, this.currentTask.itematicLessonId, _itematicScreenId, validationHash, screenState);
      }
    })
    this.lastItematicScreenId = itematicScreenId;
  }


  recordTaskState(uid:string, assignmentId:string, taskId:string, appletState:IAppletState){
    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_STATES.name)
    .doc(joinIdKey([
      uid,
      assignmentId,
      taskId, 
      this.currentSubmissionId
    ])).set({
      uid,
      assignmentId,
      taskId,
      appletState: JSON.stringify(appletState)
    }, {merge:true});
  }



  recordTaskScreenState(uid:string, assignmentId:string, taskId:string, itematicLessonId:string, itematicScreenId:string, screenState:any){
    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_SCREEN_STATES.name).doc(joinIdKey([
      uid,
      assignmentId,
      taskId, 
      itematicScreenId,
      this.currentSubmissionId
    ])).set({
      uid,
      assignmentId,
      taskId,
      itematicLessonId,
      itematicScreenId,
      screenState: JSON.stringify(screenState)
    }, {merge:true});
  }

  recordTaskScreenSubmission(uid:string, assignmentId:string, taskId:string, itematicLessonId:string, itematicScreenId:string, validationHash:string, screenState:any){
    
    this.recordTaskScreenState(uid, assignmentId, taskId, itematicLessonId, itematicScreenId, screenState);

    let questionConfig:IQuestionConfig = screenState.___questionConfig;
    let responses = [];
    let isCorrectAggr = true;
    let isFilledAggr = true;
    let responseTextAggr:string[] = []
    if (questionConfig){
      questionConfig.forEach(question => {
        if (question.expected_responses){
          question.expected_responses.forEach(response => {
            let id:string = question.id+'/'+response.slug
            let validationNode = screenState[response.validation_node] || {};
            let outputNode = screenState[response.output_node];
            let isFilled = !!validationNode.interacted_with;
            let isCorrect = !!validationNode.validation;
            let responseText = ''; 
            if (outputNode && outputNode.state){
              responseText = outputNode.state.text;
            }
            let weight = (response.weight || 1) * question.weight;

            isCorrectAggr = isCorrectAggr && isCorrect;
            isFilledAggr = isFilledAggr && isFilled;
            responseTextAggr.push(responseText);
            responses.push({
              id,
              isFilled,
              isCorrect,
              responseText,
              weight
            })
          })
          // console.log('question res', responses, question, screenState)
        }
      })
    }
    let questionTaskId:string = this.itemScreenToQuestionTask.get(itematicScreenId);
    
    let key = joinIdKey([
      uid,
      assignmentId,
      taskId, 
      itematicScreenId,
      this.currentSubmissionId
    ]);
    let payload:IAssignmentTaskSubmission = {
      timeLastModified: serverTimestamp(),
      uid,
      assignmentId,
      taskId,
      questionTaskId,
      submissionId: this.currentSubmissionId,
      taskType: 'question_screen',
      itematicLessonId,
      itematicScreenId,
      isCorrect: isCorrectAggr,
      isStarted:true,
      isFilled: isFilledAggr,
      responseText: responseTextAggr.join('\n'),
      responses,
    }
    if (validationHash){
      payload.submissionLog = firestore.FieldValue.arrayUnion({
        timestamp: new Date(),
        responses,
        validationHash,
      })
    }
    payload.timeCreated = serverTimestamp(); // need to check for created keys at beginning and store in a map

    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_SUBMISSIONS.name)
    .doc(key)
    .set(payload, {merge:true});
  }

  loadTaskById(taskId:string, then:()=>any){
    this.taskSub = this.afs.doc<ITask>('tasks/'+taskId).get().subscribe( res =>{
      let taskData:ITask = <ITask>res.data();
      if (!taskData){
        console.error('this task no longer exists!', taskId);
        alert('Problem loading assignment task!')
        return;
      }
      if (taskData.engineOverride){
        this.currentItematicEngine = taskData.engineOverride;
      }
      else{
        this.currentItematicEngine = DEFAULT_ENGINE_VERSION;
      }
      // console.log('taskData', taskData)
      if (taskData.isUserGeneratedTask){
        this.loadProgress.task = true;
        this.currentTask = taskData;
        this.openUserGeneratedTaskSet(taskData.customTaskSetId, taskData.questions, () => {
          then();
          this.checkForInitLoaded();
        });
      }
      else if (taskData.itematicLessonId){
        this.loadProgress.task = true;
        this.currentTask = taskData;
        this.openItematicLesson(taskData.itematicLessonId);
        then();
        this.checkForInitLoaded();
      }
      else {
        alert('Problem loading assignment task content!')
      }
    })
    
  }

  loadSubTasksById(taskId:string){
    let subTasksCollection = this.afs.collection<ITask>('tasks', ref => {
      return ref
        .where('parentTask', '==', taskId)
        .limit(200)
    });
    let subTasks = CollectionsService.getValuesIdentified(subTasksCollection);
    this.subTaskSub = subTasks.subscribe(data => {
      if (!data || data.length === 0){
        console.error('No question screen data in task', data);
        return;
      }
      data.forEach((questionTask:ITask) => {
        this.itemScreenToQuestionTask.set(questionTask.itematicScreenId, questionTask.__id);
      });
      this.loadProgress.subTasks = true;
      this.checkForInitLoaded();
      // console.log('this.itemScreenToQuestionTask', this.itemScreenToQuestionTask)
    })
  }


  openUserGeneratedTaskSet(customTaskSetId:string, questionTaskIds:string[], then){
    this.tcs.loadCustomTaskSetToCache(customTaskSetId).then( () => {
      this.questionTaskIds = questionTaskIds;
      this.currentQuestionTaskIndex = 0;
      this.currentQuestionTaskId = this.questionTaskIds[this.currentQuestionTaskIndex];
      this.isUserGeneratedContentTaskSet = true;
    })
    then();
  }


  isFirstQuestion(){
    return this.currentQuestionTaskIndex === 0;
  }
  isLastQuestion(){
    return this.currentQuestionTaskIndex === this.questionTaskIds.length - 1;
  }

  nextUserGeneratedQuestion(){
    if ( !this.isLastQuestion() ){
      this.questionStateMaster = null;
      this.currentQuestionTaskIndex ++;
      this.currentQuestionTaskId = this.questionTaskIds[this.currentQuestionTaskIndex];
      this.updateQuestionState();
    }
  }

  previousUserGeneratedQuestion(){
    if (!this.isFirstQuestion()){
      this.questionStateMaster = null;
      this.currentQuestionTaskIndex --;
      this.currentQuestionTaskId = this.questionTaskIds[this.currentQuestionTaskIndex];
      this.updateQuestionState();
    }
  }

  updateQuestionState(){
    if (this.questionTaskStateCache.has(this.currentQuestionTaskId)){
      this.questionStateMaster = this.questionTaskStateCache.get(this.currentQuestionTaskId);
    }
  }
  
  getCurrentCustomTaskQuestion(){
    return this.getCustomTaskSetQuestion(this.currentQuestionTaskId);
  }

  getCustomTaskSetQuestion(questionTaskId:string){
    return this.tcs.getQuestionByTaskId(questionTaskId);
  }

  openItematicLesson(itematicLessonId:string){
    // need to replace the URL
    this.itematicIframe = this.sanitizer.bypassSecurityTrustResourceUrl(ITEMATIC_ENGINE_DOMAIN+'/itematic-engines/v'+this.currentItematicEngine+'/m0/index.html#!/player/LIVE/'+itematicLessonId);
    // this.isStateLoaded = true; // going to pretend for now...
    // console.log('this.itematicIframe', this.itematicIframe, this.isStateLoaded);
  }

  checkForInitLoaded(){
    // console.log(this.loadProgress);
    let isAllLoaded = true;
    // isAllLoaded = isAllLoaded && this.loadProgress.assignment;
    isAllLoaded = isAllLoaded && this.loadProgress.task;
    isAllLoaded = isAllLoaded && this.loadProgress.subTasks;
    isAllLoaded = isAllLoaded && this.loadProgress.taskState;
    isAllLoaded = isAllLoaded && this.loadProgress.screenStates;
    // console.log('this.loadProgress', this.loadProgress)
    this.isStateLoaded = isAllLoaded;
  }

  private appletState:any;

  loadTaskState(uid:string, assignmentId:string, taskId:string, submissionId:string){
    let sub = this.afs.collection<{appletState:string}>('assignmentTaskStates', ref => {
      return ref
        .where('uid', '==', uid)
        .where('assignmentId', '==', assignmentId)
        .where('taskId', '==', taskId)
        .limit(1)
    }).valueChanges().subscribe( res => {
      // console.log('loadTaskState', res);
      if (res && res.length > 0){
        this.appletState = JSON.parse(res[0].appletState);
      }
      else{
        this.appletState = {};
      }
      this.loadTaskSubStates(uid, assignmentId, taskId);
      this.loadProgress.taskState = true;
      this.checkForInitLoaded();
      sub.unsubscribe();
    })
  }

  loadTaskSubStates(uid:string, assignmentId:string, taskId:string){
    let sub = this.afs.collection<{itematicScreenId:string, screenState:string}>('assignmentTaskScreenStates', ref => {
      return ref
        .where('uid', '==', uid)
        .where('assignmentId', '==', assignmentId)
        .where('taskId', '==', taskId)
        .limit(200)
    }).valueChanges().subscribe( res => {
      console.log('loadTaskScreenState', res);
      if (res){
        this.appletState.state_data = {};
        res.forEach(screen => {
          if (screen.itematicScreenId){
            this.appletState.state_data[screen.itematicScreenId] = JSON.parse(screen.screenState);
          }
        })
        this.loadProgress.screenStates = true;
        this.checkForInitLoaded();
      }
      sub.unsubscribe();
    })
  }

  messageIntoLesson(type:string, payload:any){
    let el = <HTMLIFrameElement>document.getElementById('lesson-container');
    el.contentWindow.postMessage(JSON.stringify({
      type,
      payload
    }), '*');
  }


}
