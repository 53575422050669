export enum FormMode {
    NONE = 'NONE',
    VIEW = 'VIEW',
    NEW = 'NEW',
    EDIT = 'EDIT',
}

export enum ListMode {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    CURRENT = 'CURRENT',
    PAST = 'PAST',
}

export interface IListMode {
    slug:ListMode,
    caption:string, 
    isActive?:boolean;
}