import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'admin-assessment-question-processing',
  templateUrl: './admin-assessment-question-processing.component.html',
  styleUrls: ['./admin-assessment-question-processing.component.scss']
})
export class AdminAssessmentQuestionProcessingComponent implements OnInit {

  questionTaskId = new FormControl(null);

  constructor(
    private afs: AngularFirestore
  ) { }

  ngOnInit() {
  }

}
