import { Component, OnInit, Input } from '@angular/core';
import { IQuestionConfig, IElementTypeDef, elementTypes, elementIconById, ElementType, McqDisplay, IContentElementMcq, IContentElement, TextParagraphStyle, IContentElementText, IContentElementImage, IContentElementMath, createDefaultElement, ICustomTaskSet } from '../models/index';
import { indexOf } from '../services/util';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ITask, generateDefaultTask, generateDefaultQuestionTask } from '../../data/collections/tasks.types';
import { TaskCacheService } from '../task-cache.service';

@Component({
  selector: 'assessment-runner-standalone',
  templateUrl: './assessment-runner-standalone.component.html',
  styleUrls: ['./assessment-runner-standalone.component.scss']
})
export class AssessmentRunnerStandaloneComponent implements OnInit {

  @Input() customTaskSetId:string;

  isLoading:boolean = true;
  questions:IQuestionConfig[] = [];
  currentQuestion:IQuestionConfig;

  constructor(
    private tcs: TaskCacheService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loadCustomTaskSet();
  }

  loadCustomTaskSet(){
    this.tcs.loadCustomTaskSetToCache(this.customTaskSetId).then( () => {
      this.questions = this.tcs.getTaskSetQuestions(this.customTaskSetId);
    });
  }

}
