
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { serverTimestamp } from '../../data/timestamp';
import { FormControl } from '@angular/forms';
import { IClassroom } from '../../data/collections/classrooms.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';

interface IClassroomSlot {
  id: string, 
  name: string,
}


@Component({
  selector: 'student-classrooms',
  templateUrl: './student-classrooms.component.html',
  styleUrls: ['./student-classrooms.component.scss']
})
export class StudentClassroomsComponent implements OnInit, OnDestroy {

  classroomSlots:IClassroomSlot[];
  classroomNameLoaders:Map<string, Subscription> = new Map();
  newClassId = new FormControl(null);

  private signinSub:Subscription;

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  
  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.signinSub = this.auth.user.subscribe(user => {
      console.log('student', user.classroomsAsStudent)
      this.loadClassrooms(user.classroomsAsStudent);
    })
  }

  ngOnDestroy() {
    if (this.signinSub){ this.signinSub.unsubscribe(); }
    if (this.classroomNameLoaders){
      this.classroomNameLoaders.forEach(loader => {
        loader.unsubscribe();
      })
    }
  }

  logout(){
    this.auth.signOut();
  }

  loadClassrooms(classroomList:string[]){
    this.classroomSlots = [];
    if (classroomList){
      classroomList.forEach(classroomId => {
        let classroomSlot = {
          id: classroomId,
          name: ''
        };
        this.classroomSlots.push(classroomSlot);
        this.loadClassroomName(classroomSlot);
      })
    }
    else{
      console.warn('You are not a teacher in any classes!')
    }
  }

  loadClassroomName(classroomSlot:IClassroomSlot){
    let sub = this.afs.doc<IClassroom>('classrooms/'+classroomSlot.id).valueChanges().subscribe(val => {
      classroomSlot.name = val.name;
    })
    this.classroomNameLoaders.set(classroomSlot.id, sub);
  }

  getUserDisplayName(){
    return this.auth.getDisplayName()
  }

  // joinNewClassroom(){
  //   let userId = this.auth.getUid();
  //   this.afs.collection('classrooms/').add({
  //     timeCreated: serverTimestamp(),
  //     timeLastTouched: serverTimestamp(),
  //     name: this.newClassName.value,
  //     currentTeachers: [userId]
  //   }).then( val => {
  //     let classroomId = val.id;
  //     this.afs.doc('users/'+userId).update({
  //       classroomsAsTeacher: firebase.firestore.FieldValue.arrayUnion(classroomId)
  //     })
  //   })
  // }

}
