import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AssignmentDetailEditorComponent } from '../../ui-teacher/assignment-detail-editor/assignment-detail-editor.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';

@Component({
  selector: 'student-headsup-lobby-leaderboard',
  templateUrl: './student-headsup-lobby-leaderboard.component.html',
  styleUrls: ['./student-headsup-lobby-leaderboard.component.scss']
})
export class StudentHeadsupLobbyLeaderboardComponent implements OnInit, OnDestroy {

  @Input() assignmentId:string;

  joinedPlayers:any[] = [];

  private leaderboardCacheSub:Subscription;

  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.afs.doc('headsUpLobbyLeaderboardCache/'+this.assignmentId)
      .valueChanges()
      .subscribe((value:any) => {
        if (value && value.playersJson){
          this.joinedPlayers = JSON.parse(value.playersJson);
        }
      });
  }

  ngOnDestroy() {
    if (this.leaderboardCacheSub){ this.leaderboardCacheSub.unsubscribe(); }
  }
  
}
