import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ReplaySubject } from 'rxjs';
import { AuthService } from '../core/auth.service';
import { serverTimestamp } from '../data/timestamp';
import { IBoosterPackItem, IBoosterPack } from '../data/cambridge/types';
import { ITask } from '../data/collections/tasks.types';
import { BoosterService, ItemTypes } from '../core/booster.service';
import { IItemCheckpoint } from '../data/collections/booster-packs-item-checkpoints';
import { ITaskBoosterMapping } from '../ui-taskcreator/booster-assign/booster-assign.component';
import { clearSubjectMap } from '../core/util/clear-subject-map';
import { FirebaseFirestore } from '@angular/fire';

export {ItemTypes}

const DEFAULT_CURRICULUM_ID = 'cambridge-stage6';

export interface IItemScore {
  score: number,
  numStars: number,
  maxStars: number,
  hasMedal?: boolean,
}

export interface IItemPerformance {
  __id?:string,
  boosterPackId: string,
  leaderboardId: string,
  boosterPackItemId: string,
  uid: string,
  lastSessionId?: string,
  score: number,
  isComplete: boolean,
}
export interface IBoosterPackItemLastTouch {
  uid: string, 
  boosterPackId: string,
  lastTouchedItemId: string,
  timestamp: any,
}

export interface IItemBundleInfo {
  item: IBoosterPackItem,
  performance: IItemPerformance,
  task: ITask,
  sessionId:string,
  itemOptions: IBoosterPackItemOptions,
}

export interface IBoosterPackItemOptions {
  boosterPackId: string;
  uid: string;
  itemOptions: {[key: string]: any};
}

export interface IInnerSubmission {
  scene:string, 
  variant:string, 
  isCorrect:boolean, 
  screenshotUrl:string,
}
export interface IItemAttemptSession {
  __id?:string,
  uid: string,
  boosterPackItemId: string,
  boosterPackId: string,
  leaderboardId: string,
  classroomIds?: string[],
  timeStart: any,
  timeEnd?: any,
  score?: number,
  isComplete?: boolean,
  innerSubmissions?: IInnerSubmission[],
  resolvedCheckpoints?: string[],
  trialId?: string,
  instScopeIds?: string[],
}

export interface IBoosterPackPerfSummary {
  __id?: string,
  uid: string,
  boosterPackId:string,
  leaderboardId: string,
  numStars: number,
  totalStars: number,
}

@Injectable({
  providedIn: 'root'
})
export class BoosterpacksService {

  boosterPacks:Map<string, ReplaySubject<any>> = new Map();
  boosterPackPerformances:Map<string, ReplaySubject<IBoosterPackPerfSummary>> = new Map();
  boosterPacksItems:Map<string, ReplaySubject<any>> = new Map();
  boosterPacksItemPerformances:Map<string, ReplaySubject<any>> = new Map();

  boosterPackCheckpoints:Map<string, ReplaySubject<IItemCheckpoint[]>> = new Map();
  boosterPackItemCheckpoint:Map<string, ReplaySubject<IItemCheckpoint>> = new Map();
  
  isAnyCheckpointsOutstandingSub:ReplaySubject<boolean> = new ReplaySubject(1);
  isAnyCheckpointsOutstandingInited:boolean;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private boosterService: BoosterService,
  ) { 
    this.auth.newAuthSub.subscribe(()=>this.clearListeners())
  }

  clearListeners(){
    clearSubjectMap(this.boosterPacks);
    clearSubjectMap(this.boosterPackPerformances);
    clearSubjectMap(this.boosterPacksItems);
    clearSubjectMap(this.boosterPacksItemPerformances);
    clearSubjectMap(this.boosterPackCheckpoints);
    clearSubjectMap(this.boosterPackItemCheckpoint);
  }


  processItemScore(itemType:string, score: number){
    return this.boosterService.processItemScore(itemType, score); 
  }

  private lastCompletedItem:string;
  setLastCompletedItem(boosterPackItemId:string){
    this.lastCompletedItem = boosterPackItemId;
  }
  getLastCompletedItem(){
    return this.lastCompletedItem;
  }
  clearLastCompletedItem(){
    this.lastCompletedItem = null;
  }

  loadItemCompletions(boosterPackId:string, leaderboardId: string = 'none') {
    const uid = this.auth.getUid();
    return this.afs
      .collection<IItemPerformance>('boosterPacksItemPerformances/', ref => ref
      .where('uid', '==', uid)
      .where('boosterPackId', '==', boosterPackId)
      .where('leaderboardId', '==', leaderboardId)
      .limit(300) // arbitrary)
    )
    .get()
    .toPromise()
    .then(snap => {
      return <IItemPerformance[]>snap.docs.map(doc => {
        return <any>{
            ... doc.data(),
          __id: doc.id,
        }
      });
    });
  }

  trackItemOpenResponse(sessionId:string, boosterPackId: string, boosterPackItemId:string, val:string) {
    const uid = this.auth.getUid();
    return this.afs
      .collection('boosterPacksItemOpenResponse')
      .add({
        uid,
        sessionId, 
        boosterPackId, 
        boosterPackItemId, 
        val,
        timestamp: serverTimestamp(),
      })
  }


  assignBoosters(assignmentId:string, taskId:string, classroomId:string, leaderboardId: string = 'none'){
    // const normalizedScore = Math.round((score||0)*100)
    const uid = this.auth.getUid();
    let boosterMappings:ITaskBoosterMapping[] = [];
    const boosterItemMastered = new Map();
    const boosterItemCheckpointsPre = new Map();
    const newBoosterItemCheckpoints:any[] = [];
    // pull booster assignments connected to this task
    return this.afs
      .collection('taskBoosterMappings', ref=>ref.where('taskId', '==', taskId))
      .get()
      .toPromise()
      .then(results => {
        boosterMappings = results.docs.map(doc => {
          return <ITaskBoosterMapping>{
            ... doc.data(),
            __id: doc.id
          }
        })
        return true;
      })
      // check for items that have already been perfectly completed by students
      .then(success=>{
        // console.log('boosterMappings', boosterMappings);
        return Promise.all(
          boosterMappings.map(boosterMapping =>{
            return this.afs
              .collection('boosterPacksItemPerformances', ref=>ref
                .where('boosterPackItemId', '==', boosterMapping.boosterPackItemId)
                .where('leaderboardId', '==', leaderboardId)
                .where('uid', '==', uid)
                .where('isComplete', '==', true)
              )
              .get()
              .toPromise()
              .then(results => {
                results.docs.forEach(doc => {
                  let performance:IItemPerformance = <IItemPerformance>doc.data();
                  if (performance.score === 1){
                    boosterItemMastered.set(performance.boosterPackItemId, true);
                  }
                })
              });
          })
        )
      })
      // check for existing booster checkpoints before re-assigning
      .then(()=>{
        return Promise.all(
          boosterMappings.map(boosterMapping =>{
            return this.afs
              .collection('boosterPacksItemCheckpoints', ref=>ref
                .where('boosterPackItemId', '==', boosterMapping.boosterPackItemId)
                .where('uid', '==', uid)
              )
              .get()
              .toPromise()
              .then(results => {
                results.docs.forEach(doc => {
                  let checkpoint:IItemCheckpoint = <IItemCheckpoint>doc.data();
                  boosterItemCheckpointsPre.set(checkpoint.boosterPackItemId, true);
                })
              });
          })
        )
      })
      .then(()=>{
        return Promise.all(
          boosterMappings.map(boosterMapping => {
            const hasPerfCompletion = boosterItemMastered.get(boosterMapping.boosterPackItemId);
            const hasCheckpoint  = boosterItemCheckpointsPre.get(boosterMapping.boosterPackItemId);
            if( !hasCheckpoint && !hasPerfCompletion ){
              const newBoosterItemCheckpoint:IItemCheckpoint = {
                uid,
                boosterPackItemId:boosterMapping.boosterPackItemId,
                boosterPackId:boosterMapping.boosterPackId,
                cause_classroomId: classroomId,
                cause_taskId: taskId,
                cause_assignmentId: assignmentId,
                trialId: this.auth.getUserTrialId(),
                instScopeIds: this.auth.getUserInstScope(),
                // cause_assessmentSession: this.sess,
                isComplete: false,
                timeCreated: serverTimestamp(),
              }
              newBoosterItemCheckpoints.push(newBoosterItemCheckpoint);
              return this.afs
                .collection('boosterPacksItemCheckpoints')
                .add(newBoosterItemCheckpoint)
            }
          })
        )
      })
      .then(()=>{
        // console.log('mapped everything');
        return newBoosterItemCheckpoints.length;
      })
  }

  
  isAnyCheckpointsOutstanding(){
    const subject = this.isAnyCheckpointsOutstandingSub;
    const uid = this.auth.getUid();
    if (!this.isAnyCheckpointsOutstandingInited){
      this.isAnyCheckpointsOutstandingInited = true;
      this.afs
        .collection('boosterPacksItemCheckpoints', ref=>ref
          .where('uid', '==', uid)
          .where('isComplete', '==', false)
          .limit(2)
        )
        .snapshotChanges()
        .subscribe((res) =>{
          const isOutstanding = (res && (res.length > 0));
          this.isAnyCheckpointsOutstandingSub.next(isOutstanding);
        })
    }
    return subject;
  }
  
  getBoosterPackCheckpoints(boosterPackId:string){
    if (this.boosterPackCheckpoints.has(boosterPackId)){
      return this.boosterPackCheckpoints.get(boosterPackId);
    }
    const subject:ReplaySubject<IItemCheckpoint[]> = new ReplaySubject(1);
    this.boosterPackCheckpoints.set(boosterPackId, subject);
    const uid = this.auth.getUid();
    this.afs
      .collection('boosterPacksItemCheckpoints', ref=>ref
        .where('boosterPackId', '==', boosterPackId)
        .where('uid', '==', uid)
        .where('isComplete', '==', false)
      )
      .snapshotChanges()
      .subscribe((res) =>{
        // console.log('getBoosterPackCheckpoints', boosterPackId, uid,  res)
        subject.next(res.map(entry => {
          const doc = entry.payload.doc;
          return {
            __id: doc.id, 
            ... <IItemCheckpoint>doc.data()
          }
        }))
      })
    return subject;
  }

  trackItemCompletion(sessionId:string, boosterPackId: string, boosterPackItemId:string, score:number, innerSubmissions?:IInnerSubmission[], leaderboardId: string = 'none') {
    const uid = this.auth.getUid();
    return this.clearPendingCheckpoints(score, boosterPackItemId, sessionId)
      .then((resolvedCheckpoints)=>{
        // console.log('resolvedCheckpoints', resolvedCheckpoints);
        return this.closeItemAttemptSession(
          sessionId,
          true,
          score,
          innerSubmissions,
          <any>resolvedCheckpoints
        )
      })
      .then(()=>{
        return this.afs
          .collection<IItemPerformance>('boosterPacksItemPerformances/', ref => ref
            .where('uid', '==', uid)
            .where('boosterPackItemId', '==', boosterPackItemId)
            .where('leaderboardId', '==', leaderboardId)
          )
          .get()
          .toPromise()
      })
      .then( snapCollection => {
        // fetch current performance from cached `boosterPacksItemPerformances`
        if (snapCollection.docs.length > 0){
          const perfDocId = snapCollection.docs[0].id;
          const previousPerf:IItemPerformance = <any>snapCollection.docs[0].data()
          return this.afs
            .doc<IItemPerformance>('boosterPacksItemPerformances/'+perfDocId)
            .update({ 
              score: Math.max(previousPerf.score, score), 
              isComplete:true, 
              lastSessionId: sessionId,
            })
            .then( ()=> true);
        }
        else{
          return this.afs
            .collection<IItemPerformance>('boosterPacksItemPerformances/')
            .add({
              uid, 
              boosterPackId,
              boosterPackItemId,
              score,
              isComplete: true,
              lastSessionId: sessionId,
              leaderboardId,
            })
            .then( ()=> true);
        }
      })
  }

  private renderPackLastTouchId(boosterPackId:string){
    return [
      this.auth.getUid(),
      boosterPackId
    ].join(':');
  }

  setPackLastTouch(boosterPackId:string, lastTouchedItemId:string){
    return this.afs
      .doc<IBoosterPackItemLastTouch>('boosterPackItemLastTouch/'+this.renderPackLastTouchId(boosterPackId))
      .set({
        uid: this.auth.getUid(),
        boosterPackId,
        lastTouchedItemId,
        timestamp: serverTimestamp(),
      })
  }
  getPackLastTouch(boosterPackId:string){
    return this.afs
      .doc<IBoosterPackItemLastTouch>('boosterPackItemLastTouch/'+this.renderPackLastTouchId(boosterPackId))
      .get()
      .toPromise()
      .then((snap)=>{
        let doc:IBoosterPackItemLastTouch = <IBoosterPackItemLastTouch>snap.data();
        if (!doc){
          return null;
        }
        return doc.lastTouchedItemId;
      })
  }

  getBoosterPackItemOptions(boosterPackId: string) {
    const uid = this.auth.getUid();
    let itemOptions: IBoosterPackItemOptions;

    return this.afs.collection<IBoosterPackItemOptions>('boosterPackItemOptions/', ref => ref
    .where('uid', '==', uid)
    .where('boosterPackId', '==', boosterPackId)
    .limit(1)
    )
    .get()
    .toPromise()
    .then( snapCollection => {
      if (snapCollection.docs.length > 0){
        const optionDoc = snapCollection.docs[0];
        itemOptions = optionDoc.data() as IBoosterPackItemOptions;
      }
    }).then ( () => itemOptions );
  }

  getBoosterPackItemTask(boosterPackItemId:string, boosterPackId: string, leaderboardId: string = 'none'){
    const res:IItemBundleInfo = {
      item: null,
      performance: null,
      task: null,
      sessionId: null,
      itemOptions: null,
    }
    // get the item info (booster pack specific)
    return this.afs
      .doc('boosterPackItems/'+boosterPackItemId)
      .get()
      .toPromise()
      .then(snap=>{
        res.item = <any>snap.data();
        return res.item.taskId;
      })
      .then(()=>{
        // get the task data (not tied to the booster pack, tied to Itematic in general)
        return this.afs
          .doc('tasks/'+res.item.taskId)
          .get()
          .toPromise()
          .then(snap=>{
            res.task = {
              ... snap.data(),
              __id: snap.id,
            }
          })
      })
      .then(()=>{
        // get the performance data
        const uid = this.auth.getUid();
        return this.afs
          .collection<IItemPerformance>('boosterPacksItemPerformances/', ref => ref
            .where('uid', '==', uid)
            .where('boosterPackItemId', '==', boosterPackItemId)
            .where('leaderboardId', '==', leaderboardId)
            .limit(1)
          )
          .get()
          .toPromise()
          .then( snapCollection => {
            // fetch current performance from cached `boosterPacksItemPerformances`
            if (snapCollection.docs.length > 0){
              const perfDoc = snapCollection.docs[0];
              res.performance = {
                ... <any>perfDoc.data(),
                __id: perfDoc.id
              }
            }
          })
      })
      .then(()=>{
        const uid = this.auth.getUid();
        return this.afs.collection<IBoosterPackItemOptions>('boosterPackItemOptions/', ref => ref
        .where('uid', '==', uid)
        .where('boosterPackId', '==', boosterPackId)
        .limit(1)
        )
        .get()
        .toPromise()
        .then( snapCollection => {
          if (snapCollection.docs.length > 0){
            const optionDoc = snapCollection.docs[0];
            res.itemOptions = optionDoc.data() as IBoosterPackItemOptions;
          }
        })
      })
      .then(()=>{
        return this.startItemAttemptSession(boosterPackItemId, res.item.boosterPackId, leaderboardId)
          .then(sessionId => {
            res.sessionId = sessionId;
          })
      })
      .then( ()=> res )
  }

  updateBoosterPackItemOptions(boosterPackId: string, itemOptions: any) {
    const uid = this.auth.getUid();

    this.afs.collection<IBoosterPackItemOptions>('boosterPackItemOptions/', ref => ref
      .where('uid', '==', uid)
      .where('boosterPackId', '==', boosterPackId)
      .limit(1)
    )
    .get()
    .toPromise()
    .then( snapCollection => {
      let optionId: string;

      let object: IBoosterPackItemOptions = {
        uid,
        boosterPackId,
        itemOptions
      };

      if (snapCollection.docs.length > 0){
        optionId = snapCollection.docs[0].id;
        this.afs.doc('boosterPackItemOptions/' + optionId).set(object);
      } else {
        this.afs.collection<IBoosterPackItemOptions>('boosterPackItemOptions/').add(object);
      }
    });
  }

  startItemAttemptSession(boosterPackItemId:string, boosterPackId:string, leaderboardId: string){
    const uid = this.auth.getUid();
    return this.afs
      .collection<IItemAttemptSession>('boosterPacksItemAttemptSession/')
      .add({
        uid,
        boosterPackItemId,
        boosterPackId,
        leaderboardId,
        classroomIds: this.auth.getStudentClassrooms(),
        trialId: this.auth.getUserTrialId(),
        instScopeIds: this.auth.getUserInstScope(),
        timeStart: serverTimestamp(),
      })
      .then(res =>{
        return res.id;
      })
  }

  pingAttemptSession(sessionId:string){
    return this.afs
      .doc<IItemAttemptSession>('boosterPacksItemAttemptSession/'+sessionId)
      .update({
        isComplete: false,
        timeEnd: serverTimestamp(),
      })
  }

  closeItemAttemptSession(sessionId:string, isComplete:boolean, score:number, innerSubmissions?:IInnerSubmission[], resolvedCheckpoints:string[]=[]){
    const attemptCloseData:Partial<IItemAttemptSession> = {
      timeEnd: serverTimestamp(),
      isComplete,
      score,
    }
    if (innerSubmissions){
      attemptCloseData.innerSubmissions = innerSubmissions
    }
    if (resolvedCheckpoints){
      attemptCloseData.resolvedCheckpoints = resolvedCheckpoints
    }
    return this.afs
      .doc<IItemAttemptSession>('boosterPacksItemAttemptSession/'+sessionId)
      .update(attemptCloseData)
  }

  clearPendingCheckpoints(score, boosterPackItemId:string, sessionId:string){
    const uid = this.auth.getUid();
    const resolvedCheckpoints:string[] = [];
    if (score !== 1){
      // TO DO: this is overkill, just needed a valid promise
      return this.afs
        .collection<IItemCheckpoint>('boosterPacksItemCheckpoints', ref=>ref.where('boosterPackItemId', '==', 'boosterPackItemId'))
        .get()
        .toPromise()
        .then(()=>{
          return resolvedCheckpoints;
        })
    }
    return this.afs
      .collection<IItemCheckpoint>('boosterPacksItemCheckpoints', ref=>ref
        .where('boosterPackItemId', '==', boosterPackItemId)
        .where('uid', '==', uid)
        .where('isComplete', '==', false)
      )
      .get()
      .toPromise()
      .then(res => {
        return Promise.all(res.docs.map(doc => {
          console.log('resolvedCheckpoints :: ', doc.id)
          resolvedCheckpoints.push(doc.id);
          return this.afs
            .doc<IItemCheckpoint>('boosterPacksItemCheckpoints/'+doc.id)
            .update({
              isComplete: true,
              timeCompleted: serverTimestamp(),
              resolution_sessionId: sessionId
            })
        }))
        .then(()=>{
          console.log('resolvedCheckpoints -- promises are done')
        })
      })
      .then(()=>{
        console.log('resolvedCheckpoints ?? early')
        return resolvedCheckpoints;
      })
  }

  getBoosterPackItems(boosterPackId:string) {
    if (this.boosterPacksItems.has(boosterPackId)){
      return this.boosterPacksItems.get(boosterPackId);
    }
    const subject = new ReplaySubject(1);
    this.boosterPacksItems.set(boosterPackId, subject);
    this.afs
      .collection('boosterPackItems', 
        ref => ref
        .where('boosterPackId', '==', boosterPackId)
      )
      .get()
      .toPromise()
      .then(snap=>{
        const packItems = [];
        snap.docs.forEach(doc => {
          packItems.push({
            ... doc.data(),
            __id: doc.id,
          });
        });
        subject.next(packItems);
      });
    return subject;
  }

  updateItemPosition(boosterPackItemId:string, data:IBoosterPackItem){
    return this.afs
      .doc('boosterPackItems/'+boosterPackItemId)
      .update({
        name: data.name,
        itemType: data.itemType,
        unlockedBy: data.unlockedBy || [],
        x: data.x,
        y: data.y,
      }) 
  }

  updateBoosterPackPerfSummary(boosterPackId:string, numStars:number, totalStars:number, leaderboardId: string = 'none'){
    let hasRun:boolean = false;
    const sub = this.getBoosterPackPerfSummary(boosterPackId, undefined, leaderboardId)
      .subscribe( perfSummary => {
        if (hasRun){ sub.unsubscribe(); return; }
        hasRun = true;
        if (perfSummary.numStars < numStars){
          this.afs 
            .doc<IBoosterPackPerfSummary>('boosterPacksPerformanceSummary/'+perfSummary.__id)
            .update({ numStars, totalStars })
        }
      })
  }

  getBoosterPackPerfSummaries(leaderboardId: string = 'none'){
    const uid = this.auth.getUid();
    return this.afs 
      .collection<IBoosterPackPerfSummary>('boosterPacksPerformanceSummary/', ref=>ref
        .where('uid', '==', uid)
        .where('leaderboardId', '==', leaderboardId)
      )
      .get()
      .toPromise()
      .then(results=>{
        const response:IBoosterPackPerfSummary[] = [];
        if (results.docs.length > 0){
          results.forEach(doc=>{
            const boosterPackPerfSummary:IBoosterPackPerfSummary = {
              ... <IBoosterPackPerfSummary>doc.data(),
              __id: doc.id,
            }
            response.push(boosterPackPerfSummary);
            this.getBoosterPackPerfSummary(boosterPackPerfSummary.boosterPackId, boosterPackPerfSummary, leaderboardId)
          })
        }
        return response;
      });
  }

  getBoosterPackPerfSummary(boosterPackId:string, data?:IBoosterPackPerfSummary, leaderboardId: string = 'none'){
    let mapRoute = boosterPackId + '/leaderboard/' + leaderboardId;
    if (this.boosterPackPerformances.has(mapRoute)){
      return this.boosterPackPerformances.get(mapRoute);
    }
    const subject:ReplaySubject<IBoosterPackPerfSummary> = new ReplaySubject(1);
    if (data){
      subject.next(data);
      return;
    }
    const uid = this.auth.getUid();
    this.boosterPackPerformances.set(mapRoute, subject);
    this.afs 
      .collection<IBoosterPackPerfSummary>('boosterPacksPerformanceSummary/', ref=>ref
        .where('uid', '==', uid)
        .where('boosterPackId', '==', boosterPackId)
        .where('leaderboardId', '==', leaderboardId)
        .limit(1)
      )
      .get()
      .toPromise()
      .then(results=>{
        if (results.docs.length > 0){
          const doc = results.docs[0];
          subject.next(<IBoosterPackPerfSummary>{
            ... doc.data(),
            __id: doc.id
          });
        }
        else {
          const newDoc:IBoosterPackPerfSummary = {
            uid, 
            boosterPackId,
            leaderboardId,
            numStars: 0,
            totalStars: -1,
          };
          this.afs 
            .collection<IBoosterPackPerfSummary>('boosterPacksPerformanceSummary/')
            .add(newDoc)
            .then( snap =>{
              newDoc.__id = snap.id;
              subject.next(newDoc);
            })
        }
        
      });
    return subject;
  }

  getBoosterPackSummary(boosterPackId:string){
    return this.ensureBoosterPackSubject(boosterPackId);
  }

  private ensureBoosterPackSubject(boosterPackId:string, data?:any){
    if (this.boosterPacks.has(boosterPackId)){
      return this.boosterPacks.get(boosterPackId);
    }
    const subject = new ReplaySubject(1);
    this.boosterPacks.set(boosterPackId, subject);
    if (data){
      subject.next(data);
    }
    else{
      this.afs 
        .doc('boosterPacks/'+boosterPackId)
        .get()
        .toPromise()
        .then(snap=>{
          subject.next(this.constructBoosterPackEntry(
            snap.id,
            snap.data(),
          ));
        });
    }
    return subject;
  }

  private constructBoosterPackEntry(__id:string, data:any){
    return {
      ... data, 
      __id
    }
  }

  

  getBoosterPacks(includeHidden?: boolean){
    return this.afs
      .collection('boosterPacks', ref=>ref.where('curriculumId','==', DEFAULT_CURRICULUM_ID) )
      .get()
      .toPromise()
      .then(snap=>{
        const packSummaries = [];
        snap.docs.forEach(doc => {
          const boosterPackId = doc.id;
          const data = doc.data() as IBoosterPack;
          if (!data.isHidden || includeHidden) {
            const entry = this.constructBoosterPackEntry(
              boosterPackId,
              doc.data(),
            );
            this.ensureBoosterPackSubject(boosterPackId, entry);
            packSummaries.push(entry);
          }
        });
        return packSummaries;
      })
  }

  getBoosterPackById(boosterPackId: string) {
    return this.afs
      .doc('boosterPacks/' + boosterPackId)
      .get()
      .toPromise()
      .then(snap => {
        return <IBoosterPack>{
          ... snap.data(),
          __id: boosterPackId
        }
      });
  }
}
