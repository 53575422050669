import * as _ from 'lodash';
import * as PIXI from 'pixi.js';
import { RainbowShift } from '../ColorUtilities';

interface IConfettiOptions {
  container?: PIXI.Container;
  x?: number;
  y?: number;
  gravity?: number;
  fade?: number;
  startVX?: number;
  startVY?: number;
  startOX?: number;
  startOY?: number;
  frictionX?: number;
  numParts?: number;
  size?: number;
  alpha?: number;
  rotation?: number;
  maxY?: number;
}

export class PIXIConfetti {
  static TEXTURES: PIXI.Texture[] = [];
  static particles: Array<PIXIConfettiParticle> = [];
  static initialized: Boolean = false;
  static rainbow: RainbowShift;

  defaultOptions: IConfettiOptions = {
    x: 0,
    y: 0,
    gravity: 0.05,
    fade: 0.002,
    startVX: 4,
    startVY: 2,
    startOX: 0,
    startOY: -2.2,
    frictionX: 0.999,
    numParts: 50,
    size: 10,
    alpha: 1,
    rotation: 0.5,
  };

  static initialize = (app: PIXI.Application) => {
    if (!PIXIConfetti.initialized) {
      PIXIConfetti.rainbow = new RainbowShift([0, 97, 79]);
      PIXI.Ticker.shared.add(PIXIConfetti.onTick);
    }
    let graphic: PIXI.Graphics = new PIXI.Graphics;
    graphic.beginFill(0xffffff);
    graphic.drawCircle(0, 0, 6);

    PIXIConfetti.TEXTURES[0] = app.renderer.generateTexture(graphic, 1, 1);
    graphic.clear();
    graphic.beginFill(0xffffff);
    graphic.drawRect(-3, -3, 6, 6);
    PIXIConfetti.TEXTURES[1] = app.renderer.generateTexture(graphic, 1, 1);
    graphic.clear();
    graphic.beginFill(0xffffff);
    graphic.drawPolygon([0, -3, 3, 3, -3, 3, 0, -3]);
    PIXIConfetti.TEXTURES[2] = app.renderer.generateTexture(graphic, 1, 1);

    PIXIConfetti.initialized = true;
  }

  static onTick = () => {
    for (let i = 0; i < PIXIConfetti.particles.length; i++) {
      let particle = PIXIConfetti.particles[i];
      particle.update();
      if (particle.alpha < 0.1) {
        particle.destroy();
        PIXIConfetti.particles.splice(i, 1);
        i--;
      }
      if (particle.options.maxY && particle.y > particle.options.maxY) {
        particle.destroy();
        PIXIConfetti.particles.splice(i, 1);
        i--;
      }
    }
  }

  constructor(options: IConfettiOptions = null) {
    if (!PIXIConfetti.initialized) {
      console.error('must initialize the static class before using');
      return;
    }

    options = _.defaults(options, this.defaultOptions);

    for (let i = 0; i < options.numParts; i += 1) {
      let particle: PIXIConfettiParticle = new PIXIConfettiParticle(options);
      PIXIConfetti.particles.push(particle);
      options.container.addChild(particle);
    }
  }
}

class PIXIConfettiParticle extends PIXI.Sprite {
  vX: number;
  vY: number;
  vR: number;

  constructor(public options: IConfettiOptions) {
    super(PIXIConfetti.TEXTURES[Math.floor(Math.random() * PIXIConfetti.TEXTURES.length)]);
    // super(PIXIConfetti.TEXTURES[Math.floor(Math.random()*PIXIConfetti.TEXTURES.length)]);
    this.pivot.set(0.25 + Math.random() * 0.5);
    this.x = options.x;
    this.y = options.y;
    this.vX = options.startOX + options.startVX * (Math.random() - 0.5);
    this.vY = options.startOY + options.startVY * (Math.random() - 0.5);
    this.vR = options.rotation * (Math.random() - 0.5);
    this.alpha = options.alpha;
    this.width = options.size;
    this.height = options.size;
    this.tint = PIXIConfetti.rainbow.getRandomColor();
    this.rotation = Math.random() * Math.PI;
  }

  update = () => {
    this.x += this.vX;
    this.y += this.vY;

    this.vX *= this.options.frictionX;
    this.vY += this.options.gravity;
    this.rotation += this.vR;

    this.alpha -= this.options.fade;
  }
}

