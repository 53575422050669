import * as PIXI from 'pixi.js';
import * as _2 from '../_2';

import { ITiledTileset } from './TempMapData';


export class TiledSpritesheet {
  static cache: {[key: string]: PIXI.Texture} = {};
  sheet: PIXI.BaseTexture;
  tileSize: number;

  ready: boolean;
  private _onLoad: () => void;

  constructor (private data: ITiledTileset) {
    this.sheet = PIXI.BaseTexture.from(this.data.image);
    this.tileSize = this.data.tilewidth - 1;
    if (this.sheet.valid) {
      this.ready = true;
    } else {
      this.sheet.on('loaded', () => {
        this.ready = true;
        if (this._onLoad) {
          this._onLoad();
        }
      });
    }
  }

  getTileTexture = (index: number): PIXI.Texture => {
    index--;
    index = _2.getTileid(index);

    if (index < 0) {
      return null;
    }

    if (TiledSpritesheet.cache[index]) {
      return TiledSpritesheet.cache[index];
    } else {
      let tileX = index % this.data.columns;
      let tileY = Math.floor(index / this.data.columns);
      let bounds = new PIXI.Rectangle((this.data.tilewidth + this.data.spacing) * tileX, (this.data.tileheight + this.data.spacing) * tileY, this.data.tilewidth, this.data.tileheight);
      let texture = new PIXI.Texture(this.sheet, bounds);
      TiledSpritesheet.cache[index] = texture;
      return texture;
    }
  }

  getPositionedTile = (index: number, x: number, y: number): PIXI.Sprite => {
    let texture = this.getTileTexture(index);
    if (texture) {
      index--;
      let transform = _2.getTileTransformation(index);

      let sprite = new PIXI.Sprite(texture);
      sprite.rotation = transform[0];
      sprite.position.set(this.tileSize * (x + transform[1]), this.tileSize * (y + transform[2]));
      sprite.scale.set(transform[3], transform[4]);
      return sprite;
    }
  }

  onLoad(callback: () => void) {
    if (this.ready) {
      callback();
    } else {
      this._onLoad = callback;
    }
  }
}
