import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidepanelService {

  private isVisible:boolean = false;;
  private isMobileSlidein:boolean = false;
  private isExpanded:boolean = true;

  constructor() { }

  expand(){
    console.log('expand')
    this.isExpanded = true;
  }
  unexpand(){
    console.log('unexpand')
    this.isExpanded = false;
  }
  activate(){
    this.isVisible = true;
  }
  deactivate(){
    this.isVisible = false;
  }
  activateMobile(){
    this.isMobileSlidein = true;
  }
  deactivateMobile(){
    this.isMobileSlidein = false;
  }
  getExpandedStatus():boolean{
    return this.isVisible && this.isExpanded;
  }
  getVisibilityStatus():boolean{
    return this.isVisible;
  }
  getMobileVisibilityStatus():boolean{
    return this.isMobileSlidein;
  }

}
