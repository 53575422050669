import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';


@Component({
  selector: 'admin-support',
  templateUrl: './admin-support.component.html',
  styleUrls: ['./admin-support.component.scss']
})
export class AdminSupportComponent implements OnInit {

  userSearchResults: Observable<IUser[]>;
  private userCollection: AngularFirestoreCollection<IUser>;

  searchUsers() {
    console.log('search users');
    this.userCollection = this.afs.collection<IUser>('users', ref => ref.orderBy("displayName").limit(100));
    this.userSearchResults = this.userCollection.valueChanges(); // ['added', 'removed']
  }

  constructor(
    private afs: AngularFirestore,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {
    
  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
  }

}
