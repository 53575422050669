import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ISelectBlockContent, ISelectBlockContentPagelet } from '../../ui-partial/body-block-arr-select/body-block-arr-select.component';
import { OPTIONS_ACCOUNT_USER_TYPE, ISelectBlockContentUserType } from './content/options-account-user-type';
import { OPTIONS_ACCOUNT_RESTORATION } from './content/options-account-restoration';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { WhitelabelService, WLST_TRIAL_ID, WLST_INST_SCOPE_ID } from '../../domain/whitelabel.service';
import { renderEmailFromUsername } from '../../data/accounts/pseudo-email-usernames';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { Subscription } from 'rxjs';
// import { SsoPagelets } from './content/pagelets';

enum SsoPagelets {
  INDEX = 'INDEX',
  LINK_OLD = 'LINK_OLD',
  CREATE_NEW = 'CREATE_NEW',
}
@Component({
  selector: 'user-registration-sso',
  templateUrl: './user-registration-sso.component.html',
  styleUrls: ['./user-registration-sso.component.scss']
})
export class UserRegistrationSsoComponent implements OnInit, OnDestroy {
  
  currentPagelet:SsoPagelets = SsoPagelets.INDEX;
  chosenPath:SsoPagelets = null;
  isAuthnMatchFailed:boolean = false;
  existingUserForm = new FormGroup({});
  existingUsername = new FormControl('');
  existingPassword = new FormControl('');
  accountRestorationMode = new FormControl();
  accountUserType = new FormControl();
  agreeTerms = new FormControl(true);
  agreeIntComms = new FormControl(true);
  optionsAccountRestoration:ISelectBlockContent[] = OPTIONS_ACCOUNT_RESTORATION
  optionsAccountUserType:ISelectBlockContent[] = OPTIONS_ACCOUNT_USER_TYPE;
  accountLinkingError:string;

  private formSubs:Subscription[];

  SSO_PAGELETS = SsoPagelets;
  ACCOUNT_LINK_ERRORS = {
    NO_USER: 'auth/user-not-found',
    WRONG_PASS: 'auth/wrong-password',
    OATH_ON_OTHER: 'auth/credential-already-in-use'
    // OATH_ON_OTHER: 'auth/provider-already-linked'
  }

  constructor(
    public whiteLabel: WhitelabelService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.whiteLabel.onReady( () => {
      this.initTemplates();
    })
    this.formSubs = [
      this.existingUsername.valueChanges.subscribe( () => this.accountLinkingError = '' ),
      this.existingPassword.valueChanges.subscribe( () => this.accountLinkingError = '' ),
    ]
  }

  ngOnDestroy() {
    if (this.formSubs){
      this.formSubs.forEach( sub => sub.unsubscribe() );
    }
  }

  initTemplates(){
    this.optionsAccountRestoration.forEach(entry => {
      entry.caption = this.whiteLabel.updateTemplateContent(entry.caption);
      entry.body = this.whiteLabel.updateTemplateContent(entry.body);
    });
    this.optionsAccountUserType.forEach(entry => {
      entry.caption = this.whiteLabel.updateTemplateContent(entry.caption);
      entry.body = this.whiteLabel.updateTemplateContent(entry.body);
    });
  }

  navContinue(){
    if (this.canContinue()){
      if (this.currentPagelet === SsoPagelets.INDEX){
        let restorationModeOption:ISelectBlockContentPagelet = this.accountRestorationMode.value;
        this.currentPagelet = <SsoPagelets>restorationModeOption.data.targetPagelet;
      }
      else if (this.currentPagelet === SsoPagelets.LINK_OLD){
        // this.existingUsername.setValue('c2t@student.calculatingcats.com');
        // this.existingPassword.setValue('math123');    }
        let userEmail:string = renderEmailFromUsername(this.existingUsername.value)
        this.accountLinkingError = null;
        this.auth.linkEmailToOauth(userEmail, this.existingPassword.value)
        .then( 
          () => this.auth.navigateToUserUiHome(),
          (reason) => this.accountLinkingError = reason.code
        )
      }
      else if (this.currentPagelet === SsoPagelets.CREATE_NEW){
        this.completeNewAccountSetup()
      }
    }
  }

  completeNewAccountSetup(){
    this.auth.googleLogin()
      .then( () => {
        let userType:ISelectBlockContentUserType = this.accountUserType.value;
        let agreementsMap:Map<string, boolean> = new Map();
        agreementsMap.set('agreeIntComms', this.agreeIntComms.value);
        agreementsMap.set('agreeTerms', this.agreeTerms.value);
        return this.auth.updateUserAgreements(agreementsMap)
        .then( () => {
          return this.auth
            .updateUserUi(
              userType.data.ui, 
              this.whiteLabel.getSiteText(WLST_TRIAL_ID),
              this.whiteLabel.getSiteText(WLST_INST_SCOPE_ID)
            )
        })
        .then( () => {
          return this.auth.navigateToUserUiHome();
        });
      })
  }

  navCancel(){
    this.auth.signOut(false);
    this.router.navigate(['signup']); 
  }

  navBack(){
    this.currentPagelet = SsoPagelets.INDEX
  }

  ifFirstPage(){
    return (this.currentPagelet === SsoPagelets.INDEX);
  }

  canContinue(){
    if (this.currentPagelet === SsoPagelets.INDEX){
      if (!this.accountRestorationMode.value){
        return false;
      }
    }
    else if (this.currentPagelet === SsoPagelets.LINK_OLD){
      
    }
    else if (this.currentPagelet === SsoPagelets.CREATE_NEW){
      if (!this.accountUserType.value){
        return false;
      }
    }
    return true;
  }

}
