import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { FormGroup, FormControl } from '@angular/forms';
import { IBoosterPackItem } from '../../data/cambridge/types';
import { serverTimestamp } from '../../data/timestamp';
import { ITask } from '../../data/collections/tasks.types';

@Component({
  selector: 'item-new-def',
  templateUrl: './item-new-def.component.html',
  styleUrls: ['./item-new-def.component.scss']
})
export class ItemNewDefComponent implements OnInit {

  @Input() boosterPackId:string;
  @Output() onComplete = new EventEmitter();

  formGroup = new FormGroup({
    name: new FormControl(),
    humanOrdering: new FormControl(),
    itemType: new FormControl(),
    itematicEngineVersion: new FormControl(),
    itematicItemVersionId: new FormControl(),
  });
  isComplete: boolean;
  isInProgress: boolean;
  itemTypeOptions = ['blue', 'red', 'black'];

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    
  }

  insertNewItem(){

    console.log('insertNewItem') ;
    
    this.isInProgress = true;

    const newTask:ITask = {
      isPublic: true,
      itematicEngineVersion: this.formGroup.controls['itematicEngineVersion'].value,
      itematicItemVersionId: this.formGroup.controls['itematicItemVersionId'].value,
      name:                  this.formGroup.controls['name'].value,
      itematicLessonId: '',
      numThumbsDown: 0,
      numThumbsUp: 0,
      timeCreated: serverTimestamp(),
      type: 'lesson',
    }

    this.afs
      .collection('tasks')
      .add(newTask)
      .then( res => {
        const taskId = res.id;
        const newItem:IBoosterPackItem = {
          boosterPackId: this.boosterPackId,
          name:          this.formGroup.controls['name'].value,
          humanOrdering: this.formGroup.controls['humanOrdering'].value,
          itemType:      this.formGroup.controls['itemType'].value,
          taskId, 
          timeCreated: serverTimestamp(),
          unlockedBy: [],
          x: 1,
          y:1,
        }
        this.afs
          .collection('boosterPackItems')
          .add(newItem)
          .then( res => {
            this.onComplete.emit();
            this.isInProgress = false;
            this.isComplete = true;
            console.log('created new item', newTask, newItem);
          })
      })
    
  }

}
