import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss']
})
export class SortComponent implements OnInit {

  @Input() by:string;
  @Input() mode:string;
  @Output() click = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {

  }

}
