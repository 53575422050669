import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';
import { ICurriculumOutcome } from '../../data/collections/curriculum-outcomes.types';
import { ITask } from '../../data/collections/tasks.types';


const enum TaskType {
  ASSESSMENT = 'assessment',
  LESSON = 'lesson',
  QUESTION_SCREEN = 'question_screen',
  MASTERY_DRILL = 'mastery_drill',
}

@Component({
  selector: 'form-task-editor',
  templateUrl: './form-task-editor.component.html',
  styleUrls: ['./form-task-editor.component.scss']
})
export class FormTaskEditorComponent implements OnInit, OnDestroy {

  activeCurriculum = new FormControl(null);
  activeLearningOutcome = new FormControl(null);
  activeTask = new FormControl(null);
  activeCurriculumCategory = new FormControl(null);
  activeTaskType = new FormControl(TaskType.ASSESSMENT);
  curriculumSearchString = new FormControl('');
  curriculumCategorySearchString = new FormControl('');
  learningOutcomeSearchString = new FormControl('');
  taskSearchString = new FormControl('');
  isCapturingNewTask = new FormControl(false);
  curriculums$: Observable<ICurriculum[]>;
  learningOutcomes$: Observable<ICurriculumOutcome[]>;
  tasks$: Observable<ITask[]>;

  private curriculumCollection: AngularFirestoreCollection<ICurriculum>;
  private currentLearningOutcomeCollection: AngularFirestoreCollection<ICurriculumOutcome>;
  private currentTasks: AngularFirestoreCollection<ITask>;
  private taskSub:Subscription;
  private taskTypeSub:Subscription;
  private curricSub:Subscription;

  constructor(
    private afs: AngularFirestore,
  ) {
    
  }

  ngOnInit() {
    this.updateLearningOutcomeFilter();
    this.updateTaskFilter();
    this.curriculumCollection = this.afs.collection<ICurriculum>('curriculums');
    this.curriculums$ = CollectionsService.getValuesIdentified(this.curriculumCollection);
    this.taskTypeSub = this.activeTaskType.valueChanges.subscribe(this.onTaskTypeFilterChange);
    this.curricSub = this.activeCurriculum.valueChanges.subscribe(data => {
      console.log('curric change', data)
    })
  }

  ngOnDestroy() {
    if (this.taskSub) {this.taskSub.unsubscribe();}
    if (this.taskTypeSub) {this.taskTypeSub.unsubscribe();}
    if (this.curricSub) {this.curricSub.unsubscribe();}
  }

  onTaskTypeFilterChange = () => {
    this.updateTaskFilter();
  }

  onNewTaskCapture(event:{name: string, itematicItemId:string, screens:Array<ITask>}){
    console.log('onNewTaskCapture', event);
    this.getTaskForItematicLesson(event.name, event.itematicItemId, (taskId:string) => {
      let screenIds = [];
      event.screens.forEach((screen, i) => {
        screen.parentTask = taskId;
        screen.timeCreated = serverTimestamp();
        screenIds[i] = 'IN_PROGRESS';
        let screenToTaskMappings = new Map();
        // TODO: identify all scene ids no longer part of the assessment and archive them here (but also keep the list of the validated ones with their task ids so that you can re-use them quickly)
        this.constructTaskQuestion(screen, screenToTaskMappings, questionTaskId => {
          screenIds[i] = questionTaskId;
          this.updateTaskQuestionList(taskId, screenIds)
        })
      })
    })
  }

  checkForExistingTaskByLessonId(itematicLessonId:String, then:(taskId:string)=>any){
    let req = this.afs.collection<ITask>('tasks', ref => ref.where('itematicLessonId', '==', itematicLessonId) );
    let sub = CollectionsService.getValuesIdentified(req).subscribe(val => {
      if (val && val.length > 0){
        let task:ITask = val[0];
        then(task.__id);
      }
      else{
        then(null);
      }
      sub.unsubscribe();
    })
  }

  updateTaskQuestionList(taskId: string, questionTaskIds:string[]){
    console.log('updateTaskQuestionList', taskId, questionTaskIds)
    this.afs.doc<ITask>('tasks/'+taskId).update({
      questions: questionTaskIds
    })
  }

  constructTaskQuestion(questionTask: ITask, screenToTaskMappings:Map<string, string>, then:(taskId:string)=>any){
    // this.afs.collection<ITask>('tasks', ref => {
    //   return ref
    //     .where('itematicSceneId', '==', this.activeTaskType.value)
    //     .limit(100)
    // });
    this.tasks$ = CollectionsService.getValuesIdentified(this.currentTasks);
    // should sweep the records for exisiting matches and flag them...
    this.afs.collection<ITask>('tasks').add(questionTask).then(val => {
      then(val.id);
    })
  }

  getTaskForItematicLesson(taskName: string, itematicLessonId:string, then:(taskId:string)=>any){
    this.checkForExistingTaskByLessonId(itematicLessonId, (taskId:string) => {
      if (taskId){
        then(taskId);
      }
      else{
        this.afs.collection<ITask>('tasks').add({
          isPublic: true,
          isOpenResponse: false,
          itematicLessonId: itematicLessonId,
          name: taskName, 
          numThumbsDown: 0,
          numThumbsUp: 0,
          type: "assessment",
          timeCreated: serverTimestamp(),
        }).then(val => {
          then(val.id);
        })
      }
    })
    // let CollectionsService.getValuesIdentified(this.assignmentsCollection).subscribe(val => {
    //   console.log('assignments', val)
    //   this.assignments = val;
    //   this.hasAssignments = (val && val.length > 0);
    // })
  }

  createSubTask(parentTaskId:string, subTaskConfig:ITask, then:Function){

  }

  updateLearningOutcomeFilter(){
    // this.currentLearningOutcomeCollection = this.afs.collection<ICurriculumOutcome>('curriculumOutcomes', ref => {
    //   return ref
    //     .limit(30)
    // });
    // this.learningOutcomes$ = CollectionsService.getValuesIdentified(this.currentLearningOutcomeCollection);
    // this.learningOutcomes$.subscribe(data => console.log('learning outcome data', data))
  }

  updateTaskFilter(){
    let taskType
    this.currentTasks = this.afs.collection<ITask>('tasks', ref => {
      return ref
        .where('type', '==', this.activeTaskType.value)
        .limit(100)
    });
    this.tasks$ = CollectionsService.getValuesIdentified(this.currentTasks);
    this.taskSub = this.tasks$.subscribe(data => console.log('task data', data))
  }

}
