import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ISelectBlockContent, ISelectBlockContentPagelet } from '../../ui-partial/body-block-arr-select/body-block-arr-select.component';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { renderEmailFromUsername, renderUsernameFromEmail } from '../../data/accounts/pseudo-email-usernames';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { Subscription } from 'rxjs';
import { ClassroomService } from '../../ui-teacher/classroom.service';
import { IClassroom } from '../../data/collections/classrooms.types';
import { UsersService } from '../../core/users.service';
import { IUser } from '../../data/collections/users.types';

interface IStudentSlot {
  uid: string,
  displayName: string,
  username: string,
}

@Component({
  selector: 'classroom-play',
  templateUrl: './classroom-play.component.html',
  styleUrls: ['./classroom-play.component.scss']
})
export class ClassroomPlayComponent implements OnInit, OnDestroy {

  classroomId:string;
  classCodeId:string;
  classConfig:IClassroom;
  routeSub:Subscription;
  signinSub:Subscription;
  currentStudentSlots:IStudentSlot[] = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId']; // used to show name in the UI
      this.classCodeId = params['classCodeId'];
      this.loadClassroom(this.classroomId);
    });
    this.signinSub = this.auth.user.subscribe(user => {
      if (user){
        this.auth.signOut(false);
        this.signinSub.unsubscribe();
        this.signinSub = null;
      }
    });

    // const freshAccount = this.auth.getFreshAccountSignup();
    // console.log('freshAccount', freshAccount)
  }

  ngOnDestroy() {
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.signinSub){ this.signinSub.unsubscribe(); }
  }

  isSupposedToShowClassList(){
    return this.isAllowedToShowClassList() && this.currentStudentSlots.length > 0;
  }

  isAllowedToShowClassList(){
    if (this.classConfig){
      return this.classConfig.enableClassListingByCC;
    }
  }

  getFreshAccountUsername(){
    const freshAccount = this.auth.getFreshAccountSignup();
    if (freshAccount){
      return freshAccount.username;
    }
  }


  loadClassroom(classroomId: string) {
    // console.log('classroomId', classroomId)
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        if (this.isAllowedToShowClassList()){
          this.loadStudents(this.classConfig.currentStudents);
        }
      });
  }

  loadStudents(studentIds:string[]){
    this.currentStudentSlots = []
    this.classConfig.currentStudents.forEach(uid => {
      const studentSlot:IStudentSlot = {
        uid,
        displayName: '',
        username: '',
      }
      this.currentStudentSlots.push(studentSlot);
      this.usersService
        .getUserInfo(uid)
        .subscribe((userInfo:IUser)=>{
          studentSlot.displayName = userInfo.displayName;
          studentSlot.username = renderUsernameFromEmail(userInfo.email);
          this.currentStudentSlots = this.currentStudentSlots.sort((a:IStudentSlot, b:IStudentSlot) => {
            return (a.displayName+'').toLowerCase() < (b.displayName+'').toLowerCase() ? -1 : 1;
          })
        })
    })
  }

  createNewAccount(){
    const path = ['student', 'new', this.classroomId, this.classCodeId];
    this.router.navigate(path);
  }

  promptLogin(username?:string){
    const path = ['classroom', 'play', this.classroomId, this.classCodeId, 'login'];
    if (username){
      path.push(username);
    }
    this.router.navigate(path);
  }


}
