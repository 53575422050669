import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'marker-coord-items',
  templateUrl: './marker-coord-items.component.html',
  styleUrls: ['./marker-coord-items.component.scss']
})
export class MarkerCoordItemsComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {
  }

  isFilterShown;

  ngOnInit() {
    this.sidePanel.activate();
    this.sidePanel.unexpand();
    this.chatPanel.deactivate();
    this.data.responses.forEach(response => {
      response['id'] = Math.floor(5000+Math.random()*10000);
    })
  }

  isActiveResponseCode(code:string){
    if (this.activeResponse && this.activeResponse.code === code){
      return true
    }
    return false
  }

  renderTagCaption(tag:string){
    return tag;
  }

  activeResponse;
  selectResponse(response){
    this.activeResponse = response;
  }

  data = {
    questionCaption: 'Estimate the area of the lake using the available tools.',
    availableTagGroups: [
        {
            slug: 'res',
            caption: 'Correct Response',
            tags: [
                {slug: 'res_blank', isNullStyle: true, caption: 'Blank Response'},
                {slug: 'res_correct', caption: 'Response is In Range'},
                {slug: 'res_incorrect_1', caption: 'Response is Out of Range (Alt 1)'},
                {slug: 'res_incorrect_2', caption: 'Response is Out of Range (Alt 2)'},
                {slug: 'res_incorrect_other', caption: 'Response is Out of Range (Other)'},
            ]
        },
        {
            slug: 'ruler',
            caption: 'Ruler',
            tags: [
                {slug: 'ruler_0', isNullStyle: true, caption: 'Did Not Use Ruler'},
                {slug: 'ruler_1', caption: 'Used Ruler 1 time'},
                {slug: 'ruler_2', caption: 'Used Ruler 2 times'},
                {slug: 'ruler_3t5', caption: 'Used Ruler 3 to 5 times'},
                {slug: 'ruler_6p', caption: 'Used Ruler 6+ times'},
            ]
        },
        {
            slug: 'counter',
            caption: 'Counters',
            tags: [
                {slug: 'counter_0', isNullStyle: true, caption: 'Did Not Use Counters'},
                {slug: 'counter_1p', caption: 'Used Counters'},
            ]
        },
        {
            slug: 'calculator',
            caption: 'Calculator',
            tags: [
                {slug: 'calculator_0', isNullStyle: true, caption: 'Did Not Use Calculator'},
                {slug: 'calculator_1p', caption: 'Used Calculator'},
            ]
        },
        {
            slug: 'geo_rectangle',
            caption: 'Rectangle Tool',
            tags: [
                {slug: 'geo_rectangle_0', isNullStyle: true, caption: 'Did not Draw Rectangles'},
                {slug: 'geo_rectangle_1', caption: 'Drew 1 Rectangle'},
                {slug: 'geo_rectangle_2p', caption: 'Drew 2+ Rectangles'},
            ]
        },
        {
            slug: 'geo_circle',
            caption: 'Circle Tool',
            tags: [
                {slug: 'geo_circle_0', isNullStyle: true, caption: 'Did not Draw Circles'},
                {slug: 'geo_circle_1', caption: 'Drew 1 Circle'},
                {slug: 'geo_circle_2p', caption: 'Drew 2+ Circles'},
            ]
        },
        {
            slug: 'pencil',
            caption: 'Free-hand Pencil Tool',
            tags: [
                {slug: 'pencil_0', isNullStyle: true, caption: 'Did not Use Pencil'},
                {slug: 'pencil_1p', caption: 'Used Pencil'},
            ]
        },

    ],
    codingHeader: 'The acceptable range is between 3800 sq. m. and 6000 sq. m.',
    availableCodings: [
      {code: '21', color: 'good',   description: 'In range. Estimated by drawing a square or rectangle.'},
      {code: '22', color: 'good',   description: 'In range. Estimated by drawing a circle.'},
      {code: '23', color: 'good',   description: 'In range. Estimated by adding areas of several regular geometric figures.'},
      {code: '24', color: 'good',   description: 'In range. Estimated by using counters on grid.'},
      {code: '25', color: 'good',   description: 'In range. Estimated by other correct method.'},
      {code: '26', color: 'good',   description: 'In range, but no work is shown.'},
      {code: '11', color: 'almost', description: 'Not in range. Estimated by drawing a square or rectangle.'},
      {code: '12', color: 'almost', description: 'Not in range. Estimated by drawing a circle.'},
      {code: '13', color: 'almost', description: 'Not in range. Estimated by adding areas of several regular geometric figures.'},
      {code: '14', color: 'almost', description: 'Not in range. Estimated by other correct method.'},
      {code: '15', color: 'almost', description: 'Not in range. Estimated by using counters on grid.'},
      {code: '16', color: 'almost', description: 'Not in range, but no work is shown.'},
      {code: '05', color: 'wrong',  description: 'Calculated the perimeter instead of area.'},
      {code: '06', color: 'wrong',  description: 'Other responses.'},
      {code: '01', color: 'blank',  description: 'Insufficient Response to make a determination.'},
      {code: '00', color: 'blank',  description: 'Blank Submission.'},
    ],
    responses: [
        {
            responses: [4900],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569430982167_Screen%20Shot%202019-09-25%20at%2012.09.36%20PM.png?alt=media&token=eeee7101-bcd4-4c57-b757-b36acdbdee83',
            tags: [
                'res_correct',
                'ruler_2',
                'counter_0',
                'calculator_0',
                'geo_rectangle_1',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [5482],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569430989208_Screen%20Shot%202019-09-25%20at%2012.11.06%20PM.png?alt=media&token=61c525d4-91e4-4e8b-bc47-c7c9b9a5098d',
            tags: [
                'res_correct',
                'ruler_3t5',
                'counter_0',
                'calculator_1p',
                'geo_rectangle_2p',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [5500],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569430997657_Screen%20Shot%202019-09-25%20at%2012.11.59%20PM.png?alt=media&token=e89db926-b547-48a3-ac15-2907f2163819',
            tags: [
                'res_correct',
                'ruler_0',
                'counter_1p',
                'calculator_0',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [4047.12],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431009905_Screen%20Shot%202019-09-25%20at%2012.12.45%20PM.png?alt=media&token=2eea3285-4715-4172-b4fc-208261a948fc',
            tags: [
                'res_correct',
                'ruler_2',
                'counter_0',
                'calculator_1p',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431018062_Screen%20Shot%202019-09-25%20at%2012.14.26%20PM.png?alt=media&token=affa96ab-b1bd-41c9-a07a-68f18107ea3d',
            tags: [
                'res_blank',
                'ruler_2',
                'counter_1p',
                'calculator_0',
                'geo_rectangle_1',
                'geo_circle_0',
                'pencil_1p',
            ]
        },
        {
            responses: [500],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431023053_Screen%20Shot%202019-09-25%20at%2012.15.24%20PM.png?alt=media&token=f17f5cd3-b59c-4902-bdae-93ea86d6196c',
            tags: [
                'res_incorrect_1',
                'ruler_0',
                'counter_1p',
                'calculator_0',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [5000],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431031070_Screen%20Shot%202019-09-25%20at%2012.17.15%20PM.png?alt=media&token=d974abbe-1cde-441d-9b7a-6e6eb54e504e',
            tags: [
                'res_correct',
                'ruler_0',
                'counter_0',
                'calculator_0',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [481],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431038641_Screen%20Shot%202019-09-25%20at%2012.17.34%20PM.png?alt=media&token=ab25a56a-a31c-4728-9e38-0685181f633a',
            tags: [
                'res_incorrect_1',
                'ruler_0',
                'counter_0',
                'calculator_0',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [269],
            imgUrl: '',
            tags: [
                'res_incorrect_2',
                'ruler_6p',
                'counter_1p',
                'calculator_1p',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [2000],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431052448_Screen%20Shot%202019-09-25%20at%2012.19.41%20PM.png?alt=media&token=b30ac1a8-ac46-4a66-b8f9-7bf5bcc2b3e6',
            tags: [
                'res_incorrect_other',
                'ruler_2',
                'counter_0',
                'calculator_1p',
                'geo_rectangle_1',
                'geo_circle_0',
                'pencil_0',
            ]
        },
        {
            responses: [],
            imgUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2F3VU5GPkWgCWNyf6Oph0Wy6o1Ey22%2F1569431056168_Screen%20Shot%202019-09-25%20at%2012.20.13%20PM.png?alt=media&token=fa3a4dcd-e0db-4e87-aae0-33627703834d',
            tags: [
                'res_blank',
                'ruler_2',
                'counter_0',
                'calculator_1p',
                'geo_rectangle_0',
                'geo_circle_0',
                'pencil_0',
            ]
        },
    ]
  }



}
