import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'student-store',
  templateUrl: './student-store.component.html',
  styleUrls: ['./student-store.component.scss']
})
export class StudentStoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
