export class AuthGuardResolveRouter {
    private static currentRoute:string = 'home';
    public static setRoute(route:string){
      if (route && route !== '/' && route !== '/login'){
        this.currentRoute = route;
      }
    }
    public static getRoute(){
      return this.currentRoute;
    }
    public static clearRoute(){
      this.currentRoute = 'home';
    }
  }