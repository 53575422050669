import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import * as moment from 'moment';

@Pipe({
  name: 'firestoreMoment'
})
export class FirestoreMomentPipe implements PipeTransform {

  transform(timestamp: Timestamp, format:string = 'MMMM Do YYYY, h:mm:ss a'): any {
    if (timestamp){
      return moment(timestamp.seconds*1000).format(format);;
    }
  }

}
