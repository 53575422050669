import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { FormControl } from '@angular/forms';
import { BoosterpacksService } from '../boosterpacks.service';
import { IBoosterPack } from '../../data/cambridge/types';

@Component({
  selector: 'item-map-upload',
  templateUrl: './item-map-upload.component.html',
  styleUrls: ['./item-map-upload.component.scss']
})
export class ItemMapUploadComponent implements OnInit {

  @Input() boosterPackId:string;
  @Input() isIconMode:boolean = false;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  isHovering: boolean;
  isRequestingReupload:boolean;
  url:string;

  private userUploadSub:Subscription;
  private storageSub:Subscription;

  constructor(
    private afs: AngularFirestore,
    private bps: BoosterpacksService,
    private storage: AngularFireStorage,
    // private db: AngularFirestore,
    private auth: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.boosterPackId){
      this.loadCurrentBoosterPackInfo();
    }
  }

  boosterPackSub;
  boosterPackInfo: IBoosterPack;
  loadCurrentBoosterPackInfo(){
    this.boosterPackSub = this.bps
      .getBoosterPackSummary(this.boosterPackId)
      .subscribe( (boosterPackInfo:IBoosterPack) => {
        this.boosterPackInfo = boosterPackInfo;
        this.url = this.boosterPackInfo.bgImageUrl;
      })
  }

  updateBoosterPackTileImage(url:string){
    this.boosterPackInfo.bgImageUrl = url;
    this.afs
      .doc('boosterPacks/'+this.boosterPackId)
      .update({
        bgImageUrl: url
      })
      .then( res => {
        this.url = url;
      })
  }

  ngOnDestroy(){
    if (this.userUploadSub){ this.userUploadSub.unsubscribe(); }
    if (this.storageSub){ this.storageSub.unsubscribe(); }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  getImageUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
  
  isWaitingForUpload(){
    return this.isRequestingReupload || !this.url;
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);
    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }
    // The storage path
    let uid = this.auth.getUid();
    const path = `booster-pack-image/${this.boosterPackId}/${new Date().toISOString()}/${uid}/${file.name}`;
    const customMetadata = { app: 'Calculating Cats' };
    this.task = this.storage.upload(path, file, { customMetadata });
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    this.userUploadSub = this.snapshot.subscribe( snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        // it looks like the processing takes a bit of time on the Firebase side, so I added a 1 second delay before retrieving the url
        setTimeout(()=> {
          this.storageSub = this.storage.ref(path).getDownloadURL().subscribe( url => {
            this.updateBoosterPackTileImage(url);
            this.isRequestingReupload = false;
          });
        }, 1000)
        
      }
    })

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

}
