import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { IUser, UserUi } from '../../data/collections/users.types';
import { ILeaderboard, ILeaderboardGroup, ILeaderboardScore, ILeaderboardGroupScore, IBoosterPack } from 'src/app/data/cambridge/types';
import { LeaderboardService } from 'src/app/core/leaderboard.service';
import { UsersService } from 'src/app/core/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ForeignUserService } from 'src/app/core/foreignUser.service';
import { BoosterpacksService } from 'src/app/ui-student/boosterpacks.service';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'teacher-leaderboards',
  templateUrl: './teacher-leaderboards.component.html',
  styleUrls: ['./teacher-leaderboards.component.scss']
})
export class TeacherLeaderboardsComponent implements OnInit {
  unranked = '<50';

  activeLeaderboards: ILeaderboard[];
  inactiveLeaderboards: ILeaderboard[];

  currentDistrict = undefined;
  currentClassType = 'universal';

  currentLeaderboard: ILeaderboard;
  currentBoosterpack: IBoosterPack;
  currentIsPast = false;

  myLeaderboardGroup: ILeaderboardGroup;
  myLeaderboardGroupScore: ILeaderboardGroupScore;
  myLeaderboardGroupRank: number;
  myLeaderboardStudentScores: ILeaderboardScore[];

  edittingTeamName = false;
  teamNameForm: FormControl = new FormControl('');

  studentSelected: ILeaderboardScore;
  studentSelectedUser: IUser;

  studentCache: Map<string, IUser> = new Map();

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private ls: LeaderboardService,
    private user: UsersService,
    private foreign: ForeignUserService,
    private bps: BoosterpacksService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();

    this.route.params.subscribe(params => {
      console.log('foreignClassId', params['foreignClassId']);
      if (params['foreignClassId']) {
        let foreignClassId = params['foreignClassId'];
        let foreignClassType = params['foreignClassType'];
        let foreignUid = params['foreignUid'];
        let district = params['district'];
        let displayName = params['displayName'];

        this.currentClassType = foreignClassType;
        this.currentDistrict = district;

        this.foreign.setForeignUserData({
          foreignClassId,
          foreignUid,
          foreignClassType,
          district,
          displayName,
        });
        this.foreign.foreignLogin(this.foreign.getForeignUserData()).then(uid => {
          this.signin();
        });
      } else {
        if (params['district']) {
          let district = params['district'];
          let classType = params['classType'] || 'stage_6';
          let classId = params['classroomId'];
          
          this.currentDistrict = district;
          this.currentClassType = classType;
          this.foreign.setForeignUserData({
            foreignClassType: classType,
            district,
            foreignClassId: classId,
            displayName: this.auth.getDisplayName()
          });
        } else if (this.foreign.isForeign()) {
          let foreignData = this.foreign.getForeignUserData();

          this.currentClassType = foreignData.foreignClassType;
          this.currentDistrict = foreignData.district;
        }
        this.signin();
      }
    });
  }

  signin = () => {
    let signinSub;

    return new Promise<IUser>((resolve) => {
      signinSub = this.auth.user.subscribe(user => {
        if (user && user.ui === UserUi.DISTRICT_COORD){
          this.router.navigate([
            'admin',
            'leaderboards'
          ]);
          resolve(user);
        }
        else{
          this.router.navigate([
            'teacher',
            'leaderboards'
          ]);
          return;
          this.ls.getLeaderboardsByDistrictAndClassType(this.currentDistrict, this.currentClassType).then(leaderboards => {
            this.activeLeaderboards = leaderboards.filter(leaderboard => leaderboard.isActive);
            this.inactiveLeaderboards = leaderboards.filter(leaderboard => !leaderboard.isActive);
            resolve(user);
          });
        }
      });
    }).then(user => {
      signinSub.unsubscribe();
      return user;
    });
  }

  selectLeaderboard(leaderboard: ILeaderboard, isPast: boolean = false) {
    this.currentIsPast = isPast;
    this.currentLeaderboard = leaderboard;

    // ** NOTE: CHANGE TO leadMyLeaderboardGroups to see a list of all groups with the same foreignClassId.  This is not fully tested. **

    this.loadMyLeaderboardGroup(leaderboard);
    // this.loadMyLeaderboardGroups(leaderboard);

    this.bps.getBoosterPackById(leaderboard.boosterPackId).then(boosterpack => {
      this.currentBoosterpack = boosterpack;
    });
  }

  loadMyLeaderboardGroup(leaderboard: ILeaderboard) {
    let foreignClassId: string = this.foreign.getForeignUserData().foreignClassId;

    this.ls.getMyModeratedLeaderboardGroups(leaderboard, foreignClassId).then(groups => {
      if (groups && groups.length > 0) {
        this.loadGroupDetails(leaderboard, groups[0]);
      } else {
        if (this.foreign.isForeign()) {
          let foreignData = this.foreign.getForeignUserData();
          this.ls.assignMeAsModeratorToForeignLeaderboardGroup(leaderboard, foreignData.foreignClassId).then(group2 => {
            if (group2) {
              this.loadGroupDetails(leaderboard, group2);
            }
          });
        }
      }
    });
  }

  loadGroupDetails(leaderboard: ILeaderboard, group: ILeaderboardGroup) {
    this.myLeaderboardGroup = group;
    this.ls.getLeaderboardStudentScores(leaderboard, group.students).then(studentScores => {
      this.myLeaderboardStudentScores = studentScores;
    });
    this.ls.getLeaderboardGroupScore(leaderboard, group.__id, 50).then(groupScore => {
      this.myLeaderboardGroupScore = groupScore;
      if (groupScore) {
        this.myLeaderboardGroupRank = groupScore.__rank || 0;
      } else {
        this.myLeaderboardGroupRank = 0;
      }
    });
  }

  loadMyLeaderboardGroups(leaderboard: ILeaderboard) {
    let foreignClassId: string = this.foreign.getForeignUserData().foreignClassId;
    // this.myLeaderboardGroupChunks = [];
    // this.ls.getMyModeratedLeaderboardGroups(leaderboard, foreignClassId).then(groups => {
    //   groups.forEach(group => this.myLeaderboardGroupChunks.push(this.makeGroupChunk(leaderboard, group)));
    // });
    // if (this.foreign.isForeign()) {
    //   this.ls.assignMeAsModeratorToForeignLeaderboardGroups(leaderboard, foreignClassId).then(groups => {
    //     groups.forEach(group => this.myLeaderboardGroupChunks.push(this.makeGroupChunk(leaderboard, group)));
    //   });
    // }
    // console.log(this.myLeaderboardGroupChunks);
  }

  // makeGroupChunk = (leaderboard: ILeaderboard, group: ILeaderboardGroup) => {
  //   let chunk: ILeaderboardGroupChunk = {};

  //   chunk.group = group;

  //   this.ls.getLeaderboardStudentScores(leaderboard, group.students).then(studentScores => {
  //     chunk.studentScores = studentScores;
  //   });

  //   this.ls.getLeaderboardGroupScore(leaderboard, group.__id, 50).then(groupScore => {
  //     chunk.groupScore = groupScore;

  //     if (groupScore) {
  //       chunk.rank = groupScore.__rank || 0;
  //     } else {
  //       chunk.rank = 0;
  //     }
  //   });

  //   return chunk;
  // }

  navigateToCurrentBooster() {
    this.router.navigate([
      'teacher',
      'leaderboard',
      this.currentLeaderboard.__id,
    ]);
  }

  closeCurrentLeaderboard() {
    this.currentLeaderboard = null;
    this.currentBoosterpack = null;
    this.myLeaderboardGroup = null;
    this.myLeaderboardGroupScore = null;
    this.myLeaderboardGroupRank = null;
    this.myLeaderboardStudentScores = null;
    this.studentSelected = null;
    this.studentSelectedUser = null;
    this.edittingTeamName = false;
    this.teamNameForm.setValue('');
  }

  focusStudent(studentScore: ILeaderboardScore) {
    this.studentSelected = studentScore;
    this.loadStudentUser(studentScore);
  }

  loadStudentUser(studentScore: ILeaderboardScore) {
    let student = this.studentCache.get(studentScore.uid);
    if (student) {
      this.studentSelectedUser = student;
    }
    this.user.getUserInfoAsPromise(studentScore.uid).then(user => {
      this.studentCache.set(studentScore.uid, user);
      this.studentSelectedUser = user;
    });
  }

  printCertificate(student: IUser, score: ILeaderboardScore) {
    window.alert('This will print a STUDENT CERTIFICATE');
  }

  viewStudentWork(student: IUser, score: ILeaderboardScore) {
    window.alert('This will lead to another page to VIEW STUDENT WORK');
  }

  secondsToDate(seconds: number) {
    return new Date(seconds * 1000).toDateString();
  }

  startEditTeamName() {
    this.teamNameForm.setValue(this.myLeaderboardGroup.name);
    this.edittingTeamName = true;
  }

  saveTeamName() {
    this.myLeaderboardGroup.name = this.teamNameForm.value;
    this.myLeaderboardGroupScore.name = this.teamNameForm.value;
    this.ls.updateGroupName(this.myLeaderboardGroup, this.teamNameForm.value);
    this.edittingTeamName = false;
  }
}
