import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'class-code-indicator',
  templateUrl: './class-code-indicator.component.html',
  styleUrls: ['./class-code-indicator.component.scss']
})
export class ClassCodeIndicatorComponent implements OnInit {

  @Input() classCode:string;
  @Input() showTooltip:boolean = false;
  @Input() isSimplified:boolean = false;
  
  public tooltipToggle:boolean = false;

  constructor() { }

  ngOnInit() {
  }

  toggleTooltip(){
    this.tooltipToggle = !this.tooltipToggle;
  }

  isToolTipVisible(){
    return (this.showTooltip == !this.tooltipToggle) && !this.isSimplified;
  }

}
