import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'demo-landing',
  templateUrl: './demo-landing.component.html',
  styleUrls: ['./demo-landing.component.scss']
})
export class DemoLandingComponent implements OnInit {

  constructor(
    public whitelabelService: WhitelabelService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
  }

}
