import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CollectionsService } from '../../data/collections.service';
import { ICustomTaskSet } from '../models';
import { AuthService } from '../../core/auth.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'custom-assessments-collections',
    templateUrl: './custom-assessments-collections.component.html',
    styleUrls: ['./custom-assessments-collections.component.scss']
  })
  export class CustomAssessmentsCollectionsComponent implements OnInit, OnDestroy {

  public projects:{project:string, role:string}[];  
  
  private projectsCollection: AngularFirestoreCollection<ICustomTaskSet>;
  private projRolesSub:Subscription;
  
  // drop(arr:any[], event: CdkDragDrop<string[]>) {
  //   moveItemInArray(arr, event.previousIndex, event.currentIndex);
  // }

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }


  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.loadCustomTaskSetProjects();
  }

  ngOnDestroy() {
    if (this.projRolesSub){ this.projRolesSub.unsubscribe(); }
  }

  loadCustomTaskSetProjects(){
    this.projectsCollection = this.afs.collection<ICustomTaskSet>('customTaskSetProjectRoles', ref => {
      return ref
        .where('uid', '==', this.auth.getUid())
    });
    this.projRolesSub = CollectionsService.getValuesIdentified(this.projectsCollection).subscribe(val => {
      this.projects = val;
    })
  }

  unlinkAccount(){
    this.auth.unlinkAccount();
  }

}
