import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LeaderboardService } from 'src/app/core/leaderboard.service';
import { SidepanelService } from 'src/app/core/sidepanel.service';
import { ChatpanelService } from 'src/app/core/chatpanel.service';


@Component({
  selector: 'student-leaderboard-certificate',
  templateUrl: './student-leaderboard-certificate.component.html',
  styleUrls: ['./student-leaderboard-certificate.component.scss']
})
export class StudentLeaderboardCertificateComponent implements OnInit {
  leaderboardName: string;
  endDate: string;
  studentName: string;
  studentScore: number;
  studentRank: number;
  groupName: string;
  groupScore: number;
  groupRank: number;

  constructor(
    private route: ActivatedRoute,
    private ls: LeaderboardService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {

  }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();

    this.route.params.subscribe(params => {
      let leaderboardId: string = params['leaderboardId'];
      let uid: string = params['uid'];
      this.ls.getLeaderboardById(leaderboardId).then(leaderboard => {
        this.ls.getMyLeaderboardGroup(leaderboard, false, uid).then(myGroup => {
          if (!myGroup) {
            return;
          }
          this.ls.getTopEntries(leaderboard, 99).then(groupScores => {
            let myGroupScore = _.find(groupScores, {groupId: myGroup.__id});
            this.ls.getUserBoosterScoresForGroup(leaderboard, myGroup).then(scores => {
              let score = _.find(scores, {uid});

              this.leaderboardName = leaderboard.name;
              this.endDate = (new Date(leaderboard.dateClosed.seconds * 1000)).toDateString();
              this.studentName = score.name;
              this.studentScore = score.score;
              this.studentRank = score.__rank;
              this.groupName = myGroup.name;
              this.groupScore = myGroupScore.score;
              this.groupRank = myGroupScore.__rank;
            });
          });
        });
      });
    });
  }

  navBack() {
    // navigate back to previous route
    javascript : history.back();
  }

  printCert() {
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      window.print();
    }

    try {
      // Print for Safari browser
      document.execCommand('print', false, null);
    } catch {
      window.print();
    }
  }
}
