import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itematic-thumbnail',
  templateUrl: './itematic-thumbnail.component.html',
  styleUrls: ['./itematic-thumbnail.component.scss']
})
export class ItematicThumbnailComponent implements OnInit {

  url: string;
  bgSettings: {url:string, img:string, color:string};

  constructor() { }

  ngOnInit() {
  }

}
