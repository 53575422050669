import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { ITask } from '../../data/collections/tasks.types';
import { CollectionsService } from '../../data/collections.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'assessment-selector',
  templateUrl: './assessment-selector.component.html',
  styleUrls: ['./assessment-selector.component.scss']
})
export class AssessmentSelectorComponent implements OnInit {
  
  @Output() select = new EventEmitter<ITask>();
  @Output() close = new EventEmitter();

  tasks$: Observable<ITask[]>;
  filteredTasks:ITask[];
  isTasksLoaded:boolean;
  private taskCollection: AngularFirestoreCollection<ITask>;

  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.loadAssessments();
  }

  loadAssessments(){
    this.taskCollection = this.afs.collection<ITask>('tasks', ref => {
      return ref.where('type', '==', 'assessment')
    });
    this.tasks$ = CollectionsService.getValuesIdentified(this.taskCollection);
    this.tasks$.subscribe(val => {
      // console.log(val)
      if (val){
        this.filteredTasks = val.sort( (a,b) => {
          if (a.name > b.name){
            return 1
          }
          else {
            return -1;
          }
        });
      }
      else{
        this.filteredTasks = [];
      }
      this.isTasksLoaded = (val && val.length > 0);
    })
  }

  selectTask(task:ITask){
    this.select.emit(task);
  }

}