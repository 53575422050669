import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  upcomingHeadsUp = [
    { prize:'', whenPlain:'July 15', when:'2019-07-15 10:00', title:'Bachelorette Trivia', link:'https://calculatingcats.com/student/classroom/ipdExyaJekVLxrjnIlp6/headsup/L3ALFFEYigkPjsgnAPmb'},
    { prize:'', whenPlain:'July 18', when:'2019-07-18 10:00', title:'Are You Smarter Than a 2nd Grader?', link:'https://calculatingcats.com/student/classroom/ipdExyaJekVLxrjnIlp6/headsup/vXwpgsnEYE00mNge0JK5'},
    { prize:'', whenPlain:'July 22', when:'2019-07-22 10:00', title:'Blue Jays Trivia', link:''},
    // { when:'2019-07-25 10:00', title:'', link:''},
  ]
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
  }

  

}
