export const indexOf = (arr:any[], t:any) => {
    let i = -1;
    arr.forEach((_t, _i) => {
      if (_t === t){
        i = _i;
      }
    });
    return i;
  }
  

export const mapToJson = (map) =>  {
    return JSON.stringify([...map]);
}