import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// // pages/views
import { AdminInstructionalCurriculumsComponent } from './admin-instructional-curriculums/admin-instructional-curriculums.component';
import { AdminInstructionalLearningOutcomesComponent } from './admin-instructional-learning-outcomes/admin-instructional-learning-outcomes.component';
import { AdminInstructionalTasksComponent } from './admin-instructional-tasks/admin-instructional-tasks.component';
import { AdminInstructionalBoostersComponent } from './admin-instructional-boosters/admin-instructional-boosters.component';
import { AdminGamesStoreComponent } from './admin-games-store/admin-games-store.component';
import { AdminGamesPrizesComponent } from './admin-games-prizes/admin-games-prizes.component';
import { AdminFinanceComponent } from './admin-finance/admin-finance.component';
import { AdminSupportComponent } from './admin-support/admin-support.component';
import { FormCurriculumEditorComponent } from './form-curriculum-editor/form-curriculum-editor.component';
import { IsArchivedPipe } from './is-archived.pipe';
import { NgFusePipe } from './ngfuse.pipe';
import { FormOrganizationEditorComponent } from './form-organization-editor/form-organization-editor.component';
import { SortPipe } from '../time/sort.pipe';
import { FormUserEditorComponent } from './form-user-editor/form-user-editor.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormTaskEditorComponent } from './form-task-editor/form-task-editor.component';
import { FormStudentSelectorComponent } from './form-student-selector/form-student-selector.component';
import { FormParentSelectorComponent } from './form-parent-selector/form-parent-selector.component';
import { FormTeacherSelectorComponent } from './form-teacher-selector/form-teacher-selector.component';
import { FormStudentClassManagerComponent } from './form-student-class-manager/form-student-class-manager.component';
import { FormStudentTaskSimulatorComponent } from './form-student-task-simulator/form-student-task-simulator.component';
import { FormStudentAssignmentSimulatorComponent } from './form-student-assignment-simulator/form-student-assignment-simulator.component';
import { FormTaskCreatorComponent } from './form-task-creator/form-task-creator.component';
import { TimeModule } from '../time/time.module';
import { AdminAssessmentQuestionProcessingComponent } from './admin-assessment-question-processing/admin-assessment-question-processing.component';
import { AdminUserAccountsComponent } from './admin-user-accounts/admin-user-accounts.component';
import { AdminLeaderboardsComponent } from './admin-leaderboards/admin-leaderboards.component';
// // smaller components

@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    ReactiveFormsModule, 
    DragDropModule, 
    TimeModule
  ],
  declarations: [
    AdminSupportComponent,
    AdminInstructionalCurriculumsComponent,
    AdminInstructionalLearningOutcomesComponent,
    AdminInstructionalTasksComponent,
    AdminInstructionalBoostersComponent,
    AdminGamesStoreComponent,
    AdminGamesPrizesComponent,
    AdminFinanceComponent,
    FormCurriculumEditorComponent,
    IsArchivedPipe,
    NgFusePipe,
    FormOrganizationEditorComponent,
    FormUserEditorComponent,
    FormTaskEditorComponent,
    FormStudentSelectorComponent,
    FormParentSelectorComponent,
    FormTeacherSelectorComponent,
    FormStudentClassManagerComponent,
    FormStudentTaskSimulatorComponent,
    FormStudentAssignmentSimulatorComponent,
    FormTaskCreatorComponent,
    AdminAssessmentQuestionProcessingComponent,
    AdminUserAccountsComponent,
    AdminLeaderboardsComponent,
  ],
  exports: [
    AdminSupportComponent,
    AdminInstructionalCurriculumsComponent,
    AdminInstructionalLearningOutcomesComponent,
    AdminInstructionalTasksComponent,
    AdminInstructionalBoostersComponent,
    AdminGamesStoreComponent,
    AdminGamesPrizesComponent,
    AdminFinanceComponent,
    AdminLeaderboardsComponent,
  ]
})
export class UiAdminModule {}
