import { Component, OnInit, Input } from '@angular/core';
import { IContentElementVideo, QuestionState } from '../models';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

@Component({
  selector: 'element-render-video',
  templateUrl: './element-render-video.component.html',
  styleUrls: ['./element-render-video.component.scss']
})
export class ElementRenderVideoComponent implements OnInit {

  @Input() element:IContentElementVideo;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  getYoutubeIframeUrl(){
    let url = `https://www.youtube.com/embed/${ this.element.youtubeId }`;
    console.log('URL', url)
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
