import * as _ from 'lodash';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ChatpanelService } from 'src/app/core/chatpanel.service';
import { AuthService } from 'src/app/core/auth.service';
import { ILeaderboard } from 'src/app/data/cambridge/types';
import { LeaderboardService } from 'src/app/core/leaderboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IUser } from 'src/app/data/collections/users.types';
import { ForeignUserService } from 'src/app/core/foreignUser.service';
import { SidepanelService } from '../../core/sidepanel.service';
import * as moment from 'moment'; // 
import { Subscription } from 'rxjs';

@Component({
  selector: 'student-leaderboards',
  templateUrl: './student-leaderboards.component.html',
  styleUrls: ['./student-leaderboards.component.scss']
})
export class StudentLeaderboardsComponent implements OnInit, OnDestroy {

  activeLeaderboards: ILeaderboard[];
  inactiveLeaderboards: ILeaderboard[];
  isLoadingLeaderboards:boolean = true;

  currentDistrict = 'cambridge';
  currentClassType = 'stage_6';

  routeDataSub:Subscription;
  routeData:{isTeacher?:boolean} = {};

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private ls: LeaderboardService,
    private router: Router,
    private foreign: ForeignUserService,
  ) { }

  ngOnInit() {
    this.routeDataSub = this.route.data.subscribe(v => this.routeData = v );
    this.route.params.subscribe(params => {
      if (this.isTeacher()) {
        this.sidePanel.activate();
        this.chatPanel.activate();
      } else {
        this.sidePanel.deactivate();
        this.chatPanel.deactivate();
      }

      if (params['foreignClassId']) {
        let foreignClassId = params['foreignClassId'];
        let foreignClassType = params['foreignClassType'];
        let foreignUid = params['foreignUid'];
        let district = params['district'];
        let displayName = params['displayName'];

        this.currentClassType = foreignClassType;
        this.currentDistrict = district;

        this.foreign.setForeignUserData({
          foreignClassId,
          foreignUid,
          foreignClassType,
          district,
          displayName,
        });
        this.foreign.foreignLogin(this.foreign.getForeignUserData()).then(uid => {
          this.afterSignin();
        });
      }
      else if (this.foreign.isForeign()) {
        let foreignData = this.foreign.getForeignUserData();
        this.currentClassType = foreignData.foreignClassType;
        this.currentDistrict = foreignData.district;
        this.afterSignin();
      } else if (params['district']) {
        let district = params['district'];
        let classType = params['classType'] || 'stage_6';
        let classId = params['classroomId'];
        
        this.currentDistrict = district;
        this.currentClassType = classType;
        this.foreign.setForeignUserData({
          foreignClassType: classType,
          district,
          foreignClassId: classId,
          displayName: this.auth.getDisplayName()
        });
        this.afterSignin();
      } else {
        this.afterSignin();
      }
    });
  }

  ngOnDestroy(){
    clearInterval(this.watchForOpenInterval);
  }


  afterSignin = () => {
    console.log('signed in', this.currentDistrict, this.currentClassType);
    let initialized:boolean;
    const signinSub = this.auth.user.subscribe(user => {
      if (!initialized){
        initialized = true;
        this.loadLeaderboards();
      }
      else{
        signinSub.unsubscribe();
      }
    });
  }

  loadLeaderboards(){
    this.ls
      .getLeaderboardsByDistrictAndClassType(this.currentDistrict, this.currentClassType)
      .then(leaderboards => {
        let currentTimestamp = moment().unix();
        this.activeLeaderboards = leaderboards.filter(leaderboard => {
          if (leaderboard.isActive && !leaderboard.isArchived) {
            if (leaderboard.dateClosed && leaderboard.dateClosed.seconds && currentTimestamp > leaderboard.dateClosed.seconds) {
              return false;
            }
            return true;
          }
          return false;
        });
        this.inactiveLeaderboards = leaderboards.filter(leaderboard => {
          if (!leaderboard.isActive && !leaderboard.isArchived) {
            return true;
          }
          if (leaderboard.dateClosed && leaderboard.dateClosed.seconds && currentTimestamp > leaderboard.dateClosed.seconds) {
            return true;
          }
          return false;
        });
        
        this.applyCountdownDateProp(this.activeLeaderboards);
        this.activeLeaderboards = this.sortLeaderboardsByDate(this.activeLeaderboards); // uses countdown prop for efficiency...
        this.checkForOpen();
        this.watchForOpenInterval = setInterval( ()=> this.checkForOpen(), 3000);
        this.isLoadingLeaderboards = false;
      });
  }

  applyCountdownDateProp(leaderboards:ILeaderboard[]){
    leaderboards.forEach(leaderboard => {
      // console.log('leaderboard dateOpened', JSON.stringify(leaderboard.dateOpened));
      if (leaderboard.dateOpened && leaderboard.dateOpened.seconds){
        leaderboard._dateOpened_countdown = moment(new Date(leaderboard.dateOpened.seconds*1000)).format('YYYY-MM-DD HH:mm');
      }
      if (leaderboard.dateClosed && leaderboard.dateClosed.seconds){
        leaderboard._dateClosed_countdown = moment(new Date(leaderboard.dateClosed.seconds*1000)).format('YYYY-MM-DD HH:mm');
      }
    })
  }
  sortLeaderboardsByDate(leaderboards:ILeaderboard[]){
    return _.sortBy(leaderboards, leaderboard => {
      if (leaderboard._dateClosed_countdown) {
        // open leaderboards
        return 'b' + leaderboard._dateClosed_countdown + leaderboard.name;
      } else if (leaderboard._dateOpened_countdown) {
        // pending leaderboards
        return 'c' + leaderboard._dateOpened_countdown + leaderboard.name;
      } else {
        // undated leaderboards go first
        return 'a' + leaderboard.name;
      }
    });
  }

  isTeacher(){
    return this.routeData.isTeacher;
  }

  watchForOpenInterval;
  checkForOpen(){
    let currentTimestamp = moment().unix();
    this.activeLeaderboards.forEach(leaderboard => {
      if (leaderboard.dateOpened && leaderboard.dateOpened.seconds){
        if (currentTimestamp > leaderboard.dateOpened.seconds){
          leaderboard._isOpened = true;
        }
      } else if (!leaderboard.dateOpened) {
        leaderboard._isOpened = true;
      }
      if (leaderboard.dateClosed && leaderboard.dateClosed.seconds){
        if (currentTimestamp > leaderboard.dateClosed.seconds){
          leaderboard._isClosed = true;
        }
      }
    });
  }

  goToLeaderboard(leaderboard: ILeaderboard) {
    if (this.isTeacher()){
      let classroomId = this.foreign.getForeignUserData().foreignClassId;
      console.log('classroomId', this.foreign.getForeignUserData(), classroomId);
      if (classroomId) {
        this.router.navigate([
          'teacher',
          'classroom',
          classroomId,
          'leaderboard',
          leaderboard.__id,
        ]);
      } else {
        this.router.navigate([
          'teacher',
          'leaderboard',
          leaderboard.__id,
        ]);
      }
    }
    else{
      this.router.navigate([
        'student',
        'leaderboard',
        leaderboard.__id,
      ]);
    }
  }

  goBackToDashboard() {
    this.router.navigate([
      'student',
      'dashboard'
    ]);
  }

  hasCurrentLeaderboards(){
    return this.activeLeaderboards && this.activeLeaderboards.length > 0;
  }

  hasPastLeaderboards(){
    return this.inactiveLeaderboards && this.inactiveLeaderboards.length > 0;
  }

  clickUnopenedLeaderboard(){
    alert('This Challenge is not yet open!')
  }

}
