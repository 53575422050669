import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ClassroomService } from '../classroom.service';
import { UsersService } from '../../core/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { IClassroom } from '../../data/collections/classrooms.types';
import { IClassroomStudent } from '../teacher-classroom-students/teacher-classroom-students.component';
import { IUser } from '../../data/collections/users.types';
import { ensureFirstLastName } from '../../data/name-split';
import { SORT_NAMES_ASC } from '../../data/sort/names';
import { renderUsernameFromEmail } from '../../data/accounts/pseudo-email-usernames';
@Component({
  selector: 'teacher-classroom-students-print',
  templateUrl: './teacher-classroom-students-print.component.html',
  styleUrls: ['./teacher-classroom-students-print.component.scss']
})
export class TeacherClassroomStudentsPrintComponent implements OnInit {

  public activeStudents: IClassroomStudent[] = [];
  public classConfig: IClassroom = { name: '' };
  public classroomId: string;
  public isClassroomInfoLoaded: boolean;
  private studentPasswords:Map<string, string> = new Map();
  private timestamp:string;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private classroomService: ClassroomService,
    private userSiteContext: UserSiteContextService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId);
    });
    this.timestamp = (new Date()).toLocaleDateString();
  }

  loadClassroom(classroomId: string) {
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        this.isClassroomInfoLoaded = true;
        this.loadStudents(this.classConfig.currentStudents);
      });
    this.classroomService
      .getClassroomPasswords(classroomId)
      .then(passwordEntries=>{
        passwordEntries.forEach(entry => {
          this.studentPasswords.set(entry.uid, entry.password)
        })
      })
  }

  getCurrentDate(){
    return this.timestamp;
  }

  loadStudents(studentIds: string[]) {
    if (this.activeStudents && this.activeStudents.length > 0) {
      console.warn('Reloading student accounts...');
    }
    this.activeStudents = [];
    if (studentIds) {
      studentIds.forEach(uid => {
        let studentPacket: IClassroomStudent = {
          uid,
          displayName: '',
          firstName: '',
          lastName: '',
          username: '',
        }
        this.activeStudents.push(studentPacket);
        this.usersService
          .getUserInfo(uid)
          .subscribe((userInfo:IUser)=>{
            ensureFirstLastName(userInfo);
            studentPacket.displayName = userInfo.displayName;
            studentPacket.firstName = userInfo.firstName;
            studentPacket.lastName = userInfo.lastName;
            studentPacket.username = renderUsernameFromEmail(userInfo.email);
            this.applyStudentSorting(); 
          })
      });
    }
  }

  triggerPrint(){
    window.print();
  }
  
  getStudentPassword(student:IClassroomStudent){
    let password;
    if(this.studentPasswords.has(student.uid)){
      password = this.studentPasswords.get(student.uid);
    }
    return this.usersService.parseStudentPassword(password);
  }

  applyStudentSorting = _.throttle(() => {
    this.activeStudents = this.activeStudents.sort(SORT_NAMES_ASC);
  }, 500)

}
