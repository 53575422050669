import * as _ from 'lodash';
import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { AvatarBubbles } from '../util/AvatarBubbles';
import { IHeadsUpLobbyPlayers, IHeadsUpLobbyPlayerActions, AvatarPlayerActions, IStudentBubble } from 'src/app/data/collections/heads-up-lobby';
import { IFDGElement } from '../util/FDG/FDGInterfaces';
import { isLoadedBody, ILoadedBody, ILoadedCosmeticsEquipped } from '../util/DragonAvatar/DragonInterfaces';

@Component({
  selector: 'avatar-podium',
  templateUrl: './avatar-podium.component.html',
  styleUrls: ['./avatar-podium.component.scss'],
})
export class AvatarPodiumComponent /*implements OnInit, OnChanges*/ {
  // @Input() players: Array<IHeadsUpLobbyPlayers>;
  // @Input() actions: Array<IHeadsUpLobbyPlayerActions>;
  // @Input() update: number;
  // @Input() cosmeticsAdded: ILoadedCosmeticsEquipped[];

  // @Output('fetchCosmetics') fetchCosmetics = new EventEmitter<string>();

  // @ViewChild('pixiViewport') pixiViewport: ElementRef;
  // parentElement: any;

  // playersLoaded = [false, false, false];
  // pendingPlayers: IHeadsUpLobbyPlayers[] = [];

  // bubbleRender: AvatarBubbles;

  // private studentBubbles: IStudentBubble[] = [];

  // initialized = false;

  // constructor(
  // ) {
  //   // window.addEventListener('keydown', e => {
  //   //   if (e.key === 'e') {
  //   //     this.players.push({
  //   //       assignmentId: 'assignmentId',
  //   //       uid: 'uid',
  //   //       isPresent: true,
  //   //       character: BaseICharacter
  //   //     });

  //   //     this.ngOnChanges();
  //   //   }
  //   // });
  // }

  // ngOnInit() {
  //   const div = this.pixiViewport.nativeElement;
  //   this.parentElement = div.closest('div avatar-podium');
  //   this.bubbleRender = new AvatarBubbles(div, { width: this.parentElement.offsetWidth, height: this.parentElement.offsetHeight }, { width: 800, height: 500 });

  //   window.addEventListener('resize', this.onResize);
  //   this.initialized = true;
  //   this.ngOnChanges();

  //   this.bubbleRender.pixi.app.ticker.add(this.orbDrift);
  // }

  // ngOnChanges(changes?: SimpleChanges) {
  //   // console.log('CHANGE!', changes);
  //   if (!this.initialized) {
  //     return;
  //   }

  //   for (let i = 0; i < this.playersLoaded.length; i++) {
  //     if (!this.playersLoaded[i]) {
  //       if (this.players[i]) {
  //         this.setupNewAvatar(this.players[i].uid, i);
  //         this.playersLoaded[i] = true;
  //         this.pendingPlayers.push(this.players[i]);
  //       }
  //     }
  //   }

  //   if (this.pendingPlayers.length > 0) {
  //     for (let i = 0; i < this.pendingPlayers.length; i++) {
  //       let cosmetics = _.filter(this.cosmeticsAdded, { uid: this.pendingPlayers[i].uid });
  //       if (cosmetics.length > 0) {
  //         if (this.finishNewAvatar(this.pendingPlayers[i].uid, cosmetics, _.indexOf(this.players, this.pendingPlayers[i]))) {
  //           this.pendingPlayers.splice(i, 1);
  //           i--;
  //         }
  //       }
  //     }
  //   }

  //   // if (this.cosmeticsAdded) {
  //   //   for (let i = 0; i < this.cosmeticsAdded.length; i++) {
  //   //     let cosmetic = this.cosmeticsAdded[i];
  //   //     let bubble = _.find(this.studentBubbles, { uid: cosmetic.uid });
  //   //     if (bubble && bubble.avatar) {
  //   //       if (!isLoadedBody(cosmetic.data)) {
  //   //         bubble.avatar.addCosmetic(cosmetic.data);
  //   //       }
  //   //       this.cosmeticsAdded.splice(i, 1);
  //   //       i--;
  //   //     }
  //   //   }
  //   // }

  //   while (this.actions.length > 0) {
  //     let action = this.actions.shift();
  //     let playerInfo = _.find(this.players, { uid: action.uid });
  //     if (playerInfo) {
  //       if (action.actionId === AvatarPlayerActions.MOVE) {
  //         this.runMoveCommand(playerInfo, action.details);
  //       }
  //     } else {
  //       console.error('NO USER CONNECTED TO ACTION', action);
  //     }
  //   }
  // }

  // onResize = (e) => {
  //   this.bubbleRender.pixi.resize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
  // }

  // setupNewAvatar(uid: string, tier: number) {
  //   // this.studentBubbles.push({ uid });
  //   // this.fetchCosmetics.emit(uid);
  // }

  // finishNewAvatar(uid: string, chunks: any[], tier: number): boolean {
  //   let config: IFDGElement;

  //   // CURRENT CANVAS = 800 x 500
  //   switch (tier) {
  //     case 0:
  //       config = {
  //         radius: 100,
  //         color: 0xc9b037,
  //         x: 400,
  //         y: 150
  //       };
  //       break;
  //     case 1:
  //       config = {
  //         radius: 75,
  //         color: 0xd7d7d7,
  //         x: 200,
  //         y: 350
  //       };
  //       break;
  //     case 2:
  //       config = {
  //         radius: 50,
  //         color: 0xAD8A56,
  //         x: 600,
  //         y: 350
  //       };
  //       break;
  //   }

  //   let start = { x: config.x, y: config.y };

  //   let body = _.find(chunks, el => isLoadedBody(el.data));
  //   if (body) {
  //     let cosmetics = _.map(_.without(chunks, body), el => el.data);

  //     let data = this.bubbleRender.addCharacterBubble(body.data as ILoadedBody, cosmetics as any, config);

  //     let bubble = _.find(this.studentBubbles, { uid });
  //     bubble.node = data.bubble;
  //     bubble.avatar = data.avatar;
  //     bubble.start = start;
  //     return true;
  //   }
  //   return false;



  //   // let avatar = this.bubbleRender.addCharacterBubble(genNakedCosmetics(uid), config, node => {
  //   //   this.studentBubbles.push({ node, avatar, uid, start });
  //   //   this.ngOnChanges();
  //   //   this.fetchCosmetics.emit(uid);
  //   // });

  //   // return playerInfo;
  // }

  // orbDrift = () => {
  //   this.studentBubbles.forEach((bubble, index) => {
  //     if (bubble.start) {
  //       let dX = bubble.start.x - bubble.node.x;
  //       let dY = bubble.start.y - bubble.node.y;
  //       bubble.node.vX += dX * 0.001;
  //       bubble.node.vY += dY * 0.001;
  //     }
  //   });
  // }

  // runMoveCommand(playerInfo: IHeadsUpLobbyPlayers, vel: { x: number, y: number }) {
  //   let bubble = _.find(this.studentBubbles, { uid: playerInfo.uid });
  //   if (bubble) {
  //     this.bubbleRender.applyForceToBubble(bubble.node, vel.x, vel.y);
  //   } else {
  //     // console.error('NO BUBBLE!', this.studentBubbles, playerInfo);
  //   }
  // }
}
