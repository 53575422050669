import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';
import { ICurriculumOutcome } from '../../data/collections/curriculum-outcomes.types';

@Component({
  selector: 'form-student-selector',
  templateUrl: './form-student-selector.component.html',
  styleUrls: ['./form-student-selector.component.scss']
})
export class FormStudentSelectorComponent implements OnInit {

  orgSearchString = new FormControl('');
  activeOrg = new FormControl('');
  teacherSearchString = new FormControl('');
  activeTeacher = new FormControl('');
  studentSearchString = new FormControl('');
  activeStudent = new FormControl('');

  constructor() { }

  ngOnInit() {
  }

}
