import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { IContentElementInput, InputFormat } from '../models';
import { FormControl, FormArray } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';



@Component({
  selector: 'element-config-input',
  templateUrl: './element-config-input.component.html',
  styleUrls: ['./element-config-input.component.scss']
})
export class ElementConfigInputComponent implements OnInit {

  @Input() element:IContentElementInput;
  @ViewChild('mathField') mathFieldRef: ElementRef;

  MQ = null;
  mathField;

  inputFormats = [
    {id: InputFormat.NUMBER,   icon:'fa-calculator', caption:'Number'},
    {id: InputFormat.FRACTION, icon:'fa-pencil',     caption:'Fraction'},
    {id: InputFormat.RATIO,    icon:'fa-pie-chart',  caption:'Ratio'},
    {id: InputFormat.ALGEBRA,  icon:'fa-pencil',     caption:'Algebra'},
    {id: InputFormat.TEXT,     icon:'fa-font',       caption:'Short Answer'},
  ]

  isShowAdvancedOptions = new FormControl(false);
  value = new FormControl();
  roundingTolerance = new FormControl();
  isMixedNumber = new FormControl();
  fracWholeNumber = new FormControl();
  fracNumerator = new FormControl();
  fracDenominator = new FormControl();
  isStrictSimplified = new FormControl();
  ratioTerms = new FormArray([]);
  minChars = new FormControl();
  maxChars = new FormControl();
  prefix = new FormControl();
  suffix = new FormControl();


  constructor() { }

  ngOnInit() {
    bindFormControls(this.element, [
      {f: this.prefix,   p: 'prefix'}, 
      {f: this.suffix,   p: 'suffix'}, 
      {f: this.isShowAdvancedOptions, p: 'isShowAdvancedOptions'},
      {f: this.value,   p: 'value'}, 
      {f: this.roundingTolerance,   p: 'roundingTolerance'}, 
      {f: this.isMixedNumber,   p: 'isMixedNumber'}, 
      {f: this.fracWholeNumber,   p: 'fracWholeNumber'}, 
      {f: this.fracNumerator,   p: 'fracNumerator'}, 
      {f: this.fracDenominator,   p: 'fracDenominator'}, 
      {f: this.isStrictSimplified,   p: 'isStrictSimplified'}, 
      {f: this.ratioTerms,   p: 'ratioTerms', isFormArray:true}, 
      {f: this.minChars,   p: 'minChars'}, 
      {f: this.maxChars,   p: 'maxChars'}, 
    ])
  }

  selectInputFormat(inputFormatId:InputFormat){
    this.element.format = inputFormatId;
  }

  isFormatActive(inputFormatId:InputFormat){
    return inputFormatId === this.element.format;
  }

  isFormatTypeNumber(){ return this.element.format === InputFormat.NUMBER }
  isFormatTypeFraction(){ return this.element.format === InputFormat.FRACTION }
  isFormatTypeAlgebra(){ return this.element.format === InputFormat.ALGEBRA }
  isFormatTypeRatio(){ return this.element.format === InputFormat.RATIO }
  isFormatTypeText(){ return this.element.format === InputFormat.TEXT }

  addRatioTerm(){
    this.ratioTerms.push(new FormControl());
  }

  removeRatioTerm(){
    this.ratioTerms.removeAt(this.ratioTerms.length -1);
  }

}
