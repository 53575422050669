import { Injectable } from '@angular/core';

export enum UserContextProperty {
  ACTIVE_CLASSROOM = ':classroomId',
}

@Injectable({
  providedIn: 'root'
})
export class UserSiteContextService {

  contextState:Map<UserContextProperty, string> = new Map();

  constructor() {
    this.resetContextState()
  }

  resetContextState(){
    this.contextState = new Map();
    this.setActiveClassroom(null)
  }

  processRoutePart(str:string) : string {
    const DEFAULT = '_';
    if (!str){
      return DEFAULT;
    }
    if (this.contextState.has(<UserContextProperty>str)){
      const contextValue = this.contextState.get(<UserContextProperty>str);
      if (!contextValue){
        return DEFAULT;
      }
      return contextValue;
    }
    return str;
  }

  handleClassroomRouteParam(classroomId:string){
    if (!classroomId || classroomId === '_' || classroomId === 'NONE'){
      return null;
    }
    else{
      this.setActiveClassroom(classroomId);
      return classroomId;
    }
  }

  setActiveClassroom(classroomId:string){
    // console.log('setActiveClassroom', classroomId)
    this.contextState.set(UserContextProperty.ACTIVE_CLASSROOM, classroomId);
  }
  
  hasActiveClassroom(){
    if (this.contextState.get(UserContextProperty.ACTIVE_CLASSROOM)){
      return true;
    }
    return false;
  }

}
