import * as _ from 'lodash';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { IItemAttemptSession, ItemTypes } from '../../ui-student/boosterpacks.service';
import { CollectionReference } from '@firebase/firestore-types';
import { ReplaySubject, Subscription } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { UsersService } from '../../core/users.service';
import { ensureFirstLastName } from '../../data/name-split';
import { BoosterService } from '../../core/booster.service';
import { IBoosterPack, IBoosterPackItem } from '../../data/cambridge/types';

export interface IBoosterActivityLogFilterFlags {
  isComplete?:boolean | null,
  studentUID?: string | null,
  boosterPackId?: string | null,
  boosterPackItemId?: string | null,
  timeStart?: number | null,
  timeEnd?: number | null,
}

interface ILogEntry {
  studentDisplayName: ReplaySubject<string>,
  studentDisplayInitial: ReplaySubject<string>,
  boosterPack: ReplaySubject<string>,
  boosterPackItem: ReplaySubject<string>,
  timeSpent:number,
  scorePerc:number,
  boosterPackItemId:string,
  isCheckpoint:boolean,
  isComplete:boolean
}

@Component({
  selector: 'boosterpack-activity-log',
  templateUrl: './boosterpack-activity-log.component.html',
  styleUrls: ['./boosterpack-activity-log.component.scss']
})
export class BoosterpackActivityLogComponent implements OnInit, OnChanges {

  @Input() classroomId:string;
  @Input() maxEntries:number = 5;

  logEntries:ILogEntry[] = [];

  filterFlags:IBoosterActivityLogFilterFlags = {
    isComplete: null,
    studentUID: null,
    boosterPackId: null,
    boosterPackItemId: null,
    timeStart: null,
    timeEnd: null,
   }

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private usersService: UsersService,
    private boosterService: BoosterService,
  ) { }

  ngOnInit() {
    this.fetchClassData();  
  }

  ngOnChanges(changes: SimpleChanges){
    _.each(changes, (change, prop) => {
      if (prop === 'classroomId'){
        this.fetchClassData();  
      }
    });
  }

  itemAttemptSessionSub:Subscription;
  fetchClassData(){
    if (this.itemAttemptSessionSub){
      this.itemAttemptSessionSub.unsubscribe();
    }
    this.logEntries = [];
    this.itemAttemptSessionSub = this.afs
      .collection<IItemAttemptSession>('boosterPacksItemAttemptSession', ref=> this.queryFilter(ref) )
      .snapshotChanges()
      .subscribe( results => {
        // compute time spent
        // console.log('sessions', results); // load item info as required
        this.logEntries = this.sanitizeEntries(results.map(entry => {
          const doc = entry.payload.doc;
          return {
            __id: doc.id, 
            ...doc.data()
          }
        }));
      })
  }

  sanitizeEntries(results:IItemAttemptSession[]){
    return <ILogEntry[]>results.map(entry => {
      let timeSpent = 0;
      if (entry.timeEnd && entry.timeStart){
        timeSpent = Math.round((entry.timeEnd.seconds - entry.timeStart.seconds)/60); // minutes
      }
      let isCheckpoint = false;
      if (entry.resolvedCheckpoints && entry.resolvedCheckpoints.length > 0){
        isCheckpoint = true;
      }
      const studentName = this.loadStudentInfo(entry.uid);
      return <ILogEntry>{
        __id: entry.__id,
        studentDisplayInitial: studentName.subjectInitial,
        studentDisplayName: studentName.subjectName,
        scorePerc: entry.score,
        timeSpent,
        isComplete: entry.isComplete,
        isCheckpoint,
        boosterPack: this.loadBoosterPackName(entry.boosterPackId),
        boosterPackItemId:entry.boosterPackItemId,
        boosterPackItem: this.loadBoosterPackItemName(entry.boosterPackItemId),
        // score: entry.score,
      }
    })
  }
  
  renderItemIconClasses(boosterPackItemId:string, scorePerc:number){
    const classes = [];
    const itemType = this.boosterPackColors.get(boosterPackItemId) || ItemTypes.red;
    switch(itemType){
      case ItemTypes.red:   
      case ItemTypes.medal:
        classes.push('is-item-red'); break; 
      case ItemTypes.black: classes.push('is-item-black'); break; 
      case ItemTypes.blue:  classes.push('is-item-blue'); break; 
    }
    if (scorePerc){
      const score = this.boosterService.processItemScore(itemType, scorePerc);
      
      if (score.hasMedal) {
        classes.push('is-has-medal');
      } else {
        switch(score.numStars){
          case 3: classes.push('is-3-stars'); break;
          case 2: classes.push('is-2-stars'); break;
          case 1: classes.push('is-1-stars'); break;
          default:
          case 0: classes.push('is-0-stars'); break;
        }
      }
    }
    else{
      classes.push('is-0-stars');
    }
    return classes;
  }

  boosterPackColors:Map<string, ItemTypes> = new Map();

  loadBoosterPackName(boosterPackId:string){
    let subject:ReplaySubject<string> = new ReplaySubject();
    // subject.next('boosterPack'); // temp
    this.boosterService
      .getBoosterPackInfo(boosterPackId)
      .subscribe((boosterPackInfo:IBoosterPack)=>{
        subject.next(boosterPackInfo.caption)
      })
    return subject;
  }

  loadBoosterPackItemName(boosterPackItemId:string){
    let subject:ReplaySubject<string> = new ReplaySubject();
    // subject.next('boosterPack'); // temp
    this.boosterService
      .getBoosterPackItemInfo(boosterPackItemId)
      .subscribe((boosterPackItemInfo:IBoosterPackItem)=>{
        this.boosterPackColors.set(boosterPackItemId, <ItemTypes>boosterPackItemInfo.itemType);
        subject.next(boosterPackItemInfo.name)
      })
    return subject;
  }

  loadStudentInfo(uid:string){
    let subjectName:ReplaySubject<string> = new ReplaySubject();
    let subjectInitial:ReplaySubject<string> = new ReplaySubject();
    this.usersService
      .getUserInfo(uid)
      .subscribe((userInfo:IUser)=>{
        ensureFirstLastName(userInfo);
        const name = userInfo.displayName;
        console.log('userInfo.lastName', userInfo.lastName)
        const initial = (userInfo.firstName.charAt(0) + userInfo.lastName.charAt(0)).toUpperCase();
        subjectName.next(name);
        subjectInitial.next(initial);
      })
    return {subjectName, subjectInitial};
  }

  queryFilter(ref:CollectionReference){
    let res = ref.where("classroomIds", "array-contains", this.classroomId);
    if(this.filterFlags.isComplete !== null){
      res = res.where('isComplete', '==', this.filterFlags.isComplete);
    }
    if(this.filterFlags.studentUID !== null){
      res = res.where('studentUID', '==', this.filterFlags.studentUID);
    }
    if(this.filterFlags.boosterPackId !== null){
      res = res.where('boosterPackId', '==', this.filterFlags.boosterPackId);
    }
    if(this.filterFlags.boosterPackItemId !== null){
      res = res.where('boosterPackItemId', '==', this.filterFlags.boosterPackItemId);
    }
    if(this.filterFlags.timeStart !== null){
      res = res.where('timeStart', '>=', this.filterFlags.timeStart);
    }
    if(this.filterFlags.timeEnd !== null){
      res = res.where('timeEnd', '<=', this.filterFlags.timeEnd);
    }
    res = res.orderBy('timeEnd', 'desc');
    return res.limit(8)
  }

}
