import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CollectionsService } from '../../data/collections.service';
import { IAssignment } from '../../data/collections/assignments.types';
import { Observable, Subscription } from 'rxjs';
import { ITask } from '../../data/collections/tasks.types';
import { serverTimestamp } from '../../data/timestamp';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { IAssignmentSubmission } from '../../data/collections/assignment-submissions.types';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'panel-student-assignments',
  templateUrl: './panel-student-assignments.component.html',
  styleUrls: ['./panel-student-assignments.component.scss']
})
export class PanelStudentAssignmentsComponent implements OnInit , OnDestroy {

  @Input() classroomIds:string[];

  assignments: IAssignment[] = [];

  private assessmentRef = new Map();
  private assignmentsCollection: AngularFirestoreCollection<IAssignment>;
  private routeSub:Subscription;
  private assignmentSub:Subscription;
  private asmtSubmStateSub:Subscription;
  private asmtSubmListeners:Map<string, {isLoading:boolean, isStarted:boolean, isSubmitted:boolean, subscription:any}>  = new Map();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { 

  }

  ngOnInit() {
    if (this.classroomIds){
      this.classroomIds.forEach(classroomId => {
        this.loadAssignments(classroomId);
      })
    }
  }

  ngOnDestroy() {
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.assignmentSub){ this.assignmentSub.unsubscribe(); }
    if (this.asmtSubmStateSub){ this.asmtSubmStateSub.unsubscribe(); }
  }

  loadAssignments(classroomId:string){
    this.assignmentsCollection = this.afs.collection<IAssignment>('assignments', ref => ref
      .where('classroom', '==', classroomId)
      .where('isArchived', '==', false)
    );
    this.assignmentSub = CollectionsService.getValuesIdentified(this.assignmentsCollection).subscribe(assignments => {
      assignments.forEach(assignment => {
        if (!this.assessmentRef.has(assignment.__id)){
          this.assessmentRef.set(assignment.__id, assignment);
          this.assignments.push(assignment);
          this.initializeAssignmentSubmissionListener(assignment);
        }
        else{
          Object.assign(this.assessmentRef.get(assignment.__id), assignment);
        }
        assignment.timeClose = assignment.timeClose || assignment.timeDirectClose || assignment.timeModified;
      })
    })
  }


  // a submission entry is created as soon as the assessment is started
  initializeAssignmentSubmissionListener(assignment:IAssignment){
    let assignmentId = assignment.__id;
    let taskId = assignment.tasks[0];
    if (!this.asmtSubmListeners.has(assignmentId)){
      let asmtSubmState = {
        isLoading: true,
        isStarted: false,
        isSubmitted: false,
        attemptCount: null,
        subscription:null
      };  
      this.asmtSubmListeners.set(assignmentId, asmtSubmState);
      let assignmentSubmissions = this.afs.collection<IAssignmentSubmission>('assignmentSubmissions', ref => {
        return ref
          .where('isCurrent', '==', true)
          .where('uid', '==', this.auth.getUid())
          .where('assignmentId', '==', assignmentId)
          .where('taskId', '==', taskId)
          .limit(5)
      });
      this.asmtSubmStateSub =  asmtSubmState.subscription = CollectionsService.getValuesIdentified(assignmentSubmissions).subscribe(val => {
        asmtSubmState.isLoading = false;
        if (val && val.length > 0){
          let currentSubmission:IAssignmentSubmission = val[0];
          asmtSubmState.isStarted = true;
          asmtSubmState.isSubmitted = currentSubmission.isSubmitted
          asmtSubmState.attemptCount = currentSubmission.attemptCount
        }
      })
    }
  }

  getAssignmentAccessState(assignment:IAssignment){
    let assignmentId = assignment.__id;
    let asmtSubmState = this.asmtSubmListeners.get(assignmentId);
    if (asmtSubmState.isLoading){
      return 'LOADING'
    }
    else if (asmtSubmState.isSubmitted){
      // to do: check if it is a repeatable assessment right here
      // else:
      return 'COMPLETED'
    }
    else {
      if (this.checkAssignmentOpen(assignment)){
        if (assignment.isDirectClose){
          return 'CLOSED'
        }
        else if (asmtSubmState.isStarted){
          return 'CONTINUE'
        } 
        else {
          return 'START'
        }
      }
      else{
        return 'UNOPENED'
      }
    }
  }

  checkAssignmentOpen(assignment:IAssignment){
    return assignment.isDirectStart || this.checkAssignmentOpenDateRange(assignment);
  }
  checkAssignmentOpenDateRange(assignment:IAssignment){
    return false;
  }

}
