import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';
import { ICurriculumDefinition } from '../../data/collections/curriculum-definitions.types';
import { ICurriculumOutcome } from '../../data/collections/curriculum-outcomes.types';
import { AuthService } from '../../core/auth.service';

interface ICurriculumCategory {
  name: string;
  subCategories: ICurriculumSubCategory[];
}
interface ICurriculumSubCategory {
  name: string;
  outcomes: ICurriculumOutcomeRef[];
}
interface ICurriculumOutcomeRef {
  name: string;
  id: string;
}


@Component({
  selector: 'form-curriculum-editor',
  templateUrl: './form-curriculum-editor.component.html',
  styleUrls: ['./form-curriculum-editor.component.scss']
})
export class FormCurriculumEditorComponent implements OnInit, OnDestroy {

  activeCurriculums = new FormControl(null);
  name = new FormControl('');
  tags = new FormControl('');
  isArchiveShown = new FormControl(false);
  curriculumSearchString = new FormControl('');
  activeCurriculumCategories = new FormControl(null);
  activeCurriculumSubCategories = new FormControl(null);
  activeCurriculumOutcomes = new FormControl(null);
  newOutcomeCode = new FormControl('');
  newOutcomeDescription = new FormControl('');
  isCreatingNewOutcome:boolean = false;
  isEditingOutcome:boolean = false;
  currentFormMode:FormMode = FormMode.NONE;
  editedCurriculum:ICurriculum;
  editedCurriculumCategories:ICurriculumCategory[];
  currentListMode:IListMode;
  curriculums$: Observable<ICurriculum[]>;
  curriculumOutcome$: Observable<ICurriculumOutcome>;
  curriculumDefinition$: Observable<ICurriculumDefinition>;

  private curricCategSub:Subscription;
  private curricSubCategSub:Subscription;
  private curricOutcomesSub:Subscription;
  private curriculumCollection: AngularFirestoreCollection<ICurriculum>;
  private curriculumOutcomeLookup: AngularFirestoreDocument<ICurriculumOutcome>;
  private curriculumDefinitionLookup: AngularFirestoreDocument<ICurriculumDefinition>;

  listModes:IListMode[] = [
    {slug:ListMode.ACTIVE, caption:'Active', isActive:true},
    {slug:ListMode.ARCHIVED, caption:'Archived'}
  ]

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {
    this.currentListMode = this.listModes[0];
  }

  ngOnInit() {
    this.curriculumCollection = this.afs.collection<ICurriculum>('curriculums');
    this.curriculums$ = CollectionsService.getValuesIdentified(this.curriculumCollection);
    this.newOutcomeCode.disable();
    this.newOutcomeDescription.disable();
    this.curricCategSub = this.activeCurriculumCategories.valueChanges.subscribe( () => {
      this.activeCurriculumSubCategories.reset();
      this.activeCurriculumOutcomes.reset();
    })
    this.curricSubCategSub = this.activeCurriculumSubCategories.valueChanges.subscribe( () => {
      this.activeCurriculumOutcomes.reset();
    })
    this.curricOutcomesSub = this.activeCurriculumOutcomes.valueChanges.subscribe( () => {
      // 
    })
  }

  ngOnDestroy() {
    this.curricCategSub.unsubscribe();
    this.curricSubCategSub.unsubscribe();
    this.curricOutcomesSub.unsubscribe();
  }

  isNotEditing(){
    return this.currentFormMode === FormMode.NONE
  }

  archiveActiveCurriculum(){
    let id = this.getActiveCurriculumId();
    this.curriculumCollection.doc(id).update({ 
      isArchived: true
    });
  }

  selectListMode(listMode:IListMode){
    this.currentListMode.isActive = false;
    this.currentListMode = listMode;
    this.currentListMode.isActive = true;
  }

  isShowingArchivedCurriculums(){
    return this.currentListMode.slug === ListMode.ARCHIVED;
  }

  restoreActiveCurriculum(){
    let id = this.getActiveCurriculumId();
    this.curriculumCollection.doc(id).update({ 
      isArchived: false
    });
  }

  discardActiveCurriculum(){
    if (window.confirm('Delete forever? (cannot undo)')){
      let id = this.getActiveCurriculumId();
      this.curriculumCollection.doc(id).delete();
      this.activeCurriculums.reset();  
    }
  }

  curricEditStart(){
    this.editedCurriculum = this.getActiveCurriculum();
    if (this.editedCurriculum){
      this.name.setValue(this.editedCurriculum.name);
      this.tags.setValue(this.editedCurriculum.tags);
      this.editedCurriculumCategories = []; // need to fetch it from a database
      // this.loadCurriculumDefinitionForEditing()
      this.currentFormMode = FormMode.EDIT;
    }
    this.activeCurriculums.disable();
    this.loadCurriculumDefinitionForEditing();
  }

  curricNewStart(){
    this.currentFormMode = FormMode.NEW;
    this.activeCurriculums.reset();
    this.activeCurriculums.disable();
    this.loadCurriculumDefinitionForEditing();
  }
  curricEditCancel(){
    this.resetForm();
  }
  curricNewCancel(){
    this.resetForm();
  }
  resetForm(){
    this.name.reset();
    this.tags.reset();
    this.activeCurriculums.reset()
    this.activeCurriculumCategories.reset()
    this.activeCurriculumSubCategories.reset()
    this.activeCurriculumOutcomes.reset()
    this.currentFormMode = FormMode.NONE;
    this.activeCurriculums.enable();
  }

  outcomeEditStart(){
    this.isEditingOutcome = true;
    
    // this.curriculumOutcomeLookup = this.afs.doc<ICurriculumOutcome>('curriculumDefinitions/'+curriculumId);
    // this.curriculumDefinition$ = this.curriculumDefinitionLookup.valueChanges();
    // let isLoaded = false;
    // let sub = this.curriculumDefinition$.subscribe( (res) => {
    //   if (res){
    //     this.editedCurriculumCategories = JSON.parse(res.data);
    //   }
    //   else{
    //     this.editedCurriculumCategories = [];
    //   }
    //   console.log('loadCurriculumDefinitionForEditing', curriculumId, this.editedCurriculumCategories);
    //   sub.unsubscribe();
    // });
    this.newOutcomeCode.enable();
    this.newOutcomeDescription.enable();
  }
  outcomeNewStart(){
    this.isCreatingNewOutcome = true;
    this.newOutcomeCode.enable();
    this.newOutcomeDescription.enable();
  }
  outcomeEditSave(){
    console.log()
  }
  outcomeNewSave(){
    let subcategory = this.getActiveCurriculumSubCategory();
    let code = this.newOutcomeCode.value;
    let name = this.newOutcomeDescription.value;
    this.afs.collection<ICurriculumOutcome>('curriculumOutcomes').add({
      code: code,
      description: name,
      timeCreated: serverTimestamp(),
      createdBy: this.auth.getUid(),
      primaryCurriculumId: this.getActiveCurriculum().__id,
      primaryCurriculumCategory: this.getActiveCurriculumCategory().name,
      primaryCurriculumSubCategory: subcategory.name,
    }).then(val => {
      
      let newOutcomeRef:ICurriculumOutcomeRef = {
        id: val.id,
        name: code +' '+ name
      }
      subcategory.outcomes.push(newOutcomeRef);
      // console.log('outcome saved', val.id)
      this.outcomeFormReset();
    })
  }
  outcomeEditCancel(){
    this.outcomeFormReset();
  }
  outcomeNewCancel(){
    this.outcomeFormReset();
  }
  outcomeFormReset(){
    this.isCreatingNewOutcome = false;
    this.isEditingOutcome = false;
    this.newOutcomeCode.reset();
    this.newOutcomeDescription.reset();
    this.newOutcomeCode.disable();
    this.newOutcomeDescription.disable();
  }

  updateCurriculum() {
    let id = this.editedCurriculum.__id;
    
    let learningOutcomeContent = JSON.stringify(this.editedCurriculumCategories);
    // console.log('updateCurriculum', id, learningOutcomeContent);

    this.curriculumCollection.doc(id).update({ 
      name: this.name.value,
      tags: this.tags.value,
      timeModified: serverTimestamp()
    });

    this.afs.doc<ICurriculumDefinition>('curriculumDefinitions/'+id).set({
      curriculum: id,
      data: learningOutcomeContent
    }, {merge:true})

    this.resetForm();
  }

  addCurriculum() {
    this.curriculumCollection.add({ 
      name: this.name.value,
      tags: this.tags.value,
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
      owner: this.auth.getUid()
    });
    this.resetForm();
  }

  createNewCategory(){
    let name = window.prompt('Name your new category');
    if (name){
      this.editedCurriculumCategories.push({
        name,
        subCategories: []
      })
    }
  }

  createNewSubCategory(){
    let category = this.getActiveCurriculumCategory();
    if (category){
      let name = window.prompt('Name your new subcategory');
      if (name){
        category.subCategories.push({
          name: name,
          outcomes: []
        })  
      }
    }
  }

  discardCategory(){
    let category = this.getActiveCurriculumCategory();
    if (category && window.confirm('Are you sure you want to delete '+category.name+' and all of its contents?')){
      let i = this.editedCurriculumCategories.indexOf(category);
      if (i !== -1){
        this.editedCurriculumCategories.splice(i, 1);
      }
    }
  }
  discardSubCategory(){
    let category = this.getActiveCurriculumCategory();
    let subcategory = this.getActiveCurriculumSubCategory();
    if (category && subcategory && window.confirm('Are you sure you want to delete '+subcategory.name+' and all of its contents?')){
      let i = category.subCategories.indexOf(subcategory);
      if (i !== -1){
        category.subCategories.splice(i, 1);
      }
    }
  }
  discardCurriculumOutcome(){
    let subcategory = this.getActiveCurriculumSubCategory();
    let outcome = this.getActiveCurriculumOutcome();
    if (subcategory && outcome && window.confirm('Are you sure you want to delete '+outcome.name+' and all of its contents?')){
      let i = subcategory.outcomes.indexOf(outcome);
      if (i !== -1){
        subcategory.outcomes.splice(i, 1);
      }
    }
  }

  getActiveCurriculum(){
    return this.getFirstSelectedMultiSelect(this.activeCurriculums);
  }
  getActiveCurriculumCategory() : ICurriculumCategory {
    return this.getFirstSelectedMultiSelect(this.activeCurriculumCategories);
  }
  getActiveCurriculumSubCategory() : ICurriculumSubCategory {
    return this.getFirstSelectedMultiSelect(this.activeCurriculumSubCategories);
  }
  getActiveCurriculumOutcome() : ICurriculumOutcomeRef {
    return this.getFirstSelectedMultiSelect(this.activeCurriculumOutcomes);
  }

  getOutcomeViewMode(){
    if (!this.getActiveCurriculumSubCategory()){
      return '';
    }
    return 'VIEW_OUTCOME';
  }

  private loadCurriculumDefinitionForEditing(){
    this.editedCurriculumCategories = null; 
    let curriculumId:string = this.editedCurriculum.__id;
    this.curriculumDefinitionLookup = this.afs.doc<ICurriculumDefinition>('curriculumDefinitions/'+curriculumId);
    this.curriculumDefinition$ = this.curriculumDefinitionLookup.valueChanges();
    let isLoaded = false;
    let sub = this.curriculumDefinition$.subscribe( (res) => {
      if (res){
        this.editedCurriculumCategories = JSON.parse(res.data);
      }
      else{
        this.editedCurriculumCategories = [];
      }
      // console.log('loadCurriculumDefinitionForEditing', curriculumId, this.editedCurriculumCategories);
      sub.unsubscribe();
    });
  }

  private getFirstSelectedMultiSelect(multiSelectFormElement:FormControl){
    if (multiSelectFormElement && multiSelectFormElement.value && multiSelectFormElement.value.length){
      return multiSelectFormElement.value[0];
    }
  }

  private getActiveCurriculumId(){
    let activeCurric = this.getActiveCurriculum();
    if (activeCurric){
      return activeCurric.__id;
    }
  }



}
