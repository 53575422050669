import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ISelectBlockContent, ISelectBlockContentPagelet } from '../../ui-partial/body-block-arr-select/body-block-arr-select.component';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { renderEmailFromUsername } from '../../data/accounts/pseudo-email-usernames';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { Subscription } from 'rxjs';
import { UsersService } from '../../core/users.service';
import { SubFormRequest, FormValidation, MatchValidator } from '../../core/forms';

enum StuRegPagelets {
  NAME_FORM = 'NAME_FORM',
  PASSWORD_FORM = 'PASSWORD_FORM',
  GRADE_FORM = 'GRADE_FORM',
  FINAL = 'FINAL',
}

const trim = (str:string) => {
  if (str){
    return str.trim()
  }
  return '';
}

interface IGrade {
  id: string,
  caption: string,
}

@Component({
  selector: 'student-registration',
  templateUrl: './student-registration.component.html',
  styleUrls: ['./student-registration.component.scss']
})
export class StudentRegistrationComponent implements OnInit, OnDestroy {

  StuRegPagelets = StuRegPagelets;
  currentPagelet:StuRegPagelets = StuRegPagelets.NAME_FORM;
  classroomId: string;
  classCodeId: string;
  usernameGenForm = new SubFormRequest();
  routeSub:Subscription;
  metaForm:FormGroup; metaFormValidation:FormValidation;
  newUsername:string;
  hidePassword = new FormControl(false);
  selectedGrade:IGrade;

  newUserUID;
  newPassword:string;
  
  gradeNamelocalization = 'Grade';
  availableGrades:IGrade[] = [
    { caption: '4', id: 'cambridge_stage4' },
    { caption: '5', id: 'cambridge_stage5' },
    { caption: '6', id: 'cambridge_stage6' },
    { caption: '7', id: 'cambridge_stage7' },
    { caption: '8', id: 'cambridge_stage8' },
  ]

  constructor(
    public whitelabelService:WhitelabelService,
    private fb: FormBuilder, 
    private router: Router, 
    private route: ActivatedRoute,
    private auth:AuthService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private usersService:UsersService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId']; // used to show name in the UI
      this.classCodeId = params['classCodeId'];
    });
    this.buildForms();
  }

  ngOnDestroy() {
    if(this.routeSub){ this.routeSub.unsubscribe(); }
  }

  goNext(){
    if (this.canGoNext()){
      switch(this.currentPagelet){
        case StuRegPagelets.NAME_FORM: 
          this.goToPagelet(StuRegPagelets.PASSWORD_FORM);
          break;
        case StuRegPagelets.PASSWORD_FORM: 
          if (this.whitelabelService.getSiteFlag('NO_STUDENT_GRADE_PICK')){
            this.goToPagelet(StuRegPagelets.FINAL);
          }
          else{
            this.goToPagelet(StuRegPagelets.GRADE_FORM);
          }
          break;
        case StuRegPagelets.GRADE_FORM: 
          this.goToPagelet(StuRegPagelets.FINAL);
          break;
        case StuRegPagelets.FINAL:
          this.gotoFirstLogin();
          break;
      }
    }
  }

  goToPagelet(target: StuRegPagelets){
    this.currentPagelet = target;
    this.autoFocusInput();
  }

  gotoFirstLogin(){
    // store username and password in a service variable, not in localStorage which could be disabled
    this.auth
      .signOut(false)
      .then(() =>{
        this.router.navigate(['play'])
      })
  }

  goPrev(){
    if (this.canGoPrev()){
      switch(this.currentPagelet){
        case StuRegPagelets.PASSWORD_FORM: return this.goToPagelet(StuRegPagelets.NAME_FORM);
        case StuRegPagelets.GRADE_FORM: return this.goToPagelet(StuRegPagelets.PASSWORD_FORM);
        case StuRegPagelets.FINAL: return this.goToPagelet(StuRegPagelets.GRADE_FORM);
      }
    }
  }

  getPasswordFieldInputType(){
    if (this.hidePassword.value === true){
      return 'password';
    }
    return 'text';
  }

  canGoNext(){
    switch(this.currentPagelet){
      case StuRegPagelets.NAME_FORM: 
        return this.metaForm.controls['firstName'].valid 
           &&  this.metaForm.controls['lastNameInitial'].valid 
      case StuRegPagelets.PASSWORD_FORM: 
        return this.metaForm.controls['password'].valid 
            && this.metaForm.controls['passwordAgain'].valid 
      case StuRegPagelets.GRADE_FORM: 
        return this.selectedGrade;
    }
    return false;
  }

  canGoPrev(){
    switch(this.currentPagelet){
      case StuRegPagelets.NAME_FORM: return false;
      case StuRegPagelets.FINAL: return false;
    }
    return true
  }

  isPageletNavigationHidden(){
    switch(this.currentPagelet){
      case StuRegPagelets.FINAL: return true;
    }
    return false;
  }
  
  buildForms() {
    this.metaForm = this.fb.group({
      'firstName': ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(20),
      ]],
      'lastNameInitial': ['', [
        Validators.required,
        Validators.maxLength(3),
      ]],
      'password': ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(12),
      ]],
      'passwordAgain': ['', [
        Validators.required,
        MatchValidator.isEqual('password')
      ]],
    });
    this.metaFormValidation = new FormValidation(this.metaForm, {
      'firstName': {
        'required': 'Please enter your given name.',
        'maxlength': 'We cannot register names that contain more than 20 letters.',
        'minlength': 'Please write your entire name (not including your last name).',
      },
      'lastNameInitial': {
        'required': 'Please include your last name\'s first initials.',
        'maxlength': 'PLease only use the first letter of your family name so that your teacher can recognize you on their list.',
      },
      'password': {
        'required': 'Please create a password for your account.',
        'minlength': 'Your password cannot be shorter than 6 letters.',
        'maxlength': 'Your password cannot be longer than 12 letters.',
      },
      'passwordAgain': {
        'required': 'Please write your password again.',
        'isEqual': 'Your passwords do not match.',
      },
    }); 
  }

  autoFocusInput(){
    setTimeout(()=> {
      try {
        document.getElementsByTagName("input")[0].focus();  
      }
      catch(e){
        console.warn('Pagelet contains not inputs')
      }
    }, 200)
  }

  
  createAccount(){
    let nameParts:string[] = [ 
      trim(this.metaForm.controls['firstName'].value),
      trim(this.metaForm.controls['lastNameInitial'].value),
    ]
    const baseUsername = nameParts.join('');
    const newDisplayName = nameParts.join(' ');
    const newPassword = trim(this.metaForm.controls['password'].value);

    let targetCurricId; 
    if (this.selectedGrade){
      targetCurricId = this.selectedGrade.id;
    }
    else{
      targetCurricId = ''
    }

    this.newPassword = newPassword;

    if (baseUsername){
      console.log('baseUsername', baseUsername);
      this.usernameGenForm.markAsSent();
      this.usersService
        .generateUniqueUsername(baseUsername)
        .then(newUsername => {
          console.log('newUsername', newUsername);
          this.newUsername = <string>newUsername;
          let email = renderEmailFromUsername(this.newUsername);
          return this.auth
            .emailSignUp(
              email,
              newPassword,
              newDisplayName,
            )
        })
        .then(uid => {
          // capture UID
          console.log('uid', uid);
          this.newUserUID = uid;
          return this.usersService
            .completeStudentRegistration(
              uid,
              '{HIDDEN}',
              targetCurricId,
              this.classroomId,
            )
        })
        .then( ()=>{
          // console.log('registerFreshAccountSignup');
          this.auth.registerFreshAccountSignup({
            uid: this.newUserUID,
            username: this.newUsername.toLowerCase(),
            password: this.newPassword,
            classCodeId: this.classCodeId,
          })
          // console.log('registration complete', this.auth.getFreshAccountSignup())
          this.usernameGenForm.markAsComplete();
        })
        .catch( error => {
          console.log('fail', error)
          this.usernameGenForm.markAsFailed(error.message);
        })
    }
  }

}
