import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { PixiInitializer, IPixiOptions } from './PixiInitializer';
import { DragonSprite } from './DragonAvatar/DragonSprite';
import { ILoadedBody, ILoadedCosmetic } from './DragonAvatar/DragonInterfaces';
import { PIXIConfetti } from './Effects/Confetti';

export class AvatarScene {
  pixi: PixiInitializer;
  display: PIXI.Container;
  screens: PIXI.Container[] = [];

  constructor(div: HTMLDivElement, pixiOptions?: IPixiOptions, private sceneOptions?: ISceneOptions) {
    this.sceneOptions = sceneOptions || {};

    this.display = new PIXI.Container;

    this.pixi = new PixiInitializer(div, pixiOptions);
    this.pixi.app.stage.addChild(this.display);

    PIXIConfetti.initialize(this.pixi.app);
    this.display.interactive = true;
    this.display.addListener('pointerdown', e => {
      let loc = e.data.getLocalPosition(this.pixi.app.stage);
      let confetti = new PIXIConfetti({ x: loc.x, y: loc.y, container: this.pixi.app.stage });
    });

    if (this.sceneOptions.width && this.sceneOptions.height) {
      this.drawRedOutline();
      this.pixi.onResize = this.onResize;
      this.onResize();
    }
  }

  addImage(url: string, options: IPlacementOptions) {
    let image = PIXI.Sprite.from(url);
    image.x = options.x || 0;
    image.y = options.y || 0;
    image.scale.set(options.scale || 1);
    this.display.addChild(image);
  }

  addScreenUnder(color: number, alpha: number = 0.05) {
    let screen = new PIXI.Graphics;
    screen.beginFill(color, alpha);
    screen.drawRect(0, 0, 100, 100);
    screen.position.set(this.pixi.stageBorders.left, this.pixi.stageBorders.top);
    screen.width = this.pixi.stageBorders.width;
    screen.height = this.pixi.stageBorders.height;

    this.display.addChildAt(screen, 0);
    this.screens.push(screen);
  }

  addCharacter = (body: ILoadedBody, cosmetics: ILoadedCosmetic[], options: IPlacementOptions): DragonSprite => {
    const avatar = new DragonSprite(body, cosmetics, !options.disableAnimation);
    avatar.display.scale.set(options.scale * (options.faceLeft ? -1 : 1), options.scale);
    avatar.display.position.set(options.x, options.y);

    if (options.addUnder) {
      this.display.addChildAt(avatar.display, 0);
    } else {
      this.display.addChild(avatar.display);
    }

    return avatar;
  }

  onResize = () => {
    let outer = this.pixi.stageBorders;
    let inner = this.sceneOptions;
    let scaleX = 1;
    let scaleY = 1;
    if (outer.width < inner.width) {
      scaleX = outer.width / inner.width;
    }

    if (outer.height < inner.height) {
      scaleY = outer.height / inner.height;
    }

    let scale = Math.min(scaleX, scaleY);

    this.display.scale.set(scale);

    this.display.x = (outer.width - scale * inner.width) / 2;
    this.display.y = (outer.height - scale * inner.height) / 2;
    outer.x = (scale * inner.width - outer.width) / 2;
    outer.y = (scale * inner.height - outer.height) / 2;
  }

  private drawRedOutline() {
    let graphic = new PIXI.Graphics; graphic.lineStyle(2, 0xff0000);
    graphic.drawRect(0, 0, this.sceneOptions.width, this.sceneOptions.height);
    this.display.addChild(graphic);
  }
}


export interface IPlacementOptions {
  x: number;
  y: number;
  scale?: number;
  disableAnimation?: boolean;
  faceLeft?: boolean;
  addUnder?: boolean;
}

export interface ISceneOptions {
  width?: number;
  height?: number;
}
