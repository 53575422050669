
import * as _ from 'lodash';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { renderEmailFromUsername } from '../../data/accounts/pseudo-email-usernames';
import { FormValidation, SubFormRequest } from '../../core/forms';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'user-login-form',
  templateUrl: './user-login-form.component.html',
  styleUrls: ['./user-login-form.component.scss']
})
export class UserLoginFormComponent implements OnInit, OnChanges {

  @Input() prefillUsername:string;
  @Input() suggestUsername:string;
  @Input() suggestPassword:string;
  @Output() requestPrefills = new EventEmitter();

  userForm: FormGroup;
  formValidation:FormValidation;
  passReset = new SubFormRequest(); // set to true when password reset is triggered
  prefillUsernameSibject:ReplaySubject<string> = new ReplaySubject(1);

  constructor(
    private fb: FormBuilder, 
    private auth: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.buildForm();
    this.prefillUsernameSibject.subscribe(username => {
      if (username){
        this.userForm.controls['email'].setValue(username)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges){
    _.each(changes, (change, prop) => {
      if (prop === 'prefillUsername'){
        this.prefillUsernameSibject.next(change.currentValue);
      }
    });
  }

  isSuggestedPasswordEntered(){
    if (this.suggestPassword && this.suggestPassword === this.userForm.controls['password'].value){
      return true
    }
  }

  buildForm() {
    this.userForm = this.fb.group({
      'email': ['', [
        Validators.required,
      ]],
      'password': ['', [
        Validators.required,
      ]],
    });
    this.formValidation = new FormValidation(this.userForm, {
      'email': {
        'required': 'Email is required.',
        'email': 'Email must be a valid email',
      },
      'password': {
        'required': 'Password is required.',
        'pattern': 'Password must include at least one letter and one number.',
        'minlength': 'Password must be at least 4 characters long.',
        'maxlength': 'Password cannot be more than 40 characters long.',
      },
    }); 
  }

  login() {
    this.formValidation.markAsSent()
    let email = this.userForm.value['email'];
    if (!email){
      alert('Please enter your email address')
    }
    email = email.trim();
    let studentEmail = renderEmailFromUsername(email);
    this.auth
      .emailLogin(studentEmail, this.userForm.value['password'])
      .then( () => this.formValidation.markAsComplete() )
      .catch(e => this.formValidation.markAsFailed(e.code) )
  }

  resetPassword() {
    this.passReset.markAsSent()
    let studentEmail = renderEmailFromUsername(this.userForm.value['email']);
    // look up the account... if its a student check the classroom as student and send a message through the teacher view (to do) https://trello.com/c/SvN12Kpi/126-if-student-enters-valid-username-and-requests-password-reset-then-teacher-should-get-a-notification
    // if not a student:
    this.auth
      .resetPassword(studentEmail)
      .then(() => {
        this.passReset.markAsComplete();
      })
      .catch((reason:Error) => {
        this.passReset.markAsFailed(reason.message);
      })
  }

}
