import { Injectable } from '@angular/core';

export const WLST_TRIAL_ID:string = 'TRIAL_ID' ;
export const WLST_INST_SCOPE_ID:string = 'INSTITUTIONAL_SCOPE_ID' ;

export const TEMPLATE_BRAND_NAME = '%BRAND_NAME%';

enum WhitelabelContext {
  CALCULATING_CATS = 'CALCULATING_CATS',
  MATHEMATIC_LUXEMBOURG = 'MATHEMATIC_LUXEMBOURG',
  MATHEMATIC_CAMBRIDGE = 'MATHEMATIC_CAMBRIDGE',
  MATHEMATIC_PORTUGAL = 'MATHEMATIC_PORTUGAL',
  MATHEMATIC_DEMO = 'MATHEMATIC_DEMO',
}
// const DEFAULT_WHITELABEL_CONTEXT = WhitelabelContext.CALCULATING_CATS;
const DEFAULT_WHITELABEL_CONTEXT = WhitelabelContext.MATHEMATIC_CAMBRIDGE;

const STANDARD_MTIC_LOGO = 'https://www.vretta.com/images/Logo-black.png';
// const STANDARD_MTIC_LOGO = 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2FMticNewLogo-0be9281cb380de44d96c466be593110a.svg?alt=media&token=a1ae1228-bbe6-45ad-ab35-b6619fd3650d';

const contextData = {

  CALCULATING_CATS : {
    homepageRoute: '/demo-landing',
    brandName: 'Calculating Cats',
    logo: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/logos%2Flogo.png?alt=media&token=50b89066-c23b-4b64-9202-a15f41c1afda',
    siteFlags: {},
    siteText: {},
  },

  MATHEMATIC_DEMO : {
    homepageRoute: '/demo-landing',
    brandName: 'MathemaTIC',
    logo: STANDARD_MTIC_LOGO,
    siteFlags: {},
    siteText: {},
  },
  MATHEMATIC_LUXEMBOURG : {
    homepageRoute: '/maintenance',
    brandName: 'MathemaTIC',
    logo: STANDARD_MTIC_LOGO,
    siteFlags: {},
    siteText: {},
  },
  MATHEMATIC_LUXEMBOURG_LEADERBOARD : {
    homepageRoute: '/email-login',
    brandName: 'MathemaTIC Leaderboard',
    logo: STANDARD_MTIC_LOGO,
    siteFlags: {
      NO_SIGNUP: true
    },
    siteText: {},
  },
  MATHEMATIC_PORTUGAL :   {
    homepageRoute: '/maintenance',
    brandName: 'MathemaTIC',
    logo: STANDARD_MTIC_LOGO,
    siteFlags: {},
    siteText: {},
  },
  MATHEMATIC_CAMBRIDGE :  {
    homepageRoute: '/cambridge-landing',
    brandName: '',
    logo: STANDARD_MTIC_LOGO,
    siteFlags: {
      NO_STUDENT_GRADE_PICK: true,
    },
    siteText: {
      SITE_LINK: 'cambridge.mathematic.org',
      SITE_LINK_ALT: 'eassessments.vretta.com',
      DEFAULT_CURRICULUM: 'Cambridge Stage 6 Field Trial',
      PARENT_LETTER_1: 'For the next few weeks, we are working WITH a powerful educational tool to help every child in the class excel in mathematics.',
      TRIAL_ID: 'cambridge-stage6-2019',
      INSTITUTIONAL_SCOPE_ID: 'cambridge-international',
    }
  },
  
}

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  context:WhitelabelContext;
  currentHomepageRoute:string;
  currentBrandName:string;
  currentLogo:string;
  
  constructor() {
    let host:string = window.location.host;
    switch (host){
      case 'app.mathematic.org':
      case 'demo.mathematic.org':
        this.context = WhitelabelContext.MATHEMATIC_DEMO;
        // this.context = WhitelabelContext.MATHEMATIC_LUXEMBOURG;
        break;
      case 'cambridge.mathematic.org':
      case 'qc.cambridge.mathematic.org':
      case 'cambridge.web.app':
        this.context = WhitelabelContext.MATHEMATIC_CAMBRIDGE;
        break;
      case 'calculatingcats.com':
        this.context = WhitelabelContext.CALCULATING_CATS;
        break;
      default: 
        const defaultWhitelabel = localStorage.getItem('defaultWhitelabel');
        if (defaultWhitelabel){
          this.context = <WhitelabelContext>defaultWhitelabel;
        }
        else{
          this.context = DEFAULT_WHITELABEL_CONTEXT;
        }
        break;
    }
    let currentContextData = contextData[this.context];
    if (!currentContextData){
      currentContextData = contextData[DEFAULT_WHITELABEL_CONTEXT]
    }
    this.currentHomepageRoute = currentContextData.homepageRoute;
    this.currentBrandName = currentContextData.brandName;
    this.currentLogo = currentContextData.logo;
    this.ensureDefaultContext();  
  }

  ensureDefaultContext(){
    if (!contextData[this.context]){
      console.error('using undefined context');
    }
    if (!contextData[this.context].siteText){
      contextData[this.context].siteText = {};
    }
    if (!contextData[this.context].siteFlags){
      contextData[this.context].siteFlags = {};
    }
    const siteText = contextData[this.context].siteText;
    if (!siteText['SITE_LINK']){
      siteText['SITE_LINK'] = window.location.host;
    }
  }

  onReady( callback ){
    callback(); // need to delay this if config is loaded over a file
  }

  updateTemplateContent(str:string){
    if (str){
      return str.split(TEMPLATE_BRAND_NAME).join(this.currentBrandName);
    }
    return str;
  }

  getSiteFlag(slug:string){
    return contextData[this.context].siteFlags[slug];
  }
  
  getSiteText(slug:string, defaultText:string=''){
    return contextData[this.context].siteText[slug] || defaultText;
  }

  getHomepageRoute(){
    return this.currentHomepageRoute
  }
}
