import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { IHeadsUpLobbyPlayers, IHeadsUpLobby, HeadsUpScreenModes, IHeadsUpLobbyPlayerQuestionState } from '../../data/collections/heads-up-lobby';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { serverTimestamp } from '../../data/timestamp';
import { ITask } from '../../data/collections/tasks.types';
import { TaskCacheService } from '../../ui-taskcreator/task-cache.service';
import { IAssignment } from '../../data/collections/assignments.types';
import { gradeUserGenQResponse } from '../student-assignment-task/util/grade-response';
import { IQuestionConfig as ICustomQuestionConfig } from '../../ui-taskcreator/models/index';
import { joinIdKey } from '../student-assignment-task/util/join-key';
import { IAssignmentTaskSubmission } from '../../data/collections/assignment-task-submissions.types';
import { COLLECTIONS } from '../student-assignment-task/util/constants';
import { CollectionsService } from '../../data/collections.service';
import { IAssignmentSubmission } from '../../data/collections/assignment-submissions.types';
import { CosmeticInfoList } from 'src/app/ui-avatar-displays/util/PlayerModels/CosmeticInfoList';
import { SidepanelService } from '../../core/sidepanel.service';
import { Subscription } from 'rxjs';
import { ChatpanelService } from 'src/app/core/chatpanel.service';
import { TaskService } from '../task.service';
import { BoosterpacksService } from '../boosterpacks.service';

@Component({
  selector: 'student-headsup-lobby',
  templateUrl: './student-headsup-lobby.component.html',
  styleUrls: ['./student-headsup-lobby.component.scss']
})

export class StudentHeadsupLobbyComponent implements OnInit, OnDestroy {

  lobbyState: Partial<IHeadsUpLobby> = {
    screenMode: HeadsUpScreenModes.LOADING
  };
  playerInfo: IHeadsUpLobbyPlayers;
  playerInfoAddress: string;
  classroomId: string;
  assignmentId: string;
  activeAssignment: IAssignment;
  activeTask: ITask;
  currentQuestionTaskId: string;
  isAssessmentLoaded: boolean;
  currentQuestionIndex__cache: number;
  currentSubmissionId: string;
  currentAttemptNumber: number;
  currentSubmission: IHeadsUpLobbyPlayerQuestionState | Partial<IHeadsUpLobbyPlayerQuestionState>;
  isLoggedIn: boolean = false;
  isSettingsMenuVisible: boolean = false;
  timeToStart: string;

  currentScore = 0;
  currentCoin = 0;
  scoreIncrease = 0;
  coinIncrease = 0;

  playerDataChangeListener: Subscription;

  private routeSub:Subscription;
  private signinSub:Subscription;
  private lobbySub:Subscription;
  private userCoinSub:Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AuthService,
    private tcs: TaskCacheService,
    private taskService: TaskService,
    private bps: BoosterpacksService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.chatPanel.deactivate();
    this.sidePanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.classroomId = params['classroomId'];
      this.assignmentId = params['assignmentId'];
      this.signinSub = this.auth.user.subscribe(userInfo => {
        // console.log('userInfo', userInfo)
        if (userInfo) {
          this.initPlayerGame();
        }
        else {
          this.auth.anonymousLogin().then( res => {
            this.loadAssignment(this.assignmentId);
          })
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.signinSub) { this.signinSub.unsubscribe(); }
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.playerDataChangeListener){ this.playerDataChangeListener.unsubscribe(); }
    if (this.lobbySub){ this.lobbySub.unsubscribe(); }
    if (this.userCoinSub){ this.userCoinSub.unsubscribe(); }
  }

  isAnon(){
    return this.auth.isAnonymous()
    return false; //this.auth.isAnonymous()
  }

  isCompletingAnonIdentification:boolean;
  identifyAnon(event:{displayName:string, email:string}){
    console.log('identifyAnon', event);
    if (event && event.displayName && event.email){
      this.auth.updateAnonUserData(event.displayName, event.email);  
    }
  }

  logout() {
    this.auth.signOut();
  }

  initPlayerGame() {
    this.isLoggedIn = true;
    this.loadPlayerData(() => { });
    this.listenToLobby();
    this.loadAssignment(this.assignmentId);
  }

  onQuestionSubmit(stateData: any) {
    if (!stateData) { stateData = new Map(); }

    // update question submission
    const uid = this.auth.getUid();
    const assignmentId = this.assignmentId;
    const taskId = this.activeTask.__id;
    const questionTaskId = this.currentQuestionTaskId;

    let question: ICustomQuestionConfig = this.getCurrentCustomTaskSetQuestion();
    let responses = [];
    let responseTextAgg = [];
    let isCorrect = true;
    let isStarted = true;
    let isFilled = true;
    if (question.entryOrder) {
      question.entryOrder.forEach(entryId => {
        const entryState = stateData.get(entryId);
        gradeUserGenQResponse(entryId, entryState, responseTextAgg, responses);
      });
    }
    // console.log('responses', responses)
    let responseText = responseTextAgg.join('\n');
    responses.forEach(response => {
      if (!response.isCorrect) {
        isCorrect = false;
      }
      if (!response.isFilled) {
        isFilled = false;
      }
    })
    let key = joinIdKey([
      uid,
      assignmentId,
      questionTaskId,
      'USERGEN',
      this.currentSubmissionId
    ]);
    let payload: IAssignmentTaskSubmission = {
      timeLastModified: serverTimestamp(),
      uid,
      assignmentId,
      taskId,
      questionTaskId,
      submissionId: this.currentSubmissionId,
      taskType: 'question_screen',
      isCorrect,
      isStarted,
      isFilled,
      responseText,
      responses,
    }

    payload.timeCreated = serverTimestamp(); // need to check for created keys at beginning and store in a map
    this.afs.collection(COLLECTIONS.ASSIGNMENT_TASK_SUBMISSIONS.name)
      .doc(key)
      .set(payload, { merge: true });

    this.currentSubmission = {
      uid,
      assignmentId,
      taskId,
      questionTaskId,
      isSubmitted: true,
      isFilled,
      isCorrect,
      timestamp: serverTimestamp()
    }
    key = joinIdKey([
      uid,
      assignmentId,
      questionTaskId,
      this.currentSubmissionId
    ]);
    this.afs.doc('headsUpLobbyPlayerQuestionState/' + key).set(this.currentSubmission);
  }

  checkNewQuestion() {
    if (this.currentQuestionIndex__cache !== this.lobbyState.currentQuestionIndex) {
      this.currentQuestionIndex__cache = this.lobbyState.currentQuestionIndex;
      this.currentSubmission = {}
    }
  }

  debug() {
    console.log(this.activeAssignment)
  }

  hasStartTime() {
    if (this.activeAssignment) {
      return this.activeAssignment.hasStartTime;
    }
  }

  getTimeOpen() {
    if (!this.timeToStart) {
      let d = new Date(this.activeAssignment.timeToStart.seconds * 1000);
      this.timeToStart = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes()
      // console.log('timeToStart', this.timeToStart);
    }
    return this.timeToStart;
  }

  loadTaskSubmissionStatus(uid: string, assignmentId: string, taskId: string, then: () => any) {
    // COLLECTIONS
    let assignmentSubmissionsCollection = this.afs.collection<IAssignmentSubmission>(COLLECTIONS.ASSIGNMENT_SUBMISSIONS.name, ref => {
      return ref
        .where('isCurrent', '==', true)
        .where('uid', '==', uid)
        .where('assignmentId', '==', assignmentId)
        .where('taskId', '==', taskId)
        .limit(5)
    });
    let assignmentSubmissions = CollectionsService.getValuesIdentified(assignmentSubmissionsCollection);
    let sub = assignmentSubmissions.subscribe(data => {
      sub.unsubscribe();
      if (data && data.length > 0) {
        if (data.length > 1) { console.error('Should not be possible to have more than one current submission at once!', data); }
        let currentSubmission: IAssignmentSubmission = data[0];
        this.currentSubmissionId = currentSubmission.__id;
        this.currentAttemptNumber = currentSubmission.attemptCount;
        then();
      } else {
        // create a new submission entry to track things (superceding current must be done in the previous screen)
        this.currentAttemptNumber = 1;
        this.createNewAssignmentSubmission(uid, assignmentId, taskId, this.currentAttemptNumber, (submissionId) => {
          this.currentSubmissionId = submissionId;
          then();
        })
      }
    })
  }

  createNewAssignmentSubmission(uid: string, assignmentId: string, taskId: string, attemptNumber: number, then: (submissionId: string) => any) {
    this.afs.collection(COLLECTIONS.ASSIGNMENT_SUBMISSIONS.name).add({
      isCurrent: true,
      isSubmitted: false,
      uid,
      assignmentId,
      taskId,
      attemptNumber,
      timeStart: serverTimestamp(),
    }).then(res => {
      then(res.id);
    });
  }

  // only designed to run once
  loadPlayerData(onComplete: () => void) {
    this.afs.collection<IHeadsUpLobbyPlayers>('headsUpLobbyPlayers/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .where('uid', '==', this.auth.getUid())
        .limit(10); // should always be 1
    }).get().toPromise().then(observer => {
      if (observer.docs.length > 0) {
        this.playerInfo = observer.docs[0].data() as IHeadsUpLobbyPlayers;
      } else {
        this.playerInfo = this.makeDefaultPlayerInfo();
      }
      const docId = [this.playerInfo.assignmentId, this.playerInfo.uid].join(':');
      this.playerInfoAddress = 'headsUpLobbyPlayers/' + docId;

      this.sendPresence(() => {
        this.listenToPlayerDataChanges();
        onComplete();
      });
    });
  }

  listenToPlayerDataChanges() {
    if (this.playerDataChangeListener) {
      this.playerDataChangeListener.unsubscribe();
    }
    this.playerDataChangeListener = this.afs.doc<IHeadsUpLobbyPlayers>(this.playerInfoAddress).valueChanges().subscribe(player => {
      // console.log(player, this.currentScore);
      this.coinIncrease = player.coins - this.currentCoin;
      this.scoreIncrease = player.score - this.currentScore;
      this.currentCoin = player.coins;
      this.currentScore = player.score;
    })
  }

  sendPresence(onComplete: () => void) {
    this.playerInfo.displayName = this.auth.getDisplayName(); // backup
    this.afs.doc<IHeadsUpLobbyPlayers>(this.playerInfoAddress).set(this.playerInfo).then(onComplete);
  }

  loadAssignment(assignmentId: string) {
    return this.afs.doc('assignments/' + assignmentId).get().toPromise().then(res => {
      this.activeAssignment = <IAssignment>res.data();
      return this.loadTask(this.activeAssignment.tasks[0]); // hard-coded to one for now...
    })
  }

  getDisplayName() {
    return this.auth.getDisplayName();
  }

  loadTask(taskId: string) {
    let uid = this.auth.getUid();
    return this.afs.doc('tasks/' + taskId).get().toPromise().then(res => {
      const task: ITask = <ITask>res.data();
      this.activeTask = task;
      this.activeTask.__id = taskId;
      const customTaskSetsLoaders = [];
      const customTaskSetsIds = [];
      if (task.customTaskSetId) {
        customTaskSetsIds.push(task.customTaskSetId);
      }
      customTaskSetsIds.forEach(customTaskSetId => {
        customTaskSetsLoaders.push(
          this.tcs.loadCustomTaskSetToCache(customTaskSetId)
        );
      });
      return Promise.all(customTaskSetsLoaders).then(() => {
        if (uid) {
          this.loadTaskSubmissionStatus(uid, this.assignmentId, this.activeTask.__id, () => {
            this.isAssessmentLoaded = true;
          });
        }
      });
    });
  }

  getCurrentCustomTaskSetQuestion() {
    if (this.isAssessmentLoaded) {
      this.currentQuestionTaskId = this.activeTask.questions[this.lobbyState.currentQuestionIndex];
      return this.tcs.getQuestionByTaskId(this.currentQuestionTaskId);
    }
  }

  listenToLobby() {
    this.lobbySub = this.afs.collection<IHeadsUpLobby>('headsUpLobby/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .limit(1);
    }).valueChanges().subscribe(data => {
      if (data.length > 0) {
        const previousLobbyState = this.lobbyState
        this.lobbyState = data[0];
        this.isShowingLeaderboard = false;
        this.checkNewQuestion();
        console.log('previousLobbyState', previousLobbyState)
        const finalScreenMode = HeadsUpScreenModes.PODIUM;
        if ( this.lobbyState.screenMode === finalScreenMode && !(previousLobbyState && previousLobbyState.screenMode === finalScreenMode) ){
          this.markAsCompleted();
        }
      }
    });
  }

  markAsCompleted(){
    return this.taskService
      .computeQuickScore(this.assignmentId, this.activeTask.__id)
      .then(score => {
        console.log('quick score:', score)
        if (score >= this.taskService.getPerformanceCutoff()){
          // this.numNewCheckpoints = 0;
          // this.isBoosterAssignmentComplete = true;
        }
        else{
          this.bps
            .assignBoosters(this.assignmentId, this.activeTask.__id, this.classroomId)
            .then(numNewCheckpoints=>{
              // this.numNewCheckpoints = numNewCheckpoints;
              // this.isBoosterAssignmentComplete = true;
            })
        }
      })
  }

  isShowingLeaderboard:boolean;
  showLeaderboard(){
    this.isShowingLeaderboard = !this.isShowingLeaderboard;
  }

  // listenToCoins() {
  //   this.afs.collection<IUserCoins>('userCoins/', ref => {
  //     return ref
  //       .where('uid', '==', this.playerInfo.uid);
  //   }).get().toPromise().then(observer => {
  //     if (observer.docs.length > 0) {
  //       this.currentCoin = observer.docs[0].data().coins;
  //     } else {
  //       this.postCoins (200);
  //       // this.playerInfo = observer.docs[0].data() as IHeadsUpLobbyPlayers;
  //     }
  //   });
  //   this.userCoinSub = this.afs.collection<IUserCoins>('userCoins/', ref => {
  //     return ref.where('uid', '==', this.playerInfo.uid);
  //   }).valueChanges().subscribe(data => {
  //     if (data.length > 0) {
  //       this.currentCoin = data[0].coins;
  //     }
  //   });
  // }

  // sendMove(loc: { x: number, y: number }) {
  //   const assignmentId = this.assignmentId;
  //   const uid = this.auth.getUid();
  //   this.afs.collection<IHeadsUpLobbyPlayerActions>('headsUpLobbyPlayerActions/').add({
  //     assignmentId,
  //     uid,
  //     actionId: AvatarPlayerActions.MOVE,
  //     details: loc,
  //     timestamp: serverTimestamp(),
  //     isProcessed: false,
  //   });
  // }

  // fetchCosmetics() {
  //   if (!this.playerInfo) {
  //     return;
  //   }
  //   this.afs.collection<ICosmeticChunk>('userAvatarCosmeticsEquipped/', ref => {
  //     return ref
  //       .where('uid', '==', this.playerInfo.uid)
  //       .limit(30); // should not be more than num cosmetics = 10
  //   }).get().toPromise().then(observer2 => {
  //     if (observer2.docs.length > 0) {
  //       let arr: ICosmeticChunk[] = _.map(observer2.docs, el => el.data() as ICosmeticChunk);
  //       this.assetLoader.loadCosmeticChunks(arr, loaded => {
  //         this.cosmeticsToAdd = loaded;
  //         this.numUpdates++;
  //       });
  //     } else {
  //       let arr = genBaseCosmetics(this.playerInfo.uid);
  //       arr.forEach(el => this.postCosmeticEquipped(el));
  //       this.assetLoader.loadCosmeticChunks(arr, loaded => {
  //         this.cosmeticsToAdd = loaded;
  //         this.numUpdates++;
  //       });
  //     }
  //   });
  // }

  makeDefaultPlayerInfo() {
    const assignmentId = this.assignmentId;
    const uid = this.auth.getUid();

    return {
      score: 0,
      coins: 0,
      assignmentId,
      uid,
      isPresent: true,
      displayName: this.auth.getDisplayName(),
    };
  }
}
