import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { PixiInitializer, IPixiOptions } from './PixiInitializer';
import { DragonSprite } from './DragonAvatar/DragonSprite';
import { ILoadedBody, ILoadedCosmetic } from './DragonAvatar/DragonInterfaces';
import { PIXIConfetti } from './Effects/Confetti';
import { PIXIFirework } from './Effects/Fireworks';
import { Tilemap } from './TileMap/Tilemap';
import { whitePop } from './Effects/MouseEffects';
import { CameraCanvas } from './TileMap/CameraCanvas';
import { ILoadedMapData, ILoadedNPCChunk, ObjectInteractionType, IInteractionOptions, ILoadedObjectChunk } from './TileMap/TempMapData';
import { IPlacementOptions } from './AvatarScene';
import { playCustomAnimation, CustomAnimation, popAndFade, playDragonAnimationOnce } from './CustomAnimations';

const AVATAR_SCALING_FACTOR = 0.3;

export class AvatarExploreCanvas {
  pixi: PixiInitializer;
  private container = new PIXI.Container;
  display: CameraCanvas;
  screens: PIXI.Container[] = [];
  tilemap: Tilemap;

  constructor(div: HTMLDivElement, pixiOptions?: IPixiOptions, public sceneOptions?: ISceneOptions) {
    this.sceneOptions = sceneOptions || {};

    this.display = new CameraCanvas;

    this.pixi = new PixiInitializer(div, pixiOptions);
    this.pixi.app.stage.addChild(this.container);
    this.container.addChild(this.display);

    PIXIConfetti.initialize(this.pixi.app);
    PIXIFirework.initialize(this.pixi.app);
    whitePop(this.display);
    this.display.interactive = true;

    if (this.sceneOptions.width && this.sceneOptions.height) {
      // this.drawRedOutline();
      this.pixi.onResize = this.onResize;
      this.onResize();
    }

    this.pixi.showFPS();
  }

  update = () => {
    this.display.update();
    if (this.tilemap) {
      this.tilemap.updateBounds(this.display.getGlobalCameraBounds());
    }
  }

  loadMapData(data: ILoadedMapData, callback: () => void) {
    // clear old first

    this.tilemap = new Tilemap(data.tileset, data.tiledata, this.pixi.app.renderer);

    this.display.addChild(this.tilemap);

    this.display.setBounds(this.pixi.stageBorders, this.tilemap.getMapBounds(), this.container.scale);

    this.placeObjects(data.objects);
    this.placeNPCs(data.npcs);

    callback();
  }

  placeObjects(objects: ILoadedObjectChunk[]) {
    _.forEach(objects, object => {
      let sprite = new PIXI.Sprite(object.data.texture);
      sprite.width = object.data.json_obj.width;
      sprite.height = object.data.json_obj.height;
      sprite.anchor.set(0.5, 1);
      sprite.pivot.set(object.data.json_obj.x, object.data.json_obj.y);

      let position = this.tileToLocal(object.placement);
      sprite.position.set(position.x, position.y);

      let tile = this.tilemap.pathingMap.getTileAt(object.placement.x, object.placement.y);
      if (tile.zObj) {
        sprite.pivot.y -= tile.zObj;
      }
      if (object.data.json_obj.wall) {
        this.tilemap.pathingMap.closeTile(tile);
      }
      if (object.data.json_obj.zObj) {
        tile.zObj = object.data.json_obj.zObj;
      }
      if (object.data.json_obj.tint) {
        sprite.tint = object.data.json_obj.tint;
      }

      if (object.interactions) {
        this.setupInteractions(sprite, object.interactions);
      }
      this.tilemap.addObject(sprite);
    });
  }

  placeNPCs(npcs: ILoadedNPCChunk[]) {
    _.forEach(npcs, npc => {
      let avatar = this.addCharacter(npc.body as ILoadedBody, npc.cosmetics, npc.placement);

      if (npc.interactions) {
        this.setupInteractions(avatar.display, npc.interactions);
      }
    });
  }

  setupInteractions(object: PIXI.DisplayObject, interactions: IInteractionOptions[]) {
    object.interactive = true;
    interactions.forEach(interaction => {
      let eFunc: Function;

      switch (interaction.type) {
        case ObjectInteractionType.MINI_DIALOGUE: eFunc = this.showMiniDialogue; break;
        case ObjectInteractionType.CUSTOM_ANIMATION: eFunc = playCustomAnimation; break;
        case ObjectInteractionType.DRAGON_ANIMATION: eFunc = playDragonAnimationOnce; break;
      }

      if (eFunc) {
        let running = false;
        object.addListener('pointerdown', e => {
          if (!running) {
            running = true;
            eFunc(object, interaction.data, () => running = false);
          }
        });
      }
    });
  }

  showMiniDialogue = (object: PIXI.DisplayObject, str: string, callback?: () => void) => {
    let text = new PIXI.Text(str, { fill: 0xffffff, dropShadow: true });
    text.x = object.x;
    text.y = object.y - object.getLocalBounds().height * object.scale.y;
    text.anchor.set(0.5, 1);
    text.zIndex = Infinity;
    this.tilemap.addChild(text);

    popAndFade(text, callback);
  }

  addImage(url: string, options: IPlacementOptions) {
    let image = PIXI.Sprite.from(url);
    image.x = options.x || 0;
    image.y = options.y || 0;
    image.scale.set(options.scale || 1);
    this.display.addChild(image);
  }

  addCharacter = (body: ILoadedBody, cosmetics: ILoadedCosmetic[], options: IPlacementOptions): DragonSprite => {
    const avatar = new DragonSprite(body, cosmetics, !options.disableAnimation);
    avatar.display.scale.set((options.scale || 1) * (options.faceLeft ? -1 : 1) * AVATAR_SCALING_FACTOR, (options.scale || 1) * AVATAR_SCALING_FACTOR);
    let position = this.tileToLocal(options);
    avatar.display.position.set(position.x, position.y);
    avatar.display.pivot.set(0, -100);

    this.tilemap.addObject(avatar.display);

    return avatar;
  }

  onResize = () => {
    let outer = this.pixi.stageBorders;
    let inner = this.sceneOptions;

    let scale = Math.min(1, outer.width / inner.width, outer.height / inner.height);

    this.container.scale.set(scale);
    console.log('scale set', this.container.scale);
    this.container.x = (outer.width - scale * inner.width) / 2;
    this.container.y = (outer.height - scale * inner.height) / 2;
    outer.x = (scale * inner.width - outer.width) / 2;
    outer.y = (scale * inner.height - outer.height) / 2;
  }

  private drawRedOutline() {
    let graphic = new PIXI.Graphics; graphic.lineStyle(2, 0xff0000);
    graphic.drawRect(0, 0, this.sceneOptions.width, this.sceneOptions.height);
    this.container.addChild(graphic);
  }

  getPathTo(origin: { x: number, y: number }, target: { x: number, y: number }): { x: number, y: number }[] {
    let start = this.tilemap.pathingMap.getTileAt(origin.x, origin.y);
    let end = this.tilemap.pathingMap.getNearestWalkable(origin, target);

    if (start === end) {
      // if (end === this.tilemap.pathingMap.getTileAt(target.x, target.y)) {
      //   return [this.tileToLocal(target, false)];
      // }else{
        return null;
      // }
    }

    let path = this.tilemap.pathingMap.makePath(start, end);
    path = this.tilemap.pathingMap.collapsePath(path);

    // if (path) {
    //   this.tilemap.overlayPathingMap(path, 0xff00ff);
    // }

    let m = _.map(path, tile => this.tileToLocal(tile.loc));

    // if (end === this.tilemap.pathingMap.getTileAt(target.x, target.y)) {
    //   m[m.length-1] = this.tileToLocal(target, false);
    // }

    return m;
  }

  tileToLocal(point: { x: number, y: number }, centered: boolean = true): { x: number, y: number } {
    return {
      x: (centered ? point.x + 0.5 : point.x) * this.tilemap.tileSize,
      y: (centered ? point.y + 0.5 : point.y) * this.tilemap.tileSize
    };
  }

  localToTile(point: { x: number, y: number }, floor?: boolean): { x: number, y: number } {
    if (floor) {
      return { x: Math.floor(point.x / this.tilemap.tileSize), y: Math.floor(point.y / this.tilemap.tileSize) };
    } else {
      return { x: point.x / this.tilemap.tileSize, y: point.y / this.tilemap.tileSize };
    }
  }
}

export interface ISceneOptions {
  width?: number;
  height?: number;
}
