import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import * as TWEEN from '@tweenjs/tween.js';

import { PixiInitializer, IPixiOptions } from './PixiInitializer';
import { IFDGOptions, IFDGElement } from './FDG/FDGInterfaces';
import { FDGContainer } from './FDG/FDGContainer';
import { FDGElement } from './FDG/FDGElement';
import { DragonSprite } from './DragonAvatar/DragonSprite';
import { PIXIPing } from './Effects/Ping';
import { ILoadedBody, ILoadedCosmetic } from './DragonAvatar/DragonInterfaces';

export class AvatarBubbles {
  pixi: PixiInitializer;
  FDG: FDGContainer;

  constructor(div: HTMLDivElement, pixiOptions?: IPixiOptions, private fdgOptions?: IFDGOptions, private disableAnimations: boolean = false) {
    this.fdgOptions = fdgOptions || {};

    this.pixi = new PixiInitializer(div, pixiOptions);

    this.FDG = new FDGContainer(this.pixi.stageBorders, this.fdgOptions);
    if (!this.fdgOptions.physicsEnabled === false) {
      this.pixi.app.ticker.add(this.FDG.applyForces);
    }

    if (this.fdgOptions.width && this.fdgOptions.height) {
      // this.drawRedOutline();
      this.pixi.onResize = this.onResize;
      this.onResize();
    }
    this.pixi.app.stage.addChild(this.FDG);
  }

  changeView (element: { bubble?: FDGElement, avatar: DragonSprite }, full: boolean, onComplete?: () => void) {
    let bubbleConfig = element.bubble.options;
    let avatarScale: number;
    let avatarY: number;

    if (full) {
      avatarScale = 0.2 * bubbleConfig.radius / 70;
      avatarY = 50 * bubbleConfig.radius / 70;
    } else {
      avatarScale = 0.4 * bubbleConfig.radius / 70;
      avatarY = 160 * bubbleConfig.radius / 70;
    }
    let tween = new TWEEN.Tween(element.avatar.display.scale).to({x: avatarScale, y: avatarScale}, 600).easing(TWEEN.Easing.Exponential.Out).start().onComplete(onComplete);
    tween = new TWEEN.Tween(element.avatar.display.position).to({x: 0, y: avatarY}, 600).easing(TWEEN.Easing.Exponential.Out).start();
    // element.avatar.display.scale.set(avatarScale, avatarScale);
    // element.avatar.display.position.set(0, avatarY);
  }

  addCharacterBubble = (body: ILoadedBody, cosmetics: ILoadedCosmetic[], bubbleConfig?: IFDGElement): { bubble: FDGElement, avatar: DragonSprite } => {
    if (!bubbleConfig) {
      bubbleConfig = {};
    }
    if (!bubbleConfig.x) {
      bubbleConfig.x = (this.fdgOptions.width || this.pixi.stageBorders.width) / 2 + -30 + Math.random() * 60;
    }
    if (!bubbleConfig.y) {
      bubbleConfig.y = (this.fdgOptions.height || this.pixi.stageBorders.height) / 2 + -30 + Math.random() * 60;
    }

    let avatarScale = 0.4;
    let avatarY = 160;

    if (bubbleConfig.radius) {
      let defaultRadius = 70;
      let newRadius = bubbleConfig.radius;

      avatarScale = avatarScale * newRadius / defaultRadius;
      avatarY = avatarY * newRadius / defaultRadius;
    }

    const avatar = new DragonSprite(body, cosmetics, !this.disableAnimations);

    avatar.display.scale.set(avatarScale, avatarScale);
    avatar.display.position.set(0, avatarY);

    bubbleConfig.display = avatar.display;
    const bubble = this.FDG.addNewNode(bubbleConfig);

    // node.interactive = true;
    // node.addListener('pointerdown', e => {
    //   let loc = e.data.getLocalPosition(node);
    //   node.vX += loc.x;
    //   node.vY += loc.y;
    // });

    // if (this.disableAnimations){
    //   avatar.display.animation.stop();
    //   avatar.display.animation.reset();
    // }

    return { bubble, avatar };
  }

  makePing = (bubble: FDGElement, point: { x: number, y: number }) => {
    if (!bubble) {
      return;
    }
    const ping = new PIXIPing({ container: bubble, x: point.x, y: point.y });
  }

  applyForceToBubble(bubble: FDGElement, x: number, y: number) {
    const dampener = 0.05;
    bubble.vX -= x * dampener;
    bubble.vY -= y * dampener;
  }

  onResize = () => {
    let outer = this.pixi.stageBorders;
    let inner = this.fdgOptions;
    let scaleX = 1;
    let scaleY = 1;
    if (outer.width < inner.width) {
      scaleX = outer.width / inner.width;
    }

    if (outer.height < inner.height) {
      scaleY = outer.height / inner.height;
    }

    let scale = Math.min(scaleX, scaleY);

    this.FDG.scale.set(scale);

    this.FDG.x = (outer.width - scale * inner.width) / 2;
    this.FDG.y = (outer.height - scale * inner.height) / 2;
    outer.x = (scale * inner.width - outer.width) / 2;
    outer.y = (scale * inner.height - outer.height) / 2;
  }

  private drawRedOutline() {
    let graphic = new PIXI.Graphics; graphic.lineStyle(2, 0xff0000);
    graphic.drawRect(0, 0, this.fdgOptions.width, this.fdgOptions.height);
    this.FDG.addChild(graphic);
  }
}
