import { Component, OnInit, Input } from '@angular/core';
import { IContentElementVideo } from '../models';
import { FormControl } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-iframe',
  templateUrl: './element-config-iframe.component.html',
  styleUrls: ['./element-config-iframe.component.scss']
})
export class ElementConfigIframeComponent implements OnInit {

  @Input() element:IContentElementVideo;

  url = new FormControl();

  constructor(
  ) { }

  ngOnInit() {
    
    bindFormControls(this.element, [
      {f: this.url, p: 'url'}
    ]);

  }

}
