import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IBoosterPack, IBoosterPackItem } from '../../data/cambridge/types';
import { BoosterService, ITaskBoosterMapping } from '../../core/booster.service';

export {
  ITaskBoosterMapping
};

enum Collections {
  t = 'tasks',
  b = 'boosterPacks',
  bi = 'boosterPackItems',
  tbim = 'taskBoosterMappings',
}


@Component({
  selector: 'booster-assign',
  templateUrl: './booster-assign.component.html',
  styleUrls: ['./booster-assign.component.scss']
})
export class BoosterAssignComponent implements OnInit {

  @Input() taskId:string;

  performanceThreshold = new FormControl(80);
  selectedBoosterPack = new FormControl(null);
  isUIBufferEnabled:boolean;

  activeBoosterPacks:IBoosterPack[] = [];
  activeBoosterPackItems:IBoosterPackItem[] = [];
  activeMappings:ITaskBoosterMapping[] = [];

  private cachedBoosterPackItems:Map<string, IBoosterPackItem[]> = new Map();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private boosterService: BoosterService
  ) { }

  ngOnInit() {
    if (this.taskId){
      this.loadBoosterPacks();
      this.loadCurrentMappings();
      this.selectedBoosterPack
        .valueChanges
        .subscribe(()=> this.loadBoosterPackItems() )
    }
  }

  loadCurrentMappings(){
    this.afs
      .collection(Collections.tbim, ref=>ref.where('taskId', '==', this.taskId))
      .snapshotChanges()
      .subscribe(response => {
        this.activeMappings = response.map(entry => {
          return {
            ... <ITaskBoosterMapping>entry.payload.doc.data(),
            __id: entry.payload.doc.id, 
          }
        });
      })
  }

  enableUIBuffer(duration:number=500){
    this.isUIBufferEnabled = true;
    setTimeout(() => {
      this.isUIBufferEnabled = false;
    }, duration);
  }

  deleteMapping(tbim:ITaskBoosterMapping){
    if (this.isUIBufferEnabled){ return; }
    this.enableUIBuffer()
    this.afs
      .doc(Collections.tbim+'/'+tbim.__id)
      .delete();
  }

  getCurrentBoosterPackName(){
    const id = this.getCurrentBoosterPackId();
    let matchingPack:IBoosterPack;
    this.activeBoosterPacks.forEach(pack =>{
      if (pack.__id === id){
        matchingPack = pack;
      }
    })
    if (matchingPack){
      return matchingPack.caption;
    }
    return '_'
  }

  getCurrentBoosterPackId(){
    return this.selectedBoosterPack.value;
  }

  insertMapping(bpi:IBoosterPackItem){
    if (this.isUIBufferEnabled){ return; }
    this.enableUIBuffer();
    let isAlreadyListed:boolean = false;
    this.activeMappings.forEach(mapping => {
      console.log('insertMapping', mapping.boosterPackItemId, bpi.__id)
      if(mapping.boosterPackItemId === bpi.__id){
        isAlreadyListed = true;
      }
    });
    if (!isAlreadyListed){
      const bpName = this.getCurrentBoosterPackName();
      const displayName = bpName+'/'+bpi.name;
      const creatorUid = this.auth.getUid();
      this._insertMapping(this.taskId, this.getCurrentBoosterPackId(), bpi.__id, displayName, this.performanceThreshold.value)
    }
  }

  private _insertMapping(taskId:string, boosterPackId:string, boosterPackItemId:string, displayName:string, performanceThreshold:number){
    const creatorUid = this.auth.getUid();
    return this.afs
      .collection(Collections.tbim)
      .add(<ITaskBoosterMapping>{
        taskId,
        displayName,
        performanceThreshold,
        boosterPackId, 
        boosterPackItemId, 
        creatorUid,
      })
  }

  loadBoosterPacks(){
    this.afs
      .collection(Collections.b)
      .get()
      .toPromise()
      .then(snap => {
        this.activeBoosterPacks = snap.docs.map(doc => {
          return {
            ... <IBoosterPack>doc.data(),
            __id: doc.id
          }
        })
      })
  }

  loadBoosterPackItems(){
    const boosterPackId:string = this.getCurrentBoosterPackId()
    // console.log('boosterPackId', boosterPackId)
    if (this.cachedBoosterPackItems.has(boosterPackId)){
      this.activeBoosterPackItems = this.cachedBoosterPackItems.get(boosterPackId);
    }
    this.afs
      .collection(Collections.bi, ref=>ref.where('boosterPackId', '==', boosterPackId))
      .get()
      .toPromise()
      .then(snap => {
        this.activeBoosterPackItems = snap.docs.map(doc => {
          return {
            ... <IBoosterPackItem>doc.data(),
            __id: doc.id
          }
        })
        this.activeBoosterPackItems = this.activeBoosterPackItems.sort( (a,b) => (a.humanOrdering < b.humanOrdering) ? -1 : 1 )        
        this.cachedBoosterPackItems.set(boosterPackId, this.activeBoosterPackItems);
      })
  }

}
