import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { CosmeticUnlock, ICosmeticInfo } from 'src/app/data/collections/heads-up-lobby';

export interface IPurchaseResponse {
  unlocked: boolean;
  info: ICosmeticInfo;
}

@Component({
  selector: 'money-display',
  templateUrl: './money-display.component.html',
  styleUrls: ['./money-display.component.scss']
})

export class MoneyDisplayComponent implements OnInit, OnChanges {
  @Input() coins: number;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
  }
}
