import * as PIXI from 'pixi.js';

export class PIXIPing {
  constructor(options: {
    container: PIXI.Container,
    x: number,
    y: number
  }) {
    let graphic = new PIXI.Graphics;
    graphic.lineStyle(7, 0x00ffff);
    graphic.drawCircle(0, 0, 100);

    graphic.position.set(options.x, options.y);
    graphic.scale.set(0, 0);

    options.container.addChild(graphic);

    let onTick = () => {
      graphic.alpha -= 0.04;
      graphic.scale.x += 0.05;
      graphic.scale.y += 0.05;

      if (graphic.alpha < 0) {
        graphic.destroy();
        PIXI.Ticker.shared.remove(onTick);
      }
    };

    PIXI.Ticker.shared.add(onTick);
  }
}
