import * as _ from 'lodash';

import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges } from '@angular/core';

import { AvatarBubbles } from '../util/AvatarBubbles';
import { IHeadsUpLobbyPlayerActions, AvatarPlayerActions, IStudentBubble, IHeadsUpLobbyPlayers, genBaseCosmetics } from 'src/app/data/collections/heads-up-lobby';
import { isLoadedBody, ILoadedCosmeticsEquipped, IUserAvatarCosmeticsEquipped } from '../util/DragonAvatar/DragonInterfaces';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { AssetLoader } from '../util/AssetLoader';

const MAX_PLAYERS = 300; // ** CHANGE THIS IF YOU WANT MORE OR LESS ON SCREEN

@Component({
  selector: 'avatar-bubbles',
  templateUrl: './avatar-bubbles.component.html',
  styleUrls: ['./avatar-bubbles.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarBubblesComponent implements OnInit, OnChanges {
  @ViewChild('pixiViewport') pixiViewport: ElementRef;
  parentElement: any;

  @Input() players: IHeadsUpLobbyPlayers[];
  @Input() update: number;
  
  initialized = false;
  assignmentId: string;

  bubbleRender: AvatarBubbles;
  assetLoader: AssetLoader;

  studentBubbles: IStudentBubble[] = [];

  constructor(
    private afs:AngularFirestore,
    private route: ActivatedRoute) {
      this.assetLoader = new AssetLoader(afs);
    // window.addEventListener('keydown',e => {
    //   if (e.key === 'e') {
    //     for (let i = 0; i < 10; i ++){

    //       this.players.push({
    //         assignmentId: 'assignmentId',
    //         uid: 'uid',
    //         isPresent: true
    //       });

    //       this.ngOnChanges();
    //     }
    //   }
    // });
  }

  ngOnInit() {
    const div = this.pixiViewport.nativeElement;
    this.parentElement = div.closest('div avatar-bubbles');
    this.bubbleRender = new AvatarBubbles(div, { width: this.parentElement.offsetWidth, height: this.parentElement.offsetHeight }, { width: 800, height: 500, elementMinScale: 0.25, elementScaleAdjust: 0.98}, true);

    window.addEventListener('resize', this.onResize);

    this.route.params.subscribe(params => {
      if (!this.initialized) {
        this.initialized = true;
        this.assignmentId = params['assignmentId'];
        this.listenToUserActions();
        this.listenToCostumeChange();
        this.ngOnChanges();
      }
    });
  }

  listenToCostumeChange() {
    this.afs.collection<IUserAvatarCosmeticsEquipped>('userAvatarCosmeticsEquipped2/', ref => {
      return ref
        .where('updatedIn', '==', this.assignmentId);
    }).stateChanges().subscribe(entries => {
      entries.forEach(entry => {
        let data = entry.payload.doc.data();
        // console.log('change entry!', data);
        this.assetLoader.loadCosmeticsEquipped(data, this.addCosmetics);
      })
    })
  }

  listenToUserActions() {
    this.afs.collection<IHeadsUpLobbyPlayerActions>('headsUpLobbyPlayerActions/', ref => {
      return ref
        .where('assignmentId', '==', this.assignmentId)
        .where('isProcessed', '==', false)
        .limit(20);
    }).snapshotChanges().subscribe(entries => {
      entries.forEach(entry => {
        this.processUserAction(entry.payload.doc.id, entry.payload.doc.data());
      });
    });
  }

  ngOnChanges() {
    if (!this.initialized) {
      return;
    }

    _.forEach(this.players, player => {
      let bubble = _.find(this.studentBubbles,bubble => bubble.uid === player.uid);
      if (!bubble) {
        this.assetLoader.fetchCosmetics(player.uid, this.addCosmetics);
      }
    });

    // _.forEach(this.studentBubbles, bubble => {
    //   let player = _.find(this.players, player => bubble.uid === player.uid);
    //   if (!player) {
    //     this.removePlayer(player.uid);
    //   }
    // });
  }

  onResize = () => {
    this.bubbleRender.pixi.resize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
  }

  finishNewAvatar(data: ILoadedCosmeticsEquipped) {
    let loadedBubble = this.bubbleRender.addCharacterBubble(data.body, data.cosmetics, { radius: 120, x: 150 + 30 * (Math.random() - 0.5), y: 150  + 30 * (Math.random() - 0.5)});

    let bubble = {
      uid: data.uid,
      cosmetics: data,
      node: loadedBubble.bubble,
      avatar: loadedBubble.avatar,
    }
    this.studentBubbles.push(bubble);
  }

  runMoveCommand(bubble: IStudentBubble, vel: { x: number, y: number }) {
    if (bubble && bubble.node) {
      this.bubbleRender.applyForceToBubble(bubble.node, vel.x, vel.y);
    } else {
      console.error('NO BUBBLE!', this.studentBubbles);
    }
  }

  addCosmetics = (cosmetics: ILoadedCosmeticsEquipped) => {
    let uid = cosmetics.uid;
    
    let bubble = _.find(this.studentBubbles, {uid});

    if (bubble) {
      if (bubble.avatar) {
        cosmetics.cosmetics.forEach(bubble.avatar.addCosmetic);
      }
    } else {
      if (this.studentBubbles.length < MAX_PLAYERS) {
        this.finishNewAvatar(cosmetics);
      } else {
        this.studentBubbles.push({
          uid,
          cosmetics,
          noVisual: true
        });
      }
    }
  }

  processUserAction = (entryId: string, data: IHeadsUpLobbyPlayerActions) => {
    this.afs.doc<IHeadsUpLobbyPlayerActions>('headsUpLobbyPlayerActions/' + entryId).update({ isProcessed: true });

    let bubble = _.find(this.studentBubbles, { uid: data.uid });
    switch (data.actionId) {
      case AvatarPlayerActions.MOVE: 
        this.runMoveCommand(bubble, data.details);
        break;
      default:
        console.error('Avatar action is unknown', data);
    }    
  }
}
