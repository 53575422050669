interface IPoint {
  x: number;
  y: number;
}

interface IVector {
  mag: number;
  dir: number;
}

export function pointToVector(point: IPoint): IVector {
  return { mag: Math.sqrt(point.x * point.x + point.y * point.y), dir: Math.atan2(point.y, point.x) };
}

export function vectorToPoint(vector: IVector): IPoint {
  return { x: vector.mag * Math.cos(vector.dir), y: vector.mag * Math.sin(vector.dir) };
}

export function vectorBetween(origin: IPoint, target: IPoint): IVector {
  let dX = target.x - origin.x;
  let dY = target.y - origin.y;

  return { mag: Math.sqrt(dX * dX + dY * dY), dir: Math.atan2(dY, dX) };
}

export function getTileid(n: number): number {
  if (n > 1000) {
    let str = n.toString(16);
    let sub = str.substr(1);
    return parseInt('0x' + sub, 16);
  }

  return n;
}

/** @returns [rotation, offH, offV, flipH, flipV] */
export function getTileTransformation(n: number): [number, number, number, number, number] {
  if (n > 1000) {
    let str = n.toString(16);
    let char = str.charAt(0);

    // console.log(str, char);
    switch (char) {
      case '6': return [-Math.PI / 2, 0, 1, 1, 1]; // rotate CC 90
      case 'A': case 'a': return [Math.PI / 2, 1, 0, 1, 1]; // rotate C 90
      case 'C': case 'c': return [Math.PI, 1, 1, 1, 1]; // rotate 180
      case '8': return [0, 1, 0, -1, 1]; // flip H
      case '4': return [0, 0, 1, 1, -1]; // flip V
      case '2': return [-Math.PI / 2, 0, 0, -1, 1]; // rotate CC 90 and flip H
      case 'E': case 'e': return [Math.PI / 2, 1, 1, -1, 1]; // rotate C 90 and flip H
    }
  }

  return [0, 0, 0, 1, 1];
}
