import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { AngularFirestore } from '@angular/fire/firestore';
import { ILoadedSticker, ILoadedOutfit, ILoadedBody, ILoadedColor, ILoadedCosmetic, IUserAvatarCosmeticsEquipped, ILoadedCosmeticsEquipped, isLoadedBody } from './DragonAvatar/DragonInterfaces';
import { CosmeticCategory, CosmeticUnlock, genBaseCosmetics } from 'src/app/data/collections/heads-up-lobby';
import { IObjectChunk, ILoadedObjectChunk, ILoadedObject, IObjectDef, IObjectData, ILoadedMapData, mapData1, ObjectMap, ILoadedNPCChunk, INPCChunk, ITiledTileset, ITiledMapData } from './TileMap/TempMapData';

// function stringObjToArray(stringObj: {[key: string]: string}): [{key: string, val: string}] {

// }

export class AssetLoader {
  static bodyDefs: { [key: string]: ILoadedBody | ILoaderQueue } = {};
  static partDefs: { [key: string]: ILoadedSticker | ILoadedOutfit | ILoaderQueue } = {};
  static objectDefs: { [key: string]: ILoadedObject | ILoaderQueue } = {};

  constructor(private afs: AngularFirestore) { }

  fetchCosmetics = (uid: string, callback: (cosmetics: ILoadedCosmeticsEquipped, data: IUserAvatarCosmeticsEquipped, newDatq: boolean) => void) => {
    this.afs.collection<IUserAvatarCosmeticsEquipped>('userAvatarCosmeticsEquipped2/', ref => {
      return ref
      .where('uid', '==', uid)
      .limit(1); // should not be more than 1
    }).get().toPromise().then(observer => {
      let data: IUserAvatarCosmeticsEquipped;
      let newData: boolean = false;

      if (observer.docs.length > 0) {
        data = observer.docs[0].data() as IUserAvatarCosmeticsEquipped;
      } else {
        data = genBaseCosmetics(uid);
        newData = true;
      }

      this.loadCosmeticsEquipped(data, cosmetics => callback(cosmetics, data, newData));
    });
  }

  loadCosmeticsEquipped = (data: IUserAvatarCosmeticsEquipped, callback: (loaded: ILoadedCosmeticsEquipped) => void) => {
    let toLoad = 1;

    let m: ILoadedCosmeticsEquipped = {
      uid: data.uid,
      body: undefined,
      cosmetics: []
    }

    _.forEach(data.elements,(slug, category) => {
      toLoad++;
      this.loadCosmetic(slug, category, loaded => {
        toLoad--;
        
        if (isLoadedBody(loaded)) {
          m.body = loaded;
        } else {
          m.cosmetics.push(loaded);
        }
        if (toLoad === 0) {
          callback(m);
        }
      });
    });

    toLoad--;
    if (toLoad === 0) {
      callback(m);
    }
  }

  loadCosmetic = (slug: string, category: string, callback: (data: ILoadedCosmetic) => void) => {
    if (category === CosmeticCategory.BODY) {
      this.getBody(slug, callback);
    } else if (category === CosmeticCategory.SKIN || category === CosmeticCategory.HAIR_COLOR) {
      callback(this.makeLoadedColor(slug, category));
    } else {
      this.getPart(slug, callback);
    }
  }

  loadObjectChunks(chunks: IObjectChunk[], callback: (loaded: ILoadedObjectChunk[]) => void) {
    let toLoad = chunks.length;
    let loaded: ILoadedObjectChunk[] = [];

    chunks.forEach(chunk => this.loadObjectChunk(chunk, loaded2 => {
      toLoad--;
      loaded.push(loaded2);
      if (toLoad === 0) {
        callback(loaded);
      }
    }));
  }

  loadObjectChunk(chunk: IObjectChunk, callback: (loaded: ILoadedObjectChunk) => void) {
    this.getObject(chunk.slug, data => callback({ uid: chunk.uid, scene: chunk.scene, placement: chunk.placement, interactions: chunk.interactions, data }));
  }

  loadMap(slug: string, callback: (loaded: ILoadedMapData) => void) {
    let map = mapData1; // map = fetch from database
    // let toLoad = 1;
    let toLoad = map.npcs.length + 1;

    let objects = _.map(map.objects, ObjectMap.getObject); // must also involve going through loadObjectChunks
    let npcs: ILoadedNPCChunk[] = [];
    let tileset: ITiledTileset;
    let tiledata: ITiledMapData;

    let _tileset = './assets/tiledMaps/baseTileset.json';
    let _tiledata = './assets/tiledMaps/TiledMapTest.json';
    // console.log('loading:', _tempSlug);
    getSharedResource({slugs: [_tileset, _tiledata], callback: loader => {
      // console.log('TEMP SLUG LOAD', loader.res[_tempSlug].data);
      tileset = loader.res[_tileset].data;
      tiledata = loader.res[_tiledata].data;
      tileset.image = map.tileset.data.image;
      toLoad--;
      if (toLoad === 0) {
        finish();
      }
    }});

    map.npcs.forEach(npc => this.getNPC(npc, loadedNPC => {
      npcs.push(loadedNPC);
      toLoad--;
      if (toLoad === 0) {
        finish();
      }
    }));

    function finish() {
      let loaded: ILoadedMapData = {
        startLoc: map.startLoc,
        tiledata,
        tileset: {
          data: tileset,
          defs: map.tileset.defs
        },

        // tiledata: map.tiledata,
        // tileset: map.tileset,

        objects,
        npcs
      };

      callback(loaded);
    }
  }

  private getObject(slug: string, callback: (data: ILoadedObject) => void) {
    let def = AssetLoader.objectDefs[slug];
    if (def) {
      if (isLoaderQueue(def)) {
        def.queue.push({ slug, callback });
      } else {
        callback(def);
      }
      return;
    } else {
      AssetLoader.objectDefs[slug] = { type: 'queue', queue: [] };
    }

    this.afs.collection<IObjectDef>('explorationObjects/').doc(slug).get().toPromise().then(snapshot => {
      let obj = snapshot.data() as IObjectDef;
      let slugs = [obj.json_url];
      let baseTexture = PIXI.BaseTexture.from(obj.png_url);

      getSharedResource({
        slugs, callback: loaded => {
          let objData: IObjectData = loaded.res[obj.json_url].data;

          this.onTextureLoaded(baseTexture, () => {
            let part = this.makeLoadedObject(objData, baseTexture);

            let queue = AssetLoader.partDefs[slug] as ILoaderQueue;
            AssetLoader.objectDefs[slug] = part;

            callback(part);

            queue.queue.forEach(req => req.callback(part));
          });
        }
      });
    });
  }

  private getBody(slug: string, callback: (data: ILoadedBody) => void) {
    let def = AssetLoader.bodyDefs[slug];
    if (def) {
      if (isLoaderQueue(def)) {
        def.queue.push({ slug, callback });
      } else {
        callback(def);
      }
      return;
    } else {
      AssetLoader.bodyDefs[slug] = { type: 'queue', queue: [] };
    }

    this.afs.collection<IBodyDef>('avatarSkeletons/').doc(slug).get().toPromise().then(snapshot => {
      let part = snapshot.data() as IBodyDef;
      let slugs = [part.skeleton, part.texture_json, part.texture_png];
      getSharedResource({
        slugs, callback: loaded => {
          let skeleton = loaded.res[slugs[0]].data;
          let texture_json = loaded.res[slugs[1]].data;
          let texture_png = loaded.res[slugs[2]].texture;

          let data: ILoadedBody = {
            slug: slug,
            type: 'body',
            category: CosmeticCategory.BODY,
            skeleton,
            texture_json,
            texture_png,
          };

          let queue = AssetLoader.bodyDefs[slug] as ILoaderQueue;
          AssetLoader.bodyDefs[slug] = data;
          callback(data);
          queue.queue.forEach(req => req.callback(data));
        }
      });
    });
  }

  private getPart(slug: string, callback: (part: ILoadedSticker | ILoadedOutfit) => void) {
    let def = AssetLoader.partDefs[slug];
    if (def) {
      if (isLoaderQueue(def)) {
        def.queue.push({ slug, callback });
      } else {
        callback(def);
      }
      return;
    } else {
      AssetLoader.partDefs[slug] = { type: 'queue', queue: [] };
    }

    this.afs.collection<IPartDef>('avatarCosmetics/').doc(slug).get().toPromise().then(snapshot => {
      let def2 = snapshot.data() as IPartDef;
      let slugs = [def2.linkingJSON];
      let hasTexture: boolean;
      if (def2.textureJSON && def2.textureJSON.length > 1) {
        hasTexture = true;
        slugs.push(def2.textureJSON);
      }
      getSharedResource({
        slugs, callback: loaded => {
          let partData = loaded.res[def2.linkingJSON].data;
          partData.texturePNG = def2.texturePNG;
          partData.slug = slug;
          if (partData.rotation) {
            partData.rotation *= Math.PI / 180;
          }
          if (partData.partMap) {
            partData.partMap.forEach((part: IPartData) => {
              if (part.rotation) {
                part.rotation *= Math.PI / 180;
              }
            });
          }
          let baseTexture = PIXI.BaseTexture.from(partData.texturePNG);
          this.onTextureLoaded(baseTexture, () => {
            let part: ILoadedOutfit | ILoadedSticker;
            if (hasTexture) {
              part = this.makeLoadedOutfit(partData, loaded.res[def2.textureJSON].data, baseTexture);
            } else {
              part = this.makeLoadedSticker(partData, baseTexture);
            }

            let queue = AssetLoader.partDefs[slug] as ILoaderQueue;
            AssetLoader.partDefs[slug] = part;

            callback(part);

            queue.queue.forEach(req => req.callback(part));
          });
        }
      });
    });
  }

  private getNPC(npcData: INPCChunk, callback: (loaded: ILoadedNPCChunk) => void) {
    let body: ILoadedBody;
    let cosmetics: ILoadedCosmetic[] = [];

    
    let chunkMap: {slug:string, category: string}[] = _.map(npcData.cosmetics, slug => {
      let category: string;
      if (_.startsWith(slug, 'skin')) {
        category = CosmeticCategory.SKIN;
      } else if (_.startsWith(slug, 'hair')) {
        category = CosmeticCategory.HAIR_COLOR;
      } else {
        category = CosmeticCategory.CLEAR;
      }

      return {
        slug,
        category
      };
    });

    let toLoad = 1 + chunkMap.length;
    this.getBody(npcData.body, (loadedBody) => {
      toLoad--;
      body = loadedBody;
      if (toLoad === 0) {
        finish();
      }
    });

    chunkMap.forEach(chunk => this.loadCosmetic(chunk.slug,chunk.category, (data => {
      cosmetics.push(data);
      toLoad--;
      if (toLoad === 0) {
        finish();
      }
    })));

    function finish() {
      let loadedNPC: ILoadedNPCChunk = {
        uid: npcData.uid,
        scene: npcData.scene,
        placement: npcData.placement,
        interactions: npcData.interactions,
        defaultAnimation: npcData.defaultAnimation,
        body,
        cosmetics,
      };

      callback(loadedNPC);
    }
  }

  private makeLoadedOutfit(part: IPartData, texture_json: any, baseTexture: PIXI.BaseTexture): ILoadedOutfit {
    let textures = this.buildTextureMap(baseTexture, texture_json);

    let partMap = this.buildPartMap(part.partMap, textures);

    return {
      slug: part.slug,
      type: 'outfit',
      baseTexture,
      category: part.category,

      texture_json,
      partMap,
    };
  }

  private makeLoadedSticker(part: IPartData, baseTexture: PIXI.BaseTexture): ILoadedSticker {
    let texture: PIXI.Texture;
    if (part.width) {
      texture = new PIXI.Texture(baseTexture, null, new PIXI.Rectangle(0, 0, part.width, part.height));
    } else {
      texture = new PIXI.Texture(baseTexture);
    }
    part.texture = texture;

    return {
      slug: part.slug,
      type: 'sticker',
      texture,
      category: part.category,

      bodypart: part.bodypart,
      forceUnder: part.forceUnder,
      x: part.x || 0,
      y: part.y || 0,
      rotation: part.rotation || 0,
      tint: (part.tint || part.tint === 0) ? part.tint : 0xffffff
    };
  }

  private makeLoadedColor(slug: string, category: string): ILoadedColor {
    return {
      slug,
      type: 'color',
      category,
      tint: parseInt('0x' + slug.split('#')[1], 16)
    };
  }

  makeLoadedObject(data: IObjectData, baseTexture: PIXI.BaseTexture): ILoadedObject {
    let texture: PIXI.Texture;
    if (data.width) {
      texture = new PIXI.Texture(baseTexture, null, new PIXI.Rectangle(0, 0, data.width, data.height));
    } else {
      texture = new PIXI.Texture(baseTexture);
    }

    return {
      slug: data.slug,
      type: 'object',
      texture,
      json_obj: data
    };
  }

  private buildTextureMap(baseTexture: PIXI.BaseTexture, texture_json: any): { [key: string]: PIXI.Texture } {
    let m: { [key: string]: PIXI.Texture } = {};

    texture_json.SubTexture.forEach(config2 => {
      let bounds = new PIXI.Rectangle(config2.x, config2.y, config2.width, config2.height);
      let texture = new PIXI.Texture(baseTexture, bounds);
      m[config2.name] = texture;
    });

    return m;
  }

  private buildPartMap(partMap: IPartData[], textures: { [key: string]: PIXI.Texture }): ILoadedSticker[] {
    let m: ILoadedSticker[] = [];
    partMap.forEach(part2 => {
      let sticker: ILoadedSticker = {
        slug: part2.slug,
        type: 'sticker',
        texture: textures[part2.src],
        category: part2.category,

        bodypart: part2.bodypart,
        forceUnder: part2.forceUnder,
        x: part2.x || 0,
        y: part2.y || 0,
        rotation: part2.rotation || 0,
        tint: (part2.tint || part2.tint === 0) ? part2.tint : 0xffffff
      };
      m.push(sticker);
    });

    return m;
  }

  private onTextureLoaded(baseTexture: PIXI.BaseTexture, callback: () => void) {
    if (baseTexture.valid) {
      callback();
    } else {
      baseTexture.on('loaded', callback);
    }
  }

  uploadNewPart(slug: string, cosmeticCategory: CosmeticCategory, linkingJSON: string, texturePNG: string, textureJSON: string = '', thumbnail?: string): any {
    let m = {
      slug,
      cosmeticCategory,
      skeletonId: 'Character',
      linkingJSON,
      texturePNG,
      textureJSON,
      thumbnail: thumbnail || texturePNG,
      unlock: CosmeticUnlock.BASIC,
      hidden: false
    };

    this.afs.doc<any>('/avatarCosmetics/' + slug).set(m);
  }

  uploadNewColor(cosmeticCategory: CosmeticCategory, color: string, sortPriority: number = 1, hidden: boolean = false) {
    let slug: string;
    if (cosmeticCategory === CosmeticCategory.HAIR_COLOR) {
      slug = 'hair' + color;
    } else if (cosmeticCategory === CosmeticCategory.SKIN) {
      slug = 'skin' + color;
    } else {
      return;
    }

    let m = {
      slug,
      cosmeticCategory,
      color,
      hidden,
      sortPriority,
      unlock: CosmeticUnlock.BASIC
    };

    this.afs.doc<any>('/avatarCosmetics/' + slug).set(m);
  }
}

interface IPixiLoaderReturn {
  data?: any;
  newLoad: boolean;
  res: any;
}

interface IPixiLoaderPacket {
  slugs: string[];
  callback: (loaded: IPixiLoaderReturn) => void;
}

interface IBodyDef {
  slug: string;
  skeleton: string;
  texture_json: string;
  texture_png: string;
}

interface IPartDef {
  slug: string;
  cosmeticCategory: CosmeticCategory;
  skeletonId: string;

  linkingJSON: string;
  textureJSON: string;
  texturePNG: string;
  thumbnail: string;
}

interface IPartData {
  slug: string;
  type: 'part' | 'sticker' | 'outfit';
  src?: string;
  texturePNG?: string;
  texture?: PIXI.Texture;


  bodypart?: string;
  forceUnder: boolean;
  category?: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  rotation?: number;
  tint?: number;

  texture_json?: string;
  partMap?: IPartData[];
}

export interface ILoaderQueue {
  type: 'queue';
  queue: {slug: string, callback: (loaded: any) => void}[];
}

export function isLoaderQueue(part: ILoaderQueue |ILoadedCosmetic | ILoadedObject): part is ILoaderQueue {
  return (part.type === 'queue');
}

let loaderQueue: IPixiLoaderPacket[] = [];

let getSharedResource = (packet: IPixiLoaderPacket) => {
  if (PIXI.Loader.shared.loading) {
    loaderQueue.push(packet);
  } else {
    let unloaded = _.filter(packet.slugs, (slug => !PIXI.Loader.shared.resources[slug]));
    if (unloaded.length === 0) {

      packet.callback({ res: PIXI.Loader.shared.resources, newLoad: false });

      if (loaderQueue.length > 0) {
        getSharedResource(loaderQueue.shift());
      }
    } else if (!PIXI.Loader.shared.loading) {
      unloaded.forEach(slug => PIXI.Loader.shared.add(slug, slug));
      PIXI.Loader.shared.load((loader, res) => {
        packet.callback({ res, newLoad: true });
        if (loaderQueue.length > 0) {
          getSharedResource(loaderQueue.shift());
        }
      });
    }
  }
};