import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'teacher-classroom-student-assignment-report',
  templateUrl: './teacher-classroom-student-assignment-report.component.html',
  styleUrls: ['./teacher-classroom-student-assignment-report.component.scss']
})
export class TeacherClassroomStudentAssignmentReportComponent implements OnInit {

  constructor(
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,

  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
  }

}
