import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'element-config-mcq-option-image',
  templateUrl: './element-config-mcq-option-image.component.html',
  styleUrls: ['./element-config-mcq-option-image.component.scss']
})
export class ElementConfigMcqOptionImageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
