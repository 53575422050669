import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// // pages/views
import { ParentChildrenComponent } from './parent-children/parent-children.component';
import { ParentActivityComponent } from './parent-activity/parent-activity.component';
import { ParentCurriculumComponent } from './parent-curriculum/parent-curriculum.component';

// // smaller components


@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule],
  declarations: [
    ParentChildrenComponent,
    ParentActivityComponent,
    ParentCurriculumComponent,
  ],
  exports: [
    ParentChildrenComponent,
    ParentActivityComponent,
    ParentCurriculumComponent,
  ]
})
export class UiParentModule {}
