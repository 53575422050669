import * as PIXI from 'pixi.js';
import * as _ from 'lodash';

export class CameraCanvas extends PIXI.Container {
  static MAX_ZOOM = 2;
  static VMULT = 2;

  cameraBounds: PIXI.Rectangle = new PIXI.Rectangle;
  worldBounds: PIXI.Rectangle = new PIXI.Rectangle;
  parentScale: PIXI.Point = new PIXI.Point(1, 1);

  private cameraTarget: {x: number, y: number};
  private cameraBuffer = 1;

  keyStates: any = {
    left: false,
    right: false,
    up: false,
    down: false
  };

  vX = 0;
  vY = 0;

  zoomC = 1;
  zoomTo = 1;

  setBounds(cameraBounds: PIXI.Rectangle, worldBounds: PIXI.Rectangle, parentScale?: PIXI.Point) {
    this.cameraBounds = cameraBounds;
    this.worldBounds = worldBounds;
    if (parentScale) {
      this.parentScale = parentScale;
    }
  }

  getGlobalCameraBounds(): PIXI.Rectangle {
    return new PIXI.Rectangle(
      (-this.x + this.cameraBounds.x / this.parentScale.x) / this.zoomC,
      (-this.y + this.cameraBounds.y / this.parentScale.y) / this.zoomC,
      this.cameraBounds.width / this.parentScale.x / this.zoomC,
      this.cameraBounds.height / this.parentScale.y / this.zoomC
    );
  }

  setLocalCameraTarget(target: {x: number, y: number}, percent: number = 0.4) {
    this.cameraTarget = target;
    this.cameraBuffer = percent;
    this.cameraJumpToTarget();
  }

  cameraJumpToTarget() {
    this.x = (this.cameraBounds.width / this.parentScale.x / 2 - this.cameraTarget.x) * this.zoomC;
    this.y = (this.cameraBounds.height / this.parentScale.x / 2 - this.cameraTarget.y) * this.zoomC;
  }

  zoomIn = (percent: number = 1) => {
    let n = this.zoomC * (1 + percent * 0.1);
    this.setZoom(n);
  }

  setZoom = (n: number) => {
    let minZoom = _.min([1, 
      this.cameraBounds.width / this.parentScale.x / this.worldBounds.width, 
      this.cameraBounds.height / this.parentScale.x / this.worldBounds.height
    ]);
    
    this.zoomTo = _.clamp(n, minZoom, CameraCanvas.MAX_ZOOM);
  }

  tbgraphic: PIXI.Graphics = new PIXI.Graphics;

  update = () => {
    if (this.zoomC !== this.zoomTo) {
      let newZoom: number;
      let diff = (this.zoomTo - this.zoomC) / 2;
      if (Math.abs(diff) < 0.01) {
        newZoom = this.zoomTo;
      } else {
        newZoom = this.zoomC + diff;
      }
      if (this.cameraTarget) {
        this.x += this.cameraTarget.x * (this.zoomC - newZoom);
        this.y += this.cameraTarget.y * (this.zoomC - newZoom);
      }

      this.zoomC = newZoom;
      this.scale.set(newZoom);
    }
    
    let diffX = this.cameraBounds.width - this.worldBounds.width * this.zoomC * this.parentScale.x;
    if (diffX > 0) {
      this.x = this.cameraBounds.x + diffX / 2;
      this.vX = 0;
    } else {
      if (this.keyStates.left) {
        this.vX += 1;
      }
      if (this.keyStates.right) {
        this.vX -= 1;
      }
      if (this.cameraTarget) {
        let pX = ((this.cameraTarget.x * this.zoomC + this.x) * this.parentScale.x  - this.cameraBounds.x) / this.cameraBounds.width;
        if (pX < this.cameraBuffer) {
          this.vX += (this.cameraBuffer - pX) * CameraCanvas.VMULT;
        } else if (pX > 1 - this.cameraBuffer) {
          this.vX += (1 - this.cameraBuffer - pX) * CameraCanvas.VMULT;
        }
      }

      if (this.vX !==0) {
        this.x += this.vX;
        this.vX *= 0.95;
        if (Math.abs(this.vX) < 0.001) {
          this.vX = 0;
        }
      }

      let max = this.cameraBounds.x / this.parentScale.x;
      let min = this.cameraBounds.right / this.parentScale.x - this.worldBounds.width * this.zoomC;
      if (this.x < min) {
        this.x = min;
        this.vX = 0;
      }else if (this.x >= max) {
        this.x = max;
        this.vX = 0;
      }
    }

    let diffY = this.cameraBounds.height - this.worldBounds.height * this.zoomC * this.parentScale.y;
    if (diffY > 0) {
      this.y = this.cameraBounds.y + diffY / 2;
      this.vY = 0;
    } else {
      let max = this.cameraBounds.y / this.parentScale.x;
      let min = this.cameraBounds.bottom / this.parentScale.x - this.worldBounds.height * this.zoomC;

      if (this.keyStates.up) {
        this.vY += 1;
      }
      if (this.keyStates.down) {
        this.vY -= 1;
      }
  
      if (this.cameraTarget) {
        let pY = ((this.cameraTarget.y * this.zoomC + this.y) * this.parentScale.y - this.cameraBounds.y) / this.cameraBounds.height;
        if (pY < this.cameraBuffer) {
          this.vY += (this.cameraBuffer - pY) * CameraCanvas.VMULT;
        }else if (pY > 1 - this.cameraBuffer) {
          this.vY += (1 - this.cameraBuffer - pY) * CameraCanvas.VMULT;
        }
      }
      if (this.vY !== 0) {
        this.y += this.vY;
        this.vY *= 0.95;
        if (Math.abs(this.vY) < 0.001) {
          this.vY = 0;
        }
      }

      if (this.y < min) {
        this.y = min;
        this.vY = 0;
      }else if (this.y >= max) {
        this.y = max;
        this.vY = 0;
      }
    }
  }
}


/*

[stage - cameraBounds (set opposite to Container)]
<scale: parentScale>
[container]
[CameraCanvas - this - scale (zoomC)]
[Tilemap - scale (1) - worldBounds]
[Target - target]



*/