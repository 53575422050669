import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyBlockArrSelectComponent } from './body-block-arr-select/body-block-arr-select.component';

@NgModule({
  declarations: [
    BodyBlockArrSelectComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    BodyBlockArrSelectComponent,
  ]
})
export class UiPartialModule { }
