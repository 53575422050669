import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl } from '@angular/forms';
import { IAssignment } from '../../data/collections/assignments.types';
import { IItemAttemptSession } from '../../ui-student/boosterpacks.service';

interface IDataRow {
  iconClass:string, 
  label: string,
  value: number,
}

@Component({
  selector: 'fiel-trial-admin',
  templateUrl: './fiel-trial-admin.component.html',
  styleUrls: ['./fiel-trial-admin.component.scss']
})
export class FielTrialAdminComponent implements OnInit {

  trialId:string = 'cambridge-stage6-2019'; // to do: fetch from white label
  totalRegistrationsNum = 11;
  data:IDataRow[] = [
    {iconClass: 'icon-teacher',    value:11, label:'Teacher Registrations'},
    {iconClass: 'icon-teacher',    value:11, label:'Teachers Accessed'},
    {iconClass: 'icon-classroom',  value:7, label:'Classes Created'},
    {iconClass: 'icon-student',    value:103, label:'Student Registrations'},
    {iconClass: 'icon-assignment', value:3, label:'Assessments Created (Heads Up)'},
    {iconClass: 'icon-assignment', value:22, label:'Assessments Created (Heads Down)'},
    {iconClass: 'icon-assignment', value:9, label:'Assessments Accessed (Heads Down)'},
    {iconClass: 'icon-booster',    value:81, label:'Booster Pack Checkpoints Assigned'},
    {iconClass: 'icon-booster',    value:0, label:'Booster Pack Checkpoints Completed'},
    {iconClass: 'icon-booster',    value:41, label:'Booster Pack Items Open'},
    {iconClass: 'icon-booster',    value:25, label:'Booster Pack Items Completed'},
  ];

  constructor(
    public router: Router,
    private auth: AuthService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }



  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      // params['classroomId'];
    });
    // this.collectData()
  }

  dateRangeFrom = new Date('2019-08-01');
  dateRangeTo = new Date('2019-08-21');
  collectionSnapshot = 'fieldTrialSnapshot'
  collectionAggregate = 'fieldTrialAggregate'
  dataOutput = new FormControl();

  collectData(){

    // this.afs
    //   .collection('users', ref => ref 
    //     .where('ui', '==', 'teacher')
    //     .where('trialId', '==', this.trialId)
    //   )
    //   .get()
    //   .toPromise()
    //   .then(res => {
    //     const teacherUsers:any[] = res.docs.map(doc=>{
    //       return {
    //         ... doc.data(),
    //         __id: doc.id
    //       }
    //     })
    //     const teacherUserIds = teacherUsers.map(user => user.__id);
    //     let teacherClassrooms = [];
    //     teacherUsers.forEach(teacherUser => {
    //       teacherClassrooms = teacherClassrooms.concat(teacherUser.classroomsAsTeacher);
    //     });
    //     teacherClassrooms = _.uniq(teacherClassrooms);
    //     this.dataOutput.setValue(JSON.stringify(teacherUserIds)); ////////
    //   })

    const teacherClassrooms = ["5o51Oli64mNtRKyfmYip","iK7dfNbJiiFa7Vgt86V2","Ig5VgFIwUu2QJeu8QCyo","MmnBsIa9MEJtNfUN15CD","YVahQUdFdczqid0a2fQb","EqWlkhFnCQP6n3Mf9SXp","jQhg7TBQXoGSPwnOdKOv"];
    const teacherUserIds = ["D53y2PZGVYNHxGBFLosSs8pp8qV2","Hgfi3EFizzSpBCCAVmDAcmk9tDa2","LMvJImuh2jh2HKuvrdvA5MKj90S2","YsmebUrXbfMasKgrM4qhTQ1JlcQ2","bsTt7uhbLwczP19n6QqgraMUXLn1","cL5Ie827Oab3f9GxOZmQujZ8wco2","dccDmo7ii3RFRFXuFl0sCpb5KaG3","l11huQdtOPewxnsi3Zs0WCEuadC2","r8dBUV5nmXcUv7BeLPKWrwYNdB22","ti48h9JL9BYUZack1LjkILTYKjk1","yp6del7IPZPNeQgVUEQMwl44aSR2"];

    // let students = []
    // Promise.all(teacherClassrooms.map(classroomId => {
    //   return this.afs
    //     .doc('classrooms/'+classroomId)
    //     .get()
    //     .toPromise()
    //     .then(res => {
    //       const classroomInfo = res.data()
    //       students = students.concat(classroomInfo.currentStudents);
    //     })
    // }))
    // .then(()=>{
    //   console.log('complete')
    //   students = _.uniq(students);
    //   this.dataOutput.setValue(JSON.stringify(students)); ////////
    // })


    // let assignments = {
    //   hu: 0,
    //   hd: 0,
    //   huc: 0,
    //   hdc: 0,
    // }
    // let headsDownAssignmentIds = []
    // Promise.all(teacherClassrooms.map(classroomId => {
    //   return this.afs
    //     .collection('assignments/', ref=>ref.where('classroom', '==', classroomId))
    //     .get()
    //     .toPromise()
    //     .then(res => {
    //       res.docs.forEach(doc=>{
    //         const asmt:IAssignment = <any>doc.data();
    //         if (asmt.isHeadsUp){
    //           assignments.hu ++;
    //           if (asmt.isDirectClose){ 
    //             assignments.huc ++;
    //           }
    //         }
    //         else {
    //           assignments.hd ++;
    //           headsDownAssignmentIds = headsDownAssignmentIds.concat(doc.id)
    //           if (asmt.isDirectClose){ 
    //             assignments.hdc ++;
    //           }
    //         }
    //       })
    //     })
    // }))
    // .then(()=>{
    //   console.log('complete')
    //   headsDownAssignmentIds = _.uniq(headsDownAssignmentIds)
    //   this.dataOutput.setValue(JSON.stringify(headsDownAssignmentIds)); ////////
    // })

    // const hdAids = [ "3NEikcRypq9wsqyykB9K","364z5ZzWJMfsOm6NaDl0","C2udgpiIz9l4Im166Vym","CCC0YLCSyxJQhDznylSw","EiTW33pF8miAilbNGgNS","VvjEGz3XTcVpOTxqcJl3","XgKRsByGv65uCDTeEVSe","YwTaPPjHl5t51mMzRUgr","bOboHUhQWnrEqYF2si1X","wvNQQ5NeBKmpN9JgF1wF","x1Kb4z4wu1TwDbfgVGnL","y8mUoePZOABW4QFPjG58","9SlCBRDa3L1KkRowxPuf","ADpL4WDEbQU43uhW2pgV","DbQyrtjHGJpwB0H9Qdrp","Ii20SaAGPkqp14kp0sTJ","Mv9yq1HBeFSweCFgjDrd","Pa6TxSBB70LdHw4ycuwS","RAv1AsCGwfz70q6hclwr","jMF6BzFFKlH5kx8p6gbJ","woGxz1kr19b9htLem2xU","Ron97i28su1lcjm6wlKS"]
    // let numTouched = 0;
    // Promise.all(hdAids.map(assignmentId => {
    //     return this.afs
    //       .collection('assignmentTaskSubmissions/', ref=>ref.where('assignmentId', '==', assignmentId).limit(1) )
    //       .get()
    //       .toPromise()
    //       .then(res => {
    //         if (res.docs.length > 0){
    //           numTouched ++;
    //         }
    //       })
    //   }))
    //   .then(()=>{
    //     console.log('complete', numTouched)
    //     this.dataOutput.setValue(JSON.stringify(numTouched)); ////////
    //   })


    // let bpl = {
    //   total: new Map(),
    //   completed: new Map(),
    // }
    // Promise.all(teacherClassrooms.map(classroomId => {
    //   return this.afs
    //     .collection('boosterPacksItemAttemptSession/', ref=>ref
    //       .where("classroomIds", "array-contains", classroomId)
    //       .limit (300)
    //     )
    //     .get()
    //     .toPromise()
    //     .then(res => {
    //       console.log('log', res.docs.length)
    //       res.docs.forEach(doc=>{
    //         const attempt:IItemAttemptSession = <any>doc.data();
    //         const key = [attempt.uid, attempt.boosterPackItemId].join('/');
    //         bpl.total.set(key, true);
    //         if (attempt.isComplete){
    //           bpl.completed.set(key, true);
    //         }
    //       })
    //     })
    // }))
    // .then(()=>{
    //   console.log('complete', bpl.total.keys)
    //   this.dataOutput.setValue(JSON.stringify([
    //     bpl.total.size,
    //     bpl.completed.size,
    //   ])); ////////
    // })

    // let checkpoints = {
    //   total: 0,
    //   completed: 0,
    // }
    // Promise.all(teacherClassrooms.map(classroomId => {
    //   return this.afs
    //     .collection('boosterPacksItemCheckpoints/', ref=>ref.where('cause_classroomId', '==', classroomId))
    //     .get()
    //     .toPromise()
    //     .then(res => {
    //       res.docs.forEach(doc=>{
    //         const checkpointAssignment = <any>doc.data();
    //         if (teacherUserIds.indexOf(checkpointAssignment.uid) === -1){
    //           checkpoints.total ++;
    //           if (checkpointAssignment.isComplete){ 
    //             checkpoints.completed ++;
    //           }
    //         }
    //       })
    //     })
    // }))
    // .then(()=>{
    //   console.log('complete')
    //   this.dataOutput.setValue(JSON.stringify(checkpoints)); ////////
    // })

  }
  writeData(){
    
  }
  getData(){

  }

}
