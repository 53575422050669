import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CollectionsService } from '../../data/collections.service';
import { IAssignment } from '../../data/collections/assignments.types';
import { Observable } from 'rxjs';
import { ITask } from '../../data/collections/tasks.types';
import { serverTimestamp } from '../../data/timestamp';
import { FormControl } from '@angular/forms';
import { IClassroom } from '../../data/collections/classrooms.types';
import { AuthService } from '../../core/auth.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { ClassroomService, ICurricPerf } from '../classroom.service';
import { SORT_NAMES_ASC } from '../../data/sort/names';
import { renderUsernameFromEmail } from '../../data/accounts/pseudo-email-usernames';
import { ensureFirstLastName } from '../../data/name-split';
import { IUser } from '../../data/collections/users.types';
import { IClassroomStudent } from '../teacher-classroom-students/teacher-classroom-students.component';
import { UsersService } from '../../core/users.service';
import { CURRICULUM_MODEL } from '../../data/cambridge/curriculum';

interface IStudentReportInfo extends IClassroomStudent {
  isLoadingCheckpoints:boolean,
  numOutstandingCheckpoints: number,
  isLoadingPerformance:boolean,
  curriculumPerformance: Map<string, ICurricPerf>,
}
@Component({
  selector: 'teacher-classroom-reports',
  templateUrl: './teacher-classroom-reports.component.html',
  styleUrls: ['./teacher-classroom-reports.component.scss']
})
export class TeacherClassroomReportsComponent implements OnInit {

  public assignments: IAssignment[];
  public customAssignmentName = new FormControl('');
  public customAssignmentIsHeadsUp = new FormControl(false);
  public classConfig:IClassroom = {name:''};
  public classroomId:string;
  public hasAssignments:boolean;
  public isAssignmentPickerOpen:boolean;
  public curriculumModel = CURRICULUM_MODEL;
  public curriculumTopics = CURRICULUM_MODEL.strands.filter(strand => !strand.isLocked);
  
  // [
  //   {code:'Nn', caption:'Numbers and the number system', boosterPackId:''},
  //   {code:'Ma', caption:'Area and perimeter', boosterPackId:''},
  //   {code:'Dh', caption:'Organising, categorising and representing data', boosterPackId:''},
  // ]
  public studentCheckpointsRef = new Map()
  public studentPerformanceMatrix = new Map()

  private assignmentsCollection: AngularFirestoreCollection<IAssignment>;
  private assignmentSortingValRef:Map<string, any> = new Map();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private usersService: UsersService,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) { 

    // <sample data>
    // let uid = 'GIvBSCWtKdVDsNJ642NZV3Gd6Zn1'
    // this.studentCheckpointsRef.set(uid, 6);
    // let performance = new Map();
    // performance.set('Nn', {
    //   performance: 62,
    //   isFailed: true,
    // });
    // performance.set('Ma', {
    //   performance: 85,
    //   isPassed: true,
    // });
    // performance.set('Dh', {
    //   performance: 0,
    //   isUngraded: true
    // });
    // this.studentPerformanceMatrix.set(uid, performance);
    // </sample data>

  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId); // no side-effects
      this.loadAssignments(this.classroomId);
    });
  }

  logout(){
    this.auth.signOut();
  }

  getStudentNumCheckpoints(uid:string){
    if (this.studentCheckpointsRef.has(uid)){
      return this.studentCheckpointsRef.get(uid);
    }
    return 0;
  }

  // getStudentPerformance(uid:string, curriculumId:string){
  //   let studentPerformance = this.studentPerformanceMatrix.get(uid);
  //   if (studentPerformance){
  //     let nodePerformance = studentPerformance.get(curriculumId);
  //     if (nodePerformance){
  //       return nodePerformance;
  //     }
  //   }
  //   return {performance: 0, isUngraded: true}
  // }

  loadClassroom(classroomId:string){ 
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
        this.loadStudents(this.classConfig.currentStudents);
      });
    
  }

  loadStudents(studentIds: string[]) {
    if (this.activeStudents && this.activeStudents.length > 0) {
      console.warn('Reloading student accounts...');
    }
    this.activeStudents = [];
    if (studentIds) {
      studentIds.forEach(uid => {
        let studentPacket: IStudentReportInfo = {
          uid,
          displayName: '',
          firstName: '',
          lastName: '',
          username: '',
          isLoadingCheckpoints: true,
          isLoadingPerformance: true,
          numOutstandingCheckpoints: 0,
          curriculumPerformance: new Map(),
        }
        this.activeStudents.push(studentPacket);
        this.usersService
          .getUserInfo(uid)
          .subscribe((userInfo:IUser)=>{
            ensureFirstLastName(userInfo);
            studentPacket.displayName = userInfo.displayName;
            studentPacket.firstName = userInfo.firstName;
            studentPacket.lastName = userInfo.lastName;
            studentPacket.username = renderUsernameFromEmail(userInfo.email);
            this.applyStudentSorting(); 
          })

        this.classroomService
          .getStudentBoosterPerformance(uid)
          .subscribe(performance => {
            studentPacket.isLoadingPerformance = false;
            studentPacket.curriculumPerformance = performance;
          })

        this.classroomService
          .getStudentOutstandingCheckpoints(uid)
          .subscribe(numCheckpoints => {
            studentPacket.isLoadingCheckpoints = false;
            studentPacket.numOutstandingCheckpoints = numCheckpoints;
          })
          
      });
    }
  }
  activeStudents:IStudentReportInfo[] = [];
  applyStudentSorting = _.throttle(() => {
    this.activeStudents = this.activeStudents.sort(SORT_NAMES_ASC);
  }, 50)

  getStudentPerformance(studentPacket:IStudentReportInfo, boosterPackId:string) : ICurricPerf  {
    // console.log('getStudentPerformance', boosterPackId, studentPacket.curriculumPerformance.has(boosterPackId))
    if ( studentPacket.curriculumPerformance && studentPacket.curriculumPerformance.has(boosterPackId)){
      return studentPacket.curriculumPerformance.get(boosterPackId);
    }
    return {
      isFailed: false,
      isPassed: false,
      isUngraded: true,
      performance: 0,
      performanceDisplay: '0%',
    }
  }

  archiveAssignment(assignmentId:string, assingmentName?:string){
    if (confirm('Archive this assignment? ('+assingmentName+')')){
      this.afs.doc('assignments/'+assignmentId).update({
        isArchived: true
      }).then( () => {
        console.log(assignmentId + ' : assignment has been archived!')
      })  
    }
  }

  loadAssignments(classroomId:string){
    this.assignmentsCollection = this.afs.collection<IAssignment>('assignments', ref => {
      return ref
        .where('classroom', '==', this.classroomId)
        .where('isDirectStart', '==', true)
        .where('isArchived', '==', false)
    });
    CollectionsService.getValuesIdentified(this.assignmentsCollection).subscribe(val => {
      console.log('assignments', val)
      this.assignments = val;
      this.assignments.forEach(assignment => {
        assignment.timeStartCalc = assignment.timeStart || assignment.timeDirectStart;
        if (this.assignmentSortingValRef.has(assignment.__id)){
          assignment.timeCloseCalc = this.assignmentSortingValRef.get(assignment.__id);
        }
        else{
          assignment.timeCloseCalc = assignment.timeClose || assignment.timeDirectClose || assignment.timeModified;
          this.assignmentSortingValRef.set(assignment.__id, assignment.timeCloseCalc);
        }
      })
      this.hasAssignments = (val && val.length > 0);
    })
  }

  renderAssignmentTimeClose(assignment:IAssignment){
    if (assignment.isDirectClose){
      return assignment.timeDirectClose;
    }
    else{
      return assignment.timeClose;
    }
  }

  directOpenAssignment(assignment:IAssignment){
    this.afs.doc<IAssignment>('assignments/'+assignment.__id).update({
      isDirectStart: true,
      isDirectClose: false,
      timeDirectStart: serverTimestamp(),
    });
  }

  directCloseAssignment(assignment:IAssignment){
    this.afs.doc<IAssignment>('assignments/'+assignment.__id).update({
      isDirectClose: true,
      timeDirectClose: serverTimestamp(),
    });
  }

  directReopenAssignment(assignment:IAssignment){
    this.afs.doc<IAssignment>('assignments/'+assignment.__id).update({
      isDirectStart: true,
      isDirectClose: false,
    });
  }

  checkAssignmentOpen(assignment:IAssignment){
    return assignment.isDirectStart || this.checkAssignmentOpenDateRange(assignment);
  }
  checkAssignmentOpenDateRange(assignment:IAssignment){
    return false;
  }

  // renameAssignment(assignment:IAssignment, newName:string){
  // }

  createAssignmentFromTask(task:ITask, useCustomName:boolean=false){
    let assignmentName = task.name;
    let isHeadsUp = (task.isHeadsUp === true) || this.customAssignmentIsHeadsUp.value;
    if (useCustomName && this.customAssignmentName.value){
      assignmentName = this.customAssignmentName.value;
    }
    let assignment:IAssignment = {
      classroom: this.classroomId,
      isArchived: false, 
      isDirectClose: false,
      isDirectStart: false,
      name: assignmentName,
      tasks: [task.__id],
      isHeadsUp,
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
    }
    this.afs.collection<IAssignment>('assignments').add(assignment);
  }

  // updateAssignment



}
