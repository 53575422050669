import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';
import { ICurriculumOutcome } from '../../data/collections/curriculum-outcomes.types';

@Component({
  selector: 'form-parent-selector',
  templateUrl: './form-parent-selector.component.html',
  styleUrls: ['./form-parent-selector.component.scss']
})
export class FormParentSelectorComponent implements OnInit {

  orgSearchString = new FormControl('');
  activeOrg = new FormControl('any');
  teacherSearchString = new FormControl('');
  activeTeacher = new FormControl('any');
  studentSearchString = new FormControl('');
  activeStudent = new FormControl('any');
  parentSearchString = new FormControl('');
  activeParent = new FormControl('');

  constructor() { }

  ngOnInit() {
  }

}
