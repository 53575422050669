import { Component, OnInit } from '@angular/core';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Router } from '@angular/router';

@Component({
  selector: 'index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(
    private whitelabel: WhitelabelService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.router.navigate(this.whitelabel.currentHomepageRoute.split('/'));
  }

}
