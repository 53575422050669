import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import { FirebaseTimestamp } from 'src/app/data/base.types';
import { ILoadedBody, ILoadedCosmetic } from '../DragonAvatar/DragonInterfaces';
import { IPlacementOptions } from '../AvatarScene';
import { CustomAnimation } from '../CustomAnimations';

export enum ObjectInteractionType {
  MINI_DIALOGUE = 'mini-dialogue',
  CUSTOM_ANIMATION = 'custom-animation',
  DRAGON_ANIMATION = 'dragon-animation'
}

export const mapData1: IMapData = {
  startLoc: {x: 18, y: 13},
  tiledata: { width: 60, height: 40, tileheight: 128, tilewidth: 128,
    layers: [
      {
        width: 60, height: 40, x: 0, y: 0, opacity: 1, type: 'tilelayer', data: [
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 47, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 47, 1,
          1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        ]
      },
      {
        width: 15, height: 10, x: 0, y: 0, opacity: 1, type: 'tilelayer', data: [
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, 22, 22, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 18, -1,
          -1, 22, 29, -1, 92, 92, 92, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 66, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, 92, -1, -1, -1, 66, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, 19, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, 19, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        ]
      },
      { width: 15, height: 10, x: 0, y: 0, opacity: 1, type: 'objectgroup', data: undefined},
      {
        width: 15, height: 10, x: 0, y: 0, opacity: 1, type: 'tilelayer', data: [
          -1, -1, -1, -1, 85, 85, 85, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, 85, 85, 85, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, 85, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, 89,
          -1, -1, 85, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
          -1, 89, 89, 89, 89, 89, 89, 89, 89, 89, 89, 89, 89, 89, -1,
          -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1, -1,
        ]
      },
    ]
  },
  tileset: {
    data: {
      image: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/game-assets%2Ftemp%2Fspritesheet_tiles.png?alt=media&token=84bccbd1-35ae-4ef5-b309-0a9afe2ec246',
      tileheight: 128,
      tilewidth: 128,
      spacing: 2,
      margin: 0,
      columns: 7,
    },
    defs: [
      {
        index: 1,
        wall: true
      },
      {
        index: 38,
        wall: true
      },
      {
        index: 92,
        wall: true,
      },
      {
        index: 22,
        zThis: -60
      }
    ]
  },
  objects: [
    { slug: 'Hedgehog', placement: {x: 15, y: 15}, interactions: [{type: ObjectInteractionType.CUSTOM_ANIMATION, data: CustomAnimation.BOUNCE}]},
    { slug: 'Hedgehog', placement: {x: 17, y: 15}, interactions: [{type: ObjectInteractionType.CUSTOM_ANIMATION, data: CustomAnimation.BOUNCE}]},
    { slug: 'Hedgehog', placement: {x: 18, y: 16}, interactions: [{type: ObjectInteractionType.CUSTOM_ANIMATION, data: CustomAnimation.BOUNCE}]},
    { slug: 'Hedgehog', placement: {x: 10, y: 15}, interactions: [{type: ObjectInteractionType.CUSTOM_ANIMATION, data: CustomAnimation.BOUNCE}]}
  ],
  npcs: [
    {
      body: 'Character',
      cosmetics: ['GreenShirt', 'hair#337733', 'skin#ffcc33', 'PoofHair', 'BlueJeans'],
      interactions: [{ type: ObjectInteractionType.MINI_DIALOGUE, data: 'I\'m a giant!' }, {type: ObjectInteractionType.DRAGON_ANIMATION, data: 'Pondering1'}],
      placement: { x: 16, y: 12, scale: 2, faceLeft: true }
    },
    {
      body: 'Character',
      cosmetics: ['BlueShirt', 'hair#333377', 'skin#ffcc33', 'Pony', 'BlueJeans'],
      interactions: [{ type: ObjectInteractionType.MINI_DIALOGUE, data: 'I\'m soooo tiny!' }, {type: ObjectInteractionType.CUSTOM_ANIMATION, data: CustomAnimation.FLIP}],
      placement: { x: 13, y: 12, scale: 0.5 }
    }
  ],
};

export interface IMapData {
  tiledata: ITiledMapData;
  tileset: ITileset;
  startLoc: {x: number, y: number};

  objects?: IObjectChunk[];
  npcs?: INPCChunk[];
}

export interface ILoadedMapData {
  // layers: ITileLayer[];
  tiledata: ITiledMapData;
  tileset: ITileset;
  startLoc: {x: number, y: number};

  objects?: ILoadedObjectChunk[];
  npcs?: ILoadedNPCChunk[];
}

export interface ITiledMapData {
  height: number;
  width: number;
  layers: ITileLayer[];
  tileheight: number;
  tilewidth: number;
}

export interface ITileLayer {
  data: number[];
  height: number;
  width: number;
  type: 'tilelayer' | 'objectgroup';
  opacity: number;
  x: number;
  y: number;

  id?: number;
  name?: string;
}

export interface ITileset {
  data: ITiledTileset;
  defs: ITileDefinition[];
}

export interface ITiledTileset {
  image: string;
  columns: number;

  tileheight: number;
  tilewidth: number;

  margin: number;
  spacing: number;

  tilecount?: number;
  name?: string;
  imageheight?: number;
  imagewidth?: number;
}

export interface ITileDefinition {
  index: number;
  wall?: boolean;
  zThis?: number;
  zObj?: number;
}

export interface IObjectChunk {
  uid?: string;
  scene?: string;
  timestamp?: FirebaseTimestamp;

  slug: string;
  placement: IPlacementOptions;
  interactions?: IInteractionOptions[];
}

export interface ILoadedObjectChunk {
  uid?: string;
  scene?: string;

  placement: IPlacementOptions;
  interactions?: IInteractionOptions[];
  data: ILoadedObject;
}

export interface INPCChunk {
  uid?: string;
  scene?: string;
  timestamp?: FirebaseTimestamp;

  placement: IPlacementOptions;

  body: string;
  cosmetics: string[];
  defaultAnimation?: string;
  interactions?: IInteractionOptions[];
}

export interface ILoadedNPCChunk {
  uid?: string;
  scene?: string;

  placement: IPlacementOptions;

  body: ILoadedBody;
  cosmetics: ILoadedCosmetic[];
  defaultAnimation?: string;
  interactions?: IInteractionOptions[];
}

export interface IInteractionOptions {
  type: ObjectInteractionType;
  data: string;
}

export interface IObjectDef {
  slug: string;
  json_url: string;
  png_url: string;
}

export interface ILoadedObject {
  slug: string;
  texture: PIXI.Texture;

  type: 'object';

  json_obj: IObjectData;
}


export interface IObjectData {
  slug: string;

  x: number;
  y: number;
  width: number;
  height: number;
  tint?: number;
  wall?: boolean;
  zObj?: number;
}

export const ObjectMap = {
  defs: {
    Mickey: {
      slug: 'Mickey',
      json_url: 'Mickey',
      png_url: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/dragon-bones-assets%2Favatar-skeletons-cosmetics%2FMickey_W.png?alt=media&token=23cbb5f0-56d1-4092-abc4-671ec821cd1c'
    },
    TallMickey: {
      slug: 'TallMickey',
      json_url: 'TallMickey',
      png_url: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/dragon-bones-assets%2Favatar-skeletons-cosmetics%2FMickey_W.png?alt=media&token=23cbb5f0-56d1-4092-abc4-671ec821cd1c'
    },
    Hedgehog: {
      slug: 'Hedgheog',
      json_url: 'Hedgehog',
      png_url: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/dragon-bones-assets%2Favatar-skeletons-cosmetics%2Fhedgehog_body.png?alt=media&token=fb5e4502-714a-4d1e-b778-57d2682fed73'
    }
  },
  jsons: {
    Mickey: {
      x: 0,
      y: 0,
      width: 140,
      height: 52,
      tint: 0xff0000,
      slug: 'Mickey'
    },
    TallMickey: {
      x: 0,
      y: -10,
      width: 50,
      height: 300,
      tint: 0x0000cc,
      wall: true,
      slug: 'TallMickey'
    },
    Hedgehog: {
      x: 0,
      y: 0,
      width: 72,
      height: 40,
      wall: false,
      slug: 'Hedgehog'
    }
  },
  getObject: (chunk: IObjectChunk): ILoadedObjectChunk => {
    let def: IObjectDef = ObjectMap.defs[chunk.slug];
    let json_obj: IObjectData = ObjectMap.jsons[def.json_url];
    let texture = PIXI.Texture.from(def.png_url);

    return { placement: chunk.placement, interactions: chunk.interactions, data: { slug: chunk.slug, json_obj, texture, type: 'object' } };
  }
};
