import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IBoosterPackItemOptionKey } from 'src/app/data/cambridge/types';
import { TranslateService } from '../../core/translate.service';

@Component({
  selector: 'student-booster-options',
  templateUrl: './student-booster-options.component.html',
  styleUrls: ['./student-booster-options.component.scss']
})
export class StudentBoosterOptionsComponent {
  @Input() itemOptionKeys: IBoosterPackItemOptionKey[] = [];
  @Input() itemOptionStates: any = {};
  @Output() updated = new EventEmitter<any>();
  hovering: IBoosterPackItemOptionKey;

  BUTTON_KEY = 'training_mode';

  hardcodedTranslations = {
    'TRAINING': {
      'en' : 'Training',
      'fr' : 'Entrainement',
      'de' : 'Training',
      'pt' : 'Treino',
    },
    'CHALLENGE': {
      'en' : 'Challenge',
      'fr' : 'Course',
      'de' : 'Wettbewerb',
      'pt' : 'Desafio',
    }
  }

  constructor(
    private translateService: TranslateService
  ) { }

  getTranslation(textId:string){
    if (this.hardcodedTranslations[textId]){
      return this.hardcodedTranslations[textId][this.translateService.getLangCode()];
    }
    return textId;
  }

  disableOption(key: string){
    // this.itemOptionStates[key]
    this.toggleOption(key, false);
  }

  enableOption(key: string){
    this.toggleOption(key, true);
  }

  toggleOption(key: string, newState:boolean) {
    this.itemOptionStates[key] = newState;
    this.updated.emit(this.itemOptionStates);
  }
}
