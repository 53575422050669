import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { IQuestionConfig, elementTypes, elementIconById, QuestionState } from '../models/index';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'assessment-runner',
  templateUrl: './assessment-runner.component.html',
  styleUrls: ['./assessment-runner.component.scss']
})
export class AssessmentRunnerComponent implements OnInit, OnChanges {

  @Input() isTeacherView:boolean;
  @Input() currentQuestion:IQuestionConfig;
  @Input() currentQuestionIndex:number;
  @Input() totalQuestions:number;
  @Input() isShowingSolution:boolean;
  @Input() isHeadsUpMode:boolean;
  @Input() questionStateMaster:Map<number, any>;
  @Input() isFirstQuestion:boolean;
  @Input() isLastQuestion:boolean;
  @Input() isSubmitted:boolean;
  @Output() prevQuestion = new EventEmitter<null>();
  @Output() nextQuestion = new EventEmitter<null>();

  questionState:QuestionState = new Map();
  lastTrackedQuestion;
  lastTrackedQuestionStateMaster;
  isCalculatorShown:boolean;
  isHighContrast:boolean;
  isScratchpadShown:boolean;
  isZoomSettingsShown:boolean;
  questionTextSize = new FormControl(1);

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(){
    if (this.lastTrackedQuestion !== this.currentQuestion){
      this.lastTrackedQuestion = this.currentQuestion;
    }
    if (this.lastTrackedQuestionStateMaster !== this.questionStateMaster){
      if (!this.questionStateMaster){
        this.questionState = new Map();
      }
      else{
        this.questionState = this.questionStateMaster;
      }
      this.lastTrackedQuestionStateMaster = this.questionState;
    }
  }

  isInputLocked(){
    if (this.isHeadsUpMode){
      if (this.isTeacherView){
        return  this.isShowingSolution;
      }
      else{
        return this.isSubmitted;
      }
    }
    
  }

  isSolutionShown(){
    return this.isTeacherView && this.isShowingSolution;
  }
  
  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  toggleCalculator(){
    this.isCalculatorShown = !this.isCalculatorShown;
  }
  toggleHiContrast(){
    this.isHighContrast = !this.isHighContrast
  }
  toggleScratchPad(){
    this.isScratchpadShown = !this.isScratchpadShown
  }
  
}
