import * as _ from 'lodash';
import { ICosmeticInfo, CosmeticCategory, CosmeticUnlock, IUserAvatarCosmeticsEarned } from 'src/app/data/collections/heads-up-lobby';
import { ICosmeticSetting } from '../../avatar-cosmetic-menu/avatar-cosmetic-menu.component';

export class CosmeticInfoList {
  static OWN_ALL = false;
  static SHOW_HIDDEN = false;

  private cosmetics: { [category: string]: { [slug: string]: ICosmeticInfo } } = {};

  constructor() { }

  add(cosmetic: ICosmeticInfo) {
    if (!this.cosmetics[cosmetic.cosmeticCategory]) {
      this.cosmetics[cosmetic.cosmeticCategory] = {};
    }

    this.cosmetics[cosmetic.cosmeticCategory][cosmetic.slug] = cosmetic;
  }

  load(cosmetics: ICosmeticInfo[], unlocks: IUserAvatarCosmeticsEarned[]) {
    // console.log(cosmetics, unlocks);
    cosmetics.forEach(cosmetic => {
      if (CosmeticInfoList.OWN_ALL) {
        (cosmetic as ICosmeticInfo).owned = true;
      } else if (cosmetic.unlock === CosmeticUnlock.BASIC) {
        (cosmetic as ICosmeticInfo).owned = true;
      } else if (_.find(unlocks, {slug: cosmetic.slug})){
        (cosmetic as ICosmeticInfo).owned = true;
      } else {
        (cosmetic as ICosmeticInfo).owned = false;
      }
      this.add(cosmetic);
    });
  }

  owns(slug: string, category: CosmeticCategory): boolean {
    return this.cosmetics[category] && this.cosmetics[category][slug] && (this.cosmetics[category][slug].owned || this.cosmetics[category][slug].unlock === CosmeticUnlock.BASIC);
  }

  acquire(slug: string, category: CosmeticCategory) {
    let newObj = _.cloneDeep(this.cosmetics[category][slug]);
    this.cosmetics[category][slug] = newObj;
  }

  get(slug: string, category: CosmeticCategory): ICosmeticInfo {
    if (this.cosmetics[category]) {
      return this.cosmetics[category][slug];
    } else {
      return undefined;
    }
  }

  toCosmeticMenuSettings(): ICosmeticSetting[] {
    let m: ICosmeticSetting[] = [];

    for (let v of Object.keys(this.cosmetics)) {
      for (let w of Object.keys(this.cosmetics[v])) {
        // let info = this.cosmetics[v][w];
        let config: ICosmeticInfo = this.cosmetics[v][w];

        // if (info.cosmeticCategory === CosmeticCategory.SKIN) {
        //   config = {
        //     slug: info.slug,
        //     color: '#' + info.slug.split('#')[1],
        //     sortPriority: (info.sortPriority || 0) + (this.owned(info) ? 0 : 10),
        //     unlock: this.owned(info) ? undefined : info.unlock
        //   };
        // } else {
        //   config = {
        //     color: info.thumbnailColor,
        //     slug: info.slug,
        //     img: info.thumbnail,
        //     isSVG: info.thumbnailSVG,
        //     sortPriority: (info.sortPriority || 0) + (this.owned(info) ? 0 : 10),
        //     unlock: this.owned(info) ? undefined : info.unlock
        //   };
        // }
        let settings = {
          cosmeticType: config.cosmeticCategory,
          config
        };

        m.push(settings);
      }
    }
    m = _.sortBy(m, el => {
      let n = (el.config.sortPriority || 0) + (el.config.owned ? 0 : 10)

      return n;
    });
    // console.log(m);
    return m;
  }
}