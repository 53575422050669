import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CollectionsService } from '../../data/collections.service';
import { IAssignment } from '../../data/collections/assignments.types';
import { Observable } from 'rxjs';
import { ITask } from '../../data/collections/tasks.types';
import { serverTimestamp } from '../../data/timestamp';
import { FormControl } from '@angular/forms';
import { IClassroom } from '../../data/collections/classrooms.types';
import { AuthService } from '../../core/auth.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { ClassroomService } from '../classroom.service';
import { AssignmentService } from '../assignment.service';


@Component({
  selector: 'teacher-classroom-assignments',
  templateUrl: './teacher-classroom-assignments.component.html',
  styleUrls: ['./teacher-classroom-assignments.component.scss']
})
export class TeacherClassroomAssignmentsComponent implements OnInit {

  public assignments: IAssignment[];
  public customAssignmentName = new FormControl('');
  public customAssignmentIsHeadsUp = new FormControl(false);
  public classConfig:IClassroom = {name:''};
  public classroomId:string;
  public hasAssignments:boolean;
  public isAssignmentPickerOpen:boolean;
  public lastAssignmentIdCreated:string;

  private assignmentsCollection: AngularFirestoreCollection<IAssignment>;
  private assignmentSortingValRef:Map<string, any> = new Map();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private classroomService: ClassroomService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
    private assignmentService: AssignmentService
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadClassroom(this.classroomId); // no side-effects
      this.loadAssignments(this.classroomId);
    });
  }

  logout(){
    this.auth.signOut();
  }

  loadClassroom(classroomId:string){ // , then:Function
    this.classroomService
      .getClassroomInfo(classroomId)
      .subscribe( classroom => {
        this.classConfig = classroom;
      });
  }

  archiveAssignment(assignmentId:string, assingmentName?:string){
    if (confirm('Archive this assignment? ('+assingmentName+')')){
      this.afs.doc('assignments/'+assignmentId).update({
        isArchived: true
      }).then( () => {
        console.log(assignmentId + ' : assignment has been archived!')
      })  
    }
  }

  

  loadAssignments(classroomId:string){
    this.assignmentsCollection = this.afs.collection<IAssignment>('assignments', ref => {
      return ref
        .where('classroom', '==', this.classroomId)
        .where('isDirectClose', '==', false)
        .where('isArchived', '==', false)
    });
    CollectionsService.getValuesIdentified(this.assignmentsCollection).subscribe(val => {
      console.log('assignments', val)
      this.assignments = val;
      this.assignments.forEach(assignment => {
        assignment.timeStartCalc = assignment.timeStart || assignment.timeDirectStart;
        if (this.assignmentSortingValRef.has(assignment.__id)){
          assignment.timeCloseCalc = this.assignmentSortingValRef.get(assignment.__id);
        }
        else{
          assignment.timeCloseCalc = assignment.timeClose || assignment.timeDirectClose || assignment.timeModified;
          this.assignmentSortingValRef.set(assignment.__id, assignment.timeCloseCalc);
        }
      })
      this.hasAssignments = (val && val.length > 0);
    })
  }

  renderAssignmentTimeClose(assignment:IAssignment){
    if (assignment.isDirectClose){
      return assignment.timeDirectClose;
    }
    else{
      return assignment.timeClose;
    }
  }

  directOpenAssignment(assignment:IAssignment){
    this.afs.doc<IAssignment>('assignments/'+assignment.__id).update({
      isDirectStart: true,
      isDirectClose: false,
      timeDirectStart: serverTimestamp(),
    });
  }

  directCloseAssignment(assignment:IAssignment, skipNav:boolean=false){
    this.assignmentService.directCloseAssignment(assignment.__id);
    if (!skipNav){
      this.router.navigate(['teacher','classroom', this.classroomId,'assignment', assignment.__id, 'report']);
    }
  }

  directReopenAssignment(assignment:IAssignment){
    this.afs.doc<IAssignment>('assignments/'+assignment.__id).update({
      isDirectStart: true,
      isDirectClose: false,
    });
  }

  checkAssignmentOpen(assignment:IAssignment){
    return assignment.isDirectStart || this.checkAssignmentOpenDateRange(assignment);
  }
  checkAssignmentOpenDateRange(assignment:IAssignment){
    return false;
  }

  closeActiveHeadsUps(){
    this.assignments.forEach(assignment => {
      if (assignment.isHeadsUp && !assignment.timeDirectClose){
        this.directCloseAssignment(assignment, true);  
      }
    })
  }
  // renameAssignment(assignment:IAssignment, newName:string){
  // }

  isCreatingAsmt:boolean;
  createAssignmentFromTask(task:ITask, useCustomName:boolean=false){
    if (this.isCreatingAsmt){
      return;
    }
    this.isCreatingAsmt = true;
    let assignmentName = task.name;
    let isHeadsUp = (task.isHeadsUp === true) || this.customAssignmentIsHeadsUp.value;
    if (isHeadsUp){
      this.closeActiveHeadsUps()
    }
    if (useCustomName && this.customAssignmentName.value){
      assignmentName = this.customAssignmentName.value;
    }
    let assignment:IAssignment = {
      classroom: this.classroomId,
      isArchived: false, 
      isDirectClose: false,
      isDirectStart: false,
      name: assignmentName,
      tasks: [task.__id],
      trialId: this.auth.getUserTrialId(),
      instScopeIds: this.auth.getUserInstScope(),
      isHeadsUp,
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
    }
    this.afs.collection<IAssignment>('assignments')
      .add(assignment)
      .then(docRef => {
        assignment.__id = docRef.id;
        this.lastAssignmentIdCreated = assignment.__id;
        if (isHeadsUp){
          this.gotoHeadsUp(assignment);
        }
        else{
          if (confirm('Open the assessment for students right now?')){
            this.directOpenAssignment(assignment);
            this.focusOnLastAssignment();
          }
        }
        this.isCreatingAsmt = false;
      })
  }

  focusOnLastAssignment(){
    setTimeout(()=>{
      const el = document.getElementsByClassName('is-last-created-assignment');
      if (el && el[0]){
        console.log('focusOnLastAssignment')
        el[0].scrollIntoView({behavior: 'smooth'});
      }
    }, 250)
  }

  isLastAssignmentCreated(assessment:IAssignment){
    return this.lastAssignmentIdCreated === assessment.__id
  }

  openCustomAssessmentPicker(){
    if (prompt() === 'rosebud'){
      this.isAssignmentPickerOpen = true;
    }
  }

  gotoHeadsUp(assignment:IAssignment){
    this.directOpenAssignment(assignment);
    this.router.navigate([
      'teacher',
      'classroom',
      this.classroomId,
      'headsup',
      assignment.__id,
      'lobby'
    ]);
  }

  // updateAssignment

}
