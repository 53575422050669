import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'progress-ring',
  templateUrl: './progress-ring.component.html',
  styleUrls: ['./progress-ring.component.scss']
})
export class ProgressRingComponent implements OnInit, OnChanges {

  @Input() stroke:number = 1;
  @Input() radius:number = 30;
  @Input() progress:number = 75;
  @Input() color:string = 'white';
  @Input() bgColor:string = '#ffffff33';

  normalizedRadius:number;
  _circumference:number;
  _offset:number;

  constructor() { }

  ngOnInit() {
    this.redraw();
  }

  ngOnChanges(){
    this.redraw(); 
  }

  redraw(){
    this.normalizedRadius = this.radius - this.stroke * 2;
    this._circumference = this.normalizedRadius * 2 * Math.PI;
    this._offset = this._circumference - (this.progress / 100 * this._circumference);
  }

}
