import { Component, OnInit, Input } from '@angular/core';
import { IContentElementText, TextParagraphStyle, IQuestionConfig, elementTypes, IElementTypeDef, ElementType, createDefaultElement, elementIconById } from '../models';
import { FormControl, FormArray } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { indexOf } from '../services/util';

@Component({
  selector: 'element-config-text',
  templateUrl: './element-config-text.component.html',
  styleUrls: ['./element-config-text.component.scss']
})
export class ElementConfigTextComponent implements OnInit {

  @Input() element:IContentElementText;

  caption = new FormControl('');
  paragraphStyle = new FormControl('');
  isShowAdvancedOptions = new FormControl(false);
  elementTypeForInsertion = new FormControl(ElementType.TEXT);
  simpleList = new FormArray([]);
  elementTypes:IElementTypeDef[] = elementTypes;

  paragraphStyles = [
    {id: TextParagraphStyle.HEADLINE, caption:'Headline'},
    {id: TextParagraphStyle.HEADLINE_SMALL, caption:'Sub Headline'},
    {id: TextParagraphStyle.REGULAR, caption:'Regular'},
    {id: TextParagraphStyle.SMALL, caption:'Small'},
    {id: TextParagraphStyle.BULLET, caption:'Bullet List'},
    {id: TextParagraphStyle.NUMBERED, caption:'Numbered List'},
    {id: TextParagraphStyle.ADVANCED_INLINE, caption:'Advanced Inline'},
  ]

  constructor() { }

  ngOnInit() {
    
    bindFormControls(this.element, [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
      {f: this.caption, p:'caption'},
      {f: this.paragraphStyle, p:'paragraphStyle'},
      {f: this.simpleList, p:'simpleList', isFormArray:true},
    ])

    // this.isShowAdvancedOptions
    this.caption.valueChanges.subscribe( () => {
      this.element.caption = this.caption.value;
    });
    this.paragraphStyle.valueChanges.subscribe( () => {
      this.element.paragraphStyle = this.paragraphStyle.value;
    });
  }

  insertSimpleListEntry(){
    this.simpleList.push(new FormControl());
  }

  insertAdvancedListEntry(){
    this.element.advancedList.push(
      createDefaultElement(this.elementTypeForInsertion.value)
    );
  }

  isSimpleText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.HEADLINE){ return true }
    if (paragraphStyleId === TextParagraphStyle.HEADLINE_SMALL){ return true }
    if (paragraphStyleId === TextParagraphStyle.REGULAR){ return true }
    if (paragraphStyleId === TextParagraphStyle.SMALL){ return true }
    return false;
  }

  isListText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.BULLET){ return true }
    if (paragraphStyleId === TextParagraphStyle.NUMBERED){ return true }
    return false;
  }

  isAdvancedInlineText(){
    let paragraphStyleId = this.paragraphStyle.value;
    if (paragraphStyleId === TextParagraphStyle.ADVANCED_INLINE){ return true }
    return false;
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this part?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
  }

  bumpChange(){
    if (this.simpleList){
      this.simpleList.push(new FormControl());
      this.simpleList.removeAt(this.simpleList.length-1)
    }
  }
  
  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr) 
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }


}
