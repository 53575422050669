import { Component, OnInit, Input, DoCheck } from '@angular/core';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
@Component({
  selector: 'render-image',
  templateUrl: './render-image.component.html',
  styleUrls: ['./render-image.component.scss']
})
export class RenderImageComponent implements OnInit {

  @Input() urlRaw:string;

  urlRawCache:string = '';
  url:SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.updateUrl();
  }

  // ngDoCheck() {
  //   this.updateUrl();
  // }

  updateUrl(){
    if (this.urlRaw !== this.urlRawCache){
      console.log('updateUrl', this.urlRaw, this.urlRawCache, this.url, )
      this.urlRawCache = this.urlRaw;
      // this.url = this.sanitizer.bypassSecurityTrustResourceUrl('https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/user-uploads%2FTYttsmK0auUbdu3NuIRkjXzkY6x1%2F1559584442497_Screen%20Shot%202019-06-03%20at%201.53.52%20PM.png?alt=media&token=5eb05f82-c083-495a-856a-eef246e35921');
      this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlRawCache);
    }
  }

}
