import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IContentElementImage } from '../models';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { FormControl } from '@angular/forms';

@Component({
  selector: 'capture-image',
  templateUrl: './capture-image.component.html',
  styleUrls: ['./capture-image.component.scss']
})
export class CaptureImageComponent implements OnInit, OnDestroy {

  @Input() element:IContentElementImage;

  task: AngularFireUploadTask;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  isHovering: boolean;
  isRequestingReupload:boolean;
  scale = new FormControl(100);

  private scaleSub:Subscription;
  private userUploadSub:Subscription;
  private storageSub:Subscription;

  constructor(
    private storage: AngularFireStorage,
    private db: AngularFirestore,
    private auth: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    if (this.element.scale){
      this.scale.setValue(this.element.scale);
    }
    this.scaleSub = this.scale.valueChanges.subscribe( e => {
      this.element.scale = this.scale.value
    })
  }

  ngOnDestroy(){
    if (this.scaleSub){ this.scaleSub.unsubscribe(); }
    if (this.userUploadSub){ this.userUploadSub.unsubscribe(); }
    if (this.storageSub){ this.storageSub.unsubscribe(); }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  getImageUrl(){
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.element.url);
  }
  
  isWaitingForUpload(){
    return this.isRequestingReupload || !this.element.url;
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);
    // Client-side validation example
    if (file.type.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }
    // The storage path
    let uid = this.auth.getUid();
    const path = `user-uploads/${uid}/${new Date().getTime()}_${file.name}`;
    const customMetadata = { app: 'Calculating Cats' };
    this.task = this.storage.upload(path, file, { customMetadata });
    this.percentage = this.task.percentageChanges();
    this.snapshot = this.task.snapshotChanges();
    this.userUploadSub = this.snapshot.subscribe( snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        // it looks like the processing takes a bit of time on the Firebase side, so I added a 1 second delay before retrieving the url
        setTimeout(()=> {
          this.storageSub = this.storage.ref(path).getDownloadURL().subscribe( url => {
            this.element.url = url;
            this.isRequestingReupload = false;
          });
        }, 1000)
        
      }
    })

  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

}
