import { ISelectBlockContentPagelet } from "../../../ui-partial/body-block-arr-select/body-block-arr-select.component";
import { TEMPLATE_BRAND_NAME } from "../../../domain/whitelabel.service";

enum SsoPagelets {
    INDEX = 'INDEX',
    LINK_OLD = 'LINK_OLD',
    CREATE_NEW = 'CREATE_NEW',
}

export const OPTIONS_ACCOUNT_RESTORATION:ISelectBlockContentPagelet[] = [
    { 
        id: 'returning',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Freturning-user.png?alt=media&token=3e6d73e5-9cdb-4ea6-bc40-11fa5dbba739',
        caption: `I have a ${TEMPLATE_BRAND_NAME} account.`,
        body: 'Enable Google sign-in for an existing account',
        data: {
            targetPagelet: SsoPagelets.LINK_OLD
        },
    },
    { 
        id: 'new',
        imageUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Fnew-user-v3.gif?alt=media&token=0650b0d8-53ce-4677-a3e6-961fae57f1bf',
        caption: `I want a new ${TEMPLATE_BRAND_NAME} account.`,
        body: 'Complete your new registration.',
        data: {
            targetPagelet: SsoPagelets.CREATE_NEW
        },
    }
];