import { Component, OnInit } from '@angular/core';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'user-registration-anon',
  templateUrl: './user-registration-anon.component.html',
  styleUrls: ['./user-registration-anon.component.scss']
})
export class UserRegistrationAnonComponent implements OnInit {

  constructor(
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.auth.signOut();
  }

}
