import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { BoosterpacksService, IItemBundleInfo, ItemTypes } from '../boosterpacks.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../core/auth.service';
import { Subscription } from 'rxjs';
import { ILeaderboard, IMessagePayload } from 'src/app/data/cambridge/types';
import { LeaderboardService } from 'src/app/core/leaderboard.service';

@Component({
  selector: 'student-booster-pack-item',
  templateUrl: './student-booster-pack-item.component.html',
  styleUrls: ['./student-booster-pack-item.component.scss']
})
export class StudentBoosterPackItemComponent implements OnInit, OnDestroy {

  @ViewChild('lessonContainer') lessonContainer : ElementRef;

  activeBoosterPackId: string;
  classroomId: string;
  activeItemId: string;
  activeItemInfo:IItemBundleInfo;
  itematicIframe:SafeResourceUrl;
  numStarsEarned:number;
  hasEarnedMedal:boolean;
  isComplete:boolean = false;
  leaderboardId: string;
  leaderboardData: ILeaderboard;
  
  private sessionPingIntervalId:any; // Timer
  private PING_INTERVAL:number = (60*2)*1000;
  private routeSub:Subscription;
  private routeDataSub:Subscription;
  private postMessageSubCancel: Function;
  private routeData:{isTeacher?:boolean, isAdmin?:boolean, isLeaderboard?:boolean} = {}

  constructor(
    private auth: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private bps: BoosterpacksService,
    private ls: LeaderboardService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.activeItemId = params['itemId'];
      this.classroomId = params['classroomId']; // only for teachers

      this.leaderboardId = params['leaderboardId']; // only for leaderboard mode
      if (this.leaderboardId) {
        this.loadLeaderboard(this.leaderboardId).then(data => {
          this.activeBoosterPackId = data.boosterPackId;
          this.loadItemInfo();
        });
      } else {
        this.activeBoosterPackId = params['boosterPackId'];
        this.loadItemInfo();
      }
    });
    this.routeDataSub = this.route.data.subscribe(v => this.routeData = v );
    this.postMessageSubCancel = this.renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnDestroy(){
    try { this.postMessageSubCancel(); } catch(e){}
    try { clearInterval(this.sessionPingIntervalId); } catch(e){}
    if (!this.isComplete){
      this.bps.pingAttemptSession(this.activeItemInfo.sessionId);
    }
  }

  isLeaderboard(){
    return this.routeData && this.routeData.isLeaderboard;
  }

  backToMap(){
    console.log('this.routeData', this.routeData)
    if (this.routeData.isAdmin && this.routeData.isLeaderboard){
      this.router.navigate([
        'admin',
        'leaderboard',
        this.leaderboardId,
      ]);
    }
    else if (this.routeData.isTeacher){
      if (this.routeData.isLeaderboard) {
        this.router.navigate([
          'teacher',
          'leaderboard',
          this.leaderboardId,
        ]);
      } else {
        this.router.navigate([
          'teacher',
          'classroom',
          this.classroomId,
          'boosterpacks',
          this.activeBoosterPackId,
        ]);
      }
    }
    else{
      if (this.routeData.isLeaderboard) {
        this.router.navigate([
          'student',
          'leaderboard',
          this.leaderboardId,
        ])
      } else {
        this.router.navigate([
          'student',
          'booster',
          this.activeBoosterPackId,
        ])
      }
    }
  }

  loadItemInfo(){
    this.bps
      .getBoosterPackItemTask(this.activeItemId, this.activeBoosterPackId, this.leaderboardId)
      .then( (data:IItemBundleInfo) =>{
        this.activeItemInfo = data;
        this.renderItematicIFrame();
        this.initSessionPing();
      })
  }

  loadLeaderboard(leaderboardId: string) {
    return this.ls.getLeaderboardById(leaderboardId).then(data => {
      if (data) {
        this.leaderboardData = data;
      }
      return data;
    });
  }
  
  initSessionPing(){
    this.sessionPingIntervalId = setInterval( () => {
      if (!this.isComplete){
        this.bps.pingAttemptSession(this.activeItemInfo.sessionId);
      }
    }, this.PING_INTERVAL)
    
    
  }

  renderItematicIFrame(){
    const itematicItemVersionId = this.activeItemInfo.task.itematicItemVersionId;
    const itematicEngineVersion = this.activeItemInfo.task.itematicEngineVersion;
    this.itematicIframe = this.sanitizer.bypassSecurityTrustResourceUrl(`https://d3kdl9636cazwt.cloudfront.net/itematic-engines/v${itematicEngineVersion}/m0/index.html#!/m0/${itematicItemVersionId}`);
    // if (this.activeItemId === '0ruIlqyuB4gUkwalU4cj'){
    //   this.itematicIframe = this.sanitizer.bypassSecurityTrustResourceUrl('https://d3kdl9636cazwt.cloudfront.net/temp/equivalent-fractions/story_html5.html');
    // }
  }

  getItemName(){
    if (this.activeItemInfo && this.activeItemInfo.item){
      return this.activeItemInfo.item.name;
    }
  }
  getItemType(){
    if (this.activeItemInfo && this.activeItemInfo.item){
      return this.activeItemInfo.item.itemType;
    }
  }
  isItemBlue(){
    return this.getItemType() === ItemTypes.blue
  }
  isItemRed(){
    return (this.getItemType() === ItemTypes.red || this.getItemType() === ItemTypes.medal)
  }
  isItemBlack(){
    return this.getItemType() === ItemTypes.black
  }
  // isItemMedal(){
  //   return this.getItemType() === ItemTypes.medal
  // }

  markEarlyExit(){
    this.bps
      .closeItemAttemptSession(this.activeItemInfo.sessionId, false, 0, null)
  }

  markAsCompleted(payload: IMessagePayload){
    console.log('completion', payload)
    if (this.activeItemInfo.item.itemType === ItemTypes.blue){
      payload.score = 1;
    }
    if (payload.score === 1){
      this.bps.setLastCompletedItem(this.activeItemId);
    }
    const performance = this.bps.processItemScore(this.activeItemInfo.item.itemType, payload.score);

    if (!this.routeData.isTeacher && !this.routeData.isAdmin) {
      this.ls.updateLeaderboardScore(this.leaderboardData, this.activeItemId, payload, performance);
    }

    this.numStarsEarned = performance.numStars;
    this.hasEarnedMedal = performance.hasMedal;
    this.isComplete = true;
    this.bps
      .trackItemCompletion(this.activeItemInfo.sessionId, this.activeBoosterPackId, this.activeItemId, payload.score, undefined, this.leaderboardId);
  }

  autoComplete(){
    // this.markAsCompleted({score:1, completed:true});
  }

  isStarsEarned(num){
    return this.numStarsEarned >= num;
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    let data:{type:string, payload: IMessagePayload};
    // if (message.origin !== ITEMATIC_ENGINE_DOMAIN) return;
    // console.log('message.data', message.data)
    if (!message.data || typeof message.data === 'object' ){
      return;
    }
    try {
      data = JSON.parse(message.data);
    }
    catch(e){
      console.error(e);
      return;
    }
    console.log('handleMessage', data.type, data.payload);
    if (data.type === 'SCREENSHOT_CAPTURED'){
      // this.recordScreenshot(data.payload)
    }
    else if (data.type === 'RESOURCE_LOAD_COMPLETE'){
      this.restoreItematicState();
      this.setItemOptions();
    }
    else if (data.type === 'IDLE_TIME_RESET'){
      console.log('IDLE_TIME_RESET')
    }
    else if (data.type === 'item-work-submission'){
      this.bps
        .trackItemOpenResponse(
          this.activeItemInfo.sessionId, 
          this.activeBoosterPackId,
          this.activeItemId,
          JSON.stringify(data)
        )
        .then(()=>{
          this.messageIntoLesson('SUBMISSION_RESOLVED_' + data.payload.comm_id, {success:true, msg:'submitted work successfully'})
        })
    }
    else if (data.type === 'ITEM_PROGRESS'){
      // this.recordSubmission(data.payload);

      if (data.payload.completed){ // based on Itematic data model
        this.markAsCompleted(data.payload);
      }
    }
  }

  messageIntoLesson(type:string, payload:any){
    let el = <HTMLIFrameElement>this.lessonContainer.nativeElement;
    el.contentWindow.postMessage(JSON.stringify({
      type,
      payload
    }), '*');

    console.log('messageIN', type, payload);
  }

  setItemOptions(){
    let overrideOptions = this.activeItemInfo.itemOptions ? this.activeItemInfo.itemOptions.itemOptions || {} : {};
    let baseOptions = this.activeItemInfo.task.itemOptions || {};

    let finalOptions = _.assign(_.clone(baseOptions), overrideOptions);
    if (finalOptions) {
      this.messageIntoLesson('SET_ITEM_OPTIONS', finalOptions);
    }
  }

  restoreItematicState(){
    let score = 0;
    let max_reached_frame = 1; // to do: store this in the item state
    if (this.auth.getUid() !== 'student'){
      max_reached_frame = 1000;
    }
    if (this.activeItemInfo.performance){
      score = this.activeItemInfo.performance.score || 0;
    }
    let appletState = {
      max_reached_frame,
      score,
    }
    // console.log('restoreItematicState', appletState)
    this.messageIntoLesson('LESSON_SCORE_RESTORE', appletState);
    this.messageIntoLesson('LESSON_STATE_RESTORE', appletState);
    setTimeout(()=>{
      console.log('max_reached_frame', max_reached_frame)
      this.messageIntoLesson('LESSON_UNLOCK', true);
    }, 1000)
  }

}
