import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { auth } from 'firebase';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference
} from '@angular/fire/firestore';
import { NotifyService } from './notify.service';

import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { switchMap, startWith, tap, filter } from 'rxjs/operators';
import { IUser, UserUi } from '../data/collections/users.types';
import { AuthGuardResolveRouter } from './auth.guard.resolver-router';
import { UserSiteContextService } from './usersitecontext.service';
import { renderUsernameFromEmail } from '../data/accounts/pseudo-email-usernames';
import { serverTimestamp } from '../data/timestamp';

interface IFreshAccountInfo {
  uid: string,
  username: string,
  password: string,
  classCodeId:string,
}

@Injectable()
export class AuthService {
  
  user: Observable<IUser | null>;
  currentUser:IUser;
  freshAccount:IFreshAccountInfo;
  newAuthSub = new Subject();

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private notify: NotifyService,
    private userSiteContext: UserSiteContextService
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<IUser>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
      // tap(user => localStorage.setItem('user', JSON.stringify(user))),
      // startWith(JSON.parse(localStorage.getItem('user')))
    );
    this.user.subscribe( (data:IUser) => {
      console.log('currentUser', data);
      this.currentUser = data;
      if (this.currentUser){
        this.trackAccess();
      }
    })
  }

  registerFreshAccountSignup(accountInfo:IFreshAccountInfo){
    // here for new student signups
    this.freshAccount = accountInfo;
  }
  clearFreshAccountSingup(){
    this.freshAccount = null;
  }
  getFreshAccountSignup() : IFreshAccountInfo{
    return this.freshAccount;
  }

  getUid(){
    if (this.currentUser){
      return this.currentUser.uid;
    }
    return '';
  }

  getUserTrialId(){
    if (this.currentUser && this.currentUser.trialId){
      return this.currentUser.trialId;
    }
    return '';
  }

  getUserInstScope(){
    if (this.currentUser && this.currentUser.instScopeIds){
      return this.currentUser.instScopeIds;
    }
    return [];
  }

  getStudentClassrooms(){
    if (this.currentUser){
      return this.currentUser.classroomsAsStudent || [];
    }
    return [];
  }

  getUsername(){
    if (this.currentUser){
      return renderUsernameFromEmail(this.currentUser.email);
    }
  }

  getIdToken(){
    return this.afAuth.auth.currentUser.getIdToken();
  }

  getDisplayName(){
    if (this.currentUser){
      let displayName = this.currentUser.displayName;
      if (this.currentUser.firstName && this.currentUser.lastName){
        displayName = this.currentUser.firstName +' '+ this.currentUser.lastName;
      }
      return displayName;
    }
    return '?';
  }

  getPhotoUrl(){
    if (this.currentUser){
      return this.currentUser.photoURL;
    }
    return '';
  }

  unlinkAccount(){
    if (confirm('Are you sure you want to unlink your account?')){
      const user = this.afAuth.auth.currentUser;
      this.afs.doc(`users/${user.uid}`).set({ isUnlinked: true }, {merge:true});
      return user.unlink(auth.GoogleAuthProvider.PROVIDER_ID);
      // "auth/no-such-provider"
    }
  }

  linkEmailToAnon(username:string, password:string){
    var credential = auth.EmailAuthProvider.credential(username, password);
    return this.afAuth.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential);
  }

  linkCredential(credential:auth.AuthCredential){
    return this.afAuth.auth.currentUser
      .linkAndRetrieveDataWithCredential(credential)
  }

  signinAndLinkOauth(username:string, password:string, oauthCredential?:auth.AuthCredential){
    return this.afAuth.auth
      .signInWithEmailAndPassword(username, password)
      .then(credential => {
        this.newAuthSub.next();
        return this.afAuth.auth.currentUser
          .linkWithPopup(new auth.GoogleAuthProvider())
          .then( () => true )
      })
  }
  
  linkEmailToOauth(username:string, password:string){
    // I don't want to create a new account here, only existing accounts should succeed
    if (this.afAuth.auth.currentUser){
      return this.afAuth.auth
        .signOut()
        .then( () => {
          return this.signinAndLinkOauth(username, password);
        })
    }
    return this.signinAndLinkOauth(username, password);
  }

  hasEmailAuth(){
    if (this.afAuth.auth.currentUser){
      const providerData = this.afAuth.auth.currentUser.providerData;
      const providerIds:string[] = providerData.map( provider => provider.providerId );
      return providerIds.includes(auth.EmailAuthProvider.PROVIDER_ID);
      // hasOauthProvider = providerIds.includes(auth.GoogleAuthProvider.PROVIDER_ID);
    }
  }

  fetchNewUserInfo(){
    const user = this.afAuth.auth.currentUser;
    return this.afs.doc(`users/${user.uid}`).get().toPromise().then(snap => {
      // console.warn('fetchNewUserInfo', snap.data())
      this.currentUser = <any>snap.data();
      return this.currentUser
    });
  }

  getUi(){
    if (this.currentUser){
      return this.currentUser.ui;
    }
    return '';
  }

  isAnonymous() {
    if (this.afAuth.auth.currentUser){
      return this.afAuth.auth.currentUser.isAnonymous && !this.currentUser.email; // !this.currentUser.email is just temporary
    }
    return false;
  }

  ////// OAuth Methods /////

  googleLogin() {
    const provider = new auth.GoogleAuthProvider();
    return this.oAuthLogin(provider);
  }

  facebookLogin() {
    const provider = new auth.FacebookAuthProvider();
    return this.oAuthLogin(provider);
  }

  twitterLogin() {
    const provider = new auth.TwitterAuthProvider();
    return this.oAuthLogin(provider);
  }

  private oAuthLogin(provider: any) {
    return this.afAuth.auth
      .signInWithPopup(provider)
      .then(credential => {
        this.newAuthSub.next();
        this.trackAccess();
        return this.updateUserData(credential.user);
      })
      .catch(error => this.handleError(error));
  }

  //// Anonymous Auth ////

  anonymousLogin() {
    return this.afAuth.auth
      .signInAnonymously()
      .then(credential => {
        this.newAuthSub.next();
        return this.updateUserData(credential.user); // if using firestore
      })
      .catch(error => {
        this.handleError(error);
      });
  }


  //// Email/Password Auth ////

  emailSignUp(email: string, password: string, displayName?:string) {
    let uid;
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(credential => {
        this.newAuthSub.next();
        // this.notify.update('Welcome new user!', 'success');
        uid = credential.user.uid;
        return this.updateUserData({
          ... credential.user,
          displayName: displayName || credential.user.displayName
        }); // if using firestore
      })
      .then ( ()=>{
        return uid;
      })
      // .catch(error => this.handleError(error));
  }

  emailLogin(email: string, password: string) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(credential => {
        this.newAuthSub.next();
        this.clearFreshAccountSingup();
        this.trackAccess();
        return this.updateUserData(credential.user);
      })
      // .catch(error => this.handleError(error));
  }

  // Sends email allowing user to reset password
  resetPassword(email: string) {
    this.afAuth.auth.sendPasswordResetEmail
    return this.afAuth.auth
      .sendPasswordResetEmail(email)
      // .then(() => this.notify.update('Password update email sent', 'info'))
      // .catch(error => this.handleError(error));
  }

  isTeacherAccount():boolean{
    // console.log('isTeacherAccount', this.getUi())
    switch (this.getUi()){
      case 'teacher': 
      case 'trial-coord': 
      case 'admin': 
        return true;
      default: 
        return false;
    }
  }

  isContentAdminAccount():boolean{
    switch (this.getUi()){
      case 'admin': 
        return true;
      default: 
        return false;
    }
  }

  signOut(redirect:boolean=true) {
    return this.afAuth.auth
      .signOut()
      .then(() => {
        this.newAuthSub.next();
        if (redirect){
          AuthGuardResolveRouter.clearRoute();
          this.userSiteContext.resetContextState();
          this.router.navigate(['/']);
        }
      });
  }

  getUserUiHome(){
    let userUI = this.getUi();
    switch (userUI){
      case 'creator': return '/create/tasks';
      case 'teacher': case 'trial-coord': case 'admin': return 'teacher/classrooms';
      case 'student': return 'student/dashboard';
      default: return '/';
    }
  }

  navigateToUserUiHome(){
    // console.log('navigateToUserUiHome');
    this.router.navigate([this.getUserUiHome()]);
  }

  updateAnonUserData(displayName:string, email:string){
    const user = this.currentUser;
    console.log('this.afAuth.auth.currentUser.isAnonymous', this.afAuth.auth.currentUser, this.currentUser)
    if (user){
      const userRef: AngularFirestoreDocument<IUser> = this.afs.doc( `users/${user.uid}` );
      let fullName = displayName.split(' ');
      let firstName = fullName.splice(0,1)[0];
      let lastName = fullName.join(' ');
      email = (email || '').toLowerCase()
      const data: Partial<IUser> = { email, displayName, firstName, lastName };
      return userRef.update(data);
    }
  }

  updateUserAgreements(agreementsMap:Map<string, boolean>){
    const user = this.currentUser;
    if (user){
      let agreementsState:any = {};
      agreementsMap.forEach( (value:boolean, key:string) => {
        agreementsState[key] = value;
      });
      return this.afs.doc(`userAgreements/${user.uid}`).set(agreementsState, {merge:true});  
    }
  }

  // overloaded to act as a one time registration close
  updateUserUi(ui: string, trialId:string='', instScopeId:string=''){
    const user = this.currentUser;
    let payload:{ui:string, trialId:string, instScopeIds:string[], timeJoined?:any} = { ui, trialId, instScopeIds:[instScopeId]};
    if (!user.timeJoined){
      payload.timeJoined = serverTimestamp();
    }
    return this.afs
      .doc(`users/${user.uid}`)
      .set(payload, {merge:true});
  }

  updateUserTargetCurriculum(curriculumId: string){
    const user = this.currentUser;
    return this.afs.doc(`users/${user.uid}`).set({ curriculumId }, {merge:true});
  }

  // If error, console log and notify user
  private handleError(error: Error) {
    console.error(error);
    this.notify.update(error.message, 'error');
  }


  isTrackingAccess:boolean;
  lastAccessTracked:Date;
  trackAccess(){
    if (!this.isTrackingAccess){
      this.isTrackingAccess = true;
      this._trackAccess(false);
      setInterval(this._trackAccess, 1000*60*60)
    }
  }
  _trackAccess = (isAutoRecurring:boolean = true) => {
    // console.log('_trackAccess')
    let now = new Date();
    if (isAutoRecurring){
      if ( 
        (now.getFullYear() === this.lastAccessTracked.getFullYear()) && 
        (now.getMonth() === this.lastAccessTracked.getMonth()) &&
        (now.getDate() === this.lastAccessTracked.getDate())
      ){
        return;
      }
    }
    this.lastAccessTracked = now;
    // console.log('_trackAccess >> ', this.currentUser)
    this.afs.collection('userAccessLog').add({
      ui: this.getUi() || '',
      uid: this.getUid(),
      trialId: this.getUserTrialId() || '',
      instScopeId: this.getUserInstScope() || '',
      timestamp: serverTimestamp(),
      isAutoRecurring
    })

  }

  // Sets user data to firestore after succesful login
  private updateUserData(user: IUser) {
    const userRef: AngularFirestoreDocument<IUser> = this.afs.doc(
      `users/${user.uid}`
    );

    let email:string = '' ;
    if( user.email ){
      email = user.email;
      email = email.toLowerCase();
    }

    const data: IUser = {
      uid: user.uid,
      email,
      photoURL: user.photoURL || 'https://goo.gl/Fz9nrQ'
    };
    if (user.displayName){
      data.displayName = user.displayName;
    }
    return userRef
      .set(data, {merge:true});
  }
}
