export class ColorObject {
  R: number;
  G: number;
  B: number;

  /**
   * @constructor specify either a number as 0xRRGGBB or string as #RRGGBB
   */
  constructor(color: string | number) {
    color = parseColor(color);
    this.color = color;
  }

  toNumber() {
    return this.color;
  }

  setColorFromString(color: string) {
    this.color = parseColor(color);
  }

  set color(n: number) {
    this.R = Math.floor(n / 0x010000);
    this.G = Math.floor((n % 0x010000) / 0x000100);
    this.B = Math.floor(n % 0x000100);
  }

  get color(): number {
    return Math.floor(this.R) * 0x010000 + Math.floor(this.G) * 0x0100 + Math.floor(this.B);
  }

  get hue(): number {
    return this.toHSL()[0];
  }

  set hue(n: number) {
    let hsl = this.toHSL();
    hsl[0] = n;
    this.fromHSL(hsl);
  }

  get saturation(): number {
    return this.toHSL()[1];
  }

  set saturation(n: number) {
    let hsl = this.toHSL();
    hsl[1] = n;
    this.fromHSL(hsl);
  }

  get lightness(): number {
    let lightness = this.toHSL()[2];
    return lightness;
  }

  set lightness(n: number) {
    let hsl = this.toHSL();
    hsl[2] = n;
    this.fromHSL(hsl);
  }

  /**
   * @function get an array of three values representing Hue, Saturation and Luminance for bulk adjustment.
   */
  toHSL(): [number, number, number] {
    let r = this.R / 255;
    let g = this.G / 255;
    let b = this.B / 255;

    let maxC = Math.max(r, g, b);
    let minC = Math.min(r, g, b);

    let c = maxC - minC;

    let h: number;
    let s: number;
    let l: number;

    l = (maxC + minC) / 2;

    if (c === 0) {
      h = 0;
      s = 0;
    } else {
      switch (maxC) {
        case r:
          h = ((g - b) / c) % 6;
          break;
        case g:
          h = (b - r) / c + 2;
          break;
        case b:
          h = (r - g) / c + 4;
          break;
      }
      h *= 60;
      if (h < 0) {
        h += 360;
      }

      s = c / (1 - Math.abs(2 * l - 1));
    }

    return [h, s * 100, l * 100];
  }

  /**
   * @function takes an array of three values representing Hue, Saturation and Luminance for bulk adjustment.
   */
  fromHSL([h, s, l]: [number, number, number]): number {
    h /= 360;
    s /= 100;
    l /= 100;
    h = Math.max(0, Math.min(1, h));
    s = Math.max(0, Math.min(1, s));
    l = Math.max(0, Math.min(1, l));
    if (s === 0) {
      this.R = this.G = this.B = l * 255;
    } else {
      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;
      this.R = this.hue2rgb(p, q, h + 1 / 3) * 255;
      this.G = this.hue2rgb(p, q, h) * 255;
      this.B = this.hue2rgb(p, q, h - 1 / 3) * 255;
    }

    return this.color;
  }

  private hue2rgb(p: number, q: number, t: number) {
    if (t < 0) {
      t += 1;
    } else if (t > 1) {
      t -= 1;
    }

    if (t < 1 / 6) {
      return p + (q - p) * 6 * t;
    } else if (t < 1 / 2) {
      return q;
    } else if (t < 2 / 3) {
      return p + (q - p) * (2 / 3 - t) * 6;
    } else {
      return p;
    }
  }
}

export class RainbowShift {
  // hsl:[number,number,number]=[0,100,50]
  colorObj: ColorObject = new ColorObject(0xff0000);

  constructor(public hsl: [number, number, number] = [0, 100, 50]) { }

  getNextColor = (inc: number = 1): number => {
    // this.colorObj.hue+=1;
    this.hsl[0] += inc;
    if (this.hsl[0] > 360) {
      this.hsl[0] -= 360;
    }

    return this.colorObj.fromHSL(this.hsl);
  }

  getRandomColor = (): number => {
    this.hsl[0] = Math.random() * 360;
    return this.colorObj.fromHSL(this.hsl);
  }
}

export function parseColor(color: string | number): number {
  if (typeof color === 'string') {
    let hashI = color.indexOf('#');
    if (hashI >= 0) {
      color = color.substr(hashI + 1);
      let c = parseInt('0x' + color, 16);
      return parseInt('0x' + color, 16);
    } else {
      return parseInt(color, 10);
    }
  }
  return color;
}

export function stringifyColor(color: string | number): string {
  if (typeof color === 'string') {
    return color;
  } else {
    return '#' + color.toString(16);
  }
}
