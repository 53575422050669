import { Component, OnInit, OnChanges, Output, EventEmitter, Input } from '@angular/core';
import { CosmeticUnlock, ICosmeticInfo } from 'src/app/data/collections/heads-up-lobby';

export interface IPurchaseResponse {
  unlocked: boolean;
  info: ICosmeticInfo;
}

@Component({
  selector: 'avatar-cosmetic-purchase',
  templateUrl: './avatar-cosmetic-purchase.component.html',
  styleUrls: ['./avatar-cosmetic-purchase.component.scss']
})

export class AvatarCosmeticPurchaseComponent implements OnInit, OnChanges {
  @Output() purchaseResult: EventEmitter<IPurchaseResponse> = new EventEmitter();
  @Input() purchaseObject: ICosmeticInfo;
  @Input() purchaseResponse: IPurchaseResponse;

  purchaseDescription = 'Something insightful';
  purchaseState: 'INITIAL' | 'LOADING' | 'COMPLETE';

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    if (this.purchaseResponse){
      this.purchaseState = 'COMPLETE';
      if (this.purchaseResponse.unlocked){
        this.purchaseDescription = 'Purchase of your ' + this.purchaseResponse.info.slug + ' was successful!'
      }else{
        this.purchaseDescription = 'Purchase of your ' + this.purchaseResponse.info.slug + ' was not successful :('
      }
    }else if (this.purchaseObject) {
      this.purchaseState = 'INITIAL';
      switch (this.purchaseObject.unlock) {
        case CosmeticUnlock.COINS:
          this.purchaseDescription = 'Unlock for ' + this.purchaseObject.cost + ' coins.';
          break;
        case CosmeticUnlock.PREMIUM:
          this.purchaseDescription = 'Unlock for ' + this.purchaseObject.cost + ' premiums.';
          break;
        case CosmeticUnlock.EVENT:
          this.purchaseDescription = this.purchaseObject.eventDescription;
          break;
      }
    }
  }

  completePurchase = () => {
    this.purchaseResult.emit({unlocked: true, info: this.purchaseObject});
    this.purchaseState = 'LOADING';
  }

  completeCancel = () => {
    this.purchaseResult.emit({unlocked: false, info: this.purchaseObject});
  }

  closeWindow = () => {
    this.purchaseResult.emit({unlocked: false, info: null});
  }
}
