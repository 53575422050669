import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ILeaderboard, ILeaderboardScore, ILeaderboardGroup, ILeaderboardGroupScore, IMessagePayload } from '../data/cambridge/types';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { serverTimestamp } from '../data/timestamp';
import { IItemScore } from '../ui-student/boosterpacks.service';
import { ForeignUserService } from './foreignUser.service';
import { FirebaseTimestamp } from '../data/base.types';
import { ClassroomService } from '../ui-teacher/classroom.service';
import { UsersService } from './users.service';

/*
  types of data this service fetches and sets:
  * 'leaderboards/': ILeaderboard - configuration for a leaderboard
  * 'leaderboardUserItemScore/': ILeaderboardScore - score block for one user completing one item
  * 'leaderboardUserBoosterScore/': ILeaderboardScore - score block for one user in a booster pack
  * 'leaderboardGroups/': ILeaderboardGroup - Information for a group / teamm in one leaderboard
  * 'leaderboardGroupScore/': ILeaderboardGroupScore - Scoring data linked to one leaderboard and one group

  * 'leaderboardTeamNames/', 'leaderboardTeamNamesLists/', 'leaderboardUsedTeamNames': All relating to naming of teams
*/

type DistrictId = string;
type ClassTypeId = string;
export interface IClassTypeDef {
  classTypeId: string,
  caption: string,
  districtId: string,
}

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  locationModified: string;
  timeZone:string;
  allowedDistricts = [];
  public leaderboardCollapseState = new Map();
  private classTypeCaptions:Map< DistrictId, Map<ClassTypeId, string> > = new Map();
  private classTypesLists:Map< DistrictId, Array<IClassTypeDef> > = new Map()
  // {
  //   'no-district': [
  //     {slug: 'no-class', caption:'No Class'},
  //   ],
  //   'luxembourg': [
  //     {classTypeId: 'lux_ef_c3',  caption:'EF - Cycle C3'}, 
  //     {classTypeId: 'lux_ef_c4',  caption:'EF - Cycle C4'}, 
  //     {classTypeId: 'lux_es_mod', caption:'ES - Voie de préparation'},
  //     {classTypeId: 'lux_es_ci',  caption:'ES - Cycle Inférieur'},
  //   ],
  //   'cambridge': [
  //     {slug: 'canada', caption:'Canada'}, 
  //     {slug: 'australia', caption:'Australia'}, 
  //     {slug: 'india', caption:'India'}, 
  //     {slug: 'jordan', caption:'Jordan'},
  //   ],
  // };

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private foreign: ForeignUserService,
    private cs: ClassroomService,
    private user: UsersService,
  ) {
    this.auth.newAuthSub.subscribe(() => this.clearListeners());
    this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.initData();
  }

  initData(){
    return this.loadDistricts()
    .then( () => this.loadClassTypes() )
  }

  loadDistricts(){
    return this.afs.collection('districts').get().toPromise().then(res => {
      this.allowedDistricts = res.docs.map(doc => {
        return doc.data().id
      })
      // console.log('loadDistricts', this.allowedDistricts)
    })
  }

  isClassTypesLoaded:boolean;
  loadClassTypes(){
    if (this.isClassTypesLoaded){
      return;
    }
    this.isClassTypesLoaded = true;
    return this.afs
      .collection('districtClassTypes', ref => ref.orderBy('order', "asc"))
      .get().toPromise()
      .then(res => {
        res.docs.forEach(doc => {
          const classType = <IClassTypeDef>doc.data();
          if (!this.classTypesLists.has(classType.districtId)){
            this.classTypesLists.set(classType.districtId, []);
            this.classTypeCaptions.set(classType.districtId, new Map());
          }
          this.classTypesLists.get(classType.districtId).push(classType);
          this.classTypeCaptions.get(classType.districtId).set(classType.classTypeId, classType.caption);
        })
      })
  }

  clearListeners() {
  }

  getDistricts(){
    return this.allowedDistricts;
  }

  getClassTypeCaption(districtId:string, classTypeId:string){
    if (this.classTypeCaptions.has(districtId)){
      return this.classTypeCaptions.get(districtId).get(classTypeId) || ''
    }
    return '';
  }

  getClassTypesByDistrict(districtId:string){
    if (this.classTypesLists.has(districtId)){
      return this.classTypesLists.get(districtId);
    }
    return [];
  }

  getAllLeaderboards() {
    return this.afs.collection<ILeaderboard>('leaderboards/').get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        return _.map(snap.docs, doc => { let m = doc.data(); m.__id = doc.id; return m; }) as ILeaderboard[];
      } else {
        return [];
      }
    });
  }

  getLeaderboardsByDistrictAndClassType(district: string, classType: string) {
    return this.afs.collection<ILeaderboard>('leaderboards/', ref => ref
      .where('district', '==', district)
      .where('classTypes', 'array-contains', classType)
    ).get().toPromise().then(snap => {
      let leaderboards: ILeaderboard[];
      if (snap.docs.length > 0) {
        leaderboards = _.map(snap.docs, doc => { let m = doc.data(); m.__id = doc.id; return m; }) as ILeaderboard[];
      } else {
        leaderboards = [];
      }

      return leaderboards;
    });
  }

  getLeaderboardById(leaderboardId: string) {
    return this.afs
      .doc('leaderboards/' + leaderboardId)
      .get()
      .toPromise()
      .then(doc => {
        if (!doc.id){
          console.error('No leaderboard found with this ID, ', leaderboardId);
        }
        return <ILeaderboard>{
          ... doc.data(),
          __id: doc.id
        }
      });
  }

  getAllLeaderboardGroups(leaderboard: ILeaderboard) {
    return this.afs.collection('leaderboardGroups/', ref => ref
    .where('leaderboardId', '==', leaderboard.__id)).get().toPromise().then(snap => {
      let data = snap.docs.map(doc => {
        let group = doc.data() as ILeaderboardGroup;
        group.__id = doc.id;
        return group;
      });

      return data;
    });
  }

  getMyLeaderboardGroup(leaderboard: ILeaderboard, orJoinGroup: boolean, uid: string = this.auth.getUid()) {
    // console.log('getMyLeaderboardGroup', uid)
    return this.afs.collection('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboard.__id)
      .where('students', 'array-contains', uid)
      .limit(1)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        let data = snap.docs[0].data() as ILeaderboardGroup;
        data.__id = snap.docs[0].id;
        // console.log('getMyLeaderboardGroup data', data)
        return data;
      } else if (orJoinGroup) {
        return this.findAndJoinGroup(leaderboard);
      } else {
        return null;
      }
    });
  }

  getMyModeratedLeaderboardGroups(leaderboardConfig: ILeaderboard, foreignClassId?: string) {
    if (foreignClassId) {
      return this.getMyModeratedForeignLeaderboardGroups(leaderboardConfig, foreignClassId);
    }

    let uid = this.auth.getUid();
    return this.afs.collection('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('moderator', '==', uid)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        return _.map(snap.docs, doc => _.assign(doc.data() as ILeaderboardGroup, {__id: doc.id}) as ILeaderboardGroup);
      } else {
        return null;
      }
    });
  }

  getMyModeratedForeignLeaderboardGroups(leaderboardConfig: ILeaderboard, foreignClassId: string) {
    let uid = this.auth.getUid();
    return this.afs.collection('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('foreignClassId', '==', foreignClassId)
      .where('moderator', '==', uid)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        return _.map(snap.docs, doc => _.assign(doc.data() as ILeaderboardGroup, {__id: doc.id}) as ILeaderboardGroup);
      } else {
        return null;
      }
    });
  }

  getMyLeaderboardItemScores(leaderboard: ILeaderboard, uid: string = this.auth.getUid()) {
    return this.afs.collection<ILeaderboardScore>('leaderboardUserItemScore/', ref => ref
      .where('uid', '==', uid)
      .where('leaderboardId', '==', leaderboard.__id)
    ).get().toPromise().then(snap => {
      return _.map(snap.docs, doc => doc.data() as ILeaderboardScore);
    })
  }

  getMyItemScore(leaderboardConfig: ILeaderboard, itemId: string, uid: string = this.auth.getUid()) {
    return this.afs.collection<ILeaderboardScore>('leaderboardUserItemScore/', ref => ref
      .where('uid', '==', uid)
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('itemId', '==', itemId)
      .limit(1)
    )
    .get()
    .toPromise()
    .then(snap => {
      if (snap.docs.length > 0) {
        return snap.docs[0].data() as ILeaderboardScore;
      } else {
        return null;
      }
    });
  }

  getMyBoosterScore(leaderboardConfig: ILeaderboard, uid = this.auth.getUid()) {
    return this.afs.collection<ILeaderboardScore>('leaderboardUserBoosterScore/', ref => ref
      .where('uid', '==', uid)
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .limit(1)
    )
    .get()
    .toPromise()
    .then(snap => {
      if (snap.docs.length > 0) {
        return snap.docs[0].data() as ILeaderboardScore;
      } else {
        return null;
      }
    });
  }

  getUserBoosterScoresForGroup(leaderboard: ILeaderboard, group: ILeaderboardGroup) {
    let scores: ILeaderboardScore[] = [];
    let remaining = group.students.length;
    return new Promise<ILeaderboardScore[]>(resolve => {
      for (let i = 0; i < group.students.length; i++) {
        this.afs.collection<ILeaderboardScore>('leaderboardUserBoosterScore/', ref => ref
          .where('uid', '==', group.students[i])
          .where('leaderboardId', '==', leaderboard.__id)
        ).get().toPromise().then(snap => {
          if (snap.docs.length > 0) {
            let data = snap.docs[0].data() as ILeaderboardScore;
            scores.push(data);
          }
          remaining --;
          if (remaining === 0) {
            scores = _.sortBy(scores, score => score.score).reverse();
            scores.forEach((score, i) => score.__rank = i + 1);
            resolve(scores);
          }
        });
      }
    });
  }

  getLeaderboardStudentScores(leaderboardConfig: ILeaderboard, studentUids: string[]) {
    let scores: ILeaderboardScore[] = [];
    let numRemaining = studentUids.length;
    return new Promise(resolve => {
      for (let i = 0; i < studentUids.length; i++) {
        this.afs.collection('leaderboardUserBoosterScore/', ref => ref
          .where('uid', '==', studentUids[i])
          .where('leaderboardId', '==', leaderboardConfig.__id)
          .limit(1)
        ).get().toPromise().then(snap => {
          if (snap.docs.length > 0) {
            scores.push(snap.docs[0].data() as ILeaderboardScore);
          }
          numRemaining--;
          if (numRemaining <= 0) {
            resolve();
          }
        });
      }
    }).then(() => scores);
  }

  getLeaderboardGroupScore(leaderboardConfig: ILeaderboard, groupId: string, rankOutOf?: number): Promise<ILeaderboardGroupScore> {
    if (rankOutOf) {
      return this.getTopEntries(leaderboardConfig, rankOutOf).then(groups => {
        let groupScore = _.find(groups, {groupId});
        if (groupScore) {
          return groupScore;
        } else {
          return this.getLeaderboardGroupScore(leaderboardConfig, groupId);
        }
      });
    } else {
      return this.afs.collection('leaderboardGroupScore/', ref => ref
        .where('leaderboardId', '==', leaderboardConfig.__id)
        .where('groupId', '==', groupId)
        .limit(1)
      ).get().toPromise().then(snap => {
        if (snap.docs.length === 0) {
          return null;
        } else {
          let groupScore = snap.docs[0].data() as ILeaderboardGroupScore;
          return groupScore;
        }
      });
    }
  }

  getTopEntries(leaderboardConfig: ILeaderboard, numEntries: number = 5) {
    let data: ILeaderboardGroupScore[];

    return this.afs.collection('leaderboardGroupScore/', getRef).get().toPromise().then(snap => {
      if (snap.docs.length === 0) {
        data = [];
      } else {
        data = snap.docs.map(doc => doc.data()) as ILeaderboardGroupScore[];
        data.forEach((groupScore, index) => groupScore.__rank = index + 1);
      }
    }).then(() => data);

    function getRef(ref: any) {
      if (leaderboardConfig.minGroupSize) {
        return ref
        .where('leaderboardId', '==', leaderboardConfig.__id)
        .where('hasMinStudents', '==', true)
        .orderBy('score', 'desc')
        .limit(numEntries);
      } else {
        return ref
        .where('leaderboardId', '==', leaderboardConfig.__id)
        .orderBy('score', 'desc')
        .limit(numEntries);
      }
    }
  }

  assignMeAsModeratorToForeignLeaderboardGroup(leaderboardConfig: ILeaderboard, foreignClassId: string) {
    let uid = this.auth.getUid();
    return this.afs.collection('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('foreignClassId', '==', foreignClassId)
      .limit(1)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        let group = snap.docs[0].data() as ILeaderboardGroup;
        if (group.moderator !== uid) {
          group.moderator = uid;
          group.__id = snap.docs[0].id;

          this.afs.doc('leaderboardGroups/' + group.__id).update({moderator: uid});

          return group;
        }
      }
      return null;
    });
  }

  assignMeAsModeratorToForeignLeaderboardGroups(leaderboardConfig: ILeaderboard, foreignClassId: string) {
    const uid = this.auth.getUid();
    console.log('assignMeAsModeratorToForeignLeaderboardGroups', leaderboardConfig.__id, foreignClassId)
    return this.afs.collection('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('foreignClassId', '==', foreignClassId)
    )
    .get()
    .toPromise()
    .then(snap => {
      console.log('assignMeAsModeratorToForeignLeaderboardGroups 2')
      if (snap.docs.length > 0) {
        const groupsToAssign = [];
        snap.docs.forEach(doc => {
          const leaderboardGroup = <ILeaderboardGroup>{ ... doc.data(), __id: doc.id };
          if (leaderboardGroup.moderator !== uid){
            groupsToAssign.push(leaderboardGroup);
          }
        })
        console.log('assignMeAsModeratorToForeignLeaderboardGroups 3', groupsToAssign);
        return Promise.all(groupsToAssign.map(leaderboardGroup => {
          return this.afs.doc('leaderboardGroups/' + leaderboardGroup.__id).update({moderator: uid});
        }))
        // groups = _.filter(groups, {moderator: uid});
      }
      return null;
    });
  }

  updateLeaderboardGroup(leaderboardConfig: ILeaderboardGroup) {
    let leaderboard = _.clone(leaderboardConfig);
    delete leaderboard.__id;
    this.afs.doc('leaderboardGroups/' + leaderboardConfig.__id).set(leaderboard);
  }

  updateLeaderboardScore(leaderboardConfig: ILeaderboard, itemId: string, payload: IMessagePayload, performance: IItemScore) {
    if (!leaderboardConfig) {
      return;
    }

    let newScore = this.calculateItemScore(leaderboardConfig, payload, performance);

    let uid = this.auth.getUid();

    this.afs.collection<ILeaderboardScore>('leaderboardUserItemScore/', ref => ref
      .where('uid', '==', uid)
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('itemId', '==', itemId)
      .limit(1)
    )
    .get()
    .toPromise()
    .then(snap => {
      if (snap.docs.length > 0) {
        let data = snap.docs[0].data() as ILeaderboardScore;
        let currentScore = data.score;

        console.log('newscore:', newScore, currentScore, data);
        let toUpdate = false;

        switch(leaderboardConfig.scoringType) {
          case 'lowest': toUpdate = (newScore < currentScore); break;
          case 'newest': toUpdate = true; break;
          case 'total': toUpdate = true; break;
          case 'highest': default: toUpdate = (newScore > currentScore); break;
        }
        if (toUpdate) {
          data.score = newScore;
          data.timeModified = serverTimestamp();
          this.afs.doc('leaderboardUserItemScore/' + snap.docs[0].id).set(data);
          this.updateBoosterpackScore(leaderboardConfig, (leaderboardConfig.scoringType === 'total') ? newScore : (newScore - currentScore));
        }
      } else {
        let data = {
          uid,
          itemId,
          leaderboardId: leaderboardConfig.__id,
          score: newScore,
          timeModified: serverTimestamp(),
          name: this.auth.getDisplayName(),
        };
        this.afs.collection<ILeaderboardScore>('leaderboardUserItemScore/').add(data);
        this.updateBoosterpackScore(leaderboardConfig, newScore);
      }
    });
  }

  private updateBoosterpackScore(leaderboardConfig: ILeaderboard, scoreDiff: number) {
    let uid = this.auth.getUid();

    this.afs.collection<ILeaderboardScore>('leaderboardUserBoosterScore/', ref => ref
      .where('uid', '==', uid)
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .limit(1)
    )
    .get()
    .toPromise()
    .then(snap => {
      if (snap.docs.length > 0) {
        let data = snap.docs[0].data() as ILeaderboardScore;
        if (!data.locationModified) {
          this.getGeoLocation().then(locationModified => {
            data.score += scoreDiff;
            data.timeModified = serverTimestamp();
            data.locationModified = locationModified;
            this.afs.doc('leaderboardUserBoosterScore/' + snap.docs[0].id).set(data);
            this.updateGroupScore(leaderboardConfig, scoreDiff);
          });
        } else {
          data.score += scoreDiff;
          data.timeModified = serverTimestamp();
          this.afs.doc('leaderboardUserBoosterScore/' + snap.docs[0].id).set(data);
          this.updateGroupScore(leaderboardConfig, scoreDiff);
        }
      } else {
        this.getGeoLocation().then(locationModified => {
          let data = {
            uid,
            leaderboardId: leaderboardConfig.__id,
            score: scoreDiff,
            timeModified: serverTimestamp(),
            name: this.auth.getDisplayName(),
            locationModified,
          };
          this.afs.collection<ILeaderboardScore>('leaderboardUserBoosterScore/').add(data);
          this.updateGroupScore(leaderboardConfig, scoreDiff);
        });
      }
    });
  }

  public updateGroupName(group: ILeaderboardGroup, newName: string) {
    group.name = newName;
    this.updateLeaderboardGroup(group);
    this.afs.collection<ILeaderboardGroupScore>('leaderboardGroupScore/', ref => ref
      .where('groupId', '==', group.__id)
      .where('leaderboardId', '==', group.leaderboardId)
      .limit(1)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        let groupScore = snap.docs[0].data() as ILeaderboardGroupScore;
        groupScore.name = newName;
        this.afs.doc('leaderboardGroupScore/' + snap.docs[0].id).set(groupScore);
      }
    });
  }

  private updateGroupScore(leaderboardConfig: ILeaderboard, scoreDiff: number) {
    let uid = this.auth.getUid();
    this.getMyLeaderboardGroup(leaderboardConfig, true).then(group => {
      this.afs.collection<ILeaderboardGroupScore>('leaderboardGroupScore/', ref => ref
      .where('groupId', '==', group.__id)
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .limit(1)
      ).get().toPromise().then(snap2 => {
        if (snap2.docs.length > 0) {
          let data = snap2.docs[0].data() as ILeaderboardGroupScore;
          data.baseScore = (data.baseScore || 0) + scoreDiff;
          data.numStudents = group.numStudents;
          if (leaderboardConfig.minGroupSize) {
            data.hasMinStudents = group.numStudents >= leaderboardConfig.minGroupSize;
          }
          if (leaderboardConfig.scoreAggregation === 'average') {
            data.score = this.calculateAverageGroupScore(data.baseScore, data.numStudents);
          } else {
            data.score = data.baseScore;
          }
          data.timeModified = serverTimestamp();
          data.name = group.name;

          this.afs.doc('leaderboardGroupScore/' + snap2.docs[0].id).set(data);
        } else {
          let data = {
            groupId: group.__id,
            leaderboardId: leaderboardConfig.__id,
            baseScore: scoreDiff,
            numStudents: group.numStudents,
            hasMinStudents: group.numStudents >= leaderboardConfig.minGroupSize,
            score: this.calculateAverageGroupScore(scoreDiff, group.numStudents),
            timeModified: serverTimestamp(),
            name: group.name,
          };
          this.afs.collection<ILeaderboardGroupScore>('leaderboardGroupScore/').add(data);
        }
      });
    });
  }

  private calculateItemScore(leaderboard: ILeaderboard, payload: IMessagePayload, performance: IItemScore) {
    let score: number;
    // console.log('calculateItemScore', leaderboard, payload, performance)
    if (leaderboard.scoreCollected === 'stars') {
      score = performance.numStars * (leaderboard.pointsPerStar || 0);
      if (performance.hasMedal) {
        score += (leaderboard.pointsPerMedal || 0);
      }
    } else {
      let stateArray: {[key: string]: number}[] = [];
      let app_state: any = (payload as any).app_state;
      for (let key of Object.keys(app_state.state_data)) {
        let sceneState: any = app_state.state_data[key];
        for (let key2 of Object.keys(sceneState)) {
          if (sceneState[key2].state) {
            stateArray.push(sceneState[key2].state);
          }
        }
      }
      
      score = 0;
      let filters = leaderboard.scoreFlagFilters;
      stateArray.forEach(state => {
        for (let key of Object.keys(state)) {
          if (_.isNumber(state[key])) {
            if (!filters || filters.length === 0 || filters.indexOf(key) !== -1) {
              score += state[key];
            }
          }
        }
      });
      console.log('scoring!', leaderboard, payload, performance, stateArray, score);
    }

    return score;
  }

  private calculateAverageGroupScore(score: number, numStudents: number) {
    return score / numStudents;
  }

  joinLeaderboardGroupById(groupId: string) {
    let group: ILeaderboardGroup;

    return this.afs.doc<ILeaderboardGroup>('leaderboardGroups/' + groupId).get().toPromise().then(snap => {
      group = snap.data() as ILeaderboardGroup;
      group.numStudents++;
      group.students.push(this.auth.getUid());

      return this.afs.doc('leaderboardGroups/' + groupId).set(group).then(() => {
        group.__id = groupId;
      });
    }).then(() => group);
  }

  private findAndJoinGroup(leaderboard: ILeaderboard) {
    switch(leaderboard.grouping) {
      case 'class':
        if (this.foreign.isForeign()) {
          let foreignUser = this.foreign.getForeignUserData();
          return this.joinLeaderboardGroupByForeignClassId(leaderboard, foreignUser.foreignClassId);
        } else {
          return this.joinRandomLeaderboardGroup(leaderboard);
        }
        case 'random': return this.joinRandomLeaderboardGroup(leaderboard);
      case 'houses': return this.joinRandomLeaderboardHouse(leaderboard);
      case 'individual': default: return this.joinNewGroup(leaderboard, {});
    }
  }

  joinNewGroup(leaderboard: ILeaderboard, config: Partial<ILeaderboardGroup>) {
    if (config.name) {
      let group: ILeaderboardGroup = {
        name: config.name,
        leaderboardId: leaderboard.__id,
        students: [this.auth.getUid()],
        numStudents: 1,
        timeCreated: serverTimestamp()
      }

      return this.afs.collection('leaderboardGroups/').add(group).then(value => {
        group.__id = value.id;
        return group;
      });
    }
    return this.generateUniqueTeamName(leaderboard).then(name => {
      let group: ILeaderboardGroup = {
        name,
        leaderboardId: leaderboard.__id,
        students: [this.auth.getUid()],
        numStudents: 1,
        timeCreated: serverTimestamp()
      };

      _.assign(group, config);

      return this.afs.collection('leaderboardGroups/').add(group).then(value => {
        group.__id = value.id;
        return group;
      });
    });
  }

  joinLeaderboardGroupByForeignClassId(leaderboardConfig: ILeaderboard, foreignClassId: string) {
    return this.afs.collection<ILeaderboardGroup>('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboardConfig.__id)
      .where('foreignClassId', '==', foreignClassId)
      .limit(1)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        let group = snap.docs[0].data() as ILeaderboardGroup;
        group.__id = snap.docs[0].id;
        group.numStudents++;
        group.students.push(this.auth.getUid());
        return this.afs.doc('leaderboardGroups/' + group.__id).update({numStudents: group.numStudents, students: group.students}).then(() => {
          return group;
        });
      } else {
        return this.joinNewGroup(leaderboardConfig, {foreignClassId});
      }
    });
  }

  joinRandomLeaderboardHouse(leaderboard: ILeaderboard) {
    return this.afs.collection<ILeaderboardGroup>('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboard.__id)
    ).get().toPromise().then(snap => {
      if (snap.docs.length > 0) {
        let groups = _.map(snap.docs, doc => {
          let data = doc.data() as ILeaderboardGroup;
          data.__id = doc.id;
          return data; 
        });

        if (groups.length < leaderboard.houses.length) {
          let remainingNames = _.filter(leaderboard.houses, name => !_.find(groups, {name}));
          return this.joinNewGroup(leaderboard, {name: remainingNames[0]});
        } else {
          groups = _.sortBy(groups, group => group.numStudents);
          let group: ILeaderboardGroup;
          if (groups[1].numStudents > groups[0].numStudents * 1.25) {
            group = groups[0];
          } else {
            group = groups[Math.floor(Math.random() * 2)];
          }

          group.numStudents++;
          group.students.push(this.auth.getUid());
          return this.afs.doc('leaderboardGroups/' + group.__id).update({numStudents: group.numStudents, students: group.students}).then(() => {
            return group;
          });
        }
      } else {
        return this.joinNewGroup(leaderboard, {name: leaderboard.houses[0]});
      }
    });
  }

  joinRandomLeaderboardGroup(leaderboard: ILeaderboard) {
    return this.afs.collection<ILeaderboardGroup>('leaderboardGroups/', ref => ref
      .where('leaderboardId', '==', leaderboard.__id)
      .where('numStudents', '<', leaderboard.minGroupSize || 3)
      .orderBy('numStudents')
      .limit(10)
    ).get().toPromise().then(snap => {

      if (snap.docs.length > 0) {
        let group = snap.docs[0].data() as ILeaderboardGroup;
        group.__id = snap.docs[0].id;
        group.numStudents++;
        group.students.push(this.auth.getUid());
        return this.afs.doc('leaderboardGroups/' + group.__id).update({numStudents: group.numStudents, students: group.students}).then(() => {
          return group;
        });
      } else {
        return this.joinNewGroup(leaderboard, {moderator: this.auth.getUid()});
      }
    });
  }

  generateRandomTeamName(teamNameListId:string){
    if (!teamNameListId || teamNameListId === 'none'){
      return Promise.all([]).then( () => {
        return '---'
      })
    }
    else{
      return this.afs
        .doc('leaderboardTeamNamesLists/'+teamNameListId)
        .get()
        .toPromise()
        .then( res=> {
          const teamNameList = res.data();
          const numNames = teamNameList.names || 0;
          const name_i = Math.floor( Math.random() * numNames );
          return this.afs
            .collection('leaderboardTeamNames', ref=>ref
              .where('i', '==', name_i)
              .where('listId', '==', teamNameListId)
            )
            .get()
            .toPromise()
            .then( res=> {
              const randomNamePacket = res.docs[0].data();
              return randomNamePacket.name;
            })
        })
    }
  }

  private generateUniqueTeamName(leaderboard: ILeaderboard) {
    return this.generateRandomTeamName(leaderboard.teamNameList).then(generatedName => {
      let baseGeneratedName = this.getBaseTeamName(generatedName);
      return this.afs.collection('leaderboardUsedTeamNames/', ref => ref
        .where('name', '==', baseGeneratedName)
      ).get().toPromise().then(snap2 => {
        if (snap2.docs.length > 0) {
          let usedNameConfig = snap2.docs[0].data() as ILeaderboardUsedTeamName;
          if (usedNameConfig.count > 0) {
            usedNameConfig.count++;
            let count = usedNameConfig.count;
            generatedName += ' ' + String(count);
            this.afs.doc('leaderboardUsedTeamNames/' + snap2.docs[0].id).set(usedNameConfig);
            return generatedName;
          }
        }

        let usedName: ILeaderboardUsedTeamName = {
          name: baseGeneratedName,
          count: 1,
          leaderboardId: leaderboard.__id,
          timeCreated: serverTimestamp(),
          timeLastTouched: serverTimestamp(),
        };

        if (snap2.docs.length > 0) {
          this.afs.doc('leaderboardUsedTeamNames/' + snap2.docs[0].id).set(usedName);
        } else {
          this.afs.collection('leaderboardUsedTeamNames/').add(usedName);
        }

        return generatedName;
      });
    });
  }

  // updateLeaderboardGroupName(leaderboardGroup: ILeaderboardGroup, newName: string) {
  //   let trailingNumbers = this.getTrailingNumbers(newName);
  //   newName = this.stripTrailingNumbers(newName);
  //   let baseTeamName = this.getBaseTeamName(newName);
  //   console.log(newName, trailingNumbers, baseTeamName);

  //   return this.afs.collection('leaderboardUsedTeamNames/', ref => ref
  //     .where('name', '==', baseTeamName)
  //   ).get().toPromise().then( snap => {
  //     if (snap.docs.length > 0) {
  //       let usedNameConfig = snap.docs[0].data() as ILeaderboardUsedTeamName;
  //       if (usedNameConfig.count > 0) {
  //         usedNameConfig.count++;
  //         let count = usedNameConfig.count;
  //         newName += ' ' + String(count);
  //         this.afs.doc('leaderboardUsedTeamNames/' + snap.docs[0].id).set(usedNameConfig);
  //         return newName;
  //       }
  //     }

  //     let usedName: ILeaderboardUsedTeamName = {
  //       name: baseTeamName,
  //       count: 1,
  //       leaderboardId: leaderboard.__id,
  //       timeCreated: serverTimestamp(),
  //       timeLastTouched: serverTimestamp(),
  //     };

  //     if (snap.docs.length > 0) {
  //       this.afs.doc('leaderboardUsedTeamNames/' + snap.docs[0].id).set(usedName);
  //     } else {
  //       this.afs.collection('leaderboardUsedTeamNames/').add(usedName);
  //     }

  //     return newName;
  //   });
  // }

  // getTrailingNumbers(name: string): string {
  //   name = name.trim();
  //   let firstDigit = name.length;
  //   while (firstDigit > 0 && name.charAt(firstDigit - 1).match(/[0-9]/)) {
  //     firstDigit--;
  //   }
  //   if (firstDigit < name.length) {
  //     return name.substring(firstDigit);
  //   } else {
  //     return '';
  //   }
  // }

  stripTrailingNumbers(name: string): string {
    while (name.charAt(name.length - 1) === ' ' || name.charAt(name.length - 1).match(/[0-9]/)) {
      name = name.substr(0, name.length - 1);
    }

    return name;
  }

  getBaseTeamName(name: string): string {
    name = name.replace(/[^a-zA-Z]+/g, '').toLowerCase().trim();
    name = this.stripTrailingNumbers(name);

    return name;
  }

  // USE THIS ONLY FOR CAMBRIDGE INTERNAL TEST
  // private getGeoLocation() {
  //   return new Promise<string>(resolve => {
  //     if (this.locationModified) {
  //       resolve(this.locationModified);
  //     } else {
  //       this.user.getUserInfoAsPromise(this.auth.getUid()).then(user => {
  //         if (user.classroomsAsStudent && user.classroomsAsStudent.length > 0) {
  //           this.cs.getClassroomInfoAsPromise(user.classroomsAsStudent[0]).then(classroom => {
  //             resolve(classroom.name);
  //           })
  //         } else {
  //           resolve('');
  //         }
  //       });
  //     }
  //   });
  // }

  // USE THIS FOR FINAL DEPLOYMENT
  private getGeoLocation() {
    return new Promise<string>(resolve => {
      if (this.locationModified) {
        resolve(this.locationModified);
      } else {
        fetch('https://extreme-ip-lookup.com/json/')
        .then( res => res.json())
        .then(response => {
          // this.locationModified = response.city + ' ' + response.country; // Ryan said no to using city.
          this.locationModified = response.country;
          resolve(this.locationModified);
        })
        .catch(() => {
            resolve('');
        });
      }
    });
  }

  public isLeaderboardActive(leaderboard: ILeaderboard) {
    if (!leaderboard.isActive) {
      return false;
    }

    let currentSeconds = new Date().getTime() / 1000;
    if (leaderboard.dateOpened && currentSeconds < leaderboard.dateOpened.seconds) {
      return false;
    }

    if (leaderboard.dateClosed && currentSeconds > leaderboard.dateClosed.seconds) {
      return false;
    }

    return true;
  }
}

interface ILeaderboardUsedTeamName {
  count?: number;
  name?: string;
  leaderboardId?: string;
  timeCreated?: FirebaseTimestamp;
  timeLastTouched?: FirebaseTimestamp;
}
