import { Component } from '@angular/core';
import { AuthService } from './core/auth.service';
import { SidepanelService } from './core/sidepanel.service';
import { WhitelabelService } from './domain/whitelabel.service';
import { ChatpanelService } from './core/chatpanel.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';
  constructor(
    private auth: AuthService,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    public whiteLabel: WhitelabelService,
  ) {
    this.chatPanel.activate();
  }
  isSidePanelExpanded(){
    return this.sidePanel.getExpandedStatus();
  }
  isSidePanelVisible(){
    return this.sidePanel.getVisibilityStatus();
  }
  isMobileLeftPanel(){
    return this.sidePanel.getMobileVisibilityStatus();
  }
  openMobileLeftPanel(){
    this.sidePanel.activateMobile();
  }
  closeMobileLeftPanel(){
    this.sidePanel.deactivateMobile();
  }
  
}
