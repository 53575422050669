import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  langCode:string = 'en';
  allowableLangCodes = ['en', 'fr', 'de', 'pt'];

  constructor() { }

  setLangCode(langCode:string){
    if ( langCode && (this.allowableLangCodes.indexOf(langCode) !== -1) ){
      this.langCode = langCode;
    }
  }

  getLangCode(){
    return this.langCode;
  }
}
