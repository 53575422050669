import { Component, OnInit } from '@angular/core';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ActivatedRoute } from '@angular/router';
import { ChatpanelService } from '../../core/chatpanel.service';
import { BoosterpacksService } from '../../ui-student/boosterpacks.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IBoosterPackSummary } from '../../data/cambridge/types';
import { IClassroom } from '../../data/collections/classrooms.types';

@Component({
  selector: 'teacher-boosterpacks',
  templateUrl: './teacher-boosterpacks.component.html',
  styleUrls: ['./teacher-boosterpacks.component.scss']
})
export class TeacherBoosterpacksComponent implements OnInit {

  boosterPacks:IBoosterPackSummary[];
  classroomId:string;
  classConfig:IClassroom;

  constructor(
    private afs: AngularFirestore,
    private bps: BoosterpacksService,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
      if (!this.classroomId){ return; }
      this.loadBoosterPacks();
    })
  }

  loadBoosterPacks(){
    this.bps
      .getBoosterPacks()
      .then((boosterPackSummaries) =>{
        this.boosterPacks = boosterPackSummaries;
      })
  }

  getPackTitleImage(pack:IBoosterPackSummary){
    return pack.titleImage;
  }

}
