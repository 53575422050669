import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IAssignmentTaskSubmission } from '../data/collections/assignment-task-submissions.types';

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { 
    // 
  }

  getPerformanceCutoff(){
    return 0.8;
  }

  // initAsmtSubmListener(assignmentId:string, taskId:string){

  // }

  computeQuickScore(assignmentId:string, taskId:string){
    const uid = this.auth.getUid();
    return this.afs
      .collection<IAssignmentTaskSubmission>('assignmentTaskSubmissions', ref=>ref
          .where('uid', '==', uid)
          .where('taskId', '==', taskId)
          .where('assignmentId', '==', assignmentId)
          .limit(100)
      )
      .get()
      .toPromise()
      .then(res => {
        const performances = new Map();
        res.forEach(resEntry => {
          const entry = <IAssignmentTaskSubmission>resEntry.data();
          if (entry.questionTaskId && !performances.get(entry.questionTaskId)){
            performances.set(entry.questionTaskId, entry.isCorrect);
          }
        });
        let numCorrect = 0;
        let numTotal = 0;
        performances.forEach((isCorrect, questionTaskId)=>{
          numTotal ++;
          if(isCorrect){
            numCorrect ++;
          }
        })
        if (numTotal === 0){
          return 0;
        }
        else{
          return numCorrect / numTotal;
        }
      })
  }
}
