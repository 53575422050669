import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { ChatpanelService } from '../../core/chatpanel.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '../../core/auth.service';
import { Subscription } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { serverTimestamp } from '../../data/timestamp';

interface ILeaderboardSubmission {
  __id?:string,
  gameLobby: string,
  playerDisplayName: string,
  uid: string,
  score: number,
  scoreDisplay: string,
  timestamp: any,
}

const SORT_SCORE_DATE = (a:ILeaderboardSubmission, b:ILeaderboardSubmission) => {
  const better = -1;
  const worse = 1;
  if (a.score === b.score){
    if (! a.timestamp){ return worse; }
    if (! b.timestamp){ return better; }
    if (a.timestamp.seconds < b.timestamp.seconds){
      return better;
    }
    else{
      return worse;
    }
  }
  if (a.score > b.score){
    return better
  }
  return worse;
}

@Component({
  selector: 'student-leaderboard-game',
  templateUrl: './student-leaderboard-game.component.html',
  styleUrls: ['./student-leaderboard-game.component.scss']
})
export class StudentLeaderboardGameComponent implements OnInit, OnDestroy {

  @ViewChild('lessonContainer') lessonContainer : ElementRef;

  classroomId: string;
  activeItemId: string;
  activeGameLobbyId: string;
  itematicIframe:SafeResourceUrl;
  activeGameLobby: {caption: string, timeCreated:any, gameUrl:string, isClosed: boolean}
  personalBest:ILeaderboardSubmission;
  worstOfTheBest:ILeaderboardSubmission;
  leaderboardList:ILeaderboardSubmission[] = []
  leaderboardTruncated:ILeaderboardSubmission[] = []

  private routeSub:Subscription;
  private routeDataSub:Subscription;
  private postMessageSubCancel: Function;
  private routeData:{isTeacher?:boolean} = {}

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private renderer: Renderer2,
  ) { }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.deactivate();
    this.routeSub = this.route.params.subscribe(params => {
      this.activeGameLobbyId = params['gameLobbyId'];
      // this.classroomId = params['classroomId']; // only for teachers
      this.loadLobby().then(()=>{
        this.loadLeaderboard();
        this.renderItematicIFrame();
      })
    });
    this.routeDataSub = this.route.data.subscribe(v => this.routeData = v );
    this.postMessageSubCancel = this.renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnDestroy(){
    try { this.postMessageSubCancel(); } catch(e){}
    // try { clearInterval(this.sessionPingIntervalId); } catch(e){}
    // if (!this.isComplete){
    //   this.bps.pingAttemptSession(this.activeItemInfo.sessionId);
    // }
  }

  renderItematicIFrame(){
    this.itematicIframe = this.sanitizer.bypassSecurityTrustResourceUrl(this.activeGameLobby.gameUrl);
  }

  // markEarlyExit(){
  //   this.bps
  //     .closeItemAttemptSession(this.activeItemInfo.sessionId, false, 0, null)
  // }
  // markAsCompleted(score:number){ }

  

  loadLobby(){
    return this.afs
      .doc('gameLeaderboardLobby/'+this.activeGameLobbyId)
      .get()
      .toPromise()
      .then( res => {
        this.activeGameLobby = <any>res.data();
        if (this.activeGameLobby.isClosed){
          alert('This leaderboard has closed!');
          this.router.navigate(['/'])
        }
        return true;
      })
  }

  loadLeaderboard(){
    this.afs
      .collection('gameLeaderboardSubmissions', ref=>ref.where('gameLobby', '==', this.activeGameLobbyId))
      .stateChanges()
      .subscribe(entries => {
        entries.forEach(entry => {
          const submission:ILeaderboardSubmission = {
            ... <any>entry.payload.doc.data(),
            __id: entry.payload.doc.id
          }
          if (entry.payload.type === 'added'){
            this.leaderboardList.push(submission);
          }
          if (entry.payload.type === 'modified'){
            this.leaderboardList.forEach((_submission, i) => {
              if (_submission.__id === submission.__id){
                this.leaderboardList[i] = submission;
              }
            })
          }
        });
        this.leaderboardList = this.leaderboardList.sort(SORT_SCORE_DATE);
        const usersPulled = new Map();
        this.leaderboardTruncated = []
        for (let i=0; i<this.leaderboardList.length; i++){
          let entry = this.leaderboardList[i];
          if (!usersPulled.get(entry.uid)){
            this.leaderboardTruncated.push(entry);
            usersPulled.set(entry.uid, true);
          }
          if (this.leaderboardTruncated.length >= 10){
            break;
          }
        }
        if (this.leaderboardList.length > 10){
          this.worstOfTheBest = this.leaderboardList[10];
        }
        // console.log('submission', this.leaderboardList, this.leaderboardTruncated)
        // console.log('this.leaderboardList', this.leaderboardList)
      })
  }

  renderDate(firebaseTimestamp:{seconds:number}){
    if (firebaseTimestamp && firebaseTimestamp.seconds){
      const d = new Date(firebaseTimestamp.seconds*1000);
      return d.toDateString();
    }
    return ''
  }

  isMe(uid:string){
    console.log('uid', uid, this.auth.getUid())
    return uid === this.auth.getUid()
  }
  
  getDisplayName(){
    return this.auth.getDisplayName();
  }

  isLeaderboardCollapsed:boolean;
  toggleLeaderboardCollapse(){
    this.isLeaderboardCollapsed = !this.isLeaderboardCollapsed;
  }

  // testPostScore(){
  //   let stateData = {};
  //   _.set(stateData, ['-LWpxSJ3j5OhfSpVszFk','game','state','strokes'], 10);
  //   console.log(stateData);
  //   this.postScore(stateData)
  // }

  postScore(stateData:any){
    let sceneId = Object.keys(stateData)[0];
    let gameNode = stateData[sceneId]['game'];
    if (!gameNode){
      gameNode = stateData[sceneId]['N10'];
    }
    if (!gameNode){
      alert('Game malformed. Cannot record score')
      return;
    }
    // define mapping better than this
    const rawScore = 1*gameNode['state']['score'];
    const computedScore = rawScore;
    const displayScore = rawScore+' points';

    this.afs
      .collection('gameLeaderboardSubmissions')
      .add({
        gameLobby: this.activeGameLobbyId,
        playerDisplayName: this.auth.getDisplayName(),
        uid: this.auth.getUid(),
        score: computedScore,
        scoreDisplay: displayScore,
        timestamp: serverTimestamp(),
      })
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    let data:{type:string, payload:any};
    // if (message.origin !== ITEMATIC_ENGINE_DOMAIN) return;
    // console.log('message.data', message.data)
    if (!message.data || typeof message.data === 'object' ){
      return;
    }
    try {
      data = JSON.parse(message.data);
    }
    catch(e){
      console.error(e);
      return;
    }
    if (data.type === 'ITEM_PROGRESS'){
      // this.recordSubmission(data.payload);
      let payload:{completed:boolean, app_state: any} = data.payload
      if (payload.completed){ // based on Itematic data model
        this.postScore(payload.app_state.state_data);
      }
    }
  }

  messageIntoLesson(type:string, payload:any){
    let el = <HTMLIFrameElement>this.lessonContainer.nativeElement;
    el.contentWindow.postMessage(JSON.stringify({
      type,
      payload
    }), '*');
  }

  
}
