// games.types.ts


import { FirebaseTimestamp } from '../base.types';
import { FDGElement } from 'src/app/ui-avatar-displays/util/FDG/FDGElement';
import { DragonSprite } from 'src/app/ui-avatar-displays/util/DragonAvatar/DragonSprite';
import { IUserAvatarCosmeticsEquipped, ILoadedCosmeticsEquipped } from 'src/app/ui-avatar-displays/util/DragonAvatar/DragonInterfaces';

export interface IHeadsUpLobby {
  assignmentId: string;
  currentQuestionIndex: number;
  screenMode: string;
  isStarted: boolean;
  isSolutionShown: boolean;
  showSolutions?: boolean;
  createdByUid?: string;
  createdByName?: string;
  timeOpen?: FirebaseTimestamp;
  timeStart?: FirebaseTimestamp;
  timeClose?: FirebaseTimestamp;
}

export interface IHeadsUpLobbyPlayers {
  __id?: string;
  assignmentId: string;
  isAnonymous?: boolean;
  uid: string;
  displayName?: string;
  isPresent: boolean;
  // character?: any; // ICharacter
  coins?: number;
  score?: number;
  rank?: number;
  email?: string; // only need this prior to export 
}

export interface IHeadsUpLobbyPlayerActions {
  assignmentId: string;
  uid: string;
  actionId: string;
  details: any;
  timestamp: FirebaseTimestamp;
  isProcessed: boolean;
}

export enum AvatarPlayerActions {
  MOVE = 'move',
  CHANGE_COSMETIC = 'change-cosmetic',
  CHANGE_COLOR = 'change-color'
}

export interface IHeadsUpLobbyPlayerQuestionState {
  isCorrect: boolean;
  isSubmitted: boolean;
  isFilled: boolean;
  uid: string;
  timestamp: FirebaseTimestamp;
  assignmentId: string;
  taskId: string;
  questionTaskId: string;
}
export interface IStudentBubble {
  uid: string;
  cosmetics: ILoadedCosmeticsEquipped
  node?: FDGElement;
  avatar?: DragonSprite;
  start?: { x: number, y: number };
  noVisual?: boolean;
}

export interface IUserCoins {
  coins: number;
  uid: string;
  updated: FirebaseTimestamp;
}

export enum CosmeticCategory {
  HAT = 'hat',
  HAIR = 'hair',

  EARS = 'ears',
  TATTOO = 'tattoo',
  GLASSES = 'glasses',
  BEARD = 'beard',

  SHIRT = 'shirt',
  PANTS = 'pants',
  SHOES = 'shoes',

  HELD = 'held',
  BACK = 'back',
  NECK = 'neck',
  BELT = 'belt',

  SKIN = 'skin',
  HAIR_COLOR = 'hair-color',

  ACCESSORY_HEAD = 'a-head',
  ACCESSORY_BODY = 'a-body',

  BODY = 'body',
  CLEAR = 'clear',
  STICKER = 'sticker',
}

export function genBaseCosmetics(uid: string): IUserAvatarCosmeticsEquipped {
  return {
    uid,
    elements: {
      body: 'Character',
      hair: 'PoofHair',
      shirt: 'WhiteShirt',
      pants: 'BlueJeans',
      skin: 'skin#F1C27D',
      'hair-color': 'hair#090806'
    }
  }
}

export enum HeadsUpScreenModes {
  LOADING = 'LOADING',
  LOBBY = 'LOBBY',
  QUESTIONS = 'QUESTIONS',
  LEADERBOARD = 'LEADERBOARD',
  PODIUM = 'PODIUM',
}

export enum CosmeticUnlock {
  BASIC = 'basic',
  COINS = 'coins',
  PREMIUM = 'premium',
  EVENT = 'event'
}

export interface IUserAvatarCosmeticsEarned {
  uid: string;
  slug: string;
  category: string;
  isAcquired: boolean;
  timestamp: FirebaseTimestamp;
}

export interface ICosmeticInfo {
  slug: string;
  cosmeticCategory: CosmeticCategory;
  skeletonId?: string;

  linkingJSON?: string;
  textureJSON?: string;
  texturePNG?: string;
  thumbnail?: string;
  thumbnailColor?: string;
  thumbnailSVG?: boolean;

  unlock: CosmeticUnlock;
  cost?: number;
  eventSlug?: string;
  eventDescription?: string;

  sortPriority?: number;
  owned?: boolean;
  timeAcquired?: FirebaseTimestamp;
}

export interface IExplorePlayerCollection {
  uid: string;
  data: IExploreLocation;
  cosmetics: ILoadedCosmeticsEquipped;
}

export interface IExploreLocation {
  shard: string;
  location: string;
  mapSlug: string;
  uid: string;

  present: boolean;
  x: number;
  y: number;

  costumeUpdated: FirebaseTimestamp;
  latestActivity: FirebaseTimestamp;
}
