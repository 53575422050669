import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { ReplaySubject } from 'rxjs';
import { IUser, UserUi } from '../data/collections/users.types';
import { IUsersReservedUsernamePrefix } from '../data/collections/users-reserved-username-prefixes.types';
import { serverTimestamp } from '../data/timestamp';
import { IClassroomStudentPlaintextPasswords } from '../data/collections/classroomStudentPlaintextPasswords.types';
import { IClassroom } from '../data/collections/classrooms.types';
import { ClassroomService } from '../ui-teacher/classroom.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { renderEmailFromUsername } from '../data/accounts/pseudo-email-usernames';
import { FunctionsService } from './functions.service';
import { WhitelabelService, WLST_TRIAL_ID, WLST_INST_SCOPE_ID } from '../domain/whitelabel.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  userListeners:Map<string, ReplaySubject<IUser>> = new Map();

  constructor(
    private afs: AngularFirestore,
    private funs: FunctionsService,
    private auth: AuthService,
    private classroomService: ClassroomService,
    private whiteLabelService: WhitelabelService,
  ) {

  }

  test(){
    // console.log('afFun test')
    // this.funs.post('holaMundo', {})
    //   .toPromise()
    //   .then(res => {
    //     console.log('afFun', res)
    //   })
    //   .catch(err => {
    //     console.error('afFun error', err);
    //   })
  }

  completeStudentRegistration(uid:string, password:string, targetCurricId:string, classroomId:string){
    return this.afs
      .doc<Partial<IUser>>(`users/${uid}`)
      .set({ 
        ui: UserUi.STUDENT,
        anchorCurricId: targetCurricId,
        classroomsAsStudent: [classroomId],
        trialId: this.whiteLabelService.getSiteText(WLST_TRIAL_ID),
        instScopeIds: [this.whiteLabelService.getSiteText(WLST_INST_SCOPE_ID)]
      }, {merge:true})
      .then(()=>{
        return this.classroomService
          .addStudentToClassroom(classroomId, uid, password)
      })
  }

  usernameCountCache:Map<string, number> = new Map();
  generateUniqueUsername(baseUsername:string){
    baseUsername = baseUsername.replace(/[^a-zA-Z]+/g, '')
    baseUsername = baseUsername.toLowerCase();
    console.log('generateUniqueUsername', baseUsername)
    return this.afs.doc<IUsersReservedUsernamePrefix>('usersReservedUsernamePrefixes/'+baseUsername)
      .get()
      .toPromise()
      .then( val => {
        const tally:IUsersReservedUsernamePrefix = <any>val.data();
        let oldCount = (tally ? tally.count : 0);
        if (this.usernameCountCache.has(baseUsername)){
          oldCount = Math.max(oldCount, this.usernameCountCache.get(baseUsername))
        }
        const newCount = oldCount + 1;
        this.usernameCountCache.set(baseUsername, newCount);
        const newUsername = baseUsername+(newCount);
        const docUpdate:IUsersReservedUsernamePrefix = {
          count: newCount,
          timeLastTouched: serverTimestamp(),
        };
        if (!tally){
          docUpdate.timeCreated = serverTimestamp();
        }
        // console.log('newUsername', newUsername, docUpdate)
        return this.afs.doc<IUsersReservedUsernamePrefix>('usersReservedUsernamePrefixes/'+baseUsername)
          .set(docUpdate)
          .then( () => {
            // console.log('all set', newUsername)
            return newUsername;
          })
          .catch(err => {
            console.error('failed to update username', err)
          })

      })
  }

  parseStudentPassword(password:string){
    if (!password || password === '{HIDDEN}'){
      return '*******'
    }
    return password;
  }

  private passworOptions = [ 'band', 'bell', 'best', 'blue', 'boat', 'book',  'card', 'cast', 'cell', 'chat', 'chip', 'city', 'code', 'cook', 'crew', 'crop', 'data', 'dawn', 'days', 'desk', 'dial', 'each', 'east', 'fact', 'fair', 'farm', 'fast', 'fate', 'file', 'film', 'find', 'firm', 'five', 'flow', 'food', 'fort', 'from', 'fuel', 'gain', 'game', 'gate', 'gear', 'gift', 'give', 'glad', 'goal', 'good', 'gulf', 'half', 'hall', 'hero', 'hill', 'hope', 'host', 'hour', 'idea', 'iron', 'item', 'jump', 'just', 'keen', 'keep', 'kind', 'lake', 'land', 'lane', 'lead', 'lift', 'like', 'link', 'list', 'live', 'look', 'mail', 'make', 'menu', 'milk', 'mill', 'mind', 'mode', 'mood', 'moon', 'more', 'most', 'much', 'navy', 'news', 'next', 'nice', 'note', 'only', 'open', 'pack', 'page', 'path', 'peak', 'pick', 'plan', 'play', 'plus', 'pool', 'port', 'pure', 'rare', 'read', 'real', 'rent', 'rest', 'rice', 'ring', 'road', 'rock', 'root', 'rose', 'rule', 'rush', 'safe', 'sand', 'save', 'seat', 'send', 'sent', 'ship', 'shop', 'show', 'side', 'sign', 'site', 'size', 'slip', 'song', 'spot', 'star', 'stay', 'step', 'tank', 'team', 'term', 'time', 'tour', 'town', 'tree', 'trip', 'true', 'tune', 'twin', 'type', 'unit', 'upon', 'wave', 'well', 'west', 'wind', 'wing', 'wire', 'wise', 'wish', 'word', 'work', 'yard', 'year', 'zone']

  createTeacherStudent(classroomId:string, displayName:string){
    const teacherUid = this.auth.getUid();
    const password = this.passworOptions[Math.floor(Math.random()*this.passworOptions.length)] + Math.floor((10 + 90*Math.random()));
    const newStudentIdentifier:{uid?:string, email?:string, username?:string} = {}
    return this.generateUniqueUsername(displayName)
      .then(username => {
        console.log('New Username:', username);
        newStudentIdentifier.username = <string>username;
        newStudentIdentifier.email = renderEmailFromUsername(newStudentIdentifier.username);
        return this.auth.getIdToken()
      })
      .then( teacherToken => {
        return this.funs.post('createTeacherStudent', { 
          classroomId,
          teacherUid,
          teacherToken,
          email: newStudentIdentifier.email,
          username: newStudentIdentifier.username,
          password,
          displayName,
        })
        .toPromise()
      })
      .then( res =>{
        const uid = res['data'].uid;
        console.log('uid', res);
        newStudentIdentifier.uid = uid;
        return this.classroomService.addStudentToClassroom(classroomId, uid, password)
      })
      .then( ()=> {
        return {
          ... newStudentIdentifier,
          displayName,
          password,
        }
      })
  }

  updateUserDisplayName(classroomId:string, studentUid:string, firstName:string, lastNameInitial:string){
    const teacherUid = this.auth.getUid();
    const lastName = lastNameInitial;
    const displayName = firstName+' '+lastNameInitial;
    return this.auth.getIdToken().then( teacherToken => {
      return this.funs.post('updateUserDisplayName', { 
        classroomId,
        teacherUid,
        teacherToken,
        studentUid,
        firstName,
        lastName,
        displayName,
      }).toPromise()
    });
  }
  updateUserPassword(classroomId:string, studentUid:string, studentUsername:string, newPassword:string){
    return this.auth.getIdToken().then( teacherToken => {
      const teacherUid = this.auth.getUid();
      const studentEmail = renderEmailFromUsername(studentUsername);
      return this.funs.post('updateUsernamePassword', { 
        classroomId,
        teacherUid,
        teacherToken,
        studentUid,
        studentUsername,
        studentEmail,
        newPassword
      })
      .toPromise()
      .then(()=>{
        return this.classroomService.registerStudentPasswordUpdate(classroomId, studentUid, newPassword);
      })
      .then(()=>true)
    })
  }
  getUserInfo(uid:string) : ReplaySubject<IUser> {
    if (this.userListeners.has(uid)){
      return this.userListeners.get(uid);
    }
    let subject:ReplaySubject<IUser> = new ReplaySubject(1);
    this.userListeners.set(uid, subject);
    this.afs.doc<IUser>('users/'+uid)
        .valueChanges()
        .subscribe( observer => {
          subject.next(observer);
        });
    return subject;
  }

  getUserInfoAsPromise(uid:string) {
    return this.afs.doc<IUser>('users/' + uid).get().toPromise().then(snap => snap.data() as IUser);
  }
}
