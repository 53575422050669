import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'field-trial-teachers',
  templateUrl: './field-trial-teachers.component.html',
  styleUrls: ['./field-trial-teachers.component.scss']
})
export class FieldTrialTeachersComponent implements OnInit {

  trialId:string = '';

  constructor(
    public router: Router,
    private auth: AuthService,
    private route: ActivatedRoute,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.route.params.subscribe(params => {
      this.trialId = params['trialId'];
    });
  }


}
