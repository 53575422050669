import { Component, OnInit, Input } from '@angular/core';
import { IAssessmentFrameworkDimensionDetail } from '../assessment-editor/models/assessment-framework';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'framework-dimension-input',
  templateUrl: './framework-dimension-input.component.html',
  styleUrls: ['./framework-dimension-input.component.scss']
})
export class FrameworkDimensionInputComponent implements OnInit {

  @Input() question : {meta:any};
  @Input() param : IAssessmentFrameworkDimensionDetail;

  targetFc:FormControl;
  dropdown:FormControl;
  numberInput:FormControl;
  checkbox:FormControl;

  constructor() { }

  ngOnInit() {
    switch(this.param.type){
      case 'select-sub': this.dropdown = this.initFormControl(); break;
      case 'binary':     this.checkbox = this.initFormControl(); break;
      case 'numeric':    this.numberInput = this.initFormControl(); break;
    }
  }

  getInputType(){
    if (this.param){
      return this.param.type;
    }
  }

  getTags(){
    if (this.param.config && this.param.config.tags){
      return this.param.config.tags
    }
    return [];
  }

  initFormControl(){
    let fc = this.targetFc = new FormControl();
    if (!this.question.meta){
      this.question.meta = {};
    }
    fc.setValue(this.question.meta[this.param.code]);
    fc.valueChanges.subscribe(()=>{
      this.question.meta[this.param.code] = fc.value;
    })
    return fc;
  }

}
