import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'list-editor',
  templateUrl: './list-editor.component.html',
  styleUrls: ['./list-editor.component.scss']
})
export class ListEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  isNoSelection(){
    return true;
  }

  discardSelection(){
    
  }

}
