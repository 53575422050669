import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'avatar-picker',
  templateUrl: './avatar-picker.component.html',
  styleUrls: ['./avatar-picker.component.scss']
})

export class AvatarPickerComponent implements OnInit {
  @Output() selectAvatar = new EventEmitter<{elements: any}>();

  images: string[] = [];
  elements: any[] = [];

  constructor(private afs: AngularFirestore) { }

  ngOnInit() {
    this.afs.doc('masterConfig/defaultAvatars').get().toPromise().then(snapshot => {
      let data = snapshot.data();
      this.images = data.images;
      this.elements = data.elements;
    });
  }

  onImageClick(index: number) {
    this.selectAvatar.emit({elements: this.elements[index]});
  }
}
