import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'button-student-dailyrewards',
  templateUrl: './button-student-dailyrewards.component.html',
  styleUrls: ['./button-student-dailyrewards.component.scss']
})
export class ButtonStudentDailyrewardsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
