import * as _ from 'lodash';
import { FormGroup, FormControl, AbstractControl, ValidatorFn, FormArray } from "@angular/forms";
import { Subscription } from 'rxjs';

type FormErrors = { [s: string]: string };
interface ValidationMessages { 
  [s: string]: {
    [s: string]: string
  }
}

export class SubFormRequest {
  public isSending:boolean;
  public isFailed:boolean;
  public isComplete:boolean;
  public errorCode:String ='';
  constructor(){
    this.resetValidationFlags();
  }
  resetValidationFlags() {
    this.errorCode = '';
    this.isSending = false;
    this.isComplete = false;
    this.isFailed = false;
  }
  markAsSent(){
    this.resetValidationFlags();
    this.isSending = true;
  }
  markAsComplete(){
    this.isComplete = true;
    this.isFailed = false;
    this.isSending = false;
  }
  markAsFailed(error:string){
    this.errorCode = error;
    this.isFailed = true;
    this.isComplete = false;
    this.isSending = false;
  }
}

export class FormValidation extends SubFormRequest{

  public formErrors: FormErrors;
  private availableFields:string[];

  constructor(
    public form:FormGroup, 
    public validationMessages:ValidationMessages
  ){
    super();
    // console.log('formvalidation', this.validationMessages)
    this.availableFields = Object.keys(this.validationMessages);
    this.formErrors = {};
    this.availableFields.forEach( fieldName => {
      this.formErrors[fieldName] = '';
    });
    this.form.valueChanges.subscribe((data) => this.onValueChanged(data));
    this.onValueChanged(); // reset validation messages
  }

  onValueChanged(data?:any){
    if (!this.form) { return; }
    this.resetValidationFlags();
    for (const field in this.formErrors) {
      if (Object.prototype.hasOwnProperty.call(this.formErrors, field) && _.includes(this.availableFields, field) ) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = this.form.get(field);
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          if (control.errors) {
            for (const key in control.errors) {
              if (Object.prototype.hasOwnProperty.call(control.errors, key) ) {
                this.formErrors[field] += `${(messages as {[key: string]: string})[key]} `;
                // console.log('onvaluechanged', field, key, this.formErrors[field])
              }
            }
          }
        }
      }
    }
  }

}

export class MatchValidator {
  static isEqual = (pairingFieldName:string): ValidatorFn => {
    let subscription:Subscription;
    return (comparisonControl: AbstractControl): {[key: string]: boolean} => {
      const formGroup = comparisonControl.parent;
      if (!formGroup){ return; }
      if (!subscription){
        subscription = formGroup.controls[pairingFieldName].valueChanges.subscribe(() => {
          comparisonControl.updateValueAndValidity();
        });
      }
      if (comparisonControl.value !== '') {
        try {
          const isMatching = comparisonControl.value === formGroup.controls[pairingFieldName].value;
          if (isMatching) {
            return undefined;
          }
        } catch (e) {
          console.error(e);
          return {
            isEqual: true // means there is an error
          };
        }
        return {
          isEqual: true // means there is an error
        };
      } else {
        return undefined;
      }
    };
  }
}