import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IOrganization } from '../../data/collections/organizations.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';

@Component({
  selector: 'form-organization-editor',
  templateUrl: './form-organization-editor.component.html',
  styleUrls: ['./form-organization-editor.component.scss']
})
export class FormOrganizationEditorComponent implements OnInit {

  activeOrganization = new FormControl(null);
  name = new FormControl('');
  tags = new FormControl('');
  isArchiveShown = new FormControl(false);
  organizationSearchString = new FormControl('');

  currentFormMode:FormMode = FormMode.NONE;
  editedOrganization:IOrganization;
  listModes:IListMode[] = [
    {slug:ListMode.ACTIVE, caption:'Active', isActive:true},
    {slug:ListMode.ARCHIVED, caption:'Archived'}
  ]
  currentListMode = this.listModes[0];

  organizations$: Observable<IOrganization[]>;
  private organizationCollection: AngularFirestoreCollection<IOrganization>;

  constructor(
    private afs: AngularFirestore,
  ) {
    this.organizationCollection = this.afs.collection<IOrganization>('organizations');
    this.organizations$ = CollectionsService.getValuesIdentified(this.organizationCollection);
  }

  ngOnInit() {
  }


  isNotEditing(){
    return this.currentFormMode === FormMode.NONE
  }

  archiveActiveOrganization(){
    let id = this.getActiveOrganizationId();
    this.organizationCollection.doc(id).update({ 
      isArchived: true
    });
  }

  selectListMode(listMode:IListMode){
    this.currentListMode.isActive = false;
    this.currentListMode = listMode;
    this.currentListMode.isActive = true;
  }

  isShowingArchivedOrganizations(){
    return this.currentListMode.slug === ListMode.ARCHIVED;
  }

  restoreActiveOrganization(){
    let id = this.getActiveOrganization();
    this.organizationCollection.doc(id).update({ 
      isArchived: false
    });
  }

  discardActiveOrganization(){
    if (window.confirm('Delete forever? (cannot undo)')){
      let id = this.getActiveOrganization();
      this.organizationCollection.doc(id).delete();
      this.activeOrganization.reset();  
    }
  }

  orgEditStart(){
    this.editedOrganization = this.getActiveOrganization();
    if (this.editedOrganization){
      this.name.setValue(this.editedOrganization.name);
      this.tags.setValue(this.editedOrganization.tags);
      this.currentFormMode = FormMode.EDIT;
    }
    this.activeOrganization.disable();
  }
  orgNewStart(){
    this.currentFormMode = FormMode.NEW;
    this.activeOrganization.reset();
    this.activeOrganization.disable();
  }
  orgEditCancel(){
    this.resetForm();
  }
  orgNewCancel(){
    this.resetForm();
  }
  resetForm(){
    this.name.reset();
    this.tags.reset();
    this.currentFormMode = FormMode.NONE;
    this.activeOrganization.enable();
  }

  updateOrganization() {
    let id = this.editedOrganization.__id;
    this.organizationCollection.doc(id).update({ 
      name: this.name.value,
      tags: this.tags.value,
      timeModified: serverTimestamp()
    });
    this.resetForm();
  }

  addOrganization() {
    this.organizationCollection.add({ 
      name: this.name.value,
      tags: this.tags.value,
      timeCreated: serverTimestamp(),
      timeModified: serverTimestamp(),
      controller: null //'organizations/cpZoY1nTvKjbKhVaJdlZ'
    });
    this.resetForm();
  }

  getActiveOrganization(){
    if (this.activeOrganization && this.activeOrganization.value && this.activeOrganization.value.length){
      return this.activeOrganization.value[0];
    }
  }

  private getActiveOrganizationId(){
    let activeorg = this.getActiveOrganization();
    if (activeorg){
      return activeorg.__id;
    }
  }

}
