import * as _ from 'lodash';

import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';

import { AssetLoader } from '../util/AssetLoader';
import { CosmeticInfoList } from '../util/PlayerModels/CosmeticInfoList';
import { ICosmeticInfo, IUserAvatarCosmeticsEarned, IUserCoins, IHeadsUpLobbyPlayerActions, AvatarPlayerActions, genBaseCosmetics, CosmeticCategory } from 'src/app/data/collections/heads-up-lobby';
import { IPurchaseResponse } from '../money-display/money-display.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { serverTimestamp } from 'src/app/data/timestamp';
import { ICosmeticSetting } from '../avatar-cosmetic-menu/avatar-cosmetic-menu.component';
import { AuthService } from 'src/app/core/auth.service';
import { SingleAvatarDisplay, ISingleAvatarDisplay } from '../util/SingleAvatarDisplay';
import { IUserAvatarCosmeticsEquipped, ILoadedCosmeticsEquipped, ILoadedCosmetic } from '../util/DragonAvatar/DragonInterfaces';
import { STANDALONE_FRAME_W } from '../constants/constants';


@Component({
  selector: 'single-avatar',
  templateUrl: './single-avatar.component.html',
  styleUrls: ['./single-avatar.component.scss'],
})

export class SingleAvatarComponent implements OnInit {
  @ViewChild('pixiViewport') pixiViewport: ElementRef;

  @Input() fullSized: boolean;
  @Input() canMove: boolean;
  @Input() canEdit: boolean;
  @Input() canEventuallyEdit: boolean;
  @Input() runAnimation: string;
  @Input() uid: string;
  @Input() assignmentId: string;

  currentCoin = 0;
  newChar: boolean;

  // cosmeticsToAdd: ILoadedCosmeticChunk[];
  cosmeticsEquipped: IUserAvatarCosmeticsEquipped;

  pendingCosmeticChanges: {[category: string]: string} = {};
  initialized = false;
  // numUpdates = 0;

  assetLoader: AssetLoader;
  cosmeticInfo: CosmeticInfoList = new CosmeticInfoList;
  cosmeticUpdate = 0;

  cosmeticToPurchase: ICosmeticInfo;
  cosmeticPurchaseResponse: IPurchaseResponse;

  // requestScreenshot: boolean;

  edittingCosmetics: boolean;

  display: SingleAvatarDisplay;

  constructor(
    private auth: AuthService,
    private afs: AngularFirestore,
    private afStorage: AngularFireStorage,
  ) {
    this.assetLoader = new AssetLoader(afs);
  }

  getDisplayName() {
    return this.auth.getDisplayName();
  }

  getUid() {
    return this.uid || this.auth.getUid();
  }

  getAssignmentId() {
    return this.assignmentId || 'NONE';
  }

  getAvatarFrameWidth(){
    return STANDALONE_FRAME_W;
  }
  getAvatarFrameHeight(){
    return STANDALONE_FRAME_W;
  }

  // == INITIALIZATION == \\
  ngOnInit() {
    this.auth.user.subscribe(userInfo => {
      // this runs again when the user avatar changes!  better to unsubscribe somehow?
      if (!this.initialized) {
        this.initialized = true;

        this.loadMasterConfig(() => {
          this.assetLoader.fetchCosmetics(this.getUid(), (cosmetics, data, newData) => {
            this.cosmeticsEquipped = data;
            if (newData) {
              this.newChar = true;
              // this.postCosmeticsEquipped(data);
            }else {
              this.newChar = false;
            }

            this.listenToCoins();
            this.loadCosmetics();

            let settings: ISingleAvatarDisplay = {
              pixiViewport: this.pixiViewport,
              fullSized: this.fullSized,
              sendMove: this.canMove ? this.sendMove : undefined,
              defaultAnimation: this.runAnimation,
              startingCosmetics: cosmetics
            };
            this.display = new SingleAvatarDisplay(settings);
          });
        });
      }
    });
  }

  generateNewAvatar(data: {elements: any}) {
    this.newChar = undefined;
    this.cosmeticsEquipped.elements = data.elements;
    console.log('els', data.elements);
    this.postCosmeticsEquipped(this.cosmeticsEquipped);
    this.assetLoader.loadCosmeticsEquipped(this.cosmeticsEquipped, loaded => {
      console.log('loaded', loaded);
      loaded.cosmetics.forEach(cosmetic => {
        this.display.addCosmetic(cosmetic);
      });
      this.newChar = false;
    });
    console.log('GEN!', data.elements);
  }

  loadMasterConfig(onComplete: () => void) {
    // CosmeticInfoList.SHOW_HIDDEN = false;
    // CosmeticInfoList.OWN_ALL = false;
    // onComplete();

    this.afs.doc('masterConfig/cosmeticFlags').get().toPromise().then(res => {
      let data = res.data();
      CosmeticInfoList.SHOW_HIDDEN = data.showHidden;
      CosmeticInfoList.OWN_ALL = data.ownAll;
      onComplete();
    });
  }

  loadCosmetics() {
    this.afs.collection<ICosmeticInfo>('avatarCosmetics/', ref => {
      if (!CosmeticInfoList.SHOW_HIDDEN) {
        return ref.where('hidden', '==', false);
      }
      return ref;
    }).get().toPromise().then(observer => {
      let cosmeticList = _.map(observer.docs, doc => doc.data() as ICosmeticInfo);
      this.afs.collection<IUserAvatarCosmeticsEarned>('userAvatarCosmeticsEarned/', ref => {
        return ref.where('uid', '==', this.getUid());
      }).get().toPromise().then(observer2 => {
        let unlocks = _.map(observer2.docs, doc => doc.data() as IUserAvatarCosmeticsEarned);
        this.cosmeticInfo.load(cosmeticList, unlocks);
        this.cosmeticUpdate++;
      });
    });
  }

  listenToCoins() {
    this.afs.collection<IUserCoins>('userCoins/', ref => {
      return ref
        .where('uid', '==', this.getUid());
    }).get().toPromise().then(observer => {
      if (observer.docs.length > 0) {
        this.currentCoin = observer.docs[0].data().coins;
      } else {
        this.postCoins(200);
      }
    });
    this.afs.collection<IUserCoins>('userCoins/', ref => {
      return ref.where('uid', '==', this.getUid());
    }).valueChanges().subscribe(data => {
      if (data.length > 0) {
        this.currentCoin = data[0].coins;
      }
    });
  }

  // == COMMANDS == \\

  sendMove = (loc: { x: number, y: number }) => {
    if (!this.canMove) {
      return;
    }
    const assignmentId = this.getAssignmentId();
    const uid = this.getUid();
    this.afs.collection<IHeadsUpLobbyPlayerActions>('headsUpLobbyPlayerActions/').add({
      assignmentId,
      uid,
      actionId: AvatarPlayerActions.MOVE,
      details: loc,
      timestamp: serverTimestamp(),
      isProcessed: false,
    });
  }

  // == COSMETIC CHANGE == \\

  processCosmeticChangeReq(update: ICosmeticSetting) {
    let slug = update.config.slug;
    let category = update.cosmeticType;

    if (this.cosmeticInfo.owns(slug, category)) {

      this.assetLoader.loadCosmetic(slug, category, this.display.addCosmetic);
      this.pendingCosmeticChanges[category] = slug;

    } else {
      let info = this.cosmeticInfo.get(slug, category);

      if (!info) {
        console.log('info not found: ', slug, category);
        return;
      }

      this.cosmeticToPurchase = info;
    }
  }

  changeCosmeticCategory = (data: { category: CosmeticCategory }) => {
    switch (data.category) {
      case CosmeticCategory.SHIRT: case CosmeticCategory.PANTS: case CosmeticCategory.SHOES: case CosmeticCategory.ACCESSORY_BODY:
      case CosmeticCategory.HELD: case CosmeticCategory.BACK: case CosmeticCategory.NECK: case CosmeticCategory.BELT:
        this.display.changeZoom(true);
        break;
      default:
        this.display.changeZoom(false);
    }
  }

  saveCosmetics() {
    this.display.changeZoom(false);
    this.display.getScreenshot(this.updateAvatarImage);
    this.edittingCosmetics = false;

    _.assign(this.cosmeticsEquipped.elements, this.pendingCosmeticChanges);
    this.pendingCosmeticChanges = {};

    this.postCosmeticsEquipped(this.cosmeticsEquipped);
  }

  cancelCosmetics() {
    this.display.changeZoom(false);
    this.edittingCosmetics = false;
    this.pendingCosmeticChanges = {};
    this.assetLoader.loadCosmeticsEquipped(this.cosmeticsEquipped, data => {
      data.cosmetics.forEach(cosmetic => this.display.addCosmetic(cosmetic));
    });
  }

  postCosmeticsEquipped(el: IUserAvatarCosmeticsEquipped) {
    let chunkName = this.getUid();
    el.lastUpdated = serverTimestamp();
    el.updatedIn = this.getAssignmentId();
    console.log('cosmetics posted');
    this.afs.doc<IUserAvatarCosmeticsEquipped>('userAvatarCosmeticsEquipped2/' + chunkName).set(el);
  }

  // == COSMETIC PURCHASE == \\

  cosmeticPurchaseResult(e: IPurchaseResponse) {
    console.log('cosmetic result is in', e);

    if (e.unlocked) {
      if (this.currentCoin >= e.info.cost) {
        this.postCoins(this.currentCoin - e.info.cost);
        this.postCosmeticEarned(e.info);
        e.info.owned = true;
        this.cosmeticPurchaseResponse = { unlocked: true, info: e.info };
        this.cosmeticUpdate++;
      } else {
        this.cosmeticPurchaseResponse = { unlocked: false, info: e.info };
      }
    } else {
      this.cosmeticPurchaseResponse = null;
      this.cosmeticToPurchase = null;
    }
  }

  postCosmeticEarned(el: ICosmeticInfo) {
    let data: IUserAvatarCosmeticsEarned = {
      uid: this.getUid(),
      slug: el.slug,
      category: el.cosmeticCategory,
      isAcquired: true,
      timestamp: serverTimestamp()
    };

    this.afs.collection<IUserAvatarCosmeticsEarned>('userAvatarCosmeticsEarned/').add(data);
  }

  postCoins(coins: number) {
    let data: IUserCoins = {
      uid: this.getUid(),
      coins,
      updated: serverTimestamp()
    };
    this.afs.doc<IUserCoins>('userCoins/' + this.getUid()).set(data);
  }

  // == IMAGE TAKER == \\

  updateAvatarImage = (e: { blob: Blob }) => {
    let uid = this.getUid();
    this.afStorage.upload('/userAvatars/' + uid + '/medium.png', e.blob).then(snapshot => {
      snapshot.ref.getDownloadURL().then(photoURL => {
        this.afs.doc('users/' + uid).update({ photoURL });
      });
    });
  }
}
