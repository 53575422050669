import { IPanelLink } from "./type";

export const districtCoordPanels:IPanelLink[] = [
    {
        caption: 'Leaderboards Administration',
        routerLink: '/admin/leaderboards',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
      },
    {
        caption: 'Leaderboards (Teacher View)',
        routerLink: '/teacher/leaderboards',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
    },
    
]