import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './core/auth.guard';
import { UserLoginComponent } from './ui/user-login/user-login.component';
import { HomePageComponent } from './ui/home-page/home-page.component';
import { NotesListComponent } from './notes/notes-list/notes-list.component';

import { StudentCalculatorComponent } from './ui-student/student-calculator/student-calculator.component';
import { StudentAssignmentComponent } from './ui-student/student-assignment/student-assignment.component';
import { StudentRegistrationComponent } from './ui-student/student-registration/student-registration.component';
import { StudentClassActivityComponent } from './ui-student/student-class-activity/student-class-activity.component';
import { StudentSkillsComponent } from './ui-student/student-skills/student-skills.component';
import { StudentStoreComponent } from './ui-student/student-store/student-store.component';
import { StudentExploreComponent } from './ui-student/student-explore/student-explore.component';

import { TeacherClassroomsComponent } from './ui-teacher/teacher-classrooms/teacher-classrooms.component';
import { TeacherClassroomStudentsComponent } from './ui-teacher/teacher-classroom-students/teacher-classroom-students.component';
import { TeacherClassroomAssignmentsComponent } from './ui-teacher/teacher-classroom-assignments/teacher-classroom-assignments.component';
import { TeacherClassroomAssignmentReportComponent } from './ui-teacher/teacher-classroom-assignment-report/teacher-classroom-assignment-report.component';
import { TeacherClassroomCurriculumComponent } from './ui-teacher/teacher-classroom-curriculum/teacher-classroom-curriculum.component';
import { TeacherMakeAssignmentComponent } from './ui-teacher/teacher-make-assignment/teacher-make-assignment.component';

import { ParentChildrenComponent } from './ui-parent/parent-children/parent-children.component';
import { ParentActivityComponent } from './ui-parent/parent-activity/parent-activity.component';
import { ParentCurriculumComponent } from './ui-parent/parent-curriculum/parent-curriculum.component';

import { AdminInstructionalCurriculumsComponent } from './ui-admin/admin-instructional-curriculums/admin-instructional-curriculums.component';
import { AdminInstructionalLearningOutcomesComponent } from './ui-admin/admin-instructional-learning-outcomes/admin-instructional-learning-outcomes.component';
import { AdminInstructionalTasksComponent } from './ui-admin/admin-instructional-tasks/admin-instructional-tasks.component';
import { AdminInstructionalBoostersComponent } from './ui-admin/admin-instructional-boosters/admin-instructional-boosters.component';
import { AdminGamesStoreComponent } from './ui-admin/admin-games-store/admin-games-store.component';
import { AdminGamesPrizesComponent } from './ui-admin/admin-games-prizes/admin-games-prizes.component';
import { AdminFinanceComponent } from './ui-admin/admin-finance/admin-finance.component';
import { AdminSupportComponent } from './ui-admin/admin-support/admin-support.component';
import { StudentAssignmentTaskComponent } from './ui-student/student-assignment-task/student-assignment-task.component';
import { TeacherClassroomStudentAssignmentReportComponent } from './ui-teacher/teacher-classroom-student-assignment-report/teacher-classroom-student-assignment-report.component';
import { StudentClassroomsComponent } from './ui-student/student-classrooms/student-classrooms.component';
import { TeacherClassroomStudentsPrintComponent } from './ui-teacher/teacher-classroom-students-print/teacher-classroom-students-print.component';
import { TeacherClassroomParentlettersComponent } from './ui-teacher/teacher-classroom-parentletters/teacher-classroom-parentletters.component';
import { AssessmentEditorComponent } from './ui-taskcreator/assessment-editor/assessment-editor.component';
import { CustomAssessmentsComponent } from './ui-taskcreator/custom-assessments/custom-assessments.component';
import { CustomAssessmentsCollectionsComponent } from './ui-taskcreator/custom-assessments-collections/custom-assessments-collections.component';
import { HeadsupLobbyComponent } from './ui-teacher/headsup-lobby/headsup-lobby.component';
import { StudentHeadsupLobbyComponent } from './ui-student/student-headsup-lobby/student-headsup-lobby.component';
import { PlayComponent } from './ui/play/play.component';
import { TeacherClassroomReportsComponent } from './ui-teacher/teacher-classroom-reports/teacher-classroom-reports.component';
import { TeacherBoosterpacksComponent } from './ui-teacher/teacher-boosterpacks/teacher-boosterpacks.component';
import { AdminUserAccountsComponent } from './ui-admin/admin-user-accounts/admin-user-accounts.component';
import { UserRegistrationComponent } from './ui/user-registration/user-registration.component';
import { UserRegistrationSsoComponent } from './ui/user-registration-sso/user-registration-sso.component';
import { MaintenanceComponent } from './ui/maintenance/maintenance.component';
import { LandingComponent } from './ui-whitelabel-cambridge/landing/landing.component';
import { DemoLandingComponent } from './ui-whitelabel-demo/demo-landing/demo-landing.component';
import { IndexComponent } from './ui/index/index.component';
import { TeacherOnboardingComponent } from './ui-teacher/teacher-onboarding/teacher-onboarding.component';
import { TeacherAvatarComponent } from './ui-teacher/teacher-avatar/teacher-avatar.component';
import { UserRegistrationAnonComponent } from './ui/user-registration-anon/user-registration-anon.component';
import { UserRegistrationInprogressComponent } from './ui/user-registration-inprogress/user-registration-inprogress.component';
import { StudentDashboardComponent } from './ui-student/student-dashboard/student-dashboard.component';
import { ShareClassroomComponent } from './ui-teacher/share-classroom/share-classroom.component';
import { ClassroomPlayComponent } from './ui-student/classroom-play/classroom-play.component';
import { ClassroomPlayLoginComponent } from './ui-student/classroom-play-login/classroom-play-login.component';
import { StudentBoosterPacksComponent } from './ui-student/student-booster-packs/student-booster-packs.component';
import { StudentBoosterPackComponent } from './ui-student/student-booster-pack/student-booster-pack.component';
import { StudentBoosterPackItemComponent } from './ui-student/student-booster-pack-item/student-booster-pack-item.component';
import { FielTrialAdminComponent } from './ui-fieldcoord/fiel-trial-admin/fiel-trial-admin.component';
import { FieldTrialTeachersComponent } from './ui-fieldcoord/field-trial-teachers/field-trial-teachers.component';
import { UserLogoutComponent } from './ui/user-logout/user-logout.component';
import { TeacherLeaderboardsComponent } from './ui-teacher/teacher-leaderboards/teacher-leaderboards.component';
import { AdminLeaderboardsComponent } from './ui-admin/admin-leaderboards/admin-leaderboards.component';
import { StudentLeaderboardsComponent } from './ui-student/student-leaderboards/student-leaderboards.component';
import { StudentLeaderboardGameComponent } from './ui-leaderboard-game/student-leaderboard-game/student-leaderboard-game.component';
import { MarkerCoordItemsComponent } from './ui-taskcreator/marker-coord-items/marker-coord-items.component';
import { StudentLeaderboardCertificateComponent } from './ui-student/student-leaderboard-certificate/student-leaderboard-certificate.component';


const routes: Routes = [
  
  { path: '', component: IndexComponent },
  { path: 'maintenance', component: MaintenanceComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'play', component: PlayComponent },
  { path: 'play-register', component: PlayComponent },
  { path: 'play/:classCode', component: PlayComponent },
  { path: 'login', component: UserLoginComponent },
  { path: 'logout', component: UserLogoutComponent },
  { path: 'signup', component: UserRegistrationComponent },
  { path: 'signup/sso', component: UserRegistrationSsoComponent },
  { path: 'signup/in-progress', component: UserRegistrationInprogressComponent },
  { path: 'signup/anon-connect', component: UserRegistrationAnonComponent,  canActivate: [AuthGuard], data: {authGuardRedirect: 'signup'} },
  
  { path: 'classroom/play/:classroomId/:classCodeId', component: ClassroomPlayComponent },
  { path: 'classroom/play/:classroomId/:classCodeId/login', component: ClassroomPlayLoginComponent },
  { path: 'classroom/play/:classroomId/:classCodeId/login/:username', component: ClassroomPlayLoginComponent },
  { path: 'student/new/:classroomId/:classCodeId', component: StudentRegistrationComponent },
  { path: 'student/dashboard', component: StudentDashboardComponent,  canActivate: [AuthGuard] },
  { path: 'student/classrooms', component: StudentClassroomsComponent,  canActivate: [AuthGuard] },
  { path: 'student/classroom/:classroomId', component: StudentClassActivityComponent,  canActivate: [AuthGuard] },
  { path: 'student/classroom/:classroomId/assignment/:assignmentId', component: StudentAssignmentComponent,  canActivate: [AuthGuard] },
  { path: 'student/classroom/:classroomId/assignment/:assignmentId/task/:taskId', component: StudentAssignmentTaskComponent },
  { path: 'student/classroom/:classroomId/headsup/:assignmentId', component: StudentHeadsupLobbyComponent },
  { path: 'student/explore', component: StudentExploreComponent,  canActivate: [AuthGuard] },
  { path: 'student/booster/:boosterPackId', component: StudentBoosterPackComponent,  canActivate: [AuthGuard] },
  { path: 'student/booster/:boosterPackId/item/:itemId', component: StudentBoosterPackItemComponent,  canActivate: [AuthGuard] },
  { path: 'student/leaderboards', component: StudentLeaderboardsComponent, canActivate: [AuthGuard] },
  { path: 'student/leaderboards/district/:district/classType/:classType', component: StudentLeaderboardsComponent },
  { path: 'student/leaderboards/district/:district/foreignClassType/:foreignClassType/foreignClassId/:foreignClassId/foreignId/:foreignUid/displayName/:displayName', component: StudentLeaderboardsComponent },
  { path: 'student/leaderboards/district/:district/foreignClassType/:foreignClassType/foreignClassId/:foreignClassId/foreignId/:foreignUid/displayName/:displayName/:langCode', component: StudentLeaderboardsComponent },
  { path: 'student/leaderboard/:leaderboardId', component: StudentBoosterPackComponent, data: {isLeaderboard: true}, canActivate: [AuthGuard] },
  { path: 'student/leaderboard/:leaderboardId/item/:itemId', component: StudentBoosterPackItemComponent, data: {isLeaderboard: true}, canActivate: [AuthGuard] },
  { path: 'leaderboard/:leaderboardId/certificate/:uid', component: StudentLeaderboardCertificateComponent, canActivate: [AuthGuard] },

  { path: 'teacher/onboarding', component: TeacherOnboardingComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/avatar', component: TeacherAvatarComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classrooms', component: TeacherClassroomsComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classrooms/:classroomId', component: TeacherClassroomsComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/share', component: ShareClassroomComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/students', component: TeacherClassroomStudentsComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/students/print', component: TeacherClassroomStudentsPrintComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/parentletters', component: TeacherClassroomParentlettersComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/assignments', component: TeacherClassroomAssignmentsComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/reports', component: TeacherClassroomReportsComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/student/:studentId/assignment/:assignmentId/report', component: TeacherClassroomStudentAssignmentReportComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/headsup/:assignmentId/lobby', component: HeadsupLobbyComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/assignment/:assignmentId/report', component: TeacherClassroomAssignmentReportComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/curriculum', component: TeacherClassroomCurriculumComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/boosterpacks', component: TeacherBoosterpacksComponent,  canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/boosterpacks/:boosterPackId', component: StudentBoosterPackComponent,  data: {isTeacher:true}, canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/boosterpacks/:boosterPackId/item/:itemId', component: StudentBoosterPackItemComponent,  data: {isTeacher:true},  canActivate: [AuthGuard] },

  { path: 'teacher/leaderboards', component: StudentLeaderboardsComponent, data: {isTeacher: true}, canActivate: [AuthGuard] },
  { path: 'teacher/leaderboards/district/:district/classType/:classType/classroom/:classroomId', data: {isTeacher: true}, component: TeacherLeaderboardsComponent },
  { path: 'teacher/leaderboards/district/:district/foreignClassType/:foreignClassType/foreignClassId/:foreignClassId/foreignId/:foreignUid/displayName/:displayName', data: {isTeacher: true}, component: TeacherLeaderboardsComponent },
  { path: 'teacher/leaderboards/district/:district/foreignClassType/:foreignClassType/foreignClassId/:foreignClassId/foreignId/:foreignUid/displayName/:displayName/:langCode', data: {isTeacher: true}, component: TeacherLeaderboardsComponent },
  { path: 'teacher/leaderboard/:leaderboardId', component: StudentBoosterPackComponent, data: {isTeacher: true, isLeaderboard: true}, canActivate: [AuthGuard] },
  { path: 'teacher/classroom/:classroomId/leaderboard/:leaderboardId', component: StudentBoosterPackComponent, data: {isTeacher: true, isLeaderboard: true}, canActivate: [AuthGuard] },
  { path: 'teacher/leaderboard/:leaderboardId/item/:itemId', component: StudentBoosterPackItemComponent, data: {isTeacher: true, isLeaderboard: true}, canActivate: [AuthGuard] },

  { path: 'admin/trial', component: FielTrialAdminComponent,  canActivate: [AuthGuard] },
  { path: 'admin/trial/:trialId', component: FielTrialAdminComponent,  canActivate: [AuthGuard] },
  { path: 'admin/trial/:trialId/accounts', component: FieldTrialTeachersComponent,  canActivate: [AuthGuard] },
  { path: 'admin/leaderboards', component: AdminLeaderboardsComponent, canActivate: [AuthGuard] },
  { path: 'admin/leaderboard/:leaderboardId', component: StudentBoosterPackComponent, data: {isAdmin: true, isLeaderboard: true}, canActivate: [AuthGuard] },
  { path: 'admin/leaderboard/:leaderboardId/item/:itemId', component: StudentBoosterPackItemComponent, data: {isAdmin: true, isLeaderboard: true}, canActivate: [AuthGuard] },

  { path: 'parent/children', component: ParentChildrenComponent,  canActivate: [AuthGuard] },
  { path: 'parent/activity', component: ParentActivityComponent,  canActivate: [AuthGuard] },
  { path: 'parent/curriculum', component: ParentCurriculumComponent,  canActivate: [AuthGuard] },
  
  { path: 'student/leaderboard-game/:gameLobbyId', component: StudentLeaderboardGameComponent,  canActivate: [AuthGuard] },

  { path: 'admin/support', component: AdminSupportComponent,  canActivate: [AuthGuard] },
  { path: 'admin/accounts', component: AdminUserAccountsComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/instructional/curriculums', component: AdminInstructionalCurriculumsComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/instructional/learning-outcomes', component: AdminInstructionalLearningOutcomesComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/instructional/tasks', component: AdminInstructionalTasksComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/instructional/boosters', component: AdminInstructionalBoostersComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/games/store', component: AdminGamesStoreComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/games/prizes', component: AdminGamesPrizesComponent,  canActivate: [AuthGuard] },
  // { path: 'admin/finance', component: AdminFinanceComponent,  canActivate: [AuthGuard] },
  { path: 'create/tasks', component: CustomAssessmentsCollectionsComponent,  canActivate: [AuthGuard] },
  { path: 'create/tasks/:projectId', component: CustomAssessmentsComponent,  canActivate: [AuthGuard] },
  { path: 'create/tasks/:projectId/:customTaskSetId', component: AssessmentEditorComponent,  canActivate: [AuthGuard] },
  
  { path: 'scorer/items/:randomId', component: MarkerCoordItemsComponent,  canActivate: [AuthGuard] },

  // whitelabel landings
  { path: 'demo-landing', component: DemoLandingComponent },
  { path: 'cambridge-landing', component: LandingComponent },

  // lazy loading tests
  { path: 'lazy', loadChildren: () => import('./lazy-loaded-module/lazy-loaded-module.module').then(mod => mod.LazyLoadedModuleModule)},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
