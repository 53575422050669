import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthService } from '../../core/auth.service';
import { AuthGuardResolveRouter } from '../../core/auth.guard.resolver-router';
import { SidepanelService } from '../../core/sidepanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit, OnDestroy {

  private signinSub:Subscription;

  constructor(
    public whiteLabel: WhitelabelService,
    public auth: AuthService,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    ) {

  }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
    this.signinSub = this.auth.user.subscribe(user => {
      if (user){
        this.afterSignIn();
      }
    })
  }

  ngOnDestroy() {
    if (this.signinSub){ this.signinSub.unsubscribe(); }
  }

  /// Social Login

  logout(){
    this.auth.signOut();
  }

  async signInWithGoogle() {
    await this.auth.googleLogin();
    return await this.afterSignIn();
  }

  // async signInWithFacebook() {
  //   await this.auth.facebookLogin();
  //   await this.afterSignIn();
  // }

  // async signInWithTwitter() {
  //   await this.auth.twitterLogin();
  //   return await this.afterSignIn();
  // }

  /// Anonymous Sign In

  async signInAnonymously() {
    await this.auth.anonymousLogin();
    return await this.afterSignIn();
  }

  /// Shared

  private afterSignIn() {
    let resolveUrl = AuthGuardResolveRouter.getRoute();
    // console.log('afterSignIn', this.auth.getUi(), this.auth.currentUser)
    return this.auth
      .fetchNewUserInfo()
      .then( () => {
        if (!resolveUrl || resolveUrl === 'login' || resolveUrl === 'home'){
          if (!this.auth.getUi()){
            if (this.auth.isAnonymous()){
              resolveUrl = 'signup/anon-connect';
            }
            else{
              resolveUrl = 'signup/in-progress';
            }
          }
          else{
            resolveUrl = this.auth.getUserUiHome() || '';
          }
        }
        return this.router.navigate(resolveUrl.split('/'));  
      })
  }

}
