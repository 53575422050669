import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { CollectionsService } from '../../data/collections.service';
import { ListMode, IListMode, FormMode } from '../../data/list-forms.types';

@Component({
  selector: 'form-student-assignment-simulator',
  templateUrl: './form-student-assignment-simulator.component.html',
  styleUrls: ['./form-student-assignment-simulator.component.scss']
})
export class FormStudentAssignmentSimulatorComponent implements OnInit {

  studentAssignmentSearchString = new FormControl('');
  activeAssignment = new FormControl(null);
  currentFormMode = 'NONE'

  constructor() { }

  ngOnInit() {
  }

  useActiveClassroom(){
    return true;
  }


}
