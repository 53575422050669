import { Component, OnInit, Input } from '@angular/core';
import { IContentElementMcq, McqDisplay, ElementType } from '../models';
import { FormControl } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';
import { indexOf } from '../services/util';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'element-config-order',
  templateUrl: './element-config-order.component.html',
  styleUrls: ['./element-config-order.component.scss']
})
export class ElementConfigOrderComponent implements OnInit {

  @Input() element:IContentElementMcq;

  isShowAdvancedOptions = new FormControl(false);
  delimiter = new FormControl();
  isScrambled = new FormControl(false);
  elementTypeForInsertion = new FormControl(ElementType.TEXT);

  elementTypes = [
    {id: ElementType.TEXT, caption: 'Text'},
    {id: ElementType.MATH, caption: 'Math'},
    {id: ElementType.IMAGE, caption: 'Image'},
  ]

  constructor() { }
  
  ngOnInit() {
    bindFormControls(this.element, [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions' },
      {f: this.delimiter, p:'delimiter' },
      {f: this.isScrambled, p:'isScrambled' },
      {f: this.elementTypeForInsertion, p:'elementTypeForInsertion' },
    ])
    if (!this.elementTypeForInsertion.value){
      this.elementTypeForInsertion.setValue(ElementType.TEXT);
    }
  }

  selectDisplayStyleVertical(){   this.element.displayStyle = McqDisplay.VERTICAL; }
  selectDisplayStyleHorizontal(){ this.element.displayStyle = McqDisplay.HORIZONTAL; }

  isDisplayStyleVertical(){   return this.element.displayStyle === McqDisplay.VERTICAL; }
  isDisplayStyleHorizontal(){ return this.element.displayStyle === McqDisplay.HORIZONTAL; }
  
  insertListEntry(){
    console.log('this.element.options', this.element.options);
    if (!this.element.options){
      this.element.options = [];
    }
    let content:any = '';
    if (this.elementTypeForInsertion.value === ElementType.IMAGE){
      content = {url: '', scale: 1}
    }
    this.element.options.push({
      elementType: this.elementTypeForInsertion.value,
      content,
      isCorrect: false,
      optionId: -1,
    })
  }

  removeElement(content:any[], element:any){
    if (window.confirm('Remove this option?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

}
