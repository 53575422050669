import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'user-logout',
  templateUrl: './user-logout.component.html',
  styleUrls: ['./user-logout.component.scss']
})
export class UserLogoutComponent implements OnInit {

  msg:string;

  constructor(
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.auth
      .signOut(false)
      .then(()=>{
        this.msg = 'LOGOUT SUCCESSFUL'
      })
  }

}
