import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { IAssessmentFrameworkDimensionDetail } from '../assessment-editor/models/assessment-framework';
import { FormControl } from '@angular/forms';



@Component({
  selector: 'framework-dimension-editor',
  templateUrl: './framework-dimension-editor.component.html',
  styleUrls: ['./framework-dimension-editor.component.scss']
})
export class FrameworkDimensionEditorComponent implements OnInit {

  @Input() param : IAssessmentFrameworkDimensionDetail;
  @Output() remove = new EventEmitter();
  @Output() onDimensionChange = new EventEmitter();

  code = new FormControl();
  name = new FormControl();
  type = new FormControl();

  constructor() { }

  ngOnInit() {
    this.initFormControl(this.code, 'code');
    this.initFormControl(this.name, 'name');
    this.initFormControl(this.type, 'type');
  }
  initFormControl(fc:FormControl, paramName:string){
    fc.setValue(this.param[paramName]);
    fc.valueChanges.subscribe(() => {
      this.param[paramName] = fc.value;
    })
  }
  insertTagOption(){
    if (!this.param.config){
      this.param.config = {};
    }
    if (!this.param.config.tags){
      this.param.config.tags = [];
    }
    this.param.config.tags.push({
      code: '',
      name: ''
    })
  }
  replaceTagProp(tag, prop:string){
    let str = prompt('New '+prop, tag[prop]);
    if (str){
      tag[prop] = str;
    }
  }


}
