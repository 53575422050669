import * as PIXI from 'pixi.js';
import * as _ from 'lodash';
import * as TWEEN from '@tweenjs/tween.js';

export class PixiInitializer {
  static tweenInitialized = false;

  app: PIXI.Application;
  stageBorders: PIXI.Rectangle;
  pixiDiv: HTMLDivElement;

  private resizing: { width: number, height: number };
  private resizeDelay = 0;
  onResize: () => void;
  
  constructor(div: HTMLDivElement, pixiOptions?: IPixiOptions) {
    pixiOptions = _.defaults(pixiOptions, {
      // backgroundColor: 0xff0000,
      // backgroundColor: 0xfe9f1d,
      transparent: true,
      antialias: true,
      resolution: 1,
      width: 800,
      height: 500,
      sharedLoader: true,
      autoPreventDefault: false,
    });

    this.stageBorders = new PIXI.Rectangle(0, 0, pixiOptions.width, pixiOptions.height);

    this.app = new PIXI.Application(pixiOptions);

    try {
      this.app.renderer.plugins.interaction.autoPreventDefault = false;
      this.app.renderer.view.style.touchAction = 'auto';
    }
    catch(e){
      console.warn('could not defend against pixi touch hijacking', e)
    }

    if (!PixiInitializer.tweenInitialized) {
      PixiInitializer.tweenInitialized = true;
      PIXI.Ticker.shared.add(() => {
        // console.log(a,b,c);
        TWEEN.update();
      });
      // PIXI.Ticker.shared.add(() => console.log('hello world'));
    }

    div.append(this.app.view);
    this.pixiDiv = div;
  }

  resize(width: number, height: number) {
    this.resizeDelay = 10;
    if (this.resizing) {
      this.resizing.width = width;
      this.resizing.height = height;
    } else {
      this.resizing = { width, height };
      this.app.ticker.add(this.resizeTick);
    }
  }

  destroy() {
    console.log('PIXI DESTROYED');
    this.app.destroy(true);
    this.app = undefined;
    this.stageBorders = undefined;
    this.pixiDiv = undefined;
  }

  showFPS() {
    let fps = new PIXI.Text('0');
    let prevTime = new Date().getTime();
    this.app.stage.addChild(fps);
    this.app.ticker.add(() => {
      let time = new Date().getTime();
      fps.text = String(time - prevTime);
      prevTime = time;
    });
  }

  // hideFPS() {

  // }

  private resizeTick = () => {
    this.resizeDelay--;
    if (this.resizeDelay < 0) {
      this.app.renderer.resize(this.resizing.width, this.resizing.height);
      this.stageBorders.width = this.resizing.width;
      this.stageBorders.height = this.resizing.height;
      this.resizing = null;
      if (this.onResize) {
        this.onResize();
      }
      this.app.ticker.remove(this.resizeTick);
    }
  }

  takeScreenshot(el?: PIXI.Container, visibleArea?: PIXI.Rectangle): PromiseLike<Blob> {
    let extracted: HTMLCanvasElement;

    if (el) {
      if (visibleArea) {
        // let graphic = new PIXI.Graphics;
        // graphic.lineStyle(2, 0xff00ff);
        // graphic.drawRect(visibleArea.x, visibleArea.y, visibleArea.width, visibleArea.height);
        // el.addChild(graphic);
        // console.log(visibleArea);

        let render = PIXI.RenderTexture.create({ width: visibleArea.width, height: visibleArea.height });
        this.app.renderer.render(el, render, true, new PIXI.Matrix(1, 0, 0, 1, -visibleArea.x, -visibleArea.y), true);
        extracted = this.app.renderer.extract.canvas(render);
      } else {
        extracted = this.app.renderer.extract.canvas(el);
      }
    } else {
      extracted = this.app.renderer.view;
    }

    return new Promise((resolve, reject) => {
      extracted.toBlob(blob => {
        resolve(blob);
      }, 'image/png');
    });
  }

  takeAndDownloadScreenshot(el?: PIXI.Container, visibleArea?: PIXI.Rectangle) {
    this.takeScreenshot(el, visibleArea).then(blob => {
      let temp = document.createElement('a');
      document.body.append(temp);
      let date = (new Date());
      let stamp: string = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDay() + '-' + date.getHours() + date.getMinutes() + date.getSeconds();
      temp.download = 'CC_Screenshot_' + stamp + '.png';
      temp.href = URL.createObjectURL(blob);
      temp.click();
      temp.remove();
    });
  }
}

export interface IPixiOptions {
  autoStart?: boolean;
  width?: number;
  height?: number;
  view?: HTMLCanvasElement;
  transparent?: boolean;
  autoDensity?: boolean;
  antialias?: boolean;
  preserveDrawingBuffer?: boolean;
  resolution?: number;
  forceCanvas?: boolean;
  backgroundColor?: number;
  clearBeforeRender?: boolean;
  forceFXAA?: boolean;
  powerPreference?: string;
  sharedTicker?: boolean;
  sharedLoader?: boolean;
  resizeTo?: Window | HTMLElement;
}
