import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IBoosterPackItem } from '../../data/cambridge/types';

@Component({
  selector: 'item-positioner',
  templateUrl: './item-positioner.component.html',
  styleUrls: ['./item-positioner.component.scss']
})
export class ItemPositionerComponent implements OnInit {

  @Input() item:IBoosterPackItem;
  @Input() isSelected:boolean;
  @Input() clipboardItem:IBoosterPackItem;
  @Input() getItemById:Function = (str) => str;
  @Output() select = new EventEmitter();
  @Output() sendToClipboard = new EventEmitter();
  @Output() clearClipboard = new EventEmitter();
  @Output() dirty = new EventEmitter();

  name = new FormControl();
  x = new FormControl();
  y = new FormControl();
  itemType = new FormControl('blue');
  unlockedByInsertion = new FormGroup({
    newId: new FormControl()
  });
  isAdjustingPosition:boolean;

  constructor() { }

  ngOnInit() {
    [
      'name',
      'x',
      'y',
      'itemType',
    ].forEach(prop => {
      this[prop].setValue(this.item[prop]);
      this[prop].valueChanges.subscribe(()=>{
        this.item[prop] = this[prop].value;
        this.markAsDirty();
      });
    })
  }

  markAsDirty(){
    this.item.isDirty = true;
    this.dirty.emit();
  }

  addDependency(){
    if (!this.item.unlockedBy){
      this.item.unlockedBy = [];
    }
    this.item.unlockedBy.push(this.clipboardItem.__id);
    this.clearClipboard.emit();
    this.markAsDirty();
  }

  removeDependency(i:number){
    this.item.unlockedBy.splice(i, 1);
    this.markAsDirty();
  }

}
