    

export const gradeUserGenQResponse = (entryId:number, entryState:any, responseTextAgg:string[], responses:any[]) => {
    let responseText = '';
    let responseDetailed = ''
    if (entryState){
        if (entryState.type === 'mcq'){
            responseDetailed = entryState.selections;
            responseText = entryState.selections.map( selection => selection.content ).join('; ');
        }
        else if (entryState.type === 'input-longtext'){
            responseText = entryState.str
        }
        else if (entryState.type === 'input-number'){
            responseText = entryState.value
        }
        else {
            console.log('entryState.type', entryState.type)
        }
    }
    let responsePayload:any = {
        id: entryId,
        responseText,
        responseDetailed,
        weight: 1,
    }
    if (entryState){
        if (entryState.isCustomGrading){ responsePayload.isCustomGrading = true }
        if (entryState.isStarted){ responsePayload.isStarted = true }
        if (entryState.isFilled){ responsePayload.isFilled = true }
        if (entryState.isCorrect){ responsePayload.isCorrect = true } 
    }

    responseTextAgg.push(responseText);
    responses.push(responsePayload)

    return responsePayload;
}
