import { Component, OnInit, Input } from '@angular/core';
import { IContentElementVideo } from '../models';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'element-config-video',
  templateUrl: './element-config-video.component.html',
  styleUrls: ['./element-config-video.component.scss']
})
export class ElementConfigVideoComponent implements OnInit {

  @Input() element:IContentElementVideo;

  youtubeId = new FormControl();

  constructor(
  ) { }

  ngOnInit() {
    this.youtubeId.valueChanges.subscribe( e => {
      let possibleUrl:string = this.youtubeId.value;
      if (possibleUrl){
        let splitUrlPop = possibleUrl.split('=').pop();
        if (splitUrlPop !== possibleUrl){
          this.youtubeId.setValue(splitUrlPop);
        }
        this.element.youtubeId = splitUrlPop;
      }
      else{
        this.element.youtubeId = null;
      }
    })
  }
  

}
