import * as _ from 'lodash';
import * as _2 from '../_2';

import { ITileLayer, ITileDefinition } from './TempMapData';

export interface IPathingTile {
  index: number;
  loc: { x: number, y: number };
  walkable: boolean;
  outlets: IPathingTile[];
  zObj: number;
}

interface IPathingMap {
  layers: ITileLayer[];
  definitions: ITileDefinition[];
}

interface IPathingNode {
  current: IPathingTile;
  previous: IPathingNode;
  hValue: number;
  pValue: number;
}

export class PathingMap {
  tiles: IPathingTile[] = [];

  constructor(public config: IPathingMap) {
    let width = config.layers[0].width;
    let height = config.layers[0].height;

    for (let i = 0; i < width * height; i++) {
      let tile: IPathingTile = {
        index: i,
        loc: { x: i % width, y: Math.floor(i / width) },
        walkable: true,
        outlets: [],
        zObj: 0,
      };
      this.tiles[i] = tile;

      if (i % width !== 0) {
        let other = this.tiles[i - 1];
        tile.outlets.push(other);
        other.outlets.push(tile);
      }

      if (i >= width) {
        let other = this.tiles[i - width];
        tile.outlets.push(other);
        other.outlets.push(tile);
      }
    }


    // let walls = _.filter(_.map(config.definitions, def => def.wall ? def.index : undefined), index => index !== undefined);

    config.layers.forEach(layer => {
      if (layer.type === 'objectgroup') {
        return;
      }
      width = layer.width;
      height = layer.height;
      for (let i = 0; i < width * height; i++) {
        for (let j = 0; j < config.definitions.length; j++) {
          if (_2.getTileid(layer.data[i]) === config.definitions[j].index) {
            if (config.definitions[j].wall) {
              let tile = this.tiles[(i % width) + Math.floor(i / width) * this.config.layers[0].width];
              this.closeTile(tile);
            }

            if (config.definitions[j].zObj) {
              let tile = this.tiles[(i % width) + Math.floor(i / width) * this.config.layers[0].width];
              tile.zObj = config.definitions[j].zObj;
            }
          }
        }
      }
    });
  }

  closeTile(tile: IPathingTile) {
    if (tile.walkable) {
      tile.walkable = false;
      _.forEach(tile.outlets, outlet => _.pull(outlet.outlets, tile));
      tile.outlets = [];
    }
  }

  getTileAt(x: number, y: number): IPathingTile {
    return this.tiles[Math.floor(x) + Math.floor(y) * this.config.layers[0].width];
  }

  getNearestWalkable(origin: {x: number, y: number}, target: {x: number, y: number}): IPathingTile {
    // ** Should get the nearest walkable tile so when you click on a wall you walk next to the wall.

    let tile = this.getTileAt(target.x, target.y);
    if (tile.walkable) {
      return tile;
    }

    if (origin.x < target.x) {
      tile = this.getTileAt(target.x - 1, target.y);
      if (tile.walkable) {
        return tile;
      }
    } else if (origin.x > target.x) {
      tile = this.getTileAt(target.x + 1, target.y);
      if (tile.walkable) {
        return tile;
      }
    }

    if (origin.y < target.y) {
      tile = this.getTileAt(target.x, target.y - 1);
      if (tile.walkable) {
        return tile;
      }
    } else if (origin.y > target.y) {
      tile = this.getTileAt(target.x, target.y + 1);
      if (tile.walkable) {
        return tile;
      }
    }
    return this.getTileAt(target.x, target.y);
  }

  makePath(start: IPathingTile, end: IPathingTile): IPathingTile[] {
    if (!start || !end) {
      return null;
    }
    let path: IPathingTile[] = [];
    let open: IPathingNode[] = [];
    let closed: IPathingNode[] = [];

    open.push({ current: start, previous: null, pValue: 0, hValue: this.heuristic(start, end) });

    while (open.length) {
      let node = open.shift();
      let current = node.current;

      if (current === end) {
        path = [node.current];
        while (node.previous) {
          node = node.previous;
          path.unshift(node.current);
        }

        return path;
      } else {
        main: for (let i = 0; i < current.outlets.length; i++) {
          for (let j = 0; j < open.length; j++) {
            if (current.outlets[i] === open[j].current) {
              if (node.pValue + 1 < open[j].pValue) {
                open[j].pValue = node.pValue + 1;
                open[j].previous = node;
              } else if (node.pValue + 1 === open[j].pValue) {
                if (Math.random() > 0.5) {
                  open[j].previous = node;
                }
              }
              continue main;
            }
          }
          for (let j = 0; j < closed.length; j++) {
            if (current.outlets[i] === closed[j].current) {

              if (node.pValue + 1 < closed[j].pValue) {
                closed[j].pValue = node.pValue + 1;
                closed[j].previous = node;
              } else if (node.pValue + 1 === closed[j].pValue) {
                if (Math.random() > 0.5) {
                  closed[j].previous = node;
                }
              }
              continue main;
            }
          }
          let node2: IPathingNode = { current: current.outlets[i], previous: node, pValue: node.pValue + 1, hValue: this.heuristic(current.outlets[i], end) };
          // for (let j=0;j<open.length;j++){
          //   if (node2.hValue+node2.pValue < open[j].hValue+open[j].pValue){
          //     open.splice(j,0,node2);
          //     continue main;
          //   }
          // }
          open.push(node2);
          // open.splice(_.sortedIndexBy(open,node2,node => (node.pValue + node.hValue)),0,node2);
        }

        open.sort((a, b): number => {
          if (a.pValue + a.hValue < b.pValue + b.hValue) {
            return -1;
          } else if (a.pValue + a.hValue > b.pValue + b.hValue) {
            return 1;
          } else {
            return 0;
          }
        });
        closed.push(node);
      }
    }
    path = null;
    return null;
  }

  heuristic (node: any, end: any): number {
    return Math.sqrt((node.x - end.x) * (node.x - end.x) + (node.y - end.y) * (node.y - end.y));
  }

  collapsePath = (path: IPathingTile[]): IPathingTile[] => {
    if (!path) {
      return path;
    }

    return _.tail(this.collapseStraights(path));

    // let newPath: IPathingTile[] = [];
    // let i = 0;
    // while (i < path.length) {
    //   let current = path[i];
    //   newPath.push(current);
    //   i++;
    //   for (let j = i + 1; j < path.length; j++) {
    //     let other = path[j];

    //     if (this.clearLine(current, other)) {
    //       i = j;
    //     }
    //   }
    // }
    // return _.tail(newPath);
  }

  collapseStraights = (path: IPathingTile[]): IPathingTile[] => {
    let newPath: IPathingTile[] = [];
    let i = 0;
    while (i < path.length - 1) {
      let start = path[i];
      newPath.push(start);
      i++;
      let end = path[i];
      if (end.loc.x === start.loc.x) {
        while (end && end.loc.x === start.loc.x) {
          i++;
          end = path[i];
        }
        i--;
      } else if (end.loc.y === start.loc.y) {
        while (end && end.loc.y === start.loc.y) {
          i++;
          end = path[i];
        }
        i--;
      }

    }

    newPath.push(path[path.length - 1]);
    return newPath;
  }

  clearLine(origin: IPathingTile, target: IPathingTile): boolean {
    return false;
    // replace with raycasting
  //   let slope = (target.loc.y - origin.loc.y) / (target.loc.x - origin.loc.x);
  //   let startX = origin.loc.x;
  //   let startY = origin.loc.y;

  //   if (target.loc.x < origin.loc.x) {
  //     [origin, target] = [target, origin];
  //   }
  //   let dX = target.loc.x - origin.loc.x;
  //   let mapX: {x: number, y: number}[] = [];
  //   for (let x = 0; x < dX; x++) {
  //     let y = Math.floor(x * slope);
  //     mapX.push({x: x + startX, y: y + startY});
  //   }

  //   if (target.loc.y < origin.loc.y) {
  //     [origin, target] = [target, origin];
  //   }
  //   let dY = target.loc.y - origin.loc.y;
  //   let mapY: {x: number, y: number}[] = [];
  //   for (let y = 0; y < dY; y++) {
  //     let x = Math.floor(y / slope);
  //     mapY.push({x: x + startX, y: y + startY});
  //   }

  //   for (let i = 0; i < mapX.length; i++) {
  //     if (!this.getTileAt(mapX[i].x, mapX[i].y).walkable) {
  //       return false;
  //     }
  //   }

  //   for (let i = 0; i < mapY.length; i++) {
  //     if (!this.getTileAt(mapY[i].x, mapY[i].y).walkable) {
  //       return false;
  //     }
  //   }

  //   return true;
  }
}
