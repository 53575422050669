import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges } from '@angular/core';
// import katex from 'katex';
@Component({
  selector: 'render-math',
  templateUrl: './render-math.component.html',
  styleUrls: ['./render-math.component.scss']
})
export class RenderMathComponent implements OnInit {

  @Input() obj:any;
  @Input() prop:string;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @ViewChild('mathField') mathFieldRef: ElementRef;

  MathLive = null;
  mathField:HTMLSpanElement;
  currentLatex;
  intervalTracker;

  constructor() { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.MathLive = (window as any).MathLive;
    this.mathField = this.mathFieldRef.nativeElement;
    this.updateLatex();
    this.intervalTracker = setInterval(this.updateLatex.bind(this), 500);
  }

  updateLatex(isForcedChange:boolean=false){
    // console.log('check');
    if (this.mathField && this.obj && this.obj[this.prop]){
      if ( isForcedChange || (this.obj[this.prop] !== this.currentLatex) ){
        this.currentLatex = this.obj[this.prop];
        this.mathField.innerHTML = '$$'+this.currentLatex+'$$';
        this.MathLive.renderMathInElement(this.mathField);
        // katex.render(this.currentLatex, this.mathField, {
        //   throwOnError: false
        // });
      }
  
    }
  }

}

