import * as _ from 'lodash';
import * as _2 from '../util/_2';
import * as PIXI from 'pixi.js';
import { Component, OnInit, Input, ElementRef, ViewChild, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';

import { isLoadedBody, ILoadedBody, ILoadedCosmeticsEquipped } from '../util/DragonAvatar/DragonInterfaces';
import { AvatarExploreCanvas } from '../util/AvatarExploreCanvas';
import { DragonSprite } from '../util/DragonAvatar/DragonSprite';
import { ILoadedMapData, ObjectInteractionType } from '../util/TileMap/TempMapData';
import { IExploreLocation, IExplorePlayerCollection } from 'src/app/data/collections/heads-up-lobby';
import { IPlacementOptions } from '../util/AvatarScene';

@Component({
  selector: 'avatar-explore',
  templateUrl: './avatar-explore.component.html',
  styleUrls: ['./avatar-explore.component.scss'],
})
export class AvatarExploreComponent implements OnInit, OnChanges {
  @ViewChild('pixiViewport') pixiViewport: ElementRef;
  parentElement: any;

  @Input() update: number;
  // @Input() runAnimation: string;
  @Input() cosmeticsAdded: ILoadedCosmeticsEquipped;
  @Input() playersAdded: IExplorePlayerCollection[];
  loadedPlayers: {uid: string, avatar: DragonSprite}[] = [];
  @Input() levelToLoad: ILoadedMapData;
  @Input() exploreLocation: IExploreLocation;
  @Input() playersUpdated: string[];

  currentLevel: ILoadedMapData;
  initialLocLoaded: boolean;

  // @Output('fetchCosmetics') fetchCosmetics = new EventEmitter<null>();
  @Output('updateLoc') updateLoc = new EventEmitter<Partial<IExploreLocation>>();

  playerAvatarLoaded: boolean;
  displayedPlayers = [];

  initialized = false;
  levelLoaded = false;

  sceneRender: AvatarExploreCanvas;
  myAvatar: DragonSprite;
  avatarPath: {x: number, y: number}[];
  otherPaths: {avatar: DragonSprite, path: {x: number, y: number}[]}[] = [];

  constructor(
  ) {
    let cAnim = -1;
    window.addEventListener('keydown', e => {
      if (e.key === 'e') {
        console.log(this.myAvatar);
      }

      if (e.key === 't') {
        cAnim++;
        if (cAnim >= this.myAvatar.display.animation.animationNames.length) {
          cAnim = 0;
        }
        this.myAvatar.display.animation.fadeIn(this.myAvatar.display.animation.animationNames[cAnim]);
      }

      if (e.key === 'p') {
        let bounds = this.myAvatar.display.getLocalBounds();
        bounds.height = bounds.width;

        this.sceneRender.pixi.takeAndDownloadScreenshot(this.myAvatar.display, bounds);
      }
    });
  }

  ngOnInit() {
    const div = this.pixiViewport.nativeElement;
    this.parentElement = div.closest('div avatar-explore');
    this.sceneRender = new AvatarExploreCanvas(div, { width: this.parentElement.offsetWidth, height: this.parentElement.offsetHeight, transparent: false, backgroundColor: 0xffcc66 }, { width: 800, height: 500 });

    this.setupControls();

    window.addEventListener('resize', this.onResize);

    this.initialized = true;
    this.ngOnChanges();
  }

  ngOnChanges(changes?: SimpleChanges) {
    // console.log('CHANGE!', changes);
    if (!this.initialized) {
      return;
    }

    if (this.levelToLoad && this.currentLevel !== this.levelToLoad) {
      // clear current level and load next one.  Currently not clearing current level.

      this.levelLoaded = false;

      this.currentLevel = this.levelToLoad;

      this.sceneRender.loadMapData(this.levelToLoad, () => {
        this.levelLoaded = true;
        this.ngOnChanges();
      });
    } else if (!this.levelToLoad && this.currentLevel) {
      // LOGOUT
      this.currentLevel = null;
      this.dispose();
    }

    if (!this.levelLoaded) {
      return;
    }

    if (this.playerAvatarLoaded) {
      // if (this.cosmeticsAdded) {
      //   while (this.cosmeticsAdded.length > 0) {
      //     let cosmetic = this.cosmeticsAdded.shift();
      //     if (!isLoadedBody(cosmetic.data)) {
      //       this.myAvatar.addCosmetic(cosmetic.data);
      //     }
      //   }
      // }

      if (this.playersAdded.length !== this.loadedPlayers.length) {
        this.playersAdded.forEach(el => {
          if (!_.find(this.loadedPlayers, {uid: el.uid})) {
            this.loadOtherAvatar(el);
          }
        });
        this.loadedPlayers.forEach(el => {
          if (!_.find(this.playersAdded, {uid: el.uid})) {
            this.removeOtherAvatar(el);
          }
        });
      }

      while (this.playersUpdated.length > 0) {
        // either cosmetics or position
        let uid = this.playersUpdated.shift();
        let data = _.find(this.playersAdded, {uid});
        let avatar = _.find(this.loadedPlayers, {uid});
        // if (data && avatar) {
        //   data.cosmetics.forEach(chunk => {
        //     avatar.avatar.addCosmetic(chunk.data as any);
        //   });
        // }

        let cPos = this.sceneRender.localToTile(avatar.avatar.display);
        if (cPos.x !== data.data.x || cPos.y !== data.data.y) {
          let path = this.sceneRender.getPathTo(cPos, data.data);
          let currentPath: any = _.find(this.otherPaths, {avatar: avatar.avatar});
          if (currentPath) {
            currentPath.path = path;
          } else {
            this.otherPaths.push({avatar: avatar.avatar, path});
          }
        }
      }
    } else {
      if (!this.cosmeticsAdded) {
        // this.fetchCosmetics.emit();
      } else {
        this.loadPlayerAvatar(this.cosmeticsAdded);
      }
    }
  }
  
  loadOtherAvatar(data: IExplorePlayerCollection) {
    let placement: IPlacementOptions = {x: data.data.x, y: data.data.y, scale: 1};

    let avatar = this.sceneRender.addCharacter(data.cosmetics.body, data.cosmetics.cosmetics, placement);
    this.sceneRender.setupInteractions(avatar.display, [{type: ObjectInteractionType.MINI_DIALOGUE, data: 'You dare challenge me?'}, {type: ObjectInteractionType.DRAGON_ANIMATION, data: 'OutOfTime'}]);
    
    this.sceneRender.showMiniDialogue(avatar.display, 'user has logged in');
    this.loadedPlayers.push({uid: data.uid, avatar});
  }

  removeOtherAvatar(data: {uid: string, avatar: DragonSprite}) {
    this.sceneRender.showMiniDialogue(data.avatar.display, 'user has logged out');
    _.pull(this.loadedPlayers, data);
    data.avatar.display.destroy();
  }

  loadPlayerAvatar(data: ILoadedCosmeticsEquipped) {
    if (!this.exploreLocation) {
      return;
    }

    this.playerAvatarLoaded = false;

    let placement: IPlacementOptions = {x: this.exploreLocation.x, y: this.exploreLocation.y, scale: 1};
    if (placement.x < 0 || placement.y < 0) {
      placement.x = this.currentLevel.startLoc.x;
      placement.y = this.currentLevel.startLoc.y;
    }
    if (this.sceneRender) {
      this.myAvatar = this.sceneRender.addCharacter(data.body, data.cosmetics, placement);
      this.sceneRender.display.setLocalCameraTarget(this.myAvatar.display);
      this.playerAvatarLoaded = true;
      // this.fetchCosmetics.emit();
    }
  }

  setupControls() {
    this.sceneRender.pixi.app.ticker.add(this.onTick);
    this.sceneRender.display.addListener('pointerdown', this.onClick);
    window.addEventListener('wheel', this.onWheel);
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
  }

  dispose() {
    this.sceneRender.pixi.app.ticker.remove(this.onTick);
    this.sceneRender.display.removeListener('pointerdown', this.onClick);
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('keydown', this.onKeyDown);
    window.removeEventListener('keyup', this.onKeyUp);
    if (this.sceneRender) {
      this.sceneRender.display.destroy();
      this.sceneRender = null;
    }
  }

  onClick = (e: PIXI.interaction.InteractionEvent) => {
    if (e.target !== this.sceneRender.display) {
      return;
    }
    if (!this.myAvatar) {
      return;
    }
    let loc = e.data.getLocalPosition(this.sceneRender.display);

    this.avatarPath = this.sceneRender.getPathTo(this.sceneRender.localToTile(this.myAvatar.display), this.sceneRender.localToTile(loc));
    if (this.avatarPath) {
      let final = _.last(this.avatarPath);
      final = this.sceneRender.localToTile(final, true);
      this.updateLoc.emit({x: final.x, y: final.y});
    }
  }

  onTick = () => {
    if (this.avatarPath && this.avatarPath.length > 0) {
      // ** Walk along a node path.  Clean up movement code to smooth out using ACCELERATION.
      let continuePath = this.walkPath(this.myAvatar, this.avatarPath);
      if (!continuePath) {
        this.avatarPath = null;
      }
    }

    if (this.otherPaths.length > 0) {
      for (let i = this.otherPaths.length - 1; i >= 0; i--) {
        let continuePath = this.walkPath(this.otherPaths[i].avatar, this.otherPaths[i].path);
        if (!continuePath) {
          this.otherPaths.splice(i, 1);
        }
      }
    }

    this.sceneRender.update();
  }

  onWheel = (e: MouseWheelEvent) => {
    this.sceneRender.display.zoomIn(- e.deltaY / 100);
  }

  onKeyDown = (e: KeyboardEvent) => {

    switch (e.key) {
      // case " ": this.running=!this.running; break;
      case 'ArrowLeft': case 'a':
        this.sceneRender.display.keyStates.left = true;
        break;
      case 'ArrowRight': case 'd':
        this.sceneRender.display.keyStates.right = true;
        break;
      case 'ArrowUp': case 'w':
        this.sceneRender.display.keyStates.up = true;
        break;
      case 'ArrowDown': case 's':
        this.sceneRender.display.keyStates.down = true;
        break;
      case '=':
        this.sceneRender.display.zoomIn(1);
        break;
      case '-':
        this.sceneRender.display.zoomIn(-1);
        break;
      // case "Escape":
      // 	this.navBack();
      // 	break;
    }
  }

  onKeyUp = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowLeft': case 'a':
        this.sceneRender.display.keyStates.left = false;
        break;
      case 'ArrowRight': case 'd':
        this.sceneRender.display.keyStates.right = false;
        break;
      case 'ArrowUp': case 'w':
        this.sceneRender.display.keyStates.up = false;
        break;
      case 'ArrowDown': case 's':
        this.sceneRender.display.keyStates.down = false;
        break;
    }
  }

  onResize = (e) => {
    this.sceneRender.pixi.resize(this.parentElement.offsetWidth, this.parentElement.offsetHeight);
  }
  
  /** @return false if done */
  walkPath(avatar: DragonSprite, path: {x: number, y: number}[]): boolean {
    if (!path || path.length === 0) {
      return false;
    }

    if (path[0].x < avatar.display.x) {
      avatar.turnLeft();
    } else if (path[0].x > avatar.display.x) {
      avatar.turnRight();
    }
    let vector = _2.vectorBetween(avatar.display, path[0]);
    if (vector.mag < 1 || (vector.mag < 5 && path.length > 1)) {
      avatar.display.position.set(path[0].x, path[0].y);
      path.shift();
      if (path.length === 0) {
        return false;
      }
      // animate idle
    } else {
      vector.mag = Math.min(5, vector.mag);
      let velocity = _2.vectorToPoint(vector);

      avatar.display.x += velocity.x;
      avatar.display.y += velocity.y;

      this.sceneRender.tilemap.updateZIndex(avatar.display);
      // animate walk
    }

    return true;
  }
}
