import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-games-prizes',
  templateUrl: './admin-games-prizes.component.html',
  styleUrls: ['./admin-games-prizes.component.scss']
})
export class AdminGamesPrizesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
