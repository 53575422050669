import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { AuthService } from '../../core/auth.service';
import { AuthGuardResolveRouter } from '../../core/auth.guard.resolver-router';
import { SidepanelService } from '../../core/sidepanel.service';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit, OnDestroy {

  constructor(
    public whiteLabel: WhitelabelService,
    public auth: AuthService,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {

  }

  async signInWithGoogle() {
    await this.auth.googleLogin();
    return await this.router.navigate('signup/sso'.split('/'));
  }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.chatPanel.activate();
  }

  ngOnDestroy() {

  }

  getDisplayName(){
    return this.auth.getDisplayName();
  }

  logout(){
    this.auth.signOut();
  }


  // /// Shared

  // private afterSignIn() {
  //   // Do after login stuff here, such router redirects, toast messages, etc.
  //   let resolveUrl = AuthGuardResolveRouter.getRoute();
  //   console.log('afterSignIn', resolveUrl);
  //   if (!resolveUrl || resolveUrl === 'login' || resolveUrl === 'home'){
  //     resolveUrl = '/';
  //     let userUI = this.auth.getUi();
  //     if (userUI){
  //       if (userUI === 'creator'){
  //         resolveUrl = '/create/tasks';
  //       }
  //       if (userUI === 'admin'){
  //         resolveUrl = 'teacher/classrooms';
  //       }
  //       else if (userUI === 'teacher'){
  //         resolveUrl = 'teacher/classrooms';
  //       }
  //       else if (userUI === 'student'){
  //         resolveUrl = 'student/classrooms';
  //       }
  //     }
  //   }
  //   return this.router.navigate(resolveUrl.split('/'));
  // }


}
