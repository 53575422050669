import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'student-skills',
  templateUrl: './student-skills.component.html',
  styleUrls: ['./student-skills.component.scss']
})
export class StudentSkillsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
