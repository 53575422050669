import {
  BrowserModule,
  BrowserTransferStateModule
} from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

// Mtic3 App Modules
import { CoreModule } from './core/core.module';
import { UiModule } from './ui/ui.module';
import { UiAdminModule } from './ui-admin/ui-admin.module';
import { UiStudentModule } from './ui-student/ui-student.module';
import { UiTeacherModule } from './ui-teacher/ui-teacher.module';
import { UiParentModule } from './ui-parent/ui-parent.module';
import { NotesModule } from './notes/notes.module';

// ui boosts
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTreeModule } from '@angular/cdk/tree';

// @angular/fire/ Modules
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database'; // temporary
import { AngularFireFunctionsModule,  } from '@angular/fire/functions';
import { UiTaskcreatorModule } from './ui-taskcreator/ui-taskcreator.module';
import { UiWhitelabelCambridgeModule } from './ui-whitelabel-cambridge/ui-whitelabel-cambridge.module';
import { UiWhitelabelDemoModule } from './ui-whitelabel-demo/ui-whitelabel-demo.module';
import { UiFieldcoordModule } from './ui-fieldcoord/ui-fieldcoord.module';
import { UiLeaderboardGameModule } from './ui-leaderboard-game/ui-leaderboard-game.module';


import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export default class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
       // Encode parentheses
       url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');
       // Use the default serializer.
       return this._defaultUrlSerializer.parse(url)
    }

    serialize(tree: UrlTree): string {
       return this._defaultUrlSerializer.serialize(tree).replace(/%28/g, '(').replace(/%29/g, ')');
    }
}

// IMPORTANT
// Add your own project credentials to environments/*.ts

@NgModule({
  providers: [
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
  ],
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    HttpClientModule,

    CoreModule,
    UiModule,
    NotesModule,
    UiAdminModule,
    UiStudentModule,
    UiTeacherModule,
    UiParentModule,
    UiTaskcreatorModule,
    UiFieldcoordModule,
    UiLeaderboardGameModule,
    
    UiWhitelabelCambridgeModule,
    UiWhitelabelDemoModule,

    DragDropModule,
    CdkTreeModule,

    AngularFireModule.initializeApp(environment.firebase, 'mtic3'),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule, // temp
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', {
    //   enabled: environment.production
    // })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
