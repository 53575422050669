import { Injectable } from '@angular/core';


import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { map, filter } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable()
export class CollectionsService {
  
  constructor( ) { }
    
  // public static filterCollection = (collection:Observable<any[]>, customFilter:(t:any)=>boolean):Observable<any[]> => {
  //   return collection.pipe(
  //     filter( (value, i) => {
  //       return false
  //       // return i % 2 === 0;
  //       // customFilter()
  //     })
  //   );
  // }
  public static getValuesIdentified = (collection:AngularFirestoreCollection<any>) => {
    return collection.snapshotChanges().pipe (
      map(actions => {
        return actions.map(action => {
          const data = action.payload.doc.data();
          return { 
            __id: action.payload.doc.id, 
            ...data 
          };
        });
      })
    )
  }
      
  public static deleteValue = (collection:AngularFirestoreCollection<any>, id:string) => {
    return collection.doc(id).delete()
  }
      
}