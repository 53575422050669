import { Component, OnInit, Input } from '@angular/core';
import { IContentElementMcqOption } from '../models';
import { FormControl } from '@angular/forms';
import { bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-mcq-option-text',
  templateUrl: './element-config-mcq-option-text.component.html',
  styleUrls: ['./element-config-mcq-option-text.component.scss']
})
export class ElementConfigMcqOptionTextComponent implements OnInit {

  @Input() mcqOption:IContentElementMcqOption;

  public content = new FormControl();

  constructor() { }

  ngOnInit() {
    bindFormControls(this.mcqOption, [
      {f: this.content, p:'content'},
    ])
  }

}
