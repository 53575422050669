import { Component, OnInit } from '@angular/core';
import { SidepanelService } from '../../core/sidepanel.service';
import { UserSiteContextService } from '../../core/usersitecontext.service';
import { ChatpanelService } from '../../core/chatpanel.service';

@Component({
  selector: 'teacher-classroom-curriculum',
  templateUrl: './teacher-classroom-curriculum.component.html',
  styleUrls: ['./teacher-classroom-curriculum.component.scss']
})
export class TeacherClassroomCurriculumComponent implements OnInit {

  constructor(
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
    private userSiteContext: UserSiteContextService,
  ) { }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
  }

}
