import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentChangeAction } from '@angular/fire/firestore';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CollectionsService } from '../../data/collections.service';
import { ICustomTaskSet } from '../models';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { serverTimestamp } from '../../data/timestamp';
import { AuthService } from '../../core/auth.service';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'custom-assessments',
  templateUrl: './custom-assessments.component.html',
  styleUrls: ['./custom-assessments.component.scss']
})
export class CustomAssessmentsComponent implements OnInit, OnDestroy {

  public customTaskSets:ICustomTaskSet[];  
  public projectId:string;

  private assignmentsCollection: AngularFirestoreCollection<ICustomTaskSet>;
  private routeSub:Subscription;
  private customTaskSetSub:Subscription;

  // drop(arr:any[], event: CdkDragDrop<string[]>) {
  //   moveItemInArray(arr, event.previousIndex, event.currentIndex);
  // }

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) { }


  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
      this.loadCustomTaskSets();
    });
  }

  ngOnDestroy(){
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.customTaskSetSub){ this.customTaskSetSub.unsubscribe(); }
  }

  loadCustomTaskSets(){
    this.assignmentsCollection = this.afs.collection<ICustomTaskSet>('customTaskSets', ref => {
      return ref
        .where('project', '==', this.projectId)
        .where('isArchived', '==', false)
    });
    this.customTaskSetSub = CollectionsService.getValuesIdentified(this.assignmentsCollection).subscribe(val => {
      // console.log('task sets', val.length)
      this.customTaskSets = val;
      if (this.customTaskSets){
        this.customTaskSets = this.customTaskSets.sort( (a,b) => {
          if (a.order < b.order){ return -1; }
          return 1;
        })
      }
    })
  }

  sortTasksByName(){
    if (this.customTaskSets){
      this.customTaskSets = this.customTaskSets.sort( (a,b) => {
        if (a.name < b.name){
          return -1;
        }
        return 1;
      })
    }
    let order = 1;
    this.customTaskSets.forEach(taskSet => {
      taskSet.order = order;
      this.afs.doc<ICustomTaskSet>('customTaskSets/'+taskSet.__id).update({order});
      order ++;
    });
  }

  drop(arr:ICustomTaskSet[], event: CdkDragDrop<string[]>) {
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
    let targetEntry = arr[event.currentIndex];
    let order = 1;
    let entryBefore, entryAfter;
    
    if (event.currentIndex > 0){
      entryBefore = arr[event.currentIndex-1];
      if (!entryBefore.order) { entryBefore.order = 1;} // not a perfect solution... but this shouldnt occur anyway }
    }
    if (event.currentIndex < arr.length - 1){
      entryAfter = arr[event.currentIndex+1];
      if (!entryAfter.order) { entryBefore.order = 2;} // not a perfect solution... but this shouldnt occur anyway }
    }

    if (!entryBefore && !entryAfter){
      order = 1;
    }
    else if (!entryBefore){
      order = entryAfter.order / 2
    }
    else if (!entryAfter){
      order = entryBefore.order + 1
    }
    else {
      order = (entryBefore.order + entryAfter.order)/2
    }

    this.afs.doc<ICustomTaskSet>('customTaskSets/'+targetEntry.__id).update({order});
  }

  createNewCustomTaskSet(){
    let name = window.prompt('What do you want to call this set?');
    let order = 1;
    if (this.customTaskSets.length){
      order = this.customTaskSets[0].order / 2;
    }
    if (!order || isNaN(order)){
      order = 1;
    }
    if (name){
      this.afs.collection<ICustomTaskSet>('customTaskSets').add({
        name,
        order,
        questions: '[]',
        project: this.projectId,
        creatorUid: this.auth.getUid(),
        isArchived: false,
        timeCreated: serverTimestamp()
      })  
    }
  }

  // Operational Sense (8m19)

}
