import { Injectable } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IAssignment } from '../data/collections/assignments.types';
import { serverTimestamp } from '../data/timestamp';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) { }

  loadAllPreviouslyRunAssignments(classroomId:string){
    return this.afs
      .collection<IAssignment>('assignments', ref=>ref
        .where('classroom', '==', classroomId)
      )
      .get()
      .toPromise()
      .then(res => res.docs.map(doc => {
        return <IAssignment>{
          ... doc.data(),
          __id: doc.id
        }
      }))
  }

  directCloseAssignment(assignmentId:string){
    return this.afs
      .doc<IAssignment>('assignments/'+assignmentId)
      .update({
        isDirectClose: true,
        timeDirectClose: serverTimestamp(),
      });
  }
    
}
