import { Injectable } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { IQuestionConfig, ICustomTaskSet } from './models/index';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { ITask, generateDefaultTask, generateDefaultQuestionTask } from '../data/collections/tasks.types';


@Injectable({
  providedIn: 'root'
})
export class TaskCacheService {

  private taskSetLoaders = new Map();

  private taskSetQuestions = new Map();
  private questionsByTaskId = new Map();

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  loadCustomTaskSetToCache(customTaskSetId:String){
    if (this.taskSetLoaders.has(customTaskSetId)){
      return this.taskSetLoaders.get(customTaskSetId);
    }
    else{
      const loader = this.afs.doc<ICustomTaskSet>('customTaskSets/'+customTaskSetId).get().toPromise().then ( docSnapshot => {
        const questions:IQuestionConfig[] = JSON.parse(docSnapshot.data().questions);
        questions.forEach(question => {
          // console.log('captured tasks', question.taskId)
          this.questionsByTaskId.set(question.taskId, question);
        })
        this.taskSetQuestions.set(customTaskSetId, questions);
      })
      this.taskSetLoaders.set(customTaskSetId, loader)
      return loader;
    }
  }

  getQuestionByTaskId(taskId:string):IQuestionConfig{
    return this.questionsByTaskId.get(taskId);
  }

  getTaskSetQuestions(customTaskSetId:string):IQuestionConfig[]{
    return this.taskSetQuestions.get(customTaskSetId);
  }

}




