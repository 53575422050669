import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ClassroomService } from '../../ui-teacher/classroom.service';
import { AuthService } from '../../core/auth.service';
import { Subscription } from 'rxjs';
import { IUser } from '../../data/collections/users.types';
import { IClassroom } from '../../data/collections/classrooms.types';

interface IClassroomSlot {
  id:string,
  name: string,
  isLoading: boolean,
}

@Component({
  selector: 'panel-student-account',
  templateUrl: './panel-student-account.component.html',
  styleUrls: ['./panel-student-account.component.scss']
})
export class PanelStudentAccountComponent implements OnInit, OnDestroy {

  userInfo:IUser;
  classroomSlots:IClassroomSlot[];
  classCodeRegForm = new FormGroup({});
  classCodeInput = new FormControl('');

  userSub:Subscription;
  classCodeInputSub:Subscription;

  constructor(
    private auth:AuthService,
    private classroomService: ClassroomService,
  ) { }
  
  ngOnInit() {
    this.userSub = this.auth.user.subscribe( userInfo => this.refreshUserInfo(userInfo) );
    this.classCodeInputSub = this.classCodeInput.valueChanges.subscribe( () => this.isJoiningClassError = false );
  }

  ngOnDestroy() {
    if (this.userSub){ this.userSub.unsubscribe(); }
  }

  refreshUserInfo(userInfo:IUser){
    this.userInfo = userInfo;
    this.loadClassroomsById();
  }

  loadClassroomsById(){
    this.classroomSlots = [];
    if (this.userInfo && this.userInfo.classroomsAsStudent){
      this.userInfo.classroomsAsStudent.forEach(classroomId => {
        let classroomSlot:IClassroomSlot = {
          id: classroomId,
          name: '',
          isLoading: true,
        };
        this.classroomSlots.push(classroomSlot);
        this.classroomService
          .getClassroomInfo(classroomId)
          .subscribe( classroomInfo => {
            classroomSlot.isLoading = false;
            classroomSlot.name = classroomInfo.name;
          })
      })
    }
  }

  logout(){
    this.auth.signOut();
  }

  getDisplayName(){
    return this.auth.getDisplayName();
  }

  getUsername(){
    return this.auth.getUsername();
  }

  isJoiningClass:boolean;
  isJoiningClassError:boolean;
  joinClassroomByClassCode(){
    this.isJoiningClass = true;
    this.isJoiningClassError = false;
    this.classroomService
      .getClassroomIdByClassCode(this.classCodeInput.value)
      .then( classroomId => {
        console.log('join this class', classroomId)
        // 42d
        return this.classroomService
          .addStudentToClassroom(classroomId, this.auth.getUid())
          .then( () => {
            this.isJoiningClass = false;
          })
      })
      .catch( e => {
        console.warn(e);
        this.isJoiningClassError = true;
      })
  }

}
