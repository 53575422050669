export const COLLECTIONS = {
    ASSIGNMENT_TASK_STATES: {
        name: 'assignmentTaskStates',
    },
    ASSIGNMENT_TASK_SCREEN_STATES: {
        name: 'assignmentTaskScreenStates',
    },
    ASSIGNMENT_SUBMISSIONS: {
        name: 'assignmentSubmissions'
    },
    ASSIGNMENT_TASK_SUBMISSIONS: {
        name: 'assignmentTaskSubmissions'
    },
}
