import { Component, OnInit } from '@angular/core';
import { WhitelabelService } from '../../domain/whitelabel.service';

@Component({
  selector: 'form-text-agree-int-comms',
  templateUrl: './form-text-agree-int-comms.component.html',
  styleUrls: ['./form-text-agree-int-comms.component.scss']
})
export class FormTextAgreeIntCommsComponent implements OnInit {

  constructor(
    public whiteLabel: WhitelabelService,
  ) { }

  ngOnInit() {
  }

}
