import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssessmentEditorComponent } from './assessment-editor/assessment-editor.component';
import { AssessmentRunnerComponent } from './assessment-runner/assessment-runner.component';
import { ElementConfigTextComponent } from './element-config-text/element-config-text.component';
import { ElementRenderTextComponent } from './element-render-text/element-render-text.component';
import { ElementConfigMathComponent } from './element-config-math/element-config-math.component';
import { ElementConfigImageComponent } from './element-config-image/element-config-image.component';
import { ElementConfigVideoComponent } from './element-config-video/element-config-video.component';
import { ElementConfigMcqComponent } from './element-config-mcq/element-config-mcq.component';
import { ElementConfigInputComponent } from './element-config-input/element-config-input.component';
import { ElementRenderMathComponent } from './element-render-math/element-render-math.component';
import { ElementRenderImageComponent } from './element-render-image/element-render-image.component';
import { ElementRenderVideoComponent } from './element-render-video/element-render-video.component';
import { ElementRenderMcqComponent } from './element-render-mcq/element-render-mcq.component';
import { ElementRenderInputComponent } from './element-render-input/element-render-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomAssessmentsComponent } from './custom-assessments/custom-assessments.component';
import { DropZoneDirective } from './drop-zone.directive';
import { FileSizePipe } from './file-size.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule } from '@angular/router';
import { ElementConfigMcqOptionTextComponent } from './element-config-mcq-option-text/element-config-mcq-option-text.component';
import { ElementConfigMcqOptionMathComponent } from './element-config-mcq-option-math/element-config-mcq-option-math.component';
import { ElementConfigMcqOptionImageComponent } from './element-config-mcq-option-image/element-config-mcq-option-image.component';
import { CaptureMathComponent } from './capture-math/capture-math.component';
import { RenderMathComponent } from './render-math/render-math.component';
import { CaptureImageComponent } from './capture-image/capture-image.component';
import { RenderImageComponent } from './render-image/render-image.component';
import { CustomAssessmentsCollectionsComponent } from './custom-assessments-collections/custom-assessments-collections.component';
import { ElementConfigOrderComponent } from './element-config-order/element-config-order.component';
import { ElementRenderOrderComponent } from './element-render-order/element-render-order.component';
import { AssessmentRunnerStandaloneComponent } from './assessment-runner-standalone/assessment-runner-standalone.component';
import { ElementRenderIframeComponent } from './element-render-iframe/element-render-iframe.component';
import { ElementConfigIframeComponent } from './element-config-iframe/element-config-iframe.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { TimeModule } from '../time/time.module';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BoosterAssignComponent } from './booster-assign/booster-assign.component';
import { FrameworkDimensionEditorComponent } from './framework-dimension-editor/framework-dimension-editor.component';
import { FrameworkDimensionInputComponent } from './framework-dimension-input/framework-dimension-input.component';
import { MarkerCoordOvervieComponent } from './marker-coord-overvie/marker-coord-overvie.component';
import { MarkerCoordOverviewComponent } from './marker-coord-overview/marker-coord-overview.component';
import { MarkerCoordItemsComponent } from './marker-coord-items/marker-coord-items.component';
import { MarkerCoordMarkersComponent } from './marker-coord-markers/marker-coord-markers.component';
import { MarkerOverviewComponent } from './marker-overview/marker-overview.component';
import { MarkerItemComponent } from './marker-item/marker-item.component';
@NgModule({
  declarations: [
    AssessmentEditorComponent, 
    AssessmentRunnerComponent, 
    ElementConfigTextComponent, 
    ElementRenderTextComponent, 
    ElementConfigMathComponent, 
    ElementConfigImageComponent, 
    ElementConfigVideoComponent, 
    ElementConfigMcqComponent, 
    ElementConfigInputComponent, 
    ElementRenderMathComponent, 
    ElementRenderImageComponent, 
    ElementRenderVideoComponent, 
    ElementRenderMcqComponent, 
    ElementRenderInputComponent, 
    CustomAssessmentsComponent, 
    DropZoneDirective, 
    FileSizePipe, 
    ElementConfigMcqOptionTextComponent, 
    ElementConfigMcqOptionMathComponent,
    ElementConfigMcqOptionImageComponent, 
    CaptureMathComponent, 
    RenderMathComponent, 
    CaptureImageComponent, 
    RenderImageComponent, 
    CustomAssessmentsCollectionsComponent, 
    ElementConfigOrderComponent, 
    ElementRenderOrderComponent, 
    AssessmentRunnerStandaloneComponent, ElementRenderIframeComponent, ElementConfigIframeComponent, CheckboxComponent, BoosterAssignComponent, FrameworkDimensionEditorComponent, FrameworkDimensionInputComponent, MarkerCoordOvervieComponent, MarkerCoordOverviewComponent, MarkerCoordItemsComponent, MarkerCoordMarkersComponent, MarkerOverviewComponent, MarkerItemComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule, 
    TimeModule,
    DragDropModule,
    CdkTreeModule
  ],
  exports: [
    AssessmentRunnerComponent,
    RenderMathComponent
  ]
})
export class UiTaskcreatorModule { }
