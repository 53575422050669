const STANDARD_EMAIL_SUFFIX:string = 'student.calculatingcats.com';

export const renderEmailFromUsername = (username:string) : string => {
  if (username){
    let usernameSplit = username.split('@');
    if (usernameSplit.length === 1){
      return username+'@'+STANDARD_EMAIL_SUFFIX;
    }
  }
  return username;
}

export const renderUsernameFromEmail = (email:string) : string => {
  if (email) {
    let emailSplit = email.split('@');
    if (emailSplit && emailSplit[1] === STANDARD_EMAIL_SUFFIX) {
      return emailSplit[0];
    }
  }
  return email;
}