// vendor
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { validateConfig } from '@angular/router/src/config';
import { firestore } from 'firebase/app';
import { ReplaySubject } from 'rxjs';
// data
import { serverTimestamp } from '../data/timestamp';
import { IClassroom } from '../data/collections/classrooms.types';
import { IUser } from '../data/collections/users.types';
import { IClassroomClassCode } from '../data/collections/classrooms-class-code.types';
// services
import { AuthService } from '../core/auth.service';
import { IClassroomStudentPlaintextPasswords } from '../data/collections/classroomStudentPlaintextPasswords.types';
import { CollectionsService } from '../data/collections.service';
import { clearSubjectMap } from '../core/util/clear-subject-map';
import { IBoosterPackPerfSummary } from '../ui-student/boosterpacks.service';
import { WhitelabelService } from '../domain/whitelabel.service';

export interface ICurricPerf {
  isPassed: boolean,
  isFailed: boolean,
  isUngraded?: boolean, // generally only set at the last minute
  performance:number, 
  performanceDisplay: string
}

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  classroomListeners:Map<string, ReplaySubject<IClassroom>> = new Map();
  studentBoosterPerformanceSubs:Map<String, ReplaySubject<Map<string, ICurricPerf>>> = new Map();
  studentOutstandingCheckpointsSubs:Map<String, ReplaySubject<number>> = new Map();

  constructor(
    public whitelabelService: WhitelabelService,
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {
    this.auth.newAuthSub.subscribe(()=>this.clearListeners())
  }

  clearListeners(){
    clearSubjectMap(this.classroomListeners);
  }

  createNewClassroom(curriculumId: string, name:string){
    const uid = this.auth.getUid();
    const res:{
      classroomId?:string,
      classCode?: string,
    } = {};
    return this.afs
      .collection('classrooms/')
      .add({
        timeCreated: serverTimestamp(),
        timeLastTouched: serverTimestamp(),
        enableClassListingByCC: true,
        curriculumId,
        name,
        trialId: this.auth.getUserTrialId(),
        instScopeId: this.auth.getUserInstScope(),
        isArchived: false,
        currentTeachers: [uid]
      })
      .then( val => {
        res.classroomId = val.id;
        this.afs.doc('users/'+uid).update({
          classroomsAsTeacher: firestore.FieldValue.arrayUnion(res.classroomId)
        });
        return this.assignClassCode(res.classroomId);
      })
      .then(classCode => {
        res.classCode = classCode
        return res;
      })
  }

  archiveClassroom(classroomId:string, isLastTeacher:boolean=false){
    const uid = this.auth.getUid();
    return this.afs.collection('classroomsArchivedTeachers').add({
      uid,
      classroomId,
      timestamp: serverTimestamp()
    })
    .then(()=>{
      return this.afs.doc('classrooms/'+classroomId).update({
        isArchived: (isLastTeacher === true),
        currentTeachers: firestore.FieldValue.arrayRemove(uid)
      })
    })
    .then(()=>{
      return this.afs.doc('users/'+uid).update({
        classroomsAsTeacher: firestore.FieldValue.arrayRemove(classroomId)
      })
    })
    .then(()=> true);
  }

  
  getStudentOutstandingCheckpoints(uid:string){
    if (this.studentOutstandingCheckpointsSubs.has(uid)){
      return this.studentOutstandingCheckpointsSubs.get(uid);
    }
    const subject:ReplaySubject<number> = new ReplaySubject(1);
    this.studentOutstandingCheckpointsSubs.set(uid, subject)
    this.afs
      .collection('boosterPacksItemCheckpoints', ref=>ref
        .where('uid', '==', uid)
        .where('isComplete', '==', false)
        .limit(21)
      )
      .snapshotChanges()
      .subscribe((res) =>{
        if (res){
          subject.next(res.length)
        }
      })
    return subject;
  }

  getStudentBoosterPerformance(uid:string){
    if (this.studentBoosterPerformanceSubs.has(uid)){
      return this.studentBoosterPerformanceSubs.get(uid);
    }
    const subject:ReplaySubject<Map<string, ICurricPerf>> = new ReplaySubject(1);
    this.studentBoosterPerformanceSubs.set(uid, subject)
    this.afs
      .collection('boosterPacksPerformanceSummary', ref=>ref
        .where('uid', '==', uid)
        .limit(100) // arbitrary
      )
      .snapshotChanges()
      .subscribe((res) =>{
        const boosterPackPerformanceMap:Map<string, ICurricPerf> = new Map();
        res.forEach(entry =>{
          const perfSummary:IBoosterPackPerfSummary = <any>entry.payload.doc.data();
          let score = 0;
          if (perfSummary.totalStars > 0 && perfSummary.numStars > 0){
            score = perfSummary.numStars/perfSummary.totalStars;
          }
          const isPassed:boolean = score >= 0.8;
          boosterPackPerformanceMap.set(perfSummary.boosterPackId, {
            isFailed: !isPassed,
            isPassed: isPassed,
            performance: score,
            performanceDisplay: Math.round(score*100)+'%',
          });
          // console.log('getStudentBoosterPerformance', boosterPackPerformanceMap.get(perfSummary.boosterPackId) );
        })
        subject.next(boosterPackPerformanceMap);
      })
    return subject;
  }

  loadArchivedTeacherClassrooms(){
    const uid = this.auth.getUid();
    return CollectionsService.getValuesIdentified(
      this.afs 
        .collection('classroomsArchivedTeachers', ref=>ref
          .where('uid', '==', uid)
      )
    )
  }

  toggleClassListingByCC(classroomId:string, enableClassListingByCC:boolean){
    this.afs
      .doc('classrooms/'+classroomId)
      .update({enableClassListingByCC})
  }

  restoreClassroom(classroomId:string, archivedClassroomId:string){
    const uid = this.auth.getUid();
    return this.afs
      .doc('classroomsArchivedTeachers/'+archivedClassroomId)
      .delete()
      .then(()=>{
        return this.afs
          .doc('classrooms/'+classroomId)
          .update({
            isArchived: false,
            currentTeachers: firestore.FieldValue.arrayUnion(uid)
          })
      })
      .then(()=>{
        return this.afs
          .doc('users/'+uid)
          .update({
            classroomsAsTeacher: firestore.FieldValue.arrayUnion(classroomId)
          })
      })
      .then(()=> true);
  }

  getClassroomInfo(classroomId:string) : ReplaySubject<IClassroom> {
    if (this.classroomListeners.has(classroomId)){
      return this.classroomListeners.get(classroomId);
    }
    let subject:ReplaySubject<IClassroom> = new ReplaySubject(1);
    this.classroomListeners.set(classroomId, subject);
    this.afs.doc<IClassroom>('classrooms/'+classroomId)
        .valueChanges()
        .subscribe( observer => {
          subject.next(observer);
        });
    return subject;
  }

  getClassroomInfoAsPromise(classroomId:string) {
    return this.afs.doc<IClassroom>('classrooms/'+classroomId)
    .get().toPromise().then( observer => {
      return observer.data() as IClassroom;
    });
  }

  registerStudentPasswordUpdate(classroomId:string, studentUid:string, newPassword:string){
    const collection = 'classroomStudentPlaintextPasswords';
    const newEntry:IClassroomStudentPlaintextPasswords = {
      classroomId,
      uid: studentUid,
      password: newPassword,
      isArchived: false,
    }
    return this.afs
      .collection<IClassroomStudentPlaintextPasswords>(collection, ref=>ref
        .where('classroomId', '==', classroomId)
        .where('uid', '==', studentUid)
      )
      .get()
      .toPromise()
      .then(entries => {
        if (entries.docs.length > 0){
          const docId = entries.docs[0].id;
          return this.afs
            .doc<IClassroomStudentPlaintextPasswords>([collection, docId].join('/'))
            .update(newEntry)
            .then(()=>true)
        }
        else{
          return this.afs
            .collection<IClassroomStudentPlaintextPasswords>(collection)
            .add(newEntry)
            .then(()=>true)
        }
      })

  }

  getClassroomPasswords(classroomId:string){
    return this.afs
      .collection<IClassroomStudentPlaintextPasswords>(`classroomStudentPlaintextPasswords`, ref=>ref
        .where('classroomId', '==', classroomId)
      )
      .get()
      .toPromise()
      .then(entries => {
        const res = [];
        entries.forEach(entry => {
          res.push({
            ... entry.data()
          })
        });
        return res;
      })
  }

  removeStudentFromClassroom(classroomId:string, studentUid:string){
    const teacherUid = this.auth.getUid();
    return this.afs
      .doc<IClassroom>('classrooms/'+classroomId)
      .update({
        currentStudents: <any>firestore.FieldValue.arrayRemove(studentUid)
      })
      .then(()=>{
        return this.afs
          .doc<IUser>('users/'+studentUid)
          .update({
            classroomsAsStudent: <any>firestore.FieldValue.arrayRemove(classroomId)
          })
      })
      .then(()=>{
        return this.afs
          .collection('classroomsStudentArchive/')
          .add({
            studentUid,
            classroomId,
            teacherUid,
            timestamp: serverTimestamp() 
          })
      })
      .then(()=>{
        return this.afs
          .doc<IClassroomStudentPlaintextPasswords>(`classroomStudentPlaintextPasswords/${studentUid}`)
          .update({
            isArchived: true
          })
      })
      .then(()=> true)
  }

  addStudentToClassroom(classroomId:string, studentUid:string, password:string='{HIDDEN}', skipStudentRecordUpdate?:boolean){
    return this.afs
      .doc<IUser>('users/'+studentUid)
      .update({
        classroomsAsStudent: <any>firestore.FieldValue.arrayUnion(classroomId)
      })
      .then(()=>{
        return this.afs
          .doc<IClassroomStudentPlaintextPasswords>(`classroomStudentPlaintextPasswords/${studentUid}`)
          .set({
            classroomId,
            uid: studentUid,
            password,
            isArchived: false
          })
      })
      .then(()=>{
        if (!skipStudentRecordUpdate){
          return this.afs
            .doc<IClassroom>('classrooms/'+classroomId)
            .update({
              currentStudents: <any>firestore.FieldValue.arrayUnion(studentUid)
            })
        }
      })
  }

  getClassroomIdByClassCode(classCodeId:string){
    return this.afs.doc<IClassroomClassCode>('classroomsClassCode/'+classCodeId)
      .get().toPromise()
      .then( res => {
        let record:IClassroomClassCode = <any>res.data();
        if (!record){
          throw new Error('classcode/does-not-exist');
        }
        if (!record.isUsed || !record.classroomId){
          console.warn('getClassroomIdByClassCode', 'classcode/no-class', record)
          throw new Error('classcode/no-class');
        }
        if (record.isExpired){
          throw new Error('classcode/expired');
        }
        console.log('getClassroomIdByClassCode', record)
        return record.classroomId
        // return classCodeToUse.id
      })
  }

  assignClassCode(classroomId:string, iteration:number=0) {
    const res:{
      classCode?: string,
    } = {};
    return this.fetchUnusedClassCode()
      .then( classCodeId => {
        res.classCode = classCodeId;
        this.afs.doc('classrooms/'+classroomId)
          .update({
            classCode: classCodeId
          });
        this.afs.doc<IClassroomClassCode>('classroomsClassCode/'+classCodeId)
          .update({
            classroomId,
            isUsed: true,
            timeUsed: serverTimestamp(),
            usedByUid: this.auth.getUid(),
          })
          .then ( () =>  this.verifyClassCodePersistence(classCodeId, classroomId, iteration) )

      })
      .then( () => {
        return res.classCode;
      })
  }

  private verifyClassCodePersistence(classcode:string, classroomId:string, iteration:number=0){
    // TO DO: check after delay of 10 seconds
    return true;
  }

  private fetchUnusedClassCode(){
    // the collection is already pre-shuffled on the `i` property
    return this.afs.collection('classroomsClassCode', (ref) => ref
      .where('isUsed', '==', false)
      .orderBy('i', 'asc')
      .limit(100)
    )
    .get().toPromise()
    .then( res => {
      const indexToUse = Math.floor(res.docs.length * Math.random())
      const classCodeToUse = res.docs[indexToUse]
      // console.log('fetchUnusedClassCode', classCodeToUse.id)
      return classCodeToUse.id
    })
  }

}
