import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentLeaderboardGameComponent } from './student-leaderboard-game/student-leaderboard-game.component';

@NgModule({
  declarations: [StudentLeaderboardGameComponent],
  imports: [
    CommonModule
  ]
})
export class UiLeaderboardGameModule { }
