import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { ListMode, IListMode, FormMode } from '../../data/list-forms.types';

@Component({
  selector: 'form-student-class-manager',
  templateUrl: './form-student-class-manager.component.html',
  styleUrls: ['./form-student-class-manager.component.scss']
})
export class FormStudentClassManagerComponent implements OnInit {

  studentClassroomsSearchString = new FormControl('');
  activeClassroom = new FormControl(null);
  joinClassSearchQuery = new FormControl('');
  activeJoinableClassroom = new FormControl(null);

  currentFormMode = FormMode.NONE;

  listModes:IListMode[] = [
    {slug:ListMode.CURRENT, caption:'Current', isActive:true},
    {slug:ListMode.CURRENT, caption:'Passed'}
  ]
  currentListMode = this.listModes[0];


  constructor() { }

  ngOnInit() {
  }

  isShowingPastClasses(){
    return this.currentListMode.slug === ListMode.PAST;
  }

  useActiveClassroom(){

  }
  leaveActiveClassroom(){
    if (confirm('Are you sure you want to un-enroll from this class on behalf of the student? This will become a "Past Class" and the student will not be able to access it anymore')){

    }
  }

  classroomJoinStart(){
    this.currentFormMode = FormMode.NEW;
  }
  joinActiveClassroom(){
    // this.activeJoinableClass
  }
  classroomJoinCancel(){
    this.currentFormMode = FormMode.NONE;
  }

}
