import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { IContentElementMath, TextParagraphStyle, ElementType, IContentElementInput, InputFormat } from '../models';
import { FormControl } from '@angular/forms';
import { bindFormControl, bindFormControls } from '../services/data-bind';

@Component({
  selector: 'element-config-math',
  templateUrl: './element-config-math.component.html',
  styleUrls: ['./element-config-math.component.scss']
})
export class ElementConfigMathComponent implements OnInit {

  @Input() element:IContentElementMath;

  paragraphStyle = new FormControl('');
  isShowAdvancedOptions = new FormControl(false);

  paragraphStyles = [
    {id: TextParagraphStyle.HEADLINE, caption:'Extra Large'},
    {id: TextParagraphStyle.HEADLINE_SMALL, caption:'Large'},
    {id: TextParagraphStyle.REGULAR, caption:'Regular'},
    {id: TextParagraphStyle.SMALL, caption:'Small'},
  ]

  constructor() { }

  ngOnInit() {
    // console.log('jquery in angular', (window as any).jQuery);
    // console.log('mathquill in angular', (window as any).MathQuill);
    bindFormControls(this.element, [
      {f: this.isShowAdvancedOptions, p:'isShowAdvancedOptions'},
      {f: this.paragraphStyle, p:'paragraphStyle'},
    ])
  }

  convertToInput(){
    let element:IContentElementInput = <any>this.element;
    element.isShowAdvancedOptions = false;
    element.elementType = ElementType.INPUT
    element.format = InputFormat.ALGEBRA;
  }


}
