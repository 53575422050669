import * as _ from 'lodash';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ILeaderboard, ILeaderboardGroupScore, ILeaderboardScore, ILeaderboardGroup } from 'src/app/data/cambridge/types';
import { LeaderboardService } from 'src/app/core/leaderboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ForeignUserService } from 'src/app/core/foreignUser.service';
import { AuthService } from 'src/app/core/auth.service';
import { UserSiteContextService } from 'src/app/core/usersitecontext.service';
import { ClassroomService } from 'src/app/ui-teacher/classroom.service';
import { IClassroom } from 'src/app/data/collections/classrooms.types';

enum ListMode {
  TEAMS,
  MYTEAM,
  STUDENTS,
  CERTIFICATE,
}

interface ILeaderboardStudentChunk {
  uid: string;
  name: string;
  groupName: string;
  rank: number;
  scoreTotal: number;
  score: ILeaderboardScore;
  group: ILeaderboardGroup;
}

@Component({
  selector: 'student-leaderboard-window',
  templateUrl: './student-leaderboard-window.component.html',
  styleUrls: ['./student-leaderboard-window.component.scss']
})
export class StudentLeaderboardWindowComponent implements OnInit, OnChanges {
  @Input() leaderboardData: ILeaderboard;
  
  groupScores: ILeaderboardGroupScore[] = [];
  allGroupScores: ILeaderboardGroupScore[];
  groupId: string;
  myGroupScore?: ILeaderboardGroupScore;
  myBoosterScore?: ILeaderboardScore;
  leaderboardHidden = false;
  routeData: {isTeacher?: boolean, isAdmin?: boolean, isLeaderboard?: boolean} = {};
  isRouteDataLoaded: boolean;
  showWindow = true;
  animateWindow = false;
  showLocked = false;
  lockedText = '';
  isInited: boolean;
  ListMode = ListMode;

  myGroupStudents: ILeaderboardScore[];

  classroomId: string;
  isStudentDataFetch = false;
  studentPerformanceForClass: IClassroom;
  studentPerformances: ILeaderboardStudentChunk[] = [];

  currentListMode: ListMode = ListMode.TEAMS;
  listModeMap = {
    [ListMode.TEAMS]: {id: ListMode.TEAMS, caption: 'Teams'},
    [ListMode.STUDENTS]: {id: ListMode.STUDENTS, caption: 'Students'},
    [ListMode.MYTEAM]: {id: ListMode.MYTEAM, caption: 'My Team'},
    [ListMode.CERTIFICATE]: {id: ListMode.CERTIFICATE, caption: 'Certificate'},
  };

  listModes: Array<{caption: string, id: ListMode}> = [];

  constructor(
    private ls: LeaderboardService,
    private route: ActivatedRoute,
    private foreign: ForeignUserService,
    private auth: AuthService,
    private userSiteContext: UserSiteContextService,
    private cs: ClassroomService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.classroomId = this.userSiteContext.handleClassroomRouteParam(params['classroomId']);
    });
    let routeSub = this.route.data.subscribe(v => {
      this.isRouteDataLoaded = true;
      this.routeData = v;
      if (this.routeData.isTeacher) {
        this.showLocked = false;
      }
      window.setTimeout(() => routeSub.unsubscribe(), 100);
      this.ensureInit();
    });
  }


  ngOnChanges() {
    this.ensureInit();
  }

  getUid() {
    return this.auth.getUid();
  }

  populateListModes() {
    if (!_.includes(this.listModes, this.listModeMap[ListMode.TEAMS])) {
      this.listModes.push(this.listModeMap[ListMode.TEAMS]);
    }
    if (this.isTeacher() && !_.includes(this.listModes, this.listModeMap[ListMode.STUDENTS])) {
      this.listModes.push(this.listModeMap[ListMode.STUDENTS]);
    } else {
      if (!_.includes(this.listModes, this.listModeMap[ListMode.MYTEAM])) {
        this.listModes.push(this.listModeMap[ListMode.MYTEAM]);
      }
      if (!this.ls.isLeaderboardActive(this.leaderboardData) && this.myBoosterScore && !_.includes(this.listModes, this.listModeMap[ListMode.CERTIFICATE])) {
        this.listModes.push(this.listModeMap[ListMode.CERTIFICATE]);
      }
    }
  }

  ensureInit() {
    if (this.isRouteDataLoaded && !this.isInited){
      this.isInited = true;
      this.populateListModes();

      let leaderboardId = this.leaderboardData.__id;
      // console.log('this.leaderboardData.__id', );
      if (this.ls.leaderboardCollapseState.has(leaderboardId)){
        this.showWindow = this.ls.leaderboardCollapseState.get(leaderboardId);
      }
      // this.rerenderLeaderboardInfo();
      if (!this.routeData || !this.leaderboardData) {
        return;
      }
      this.rerenderLeaderboardStats();
    }
  }

  toggleWindow(newState:boolean){
    if (newState || this.isTeacher() || this.ls.isLeaderboardActive(this.leaderboardData)) {
      this.showWindow = newState;
      let leaderboardId = this.leaderboardData.__id;
      this.ls.leaderboardCollapseState.set(leaderboardId, newState);
    }
  }

  secondsToDate(seconds: number) {
    return new Date(seconds * 1000).toDateString();
  }

  isTeacher() {
    return this.routeData && (this.routeData.isTeacher || this.routeData.isAdmin);
  }
  
  isExpanded(){
    if ((!this.isTeacher() && !this.ls.isLeaderboardActive(this.leaderboardData)) || this.showWindow){
      return true;
    }
    return false;
  }
  
  ensureStudentDataFetch(){
    if (!this.isStudentDataFetch && this.userSiteContext.hasActiveClassroom()){
      this.isStudentDataFetch = true;
      this.studentPerformances = [];
      this.cs.getClassroomInfoAsPromise(this.classroomId).then(classroom => {
        this.studentPerformanceForClass = classroom;
        let students = classroom.currentStudents;
        this.ls.getLeaderboardStudentScores(this.leaderboardData, students).then(scores => {
          // this.studentPerformances = scores;
          this.ls.getAllLeaderboardGroups(this.leaderboardData).then(groups => {
            this.studentPerformances = [];
            students.forEach(uid => {
              let score = _.find(scores, {uid: uid});
              let group = _.find(groups, group => _.includes(group.students, uid));
              if (score && group) {
                this.studentPerformances.push({uid, score, group, name: score.name, groupName: group.name, scoreTotal: score.score, rank: 0});
              }
            });
            
            this.studentPerformances = _.sortBy(this.studentPerformances, ['scoreTotal']).reverse();
            this.studentPerformances.forEach((perf, i) => perf.rank = i + 1);
            console.log('groups established!', this.studentPerformances, students, groups);
          });
        });
      });
    }
  }

  selectListMode(listMode:ListMode){
    this.currentListMode = listMode; 
    if (listMode === ListMode.STUDENTS){
      this.ensureStudentDataFetch();
    } else if (listMode === ListMode.MYTEAM) {
      this.ls.getMyLeaderboardGroup(this.leaderboardData, false).then(group => {
        if (group) {
          this.ls.getUserBoosterScoresForGroup(this.leaderboardData, group).then(scores => {
            this.myGroupStudents = scores;
          });
        }
      });
    }
  }

  isListModeActive(listMode:ListMode){
    return listMode === this.currentListMode;
  }

  // rerenderLeaderboardInfo(){
  //   let nowSeconds = new Date().getTime() / 1000;
  //   if (!this.routeData.isTeacher && this.leaderboardData.dateOpened && (nowSeconds < this.leaderboardData.dateOpened.seconds)) {
  //     this.showLocked = true;
  //     this.lockedText = 'This leaderboard is not yet open.  Please wait until ' + new Date(this.leaderboardData.dateOpened.seconds * 1000).toDateString() + '.';
  //   } 
  //   else if (!this.routeData.isTeacher && this.leaderboardData.dateClosed && (nowSeconds > this.leaderboardData.dateClosed.seconds)) {
  //     this.showLocked = true;
  //     this.lockedText = 'This leaderboard is finished as of ' + new Date(this.leaderboardData.dateClosed.seconds * 1000).toDateString() + '.';
  //   } 
  //   else {
  //     this.ls.getMyBoosterScore(this.leaderboardData).then(myScore => {
  //       // this.showWindow = !this.routeData.isTeacher && !Boolean(myScore);
  //     });
  //   }
  // }

  rerenderLeaderboardStats(){
    if (this.isTeacher()) {
      let foreignClassId: string = this.foreign.getForeignUserData().foreignClassId;
      if (foreignClassId) {
        this.ls
          .assignMeAsModeratorToForeignLeaderboardGroups(this.leaderboardData, foreignClassId)
          .then( res => {
            this.rerenderLeaderboardStatsForTeacher(foreignClassId);
            // groups.forEach(group => this.myLeaderboardGroupChunks.push(this.makeGroupChunk(leaderboard, group)));
          });
      }
      else{
        this.rerenderLeaderboardStatsForTeacher(null);
      }
    } 
    else {
      this.rerenderLeaderboardStatsForStudent();
    }
    if (this.myGroupScore){
      this.myGroupScore.score = Math.floor(this.myGroupScore.score);
    }
  }

  myGroup:ILeaderboardGroup;
  rerenderLeaderboardStatsForTeacher(foreignClassId:string){
    this.ls.getMyModeratedLeaderboardGroups(this.leaderboardData, foreignClassId).then(groups => {
      if (groups && groups.length > 0) {
        this.myGroup = groups[0];
        this.groupId = groups[0].__id;
        this.ls.getTopEntries(this.leaderboardData, 50).then(groupScores => {
          this.allGroupScores = groupScores;
          this.groupScores = groupScores.slice(0, 5);

          this.myGroupScore = _.find(this.allGroupScores, {groupId: this.groupId});
          if (!this.myGroupScore) {
            this.ls.getLeaderboardGroupScore(this.leaderboardData, this.groupId).then(data => {
              this.myGroupScore = data;
            });
          }
        });
      } 
      else {
        this.ls.getTopEntries(this.leaderboardData, 50).then(groupScores => {
          this.allGroupScores = groupScores;
          this.groupScores = groupScores.slice(0, 5);
        });
      }
    });
  }

  updateTeamName() {
    const promptCaption = this.leaderboardData.teamNameChangeMessage || 'New Name';
    const newName = prompt(promptCaption);
    if (newName){
      this.myGroup.name = newName;
      this.myGroupScore.name = newName;
      this.ls
        .updateGroupName(this.myGroup, newName);
    }
  }

  rerenderLeaderboardStatsForStudent(){
    this.ls.getMyLeaderboardGroup(this.leaderboardData, false).then(group => {
      if (group) {
        this.groupId = group.__id;
      }
      this.ls.getMyBoosterScore(this.leaderboardData).then(score => {
        this.myBoosterScore = score;
        this.populateListModes();
      });
      this.ls.getTopEntries(this.leaderboardData, 50).then(groupScores => {
        this.allGroupScores = groupScores;
        if (group) {
          this.myGroupScore = _.find(this.allGroupScores, {groupId: this.groupId});
        }
        // console.log('groups', this.allGroupScores, this.myGroupScore);
        if (this.myGroupScore && this.myGroupScore.numStudents < this.leaderboardData.minGroupSize) {
          this.myGroupScore.__rank = 0;
          _.pull(this.allGroupScores, this.myGroupScore);
        }

        this.groupScores = groupScores.slice(0, 5);
        this.groupScores.forEach(group =>{
          if (group.score){
            group.score = Math.floor(group.score);
          }
        })

        if (!this.myGroupScore && this.groupId) {
          this.ls.getLeaderboardGroupScore(this.leaderboardData, this.groupId).then(data => {
            this.myGroupScore = data;
          });
        }
      });
    });
  }

  isInTop5() {
    return (this.myGroupScore && this.myGroupScore.__rank && this.myGroupScore.__rank <= 5);
  }

  formatScore(n: number) {
    return Math.floor(n);
  }

  openMyGroup(groupScore: ILeaderboardGroupScore) {
    if (this.myGroupScore && groupScore === this.myGroupScore) {
      this.selectListMode(ListMode.MYTEAM);
    }
  }

  runCertificatePlaceholder(){
    alert('This will link to a printable certificate');
  }
}
