import * as _ from 'lodash';
import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'student-leaderboard-info',
  templateUrl: './student-leaderboard-info.component.html',
  styleUrls: ['./student-leaderboard-info.component.scss']
})
export class StudentLeaderboardInfoComponent   {

  constructor(
  ) { }


}
