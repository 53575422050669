import { IPanelLink } from "./type";

export const adminPanels:IPanelLink[] = [
    {
        caption: 'Task Creator',
        routerLink: '/create/tasks',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
    },
    {
        caption: 'Itematic Linker',
        routerLink: '/admin/support',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
    },
    {
        caption: 'Accounts',
        routerLink: '/admin/accounts',
        iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficon-classroom.png?alt=media&token=ce81d29b-8760-4154-a62e-c1da80903828',
    },
    {
      caption: 'Leaderboards Creation',
      routerLink: '/admin/leaderboards',
      iconUrl: 'https://firebasestorage.googleapis.com/v0/b/calculating-cats.appspot.com/o/website%2Ficons%2Ficons-assessment.png?alt=media&token=e6aa3f51-1daf-4b08-ab11-7ae19b0e8b05',
    },
]