import * as _ from 'lodash';
import * as PIXI from 'pixi.js';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/core/auth.service';
import { TaskCacheService } from 'src/app/ui-taskcreator/task-cache.service';
import { genBaseCosmetics, IExploreLocation } from 'src/app/data/collections/heads-up-lobby';
import { ILoadedCosmeticsEquipped, IUserAvatarCosmeticsEquipped } from 'src/app/ui-avatar-displays/util/DragonAvatar/DragonInterfaces';
import { serverTimestamp } from 'src/app/data/timestamp';
import { AssetLoader } from 'src/app/ui-avatar-displays/util/AssetLoader';
import { ILoadedMapData } from 'src/app/ui-avatar-displays/util/TileMap/TempMapData';
import { SidepanelService } from '../../core/sidepanel.service';
import { Subscription } from 'rxjs';

const ACCOUNT_LOOKUP_LIMIT = 100; // see what is possible!

@Component({
  selector: 'student-explore',
  templateUrl: './student-explore.component.html',
  styleUrls: ['./student-explore.component.scss']
})
export class StudentExploreComponent implements OnInit, OnDestroy {
  assetLoader: AssetLoader;
  playerInfo: {uid: string};

  exploreLocation: IExploreLocation;

  playersPending: {uid: string}[] = [];
  playersAdded: {uid: string, data: IExploreLocation, cosmetics: ILoadedCosmeticsEquipped}[] = [];
  playersUpdated: string[] = [];

  cosmeticsToAdd: ILoadedCosmeticsEquipped;
  levelToLoad: ILoadedMapData;
  numUpdates = 0;

  private signinSub:Subscription;
  private userExplorSub:Subscription;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private auth: AuthService,
    private tcs: TaskCacheService,
    private sidePanel: SidepanelService,
  ) {
    this.assetLoader = new AssetLoader(afs);
    window.addEventListener('keydown', e => {
      if (e.key === 'q') {
        this.simulateLogout();
      }
    });
  }

  ngOnInit() {
    this.sidePanel.deactivate();
    this.signinSub = this.auth.user.subscribe(userInfo => {
      // console.log('userInfo', userInfo)
      if (userInfo) {
        this.initPlayerGame();
      } else {
        window.alert('You are not logged in and cannot play!');
      }
    });
  }

  ngOnDestroy() {
    if (this.signinSub) {this.signinSub.unsubscribe();}
    if (this.userExplorSub) {this.userExplorSub.unsubscribe();}
  }

  initPlayerGame() {
    this.playerInfo = {uid: this.auth.getUid()};
    this.assetLoader.fetchCosmetics(this.playerInfo.uid, cosmetics => {
      this.cosmeticsToAdd = cosmetics;
      this.numUpdates++;
    });
    this.fetchCurrentLevel(() => {
      this.listenOtherPlayers();
    });
    // this.listenToCoins();
  }

  listenOtherPlayers() {
    this.userExplorSub = this.afs.collection<IExploreLocation>('userExploreLocation/', ref => {
      return ref
        .where('shard', '==', this.exploreLocation.shard)
        .where('location', '==', this.exploreLocation.location)
        // .where('present', '==', true)
        .limit(ACCOUNT_LOOKUP_LIMIT); // see what upper limit is possible!
    }).stateChanges().subscribe(observer => {
      observer.forEach(entry => {
        let data = entry.payload.doc.data();
        if (data.uid !== this.playerInfo.uid) {
          this.updateOtherPlayer(data);
        }
      });
    });
  }

  updateOtherPlayer = (data: IExploreLocation) => {
    
    let uid = data.uid || 'NPC';

    let current = _.find(this.playersAdded, {uid});
    if (current) {
      if (!data.present) {
        _.pull(this.playersAdded, current);
        this.numUpdates++;
        return;
      }
      let compare: number;
      if (data.costumeUpdated && current.data.costumeUpdated) {
        // console.log('whatis', data.costumeUpdated, current.data.costumeUpdated);
        compare = data.costumeUpdated.seconds - current.data.costumeUpdated.seconds;
      } else if (data.costumeUpdated) {
        compare = 1;
      } else {
        compare = 0;
      }
      if (compare > 0) {
        this.assetLoader.fetchCosmetics(uid, cosmetics => {
          let data2 = _.find(this.playersAdded, {uid});
          data2.cosmetics = cosmetics;
          this.playersUpdated.push(uid);
          this.numUpdates++;
        });
      }
      if (data.x !== current.data.x || data.y !== current.data.y) {
        current.data.x = data.x;
        current.data.y = data.y;
        this.playersUpdated.push(uid);
        this.numUpdates++;
      }
    } else if (!_.find(this.playersPending, {uid})) {
      if (!data.present) {
        return;
      }
      this.playersPending.push({uid});

      this.assetLoader.fetchCosmetics(uid, cosmetics => {
        let pending = _.find(this.playersPending, {uid});
        if (pending) {
          _.pull(this.playersPending, pending);
          this.playersAdded.push({uid, data, cosmetics});
          this.numUpdates++;
        }
      });
    }
  }

  fetchCurrentLevel = (callback: () => void) => {
    this.afs.collection<IExploreLocation>('userExploreLocation/', ref => {
      return ref.where('uid', '==', this.playerInfo.uid)
      .limit(1);
    }).get().toPromise().then(observer => {
      if (observer.docs.length > 0) {
        this.exploreLocation = observer.docs[0].data() as IExploreLocation;
        this.exploreLocation.present = true;
      } else {
        this.exploreLocation = {
          uid: this.playerInfo.uid,
          location: 'test_town',
          mapSlug: 'mapData1',
          shard: 'only_shard_for_now',
          present: true,
          x: -1,
          y: -1,
          latestActivity: serverTimestamp(),
          costumeUpdated: serverTimestamp()
        };
      }

      let mapSlug = this.exploreLocation.mapSlug;
      this.assetLoader.loadMap(mapSlug, loaded => {
        this.levelToLoad = loaded;
        if (observer.docs.length === 0){
          this.exploreLocation.x = loaded.startLoc.x;
          this.exploreLocation.y = loaded.startLoc.y;
        }
        this.postCurrentLevel();
        callback();
      });
    });
  }

  simulateLogout() {
    this.postCurrentLevel({present: false});
    this.levelToLoad = null;
  }

  postCurrentLevel = (e?: Partial<IExploreLocation>) => {
    let exploreLocation = this.exploreLocation;
    if (e) {
      if (e.x || e.x === 0) {
        exploreLocation.x = e.x;
      }
      if (e.y || e.y === 0) {
        exploreLocation.y = e.y;
      }
      if (e.location || e.mapSlug) {
        // update location
      }
      if (e.present || e.present === false) {
        exploreLocation.present = e.present;
      }
    }
    this.exploreLocation.latestActivity = serverTimestamp();
    this.afs.doc<IExploreLocation>('userExploreLocation/' + this.playerInfo.uid).set(exploreLocation);
  }

  postCosmeticEquipped = (el: IUserAvatarCosmeticsEquipped) => {
    let chunkName = this.playerInfo.uid;
    el.lastUpdated = serverTimestamp();
    el.updatedIn = 'EXPLORE';
    this.exploreLocation.costumeUpdated = serverTimestamp();
    this.afs.doc<IUserAvatarCosmeticsEquipped>('userAvatarCosmeticsEquipped2/' + chunkName).set(el);
    this.afs.doc<IExploreLocation>('userExploreLocation/' + this.playerInfo.uid).update({costumeUpdated: this.exploreLocation.costumeUpdated});
  }
}
