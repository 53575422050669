import { Component, OnInit } from '@angular/core';
import { IHeadsUpLobbyPlayers } from 'src/app/data/collections/heads-up-lobby';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'teacher-avatar',
  templateUrl: './teacher-avatar.component.html',
  styleUrls: ['./teacher-avatar.component.scss']
})
export class TeacherAvatarComponent implements OnInit {

  playerInfo: IHeadsUpLobbyPlayers;
  constructor(private auth: AuthService) { }

  ngOnInit() {
    this.auth.user.subscribe(userInfo => {
      this.playerInfo = this.makeDefaultPlayerInfo();
    });
  }

  makeDefaultPlayerInfo(): IHeadsUpLobbyPlayers {
    const assignmentId = 'TEACHER-EDIT';
    const uid = this.auth.getUid();

    return {
      score: 0,
      coins: 0,
      assignmentId,
      uid,
      isPresent: true,
      displayName: this.auth.getDisplayName(),
    };
  }

}
