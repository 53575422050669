import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IQuestionConfig, IElementTypeDef, elementTypes, elementIconById, ElementType, McqDisplay, IContentElementMcq, IContentElement, TextParagraphStyle, IContentElementText, IContentElementImage, IContentElementMath, createDefaultElement, ICustomTaskSet, ICustomTaskTag, ICustomTaskComment } from '../models/index';
import { indexOf } from '../services/util';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { AngularFirestore } from '@angular/fire/firestore';
import { AuthService } from '../../core/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { serverTimestamp } from '../../data/timestamp';
import { ITask, generateDefaultTask, generateDefaultQuestionTask } from '../../data/collections/tasks.types';
import { ICustomEditorSettings } from './models/editor-settings';
import { Subscription } from 'rxjs';
import { SidepanelService } from '../../core/sidepanel.service';
import { ChatpanelService } from '../../core/chatpanel.service';
import { IAssessmentFrameworkDetail, IAssessmentFrameworkDef, IAssessmentFrameworkDimensionDetail } from './models/assessment-framework';

const CURRENT_SCHEMA_VERSION:number = 5;
@Component({
  selector: 'assessment-editor',
  templateUrl: './assessment-editor.component.html',
  styleUrls: ['./assessment-editor.component.scss']
})
export class AssessmentEditorComponent implements OnInit, OnDestroy {

  questions: IQuestionConfig[];
  currentQuestion:IQuestionConfig;
  projectId:string;
  currentTaskId:string;
  customTaskSetId:string;
  isLoading:boolean = true;
  showAdvancedSettings:boolean = false;
  isEditingSetName:boolean = false;
  currentQuestionLabel = new FormControl();
  currentSetName = new FormControl();
  currentQuestionNotes = new FormControl();
  labelCounter = 0;
  isSaveChangedRequired: boolean = false;
  existingFakeSaveDelayTimeout;
  questionTextSize = new FormControl('1.5');
  elementTypes:IElementTypeDef[] = elementTypes;
  lastBackupId:string;
  saveConflictWarningGiven:boolean;
  personalEditorSettings = {};
  advancedSettings = [
    {prop: 'isBoosterPackMappingsEnabled',   caption: 'Booster Pack Mappings'},
    {prop: 'isEditorHidden',   caption: 'Disable Content Editing'},
    {prop: 'isMetaScreensEnabled',   caption: 'Meta Info Screens'},
    {prop: 'isSolutionBoxesEnabled', caption: 'Solution Boxes (coming soon)'},
    {prop: 'isLogbookEnabled',       caption: 'Logbook (coming soon)'},
    {prop: 'isTableBlocksEnabled',   caption: 'Table Blocks (coming soon)'},
    {prop: 'isTagsEnabled',          caption: 'Question Tags'},
    {prop: 'isCurriculumEnabled',    caption: 'Curriculum Linkage (coming soon)'},
    {prop: 'isCommentsEnabled',      caption: 'Comments (coming soon)'},
    {prop: 'isQuestionStatusEnabled',   caption: 'Question Ready'},
    {prop: 'isPsychometricsEnabled',    caption: 'Psychometrics Mode'},
    {prop: 'isDistractorsEnabled',      caption: 'Insert Distractor Info'},
  ];

  private qNoteSub:Subscription;
  private qLabelSub:Subscription;
  private routeSub:Subscription;
  private customSettingsSub:Subscription;
  private customTaskSetSub:Subscription;
  
  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private sidePanel: SidepanelService,
    private chatPanel: ChatpanelService,
  ) {
    this.questions = [];
  }

  ngOnInit() {
    this.sidePanel.activate();
    this.chatPanel.activate();
    this.qNoteSub = this.currentQuestionNotes.valueChanges.subscribe( e => {
      this.currentQuestion.notes = this.currentQuestionNotes.value;
    });
    this.qLabelSub = this.currentQuestionLabel.valueChanges.subscribe( e => {
      this.currentQuestion.label = this.currentQuestionLabel.value;
    });
    this.loadPersonalEditorSettings()
    this.routeSub = this.route.params.subscribe(params => {
      this.projectId = params['projectId'];
      this.customTaskSetId = params['customTaskSetId'];
      this.loadCustomTaskSet();
    });
    window.addEventListener("beforeunload", this.onbeforeunload);
  }

  ngOnDestroy(){
    if (this.qNoteSub){ this.qNoteSub.unsubscribe(); }
    if (this.qLabelSub){ this.qLabelSub.unsubscribe(); }
    if (this.routeSub){ this.routeSub.unsubscribe(); }
    if (this.customSettingsSub){ this.customSettingsSub.unsubscribe(); }
    if (this.customTaskSetSub){ this.customTaskSetSub.unsubscribe(); }
    if (this.currentQuestionTagListener){ this.currentQuestionTagListener.unsubscribe(); }
    if (this.currentQuestionCommentListener){ this.currentQuestionCommentListener.unsubscribe(); }
    window.removeEventListener("beforeunload", this.onbeforeunload);
  }

  isSidePanelVisible(){
    return this.sidePanel.getVisibilityStatus();
  }
  isSidePanelExpanded(){
    return this.sidePanel.getExpandedStatus();
  }

  isContentEditingDisabled(){
    return this.personalEditorSettings['isEditorHidden'];
  }

  onbeforeunload = (e) => {
    e.preventDefault();
    e.returnValue = '';
  }

  // debug(t){
  //   console.log(t)
  //   return t;
  // }


  loadPersonalEditorSettings(){
    this.customSettingsSub = this.afs.doc<ICustomEditorSettings>('customEditorSettings/'+this.auth.getUid()).valueChanges().subscribe( data => {
      if (data){
        this.personalEditorSettings = data;
      }
    })
  }
  setPersonalEditorSetting(settingProp:string, val:boolean){
    let payload = {};
    payload[settingProp] = val;
    this.afs.doc<ICustomEditorSettings>('customEditorSettings/'+this.auth.getUid()).set(payload, {merge:true});
  }


  loadCustomTaskSet(){
    // let sub = this.afs.doc<ICustomTaskSet>('customTaskSets/'+this.customTaskSetId).get().subscribe( (res) => {
    let sub = this.customTaskSetSub = this.afs.doc<ICustomTaskSet>('customTaskSets/'+this.customTaskSetId).snapshotChanges().subscribe( res => {
      let data:ICustomTaskSet = res.payload.data();
      let lastBackupId = data.__backup__id;
      if (!this.isLoading){
        if ( (lastBackupId !== this.lastBackupId) && (!this.saveConflictWarningGiven) ){
          this.saveConflictWarningGiven = true;
          alert('This question set was just saved by: '+data.__backup__userDisplayName+'. You are working on the same page and may be saving over each other\'s work.');
        }
        return;
      }
      this.lastBackupId = lastBackupId;

      this.currentTaskId = data.taskId;
      this.currentAsmtFrwkId = data.assessmentFrameworkId;

      // let data:ICustomTaskSet = snap.data();
      if (data.questions){
        this.questions = JSON.parse(<string>data.questions);
      }
      if (this.questions.length === 0){
        this.createNewQuestion();
      }
      if (data.labelCounter){
        this.labelCounter = data.labelCounter;
      }
      this.currentSetName.setValue(data.name);
      this.isLoading = false;
      this.selectQuestion(this.questions[0])
      this.fakeNeedForSaveAgain();
    })
  }

  saveChanges(){
    this.isSaveChangedRequired = true;
    // temporary... need to split into their own entries
    this.ensureTaskSetIsLinked( () => {
      this.ensureQuestionsAreLinked( () => {
        this.ensureQuestionsEntryIds();
        this.saveMainBlob((res) => {
          this.isSaveChangedRequired = false;
          this.fakeNeedForSaveAgain();
        })
      });
    })
  }

  ensureQuestionsEntryIds(){
    this.questions.forEach(question => {
      const tracker = {
        counter: question.entryIdCounter || 1,
        entryOrder: []
      }
      this.ensureQuestionNodesEntryIds(question.content, tracker);
      question.entryIdCounter = tracker.counter;
      question.entryOrder = tracker.entryOrder;
    })
  }

  ensureQuestionNodesEntryIds(content:IContentElement[], tracker:{counter:number, entryOrder:number[]}){
    if (content){
      content.forEach(contentElement => {
        switch(contentElement.elementType){
          case ElementType.TEXT: 
            let isAdvancedList = ((<IContentElementText>contentElement).paragraphStyle === TextParagraphStyle.ADVANCED_INLINE);
            let hasAdvancedList = (<IContentElementText>contentElement).advancedList;
            if ( isAdvancedList && hasAdvancedList){
                this.ensureQuestionNodesEntryIds((<IContentElementText>contentElement).advancedList, tracker);
            }
          break;
          case ElementType.INPUT: 
          case ElementType.MCQ: 
          case ElementType.ORDER: 
            if (!contentElement.entryId){
              contentElement.entryId = tracker.counter;
              tracker.counter ++;
            }
            tracker.entryOrder.push(contentElement.entryId);
          break;
        }
      })
    }
  }

  mergeIntoTaskSetDoc(data:ICustomTaskSet, then:(res)=>void){
    return this.getTaskSetDoc()
      .set(data, {merge:true})
      .then(then);
  }
  getTaskSetDoc(){
    return this.afs
      .doc<ICustomTaskSet>('customTaskSets/'+this.customTaskSetId);
  }

  checkIsAllReady(){
    let isAllReady = true;
    this.questions.forEach(question => {
      if (!question.isInfoSlide && !question.isReady){
        isAllReady = false;
      }
    })
    return isAllReady;
  }


  saveAssessmentFrameworkId(){
    this.afs
      .collection('customTaskSetsFrameworkAssignmentBackups')
      .add({
        taskSetId: this.customTaskSetId,
        assessmentFrameworkId: this.currentAsmtFrwkId,
        timestamp: serverTimestamp(),
      })
    this.afs
      .doc('customTaskSets/'+this.customTaskSetId)
      .update({
        assessmentFrameworkId: this.currentAsmtFrwkId
      })
  }

  saveMainBlob(then:(res:any)=>void){
    let questionsJson = JSON.stringify(this.questions);
    // console.log('questionsJson', questionsJson)
    let lastTouchedBy = this.auth.getUid();
    let isReady = this.checkIsAllReady();
    this.saveConflictWarningGiven = false;
    let payload:ICustomTaskSet = {
      __id: this.customTaskSetId, 
      name: this.currentSetName.value,
      isReady,
      labelCounter: this.labelCounter,
      taskId: this.currentTaskId,
      questions: questionsJson,
      lastTouchedBy,
      schemaVersion: CURRENT_SCHEMA_VERSION,
      timeLastSaved: serverTimestamp(),
    }
    if (this.currentAsmtFrwkId){
      payload.assessmentFrameworkId = this.currentAsmtFrwkId;
    }
    this.afs
      .collection<ICustomTaskSet>('customTaskSetsBackups')
      .add(payload)
      .then(res => {
        this.lastBackupId = res.id;
        this.mergeIntoTaskSetDoc({
          ... payload,
          __backup__id: this.lastBackupId,
          __backup__userDisplayName: this.auth.getDisplayName(),
        }, (res)=>{
          let questionTaskIds = this.extractQuestionTaskIds();
          // console.log('idList', questionTaskIds);
          this.afs
            .doc<ITask>('tasks/'+this.currentTaskId)
            .set({
              name: this.currentSetName.value,
              questions: questionTaskIds,
            }, {merge:true})
            .then (then)
        })
      });
  }

  extractQuestionTaskIds(){
    const taskIds:string[] = [];
    this.questions.forEach(question => {
      if (!question.isInfoSlide && question.taskId){
        taskIds.push(question.taskId);
      }
    })
    return taskIds;
  }

  ensureTaskSetIsLinked( then:()=>void ){
    if (this.currentTaskId){
      then()
    }
    else{
      this.afs.collection<ITask>('tasks')
        .add( 
          generateDefaultTask(
            this.currentSetName.value, 
            {
              isUserGeneratedTask:true, 
              customTaskSetId: this.customTaskSetId
            } 
          )
        )
        .then( (res)=>{
          let taskId = res.id;
          this.currentTaskId = taskId;
          this.mergeIntoTaskSetDoc({taskId}, then);
        });
    }
  }


  ensureLocalTaskIds(){
    if (!this.currentQuestion.localTaskId){
      let minId = 1;
      this.questions.forEach(question => {
        if (question.localTaskId){
          minId = Math.max(minId, question.localTaskId)
        }
      })
      this.questions.forEach(question => {
        if (!question.localTaskId){
          minId ++;
          question.localTaskId = minId;
        }
      })
    }
  }

  currentQuestionTags = [];
  currentQuestionTagListener:Subscription;
  currentQuestionComments = [];
  currentQuestionCommentListener:Subscription;

  listenToTags(){
    this.ensureLocalTaskIds();
    if (this.currentQuestionTagListener){ this.currentQuestionTagListener.unsubscribe(); }
    this.currentQuestionTagListener = this.afs.collection<ICustomTaskTag>('customTaskTags', ref =>{
        return ref.where('customTaskSetId', '==', this.customTaskSetId)
                 .where('localTaskId', '==', this.currentQuestion.localTaskId)
      })
      .snapshotChanges()
      .subscribe( response => {
        this.currentQuestionTags = response.map(entry => {
          const data = entry.payload.doc.data();
          return {
            __id: entry.payload.doc.id, 
            ...data
          }
        });
      })
  }

  listenToComments(){
    this.ensureLocalTaskIds();
    if (this.currentQuestionCommentListener){ this.currentQuestionCommentListener.unsubscribe(); }
    this.currentQuestionCommentListener = this.afs.collection<ICustomTaskTag>('customTaskComments', ref =>{
      return ref.where('customTaskSetId', '==', this.customTaskSetId)
                .where('localTaskId', '==', this.currentQuestion.localTaskId)
    })
    .snapshotChanges().subscribe( response => {
      this.currentQuestionComments = response.map(entry => {
        const doc = entry.payload.doc;
        return {
          __id: doc.id, 
          ... doc.data()
        }
      });
    })
  }

  assignTag(){
    let caption = prompt('Please type in your tag');
    if (caption){
      caption = caption.trim();
      this.ensureLocalTaskIds();
      this.afs.collection<ICustomTaskTag>('customTaskTags').add({
        caption,
        customTaskSetId: this.customTaskSetId,
        localTaskId: this.currentQuestion.localTaskId,
        createdBy: this.auth.getUid(),
        timestamp: serverTimestamp(),
      })
    }
    // .then(res => { res.id })
  }

  removeTag(tag:ICustomTaskTag){
    if (confirm('Are you sure?')){
      this.afs.doc<ICustomTaskTag>('customTaskTags/'+tag.__id).delete();
    }
  }


  createNewConversation(){
    let caption = prompt('Start the conversation by entering your message');
    if (caption){
      caption = caption.trim();
      this.ensureLocalTaskIds();
      this.afs.collection<ICustomTaskComment>('customTaskComments').add({
        caption,
        customTaskSetId: this.customTaskSetId,
        localTaskId: this.currentQuestion.localTaskId,
        createdByName: this.auth.getDisplayName(),
        createdByUid: this.auth.getUid(),
        timestamp: serverTimestamp(),
      })
    }
    // .then(res => { res.id })
  }

  ensureQuestionsAreLinked( then:()=>void ){
    // console.log('ensureQuestionsAreLinked')
    const updates:any[] = [];
    this.questions.forEach((question:IQuestionConfig, i) => {
      // console.log(i, question.taskId)
      if (!question.taskId && !question.isInfoSlide){
        updates.push(
          this.afs.collection<ITask>('tasks')
            .add( 
              generateDefaultQuestionTask(
                this.currentTaskId, 
                {
                  isUserGeneratedTask:true, 
                  customTaskSetId: this.customTaskSetId
                } 
              )
            )
            .then( (res)=>{
              let taskId = res.id;
              question.taskId = taskId;
              // console.log(i, question.taskId, res)
              return
            })
        )
      }
    })
    if (updates.length === 0){
      then();
    }
    else{
      Promise.all(updates).then( then );
    }
  }

  fakeNeedForSaveAgain(){
    if (this.existingFakeSaveDelayTimeout){
      clearTimeout(this.existingFakeSaveDelayTimeout);
    }
    this.existingFakeSaveDelayTimeout = setTimeout(()=> {
      this.isSaveChangedRequired = true;
    }, 5000)
  }

  createNewInfoSlide(){
    this.createNewQuestion(true);
  }

  createNewQuestion(isInfoSlide:boolean=false){
    let i = indexOf(this.questions, this.currentQuestion);
    this.labelCounter ++;
    let newQuestion:IQuestionConfig = {
      content: [],
      isInfoSlide,
      localTaskId: this.labelCounter,
      label: ''+this.labelCounter
    }
    if (i === -1){
      this.questions.push(newQuestion)  
    }
    else{
      this.questions.splice(i+1, 0, newQuestion)  
    }
    this.selectQuestion(newQuestion);
  }

  selectQuestion(targetQuestion:IQuestionConfig){
    this.currentQuestion = targetQuestion;
    this.currentQuestionLabel.setValue(this.currentQuestion.label);
    this.currentQuestionNotes.setValue(this.currentQuestion.notes);
    this.listenToTags();
    this.listenToComments();
  }



  getIconByElementTypeId(elementTypeId:string){
    return elementIconById.get(elementTypeId);
  }

  removeCurrentQuestion(){
    if (window.confirm('Are you sure you want to remove this question?')){
      let i = indexOf(this.questions, this.currentQuestion);
      if (i !== -1){
        this.questions.splice(i, 1);
        this.currentQuestion = null;
      }
    }
  }

  duplicateCurrentQuestion(){
    let cloneTarget = this.currentQuestion;
    let i = indexOf(this.questions, this.currentQuestion);
    this.createNewQuestion();
    this.currentQuestion.content = _.cloneDeep(cloneTarget.content);
  }

  duplicateQuestion(question){
    this.selectQuestion(question);
    this.duplicateCurrentQuestion();
  }

  removeElement(content:IQuestionConfig[], element:IQuestionConfig){
    if (window.confirm('Remove this block?')){
      let i = indexOf(content, element);
      if (i !== -1){
        content.splice(i, 1)
      }
    }
  }

  drop(arr:any, event: CdkDragDrop<string[]>) {
    // console.log('drop', arr)
    moveItemInArray(arr, event.previousIndex, event.currentIndex);
  }

  getCurrentContentStack(){

  }
  
  createNewStackElement(elementType:string){
    let newElement = createDefaultElement(elementType);
    this.currentQuestion.content.push(newElement)
    console.log(this.currentQuestion)
  }





  // assessment framework stuff

  isPsychometricInited:boolean;
  currentAsmtFrwkId:string;
  isPsychometricViewEnabled:boolean;
  assessmentFrameworks: IAssessmentFrameworkDef[] = [];
  activeAssessmentFramework = new FormControl();
  asmtFmrk: IAssessmentFrameworkDetail;
  activeAsmtFwrkDimensions: Array<IAssessmentFrameworkDimensionDetail[]>; // an array of arrays...
  isEditingFramework:boolean;

  switchToPsychometricView(){
    this.isPsychometricViewEnabled = true;
    if (!this.isPsychometricInited){
      this.isPsychometricInited = true;
      this.loadAssessmentFrameworks();
      this.activeAssessmentFramework.valueChanges.subscribe( () => {
        this.currentAsmtFrwkId = this.activeAssessmentFramework.value;
        console.log('loadAssessmentFrameworks', this.currentAsmtFrwkId)
        this.loadSelectedFramework(this.activeAssessmentFramework.value)
      });
      if (this.currentAsmtFrwkId){
        this.activeAssessmentFramework.setValue(this.currentAsmtFrwkId);
      }
      console.log('this.currentAsmtFrwkId', this.currentAsmtFrwkId)
    }
  }
  switchToEditorView(){
    this.isPsychometricViewEnabled = false;
  }

  saveAsmtFwrkEdits(){
    let frameworkPayloadBase:Partial<IAssessmentFrameworkDef> = {
      caption: this.asmtFmrk.caption,
      subcaption: this.asmtFmrk.subcaption,
      uidLastTouch: this.auth.getUid(), 
      timeLastTouch: serverTimestamp(),
    }
    this.afs
      .doc('assessmentFrameworks/'+this.asmtFmrk.__id)
      .update(frameworkPayloadBase);
    this.afs
      .doc('assessmentFrameworkConfig/'+this.asmtFmrk.__id)
      .update({
        ... frameworkPayloadBase,
        notes: '',
        partitions : this.asmtFmrk.partitions || [],
        primaryDimensions: this.asmtFmrk.primaryDimensions,
        secondaryDimensions: this.asmtFmrk.secondaryDimensions,
      })
  }
  saveAssessmentFramework(){
    this.saveAssessmentFrameworkId();
    if (this.isEditingFramework){
      this.saveAsmtFwrkEdits()
    }
  }

  changeQuestionCaption(question){
    let str = prompt('New question name', question.caption);
    if (str){
      question.caption = str;
    }
  }

  defineNewParameter(arr:IAssessmentFrameworkDimensionDetail[]){
    arr.push({
      name: 'New Parameter', // ex: Number Sense
      code: '', // ex: NS
      type: 'binary',
      config: {

      }
    })
  }

  loadAssessmentFrameworks(){
    this.afs
      .collection('assessmentFrameworks', ref=>ref.where('projectId', '==', this.projectId))
      .snapshotChanges()
      .subscribe( data => {
        this.assessmentFrameworks = data.map(entry =>{
          const doc = entry.payload.doc;
          return {
            ... <IAssessmentFrameworkDef>doc.data(),
            __id: doc.id, 
          }
        });
      });
  }
  loadSelectedFramework(asmtFwrkId:string){
    this.afs
      .doc('assessmentFrameworkConfig/'+asmtFwrkId)
      .get()
      .toPromise()
      .then(res=>{
        let payloadDetail:IAssessmentFrameworkDetail = <any>res.data()
        payloadDetail.__id = res.id;
        this.hoistAssessmentFrameworkForEditing(payloadDetail)
      })
  }
  hoistAssessmentFrameworkForEditing(asmtFwrk:IAssessmentFrameworkDetail){
    this.asmtFmrk = asmtFwrk;
    this.activeAsmtFwrkDimensions = [
      asmtFwrk.primaryDimensions,
      asmtFwrk.secondaryDimensions
    ]
  }
  getParamCategNaming(dimLevel:number){
    if (dimLevel === 0){
      return 'Dimension'
    }
    return 'Parameter';
  }
  renameAsmtFmwrkCaption(){
    let str = prompt('Rename', this.asmtFmrk.caption);
    if (str){
      this.asmtFmrk.caption = str;
    }
  }
  renameAsmtFmwrkSubCaption(){
    let str = prompt('Rename', this.asmtFmrk.subcaption);
    if (str){
      this.asmtFmrk.subcaption = str;
    }
  }

  questionFrameworkMap:{d1:string[], d2:string[], count:Map<string, number>};
  previewQuestionFrameworkMapping(){
    console.log('previewQuestionFrameworkMapping 1')
    this.questionFrameworkMap = {
      d1: [],
      d2: [],
      count: new Map()
    };
    let d1Def = this.asmtFmrk.primaryDimensions[0];
    let d2Def = this.asmtFmrk.primaryDimensions[1];
    let d1Tags = d1Def.config.tags;
    let d2Tags = d2Def.config.tags;
    // let d1_pre = this.questionFrameworkMap.d1 = d1Tags.map(tag => tag.code);
    let d2 = this.questionFrameworkMap.d2 = d2Tags.map(tag => tag.code);
    let joiner, joinerCode;
    this.asmtFmrk.secondaryDimensions.forEach(sec => { // grossnes....
      if (sec.code === 'D1b'){
        console.log('sec', sec)
        joinerCode = sec.code;
        joiner = sec.config.tags.map(tag => tag.code)
      }
    })
    
    let d1 = this.questionFrameworkMap.d1 = joiner;
    d1.forEach(tag1 => {
      d2.forEach(tag2 => {
        let key = tag1+'/'+tag2;
        this.questionFrameworkMap.count.set(key, 0);
      })
    })
    this.questions.forEach(question => {
      let tag1 = question['meta'][joinerCode];
      let tag2 = question['meta'][d2Def.code];
      if (tag1 && tag2){
        let key = tag1+'/'+tag2;
        let count = this.questionFrameworkMap.count.get(key);
        this.questionFrameworkMap.count.set(key, count+1);
      }
    })
    console.log('previewQuestionFrameworkMapping 2')
  }

  defineNewAssessmentFramework(){
    let uid = this.auth.getUid();
    this.asmtFmrk = null;
    let caption = prompt('Name', this.projectId);
    if (caption){
      let payloadDef:IAssessmentFrameworkDef = {
        projectId: this.projectId,
        caption,
        subcaption: 'v1',
        uidCreator: uid,
        uidLastTouch: uid, 
        timeCreated: serverTimestamp(),
        timeLastTouch: serverTimestamp(),
      }
      let payloadDetail:IAssessmentFrameworkDetail = {
        ... payloadDef,
        primaryDimensions: [],
        secondaryDimensions: [],
      }
      this.afs
        .collection('assessmentFrameworks')
        .add(payloadDef)
        .then( res => {
          let id = res.id
          payloadDetail.__id = id;
          this.afs
            .doc('assessmentFrameworkConfig/'+id)
            .set(payloadDetail)
            .then(()=>{
              this.hoistAssessmentFrameworkForEditing(payloadDetail)
            })
        })
    }
  }
}

