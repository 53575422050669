import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// // pages/views
import { StudentCalculatorComponent } from './student-calculator/student-calculator.component';
import { StudentAssignmentComponent } from './student-assignment/student-assignment.component';
import { StudentRegistrationComponent } from './student-registration/student-registration.component';
import { StudentClassActivityComponent } from './student-class-activity/student-class-activity.component';
import { StudentSkillsComponent } from './student-skills/student-skills.component';
import { StudentStoreComponent } from './student-store/student-store.component';
import { StudentExploreComponent } from './student-explore/student-explore.component';
import { StudentLeaderboardInfoComponent } from './student-leaderboard-info/student-leaderboard-info.component';
import { StudentAssignmentTaskComponent } from './student-assignment-task/student-assignment-task.component';
import { StudentClassroomsComponent } from './student-classrooms/student-classrooms.component';
import { TimeModule } from '../time/time.module';
import { UiTaskcreatorModule } from '../ui-taskcreator/ui-taskcreator.module';
import { StudentHeadsupLobbyComponent } from './student-headsup-lobby/student-headsup-lobby.component';
import { UiAvatarDisplaysModule } from '../ui-avatar-displays/ui-avatar-displays.module';
import { AnonIdComponent } from './anon-id/anon-id.component';
import { UiModule } from '../ui/ui.module';
import { StudentHeadsupLobbyCountComponent } from './student-headsup-lobby-count/student-headsup-lobby-count.component';
import { StudentHeadsupLobbyLeaderboardComponent } from './student-headsup-lobby-leaderboard/student-headsup-lobby-leaderboard.component';
import { StudentDashboardComponent } from './student-dashboard/student-dashboard.component';
import { PanelStudentAssignmentsComponent } from './panel-student-assignments/panel-student-assignments.component';
import { PanelStudentAccountComponent } from './panel-student-account/panel-student-account.component';
import { ButtonStudentCoinsComponent } from './button-student-coins/button-student-coins.component';
import { ButtonStudentCheckpointsComponent } from './button-student-checkpoints/button-student-checkpoints.component';
import { ButtonStudentDailyrewardsComponent } from './button-student-dailyrewards/button-student-dailyrewards.component';
import { ClassroomPlayComponent } from './classroom-play/classroom-play.component';
import { ClassroomPlayLoginComponent } from './classroom-play-login/classroom-play-login.component';
import { StudentBoosterPacksComponent } from './student-booster-packs/student-booster-packs.component';
import { StudentBoosterPackComponent } from './student-booster-pack/student-booster-pack.component';
import { StudentBoosterPackItemComponent } from './student-booster-pack-item/student-booster-pack-item.component';
import { ItemPositionerComponent } from './item-positioner/item-positioner.component';
import { ProgressRingComponent } from './progress-ring/progress-ring.component';
import { ItemNewDefComponent } from './item-new-def/item-new-def.component';
import { ItemMapUploadComponent } from './item-map-upload/item-map-upload.component';
import { StudentBoosterOptionsComponent } from './student-booster-options/student-booster-options.component';
import { StudentLeaderboardWindowComponent } from './student-leaderboard-window/student-leaderboard-window.component';
import { StudentLeaderboardsComponent } from './student-leaderboards/student-leaderboards.component';
import { ItemMapOverlaysComponent } from './item-map-overlays/item-map-overlays.component';
import { CaptureImageComponent } from './capture-image/capture-image.component';
import { StudentLeaderboardCertificateComponent } from './student-leaderboard-certificate/student-leaderboard-certificate.component';
// import { ClassroomPlayPasswordComponent } from './classroom-play-password/classroom-play-password.component';
// import { ClassroomPlayLoginPasswordComponent } from './classroom-play-login-password/classroom-play-login-password.component';

// // smaller components


@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    ReactiveFormsModule,
    UiTaskcreatorModule,
    TimeModule,
    UiModule,
    UiAvatarDisplaysModule,
  ],
  declarations: [
    StudentCalculatorComponent,
    StudentAssignmentComponent,
    StudentRegistrationComponent,
    StudentClassActivityComponent,
    StudentSkillsComponent,
    StudentStoreComponent,
    StudentExploreComponent,
    StudentLeaderboardWindowComponent,
    StudentLeaderboardInfoComponent,
    StudentAssignmentTaskComponent,
    StudentClassroomsComponent,
    StudentHeadsupLobbyComponent,
    AnonIdComponent,
    StudentHeadsupLobbyCountComponent,
    StudentHeadsupLobbyLeaderboardComponent,
    StudentDashboardComponent,
    PanelStudentAssignmentsComponent,
    PanelStudentAccountComponent,
    ButtonStudentCoinsComponent,
    ButtonStudentCheckpointsComponent,
    ButtonStudentDailyrewardsComponent,
    ClassroomPlayComponent,
    ClassroomPlayLoginComponent,
    StudentBoosterPacksComponent,
    StudentBoosterPackComponent,
    StudentBoosterPackItemComponent,
    ItemPositionerComponent,
    ProgressRingComponent,
    ItemNewDefComponent,
    ItemMapUploadComponent,
    StudentBoosterOptionsComponent,
    StudentLeaderboardsComponent,
    StudentLeaderboardCertificateComponent,
    ItemMapOverlaysComponent,
    CaptureImageComponent,
    // ClassroomPlayPasswordComponent,
    // ClassroomPlayLoginPasswordComponent
  ],
  exports: [
    StudentCalculatorComponent,
    StudentAssignmentComponent,
    StudentRegistrationComponent,
    StudentClassActivityComponent,
    StudentSkillsComponent,
    StudentStoreComponent,
    StudentExploreComponent,
    StudentLeaderboardWindowComponent,
    StudentLeaderboardsComponent,
    StudentLeaderboardCertificateComponent,
  ]
})
export class UiStudentModule {}
