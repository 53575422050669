import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import { Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ICurriculum } from '../../data/collections/curriculums.types';
import { serverTimestamp } from '../../data/timestamp';
import { CollectionsService } from '../../data/collections.service';
import { map, filter } from "rxjs/operators";
import { FormMode, ListMode, IListMode } from '../../data/list-forms.types';
import { ICurriculumOutcome } from '../../data/collections/curriculum-outcomes.types';
import { IOrganization } from '../../data/collections/organizations.types';
import { IUser } from '../../data/collections/users.types';
import { IClassroom } from '../../data/collections/classrooms.types';
import { AuthService } from '../../core/auth.service';

const ACCOUNT_LOOKUP_LIMIT = 100;
const enum FilterSelectGenericOption {
  ANY = 'any',
  NONE = 'none',
}
@Component({
  selector: 'form-teacher-selector',
  templateUrl: './form-teacher-selector.component.html',
  styleUrls: ['./form-teacher-selector.component.scss']
})
export class FormTeacherSelectorComponent implements OnInit {

  orgSearchString = new FormControl('');
  activeOrg = new FormControl('');
  teacherSearchString = new FormControl('');
  activeTeacher = new FormControl('');
  newClassroomName = new FormControl('');
  newTeachers = new FormControl('');
  newClassroomId:string;
  organizations$: Observable<IOrganization[]>;
  teachers$: Observable<IUser[]>;

  private organizationCollection: AngularFirestoreCollection<IOrganization>;
  private teacherCollection: AngularFirestoreCollection<IUser>;
  private orgSub:Subscription;
  private teacherSub:Subscription;

  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
  ) {
    
  }

  ngOnInit() {
    this.organizationCollection = this.afs.collection<IOrganization>('organizations');
    this.organizations$ = CollectionsService.getValuesIdentified(this.organizationCollection);
    this.orgSub = this.activeOrg.valueChanges.subscribe(this.onOrgChange);
  }

  ngOnDestroy() {
    if (this.orgSub) { this.orgSub.unsubscribe(); }
    if (this.teacherSub) { this.teacherSub.unsubscribe(); }
  }

  updateTeacherFilter(){
    this.teacherCollection = this.afs.collection<IUser>('users', ref => {
      return ref
        .where('ui', '==', 'teacher')
        .limit(ACCOUNT_LOOKUP_LIMIT)
    });
    this.teachers$ = CollectionsService.getValuesIdentified(this.teacherCollection);
    this.teacherSub = this.teachers$.subscribe(data => console.log('teacher data', data))
  }

  createTeacherBatch(data){
    let entries = JSON.parse(data);
    entries.forEach(entry => {
      let email = entry.uuid //+'+account@vretta.com';
      let password = 'vretta';
      this.auth.emailSignUp(email, password).then(value =>{
        console.log(value)
      })
    });
  }

  createNewClassroom(classroomName:string){
    this.newClassroomId = '';
    this.afs.collection<IClassroom>('classrooms').add({
      name: classroomName,
      timeCreated: serverTimestamp(),
      timeLastTouched: serverTimestamp(),
    }).then( val => {
      this.newClassroomId = val.id;
    })
  }

  onOrgChange = (orgSelections: Array<IOrganization | string>) => {
    const orgSelection = orgSelections[0];
    console.log('onOrgChange', orgSelection);
    if (orgSelection === FilterSelectGenericOption.ANY){
      this.updateTeacherFilter();
    }
    else if (orgSelection === FilterSelectGenericOption.NONE){
      this.updateTeacherFilter();
    }
    else{

    }
  }

}
