import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'parent-children',
  templateUrl: './parent-children.component.html',
  styleUrls: ['./parent-children.component.scss']
})
export class ParentChildrenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
