import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'admin-instructional-curriculums',
  templateUrl: './admin-instructional-curriculums.component.html',
  styleUrls: ['./admin-instructional-curriculums.component.scss']
})
export class AdminInstructionalCurriculumsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
