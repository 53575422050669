import { DocumentReference } from "@angular/fire/firestore";
import { FirebaseTimestamp, FirebaseUID } from "../base.types";

export interface IUser {
    uid: string;
    email?: string | null;
    photoURL?: string;
    displayName?: string;
    firstName?: string;
    lastName?: string;
    username?: string;
    foreignUid?: string;
    timeCreated?: any, // deprecated
    timeJoined?: any,
  
    providerId?: string;

    ui?: UserUi;
    trialId?:string,
    anchorCurricId?: string;
    isTestUser?: boolean;
    instScopeIds?:string[],
    familiesAsParent?: string[];
    familiesAsChild?: string[];
    classroomsAsTeacher?: string[];
    classroomsAsStudent?: string[];
}

export enum UserUi {
    TEACHER = 'teacher',
    STUDENT = 'student',
    PARENT = 'parent',
    TRIAL_COORD = 'trial-coord',
    DISTRICT_COORD = 'district-coord',
    ADMIN = 'admin',
    CREATOR = 'creator',
    ADMIN_SUPPORT = 'admin_support',
    ADMIN_INSTRUCTIONAL = 'admin_instructional',
    ADMIN_GAMIFICATION = 'admin_gamification',
    ADMIN_SALES = 'admin_sales',
  }