import { Component, OnInit, Input } from '@angular/core';
import { IContentElementInput, InputFormat, QuestionState, IEntryStateInputText, IEntryStateInputNumber, IEntryStateInputMath } from '../models';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'element-render-input',
  templateUrl: './element-render-input.component.html',
  styleUrls: ['./element-render-input.component.scss']
})
export class ElementRenderInputComponent implements OnInit {

  @Input() element:IContentElementInput;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;

  numberInput = new FormControl('');
  textInput = new FormControl('');
  isStarted:boolean;
  latexCapture = {latexInput:''};

  constructor() { }

  ngOnInit() {
    if (this.element.startingLatex){
      this.latexCapture.latexInput = this.element.startingLatex
    }
    this.numberInput.valueChanges.subscribe( () => this.updateState() )
    this.textInput.valueChanges.subscribe( () => this.updateState() )
  }

  isFormatTypeNumber(){ return this.element.format === InputFormat.NUMBER }
  isFormatTypeFraction(){ return this.element.format === InputFormat.FRACTION }
  isFormatTypeAlgebra(){ return this.element.format === InputFormat.ALGEBRA }
  isFormatTypeRatio(){ return this.element.format === InputFormat.RATIO }
  isFormatTypeText(){ return this.element.format === InputFormat.TEXT }


  getEntryState(){
    console.log('getEntryState', this.element.format, InputFormat.TEXT,this.textInput.value)
    switch (this.element.format){
      case InputFormat.TEXT: 
        return <IEntryStateInputText>{
          type: 'input-longtext',
          isCustomGrading: true,
          isStarted: this.isStarted,
          isFilled: this.isStarted,
          str: this.textInput.value,
        }
      case InputFormat.NUMBER: 
        return <IEntryStateInputNumber>{
          type: 'input-number',
          isCorrect: this.isNumberCorrect(),
          isStarted: this.isStarted,
          isFilled: this.isStarted,
          value: this.numberInput.value,
        }
      case InputFormat.ALGEBRA: 
        return <IEntryStateInputMath>{
          type: 'input-algebra',
          isCustomGrading: true,
          isStarted: this.isStarted,
          isFilled: this.isStarted,
          latex: this.latexCapture.latexInput,
        }
    }
    return <IEntryStateInputText>{
      type: 'input-longtext',
      isCustomGrading: true,
      isStarted: false,
      isFilled: false,
      str: '{ERROR}',
    }
  }

  isNumberCorrect(){
    if (this.numberInput.value !== null && this.numberInput.value != ''){
      let valInput = parseFloat(this.numberInput.value);
      let valExpected = parseFloat(this.element.value);
      if( Math.abs(valExpected - valInput) < 0.001 ){
        return true
      }
    }
    return false;
  }

  updateState(){
    this.questionState.set(this.element.entryId, this.getEntryState());
  }

}
