import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { WhitelabelService } from '../domain/whitelabel.service';
import { AuthService } from './auth.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatpanelService {

  private signinSub:Subscription;
  private zdWidget;
  private isLauncherVisible:boolean = true;

  constructor(
    private auth:AuthService,
    private whiteLabel:WhitelabelService,
  ) {
    let widgetWatchInterval = setInterval(()=>{
      // console.log('widgetWatchInterval')
      if (this.fetchWidget()){
        if (!this.isLauncherVisible){
          this.deactivate();
        }
        clearInterval(widgetWatchInterval);
      }
    }, 500);
    try {
      this.signinSub = this.auth.user.subscribe(userInfo => {
        if (userInfo){
          let firstName ='', lastName ='', email ='';
          let status = 'LOGGED_IN'
          // this.fcWidget.user.setProperties({status, firstName, lastName, email})
          // this.fcWidget.setExternalId(userInfo.uid);
        }
        else {
          let status = 'LOGGED_OUT'
          // this.fcWidget.user.setProperties({status})
        }
      });
    }
    catch(e){
      console.warn('trouble setting up the support chat')
    }
  }

  setLocale(locale:string){
    // should set up a subscription
    // if (this.fcWidget){
    //   this.fcWidget.user.setLocale(locale);
    // }
  }

  fetchWidget(){
    return this.zdWidget = window.document.getElementsByClassName('zEWidget-launcher')[0];
  }
  activate(){
    this.isLauncherVisible = true;
    if (this.fetchWidget()){
      this.zdWidget.style.display = 'block';
    }
  }
  deactivate(){
    this.isLauncherVisible = false;
    if (this.fetchWidget()){
      this.zdWidget.style.display = 'none';
    }
  }
  expand(){
    // if (this.fcWidget){
    //   this.fcWidget.open();
    // }
  }
  collapse(){
    // if (this.fcWidget){
    //   this.fcWidget.open();
    // }
  }
}
