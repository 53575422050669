import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import {
  AngularFireStorage,
  AngularFireUploadTask
} from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import {BrowserModule, DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'
import { FormControl } from '@angular/forms';
import { BoosterpacksService } from '../boosterpacks.service';
import { IBoosterPack, IOverlayImage, IImage } from '../../data/cambridge/types';


@Component({
  selector: 'item-map-overlays',
  templateUrl: './item-map-overlays.component.html',
  styleUrls: ['./item-map-overlays.component.scss']
})
export class ItemMapOverlaysComponent implements OnInit {

  @Input() boosterPackId:string;
  @Output() overlayFetch:EventEmitter<IOverlayImage[]> = new EventEmitter();

  boosterPackSub: Subscription;
  boosterPackInfo: IBoosterPack;
  overlayImages: IImage[];

  constructor(
    private afs: AngularFirestore,
    private bps: BoosterpacksService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    if (this.boosterPackId){
      this.loadCurrentBoosterPackInfo();
    }
  }

  ngOnDestroy(){
  }

  loadCurrentBoosterPackInfo(){
    this.boosterPackSub = this.bps
      .getBoosterPackSummary(this.boosterPackId)
      .subscribe( (boosterPackInfo:IBoosterPack) => {
        this.boosterPackInfo = boosterPackInfo;
        this.overlayImages = this.boosterPackInfo.overlayImages || [];
      })
  }

  insertNewImage(){
    this.overlayImages.push({
      url: '',
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    })
  }

  save(){
    this.afs
      .doc('boosterPacks/'+this.boosterPackId)
      .update({
        overlayImages: this.overlayImages
      })
  }

}
