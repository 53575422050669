import * as TWEEN from '@tweenjs/tween.js';
import { PixiArmatureDisplay } from './DragonAvatar/DragonInterfaces';

export enum CustomAnimation {
  BOUNCE = 'bounce',
  FLIP = 'flip',
}

function nullFunc () {}

export function playDragonAnimationOnce(object: PixiArmatureDisplay, animation: string, onComplete?: () => void) {
  let tempListener = () => {
    object.animation.play('Idle1');
    object.removeDBEventListener('complete', tempListener);

    onComplete();
  }
  
  object.addDBEventListener('complete',tempListener);
  object.animation.play(animation,1);
}

export function playCustomAnimation(object: PIXI.DisplayObject, animation: CustomAnimation, onComplete?: () => void) {
  onComplete = onComplete || nullFunc;

  if (animation === CustomAnimation.BOUNCE) {
    let baseY = object.y;
    let baseRot = object.rotation;
    let tweenBounceDown3 = new TWEEN.Tween(object).to({y: baseY}, 300).easing(TWEEN.Easing.Quadratic.In);
    let tweenBounceUp3 = new TWEEN.Tween(object).to({y: baseY - 50}, 300).easing(TWEEN.Easing.Quadratic.Out).chain(tweenBounceDown3);
    let tweenBounceDown2 = new TWEEN.Tween(object).to({y: baseY}, 300).easing(TWEEN.Easing.Quadratic.In).chain(tweenBounceUp3);
    let tweenBounceUp2 = new TWEEN.Tween(object).to({y: baseY - 50}, 300).easing(TWEEN.Easing.Quadratic.Out).chain(tweenBounceDown2);
    let tweenBounceDown = new TWEEN.Tween(object).to({y: baseY}, 300).easing(TWEEN.Easing.Quadratic.In).chain(tweenBounceUp2);
    let tweenBounceUp = new TWEEN.Tween(object).to({y: baseY - 50}, 300).easing(TWEEN.Easing.Quadratic.Out).chain(tweenBounceDown).start();

    let tweenRotCenter = new TWEEN.Tween(object).to({rotation: baseRot}, 600).onComplete(onComplete);
    let tweenRotRight = new TWEEN.Tween(object).to({rotation: baseRot - Math.PI / 6}, 600).chain(tweenRotCenter);
    let tweenRotLeft = new TWEEN.Tween(object).to({rotation: baseRot + Math.PI / 6}, 600).chain(tweenRotRight).start();
    // tweenRotLeft.chain(tweenRotRight, tweenRotCenter).start()
    // tweenBounceUp.repeat(1).start();
  } else if (animation === CustomAnimation.FLIP) {
    let baseY = object.y;
    let baseRot = object.rotation;
    let tweenBounceDown = new TWEEN.Tween(object).to({y: baseY}, 300).easing(TWEEN.Easing.Quadratic.In);
    let tweenBounceUp = new TWEEN.Tween(object).to({y: baseY - 100}, 300).easing(TWEEN.Easing.Quadratic.Out).chain(tweenBounceDown).start();
    let tweenRotate = new TWEEN.Tween(object).to({rotation: baseRot - Math.PI * 2}, 600).start().onComplete(() => {
      object.rotation = baseRot;
      onComplete();
    });
  } else {
    // stretchy?
    let baseX = object.scale.x;
    let baseY = object.scale.y;
    let tween2 = new TWEEN.Tween(object.scale).to({ x: baseX, y: baseY }, 1000).easing(TWEEN.Easing.Back.InOut).start().onComplete(onComplete);
    let tween = new TWEEN.Tween(object.scale).to({ x: baseX * 0.5, y: baseY * 1.5 }, 1000).easing(TWEEN.Easing.Back.InOut).chain(tween2).start();
  }
}

export function popAndFade(object: PIXI.DisplayObject, onComplete?: () => void) {
  let scale = {x: object.scale.x, y: object.scale.y};

  object.scale.set(0, 0);
  let tween = new TWEEN.Tween(object.scale).to(scale, 500).easing(TWEEN.Easing.Back.Out).start();
  tween = new TWEEN.Tween(object).delay(3000).to({ alpha: 0 }, 1000).start().onComplete(() => {
    object.destroy();
    if (onComplete) {
      onComplete();
    }
  });
}