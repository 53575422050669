import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { IUser } from '../data/collections/users.types';
import { serverTimestamp } from '../data/timestamp';

export interface IForeignUserData {
  foreignUid?: string;
  foreignClassId?: string;
  foreignClassType?: string;
  district?: string;
  displayName?: string;
}

const constructUsername = (base: string) => {
  return base + '.mtic3.foreign@vretta.com';
};

const constructPassword = (base: string) => {
  return 'aaaaaa_' + base;
};

@Injectable({
  providedIn: 'root'
})

export class ForeignUserService {

  private foreignUserData: IForeignUserData;

  constructor(
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private afs: AngularFirestore,
  ) { }

  isForeign():boolean {
    return <boolean>(this.foreignUserData && true);
  }

  getForeignUserData(): IForeignUserData {
    // console.log('this.foreignUserData', this.foreignUserData)
    return this.foreignUserData || {};
  }

  setForeignUserData(data: IForeignUserData) {
    this.foreignUserData = data;
  }


  //// Foreign Auth ////

  foreignLogin = (foreignData: IForeignUserData) => {
    let username = constructUsername(foreignData.foreignUid);
    let password = constructPassword(foreignData.foreignUid);
    let displayName = foreignData.displayName;
    console.warn('error below is due to race condition associated with early signout (before the account has been validated) and should be harmless.\n\n\n', 'FirebaseError: [code=permission-denied]: Missing or insufficient permissions.')
    return this.auth
      .signOut(false)
      .then( ()=>{
        return this.auth
        .emailLogin(username, password)
        .catch(error => {
          return this.auth
          .emailSignUp(username, password, displayName)
          .then(uid => {
            return this.afs
              .doc('users/' + uid)
              .set({
                uid: uid,
                foreignUid: foreignData.foreignUid,
                timeJoined: serverTimestamp(),
              }, {merge: true})
          })
        });
      })
  }
}
