import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

// // pages/views
import { TeacherClassroomsComponent } from './teacher-classrooms/teacher-classrooms.component';
import { TeacherLeaderboardsComponent } from './teacher-leaderboards/teacher-leaderboards.component';
import { TeacherClassroomStudentsComponent } from './teacher-classroom-students/teacher-classroom-students.component';
import { TeacherClassroomAssignmentsComponent } from './teacher-classroom-assignments/teacher-classroom-assignments.component';
import { TeacherClassroomAssignmentReportComponent } from './teacher-classroom-assignment-report/teacher-classroom-assignment-report.component';
import { TeacherClassroomCurriculumComponent } from './teacher-classroom-curriculum/teacher-classroom-curriculum.component';
import { TeacherMakeAssignmentComponent } from './teacher-make-assignment/teacher-make-assignment.component';
import { TimeModule } from '../time/time.module';
import { TeacherGradingFormletComponent } from './teacher-grading-formlet/teacher-grading-formlet.component';
import { UiModule } from '../ui/ui.module';
import { TeacherClassroomStudentAssignmentReportComponent } from './teacher-classroom-student-assignment-report/teacher-classroom-student-assignment-report.component';
import { StudentResponsesTableComponent } from './student-responses-table/student-responses-table.component';
import { AssessmentSelectorComponent } from './assessment-selector/assessment-selector.component';
import { AssignmentDetailEditorComponent } from './assignment-detail-editor/assignment-detail-editor.component';
import { TeacherClassroomStudentsPrintComponent } from './teacher-classroom-students-print/teacher-classroom-students-print.component';
import { TeacherClassroomParentlettersComponent } from './teacher-classroom-parentletters/teacher-classroom-parentletters.component';
import { UiTaskcreatorModule } from '../ui-taskcreator/ui-taskcreator.module';
import { HeadsupLobbyComponent } from './headsup-lobby/headsup-lobby.component';
import { UiAvatarDisplaysModule } from '../ui-avatar-displays/ui-avatar-displays.module';
import { RenderImageComponent } from './render-image/render-image.component';
import { AssignmentSelectorComponent } from './assignment-selector/assignment-selector.component';
import { TeacherClassroomReportsComponent } from './teacher-classroom-reports/teacher-classroom-reports.component';
import { TeacherBoosterpacksComponent } from './teacher-boosterpacks/teacher-boosterpacks.component';
import { TeacherOnboardingComponent } from './teacher-onboarding/teacher-onboarding.component';
import { TeacherAvatarComponent } from './teacher-avatar/teacher-avatar.component';
import { ShareClassroomComponent } from './share-classroom/share-classroom.component';
import { BoosterpackActivityLogComponent } from './boosterpack-activity-log/boosterpack-activity-log.component';
import { ClassCodeIndicatorComponent } from './class-code-indicator/class-code-indicator.component';
import { QuickCreateComponent } from './quick-create/quick-create.component';
import { TeacherClassroomReportStudentComponent } from './teacher-classroom-report-student/teacher-classroom-report-student.component';

// // smaller components


@NgModule({
  imports: [
    CommonModule, 
    RouterModule, 
    ReactiveFormsModule,
    TimeModule,
    UiModule,
    UiAvatarDisplaysModule,
    UiTaskcreatorModule,
  ],
  declarations: [
    TeacherClassroomsComponent,
    TeacherClassroomStudentsComponent,
    TeacherClassroomAssignmentsComponent,
    TeacherClassroomAssignmentReportComponent,
    TeacherClassroomCurriculumComponent,
    TeacherMakeAssignmentComponent,
    TeacherGradingFormletComponent,
    TeacherClassroomStudentAssignmentReportComponent,
    StudentResponsesTableComponent,
    AssessmentSelectorComponent,
    AssignmentDetailEditorComponent,
    TeacherClassroomStudentsPrintComponent,
    TeacherClassroomParentlettersComponent,
    HeadsupLobbyComponent,
    RenderImageComponent,
    AssignmentSelectorComponent,
    TeacherClassroomReportsComponent,
    TeacherBoosterpacksComponent,
    TeacherOnboardingComponent,
    TeacherAvatarComponent,
    ShareClassroomComponent,
    BoosterpackActivityLogComponent,
    ClassCodeIndicatorComponent,
    QuickCreateComponent,
    TeacherClassroomReportStudentComponent,
    TeacherLeaderboardsComponent,
  ],
  exports: [
    TeacherClassroomsComponent,
    TeacherClassroomStudentsComponent,
    TeacherClassroomAssignmentsComponent,
    TeacherClassroomAssignmentReportComponent,
    TeacherClassroomCurriculumComponent,
    TeacherMakeAssignmentComponent,
    TeacherLeaderboardsComponent,
  ]
})
export class UiTeacherModule {}
