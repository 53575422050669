import { IUser } from "./collections/users.types";

export const ensureFirstLastName = (userInfo:IUser) => {
    if (userInfo.displayName === 'nameless user'){
        if (userInfo.firstName){
            userInfo.displayName = userInfo.firstName +' '+userInfo.lastName;
        }
        else{
            userInfo.displayName = '? ?' 
        }
    }
    if (!userInfo.firstName){
        const nameSplit = userInfo.displayName.split(' ');
        userInfo.lastName = nameSplit.pop();
        userInfo.firstName = nameSplit.join(' ');
    }
    if (!userInfo.lastName){
        userInfo.lastName = '';
    }
    userInfo.lastName = ''+userInfo.lastName;
}